//ROUTE_DISCONNECT_WIFI
import React, { useEffect, useState } from "react";
import { String } from "../../l10n/loader";
import { connect } from "react-redux";
import {
  NETWORK_STATUS_ATTACKED,
  NETWORK_STATUS_HACKED,
  ONBOARD_DISCONNECT_WIFI_EDUCATE,
} from "../../constants/main";

// DOM
import { Display, Paragraph, Button } from "@mcafee/pegasus";

import { networkDisconnectSkip } from "../../store/network";

import image from "../_assets/images/hackBlock/disconnectWifiEducation.svg";
import { ROUTE_DISCONNECT_WIFI_DONE } from "../app/routes";
import { uwpOpenWindowsSettingPage } from "../../uwp";
import { createFormattedId } from "../../utils";
import { DialogLayout } from "../layouts/DialogLayout";
import { navigateBack } from "../../store/thunks/app";

const DisconnectWifiEducate = ({
  status: wifiStatus,
  navigateBack,
  replace,
  networkDisconnectSkip,
}) => {
  const [initWifiStatus] = useState(wifiStatus);
  const _T = (str) => `hackBlock.${str}`;
  const _S = (str) => `hackBlock.disconnectWifiEducate.${str}`;

  const onTakeRiskHandler = (e) => {
    networkDisconnectSkip();
    navigateBack({ steps: 2 });
  };

  const onOpenWifiSettings = (e) => {
    uwpOpenWindowsSettingPage("ms-settings:network-wifi");
  };

  useEffect(() => {
    if (
      wifiStatus !== initWifiStatus &&
      wifiStatus !== NETWORK_STATUS_ATTACKED &&
      wifiStatus !== NETWORK_STATUS_HACKED
    ) {
      replace(ROUTE_DISCONNECT_WIFI_DONE);
    }
  }, [replace, wifiStatus, initWifiStatus]);

  const Buttons = () => (
    <>
      <Button
        className="mr-24 px-40"
        onClick={onOpenWifiSettings}
        id={`${createFormattedId(
          ONBOARD_DISCONNECT_WIFI_EDUCATE
        )}OpenWifiSettings`}
        size="sm"
      >
        <String id={_T`openWifiSettings`} />
      </Button>
      <Button
        variant="tertiary"
        onClick={onTakeRiskHandler}
        id={`${createFormattedId(ONBOARD_DISCONNECT_WIFI_EDUCATE)}TakeRisk`}
        size="sm"
      >
        <String id={_T`takeRisk`} />
      </Button>
    </>
  );

  return (
    <DialogLayout
      pageId={ONBOARD_DISCONNECT_WIFI_EDUCATE}
      image={image}
      showBack={false}
      buttons={<Buttons />}
    >
      <Display size="sm">
        <String id={_S`header`} />
      </Display>
      <Paragraph size="md" className="mt-32">
        <String id={_S`content_1`} />
      </Paragraph>
      <Paragraph size="md" className="mt-32">
        <String id={_S`content_2`} />
      </Paragraph>
    </DialogLayout>
  );
};

export default connect((state) => ({ status: state.network.status }), {
  networkDisconnectSkip,
  navigateBack,
})(DisconnectWifiEducate);
