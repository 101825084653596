import {
  APP_POLARIS,
  EMPTY_VAL,
  FALSE_S,
  MTX_PROPS,
  SUCCESS,
  TRUE_S,
} from "../constants/main";
import msgbus from "../msgbus";
import { store } from "../store/store";
import { uwpInvoke } from "../uwp";
import { stringifyJson } from "./main";
import { syncedInvoke, waitMutex } from "./sync";

let msgBusWorks = false;

if (!window.localStorage) {
  msgbus
    .request("SETTINGS_MANAGER_GET_PROPERTY", "__ECHO__")
    .then((value) => {
      msgBusWorks = true;
    })
    .catch((err) => {
      msgBusWorks = false;
    });
}

export const setProperties = async (props) => {
  return syncedInvoke({ mutex: MTX_PROPS }, async () => {
    if (
      store.getState().experience.app === APP_POLARIS &&
      !window.localStorage && //inside Chromium Container
      msgBusWorks
    ) {
      for (let key in props) {
        try {
          await msgbus.request(
            "SETTINGS_MANAGER_SET_PROPERTY",
            key,
            props[key]
          );
        } catch (e) {
          return false;
        }
      }
      return true;
    }

    let result;
    let propsToUpdate = {};
    let propsToDelete = [];

    //Split updated and delete properties and stringify updated values
    Object.keys(props).forEach((k) => {
      const val = props[k];
      if (val === EMPTY_VAL) {
        propsToDelete.push(k); //If delete fails, the prop value would still be set to empty string
      }
      propsToUpdate[k] = stringifyJson(val);
    });

    try {
      if (Object.keys(propsToUpdate).length) {
        const updateResponse = await uwpInvoke(
          "settings_manager.set_properties",
          {
            properties: propsToUpdate,
          }
        );
        result = updateResponse.status === SUCCESS;
      }

      if (propsToDelete.length) {
        const deleteResponse = await uwpInvoke(
          "settings_manager.delete_properties",
          {
            properties: propsToDelete,
          }
        );

        result = result !== false && deleteResponse.status === SUCCESS;
      }
    } catch (e) {
      //Failed to execute communicator invoke
      result = undefined;
    }

    if (result === undefined) {
      //Use localStorage
      Object.keys(propsToUpdate).forEach((k) => {
        const val = propsToUpdate[k];
        if (val === EMPTY_VAL) {
          localStorage.removeItem(k);
        } else {
          localStorage.setItem(k, val);
        }
      });
      propsToDelete.forEach((k) => {
        localStorage.removeItem(k);
      });
      result = true;
    }

    return result;
  });
};

export const getProperties = async (properties) => {
  if (
    store.getState().experience.app === APP_POLARIS &&
    !window.localStorage &&
    msgBusWorks
  ) {
    let results = {};

    for (let key in properties) {
      try {
        results[key] = await msgbus.request(
          "SETTINGS_MANAGER_GET_PROPERTY",
          key
        );
      } catch (e) {
        return null;
      }
    }

    return results;
  }

  await waitMutex(MTX_PROPS);
  let results = {};

  try {
    const {
      properties: uwpResults,
      status,
    } = await uwpInvoke("settings_manager.get_properties", { properties });
    if (status === SUCCESS) {
      results = { ...uwpResults, ...results };
    }
  } catch (e) {
    //use localStorage as a fallback for Browser only implementation
    properties.forEach((item) => {
      results[item] = localStorage.getItem(item);
    });
  }

  //Handle special cases
  return Object.keys(results).reduce((r, k) => {
    const val = results[k];
    if ([EMPTY_VAL, null].includes(val)) {
      return r; //skip null and empty strings
    }

    if (val === TRUE_S) {
      r[k] = true;
    } else if (val === FALSE_S) {
      r[k] = false;
    } else {
      r[k] = val;
    }
    return r;
  }, {});
};
