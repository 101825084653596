import {
  Button,
  Col,
  Grid,
  Heading,
  Paragraph,
  ProgressGauge,
  Row,
} from "@mcafee/pegasus";
import { String } from "../../l10n/loader";
import "./scanProgress.scss";
import { useDispatch } from "react-redux";
import { POPUP_AV_CANCEL_SCAN } from "../PopUps/constants";
import { showPopup } from "../../store/thunks/popups";
import { ROUTE_DASHBOARD } from "../app/routes";
import { replace } from "connected-react-router";

export default function ScanProgress({
  progress = 0,
  fixed,
  scanned,
  onCancelScan,
  scanType,
}) {
  const _S = (str) => `av.scanProgress.${str}`;
  const dispatch = useDispatch();
  const onTellMeWhenDone = () => {
    dispatch(replace(ROUTE_DASHBOARD));
  };
  const onCancel = () => {
    dispatch(
      showPopup(POPUP_AV_CANCEL_SCAN, {
        onCancelScan: onCancelScan,
      })
    );
  };
  return (
    <div className="ScanProgress">
      <Grid className="ScanProgress__container" fluid>
        <Row className="ScanProgress__gauge">
          <Col xs={3} sm={2} md={2} lg={2}>
            <ProgressGauge value={progress} />
          </Col>
        </Row>
      </Grid>

      <div className="ScanProgress__content">
        <Heading className="ScanProgress__header" size="xs">
          {scanType.charAt(0).toUpperCase() + scanType.slice(1)} scan in progress...
        </Heading>
        <div className="ScanProgress__threats">
          <div className="ScanProgress__threats__container">
            <Heading size="xs" className="text-success">
              {fixed}
            </Heading>
            <Paragraph size="md">
              <String id={_S`threatsFixed`} />
            </Paragraph>
          </div>
          <div>
            <Heading size="xs">{scanned}</Heading>
            <Paragraph size="md">
              <String id={_S`filesScanned`} />
            </Paragraph>
          </div>
        </div>
        
      </div>
<div><Paragraph size="xs" className="ScanProgress__info">
          <String id={_S`content`} values={{ br: <br /> }} />
        </Paragraph>
       </div>

      <div className="ScanProgress__buttons">
        <Button
          className="ScanProgress__buttons__button"
          onClick={onTellMeWhenDone}
          id="AvTellMeWhenDone"
          size="sm"
        >
          <String id={_S`tellMe`} />
        </Button>
        <Button
          className="ScanProgress__buttons__button"
          variant="tertiary"
          onClick={onCancel}
          id="AvCancel"
          size="sm"
        >
          <String id={_S`cancel`} />
        </Button>
      </div>
    </div>
  );
}
