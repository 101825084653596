// React
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./settings.scss";
import {
  Subheading,
  Grid,
  Row,
  Col,
  Label,
  Paragraph,
  Icon,
  Popover,
  PopoverHandler,
  PopoverContent,
  PopoverItem,
  Link,
} from "@mcafee/pegasus";

import parsePhoneNumber from "libphonenumber-js";

import {
  POPUP_ADD_PHONE_NUMBER,
  POPUP_REMOVE_PHONE_NUMBER,
} from "../PopUps/constants";

// utils
import {
  deleteMobileNumber,
  fetchMobileNumber,
} from "../../store/thunks/dashboard";
import { String } from "../../l10n/loader";

// Custom components
import NavbarLayout from "../NavBar/NavbarLayout";
import SettingsMenu from "./SettingsMenu";
import SubscriptionInformation from "./SubscriptionInformation";

import { showPopup } from "../../store/thunks/popups";
import { EMPTY_VAL, MAX_ERROR_RETRY } from "../../constants/main";
import { collectScreenLoadEvent, navToErrorPage } from "../../utils";
import {
  ANALYTICS_LIFE_CYCLE,
  ANALYTICS_MANUAL,
  ANALYTICS_MENU,
  ANALYTICS_SETTING,
  HIT_CATEGORY,
  HIT_FEATURE,
  HIT_SCREEN,
  HIT_TRIGGER,
  SCREEN_DETAILS,
  SCREEN_TYPE,
} from "../../constants/analytics";
import {
  SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION,
  SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_MENU,
} from "../../constants/analyticsScreens";
import {
  DeleteForeverOutlined,
  MoreVertRounded,
  PhoneIphoneRounded,
} from "@material-ui/icons";
import ErrorMessage from "../Shared/ErrorMessage";
import { InPlaceProgress } from "../styledComponents/progress";
import { showToast } from "../../store/thunks/toasts";
import {
  BANNER_NOTIFICATION,
  TOAST_SNACKBAR_SUCCESS,
} from "../Toasts/constants";

export default function AccountSettings() {
  const _S = (str) => `settings.account.phoneNumber.${str}`;
  const [retryCount, setRetryCount] = useState(0);
  const [parsedPhoneNumber, setParsedPhoneNumber] = useState(EMPTY_VAL);

  const dispatch = useDispatch();

  //Store selector
  const subscriptionStatus = useSelector(
    (state) => state.subscription.subscriptionStatus
  );
  const { fetchMobileError, mobileNumber, deleteMobileProgress } = useSelector(
    (state) => state.dashboard
  );

  const tryFetchPhoneNumber = useCallback(
    (retryCount) => {
      dispatch(fetchMobileNumber()).then(() => {
        setRetryCount(retryCount + 1);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (fetchMobileError && retryCount > MAX_ERROR_RETRY) {
      navToErrorPage(fetchMobileError);
    }
  }, [dispatch, fetchMobileError, retryCount]);

  //Initial call
  useEffect(() => {
    tryFetchPhoneNumber(0);
  }, [tryFetchPhoneNumber]);

  useEffect(() => {
    if (subscriptionStatus) {
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_CATEGORY]: ANALYTICS_SETTING,
        [HIT_TRIGGER]: ANALYTICS_MANUAL,
        [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION,
        [SCREEN_TYPE]: ANALYTICS_SETTING,
        [SCREEN_DETAILS]: subscriptionStatus,
      });
    }
  }, [subscriptionStatus]);

  useEffect(() => {
    //Update phone number rendered UI
    setParsedPhoneNumber(
      mobileNumber ? parsePhoneNumber(`+${mobileNumber}`) : EMPTY_VAL
    );
  }, [mobileNumber]);

  const onRetry = (e) => {
    tryFetchPhoneNumber(retryCount);
  };

  const showAddPhoneNumberPopup = async () => {
    dispatch(
      showPopup(POPUP_ADD_PHONE_NUMBER, {}, "", { overflow: "visible" })
    );
  };

  const tryRemovePhoneNumber = async (retryCount = 0) => {
    let { ok, error } = await dispatch(deleteMobileNumber());
    if (ok) {
      dispatch(
        showToast(TOAST_SNACKBAR_SUCCESS, {
          stringId: "toasters.removePhone",
        })
      );
      tryFetchPhoneNumber();
    } else {
      if (retryCount >= MAX_ERROR_RETRY) {
        navToErrorPage(error);
      } else {
        dispatch(
          showToast(BANNER_NOTIFICATION, {
            stringId: "toasters.banner.somethingWrong",
            selfDestruct: false,
          })
        ).then((retry) => {
          if (retry) {
            tryRemovePhoneNumber(retryCount + 1);
          }
        });
      }
    }
  };

  const showDeletePhoneNumberPopup = async () => {
    collectScreenLoadEvent({
      [HIT_CATEGORY]: ANALYTICS_SETTING,
      [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
      [HIT_TRIGGER]: ANALYTICS_MANUAL,
      [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_MENU,
      [SCREEN_TYPE]: ANALYTICS_MENU,
      [SCREEN_DETAILS]: "new_device_default",
    });
    if (await dispatch(showPopup(POPUP_REMOVE_PHONE_NUMBER))) {
      tryRemovePhoneNumber();
    }
  };

  const progress =
    deleteMobileProgress || (mobileNumber === undefined && !fetchMobileError);

  return (
    <NavbarLayout
      id="Settings"
      className="pa-0"
      classNameLayout="pa-0 bg-white no-overflow"
    >
      <Grid>
        <Row style={{ height: "100%" }}>
          <Col sm xs={12} className="flex">
            <SettingsMenu />
          </Col>
          <Col
            sm={8}
            xs={12}
            className="flex direction-column justify-content-start bg-white pa-24 grow-1 overflow"
          >
            <Subheading
              size="lg"
              className="mb-24"
              data-testid="AccountSettingsPageMyAccount"
            >
              <String id="settings.notifications.myAccount" />
            </Subheading>

            <SubscriptionInformation />

            <>
              <Label
                size="sm"
                className="mb-4 text-body"
                data-testid="AccountSettingsPageTitle"
              >
                <String id={_S`title`} />
              </Label>

              <Paragraph
                size="sm"
                className="text-greyscale mb-24"
                data-testid="AccountSettingsPageText"
              >
                <String id={_S`text`} />
              </Paragraph>
            </>

            {progress && <InPlaceProgress infinite noPercentage />}

            {!progress && fetchMobileError && (
              <ErrorMessage
                {...{
                  error: fetchMobileError,
                  onRetry,
                }}
              />
            )}

            {!progress && !fetchMobileError && parsedPhoneNumber && (
              <Row className="pa-8">
                <Col
                  sm={1}
                  className="flex align-items-center justify-content-center"
                >
                  <PhoneIphoneRounded className="text-gray-light" />
                </Col>
                <Col sm={10}>
                  <Row>
                    <Label size="md">
                      {parsedPhoneNumber.format("NATIONAL")}
                    </Label>
                  </Row>
                  <Row>
                    <Label className="text-success">
                      <String id={_S`verified`} />
                    </Label>
                  </Row>
                </Col>
                <Col sm={1}>
                  <Popover className="mr-16" position="bottom-left">
                    <PopoverHandler>
                      <Link>
                        <MoreVertRounded className="text-gray-light" />
                      </Link>
                    </PopoverHandler>
                    <PopoverContent style={{ right: 0, left: "auto" }}>
                      <PopoverItem
                        onClick={showDeletePhoneNumberPopup}
                        className="flex justify-content-between"
                      >
                        <Col>
                          <Label size="md">
                            <String id={_S`popOverText`} />
                          </Label>
                        </Col>
                        <Col>
                          <DeleteForeverOutlined className="text-danger ml-12" />
                        </Col>
                      </PopoverItem>
                    </PopoverContent>
                  </Popover>
                </Col>
              </Row>
            )}

            {!progress && !fetchMobileError && !parsedPhoneNumber && (
              <Link onClick={showAddPhoneNumberPopup}>
                <Row className="pa-8">
                  <Icon className="text-info" icon="add-atlas-outline" />
                  <Label
                    size="md"
                    className="text-info ml-12"
                    data-testid="AccountSettingsPageAddPhoneNumber"
                  >
                    <String id={_S`addPhoneNumber`} />
                  </Label>
                </Row>
              </Link>
            )}
          </Col>
        </Row>
      </Grid>
    </NavbarLayout>
  );
}
