import { push, replace } from "connected-react-router";
import {
  APP_ID,
  DAY_MS,
  HTTP_BAD_REQUEST,
  HTTP_FETCH_FAILED,
  HTTP_OK,
  HTTP_UNAUTHORIZED,
  LS_ACCESS_TOKEN,
  LS_AFF_ID,
  LS_CACHE_LIST,
  LS_CARRIER_ID,
  LS_CLIENT_ID,
  LS_DEVICE_GUID,
  LS_DEVICE_INFO,
  LS_DONE_WELCOME,
  LS_EMAIL,
  LS_EULA_ACCEPTED,
  LS_ID_TOKEN,
  LS_LAST_SUB_SYNC_SERVER_TIMESTAMP,
  LS_LOCALE,
  LS_PRE_EXPIRY_POPUP_INFO,
  LS_PRODUCT_DEFINITION,
  LS_PRODUCT_ID,
  LS_PROVISION_ID,
  LS_REMOTE_CONFIG,
  LS_RESPAWN,
  LS_SESSION,
  LS_SPLIT_PRODUCT_ID,
  LS_USER_ACTIONS,
  LS_VPN_SETUP,
  SETTING_SERVICE_URLS,
  NETWORK_STATUS_ATTACKED,
  NETWORK_STATUS_SECURED,
} from "./constants";
import { store } from "./store/store";
import { showOverlay } from "./store/thunks/overlays";
import { showPopup } from "./store/thunks/popups";
import { showToast } from "./store/thunks/toasts";
import { cleanupCache, getProperties } from "./utils";

window.mock = {
  api: (match, mockResponse) => {
    window.dispatchAction("@context/startAutomation", {
      [match]: mockResponse,
    });
  },
  reset: () => {
    window.dispatchAction("@context/resetAutomation");
  },
  popup: (id) => {
    store.dispatch(showPopup(id));
  },
  toast: (id) => {
    store.dispatch(showToast(id));
  },
  overlay: (id) => {
    store.dispatch(showOverlay(id));
  },

  //Settings
  removePhoneFailure: (status = HTTP_UNAUTHORIZED, body) => {
    window.mock.api(
      `/setting/v1/account/${APP_ID}/comm_preference/preferred_sms`,
      { status, body }
    );
  },
  addPhoneFailure: (status = HTTP_UNAUTHORIZED, body) => {
    window.mock.api(`/setting/v2/account/${APP_ID}/comm_preference`, {
      status,
      body,
    });
  },

  //Identity
  identityFailure: (status = HTTP_UNAUTHORIZED, body) => {
    window.mock.api(`/v1/dashboard/dwsmonitor`, { status, body });
  },
  breachCountFailure: (status = HTTP_BAD_REQUEST, body) => {
    window.mock.api(`/breach/v1/BreachCount`, { status, body });
  },
  breachDetailsFailure: (
    status = HTTP_UNAUTHORIZED,
    body = {
      response_header: {
        response_code: HTTP_UNAUTHORIZED,
        traceid: "|fb95a59a-475ab54b519fc419.",
        message: "Unauthorized",
      },
    }
  ) => {
    window.mock.api(`/breach/v1/BreachDetails`, { status, body });
  },
  addAssetFailure: (status = HTTP_UNAUTHORIZED, body) => {
    window.mock.api(`/v1/vaults/idm/assets`, { status, body });
  },
  removeAssetFailure: (status = HTTP_UNAUTHORIZED, body) => {
    window.mock.api(`/v1/vaults/idm/assets`, { status, body });
  },
  remediateBreachFailure: (
    status = HTTP_UNAUTHORIZED,
    body = {
      response_header: {
        response_code: HTTP_UNAUTHORIZED,
        message: "Unauthorized",
        traceid: "|fb95a59a-475ab54b519fc419.",
      },
    }
  ) => {
    window.mock.api(`/remediation/v2/remediate`, { status, body });
  },

  //Home screen
  cardsFailure: (status = HTTP_UNAUTHORIZED, body) => {
    window.mock.api(`from-current-context`, { status, body });
  },
  emailKeyCardFailure: (status = HTTP_BAD_REQUEST, body) => {
    window.mock.api(`/keycard/v1/email/${APP_ID}`, { status, body });
  },

  //Authentication
  tokenFailure: (status = HTTP_BAD_REQUEST, body) => {
    window.mock.api("/v1/auth/token", { status, body });
  },
  tokenRefreshFailure: (status = HTTP_FETCH_FAILED, body) => {
    window.mock.api("/v1/auth/refresh", {
      status,
      body,
    });
  },
  claimsFailure: (status = HTTP_FETCH_FAILED, body) => {
    window.mock.api("/auth/v1/token/claims", {
      status,
      body,
    });
  },
  signOutFailure: (status = HTTP_FETCH_FAILED, body) => {
    window.mock.api(`/v1/auth/logout`, { status, body });
  },
  sendOtpFailure: (status = HTTP_BAD_REQUEST, body) => {
    window.mock.api("/OTP/v1/SendOtp", { status, body });
  },
  resendOtpFailure: (status = HTTP_BAD_REQUEST, body) => {
    window.mock.api("/OTP/v1/resendOtp", { status, body });
  },
  //Account & subscription
  pdFailure: (status = HTTP_BAD_REQUEST, body) => {
    window.mock.api("product-definition", { status, body });
  },
  trialExpired: (days = 5) => {
    const time_to_expiry_in_seconds = (-days * DAY_MS) / 1000;
    const expDate = new Date(
      new Date().getTime() + time_to_expiry_in_seconds * 1000
    );

    window.mock.api("product-definition", {
      status: HTTP_OK,
      body: {
        product_definition: {
          sub_status: "Trial-Expired",
          exp_date: expDate.toISOString(),
          features_sku: [],
          features_user_disabled: [],
          time_to_expiry_in_seconds,
        },
      },
    });
  },
  trialExpiringSoon: (days = 5) => {
    const time_to_expiry_in_seconds = (days * DAY_MS) / 1000;
    const expDate = new Date(
      new Date().getTime() + time_to_expiry_in_seconds * 1000
    );

    window.mock.api("product-definition", {
      status: HTTP_OK,
      body: {
        product_definition: {
          sub_status: "Trial-Active",
          exp_date: expDate.toISOString(),
          features_sku: [],
          features_user_disabled: [],
          time_to_expiry_in_seconds,
        },
      },
    });
  },
  //VPN
  vpn: {
    loginFailure: (response = { status: "failed" }) => {
      window.mock.api("vpn.login", response);
    },
    activateFailure: (status = HTTP_BAD_REQUEST, body) => {
      window.mock.api("/feature/v1/activate/vpn", { status, body });
    },
    startFailure: (response = { status: "failed" }) => {
      window.mock.api("vpn.start", response);
    },
    stopFailure: (response = { status: "failed" }) => {
      window.mock.api("vpn.stop", response);
    },
    tokenFailure: (status = HTTP_BAD_REQUEST, body) => {
      window.mock.api("/feature/safeconnect/v1/vpntoken", { status, body });
    },
  },
  network: {
    simulateNetworkAttack: () => {
      window.dispatchAction("@net/networkCheckSuccess", {
        status: NETWORK_STATUS_ATTACKED,
      });
    },
    simulateNetworkAttackSolution: () => {
      window.dispatchAction("@net/networkCheckSuccess", {
        status: NETWORK_STATUS_SECURED,
      });
    },
  },
};

//For UWP container and DevTools console to dispatch actions
window.dispatchAction = (actionId, payload) => {
  let action;
  switch (actionId) {
    case "replace":
      action = replace(payload);
      break;
    case "push":
      action = push(payload);
      break;
    default:
      store.dispatch({ type: actionId, payload });
      return;
  }

  store.dispatch(action);
};

window.logSettings = async () => {
  console.log("Fetching settings...");

  const props = await getProperties([
    LS_USER_ACTIONS,
    LS_CACHE_LIST,
    LS_DONE_WELCOME,
    LS_EULA_ACCEPTED,
    LS_ACCESS_TOKEN,
    LS_ID_TOKEN,
    LS_SESSION,
    LS_EMAIL,
    LS_VPN_SETUP,
    LS_RESPAWN,
    LS_LOCALE,
    LS_AFF_ID,
    LS_PROVISION_ID,
    LS_DEVICE_GUID,
    LS_CLIENT_ID,
    LS_DEVICE_INFO,
    LS_CARRIER_ID,
    LS_PRODUCT_ID,
    LS_SPLIT_PRODUCT_ID,
    SETTING_SERVICE_URLS,
    LS_PRODUCT_DEFINITION,
    LS_LAST_SUB_SYNC_SERVER_TIMESTAMP,
    LS_REMOTE_CONFIG,
    LS_PRE_EXPIRY_POPUP_INFO,
  ]);

  console.info(props);
  return props;
};

/**
 * A Javascript console utility
 * call to reset the stored APIs cache
 */
window.resetCache = () => {
  cleanupCache({ deleteAll: true });
};

Object.defineProperty(window, "reduxStore", {
  get() {
    return store;
  },
});
