import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading } from "@mcafee/pegasus";

import "./avCancelScanPopup.scss";

const AvCancelScanPopup = ({ onCancel, onCancelScan }) => {
  const _S = (str) => `popups.cancelScanPopup.${str}`;

  return (
    <div className="cancelScanPopup">
      <Heading
        className="cancelScanPopup__title"
        size="xs"
        data-testid="cancelScanHeader"
      >
        <String id={_S`header`} />
      </Heading>

      <Paragraph
        className="cancelScanPopup__content"
        size="sm"
        data-testid="cancelScanContent"
      >
        <String id={_S`content`} />
      </Paragraph>
      <div className="cancelScanPopup__buttons">
        <Button
          size="sm"
          onClick={() => {
            onCancelScan();
            onCancel();
          }}
          id="cancelScan"
          data-testid="cancelScanButton"
          shape="pill"
          variant="primary"
        >
          <String id={_S`cancelScan`} />
        </Button>
        <Button
          className="ml-32"
          onClick={onCancel}
          id="goBack"
          variant="tertiary"
          data-testid="goBackButton"
          size="sm"
        >
          <String id={_S`goBack`} />
        </Button>
      </div>
    </div>
  );
};

export default AvCancelScanPopup;
