import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ENV_DEV,
  ENV_QA,
  MD_VIEWPORT_SIZE,
  SM_VIEWPORT_SIZE,
  WINDOW_ASPECT_LANDSCAPE,
  WINDOW_ASPECT_PORTRAIT,
  WINDOW_SIZE_LARGE,
  WINDOW_SIZE_MEDIUM,
  WINDOW_SIZE_SMALL,
} from "../constants/main";
import { store } from "../store/store";
import { fetchLegalInfo, fetchPrivacyInfo } from "../store/thunks/app";
import { fetchDwsDashboard } from "../store/thunks/identity";
import { detectEnvironment } from "./main";

/**
 * A custom hook that watches and returns if user is signed in
 */
export const useIsSignedIn = () => {
  const { accessToken } = useSelector((state) => state.auth);
  return accessToken !== null;
};

/**
 * A custom hook the watches and returns a shared window size information
 * @returns {{width:Number, height:Number, aspect:Number}} window size and aspect
 */
export const useWindowSize = () => {
  const calcWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    const size = {};
    if (SM_VIEWPORT_SIZE >= innerWidth) {
      size.width = WINDOW_SIZE_SMALL;
    } else if (MD_VIEWPORT_SIZE >= innerWidth) {
      size.width = WINDOW_SIZE_MEDIUM;
    } else {
      size.width = WINDOW_SIZE_LARGE;
    }
    if (SM_VIEWPORT_SIZE >= innerHeight) {
      size.height = WINDOW_SIZE_SMALL;
    } else if (MD_VIEWPORT_SIZE >= innerHeight) {
      size.height = WINDOW_SIZE_MEDIUM;
    } else {
      size.height = WINDOW_SIZE_LARGE;
    }
    size.aspect =
      innerWidth > innerHeight
        ? WINDOW_ASPECT_LANDSCAPE
        : WINDOW_ASPECT_PORTRAIT;
    return size;
  };
  const [size, setSize] = useState(calcWindowSize());

  useEffect(() => {
    function handleResize() {
      setSize(calcWindowSize());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return size;
};

/**
 * A custom hook that watches and returns shared legal and privacy info Urls
 */
export const useLegalAndPrivacyInfo = () => {
  const [legalUrl, setLegalUrl] = useState(null);
  const [privacyUrl, setPrivacyUrl] = useState(null);
  useEffect(() => {
    const { getState, dispatch } = store;
    const {
      context: { legalUrl, privacyUrl },
    } = getState();
    if (!legalUrl) {
      dispatch(fetchLegalInfo()).then(({ legalUrl }) => {
        setLegalUrl(legalUrl);
      });
    } else {
      setLegalUrl(legalUrl);
    }
    if (!privacyUrl) {
      dispatch(fetchPrivacyInfo()).then(({ privacyUrl }) => {
        setPrivacyUrl(privacyUrl);
      });
    } else {
      setPrivacyUrl(privacyUrl);
    }
  }, []);
  return { legalUrl, privacyUrl };
};

export const useShowDebugError = () => {
  const [showError] = useState([ENV_QA, ENV_DEV].includes(detectEnvironment()));
  return showError;
};

/**
 * Custom hook that initiate fetchDwsDashboard if not yet called
 * And returns if primary email is monitored.
 * isMonitored undefined value indicates unknown state
 * @returns {{isMonitored:boolean}}
 */
export const useCheckPrimaryEmail = () => {
  const [isMonitored, setIsMonitored] = useState();

  const dispatch = useDispatch();
  const assets = useSelector((state) => state.identity.assets);
  const primaryEmail = useSelector((state) => state.auth.email);

  /**
   * calculate and reset the isMonitored flag value upon relevant states change
   */
  useEffect(() => {
    if (assets === null) {
      dispatch(fetchDwsDashboard()).then(({ error }) => {
        if (error) {
          setIsMonitored(false);
        }
      }); //Initiate fetching dashboard
    } else {
      //assets updated, check if primary email included
      setIsMonitored(
        Array.isArray(assets) &&
          assets.some((asset) => asset.asset_value === primaryEmail)
      );
    }
  }, [assets, primaryEmail, dispatch]);

  return { isMonitored };
};

