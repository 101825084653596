import React from "react";
// React

// utils
import { String } from "../../l10n/loader";

// DOM
import { Button, Heading, Paragraph } from "@mcafee/pegasus";
import accountCreationComplete from "../_assets/images/onboard/accountCreationComplete.svg";

// Store
import { publishEvent } from "../../store/thunks/experience";
import events from "../../experience/events";
import { connect } from "react-redux";
import { StyledPageBody, StyledPageContent } from "../styledComponents";
import { useIsSignedIn } from "../../utils";
import Unauthorized from "../Shared/Unauthorized";

const AccountCreationComplete = ({ publishEvent }) => {
  const _S = (str) => `onboard.${str}`;

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  const onNextClick = () => {
    publishEvent(events.account.accountCreationComplete.next);
  };

  return (
    <StyledPageBody className="flex align-items-center justify-content-center text-center direction-column">
      <div className="pt-96 pb-16">
        <img
          src={accountCreationComplete}
          alt={"account_creation_complete_Image"}
        />
      </div>
      <StyledPageContent className="mx-auto mt-24 mb-8">
        <Heading size="xs" data-testid="accountCreationCompleteTitle">
          <String id={_S`accountCreationComplete.title`} />
        </Heading>

        <Paragraph
          className="mt-16"
          data-testid="accountCreationCompleteContent"
        >
          <String id={_S`accountCreationComplete.content`} />
        </Paragraph>
        <div className="mt-56">
          <Button
            onClick={onNextClick}
            id="AccountCreationCompleteNext"
            data-testid="accountCreationCompleteButton"
            size="sm"
          >
            <String id={_S`next`} />
          </Button>
        </div>
      </StyledPageContent>
    </StyledPageBody>
  );
};

export default connect(() => ({}), {
  publishEvent,
})(AccountCreationComplete);
