import React from "react";
import { connect } from "react-redux";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading, Icon, Tag } from "@mcafee/pegasus";

// Store
import { updateMobileNumber } from "../../store/thunks/dashboard";

import "./secureVpnPopUp.scss";

const SecureVpnPopUp = ({ onFinish, onCancel }) => {
  const _S = (str) => `popups.${str}`;

  return (
    <div className="secureVpnPopup" handleClose={onCancel}>
      <Tag icon variant="solid" className="secureVpnPopup__icon">
        <Icon icon="vpn-secure-outline" size="lg" />
      </Tag>
      <Heading
        className="secureVpnPopup__title"
        size="xs"
        data-testid="secureVpnPopUpHeader"
      >
        <String id={_S`secureVpnPopUp.header`} />
      </Heading>

      <Paragraph
        className="secureVpnPopup__paragraph"
        size="md"
        data-testid="secureVpnPopUpContent"
      >
        <String id={_S`secureVpnPopUp.content`} />
      </Paragraph>
      <Paragraph
        className="secureVpnPopup__paragraph"
        size="md"
        data-testid="secureVpnPopUpContent2"
      >
        <String id={_S`secureVpnPopUp.content2`} />
      </Paragraph>
      <Button
        className="ml-auto"
        onClick={(e) => {
          onFinish(true);
        }}
        id="SecureVpnPopUpGotIt"
        data-testid="secureVpnPopUpGotItButton"
        size="sm"
      >
        <String id={_S`gotIt`} />
      </Button>
    </div>
  );
};

export default connect(
  (state) => ({
    ...state.dashboard,
  }),
  { updateMobileNumber }
)(SecureVpnPopUp);
