const events = {
  welcome: {
    1: {
      next: "@welcome/1/next",
    },
    2: {
      next: "@welcome/2/next",
      back: "@welcome/2/back",
    },
    3: {
      next: "@welcome/3/next",
      back: "@welcome/3/back",
    },
  },
  root: {
    home: "@root/home",
    welcome: "@root/welcome",
  },
  appHeader: {
    clickLogo: "appHeader.clickLogo",
  },
  account: {
    signIn: "@account/signIn",
    signInDefault: "@account/signInDefault",
    create: "@account/create",
    skip: "@account/skip",
    openLegal: "@account/openLegal",
    openPrivacy: "@account/openPrivacy",
    trackEula: "@account/trackEula",
    signedIn: "@account/signedIn",
    accountCreated: "@account/accountCreated",
    accountCreationComplete: {
      next: "@accountCreationComplete/next",
    },
    trialAccountCreated: "@account/trialAccountCreated",
    trialAccountCreationComplete: {
      next: "@accountTrialCreationComplete/next",
    },
  },
  settings: {
    general: {
      account: "settings.general.account",
      notifications: "settings.general.notifications",
      about: "settings.general.about",
    },
    services: {
      vpn: "settings.services.vpn",
      av: "settings.services.av",
      identity: "settings.services.identity",
      monitoredEmails: "settings.services.monitoredEmails",
    },
    feedback: "settings.feedback",
    signOut: "settings.signOut",
    back: "settings.back",
  },
  services: {
    identity: "services.identity",
    vpn: "services.vpn",
    av: "services.av",
  },
  navbar: {
    home: "@navbar/home",
    identity: "@navbar/identity",
    services: "@navbar/services",
    settings: "@navbar/settings",
    survey: "@navbar/survey",
    account: "@navbar/account",
  },
  dws: {
    more: "@dws/more",
    search: "@dws/search",
    skip: "@dws/skip",
    fixNow: "@dws/fixNow",
    fixLater: "@dws/fixLater",
    noResultsOk: "@dws/noResultsOk",
  },
  ids: {
    back: "@ids/back",
    doneRemediation: "@ids/doneRemdiation",
  },
  app: {
    heartbeat: "app.heartbeat",
  },
  subscription: {
    expired: "@subscription/expired",
  },
};

export default events;