import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { String } from "../../l10n/loader";
import "./settings.scss";

import {
  Label,
  Link,
  Paragraph,
  Emphasis,
  EmphasisGroup,
  Grid,
  Row,
  Col,
  Subheading,
} from "@mcafee/pegasus";

import NavbarLayout from "../NavBar/NavbarLayout";
import SettingsMenu from "./SettingsMenu";

import { saveSettings, loadSettings } from "../../store/thunks/settings";
import {
  SETTING_VPN_CONNECT_AUTO,
  SETTING_VPN_CONNECT_MANUAL,
  SETTING_VPN_CONNECT_ON,
  SETTING_VPN_STARTUP,
} from "../../constants/main";
import { ROUTE_SETTINGS_NOTIFICATIONS } from "../app/routes";
import { useIsSignedIn } from "../../utils";
import Unauthorized from "../Shared/Unauthorized";

const VPNSettings = ({
  push,
  vpn_profile,
  loading,
  saveSettings,
  loadSettings,
}) => {
  const [emphasisActiveID, setEmphasisActiveID] = useState(
    vpn_profile ? vpn_profile : SETTING_VPN_CONNECT_AUTO
  );

  const changeProfileSelected = (option) => {
    saveSettings({ [SETTING_VPN_STARTUP]: option });
  };

  useEffect(() => {
    loadSettings([SETTING_VPN_STARTUP]);
  }, [loadSettings]);

  useEffect(() => {
    setEmphasisActiveID(vpn_profile);
  }, [vpn_profile]);

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  const emphasisContent = [
    SETTING_VPN_CONNECT_AUTO,
    SETTING_VPN_CONNECT_ON,
    SETTING_VPN_CONNECT_MANUAL,
  ];

  const styles = {
    radio: {
      height: "20px",
      width: "20px",
      border: "1px solid #848faa",
      lineHeight: "1",
      flex: "none",
    },
    label: { width: "100%", height: "100%" },
  };

  return (
    <NavbarLayout
      id="Settings"
      className="pa-0"
      classNameLayout="pa-0 bg-white"
    >
      <Grid>
        <Row style={{ height: "100%" }}>
          <Col sm xs={12} className="flex">
            <SettingsMenu />
          </Col>
          <Col
            sm={8}
            xs={12}
            className="flex direction-column justify-content-start bg-white pa-24 grow-1"
          >
            <Subheading size="md" className="mb-24">
              <String id="settings.vpn.title" />
            </Subheading>

            <Label size="sm" className="mb-12 text-primary">
              <String id="settings.vpn.whenToProtectMe" />
            </Label>

            <Paragraph size="sm" className="text-greyscale mb-24">
              <String id="settings.vpn.description" />
            </Paragraph>
            <EmphasisGroup activeID={emphasisActiveID}>
              {emphasisContent.map((emphasis, index) => (
                <Emphasis
                  key={index}
                  id={emphasis}
                  className="flex mb-8 pa-0"
                  onClick={changeProfileSelected}
                >
                  <label
                    className="flex py-16 px-24"
                    htmlFor={emphasis}
                    style={styles.label}
                  >
                    <input
                      type="radio"
                      checked={emphasisActiveID === emphasis}
                      className="mr-12"
                      style={styles.radio}
                      disabled={loading}
                    />
                    <div>
                      <Label size="md">
                        <String
                          id={`settings.vpn.emphasis.${emphasis}.title`}
                        />
                      </Label>
                      {emphasis === "auto" && (
                        <>
                          <Label size="xs" className="text-gray-light py-4">
                            <String
                              id={`settings.vpn.emphasis.${emphasis}.recommended`}
                            />
                          </Label>

                          <Paragraph size="sm">
                            <String
                              id={`settings.vpn.emphasis.${emphasis}.description`}
                            />
                          </Paragraph>
                        </>
                      )}
                    </div>
                  </label>
                </Emphasis>
              ))}
            </EmphasisGroup>

            <Label size="sm" className="mb-12 mt-24 text-primary">
              <String id="settings.vpn.notificationsTitle" />
            </Label>

            <Paragraph size="sm" className="text-greyscale">
              <String id="settings.vpn.notificationsText" />{" "}
              <Link disabled onClick={() => push(ROUTE_SETTINGS_NOTIFICATIONS)}>
                <String id="settings.vpn.notificationsPreferences" />
              </Link>
              .
            </Paragraph>
          </Col>
        </Row>
      </Grid>
    </NavbarLayout>
  );
};

export default connect(
  (state) => ({
    vpn_profile: state.settings.vpn_profile,
    loading: state.settings.loading,
  }),
  { push, saveSettings, loadSettings }
)(VPNSettings);
