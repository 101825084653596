const bus = {
  request: (id, name, value, timeout) => {
    return new Promise((onResolve, onReject) => {
      let timedOut = false;
      let timeoutId = 0;

      if (value !== undefined) {
        value = JSON.stringify(value);
      }

      if (timeout) {
        timeoutId = setTimeout(() => {
          timedOut = true;

          onReject(new Error("MsgBus request timed out!"));
        });
      }

      window.McMessageBus.request(
        id,
        {
          name: name,
          value: value,
        },
        (result) => {
          let retValue;

          if (timedOut) {
            return;
          }

          timeoutId && clearTimeout(timeoutId);

          if (result.value !== undefined) {
            retValue = JSON.parse(result.value);
          }

          onResolve(retValue);
        },
        onReject
      );
    });
  },
};

export default bus;