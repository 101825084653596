import React, { useEffect, useState } from "react";
import { Paragraph, Switch, Subheading } from "@mcafee/pegasus";
import { String } from "../../l10n/loader";
import { useDispatch } from "react-redux";

import { saveSettings } from "../../store/thunks/settings";

export default function NotificationSettingsCard({
  image,
  text,
  emailNotificationId,
  emailNotificationState,
  deviceNotificationId,
  deviceNotificationState,
  type,
}) {
  const dispatch = useDispatch();
  const [selectedNotifications, setSelectedNotifications] = useState();

  const onDeviceSettingChange = (e, option) => {
    let deviceId = option
      ? [deviceNotificationId + "." + option]
      : [deviceNotificationId];
    dispatch(
      saveSettings({
        [deviceId]: e.target.checked,
      })
    );
  };

  const onEmailSettingChange = (e) => {
    dispatch(
      saveSettings({
        [emailNotificationId]: e.target.checked,
      })
    );
  };

  useEffect(() => {
    var notifications = [];

    if (deviceNotificationState === true) {
      notifications.push("Push notification");
    }
    if (emailNotificationState === true) {
      notifications.push("Email");
    }

    setSelectedNotifications(notifications.join(", "));
  }, [deviceNotificationState, emailNotificationState]);

  const styles = {
    border: { borderTop: "1px solid #E6E9F0" },
    filter: { filter: "grayscale(1)" },
    font: { fontWeight: "700" },
  };

  if (type === "handler")
    return (
      <div className="flex direction-row">
        <img
          src={image}
          alt="notification icon"
          className="mr-12"
          style={!selectedNotifications ? styles.filter : null}
        />
        <div className="text-left">
          <Subheading size="sm">
            <String id={`settings.notifications.${text}`} />
          </Subheading>
          <Paragraph size="xs">
            {selectedNotifications ? selectedNotifications : "off"}
          </Paragraph>
        </div>
      </div>
    );
  else if (type === "content")
    return (
      <div className="mb-12 pa-24 ml-32">
        <Paragraph size="sm" className="mb-24" style={styles.font}>
          <String id={`settings.notifications.${text}.text`} />
        </Paragraph>
        {emailNotificationId && (
          <div
            className="flex justify-content-between align-items-center py-16"
            style={styles.border}
          >
            <Paragraph size="sm" data-testid="NotificationSettingsCardEmailMe">
              <String id="settings.emailMe" />
            </Paragraph>
            <Switch
              kind="success"
              checked={emailNotificationState === true}
              onChange={onEmailSettingChange}
            />
          </div>
        )}
        {deviceNotificationId && (
          <div
            className="flex justify-content-between align-items-center py-16"
            style={styles.border}
          >
            <Paragraph
              size="sm"
              data-testid="NotificationSettingsCardNotifyMeOnThisDevice"
            >
              <String id="settings.notifyMeOnThisDevice" />
            </Paragraph>
            <Switch
              kind="success"
              checked={deviceNotificationState === true}
              onChange={onDeviceSettingChange}
            />
          </div>
        )}
        {/* commenting out for beta release till we have api support for these checkboxes */}
        {/* {text === "myVPN" && (
          <div>
            <div className="flex  py-16" style={styles.border}>
              <Paragraph size="sm" style={styles.font}>
                <String id="settings.secureVpn.text" />
              </Paragraph>
            </div>
            {options.map((option, index) => {
              return (
                <div
                  className="flex direction-row align-items-start py-12"
                  style={index !== 0 ? styles.border : {}}
                  key={index}
                >
                  <Checkbox
                    onChange={(e) => onDeviceSettingChange(e, option)}
                  />
                  <div className="ml-16">
                    <Paragraph size="sm" style={styles.font}>
                      <String id={`settings.secureVpn.${option}.header`} />
                    </Paragraph>
                    <Paragraph size="xs">
                      <String id={`settings.secureVpn.${option}.text`} />
                    </Paragraph>
                  </div>
                </div>
              );
            })}
          </div>
        )} */}
      </div>
    );
  else return null;
}
