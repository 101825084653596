// React
import React from "react";

// DOM
import AntivirusNeedsAttentionImage from "../_assets/images/dashboard/cards/antivirusNeedsAttention.svg";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";

// utils
import { NEEDS_ATTENTION } from "../../constants/main";

import { useDispatch } from "react-redux";

import { ROUTE_SETTINGS_ANTIVIRUS } from "../app/routes";
import { replace } from "connected-react-router";

export default function AvTurnOnRTS({ id }) {
  // Close the current PopUp and show the other popup based on the Storing status.
  const dispatch = useDispatch();

  const onPrimaryAction = async (e) => {
    await dispatch(replace(ROUTE_SETTINGS_ANTIVIRUS));
  };

  const card = {
    id: id,
    backgroundImage: AntivirusNeedsAttentionImage,
    tagText: NEEDS_ATTENTION,
    primaryActionStringId: "seeAntivirus",
    primaryActionDataTestId: "seeAntivirusTurnOnRtsId",
    onPrimaryAction,
  };

  // has the Popup handler intact with the card UI
  return <DashboardCard {...card} />;
}
