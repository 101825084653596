import {
  BREACH_STATUS_URGENT,
  FEATURE_VPN,
  FEATURE_AV,
  NETWORK_STATUS_ATTACKED,
  NETWORK_STATUS_HACKED,
  NETWORK_STATUS_SECURED,
  UA_NOTIFICATIONS_DONE,
  UA_ONBOARDING_BREACHES_FOUND,
  UA_ONBOARDING_EMAIL_ENROLLED,
  UA_ONBOARDING_SCAN_DONE,
  UA_WHAT_APP_CAN_DO_FOR_YOU_SHOWN,
  VPN_STATE_CONNECTED,
} from "../constants/main";
import { store } from "../store/store";
import { publishEvent } from "../store/thunks/experience";
import { objectNodeValue, stringifyJson } from "../utils";
import { uwpLog } from "../uwp";

const { addEventListener } = window;

const mapRuleVarToStatePath = {
  VpnEnabled: [
    "experience.features",
    (features) => features[FEATURE_VPN] && features[FEATURE_VPN].enabled,
  ],
  AvEnabled: [
    "experience.features",
    (features) => features[FEATURE_AV] && features[FEATURE_AV].enabled,
  ],
  IdentityEnabled: [
    "experience.features",
    (features) =>
      features.email_monitoring &&
      (features.email_monitoring.enabled || features.phone_monitoring.enabled),
  ],
  SubscriptionEnabled: [
    "experience.features",
    (features) =>features.subscription && features.subscription.enabled,
  ],
  VpnSetupComplete: "vpn.vpnSetup",
  VpnConnected: ["vpn.state", (state) => state === VPN_STATE_CONNECTED],
  WifiSecured: [
    "network.status",
    (status) => status === NETWORK_STATUS_SECURED,
  ],
  WifiUnsafe: [
    "network.status",
    (status) =>
      status === NETWORK_STATUS_ATTACKED || status === NETWORK_STATUS_HACKED,
  ],
  Notifications: "context.notifications",
  OnboardingScanDone: "auth.userActions." + UA_ONBOARDING_SCAN_DONE,
  NotificationsDone: "auth.userActions." + UA_NOTIFICATIONS_DONE,
  OnboardingScanBreachesFound:
    "auth.userActions." + UA_ONBOARDING_BREACHES_FOUND,

  OnboardingEmailEnrolled: "auth.userActions." + UA_ONBOARDING_EMAIL_ENROLLED,

  WhatAppCanDoForYouShown:
    "auth.userActions." + UA_WHAT_APP_CAN_DO_FOR_YOU_SHOWN,

  BreachesFound: [
    "identity.breaches",
    (breaches) => Array.isArray(breaches) && breaches.length > 0,
  ],
  UrgentBreachesFound: [
    "identity.breaches",
    (breaches) =>
      Array.isArray(breaches) &&
      breaches.filter((b) => b.status === BREACH_STATUS_URGENT).length > 0,
  ],
  PrimaryEmailMonitored: [
    "identity.assets",
    "auth.email",
    (assets, email) =>
      Array.isArray(assets) &&
      assets.some(
        (asset) => asset.asset_value === email //check if primary email is already monitored
      ),
  ],
  TwoOrMoreEmailsMonitored: [
    "identity.assets",
    (assets) => Array.isArray(assets) && assets.length >= 2,
  ],
};

const mapStatePathToRuleVars = (statePath) => {
  return Object.keys(mapRuleVarToStatePath).filter((k) => {
    return Array.isArray(mapRuleVarToStatePath[k])
      ? mapRuleVarToStatePath[k].find(
          (item) => typeof item === "string" && statePath.indexOf(item) === 0
        )
      : mapRuleVarToStatePath[k] === statePath;
  });
};

const validateExpression = (state, ruleVar, rightVal) => {
  const statePath = mapRuleVarToStatePath[ruleVar];

  let leftVal;
  if (Array.isArray(statePath)) {
    const fn = statePath[statePath.length - 1];
    leftVal = fn(
      ...objectNodeValue(state, statePath.slice(0, statePath.length - 1))
    );
  } else {
    leftVal = objectNodeValue(state, statePath);
  }

  if (Array.isArray(rightVal)) {
    // eslint-disable-next-line eqeqeq
    return rightVal.some((val) => leftVal == val);
  }
  // eslint-disable-next-line eqeqeq
  return leftVal == rightVal;
};

addEventListener("@state/updates", (e) => {
  const vars = e.data;

  const ruleVars = vars
    .map((v) => mapStatePathToRuleVars(v))
    .reduce((master, subList) => {
      return [...master, ...subList];
    }, []);

  uwpLog(
    `~~updates:${stringifyJson(vars)}, ruleVars:${stringifyJson(ruleVars)}`
  );

  if (!ruleVars.length) {
    return; //no rule vars affected
  }

  const rules = store.getState().experience.rules;
  const rulesToCheck = rules.filter((rule) => {
    if (Object.keys(rule.conditions).some((k) => ruleVars.includes(k))) {
      return true;
    }
    return false;
  });

  //Find rules with valid conditions to trigger their events
  rulesToCheck.forEach((rule) => {
    const state = store.getState();
    let triggerEvent = false;
    if (rule.operation === "AND") {
      triggerEvent = Object.keys(rule.conditions).every((ruleVar) => {
        return validateExpression(state, ruleVar, rule.conditions[ruleVar]);
      });
    }
    if (rule.operation === "OR") {
      triggerEvent = Object.keys(rule.conditions).some((ruleVar) => {
        return validateExpression(state, ruleVar, rule.conditions[ruleVar]);
      });
    }
    if (rule.operation === "NAND") {
      triggerEvent = !Object.keys(rule.conditions).some((ruleVar) => {
        return validateExpression(state, ruleVar, rule.conditions[ruleVar]);
      });
    }
    if (rule.operation === "NOR") {
      triggerEvent = !Object.keys(rule.conditions).every((ruleVar) => {
        return validateExpression(state, ruleVar, rule.conditions[ruleVar]);
      });
    }
    if (triggerEvent) {
      uwpLog(`~~Event( ${rule._event} )->Data( ${stringifyJson(rule._data)} )`);
      store.dispatch(publishEvent(rule._event, rule._data));
    }
  });
});
