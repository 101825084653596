import { fetchLegalInfo, fetchPrivacyInfo } from "./app";

const _openUrl = (url, dispatch) => {
  //dispatch(openUrl(url)); never used
  window.open(url, "_blank");
  return true;
};

export const openLegal = () => async (dispatch, getState) => {
  const context = getState().context;
  const legalUrl =
    context.legalUrl || (await dispatch(fetchLegalInfo())).legalUrl;
  return _openUrl(legalUrl, dispatch);
};

export const openPrivacy = () => async (dispatch, getState) => {
  const context = getState().context;
  const privacyUrl =
    context.privacyUrl || (await dispatch(fetchPrivacyInfo())).privacyUrl;
  return _openUrl(privacyUrl, dispatch);
};

export const openSurvey = () => (dispatch) => {
  return _openUrl("https://surveymonkey.com", dispatch);
};
