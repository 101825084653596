import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  VPN_STATE_CONNECTED,
  FEATURE_VPN,
  VPN_STATE_DISCONNECTED,
  VPN_STATE_CONNECTING,
  VPN_STATE_DISCONNECTING,
  VPN_STATE_ERROR,
} from "../../constants/main";
import { String } from "../../l10n/loader";
import { push } from "connected-react-router";

// DOM
import loaderIcon from "../_assets/images/shared/loader.svg";
import { startVpn, stopVpn } from "../../store/thunks/vpn";

import { disableVPNFirstTimeSetup } from "../../store/vpn";
import "./ServiceStatusBar.scss";

// Custom components
import {
  Heading,
  Paragraph,
  Icon,
  Switch,
  Popover,
  PopoverHandler,
  PopoverItem,
  PopoverContent,
  Label,
  Tag,
} from "@mcafee/pegasus";
import CheckFeature from "../Shared/CheckFeature";
import { POPUP_SECURE_VPN } from "../PopUps/constants";
import { showPopup } from "../../store/thunks/popups";
import { collectScreenLoadEvent } from "../../utils";
import {
  ANALYTICS_DASHBOARD,
  ANALYTICS_MANUAL,
  ANALYTICS_PROTECTION,
  HIT_CATEGORY,
  HIT_FEATURE,
  HIT_SCREEN,
  HIT_TRIGGER,
  SCREEN_DETAILS,
  SCREEN_FLOW,
  SCREEN_TYPE,
  SCREEN_VPN_FAB_OVERVIEW,
  ANALYTICS_PROGRESS,
  ANALYTICS_ERROR,
} from "../../constants";

const ServiceStatusBar = ({
  state: vpnState, //Connected|Disconnected|Connecting|Disconnecting|Error
  vpnSetup,
  vpnFirstSetup,
  startVpn,
  stopVpn,
  push,
  disableVPNFirstTimeSetup,
}) => {
  const localePrefix = "vpn.";
  const dispatch = useDispatch();
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    if (vpnFirstSetup) {
      dispatch(showPopup(POPUP_SECURE_VPN)).then(disableVPNFirstTimeSetup);
    }
  }, [vpnFirstSetup, disableVPNFirstTimeSetup, dispatch]);

  const toggleVPN = (e) => {
    e.preventDefault();
    switch (vpnState) {
      case VPN_STATE_DISCONNECTED:
        startVpn(0);
        break;
      case VPN_STATE_CONNECTED:
        stopVpn(0);
        break;
      default:
        return;
    }
  };

  const toggleShadow = (e) => {
    let isOverlay = !showOverlay;
    let screen_details = null;
    let screen_type = null;
    setShowOverlay(isOverlay);
    switch (vpnState) {
      case VPN_STATE_CONNECTED:
        screen_details = "vpn_fab_expanded_vpn_connected";
        screen_type = ANALYTICS_DASHBOARD;
        break;
      case VPN_STATE_CONNECTING:
        screen_details = "vpn_fab_expanded_connecting";
        screen_type = ANALYTICS_PROGRESS;
        break;
      case VPN_STATE_DISCONNECTING:
        screen_details = "vpn_fab_expanded_disconnecting";
        screen_type = ANALYTICS_PROGRESS;
        break;
      case VPN_STATE_DISCONNECTED:
        screen_details = "vpn_fab_expanded_disconnected";
        screen_type = ANALYTICS_DASHBOARD;
        break;
      case VPN_STATE_ERROR:
        screen_details = "vpn_fab_expanded_error_template";
        screen_type = ANALYTICS_ERROR;
        break;
      default:
    }

    if (isOverlay) {
      collectScreenLoadEvent({
        [HIT_FEATURE]: FEATURE_VPN,
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_TRIGGER]: ANALYTICS_MANUAL,
        [HIT_SCREEN]: SCREEN_VPN_FAB_OVERVIEW,
        [SCREEN_TYPE]: screen_type,
        [SCREEN_DETAILS]: screen_details,
        [SCREEN_FLOW]: "FAB",
      });
    } else {
      collectScreenLoadEvent({
        [HIT_FEATURE]: FEATURE_VPN,
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_TRIGGER]: ANALYTICS_MANUAL,
        [HIT_SCREEN]: SCREEN_VPN_FAB_OVERVIEW,
        [SCREEN_TYPE]: ANALYTICS_DASHBOARD,
        [SCREEN_DETAILS]:
          vpnState === VPN_STATE_CONNECTED
            ? "vpn_fab_collapsed_vpn_connected"
            : "vpn_fab_collapsed_disconnected",
        [SCREEN_FLOW]: "FAB",
      });
    }
  };

  const popoverContent = {
    [VPN_STATE_CONNECTED]: {
      vpnStatus: "vpnIsOn",
      vpnStatusText: "weAreBoosting",
      itemMSG: "turnOffVPN",
      lockIcon: "lock-atlas-outline",
      tagProperties: {
        variant: "solid",
      },
      progress: false,
    },
    [VPN_STATE_DISCONNECTING]: {
      vpnStatus: "disconnecting",
      vpnStatusText: "yourPersonalInfo",
      itemMSG: "turnOffVPN",
      tagProperties: {
        variant: "solid",
      },
      progress: true,
      progressText: "vpnIsDisconnecting",
    },
    [VPN_STATE_DISCONNECTED]: {
      vpnStatus: "vpnIsOff",
      vpnStatusText: "youAreProtected",
      itemMSG: "turnOnVPN",
      lockClass: "text-gray-light",
      lockIcon: "lock_open-atlas-outline",
      tagProperties: {
        kind: "neutral",
      },
      tagClassName: "text-gray-light",
      progress: false,
    },
    [VPN_STATE_CONNECTING]: {
      vpnStatus: "connecting",
      vpnStatusText: "andMoreSecurity",
      itemMSG: "turnOnVPN",
      tagProperties: {
        kind: "neutral",
      },
      tagClassName: "text-gray-light",
      progress: true,
      progressText: "vpnIsConnecting",
    },
    [VPN_STATE_ERROR]: {
      vpnStatus: "vpnIsOff",
      vpnStatusText: "youAreProtected",
      itemMSG: "turnOnVPN",
      lockClass: "text-gray-light",
      lockIcon: "lock_open-atlas-outline",
      tagProperties: {
        kind: "neutral",
      },
      tagClassName: "text-gray-light",
      progress: false,
    },
  };

  const styles = {
    tag: {
      border: "2em transparent", //border to avoid tag from moving when swapping between on/off
    },
    popoverHandlerIcon: {
      fontSize: "28px",
    },
    popoverContent: {
      minWidth: "375px",
      maxWidth: "375px",
      overflow: "hidden",
      boxShadow: "none",
      top: "64px",
      zIndex: 1,
    },
    popoverItem: {
      minHeight: "164px",
      padding: "24px 20px 0",
    },
    lastPopoverItem: {
      border: "none",
    },
    noEllipsis: {
      whiteSpace: "initial",
      overflow: "visible",
      textOverflow: "initial",
      textAlign: "center",
    },
  };

  return (
    <div id="ServiceStatusBar">
      <CheckFeature feature={FEATURE_VPN}>
        {vpnSetup && vpnState !== null && (
          <Popover
            onOpen={toggleShadow}
            onClose={toggleShadow}
            position="bottom-right"
          >
            <PopoverHandler>
              {popoverContent[vpnState] &&
                popoverContent[vpnState].tagProperties && (
                  <Tag
                    icon
                    tabIndex="0"
                    {...popoverContent[vpnState].tagProperties}
                    className={`shadow-2 ${popoverContent[vpnState].tagClassName}`.trim()}
                    style={styles.tag}
                  >
                    <Icon
                      icon="vpn-secure-outline"
                      size="md"
                      style={styles.popoverHandlerIcon}                      
                      data-testid= "vpnFabIcon"
                    />
                  </Tag>
                )}
            </PopoverHandler>
            <PopoverContent
              style={styles.popoverContent}
              className="corner-xxl"
            >
              <PopoverItem
                className="flex direction-column"
                style={styles.popoverItem}
                preventClose
              >
                {popoverContent[vpnState] &&
                  popoverContent[vpnState].tagProperties && (
                    <Tag
                      icon
                      tabIndex="0"
                      {...popoverContent[vpnState].tagProperties}
                      className={`${popoverContent[vpnState].tagClassName}`.trim()}
                      style={styles.tag}
                    >
                      <Icon icon="vpn-secure-outline" size="md" />
                    </Tag>
                  )}
                {popoverContent[vpnState] &&
                  popoverContent[vpnState].vpnStatus && (
                    <Heading
                      size="xs"
                      className="mb-4 mt-24"
                      style={styles.noEllipsis}
                    >
                      <String
                        id={`${localePrefix}widget.${popoverContent[vpnState].vpnStatus}`}
                      />
                    </Heading>
                  )}

                {popoverContent[vpnState] &&
                  popoverContent[vpnState].vpnStatusText && (
                    <Paragraph size="sm" style={styles.noEllipsis}>
                      <String
                        id={`${localePrefix}widget.${popoverContent[vpnState].vpnStatusText}`}
                      />
                    </Paragraph>
                  )}
              </PopoverItem>
              <PopoverItem
                className="flex justify-content-between"
                style={styles.lastPopoverItem}
                preventClose
              >
                <div className="flex align-items-center">
                  {popoverContent[vpnState] &&
                    popoverContent[vpnState].progress && (
                      <>
                        <img
                          src={loaderIcon}
                          className="loader-icon mr-16"
                          alt="loader icon"
                        />
                        <String
                          id={`${localePrefix}widget.${popoverContent[vpnState].progressText}`}
                        />
                      </>
                    )}
                  {popoverContent[vpnState] &&
                    popoverContent[vpnState].lockIcon && (
                      <>
                        <Icon
                          className="mr-16 text-gray-light"
                          icon={popoverContent[vpnState].lockIcon}
                        ></Icon>
                        <Label size="md" className="text-body">
                          <String
                            id={`${localePrefix}widget.${popoverContent[vpnState].itemMSG}`}
                          />
                        </Label>
                      </>
                    )}
                </div>
                <Switch
                  checked={vpnState === VPN_STATE_CONNECTED}
                  onChange={toggleVPN}
                  kind="success"
                  data-testid="toggleVPNSwitch"
                />
              </PopoverItem>
              <PopoverItem onClick={() => push("settings/vpn")}>
                <Icon
                  icon="settings-atlas-outline"
                  className="mr-16 text-gray-light"
                ></Icon>
                <Label size="md" className="text-body">
                  <String id={`${localePrefix}widget.goToVPNSettings`} />
                </Label>
              </PopoverItem>
            </PopoverContent>
            {/* background overlay */}
            {showOverlay && (
              <div data-testid="vpnOverlay" className="popover-overlay animated fadeIn faster" />
            )}
          </Popover>
        )}
      </CheckFeature>
    </div>
  );
};

export default connect(
  (state) => ({
    ...state.vpn,
  }), //mapStateToProps
  { push, startVpn, stopVpn, disableVPNFirstTimeSetup }
)(ServiceStatusBar);
