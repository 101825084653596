import { addToast, removeToast } from "../toasts";
import { v4 as uuidv4 } from "uuid";

export const showToast = (toast, props = {}) => (dispatch) => {
  const { addEventListener, removeEventListener } = window;

  if (!props.id) {
    props.id = uuidv4().toUpperCase();
  }

  if (props.selfDestruct === null || props.selfDestruct === undefined) {
    props.selfDestruct = true;
  }

  if (
    props.selfDestructTimer === null ||
    props.selfDestructTimer === undefined
  ) {
    props.selfDestructTimer = 5000;
  }

  return new Promise((onSuccess) => {
    dispatch(addToast({ toast, props }));
    const handleHideToast = (e) => {
      if (e.data.toast === toast) {
        onSuccess(e.data.results);
        removeEventListener(removeToast.type, handleHideToast);
      }
    };
    addEventListener(removeToast.type, handleHideToast);
  });
};
