import React from "react";

export function Loader({ className, width = 60 }) {
  return (
    <div
      className="centerContent"
      style={{
        left: 0,
        top: 0,
        width: "100%",
        position: "absolute",
        zIndex: 10,
      }}
    >
      <svg
        className={`animated fadeIn faster ${className ? className : ""}`}
        xmlns="http://www.w3.org/2000/svg"
        width={width + "px"}
        height={width + "px"}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          r="40"
          stroke="#00aeef"
          fill="none"
          strokeWidth="7"
          strokeLinecap="round"
        >
          <animate
            attributeName="stroke-dashoffset"
            dur="2s"
            repeatCount="indefinite"
            from="-188"
            to="-692"
          />
          <animate
            attributeName="stroke-dasharray"
            dur="2s"
            repeatCount="indefinite"
            values="125.5 125.5;1 250;125.5 125.5"
          />
        </circle>
      </svg>
    </div>
  );
}
