import styled from "styled-components";

const StyledPageBody = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(33, 41, 52, 0.12),
    0px 2px 2px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  height: 100vh;
`;

const StyledPageContent = styled.div`
  max-width: 500px;
`;

const StyledCardMessage = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(33, 41, 52, 0.12),
    0px 2px 2px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  padding: 65px 0;
  margin-bottom: 24px;
`;

export { StyledPageBody, StyledPageContent, StyledCardMessage };
