import React from "react";
import { useDispatch, useSelector } from "react-redux";

//Supported popups
import FailurePopUp from "./FailurePopUp";
import ProtectEmailDevicePopUp from "./ProtectEmailDevicePopUp";
import ProtectMobileDevicePopUp from "./ProtectMobileDevicePopUp";
import SecureVpnPopUp from "./SecureVpnPopUp";
import SuccessPopUp from "./SuccessPopUp";
import ConfirmSignOutPopUp from "./ConfirmSignOut";
import Survey from "./Survey";
import OtpPopup from "../OtpPopup";
import NewAssetCancelPopUp from "./NewAssetCancelPopUp";
import DWSLearnMore from "../../components/Onboard/DWSLearnMore";
import TellMeMoreModal from "../VpnSetup/TellMeMoreModal";
import FiveReasonsModal from "../SecureVpn/FiveReasonsModal";
import NotificationsModal from "../Dashboard/NotificationsModal";
import DataDisclosureModal from "../VpnSetup/DataDisclosureModal";
import OnboardKnowMorePopUp from "./OnboardKnowMorePopUp";
import AddEmailPopUp from "./AddEmailPopUp";
import RemoveEmailPopup from "./RemoveEmailPopup";
import AddPhoneNumberPopUp from "./AddPhoneNumberPopUp";
import RemovePhoneNumberPopUp from "./RemovePhoneNumberPopUp";
import ConfirmBeforeExternalLinkPopup from "./ConfirmBeforeExternalLinkPopup";
import ConfirmVpnDisclosurePopup from "./ConfirmVpnDisclosurePopup";
import WhatCanYouDoPopup from "./WhatCanYouDoPopup";
import PasswordTipsPopup from "./PasswordTipsPopup";
import AddressedBreachPopup from "./AddressedBreachPopup";
import EncryptedPasswordPopup from "./EncryptedPasswordPopup";
import ConfirmBeforeLeavePagePopup from "./ConfirmBeforeLeavePagePopup";
import VirtualLocationPopup from "../SecureVpn/VirtualLocationPopup";
import ConfirmPasswordChangePopup from "../IdentityBreachDetails/ConfirmPasswordChange";
import AssetAddedPopUp from "./AssetAddedConfirmationPopup";
import SyncSubscriptionPopup from "./SyncSubscriptionPopup";
import PreExpiryPopup from "./PreExpiryPopup";
import NoPhoneFoundPopUp from "./NoPhoneFoundPopUp";
import WssPopup from "./WssPopup";
import AvScanTypesPopup from "./AvScanTypesPopup";
import AvScanEducationPopup from "./AvScanEducationPopup";
import AvCancelScanPopup from "./AvCancelScanPopup";
import AvRestoreFailedPopup from "./AvRestoreFailedPopup";
import AvScanFileDetailsPopup from "./AvScanFileDetailsPopup";
import AvDeleteFilePopup from "./AvDeleteFilePopup";
import AvExcludeItemPopup from "./AvExcludeItemPopup";
import AvRemoveExcludedItemPopup from "./AvRemoveExcludedItemPopup";
import AvRestoreItemPopup from "./AvRestoreItemPopup";

import { Modal } from "@mcafee/pegasus";
import { closePopup } from "../../store/popups";

import "./popups.scss";

const UnknownPopup = () => {
  return <div className="pa-32">Unknown Popup</div>;
};
const PopupComponent = ({ popup, props }) => {
  const Popup =
    {
      Survey,
      OtpPopup,
      NewAssetCancelPopUp,
      FailurePopUp,
      SuccessPopUp,
      ProtectMobileDevicePopUp,
      ProtectEmailDevicePopUp,
      SecureVpnPopUp,
      ConfirmSignOutPopUp,
      DWSLearnMore,
      TellMeMoreModal,
      FiveReasonsModal,
      NotificationsModal,
      DataDisclosureModal,
      OnboardKnowMorePopUp,
      AddEmailPopUp,
      RemoveEmailPopup,
      AddPhoneNumberPopUp,
      RemovePhoneNumberPopUp,
      ConfirmBeforeExternalLinkPopup,
      ConfirmVpnDisclosurePopup,
      WhatCanYouDoPopup,
      PasswordTipsPopup,
      AddressedBreachPopup,
      EncryptedPasswordPopup,
      ConfirmBeforeLeavePagePopup,
      VirtualLocationPopup,
      ConfirmPasswordChangePopup,
      AssetAddedPopUp,
      SyncSubscriptionPopup,
      PreExpiryPopup,
      NoPhoneFoundPopUp,
      WssPopup,
      AvScanTypesPopup,
      AvScanEducationPopup,
      AvCancelScanPopup,
      AvRestoreFailedPopup,
      AvScanFileDetailsPopup,
      AvDeleteFilePopup,
      AvExcludeItemPopup,
      AvRemoveExcludedItemPopup,
      AvRestoreItemPopup,
    }[popup] || UnknownPopup;

  return <Popup {...props} />;
};

export default function Popups() {
  const popupList = useSelector((state) => {
    return state.popups.list;
  });
  const dispatch = useDispatch();

  return (
    <>
      {popupList.map((info, index) => {
        const { popup, props, className, style = {} } = info;
        const handleFinish = (results = true) => {
          const {
            handleFinish,
            onFinish,
            onFinishHandler,
            onSubmit,
            onSuccess,
          } = info.props;
          const submitHandler =
            handleFinish ||
            onFinish ||
            onSubmit ||
            onSuccess ||
            onFinishHandler;
          if (submitHandler) {
            submitHandler(results);
          }
          dispatch(closePopup({ popup, results, props }));
        };
        const handleClose = (e, results = undefined) => {
          dispatch(closePopup({ popup, results, props }));

          const { onCancel, onClose, onCancelHandler, onCloseHandler } =
            info.props || {};
          const cancelHandler =
            onCancel || onClose || onCancelHandler || onCloseHandler;
          if (cancelHandler) {
            cancelHandler(results);
          }
        };

        return (
          <Modal
            {...{
              className: `${className}`.trim(),
              style: { overflow: "hidden", ...style },
            }}
            {...{ show: true, key: index, handleClose }}
          >
            <PopupComponent
              popup={popup}
              props={{
                ...props,
                ...{
                  handleFinish,
                  onFinish: handleFinish,
                  onFinishHandler: handleFinish,
                  onSubmit: handleFinish,
                  onSuccess: handleFinish,

                  handleClose,
                  onCancel: handleClose,
                  onClose: handleClose,
                  onCancelHandler: handleClose,
                  onCloseHandler: handleClose,
                },
              }}
            />
          </Modal>
        );
      })}
    </>
  );
}
