import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading } from "@mcafee/pegasus";
import { subscriptionInitiatedEvent } from "../../utils";

import {
  ANALYTICS_HOME,
  SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_COUNTER_STATE,
} from "../../constants";

import "./preExpiryPopup.scss";

const _S = (str) => `popups.preexpiry.${str}`;

const PreExpiryPopUp = ({ onFinish, renewUrl, daysToExpiry }) => {
  const onSubscribeNow = () => {
    subscriptionInitiatedEvent(
      SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_COUNTER_STATE,
      ANALYTICS_HOME
    );
    onFinish(true);
    window.open(renewUrl, "_blank");
  };

  const getHeaderString = () => {
    let mainString;
    let secondaryString;
    let secondaryStringValues = {};

    if (daysToExpiry > 1) {
      mainString = "header";
      secondaryString = "period_of_days";
      secondaryStringValues = { numberOf: daysToExpiry };
    } else {
      mainString = "header_tomorrow";
      secondaryString = "period_tomorrow";
    }

    return (
      <String
        id={_S(mainString)}
        values={{
          period: (
            <span className="text-danger ml-8">
              <String id={_S(secondaryString)} values={secondaryStringValues} />
            </span>
          ),
        }}
      />
    );
  };

  return (
    <div className="preExpiryPopup">
      <Heading
        className="preExpiryPopup__header"
        size="xs"
        data-testid="PreExpiryPopUpHeader"
      >
        {getHeaderString()}
      </Heading>

      <Paragraph
        className="preExpiryPopup__content"
        size="sm"
        data-testid="PreExpiryPopUpContent"
      >
        <String id={_S`content`} />
      </Paragraph>

      <div className="preExpiryPopup__buttons">
        <Button
          className="mx-8"
          onClick={onSubscribeNow}
          variant="primary"
          id="PreExpiryPopUpBtn1"
          data-testid="PreExpiryPopUpBtn1"
          size="sm"
        >
          <String id={_S`button1`} />
        </Button>
        <Button
          className="mx-8"
          onClick={(e) => {
            onFinish(true);
          }}
          variant="tertiary"
          id="PreExpiryPopUpBtn2"
          data-testid="PreExpiryPopUpBtn2"
          size="sm"
        >
          <String id={_S`button2`} />
        </Button>
      </div>
    </div>
  );
};

export default PreExpiryPopUp;
