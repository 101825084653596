import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { String } from "../../l10n/loader";
import { ANALYTICS_CARD } from "../../constants/analytics";

// DOM
import {
  Card,
  Grid,
  Row,
  Col,
  Button,
  Icon,
  Paragraph,
  Heading,
} from "@mcafee/pegasus";

import DashboardCardHeader from "./DashboardCardHeader";

// Store
import { dashboardDismissCard } from "../../store/dashboard";
import { rescheduleCard } from "../../store/thunks/auth";

import "./DashboardCard.scss";
import { StyledCardBody, StyledCardContent } from "../styledComponents";
import { SM_VIEWPORT_SIZE, TRUE_S } from "../../constants/main";
import { cardAnalytics } from "../DashboardCards/cardsMap";
import { collectScreenLoadEvent } from "../../utils";

export default function DashboardCard({
  id,
  backgroundImage,
  stringValues = {},
  tagText = "",
  primaryActionStringId = "tellMeMore",
  onPrimaryAction,
  primaryActionLoading = false,
  primaryActionId,
  primaryActionDataTestId,
  primaryActionDisabled = false,
  dismissDataTestId = null,
  showDismiss = false,
  children,
}) {
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth <= SM_VIEWPORT_SIZE
  );
  const dispatch = useDispatch();

  const dismissCard = (e) => {
    dispatch(rescheduleCard(id));
    dispatch(dashboardDismissCard(id));
  };

  useEffect(() => {
    //post card view analytics event
    const {
      hit_screen,
      hit_feature,
      hit_category_0,
      hit_label_1, //NA
      hit_label_3 = "dashboard", //screen_type
      hit_label_5 = ANALYTICS_CARD,
      hit_label_8,
    } = cardAnalytics(id);

    if (hit_screen) {
      collectScreenLoadEvent({
        hit_screen,
        hit_feature,
        hit_category_0,
        hit_label_1,
        hit_label_3,
        hit_label_5,
        hit_label_8,
      });
    }

    function handleResize() {
      setIsSmallScreen(window.innerWidth <= SM_VIEWPORT_SIZE);
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, [id]);

  const sanitizedCardId = id.replace(
    /[_|-]([a-zA-Z])/g,
    (_, letterAfterUnderscore) => letterAfterUnderscore.toUpperCase()
  );

  const localePrefix = `dashboard.${sanitizedCardId}`;

  return (
    <StyledCardBody className="pa-36" id={`card_${id}`}>
      <Card style={{ overflow: "visible" }}>
        <Grid fluid>
          <Row>
            {backgroundImage && !isSmallScreen && (
              <Col xs={12} sm={4}>
                <img
                  className="image-responsive pa-24"
                  src={backgroundImage}
                  alt="dashboard card"
                  style={{ objectFit: "contain" }}
                />
              </Col>
            )}
            <Col xs={12} sm={backgroundImage ? 8 : 12} className="pa-24">
              <StyledCardContent>
                <DashboardCardHeader id={id} tagText={tagText} />
                {isSmallScreen && backgroundImage && (
                  <img
                    className="image-responsive pa-24"
                    src={backgroundImage}
                    alt="dashboard card"
                    style={{ height: 200, objectFit: "contain" }}
                  />
                )}
                <div className="mt-8 mb-24">
                  <Heading
                    className="mb-8"
                    size="sm"
                    data-testid={`${sanitizedCardId}Header`}
                  >
                    <String
                      id={`${localePrefix}.header`}
                      values={stringValues}
                    />
                  </Heading>

                  <Paragraph
                    size="sm"
                    data-testid={`${sanitizedCardId}Content`}
                  >
                    <String
                      id={`${localePrefix}.content`}
                      values={stringValues}
                    />
                  </Paragraph>
                </div>
                {children}
                <div
                  className={`flex direction-column ${
                    isSmallScreen ? `align-items-stretch` : `align-items-end`
                  }`}
                >
                  <Button
                    className="mr-8"
                    size="sm"
                    onClick={onPrimaryAction}
                    loading={primaryActionLoading ? TRUE_S : undefined}
                    disabled={primaryActionDisabled}
                    id={primaryActionId || `${sanitizedCardId}TellMeMore`}
                    data-testid={`${
                      primaryActionDataTestId || `${sanitizedCardId}TellMeMore`
                    }`}
                  >
                    <String id={`dashboard.${primaryActionStringId}`} />
                  </Button>
                  {showDismiss && (
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={dismissCard}
                      id={`${sanitizedCardId}Dismiss`}
                      data-testid={
                        dismissDataTestId || `${sanitizedCardId}Dismiss`
                      }
                    >
                      <Icon icon="close-outline" size="sm" className="mr-12" />
                      <String id="dashboard.dismiss" />
                    </Button>
                  )}
                </div>
              </StyledCardContent>
            </Col>
          </Row>
        </Grid>
      </Card>
    </StyledCardBody>
  );
}
