import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading, Link } from "@mcafee/pegasus";

import "./removeEmailPopup.scss";
import { DeleteForeverOutlined } from "@material-ui/icons";

const RemoveEmailPopup = ({ email, onFinish, onCancel }) => {
  const handleRemoveEmail = () => {
    onFinish(true);
  };

  const handleClose = (e) => {
    onCancel();
  };

  const _S = (str) => `popups.removeEmailAddress.${str}`;

  return (
    <div className="removeEmailPopup__container">
      <Heading
        className="removeEmailPopup__title"
        size="xs"
        data-testid="removeEmailAddressPopUpHeader"
      >
        <String id={_S`header`} />
      </Heading>

      {email && <Link className="removeEmailPopup__email">{email}</Link>}

      <Paragraph size="sm" data-testid="removeEmailAddressPopupContent">
        <String id={_S`content`} />
      </Paragraph>

      <div className="removeEmailPopup__buttons">
        <Button
          onClick={handleRemoveEmail}
          kind="danger"
          id="removeEmailDeleteButton"
          data-testid="removeEmailDeleteButton"
          size="sm"
        >
          <DeleteForeverOutlined className="buttonIcon" />
          <String id={_S`removeEmailButton`} />
        </Button>
        <Button
          variant="tertiary"
          onClick={handleClose}
          id="removeEmailCancel"
          data-testid="removeEmailCancelButton"
          size="sm"
        >
          <String id={_S`cancel`} />
        </Button>
      </div>
    </div>
  );
};

export default RemoveEmailPopup;
