import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading } from "@mcafee/pegasus";

import "./avDeleteFilePopup.scss";

const AvDeleteFilePopup = ({ index, onCancel, onDelete }) => {
  const _S = (str) => `popups.deleteItemPopup.${str}`;

  return (
    <div className="avDeleteFilePopup">
      <Heading
        className="avDeleteFilePopup__title"
        size="xs"
        data-testid="avDeleteFilePopupHeader"
      >
        <String id={_S`header`} />
      </Heading>

      <Paragraph
        className="avDeleteFilePopup__content"
        size="sm"
        data-testid="avDeleteFilePopupContent"
      >
        <String id={_S`content`} />
      </Paragraph>
      <Paragraph
        className="avDeleteFilePopup__content"
        size="sm"
        data-testid="avDeleteFilePopupContent"
      >
        <String id={_S`info`} />
      </Paragraph>
      <div className="avDeleteFilePopup__buttons">
        <Button
          size="sm"
          onClick={() => {
            onDelete(index);
            onCancel(index);
          }}
          id="avDeleteFile"
          data-testid="deleteFileButton"
          shape="pill"
          variant="primary"
        >
          <String id={_S`deleteButton`} />
        </Button>
        <Button
          className="ml-32"
          onClick={onCancel}
          id="avDeleteFileCancel"
          variant="tertiary"
          data-testid="avDeleteFileCancelButton"
          size="sm"
        >
          <String id={_S`cancelButton`} />
        </Button>
      </div>
    </div>
  );
};

export default AvDeleteFilePopup;
