import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading, Label, Input } from "@mcafee/pegasus";

import "./addressedBreachPopup.scss";
import { analyzeBreachDetails } from "../../utils";
import { BREACH_ASSETS } from "../../constants";
import {
  FULL_NAME,
  LAST_NAME,
  DOB,
  ADDRESS,
  CREDIT_CARD,
  SSN,
  PHONE_NUMBER,
  FIRST_NAME,
} from "../../constants/main";

const AddressedBreachPopup = ({ breach, onCancel }) => {
  const _S = (str) => `popups.addressedBreach.${str}`;
  const { assetsStolen } = analyzeBreachDetails(breach);
  return (
    <div className="addressedBreachPopup">
      <Heading size="xs" className="addressedBreachPopup__title">
        {breach.title || <String id={_S`header`} />}
      </Heading>
      {/* currently resolve date is not available */}
      {/* <Paragraph
        size="sm"
        data-testid="addressedBreachPopupCaption"
        className="addressedBreachPopup__caption"
      >
        <String id={_S`caption`} />
      </Paragraph> */}
      <Label size="sm" className="addressedBreachPopup__subheader">
        <String id={_S`breachInfoHeader`} />
      </Label>
      {assetsStolen.map((asset) => {
        switch (asset) {
          case BREACH_ASSETS.emailAddress.label:
            return (
              <Input
                key={asset}
                icon={BREACH_ASSETS.emailAddress.icon}
                readOnly
                className="addressedBreachPopup__assetList"
                value={breach.email}
              />
            );

          case BREACH_ASSETS.plaintextPassword.label:
            return (
              <Input
                key={asset}
                icon={BREACH_ASSETS.plaintextPassword.icon}
                className="addressedBreachPopup__assetList"
                readOnly
                value="************"
              />
            );
          case BREACH_ASSETS.hashedPassword.label:
            return (
              <Input
                key={asset}
                icon={BREACH_ASSETS.hashedPassword.icon}
                className="addressedBreachPopup__assetList"
                readOnly
                value="************"
              />
            );
          case BREACH_ASSETS.fullName.label:
            return (
              <Input
                key={asset}
                icon={BREACH_ASSETS.fullName.icon}
                className="addressedBreachPopup__assetList"
                readOnly
                value={FULL_NAME}
              />
            );
          case BREACH_ASSETS.firstName.label:
            return (
              <Input
                key={asset}
                icon={BREACH_ASSETS.fullName.icon}
                className="addressedBreachPopup__assetList"
                readOnly
                value={FIRST_NAME}
              />
            );
          case BREACH_ASSETS.lastName.label:
            return (
              <Input
                key={asset}
                icon={BREACH_ASSETS.fullName.icon}
                className="addressedBreachPopup__assetList"
                readOnly
                value={LAST_NAME}
              />
            );
          case BREACH_ASSETS.dob.label:
            return (
              <Input
                key={asset}
                icon={BREACH_ASSETS.dob.icon}
                className="addressedBreachPopup__assetList"
                readOnly
                value={DOB}
              />
            );
          case BREACH_ASSETS.address.label:
            return (
              <Input
                key={asset}
                icon={BREACH_ASSETS.address.icon}
                className="addressedBreachPopup__assetList"
                readOnly
                value={ADDRESS}
              />
            );
          case BREACH_ASSETS.phoneNumber.label:
            return (
              <Input
                key={asset}
                icon={BREACH_ASSETS.phoneNumber.icon}
                className="addressedBreachPopup__assetList"
                readOnly
                value={PHONE_NUMBER}
              />
            );
          case BREACH_ASSETS.creditCardNumber.label:
            return (
              <Input
                key={asset}
                icon={BREACH_ASSETS.creditCardNumber.icon}
                className="addressedBreachPopup__assetList"
                readOnly
                value={CREDIT_CARD}
              />
            );
          case BREACH_ASSETS.ssnNumber.label:
            return (
              <Input
                key={asset}
                icon={BREACH_ASSETS.ssnNumber.icon}
                className="addressedBreachPopup__assetList"
                readOnly
                value={SSN}
              />
            );
          default:
            return null;
        }
      })}

      <Label size="sm" className="addressedBreachPopup__subheader">
        <String id={_S`whatShouldIDo`} />
      </Label>

      <Paragraph
        size="sm"
        data-testid="addressedBreachPopupContent1"
        className="addressedBreachPopup__content"
      >
        <String id={_S`content1`} />
      </Paragraph>
      <Paragraph
        size="sm"
        data-testid="addressedBreachPopupContent2"
        className="addressedBreachPopup__content"
      >
        <String id={_S`content2`} />
      </Paragraph>
      <div className="addressedBreachPopup__buttonBar">
        <Button
          onClick={onCancel}
          variant="secondary"
          id="addressedBreachPopupClose"
          data-testid="addressedBreachPopupCloseButton"
          size="sm"
          className="addressedBreachPopup__buttonBar__button"
        >
          <String id="popups.close" />
        </Button>
      </div>
    </div>
  );
};

export default AddressedBreachPopup;
