// React
import React from "react";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import PpsSoftwareOutdatedImage from "../_assets/images/dashboard/cards/ppsOutdated.svg";

// utils
import { RECOMMENDED } from "../../constants/main";

export default function PpsSoftwareOutdated({ id }) {
  const card = {
    id: id,
    backgroundImage: PpsSoftwareOutdatedImage,
    tagText: RECOMMENDED,
    primaryActionStringId: "updateNow",
    primaryActionDataTestId: "ppsSoftwareOutdatedUpdateNow",
    onPrimaryAction: () => {},
  };

  return <DashboardCard {...card} />;
}
