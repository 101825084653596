import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toastList: [],
  lastToastsResults: null,
};

const slice = createSlice({
  name: "@toasts",
  initialState,
  reducers: {
    addToast: (state, action) => {
      state.toastList = [...state.toastList, action.payload];
      state.lastToastsResults = null;
    },
    removeToast: (state, action) => {
      const { id, results } = action.payload;
      state.toastList = state.toastList.filter((item) => item.props.id !== id);
      state.lastToastsResults = results;
    },
  },
  extraReducers: {},
});

export const { addToast, removeToast } = slice.actions;

export default slice.reducer;
