//ROUTE_VPN_SETUP_RUN

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { String } from "../../l10n/loader";

// Store
import { setupVpn } from "../../store/thunks/vpn";

import { Animator, Display, Paragraph, Button } from "@mcafee/pegasus";
import ovel_loader from "../../components/_assets/animations/oval_loader/oval_loader.json";

import VpnSetupFailureMessage from "./VpnSetupFailureMessage";
import VpnSetupErrorMessage from "./VpnSetupErrorMessage";
import { ViewPortCenter } from "../Widgets/ViewPortCenter";
import { uwpSendTelemetry } from "../../uwp";
import {
  ANALYTICS_PROGRESS,
  ANALYTICS_VPN,
  EVENTID_PPS_VPN_SETUP_CANCEL,
  ANALYTICS_MANUAL,
  ANALYTICS_FAILURE,
  ANALYTICS_ERROR,
} from "../../constants/analytics";
import {
  SCREEN_VPN_SETUP_ERROR_RETRY,
  SCREEN_VPN_SETUP_COMPLETE,
  SCREEN_VPN_SETUP_ERROR_FAILED,
} from "../../constants/analyticsScreens";
import { MAX_ERROR_RETRY, VPN_SETUP_RUN } from "../../constants/main";
import {
  collectScreenLoadEvent,
  getScreenCategory,
  getScreenType,
  getTrigger,
} from "../../utils";
import { useIsSignedIn } from "../../utils";
import Unauthorized from "../Shared/Unauthorized";

import { v4 as uuidv4 } from "uuid";
import { DialogLayout } from "../layouts/DialogLayout";

import image from "../../components/_assets/images/vpnSetup/wifiConnected.svg";
import { navigateBack } from "../../store/thunks/app";

const VpnSetupRun = ({
  //...state.vpn
  progress,
  error,
  vpnSetup,
  //Dispatches
  setupVpn,
  navigateBack,
}) => {
  const localePrefix = "vpn.";
  const [retryAttempt, setRetryAttempt] = useState(0);
  const [isSetupEventSent, setIsSetupEventSent] = useState(undefined);
  const [isErrorEventSent, setIsErrorEventSent] = useState(undefined);
  const [isFailureEventSent, setIsFailureEventSent] = useState(undefined);
  const hit_guid = uuidv4().toUpperCase();

  useEffect(() => {
    if (vpnSetup && !isSetupEventSent) {
      collectScreenLoadEvent({
        hit_feature: ANALYTICS_VPN,
        hit_trigger: getTrigger(SCREEN_VPN_SETUP_COMPLETE),
        hit_category_0: getScreenCategory(SCREEN_VPN_SETUP_COMPLETE),
        hit_screen: SCREEN_VPN_SETUP_COMPLETE,
        hit_label_3: ANALYTICS_PROGRESS,
      });

      setIsSetupEventSent(true);
    }
  }, [vpnSetup, isSetupEventSent]);

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  const onClickGetStarted = () => {
    navigateBack({ steps: 3 });
  };

  const onRetry = (e) => {
    setRetryAttempt(retryAttempt + 1);
    if (retryAttempt < MAX_ERROR_RETRY) {
      const trigger = "retry";
      setupVpn({ trigger });
      if (!isErrorEventSent) {
        collectScreenLoadEvent({
          hit_feature: ANALYTICS_VPN,
          hit_trigger: getTrigger(SCREEN_VPN_SETUP_ERROR_RETRY),
          hit_category_0: getScreenCategory(SCREEN_VPN_SETUP_ERROR_RETRY),
          hit_label_0: ANALYTICS_FAILURE, // Capture screen load is success or fail 1- success/ 0- failure
          hit_screen: SCREEN_VPN_SETUP_ERROR_RETRY,
          hit_label_3: getScreenType(SCREEN_VPN_SETUP_ERROR_RETRY),
          hit_label_6: ANALYTICS_ERROR,
        });

        setIsErrorEventSent(true);
      }
    } else if (!isFailureEventSent) {
      collectScreenLoadEvent({
        hit_feature: ANALYTICS_VPN,
        hit_trigger: getTrigger(SCREEN_VPN_SETUP_ERROR_FAILED),
        hit_category_0: getScreenCategory(SCREEN_VPN_SETUP_ERROR_FAILED),
        hit_label_0: ANALYTICS_FAILURE, // Capture screen load is success or fail 1- success/ 0- failure
        hit_screen: SCREEN_VPN_SETUP_ERROR_FAILED,
        hit_label_3: getScreenType(SCREEN_VPN_SETUP_ERROR_FAILED),
        hit_label_6: ANALYTICS_ERROR,
      });

      setIsFailureEventSent(true);
    }
  };

  const onGotIt = (e) => {
    navigateBack({ steps: 3 });
  };

  const onDoItLater = (e) => {
    uwpSendTelemetry(EVENTID_PPS_VPN_SETUP_CANCEL, {
      hit_trigger: ANALYTICS_MANUAL,
      hit_screen: SCREEN_VPN_SETUP_ERROR_RETRY,
      hit_label_0: ANALYTICS_FAILURE,
      hit_label_2: ANALYTICS_MANUAL,
      hit_label_1: ANALYTICS_MANUAL,
      hit_guid,
    });
    navigateBack({ steps: 3 });
  };

  const SettingUp = () => {
    return (
      <ViewPortCenter>
        <Animator src={ovel_loader} style={{ width: 100, height: 100 }} />
        <div className="mt-32" data-testid="VpnSettingUp">
          <String id={`${localePrefix}settingUp.content`} />
        </div>
      </ViewPortCenter>
    );
  };

  const SetupSuccess = () => {
    return (
      <DialogLayout
        pageId={VPN_SETUP_RUN}
        image={image}
        showBack={false}
        animate={true}
        buttons={
          <>
            {" "}
            <Button onClick={onClickGetStarted} id="VpnSetupGotIt" size="sm" data-testid="VpnSetupGotItButton">
              <String id={`${localePrefix}gotIt`} />
            </Button>
          </>
        }
      >
        <Display size="xs" data-testid="VpnHeader">
          <String
            id={`${localePrefix}setupDone.header`}
            values={{ br: <br /> }}
          />
        </Display>
        <Paragraph size="lg" className="text-secondary mt-40">
          <String id={`${localePrefix}setupDone.content_part1`} />
        </Paragraph>
      </DialogLayout>
    );
  };

  const ErrorScreen = ({ retryAttempt }) => {
    if (retryAttempt >= 3) {
      return (
        <VpnSetupFailureMessage
          {...{
            localePrefix,
            error,
            onGotIt,
          }}
        />
      );
    }

    return (
      <VpnSetupErrorMessage
        {...{
          error,
          onRetry,
          onDoItLater,
        }}
      />
    );
  };

  return (
    <>
      {progress ? (
        <SettingUp />
      ) : vpnSetup ? (
        <SetupSuccess />
      ) : (
        <ViewPortCenter>
          <ErrorScreen {...{ retryAttempt }} />
        </ViewPortCenter>
      )}
    </>
  );
};

export default connect(
  (state) => ({
    ...state.vpn,
    historyIndex: state.context.historyIndex,
  }),
  { push, navigateBack, setupVpn }
)(VpnSetupRun);
