// React
import React from "react";
// DOM
import {
 // Button,
  Paragraph,
 // Icon,
 // Popover,
  //PopoverHandler,
 // PopoverContent,
 // PopoverItem,
 // Link,
  Label,
} from "@mcafee/pegasus";

// utils
//import { createFormattedId } from "../../utils";
//import { dashboardDismissCard } from "../../store/dashboard";
import {
//  REMINDE_TOMORROW,
 // REMINDE_NEXT_WEEK,
  RECOMMENDED,
  LEARNING,
  NEEDS_ATTENTION,
} from "../../constants/main";
import { String } from "../../l10n/loader";
//import { rescheduleCard } from "../../store/thunks/auth";

/* This is a commonPlace for rendering the header section of the Dashboard cards
tagText: displays the timer with 2 mins text. */
export default function DashboardCardHeader({ id, tagText }) {
  const localePrefix = "dashboard.";
  let tagType = "text-danger";
  // const onClickTomorrow = (e) => {
  //   rescheduleCard(id, REMINDE_TOMORROW);
  //   dashboardDismissCard(id);
  // };

  // const onClickNextWeek = (e) => {
  //   rescheduleCard(id, REMINDE_NEXT_WEEK);
  //   dashboardDismissCard(id);
  // };

  switch (tagText) {
    case RECOMMENDED:
      tagType = "text-decorative";
      break;
    case LEARNING:
      tagType = "text-info";
      break;
    default:
    case NEEDS_ATTENTION:
      tagType = "text-danger";
      break;
  }

  return (
    <div className="flex align-items-center">
      {tagText && (
        <Paragraph
          className="flex align-items-center text-gray-light"
          size="xs"
        >
          <Label className={tagType} size="sm">
            <String id={`${localePrefix}${tagText}`} />
          </Label>
        </Paragraph>
      )}
      {/* commenting out overflow menu for Beta release */}
      {/* <Popover
        className="dashboard-card-header__menu ml-auto"
        position="bottom-right"
      >
        <PopoverHandler>
          <Button variant="tertiary" icon size="sm">
            <Icon icon="more-options-outline" />
          </Button>
        </PopoverHandler>
        <PopoverContent>
          <PopoverItem>
            <Paragraph
              className="text-gray-light"
              id={`${createFormattedId(id)}RemindMe`}
              size="xs"
            >
              <String id={`${localePrefix}remindMe`} />
            </Paragraph>
          </PopoverItem>
          <PopoverItem
            onClick={onClickTomorrow}
            id={`${createFormattedId(id)}Tomorrow`}
          >
            <Link size="sm">
              <Paragraph size="sm">
                <String id={`${localePrefix}tomorrow`} />
              </Paragraph>
            </Link>
          </PopoverItem>
          <PopoverItem
            id={`${createFormattedId(id)}NextWeek`}
            onClick={onClickNextWeek}
          >
            <Link>
              <Paragraph size="sm">
                <String id={`${localePrefix}nextWeek`} />
              </Paragraph>
            </Link>
          </PopoverItem>
          <PopoverItem
            id={`${createFormattedId(id)}Dismiss`}
            onClick={() => {}}
          >
            <Link className="text-gray-light">
              <Paragraph size="sm">
                <String id={`${localePrefix}dismiss`} />{" "}
                <Icon
                  icon="close-outline"
                  inline={true}
                  className="ml-64"
                ></Icon>
              </Paragraph>
            </Link>
          </PopoverItem>
        </PopoverContent>
      </Popover> */}
    </div>
  );
}
