import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Store
import { signOut } from "../../store/thunks/auth";

import { POPUP_CONFIRM_SIGNOUT } from "../PopUps/constants";
// DOM
import {
  SidebarNavListItem,
  SidebarNavListButton,
  Label,
  Button,
} from "@mcafee/pegasus";
import "./Logout.scss";
import { replace } from "connected-react-router";
import { ROUTE_ACCOUNT } from "../app/routes";
import { String } from "../../l10n/loader";
import { showPopup } from "../../store/thunks/popups";
import { BANNER_NOTIFICATION } from "../Toasts/constants";
import { showToast } from "../../store/thunks/toasts";
import { makeCancelable, navToErrorPage } from "../../utils";
import { FORCE, MAX_ERROR_RETRY } from "../../constants";
import { ExitToAppRounded } from "@material-ui/icons";

export default function Logout({ type = "navbar" }) {
  const _S = (str) => `logout.${str}`;
  const [retryAttempt, setRetryAttempt] = useState(0);
  const [signOutPromise, setSigningOutPromise] = useState();

  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.accessToken);

  useEffect(() => {
    return () => {
      if (signOutPromise) {
        signOutPromise.cancel();
      }
    };
  }, [signOutPromise]);

  const trySignOut = async (attempt = 0) => {
    const signOutPromise = makeCancelable(dispatch(signOut()));

    setSigningOutPromise(signOutPromise);

    const response = await signOutPromise.promise;

    if (response.ok === true) {
      dispatch(
        replace({
          pathname: ROUTE_ACCOUNT,
        })
      );
      return;
    }

    if (attempt < MAX_ERROR_RETRY) {
      dispatch(
        showToast(BANNER_NOTIFICATION, {
          stringId: "toasters.banner.failedToSignOut",
          selfDestruct: false,
        })
      ).then((retry) => {
        if (retry) {
          setRetryAttempt(attempt + 1);
          trySignOut(attempt + 1);
        } else {
          //Dismiss
          setRetryAttempt(0);
        }
      });
    } else {
      navToErrorPage({
        ...response.error,
        content: "sign_out",
      });
    }
  };

  const onClickConfirmSignOut = async (e) => {
    if (!accessToken) {
      //Already signed out
      dispatch(
        replace({
          pathname: ROUTE_ACCOUNT,
        })
      );

      return;
    }
    if (await dispatch(showPopup(POPUP_CONFIRM_SIGNOUT))) {
      trySignOut(retryAttempt);
    }
  };

  const onClickSignOutButton = async (e) => {
    if (!accessToken) {
      //Already signed out
      dispatch(
        replace({
          pathname: ROUTE_ACCOUNT,
        })
      );
      return;
    }
    dispatch(signOut(FORCE)).then(() => {
      dispatch(
        replace({
          pathname: ROUTE_ACCOUNT,
        })
      );
    });
  };

  if (type === "button") {
    return (
      <Button
        onClick={onClickSignOutButton}
        id="SubscriptionSignOut"
        data-testid="subscriptionTrialExpiredPopupSignOutButton"
        size="sm"
        variant="tertiary"
      >
        <String id="popups.subscription.signOut" />
      </Button>
    );
  }

  return (
    <SidebarNavListItem tabIndex="0">
      <SidebarNavListButton
        onClick={onClickConfirmSignOut}
        data-testid="LogoutLink"
        id="Sign Out"
      >
        <ExitToAppRounded className="settingsMenu__icon mr-8" />
        <Label size="sm">
          <String id={accessToken ? _S`signOut` : `messages.signIn`} />
        </Label>
      </SidebarNavListButton>
    </SidebarNavListItem>
  );
}
