import React from "react";
import { String } from "../../l10n/loader";

import { Heading, Icon, Label, Paragraph, Button } from "@mcafee/pegasus";

import { POPUP_TELL_ME_MORE } from "../PopUps/constants";
import { TELL_MORE_VPN } from "../../constants/main";
import { useDispatch } from "react-redux";
import { showPopup } from "../../store/thunks/popups";

import "./fiveReasonsPopup.scss";
import {
  BlockRounded,
  LocationOffOutlined,
  VisibilityOffOutlined,
} from "@material-ui/icons";

export default function FiveReasonsModal({ onFinish }) {
  const items = [
    { text: "passwords", icon: "lock-atlas-outline" },
    {
      text: "bankingAndPayment",
      icon: "card-atlas-outline",
    },
    {
      text: "onlineActivity",
      icon: "visibility_off",
    },
    {
      text: "privacy",
      icon: "block",
    },
    {
      text: "location",
      icon: "location_off",
    },
  ];

  const dispatch = useDispatch();

  const _S = (str) => `vpn.modal.fiveReasons.${str}`;

  const handleTellMeMore = async (e) => {
    await dispatch(
      showPopup(POPUP_TELL_ME_MORE, {
        source: TELL_MORE_VPN,
        showSecondaryButton: false,
      })
    );
    onFinish();
  };

  const renderIcon = (icon) => {
    switch (icon) {
      case "visibility_off":
        return <VisibilityOffOutlined className="text-info" />;
      case "block":
        return <BlockRounded className="text-info" />;
      case "location_off":
        return <LocationOffOutlined className="text-info" />;
      default:
        return <Icon icon={icon} className="text-info" />;
    }
  };

  return (
    <div className="fiveReasonsPopup">
      <Heading size="xs" className="fiveReasonsPopup__title">
        <String id={_S`title`} />
      </Heading>

      {items.map(({ text, icon, material }, index) => (
        <div
          className="fiveReasonsPopup__reasonContainer"
          key={`${index}Reason`}
        >
          {renderIcon(icon)}
          <div className="fiveReasonsPopup__reasonContainer__text">
            <Label size="sm" className="text-body">
              <String id={_S(text)} />
            </Label>
            <Paragraph size="sm">
              <String id={_S(`${text}.text`)} />
            </Paragraph>
          </div>
        </div>
      ))}
      <div className="text-right">
        <Button variant="secondary" onClick={handleTellMeMore} size="sm" data-testid="TellMeMoreButton">
          <String id={_S`tellMeMore`} />
        </Button>
      </div>
    </div>
  );
}
