// React
import React from "react";
import { useDispatch } from "react-redux";

// DOM
import {
  Carousel,
  CarouselBrand,
  CarouselButtonNext,
  Button,
  Col,
  Grid,
  Heading,
  Icon,
  Link,
  Paragraph,
  Row,
} from "@mcafee/pegasus";
import carouselImage1 from "../../components/_assets/images/onboard/welcome/onboardingFirstSlide.svg";
import carouselImage2 from "../../components/_assets/images/onboard/welcome/onboardingSecondSlide.svg";
import carouselImage3 from "../../components/_assets/images/onboard/welcome/onboardingThirdSlide.svg";

// Store
import { publishEvent } from "../../store/thunks/experience";

// Custom components

// utils
import events from "../../experience/events";
import { String } from "../../l10n/loader";
import { showPopup } from "../../store/thunks/popups";
import { POPUP_ONBOARD_KNOWMORE } from "../PopUps/constants";

import "./OnboardWelcome.scss";

export default function OnboardWelcome() {
  const localePrefix = "onboard.welcome.";
  const carouselImages = [carouselImage1, carouselImage2, carouselImage3];

  const dispatch = useDispatch();

  const handleNextClick = (page) => {
    dispatch(publishEvent(events.welcome[page].next));
  };

  const handleSkipClick = () => {
    dispatch(publishEvent(events.welcome[3].next));
  };

  const handleKnowMoreClick = () => {
    dispatch(showPopup(POPUP_ONBOARD_KNOWMORE));
  };

  return (
    <div className="welcomeCarousel">
      <Carousel className="onboardCarousel__step" paginated>
        {[1, 2, 3].map((page) => (
          <div key={page}>
            <Grid className="welcomeCarousel__grid">
              <Row className="welcomeCarousel__row">
                <Col
                  xs={12}
                  sm={5}
                  className="flex justify-content-center direction-column"
                >
                  <div className="my-64">
                    <Heading
                      size="lg"
                      className={`mb-32 ${page === 2 ? "text-light" : ""}`}
                    >
                      <String id={`${localePrefix}title_welcome_${page}`} />
                    </Heading>

                    <Paragraph
                      size="sm"
                      className={`${page === 2 ? "text-light" : ""}`}
                    >
                      <String
                        id={`${localePrefix}description_welcome_${page}`}
                      />
                    </Paragraph>
                    {page === 3 && (
                      <Link
                        className="flex align-items-center pt-32"
                        onClick={handleKnowMoreClick}
                      >
                        <Icon icon="info-outline" size="md" className="px-12" />
                        <String id={`${localePrefix}knowMore`} />
                      </Link>
                    )}
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={7}
                  className="flex justify-content-center align-items-center"
                >
                  <img
                    src={carouselImages[page - 1]}
                    alt=""
                    className="welcomeCarousel__image"
                  />
                </Col>
              </Row>
            </Grid>
            <CarouselBrand kind={page % 2 === 0 ? `light` : `dark`} />
            {page !== 3 && (
              <Button
                kind={page % 2 === 0 ? `light` : `dark`}
                variant="tertiary"
                onClick={handleSkipClick}
                className="skipButton"
              >
                <String id={`${localePrefix}skip`} />
              </Button>
            )}
            <CarouselButtonNext
              id={`WelcomeNext${page}`}
              onClick={() => handleNextClick(page)}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
