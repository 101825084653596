// React
import React from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import backgroundImage from "../_assets/images/dashboard/cards/vpnEducate.svg";

// utils
import { LEARNING } from "../../constants/main";
import { ROUTE_SECURE_VPN, ROUTE_VPN_SETUP } from "../app/routes";

export default function VpnLearnMore({ id }) {
  const dispatch = useDispatch();
  const { vpnSetup } = useSelector((state) => state.vpn);
  const onWhatWeCanDo = () => {
    dispatch(
      push({
        pathname: vpnSetup ? ROUTE_SECURE_VPN : ROUTE_VPN_SETUP,
        showPopup: true,
      })
    );
  };

  const card = {
    id: id,
    backgroundImage,
    tagText: LEARNING,
    primaryActionStringId: "whatCanWeDo",
    onPrimaryAction: onWhatWeCanDo,
    primaryActionDataTestId: "vpnEducationWhatCanWeDo",
  };

  return <DashboardCard {...card} />;
}
