import React from "react";
import { unmountComponentAtNode, render } from "react-dom";
export const UnifiedAuthIframeId = 'unifiedAuthIframe';

function createIframe ({src}) {

	const style = {
		border: 'none',
		display: 'none',
		height: window.screen.height,
		left: 0,
		position: 'fixed',
		top: 0,
		width: window.screen.width,
		zIndex:10000
	};

	return (<iframe title="McAfee" src={src} id={UnifiedAuthIframeId} onLoad={style.display = ''} seamless style={style} />);
}

export function UnifiedAuthIframeInsert (arg) {

	render(
		createIframe(arg),
		document.getElementById("UnifiedAuthIframeContainer")
	);
}

export function UnifiedAuthIframeRemove () {

	const iframe = document.getElementById(UnifiedAuthIframeId);

	if (iframe) {
		
		unmountComponentAtNode(iframe);
	}
}

export function UnifiedAuthIframeContainer () {

	return (<div id="UnifiedAuthIframeContainer"></div>)
}