import React, { useState, useEffect, useRef } from "react";
import { LocInput, String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading, Label } from "@mcafee/pegasus";

import {
  ASSET_TYPE_EMAIL,
  DWS_MONITOR_ENABLED,
  EMAIL_MAX_LENGTH,
  EMAIL_REGEX,
} from "../../constants/main";
import { connect } from "react-redux";

const AddEmailPopUp = ({ onFinish, onCancel, assets }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [inputState, setInputState] = useState("");

  const emailInput = useRef();
  const localePrefix = "identity.";

  useEffect(() => {
    emailInput.current.focus();
  }, []);

  const handleAddingEmail = (email) => {
    onFinish(email);
  };

  const validateEmail = (email) => {
    let error = "";
    if (!email) error = "emptyError";
    else if (!EMAIL_REGEX.test(email)) error = "invalidError";
    setError(error);
    return !error.length;
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    const isValidEmail = validateEmail(e.target.value);
    const inputState = isValidEmail ? "success" : "danger";

    setInputState(inputState);
  };

  const handleSubmit = (e) => {
    if (!validateEmail(email)) return;
    else if (assets && assets.length > 0) {
      let isDuplicate = assets.some(
        (asset) =>
          asset.type === ASSET_TYPE_EMAIL &&
          asset.dws_monitor === DWS_MONITOR_ENABLED &&
          asset.asset_value === email
      );
      if (isDuplicate) {
        setError("duplicateError");
        setInputState("danger");
        return;
      }
    }
    handleAddingEmail(email);
  };

  const handleClose = (e) => {
    onCancel();
  };

  const styles = {
    popUpStyles: {
      maxWidth: 550,
    },
    logoStyles: {
      float: "center",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
    contentStyles: {
      textAlign: "left",
      display: "flex",
      justifyContent: "center",
    },
    errorStyles: {
      display: "flex",
      justifyContent: "center",
    },
  };

  return (
    <div style={styles.popUpStyles} className="pa-24 text-left">
      <Heading size="xs" data-testid="addEmailPopUpHeader">
        <String id={`${localePrefix}addEmailPopUp.header`} />
      </Heading>

      <Paragraph
        style={styles.contentStyles}
        size="md"
        className="mt-8"
        data-testid="addEmailPopupContent"
      >
        <String id={`${localePrefix}addEmailPopUp.content`} />
      </Paragraph>
      <Paragraph
        style={styles.contentStyles}
        size="md"
        className="mt-16"
        data-testid="addEmailPopupContent2"
      >
        <String id={`${localePrefix}addEmailPopUp.content2`} />
      </Paragraph>
      <div
        className="wrap mt-24"
        style={styles.contentStyles}
        data-testid="addEmailPopUpInput"
      >
        <LocInput
          innerRef={emailInput}
          kind={inputState}
          label="Email"
          icon="email-outline"
          onChange={handleInputChange}
          id="AddEmailFormEmail"
          width="normal"
          maxLength={EMAIL_MAX_LENGTH}
        >
          {`${localePrefix}addEmailPopUp.placeholder`}
        </LocInput>
      </div>
      <div style={styles.errorStyles}>
        {error && (
          <Label size="sm" className="text-danger mt-4">
            <String id={`${localePrefix}${error}`} />
          </Label>
        )}
      </div>
      <div className="mt-24 text-right">
        <Button
          onClick={handleSubmit}
          disabled={error.length || !email}
          id="AddEmailPopUpAddMail"
          data-testid="addEmailPopUpAddMail"
          size="sm"
        >
          <String id={`${localePrefix}monitorNewEmail`} />
        </Button>
        <Button
          className="ml-32"
          variant="tertiary"
          onClick={handleClose}
          id="AddEmailFormCancel"
          data-testid="addEmailPopUpCancelButton"
          size="sm"
        >
          <String id={`${localePrefix}cancel`} />
        </Button>
      </div>
    </div>
  );
};

export default connect((state) => ({
  ...state.identity,
}))(AddEmailPopUp);
