import { createBrowserHistory } from "history";
import {
  GITHUB_PAGES_DEFAULT_BASENAME,
  OLD_VER_URL_MATCH as OLDER_VER_URL_PATHNAME_MATCH,
} from "../constants/main";

const matchOlderVersionPathBase = window.location.pathname.match(
  OLDER_VER_URL_PATHNAME_MATCH
);
const basename = matchOlderVersionPathBase
  ? matchOlderVersionPathBase[0]
  : window.location.host.match(/^github/)
  ? `${
      process.env.REACT_APP_GITHUB_PAGES_BASENAME ||
      GITHUB_PAGES_DEFAULT_BASENAME
    }/`
  : "/";

export const history = createBrowserHistory({
  basename,
});
