import { createSlice } from "@reduxjs/toolkit";
import {
  VPN_STATE_ERROR,
  VPN_STATE_CONNECTING,
  VPN_STATE_DISCONNECTED,
  VPN_STATE_DISCONNECTING,
  VPN_STATE_CONNECTED,
  VPN_STATUS_FAILURE,
  VPN_STATUS_SUCCESS,
  VPN_STATUS_SETTING_UP,
  VPN_STATUS_CHECKING,
  VPN_STATUS_CONNECTING,
  VPN_STATUS_DISCONNECTING,
} from "../constants/main";
import { signOutSuccess } from "./auth";

const initialState = {
  progress: false,
  error: null,
  vpnSetup: false,
  vpnFirstSetup: false,
  state: null, //Disconnected,Disconnecting,Connecting,Connected,Error
  status: null,
  deviceActive: false,
  activateError: null,
  activateProgress: false,

  current_country: "US",
  is_data_limited: false,
  data_limit: 0,
  data_used: 0,
  start_type: null,

  fetchingVpnToken: false,
  vpnToken: null,
};

const onVpnResponse = (st, response) => {
  const {
    state,
    status,
    vpnSetup,
    current_country,
    is_data_limited,
    data_limit,
    data_used,
    start_type,
  } = response;

  status !== undefined && (st.status = status);

  if (state === VPN_STATE_ERROR) {
    if (st.state === VPN_STATE_CONNECTING) {
      st.state = VPN_STATE_DISCONNECTED;
    } else if (st.state === VPN_STATE_DISCONNECTING) {
      st.state = VPN_STATE_CONNECTED;
    }
    st.status = VPN_STATUS_FAILURE;
  } else {
    state !== undefined && (st.state = state);
  }

  vpnSetup !== undefined && (st.vpnSetup = vpnSetup);
  current_country !== undefined && (st.current_country = current_country);
  is_data_limited !== undefined && (st.is_data_limited = is_data_limited);
  data_limit !== undefined && (st.data_limit = data_limit);
  data_used !== undefined && (st.data_used = data_used);
  start_type !== undefined && (st.start_type = start_type);
};

// Reducers
const slice = createSlice({
  name: "@vpn",
  initialState,
  reducers: {
    ///////////// VPN setup
    setupVpnBegin: (state) => {
      state.progress = true;
      state.status = VPN_STATUS_SETTING_UP;
      state.error = null;
    },
    setupVpnSuccess: (state, action) => {
      state.progress = false;
      state.status = VPN_STATUS_SUCCESS;
      state.error = null;
      state.vpnSetup = true;
      state.vpnFirstSetup = true;
    },
    setupVpnFailure: (state, action) => {
      state.progress = false;
      state.status = VPN_STATUS_FAILURE;
      state.error = action.payload;
      state.vpnSetup = false;
      state.vpnFirstSetup = false;
    },
    ////////////// VPN checking
    checkVpnBegin: (state) => {
      state.progress = true;
      state.status = VPN_STATUS_CHECKING;
      state.error = null;
    },
    checkVpnSuccess: (state, action) => {
      state.progress = false;
      state.status = VPN_STATUS_SUCCESS;
      onVpnResponse(state, action.payload);
    },
    checkVpnFailure: (state, action) => {
      state.progress = false;
      state.status = VPN_STATUS_FAILURE;
      state.error = action.payload;
    },

    ///////////// VPN starting
    startVpnBegin: (state) => {
      state.progress = true;
      state.status = VPN_STATUS_CONNECTING;
      state.state = VPN_STATE_CONNECTING;
      state.error = null;
    },
    startVpnSuccess: (state, action) => {
      state.progress = false;
      state.status = VPN_STATUS_SUCCESS;
      state.state = VPN_STATE_CONNECTED;
      onVpnResponse(state, action.payload);
    },
    startVpnFailure: (state, action) => {
      state.progress = false;
      state.status = VPN_STATUS_FAILURE;
      state.state = VPN_STATE_DISCONNECTED;
      state.error = action.payload;
      onVpnResponse(state, action.payload);
    },
    ///////////// VPN stopping
    stopVpnBegin: (state) => {
      state.progress = true;
      state.status = VPN_STATUS_DISCONNECTING;
      state.state = VPN_STATE_DISCONNECTING;
      state.error = null;
    },
    stopVpnSuccess: (state, action) => {
      state.progress = false;
      state.status = VPN_STATUS_SUCCESS;
      state.state = VPN_STATE_DISCONNECTED;
      onVpnResponse(state, action.payload);
    },
    stopVpnFailure: (state, action) => {
      state.progress = false;
      state.status = VPN_STATUS_FAILURE;
      state.state = VPN_STATE_CONNECTED;
      state.error = action.payload;
    },

    ///////////// VPN callback state update
    vpnUpdateState: (state, action) => {
      if (state.progress === false) {
        onVpnResponse(state, action.payload);
      }
    },

    ///////////// VPN disable first time setup flag
    disableVPNFirstTimeSetup: (state) => {
      state.vpnFirstSetup = false;
    },

    activateVPNBegin: (state) => {
      state.activateProgress = true;
      state.activateError = null;
    },
    activateVPNSuccess: (state) => {
      state.activateProgress = false;
      state.deviceActive = true;
    },
    activateVPNFailure: (state, action) => {
      state.activateProgress = false;
      state.activateError = action.payload;
    },
    /// fetch VPN token
    vpnTokenBegin: (state) => {
      state.fetchingVpnToken = true;
    },
    vpnTokenSuccess: (state, action) => {
      state.fetchingVpnToken = false;
      state.vpnToken = action.payload;
    },
    vpnTokenFailure: (state, action) => {
      state.fetchingVpnToken = false;
      state.vpnToken = null;
      state.error = action.payload;
    },
    extraReducers: {
      [signOutSuccess]: () => {
        return initialState;
      },
    },
  },
});

export const {
  setupVpnBegin,
  setupVpnFailure,
  setupVpnSuccess,

  startVpnBegin,
  startVpnFailure,
  startVpnSuccess,

  stopVpnBegin,
  stopVpnFailure,
  stopVpnSuccess,

  checkVpnBegin,
  checkVpnFailure,
  checkVpnSuccess,

  vpnUpdateState,

  disableVPNFirstTimeSetup,

  activateVPNBegin,
  activateVPNSuccess,
  activateVPNFailure,

  vpnTokenBegin,
  vpnTokenSuccess,
  vpnTokenFailure,
} = slice.actions;

export default slice.reducer;
