// React
import React from "react";
import { connect } from "react-redux";

// Store
import { setDirtyPage } from "../../store/context";

import {
  Grid,
  Row,
  Col,
  Icon,
  Subheading,
  Paragraph,
  Label,
  Button,
  Snackbar,
} from "@mcafee/pegasus";

// utils
import { LocInput, String } from "../../l10n/loader";
import {
  FEEDBACK_POSITIVE,
  FEEDBACK_NEUTRAL,
  FEEDBACK_NEGATIVE,
  MCAFEE_SUPPORT_URL,
} from "../../constants/main";

// Custom components
import NavbarLayout from "../NavBar/NavbarLayout";
import SettingsMenu from "./SettingsMenu";
import { newGUID, delay, collectScreenLoadEvent } from "../../utils";

import "./FeedbackSettingsPage.scss";

//Analytics
import {
  EVENTID_PPS_SURVEY_COMPLETE,
  EVENTID_PPS_SUPPORT_INITIATED,
  EVENTID_PPS_SURVEY_PROMPTED,
  EVENTID_PPS_SURVEY_SKIP,
  SURVEY_RESPONSE_LANGUAGE_EN,
  ANALYTICS_LIFE_CYCLE,
  ANALYTICS_SETTING,
  ANALYTICS_MANUAL,
  ANALYTICS_SUCCESS,
  HIT_CATEGORY,
  HIT_FEATURE,
  HIT_SCREEN,
  HIT_TRIGGER,
  HIT_LABEL_0,
  HIT_LABEL_2,
  HIT_LABEL_6,
  HIT_LABEL_9,
  HIT_LABEL_10,
  HIT_LABEL_11,
  HIT_LABEL_12,
  HIT_LABEL_13,
  HIT_LABEL_14,
  HIT_LABEL_15,
  SCREEN_DETAILS,
  SCREEN_TYPE,
  ANALYTICS_PROGRESS,
  ANALYTICS_FORM,
} from "../../constants/analytics";
import { uwpSendTelemetry } from "../../uwp";

import {
  SCREEN_SETTINGS_FEEDBACK_DEFAULT,
  SCREEN_SETTINGS_FEEDBACK_GENERIC_FEEDBACK_SENT_CONFIRMATION,
  SCREEN_SETTINGS_FEEDBACK_NEGATIVE,
  SCREEN_SETTINGS_FEEDBACK_NEGATIVE_FEEDBACK_SENT_CONFIRMATION,
  SCREEN_SETTINGS_FEEDBACK_NEGATIVE_SUPPORT,
  SCREEN_SETTINGS_FEEDBACK_NEUTRAL,
  SCREEN_SETTINGS_FEEDBACK_POSITIVE,
} from "../../constants/analyticsScreens";

//images
import positiveDefaultImage from "../_assets/images/settings/positiveDefault.svg";
import positiveUnselectedImage from "../_assets/images/settings/positiveUnselected.svg";
import neutralDefaultImage from "../_assets/images/settings/neutralDefault.svg";
import neutralUnselectedImage from "../_assets/images/settings/neutralUnselected.svg";
import negativeDefaultImage from "../_assets/images/settings/negativeDefault.svg";
import negativeUnselectedImage from "../_assets/images/settings/negativeUnselected.svg";
import { FeedbackOutlined } from "@material-ui/icons";

// const FeedbackSettingsPage = () => {

const _S = (str) => {
  return `settings.feedback.${str}`;
};

const feedbackButtons = [
  {
    key: "Negative",
    value: FEEDBACK_NEGATIVE,
    defaultImage: negativeDefaultImage,
    unSelectedImage: negativeUnselectedImage,
    defaultScreen: SCREEN_SETTINGS_FEEDBACK_NEGATIVE,
  },
  {
    key: "Neutral",
    value: FEEDBACK_NEUTRAL,
    defaultImage: neutralDefaultImage,
    unSelectedImage: neutralUnselectedImage,
    defaultScreen: SCREEN_SETTINGS_FEEDBACK_NEUTRAL,
  },
  {
    key: "Positive",
    value: FEEDBACK_POSITIVE,
    defaultImage: positiveDefaultImage,
    unSelectedImage: positiveUnselectedImage,
    defaultScreen: SCREEN_SETTINGS_FEEDBACK_POSITIVE,
  },
];

class FeedbackSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableSend: true,
      selectedFeedback: "",
      feedbackSent: false,
      showSnackbar: false,
      showSpinner: false,
      surveyStartTime: Date.now(),
      negativeFeedbackSent: false,
      answer2: "",
      answer3: "",
    };
    this.answer2Ref = React.createRef();
    this.answer3Ref = React.createRef();
    this.onClickFeedbackEmojiButton =
      this.onClickFeedbackEmojiButton.bind(this);
    this.sendSurveyCompleteEvent = this.sendSurveyCompleteEvent.bind(this);
    this.onAnswer2Change = this.onAnswer2Change.bind(this);
    this.onAnswer3Change = this.onAnswer3Change.bind(this);
    this.onClickSendFeedback = this.onClickSendFeedback.bind(this);
    this.onClickNotNow = this.onClickNotNow.bind(this);
  }

  componentDidMount() {
    this.sendSurveyPromptedEvent();
    this.sendScreenLoadEvents(SCREEN_SETTINGS_FEEDBACK_DEFAULT);
  }

  componentWillUnmount() {
    //SKIP events
    if (!this.state.feedbackSent) {
      uwpSendTelemetry(EVENTID_PPS_SURVEY_SKIP, {
        [HIT_TRIGGER]: ANALYTICS_MANUAL,
        [HIT_SCREEN]: this.state.selectedFeedback
          ? feedbackButtons.find(
              (obj) => obj.value === this.state.selectedFeedback
            ).defaultScreen
          : SCREEN_SETTINGS_FEEDBACK_DEFAULT,
        [HIT_LABEL_0]: ANALYTICS_SUCCESS,
        [HIT_LABEL_9]: SURVEY_RESPONSE_LANGUAGE_EN,
        hit_guid: newGUID(),
      });
    }
  }

  sendScreenLoadEvents(screenName) {
    let screenType = ANALYTICS_FORM;
    let screenDetails;
    if (
      [
        SCREEN_SETTINGS_FEEDBACK_GENERIC_FEEDBACK_SENT_CONFIRMATION,
        SCREEN_SETTINGS_FEEDBACK_NEGATIVE_FEEDBACK_SENT_CONFIRMATION,
      ].includes(screenName)
    ) {
      screenType = ANALYTICS_PROGRESS;
      screenDetails = "feedback_sent_confirmation";
    }

    collectScreenLoadEvent({
      [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
      [HIT_CATEGORY]: ANALYTICS_SETTING,
      [HIT_TRIGGER]: ANALYTICS_MANUAL,
      [HIT_SCREEN]: screenName,
      [SCREEN_TYPE]: screenType,
      [SCREEN_DETAILS]: screenDetails,
    });
  }

  sendSurveyPromptedEvent() {
    uwpSendTelemetry(EVENTID_PPS_SURVEY_PROMPTED, {
      [HIT_TRIGGER]: ANALYTICS_MANUAL,
      [HIT_SCREEN]: SCREEN_SETTINGS_FEEDBACK_DEFAULT,
      [HIT_LABEL_0]: ANALYTICS_SUCCESS,
      [HIT_LABEL_9]: SURVEY_RESPONSE_LANGUAGE_EN,
      hit_guid: newGUID(),
    });
  }

  sendSurveyCompleteEvent() {
    uwpSendTelemetry(EVENTID_PPS_SURVEY_COMPLETE, {
      [HIT_TRIGGER]: ANALYTICS_MANUAL,
      [HIT_SCREEN]: feedbackButtons.find(
        (obj) => obj.value === this.state.selectedFeedback
      ).defaultScreen,
      [HIT_LABEL_0]: ANALYTICS_SUCCESS,
      [HIT_LABEL_6]: Date.now() - this.state.surveyStartTime,
      [HIT_LABEL_9]: SURVEY_RESPONSE_LANGUAGE_EN,
      [HIT_LABEL_10]: "How do you feel about your app so far?",
      [HIT_LABEL_11]: this.state.selectedFeedback,
      [HIT_LABEL_12]: "Why did you open the app today?",
      [HIT_LABEL_13]: this.state.answer2,
      [HIT_LABEL_14]: "Anything we should change?",
      [HIT_LABEL_15]: this.state.answer3,
      hit_guid: newGUID(),
    });
  }

  onAnswer2Change(e) {
    const answer = e.target.value;
    this.setState({
      answer2: answer,
    });
  }

  onAnswer3Change(e) {
    const answer = e.target.value;
    this.setState({
      answer3: answer,
    });
  }

  async onClickSendFeedback() {
    this.setState({
      showSpinner: true,
    });
    await delay(1000);
    this.setState({
      showSpinner: false,
    });
    if (this.state.selectedFeedback) {
      if (this.state.feedbackSent) {
        this.sendSurveyPromptedEvent();
      }
      this.setState({
        feedbackSent: true,
      });

      this.sendSurveyCompleteEvent();
    }

    if (this.state.selectedFeedback === FEEDBACK_NEGATIVE) {
      this.setState({
        negativeFeedbackSent: true,
      });
      this.sendScreenLoadEvents(
        SCREEN_SETTINGS_FEEDBACK_NEGATIVE_FEEDBACK_SENT_CONFIRMATION
      );
    } else {
      this.setState({
        enableSend: true,
      });
      this.sendScreenLoadEvents(
        SCREEN_SETTINGS_FEEDBACK_GENERIC_FEEDBACK_SENT_CONFIRMATION
      );
      this.setState({
        selectedFeedback: "",
      });
    }

    this.props.setDirtyPage(false);

    this.setState({
      showSnackbar: true,
    });

    await delay(2500);
    this.setState({
      showSnackbar: false,
    });
    this.sendScreenLoadEvents(
      this.state.selectedFeedback === FEEDBACK_NEGATIVE
        ? SCREEN_SETTINGS_FEEDBACK_NEGATIVE_SUPPORT
        : SCREEN_SETTINGS_FEEDBACK_DEFAULT
    );

    this.setState({
      answer2: "",
    });
    this.setState({
      answer3: "",
    });
    this.setState({
      surveyStartTime: Date.now(),
    });
  }

  onClickNotNow() {
    this.setState({
      selectedFeedback: "",
    });
    this.setState({
      enableSend: true,
    });
    this.setState({
      negativeFeedbackSent: false,
    });

    this.sendScreenLoadEvents(SCREEN_SETTINGS_FEEDBACK_DEFAULT);
  }

  onClickContactSupport() {
    window.open(MCAFEE_SUPPORT_URL, "_blank");
    uwpSendTelemetry(EVENTID_PPS_SUPPORT_INITIATED, {
      [HIT_TRIGGER]: ANALYTICS_MANUAL,
      [HIT_SCREEN]: SCREEN_SETTINGS_FEEDBACK_NEGATIVE_SUPPORT,
      [HIT_LABEL_0]: ANALYTICS_SUCCESS,
      [HIT_LABEL_2]: "feedback",
      hit_guid: newGUID(),
    });
  }

  onClickFeedbackEmojiButton(e) {
    this.setState({
      selectedFeedback: e.currentTarget.attributes.value.value,
    });

    this.setState({
      enableSend: false,
    });
    this.props.setDirtyPage(true);

    this.sendScreenLoadEvents(
      feedbackButtons.find(
        (obj) => obj.value === e.currentTarget.attributes.value.value
      ).defaultScreen
    );
  }

  render() {
    return (
      <NavbarLayout
        id="Settings"
        className="pa-0"
        classNameLayout="pa-0 bg-white"
      >
        <Grid>
          <Row style={{ height: "100%" }}>
            <Col sm xs={12} className="flex">
              <SettingsMenu />
            </Col>
            <Col
              sm={8}
              xs={12}
              className="flex direction-column align-items-start bg-white pa-24 grow-1"
            >
              <Subheading
                size="lg"
                className="mb-24"
                data-testid="feedbackSettingsPageTitle"
              >
                <String id={_S`title`} />
              </Subheading>
              {this.state.negativeFeedbackSent ? (
                <div className="text-center align-self-center grow-1">
                  <FeedbackOutlined className="text-gray-light mt-48 FeedbackSettingsPage__supportPageIcon" />
                  <Label size="lg" className="mt-24 text-primary">
                    <String id={_S`negative.title`} />
                  </Label>
                  <Paragraph size="md" className="mt-16 text-primary">
                    <String id={_S`negative.text1`} />
                  </Paragraph>
                  <Paragraph size="md" className="text-primary">
                    <String id={_S`negative.text2`} />
                  </Paragraph>
                </div>
              ) : (
                <div className="grow-1">
                  <Paragraph
                    size="sm"
                    className="mt-24"
                    data-testid="feedbackSettingsPageText"
                  >
                    <String id={_S`text`} />
                  </Paragraph>
                  <Label
                    size="sm"
                    className="my-16  text-primary"
                    data-testid="feedbackSettingsPageQuestion1"
                  >
                    <String id={_S`question1`} />
                  </Label>
                  <div className="flex bg-white">
                    {feedbackButtons.map((item) => (
                      <Button
                        variant="tertiary"
                        key={item.key}
                        id={`${item.key}Feedback`}
                        value={item.value}
                        onClick={this.onClickFeedbackEmojiButton}
                        className={`FeedbackSettingsPage__${item.value}Button${
                          this.state.selectedFeedback &&
                          this.state.selectedFeedback === item.key.toLowerCase()
                            ? "Selected"
                            : ""
                        }
                      `.trim()}
                      >
                        <img
                          className="image-responsive px-24 py-8"
                          src={
                            this.state.selectedFeedback &&
                            this.state.selectedFeedback !== item.value
                              ? item.unSelectedImage
                              : item.defaultImage
                          }
                          alt={`feedback ${item.value} button`}
                        />
                      </Button>
                    ))}
                  </div>
                  {this.state.selectedFeedback && (
                    <div>
                      <Label
                        size="sm"
                        className="mt-24  text-primary"
                        data-testid="feedbackSettingsPageQuestion2"
                      >
                        <String id={_S`question2`} />
                      </Label>
                      <LocInput
                        id="FeedbackReply1"
                        className="mt-16"
                        width="full"
                        innerRef={this.answer2Ref}
                        onChange={this.onAnswer2Change}
                        value={this.state.answer2}
                      >
                        settings.feedback.placeholder
                      </LocInput>
                      <Label
                        size="sm"
                        className="mt-24  text-primary"
                        data-testid="feedbackSettingsPageQuestion3"
                      >
                        <String id={_S`question3`} />
                      </Label>
                      <LocInput
                        width="full"
                        id="FeedbackReply2"
                        className="mt-16"
                        innerRef={this.answer3Ref}
                        onChange={this.onAnswer3Change}
                        value={this.state.answer3}
                      >
                        settings.feedback.placeholder
                      </LocInput>
                    </div>
                  )}
                </div>
              )}

              <div className="align-self-end mt-40 flex">
                {this.state.showSnackbar ? (
                  <div className="text-right">
                    <Snackbar kind="success" size="lg">
                      <Paragraph size="md">
                        <String id={_S`snackbarText`} />
                      </Paragraph>
                    </Snackbar>
                  </div>
                ) : (
                  <>
                    {this.state.negativeFeedbackSent ? (
                      <div className="text-right">
                        <Button
                          variant="primary"
                          size="sm"
                          className="mr-12"
                          onClick={this.onClickContactSupport}
                          id="ContactSupportButton"
                        >
                          <String id={_S`negative.contactSupport`} />
                        </Button>
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={this.onClickNotNow}
                          id="NotNowButton"
                        >
                          <String id={_S`negative.notNow`} />
                        </Button>
                      </div>
                    ) : (
                      <div className="text-right">
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={this.onClickSendFeedback}
                          disabled={this.state.enableSend}
                          id="SendFeedbackButton"
                        >
                          {this.state.showSpinner ? (
                            <Icon icon="loading-outline" size="sm" />
                          ) : (
                            <String id={_S`sendFeedback`} />
                          )}
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Grid>
      </NavbarLayout>
    );
  }
}
export default connect((state) => ({}), { setDirtyPage })(FeedbackSettingsPage);
