import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Paragraph, Heading, Button } from "@mcafee/pegasus";
import accountCreationComplete from "../_assets/images/onboard/accountCreationComplete.svg";
import "./wssPopup.scss";
import { uwpInvoke } from "../../uwp";

export default function WssPopUp({ wssVariation, onCancel }) {
  const _S = (str) => `popups.wssPopUp.${str}`;
  const redirectToWss = () => {
    //redirect to Wss variation
    uwpInvoke("app_discovery.launch_wss");
    onCancel();
  };

  return (
    <div className="wssPopup">
      <img src={accountCreationComplete} alt="" className="wssPopup__image" />

      <Heading
        className="wssPopup__title"
        size="xs"
        data-testid="wssPopUpHeader"
      >
        <String id={_S`header1`} />
      </Heading>
      <Heading
        className="wssPopup__title"
        size="xs"
        data-testid="wssPopUpHeader"
      >
        <String
          id={_S`header2`}
          values={{
            variation: wssVariation,
          }}
        />
      </Heading>

      <div data-testid="wssPopUpContent">
        <Paragraph size="sm" className="wssPopup__content">
          <String
            id={_S`content`}
            values={{
              variation: wssVariation,
            }}
          />
        </Paragraph>
      </div>
      <div className="wssPopup__buttons">
        <Button
          className="ml-32"
          onClick={redirectToWss}
          size="sm"
          id="takeMeThere"
          data-testid="takeMeThereButton"
        >
          <String id={_S`takeMeThere`} />
        </Button>
        <Button
          className="ml-32"
          variant="tertiary"
          onClick={onCancel}
          id="wssSkip"
          data-testid="wssSkipButton"
          size="sm"
        >
          <String id={_S`skip`} />
        </Button>
      </div>
    </div>
  );
}
