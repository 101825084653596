import React, { useState, useEffect } from "react";
import { String } from "../../l10n/loader";

// DOM
import { Icon, Button } from "@mcafee/pegasus";

// Custom components
import { URL_REGEX, DOMAIN_REGEX } from "../../constants/main";
import { POPUP_CONFIRM_BEFORE_EXTERNAL_LINK } from "../PopUps/constants";

import { showPopup } from "../../store/thunks/popups";
import { useDispatch } from "react-redux";

import "./breachActions.scss";

const BreachActions = ({
  onChangePasswordClick,
  onAlreadyFixedIt,
  site,
  thirdParty,
  hasPassword,
  hasPlainTextPassword,
  siteKnown,
  resolved,
}) => {
  const [siteUrl, setSiteUrl] = useState(null);
  const _S = (str) => `identity.breachView.${str}`;

  const dispatch = useDispatch();

  const onAlreadyFixedItClicked = (e) => {
    onAlreadyFixedIt();
  };

  useEffect(() => {
    if (site) {
      if (site.match(URL_REGEX)) {
        setSiteUrl(site);
      } else if (site.match(DOMAIN_REGEX)) {
        //a valid domain, without protocol
        setSiteUrl(`https://${site}`);
      }
    }
  }, [site]);

  const changePasswordClick = async () => {
    if (
      await dispatch(
        showPopup(POPUP_CONFIRM_BEFORE_EXTERNAL_LINK, { siteUrl, thirdParty })
      )
    ) {
      window.open(siteUrl);
      onChangePasswordClick();
    }
  };

  /* Conditions for Buttons display:
  'Got it' - Email known and unknown, Hashed password unknown
  'Take back control' - Plaintext and Hashed password known
  'I already fixed it' - Plaintext and Hashed password known
  ** For Plaintext password unknown :-
  We first display 'Take back control' first and upon its click we show 'I already fixed it' */

  // TODO: simplify this function
  const takeBackControlButton = () => {
    if (hasPlainTextPassword && !siteKnown) {
      return (
        <>
          <Button
            variant="secondary"
            onClick={onAlreadyFixedItClicked}
            id="IdentityBreachViewFixedIt"
            size="sm"
          >
            <String id={_S`thanksForTelling`} />
          </Button>
        </>
      );
    } else if (
      ((hasPassword || hasPlainTextPassword) && siteKnown) ||
      siteKnown
    ) {
      //a password asset was found
      return (
        <>
          <Button
            onClick={changePasswordClick}
            id="IdentityBreachViewTakeBackControl"
            size="sm"
            style={{ flexShrink: 0, transition: "none" }}
          >
            <String id={_S`changePwd`} />
            <Icon
              className="btn__icon left breachActions__changePassword__icon"
              icon="external-link-outline"
            />
          </Button>
          <Button
            variant="tertiary"
            className="breachActions__alreadyFixedIt--withMargin"
            onClick={onAlreadyFixedItClicked}
            width="full"
            id="BreachViewFixedIt"
            size="sm"
            data-testid="BreachViewFixedIt"
            style={{ transition: "none" }}
          >
            <String id={_S`fixedIt`} />
          </Button>
        </>
      );
    } else if (hasPlainTextPassword && !siteKnown) {
      //a password asset was found
      return (
        <>
          <Button
            onClick={changePasswordClick}
            id="BreachViewTakeBackControl"
            size="sm"
            variant="secondary"
          >
            <String id={_S`thanksForTelling`} />
          </Button>
        </>
      );
    } else {
      return (
        <Button
          onClick={onAlreadyFixedItClicked}
          id="IdentityBreachViewGotIt"
          size="sm"
        >
          <String id={_S`gotIt`} />
        </Button>
      );
    }
  };

  return (
    <>
      {!resolved && (
        <div className="breachActions__container--withSpaceBetween">
          {takeBackControlButton()}
        </div>
      )}
    </>
  );
};

export default BreachActions;
