import React, { memo, useRef, useEffect } from "react";
import lottie from "lottie-web";

const Animator = memo(
  ({ src, extraParams, onClick, extraStyles = {}, ...rest }) => {
    // const [lottieAnimation, setLottieAnimation ] = useState(null);

    const styles = {
      width: "100%",
      height: "100%",
      ...extraStyles,
    };

    const animElementRef = useRef();
    const animRef = useRef();

    useEffect(() => {
      animRef.current = lottie.loadAnimation({
        container: animElementRef.current, // the dom element that will contain the animation
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: src, // the path to the animation json
        ...extraParams,
      });

      return () => {
        animRef.current.destroy();
        animRef.current = undefined;
      };
    }, [src, extraParams]);

    const handleClick = () => onClick && onClick();

    return (
      <div
        style={styles}
        ref={animElementRef}
        onClick={handleClick}
        {...rest}
      ></div>
    );
  }
);

export default Animator;
