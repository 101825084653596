import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading, Icon } from "@mcafee/pegasus";
import AddDeviceFailure from "../../components/_assets/images/popUps/addDeviceFailure.svg";

const FailurePopUp = ({ onFinish, message }) => {
  const localePrefix = "popups.";
  const styles = {
    popUpStyles: {
      maxWidth: 450,
    },
    logoStyles: {
      float: "center",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
    titleStyles: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    contentStyles: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    errorStyles: {
      textAlign: "left",
      display: "flex",
      justifyContent: "left",
      alignItems: "left",
      marginLeft: "12px",
    },
  };

  return (
    <div style={styles.popUpStyles} className="pa-32">
      <img
        style={styles.logoStyles}
        src={AddDeviceFailure}
        alt=""
        className="mb-36 pb-36"
      />

      <Heading
        className="mt-36 pt-36 flex justify-content-center align-items-center"
        size="xs"
        data-testid="failurePopUpHeader"
      >
        <String id={`${localePrefix}${message}.header`} />
      </Heading>

      <Paragraph
        style={styles.contentStyles}
        className="mt-8"
        size="sm"
        data-testid="failurePopUpContent"
      >
        <String id={`${localePrefix}${message}.content`} />
      </Paragraph>

      <div className="mt-40 flex">
        <Button
          className="mx-auto"
          onClick={(e) => {
            onFinish(true);
          }}
          variant="primary"
          kind="danger"
          id="AddFailurePopUpTryAgain"
          data-testid="failurePopUpTryAgain"
          size="sm"
        >
          <Icon className="btn__icon left mr-8" icon="alert-fill" />
          <String id={`${localePrefix}${message}.tryAgain`} />
        </Button>
      </div>
    </div>
  );
};

export default FailurePopUp;
