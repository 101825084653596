import { createSlice } from "@reduxjs/toolkit";
import { signOutSuccess } from "./auth";

const initialState = {
  checking: false,
  checkingError: null,
  status: null, //secure|unsecure|ssl-attack
  type: null, //wired|wifi
};

const slice = createSlice({
  name: "@net",
  initialState,
  reducers: {
    networkCheckBegin: (state, action) => {
      state.checking = true;
      state.checkingError = null;
    },
    networkCheckFailure: (state, action) => {
      state.checking = false;
      state.checkingError = action.payload;
      state.status = "unknown";
    },
    networkCheckSuccess: (state, action) => {
      state.checking = false;
      state.checkingError = null;
      if (action.payload.status) {
        state.status = action.payload.status;
      }
    },
    networkUpdateStatus: (state, action) => {
      state.status = action.payload.status;
    },
    networkDisconnectStart: () => {},
    networkDisconnectSkip: () => {},
  },
  extraReducers: {
    [signOutSuccess]: () => {
      return initialState;
    },
  },
});

export const {
  networkCheckBegin,
  networkCheckSuccess,
  networkCheckFailure,
  networkUpdateStatus,

  networkDisconnectStart,
  networkDisconnectSkip,
} = slice.actions;

export default slice.reducer;
