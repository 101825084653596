//TODO: Check if still used
// React
import React from "react";

// DOM
import "../DashboardCard/DashboardCard.scss";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import AddMobileImage from "../_assets/images/dashboard/cards/addMobile.svg";

//utils
import { POPUP_ADD_PHONE_NUMBER } from "../PopUps/constants";
import { useDispatch } from "react-redux";
import { RECOMMENDED } from "../../constants/main";
import { showPopup } from "../../store/thunks/popups";
import { fetchDashboardCards } from "../../store/thunks/dashboard";

export default function AddMobilePhone({ id }) {
  const dispatch = useDispatch();

  const onPrimaryAction = async (e) => {
    if (
      await dispatch(
        showPopup(POPUP_ADD_PHONE_NUMBER, {}, "", { overflow: "visible" })
      )
    ) {
      dispatch(fetchDashboardCards());
    }
  };

  const card = {
    id,
    backgroundImage: AddMobileImage,
    tagText: RECOMMENDED,
    primaryActionStringId: "sendAlerts",
    primaryActionDataTestId: "missingPhoneNumberSendAlerts",
    onPrimaryAction,
  };

  return <DashboardCard {...card} />;
}
