import { closePopup, openPopup } from "../popups";

export const showPopup = (popup, props = {}, className = "", style = {}) => (
  dispatch
) => {
  const { addEventListener, removeEventListener } = window;

  return new Promise((onSuccess) => {
    dispatch(openPopup({ popup, props, className, style }));
    const handleHide = (e) => {
      if (e.data.popup === popup) {
        onSuccess(e.data.results);
        removeEventListener(closePopup.type, handleHide);
      }
    };
    addEventListener(closePopup.type, handleHide);
  });
};

export const closeAllPopups = () => (dispatch, getState) => {
  const { list: popupList } = getState().popups;
  for (var i = popupList.length - 1; i >= 0; i--) {
    dispatch(closePopup(popupList[i]));
  }
};
