import React from "react";

import NavbarLayout from "../NavBar/NavbarLayout";
import {
  Link,
  Heading,
  Card,
  Button,
  Paragraph,
  Subheading,
} from "@mcafee/pegasus";
import { uwpPublish } from "../../uwp";

import { ROUTE_SETTINGS_ANTIVIRUS } from "../app/routes";
import antivirusSuccess from "../_assets/images/shared/happyLaptop.svg";
import timerRoundedIcon from "../_assets/images/shared/timerRounded.svg";
import mcafeeScanIcon from "../_assets/images/shared/mcafeeScan.svg";
import mcafeeScanInfoIcon from "../_assets/images/shared/mcafeeInfo.svg";
import antivirusFailure from "../_assets/images/shared/laptopError.svg";
import { ArrowBackRounded, ArrowForwardRounded } from "@material-ui/icons";

import { push } from "connected-react-router";
import { connect, useDispatch, useSelector } from "react-redux";

import "./Antivirus.scss";
import { DialogLayout } from "../layouts/DialogLayout";
import { AV, LTR_DIRECTION } from "../../constants";
import {
  POPUP_AV_SCAN_EDUCATION,
  POPUP_AV_SCAN_TYPES,
} from "../PopUps/constants";
import { showPopup } from "../../store/thunks/popups";
import { startScan } from "../../store/thunks/av";
import ScanProgress from "./ScanProgress";
import { String } from "../../l10n/loader";

function Antivirus({
  scanId,
  scanType,
  progress,
  issuesResolved,
  filesScanned,
  issuesUnresolved,
  rtsStatus,
  status,
  push,
}) {
  const dispatch = useDispatch();
  const _S = (str) => `av.rts.${str}`;

  const onRtsOn = (e) => {
    uwpPublish("av.state.set", { state: "on" });
  };

  const onCancelScan = (e) => {
    uwpPublish("nav.ods.control", {
      scan_id: scanId,
      control: "cancel",
    });
  };
  const onRunQuickScan = (e) => {
    dispatch(startScan("quick"));
  };

  const onRunFullScan = (e) => {
    dispatch(startScan("full"));
  };

  const onShowScans = () => {
    dispatch(
      showPopup(POPUP_AV_SCAN_TYPES, {
        onRunFullScan: onRunFullScan,
        onRunQuickScan: onRunQuickScan,
      })
    );
  };
  const onShowWhyScans = () => {
    dispatch(showPopup(POPUP_AV_SCAN_EDUCATION));
  };

  const onSettingsClick = async (e) => {
    await dispatch(push(ROUTE_SETTINGS_ANTIVIRUS));
  };

  const Buttons = () => (
    <div className="av__buttons">
      <Card className="av__card">
        <img src={mcafeeScanInfoIcon} alt="feature_avatar" />
        <div className="av__card__content">
          <Subheading size="sm">
            <String id={_S`whyScan`} />
          </Subheading>
          <Paragraph size="sm">
            <String id={_S`whyScan.content`} />
          </Paragraph>
        </div>
        <Button
          tabIndex={0}
          kind="normal"
          variant="tertiary"
          icon
          onClick={onShowWhyScans}
          //id={`${feature}Service`}
          // disabled={disabled}
          size="sm"
          className="av__card__arrow"
        >
          {direction === LTR_DIRECTION ? (
            <ArrowForwardRounded className="btn__icon text-gray-light" />
          ) : (
            <ArrowBackRounded className="btn__icon text-gray-light" />
          )}
        </Button>
      </Card>
      <Card className="av__card">
        <img src={mcafeeScanIcon} alt="feature_avatar" />
        <div className="av__card__content">
          <Subheading size="sm">
            <String id={_S`quickVsFull`} />
          </Subheading>{" "}
          <Paragraph size="sm">
            <String id={_S`quickVsFull.content`} />
          </Paragraph>
        </div>
        <Button
          tabIndex={0}
          kind="normal"
          variant="tertiary"
          icon
          onClick={onShowScans}
          size="sm"
          className="av__card__arrow"
        >
          {direction === LTR_DIRECTION ? (
            <ArrowForwardRounded className="btn__icon text-gray-light" />
          ) : (
            <ArrowBackRounded className="btn__icon text-gray-light" />
          )}
        </Button>
      </Card>
    </div>
  );
  const { direction } = useSelector((state) => state.context);
  const variation =
    issuesUnresolved > 0
      ? "unresolvedThreats"
      : rtsStatus === "off"
      ? "off"
      : "on";
  const img = variation === "on" ? antivirusSuccess : antivirusFailure;
  const avLanding = () => {
    return (
      <DialogLayout
        image={img}
        buttons={<Buttons />}
        pageId={AV}
        animate={false}
        showBack={false}
        title="Antivirus"
        titleIcon="settings-atlas-outline"
        onSettingsClick={onSettingsClick}
      >
        <div className="av__content">
          <Heading size="sm" className="av__content">
            <String id={_S(`${variation}.header`)} />
          </Heading>

          <Paragraph size="sm" className="av__content">
            <String id={_S(`${variation}.content1`)} />
            {variation === "unresolvedThreats" && (
              <Link size="sm" className="av__content__link">
                <String id={_S(`${variation}.seeDetails`)} />
              </Link>
            )}
          </Paragraph>

          {variation !== "unresolvedThreats" && (
            <Paragraph size="sm" className="av__content">
              <String id={_S(`${variation}.content2`)} />
            </Paragraph>
          )}
          {variation === "on" && (
            <>
              <div className="av__content__info">
                <img src={timerRoundedIcon} alt="feature_avatar" />
                <Subheading size="sm" className="av__content__info__text">
                  <String id={_S(`${variation}.subheading`)} />
                </Subheading>
              </div>
              <Button onClick={onRunQuickScan} className="av__content__button">
                <String id={_S(`quickScan.button`)} />
              </Button>
            </>
          )}
          {variation === "off" && (
            <div>
              <Button
                onClick={onRunQuickScan}
                variant="secondary"
                className="av__content__button"
              >
                <String id={_S`quickScan.button`} />
              </Button>
              <Button onClick={onRtsOn} className="av__content__button">
                <String id={_S`turnOn.button`} />
              </Button>
            </div>
          )}
          {variation === "unresolvedThreats" && (
            <>
              <Button
                onClick={onRunQuickScan}
                variant="secondary"
                className="av__content__button"
              >
                <String id={_S`scanAgain.button`} />
              </Button>
              <Button onClick={onRunQuickScan} className="av__content__button">
                <String id={_S`contactUs.button`} />
              </Button>
            </>
          )}
        </div>
      </DialogLayout>
    );
  };
  return (
    <NavbarLayout>
      {status === "running" && (
        <ScanProgress
          progress={progress}
          fixed={issuesResolved}
          scanned={filesScanned}
          onCancelScan={onCancelScan}
          scanType={scanType}
        />
      )}
      {(status === "idle" || status === "completed") && avLanding()}
    </NavbarLayout>
  );
}

export default connect(
  (state) => ({
    ...state.av,
  }),
  {
    push,
  }
)(Antivirus);
