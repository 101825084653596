import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { String } from "../l10n/loader";

// Store
import { sendOtp, verifyOtp } from "../store/thunks/otp";

// DOM
import {
  Button,
  Paragraph,
  Heading,
  Label,
  Pinput,
  Link,
} from "@mcafee/pegasus";
import { resetOtp } from "../store/otp";
import { navToErrorPage, num2string } from "../utils";
import {
  ERROR_OTP_INVALID_TRANSACTION_KEY,
  MAX_ERROR_RETRY,
  MCAFEE_SUPPORT_URL,
  OTP_FLOW_TYPE_PHONE,
} from "../constants/main";
import { closeAllPopups } from "../store/thunks/popups";

/* Awaiting for the resolution of the following pegasus Issues:
- Require onKeyUp, onKeyDown listeners for Input Component #114
- Input component default styles doesn't let me override it with my styles. #112 */
export default function OtpPopup({
  newEmail,
  newPhoneNumber,
  sentPhoneNumber,
  formattedPhoneNumber,
  onFinish,
  trigger,
  flow,
  scope,
}) {
  const [validPin, setValidPin] = useState(false);
  const [inputKind, setInputKind] = useState("");
  const [otpInput, setOtpInput] = useState("");
  const [doneAutoSend, setDoneAutoSend] = useState(false);
  const [pinputKey, setPinputKey] = useState(0);
  const [coolDownSeconds, setCoolDownSeconds] = useState();
  const [sendEnabled, setSendEnabled] = useState(false);
  const [errorType, setErrorType] = useState("");
  const [resentOtp, setResentOtp] = useState(false);
  const [sendRetryCount, setSendRetryCount] = useState(0);

  const popupRef = useRef();
  const _S = (str) => `popups.otp.${str}`;

  const {
    isNewRequest,
    token: otpToken,
    verifiedEmail,
    verifyError,
    verifyProgress,
    sendError,
    sendProgress,
    sentEmail,
    transaction_key,
    resend_remaining,
    verify_attempts_remaining,
    resend_time,
    expiry_time,
    verifiedPhoneNumber,
  } = useSelector((state) => state.otp);

  const dispatch = useDispatch();

  const clearPin = () => {
    const inputs = popupRef.current.querySelectorAll("input");
    for (let input of inputs) {
      input.value = "";
    }
    setOtpInput("");
  };

  /**
   * React to resend_time count down to enable/disable resend button
   */
  useEffect(() => {
    const { setInterval, clearInterval } = window;
    const checkExpiryAndCoolDown = () => {
      const now = Date.now();
      if (resend_time && resend_time >= now) {
        setCoolDownSeconds(Math.floor((resend_time - now) / 1000));
      } else {
        setCoolDownSeconds(undefined);
        if (expiry_time && expiry_time <= now) {
          dispatch(resetOtp());
          clearPin();
          setErrorType("codeExpired");
          setResentOtp(false);
          return;
        }
      }
    };
    checkExpiryAndCoolDown();
    const id = setInterval(checkExpiryAndCoolDown, 1000);
    return () => {
      clearInterval(id);
    };
  }, [resend_time, expiry_time, dispatch]);

  /**
   * Called on popup open and upon clicking Resend button
   *
   */
  const sendOtpCode = useCallback(
    async ({ startup = false } = {}) => {
      setDoneAutoSend(true);
      setErrorType("");
      setResentOtp(false);

      const { resend_after_seconds, error } = await dispatch(
        sendOtp({
          email: newEmail,
          trigger,
          phone_number: newPhoneNumber,
          flow,
          scope,
        })
      );

      if (error) {
        //Failed to request otp code
        if (startup && error.code === ERROR_OTP_INVALID_TRANSACTION_KEY) {
          //First call failure due to bad transaction_key, try again?
          return sendOtpCode();
        }
        return { error }; //Failed to request
        //useEffect would take care of showing an error
      } else if (resend_after_seconds > 0) {
        //cool down period requested, disable UI
        setResentOtp(false);
        setCoolDownSeconds(resend_after_seconds);
      }
      setSendRetryCount(0); //reset retryCount
      return { ok: true }; //successful send
    },
    [dispatch, newEmail, trigger, newPhoneNumber, flow, scope]
  );

  const setPinFocus = () => {
    if (popupRef.current) {
      const inputs = popupRef.current.querySelectorAll("input,button");
      //Set the focus on the first enabled input
      for (let input of inputs) {
        if (!input.disabled) {
          input.focus();
          break;
        }
      }
    }
  };

  useEffect(() => {
    setSendEnabled(!sendProgress && !coolDownSeconds);
    setPinFocus();
  }, [sendProgress, coolDownSeconds]);

  useEffect(() => {
    if (!(verify_attempts_remaining > 0)) {
      clearPin();
    }
  }, [expiry_time, verify_attempts_remaining]);

  useEffect(() => {
    const numericEx = /^[0-9]*$/;
    const validOtpRegEx = /^[0-9]{6}$/;

    /* Green:   length = 6 and all numeric
       Red:    alpha-numeric
       normal: remaining cases */
    if (validOtpRegEx.test(otpInput)) {
      setInputKind("success");
      setValidPin(true);
    } else if (numericEx.test(otpInput)) {
      setValidPin(false);
      setInputKind("");
    } else {
      setInputKind("danger");
      setValidPin(false);
    }
  }, [otpInput]);

  /**
   * Initial Otp request
   */
  useEffect(() => {
    //First open auto send
    if (!doneAutoSend) {
      sendOtpCode({ startup: true });
    }
  }, [doneAutoSend, sendOtpCode]);

  /**
   * React to an error from verify action
   * Show an error message
   */
  useEffect(() => {
    if (verifyError) {
      setPinFocus();
      setInputKind("danger");
      setValidPin(false);
      setErrorType("verifyError");
    }
  }, [verifyError]);

  const onClickValidate = (e) => {
    dispatch(verifyOtp({ otpInput, trigger, flow, scope }));
  };

  const handleChange = (inputValue) => {
    setOtpInput(inputValue.join(""));
    setResentOtp(false);
  };

  const onSupportClick = () => {
    window.open(MCAFEE_SUPPORT_URL, "_blank");
  };

  /**
   * React to valid Otp token obtained for the provided email
   * or phone number.
   * Close the popup and return the token
   * and transaction key to the opener.
   */
  useEffect(() => {
    if (
      (newEmail
        ? verifiedEmail === newEmail
        : verifiedPhoneNumber === newPhoneNumber) &&
      otpToken
    ) {
      onFinish({ otpToken, transaction_id: transaction_key }); //Valid otp token for that email received
    }
  }, [
    verifiedEmail,
    otpToken,
    transaction_key,
    newEmail,
    onFinish,
    verifiedPhoneNumber,
    newPhoneNumber,
  ]);

  //Handle resend button click
  const resendOtpCode = async (e) => {
    setPinputKey(pinputKey + 1);
    clearPin();
    //to wait till the call is complete and isResend is updated

    setSendRetryCount(sendRetryCount + 1);

    const { error } = await sendOtpCode();
    if (!error) {
      setResentOtp(true);
    } else if (sendRetryCount + 1 >= MAX_ERROR_RETRY) {
      dispatch(closeAllPopups());
      navToErrorPage(error);
    }
  };

  const isResend =
    transaction_key &&
    sentEmail === newEmail &&
    sentPhoneNumber === newPhoneNumber &&
    resend_remaining > 0 &&
    verify_attempts_remaining > 0 &&
    expiry_time > Date.now();

  const secondsToMinutes = (sec) =>
    `${num2string(Math.floor(sec / 60))}:${num2string(sec % 60)}`;

  return (
    <div
      className="pa-24"
      style={{ maxWidth: 550 }}
      data-testid="otpPopup"
      ref={popupRef}
    >
      <Heading className="mb-8 text-left" size="xs">
        <String id={_S`title`} />
      </Heading>

      <Paragraph className="mt-8 mb-32 text-left" size="md">
        {flow === OTP_FLOW_TYPE_PHONE ? (
          <String
            id={_S`phone.description`}
            values={{ phone: formattedPhoneNumber }}
          />
        ) : (
          <String id={_S`description`} values={{ email: newEmail }} />
        )}
      </Paragraph>
      <div className="wrap text-center">
        <Pinput
          digits={6}
          placeholder="#"
          kind={inputKind}
          onChange={handleChange}
          key={pinputKey}
          type="number"
          disabled={
            sendProgress || !transaction_key || !(verify_attempts_remaining > 0)
          }
        />

        <div
          className="flex direction-column justify-content-center"
          style={{ minHeight: 30 }}
        >
          {(sendProgress || verifyProgress) && (
            <Label size="sm" className="text-info">
              <String id={_S`inProgressOtp`} />
            </Label>
          )}
          {(errorType || sendError) && (
            <Label size="sm" className="text-danger">
              {sendError && (
                <span>
                  {sendError.message}. <String id="popups.tryAgain" />
                </span>
              )}
              {errorType && <String id={_S(errorType)} />}
            </Label>
          )}
          {resentOtp && (
            <Label size="sm" className="text-success">
              <String id={_S(!isNewRequest ? "resentCode" : "sentNewCode")} />
            </Label>
          )}
        </div>
      </div>

      <div className="mt-12 flex direction-column align-items-center justify-content-center">
        <Button
          className="mb-12 px-96"
          onClick={onClickValidate}
          disabled={
            !validPin ||
            sendProgress ||
            !transaction_key ||
            !(verify_attempts_remaining > 0)
          }
          id="PopupsOtpItsMe"
          data-testid="verifyOtpButton"
          size="sm"
        >
          <String id={_S`itsMe`} />
        </Button>
        <Button
          variant="tertiary"
          onClick={resendOtpCode}
          id="PopupsOtpSendNewCode"
          disabled={!sendEnabled}
          data-testid="resendOtpButton"
          size="sm"
        >
          <String
            id={_S(
              isResend
                ? "resendCode"
                : coolDownSeconds
                ? "sendNewCodeIn"
                : "sendNewCode"
            )}
            values={{ time: secondsToMinutes(coolDownSeconds) }}
          />
        </Button>
      </div>
      <Label size="xs" className="mt-8 text-center">
        <String
          id={_S`havingTrouble`}
          values={{
            link: (
              <Link onClick={onSupportClick} id="OtpSupport">
                <String id={_S`support`} />
              </Link>
            ),
          }}
        />
      </Label>
    </div>
  );
}

// export default connect(
//   (state) => ({
//     ...state.otp,
//   }),
//   { resetOtp, verifyOtp, sendOtp }
// )(OtpPopup);
