import styled from "styled-components";

const StyledModalContainer = styled.div`
  max-width: 650px;
  overflow: hidden;
  width: 100%;
`;

const StyledModalSlider = styled.div`
  height: 255px;
  background: #eff4ff;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

export { StyledModalContainer, StyledModalSlider };
