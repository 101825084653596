import React, { useCallback, useEffect, useState } from "react";
import ServiceCard from "./ServiceCard";
import {
  ASSET_TYPE_EMAIL,
  DWS_MONITOR_ENABLED,
  FEATURE_IDENTITY,
  REMEDIATION_ACTED,
} from "../../constants/main";
import { String } from "../../l10n/loader";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { fetchDwsDashboard } from "../../store/thunks/identity";
import {
  ROUTE_IDENTITY,
  ROUTE_SCAN_EMAIL,
  ROUTE_SERVICES,
} from "../app/routes";

import noEmailSetIcon from "../../components/_assets/images/services/breachNew.svg";
import errorIcon from "../../components/_assets/images/services/breachError.svg";
import breachesSolvedIcon from "../../components/_assets/images/services/breachSolved.svg";
import breachesUnsolvedIcon from "../../components/_assets/images/services/breachUnsolved.svg";

import {
  collectScreenLoadEvent,
  createQueryString,
  getScreenCategory,
  getScreenType,
} from "../../utils";
import {
  ANALYTICS_CARD,
  ANALYTICS_FAILURE,
  ANALYTICS_ID_PROTECTION,
  ANALYTICS_NEW,
  ANALYTICS_SUCCESS,
  ANALYTICS_MANUAL,
} from "../../constants/analytics";
import { SCREEN_SERVICES_IDENTITY } from "../../constants/analyticsScreens";

import { v4 as uuidv4 } from "uuid";
import { waitFor } from "../../utils";

const IdentityServiceCard = ({
  features,
  breaches,
  fetchingDashboard,
  fetchDashboardError,
  assets,
  fetchDwsDashboard,
  push,
}) => {
  const localePrefix = "services.";
  const contentPrefix = "identityCard";

  const [haveMonitoredEmail, setHaveMonitoredEmail] = useState(
    assets && assets.length
  );
  const [breachCount, setBreachCount] = useState();

  const feature = FEATURE_IDENTITY;
  const disabled = breachCount === undefined;

  const tagContent = haveMonitoredEmail ? (
    <String
      id={`${localePrefix}urgentBreaches`}
      values={{ count: breachCount }}
    />
  ) : (
    <String id={`${localePrefix}new`} />
  );

  const tagKind =
    breachCount > 0
      ? "danger"
      : haveMonitoredEmail
      ? fetchDashboardError
        ? "neutral"
        : "normal"
      : "decorative";

  const icon =
    breachCount > 0
      ? breachesUnsolvedIcon
      : haveMonitoredEmail
      ? fetchDashboardError
        ? errorIcon
        : breachesSolvedIcon
      : noEmailSetIcon;

  const [hitLabel0, setHitLabel0] = useState(undefined);
  const [hitTrigger, setHitTrigger] = useState(undefined);
  const [screenName, setScreenName] = useState(undefined);
  const [screenDetails, setScreenDetails] = useState(undefined);
  const [isEventSent, setIsEventSent] = useState(false);

  const hit_guid = uuidv4().toUpperCase();

  const onClick = (e) => {
    let urlQuery = {
      back: ROUTE_SERVICES,
      hide_skip: haveMonitoredEmail ? undefined : true,
    };

    push({
      pathname: haveMonitoredEmail ? ROUTE_IDENTITY : ROUTE_SCAN_EMAIL,
      search: createQueryString(urlQuery),
    });
  };

  useEffect(() => {
    if (!features || !features[FEATURE_IDENTITY].enabled) {
      return;
    }
    if (!breaches && !fetchingDashboard && !fetchDashboardError) {
      fetchDwsDashboard({ hit_trigger: "dashboard" });
    }
  }, [
    features,
    breaches,
    fetchingDashboard,
    fetchDashboardError,
    fetchDwsDashboard,
  ]);

  useEffect(() => {
    if (!features || !features[FEATURE_IDENTITY].enabled) {
      return;
    }
    /* iterates through the breaches information in the breach API response 
      and counts the total number of not "status":"Resolved" */
    const getStatusCount = () => {
      if (Array.isArray(breaches)) {
        return breaches.filter(
          (breach) => breach.remediation_status !== REMEDIATION_ACTED
        ).length;
      }
      return 0;
    };

    setBreachCount(getStatusCount());
    if (assets && assets.length > 0)
      setHaveMonitoredEmail(
        assets.some(
          (e) =>
            e.type === ASSET_TYPE_EMAIL && e.dws_monitor === DWS_MONITOR_ENABLED
        )
      );
  }, [features, assets, breaches]);

  const prepareAnalytics = useCallback(async () => {
    await waitFor(
      () => breaches && breachCount !== undefined && !fetchingDashboard
    );
    if (!fetchingDashboard) {
      setHitLabel0(
        !fetchDashboardError ? ANALYTICS_SUCCESS : ANALYTICS_FAILURE
      );
      setHitTrigger(!haveMonitoredEmail ? ANALYTICS_NEW : ANALYTICS_MANUAL);
      setScreenDetails(
        breachCount > 0
          ? "urgent_breaches_found"
          : haveMonitoredEmail
          ? fetchDashboardError
            ? "error"
            : "no_breaches_found"
          : "new"
      );
      setScreenName(SCREEN_SERVICES_IDENTITY);
    }
  }, [
    fetchingDashboard,
    fetchDashboardError,
    haveMonitoredEmail,
    breaches,
    breachCount,
  ]);

  useEffect(() => {
    if (
      !fetchingDashboard &&
      !screenName &&
      !hitLabel0 &&
      !hitTrigger &&
      !screenDetails &&
      !isEventSent
    ) {
      prepareAnalytics();
    } else if (
      screenName &&
      hitLabel0 &&
      hitTrigger &&
      screenDetails &&
      !isEventSent
    ) {
      collectScreenLoadEvent({
        hit_category_0: getScreenCategory(screenName),
        hit_feature: ANALYTICS_ID_PROTECTION,
        hit_screen: screenName,
        hit_label_0: hitLabel0,
        hit_label_3: getScreenType(screenName),
        hit_label_5: ANALYTICS_CARD,
        hit_label_6: screenDetails,
        hit_label_8: "services",
        hit_trigger: hitTrigger,
      });
      setIsEventSent(true);
    }
  }, [
    fetchingDashboard,
    hitLabel0,
    hitTrigger,
    hit_guid,
    isEventSent,
    prepareAnalytics,
    screenDetails,
    screenName,
  ]);

  return (
    <ServiceCard
      {...{
        feature,
        icon,
        tagKind,
        tagContent,
        onClick,
        contentPrefix,
        disabled,
      }}
    />
  );
};

export default connect(
  (state) => ({
    ...state.identity,
    features: state.experience.features,
  }),
  {
    push,
    fetchDwsDashboard,
  }
)(IdentityServiceCard);
