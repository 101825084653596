// React
import React from "react";

// DOM
import ProtectDevicesImage from "../_assets/images/dashboard/cards/protectDevices.svg";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";

// utils
import { RECOMMENDED } from "../../constants/main";
import { POPUP_ADD_EMAIL_DEVICE } from "../PopUps/constants";
import { useDispatch } from "react-redux";
import { showPopup } from "../../store/thunks/popups";

export default function ProtectNewDevice({ id }) {
  // Close the current PopUp and show the other popup based on the Storing status.
  const dispatch = useDispatch();

  const onPrimaryAction = async (e) => {
    await dispatch(showPopup(POPUP_ADD_EMAIL_DEVICE));
  };

  const card = {
    id: id,
    backgroundImage: ProtectDevicesImage,
    tagText: RECOMMENDED,
    primaryActionStringId: "protectDevice",
    primaryActionDataTestId: "protectDeviceButton",
    onPrimaryAction,
  };

  // has the Popup handler intact with the card UI
  return <DashboardCard {...card} />;
}
