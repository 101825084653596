//ROUTE_MONITOR_ASSET_DONE page
import React, { useState, useEffect } from "react";
import { String } from "../../l10n/loader";
import { useDispatch, useSelector } from "react-redux";
import {
  ROUTE_IDENTITY,
  ROUTE_MONITOR_ASSET_DONE,
} from "../../components/app/routes";
import {
  ONBOARD_NEW_ASSET_BREACHES_FOUND,
  ONBOARD_NEW_ASSET_NO_BREACHES,
} from "../../constants/main";

import addEmailBreachesIdentityAlert from "../../components/_assets/images/onboard/scanEmail/breachesFound.svg";
import addEmailNoBreachesIdentityAlert from "../../components/_assets/images/onboard/scanEmail/noBreachesFound.svg";

// DOM
import { Display, Paragraph, Button, Row, Col, Input } from "@mcafee/pegasus";

import { POPUP_NO_PHONE_FOUND } from "../../components/PopUps/constants";

import { showPopup } from "../../store/thunks/popups";
import { DialogLayout } from "../../components/layouts/DialogLayout";
import BreachRow from "../../components/IdentityBreachList/BreachRow";
import "./MonitorAssetDone.scss";
import { navigateBack } from "../../store/thunks/app";
import { replace } from "connected-react-router";

export default function MonitorAssetDone() {
  const _S = (str) => `identity.newAssetAdded.${str}`;
  const query = useSelector((state) => state.router.location.query);

  const verifiedEmail = decodeURIComponent(query.email);
  const path = decodeURIComponent(query.path);

  const { breaches } = useSelector((state) => state.identity);

  const mobileNumber = useSelector((state) => state.dashboard.mobileNumber);

  const [filteredBreaches, setFilteredBreaches] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    //filter breaches by verified email
    const filterByEmail = (breaches || []).filter(
      (breach) => breach.email === verifiedEmail
    );
    //further filter breaches to get only unique site names

    var filterBySite = filterByEmail.reduce((unique, breach) => {
      var i = unique.findIndex((item) => breach.site === item.site);
      return i <= -1 ? [...unique, breach] : unique;
    }, []);

    setFilteredBreaches(filterBySite);
  }, [breaches, verifiedEmail]);

  const breachList = () => {
    return (
      <div>
        {Array.isArray(filteredBreaches) && (
          <div>
            {filteredBreaches.length > 0
              ? filteredBreaches.slice(0, 3).map(
                  (
                    breach //only show max 3 breaches
                  ) => (
                    <BreachRow
                      {...breach}
                      readOnly={true}
                      key={breach.breach_id}
                      pageId={ROUTE_MONITOR_ASSET_DONE}
                    />
                  )
                )
              : null}
          </div>
        )}
      </div>
    );
  };

  let domItems = {
    waves: ONBOARD_NEW_ASSET_NO_BREACHES,
    header: _S`noBreachesHeader`,
    content1: _S`noBreachesContent_1`,
    content2: _S`noBreachesContent_2`,
    okBtn: _S`gotIt`,
  };

  if (filteredBreaches.length > 0) {
    domItems.waves = ONBOARD_NEW_ASSET_BREACHES_FOUND;
    domItems.header = _S`breachesFoundHeader`;
    domItems.content1 = _S`breachesFoundContent_1`;
    domItems.content2 = breachList();
    domItems.okBtn = _S`letsDoIt`;
  }

  const onClickOk = (e) => {
    if (!filteredBreaches.length && !mobileNumber) {
      //No breaches found and no phone number registered,
      //offer to monitor the added asset via SMS messages
      dispatch(showPopup(POPUP_NO_PHONE_FOUND));
    }

    if (path === ROUTE_IDENTITY) {
      dispatch(navigateBack({ defaultRoute: ROUTE_IDENTITY }));
    } else {
      dispatch(replace({ pathname: ROUTE_IDENTITY, search: "back=onboarding" }));
    }
  };

  const Buttons = () => (
    <>
      <Button
        onClick={onClickOk}
        id="Verify"
        data-testid="newAssetDarkWebEducateVerifyButton"
        size="sm"
      >
        <String id={domItems.okBtn} />
      </Button>
    </>
  );

  return (
    <DialogLayout
      pageId={domItems.waves}
      buttons={<Buttons />}
      image={
        domItems.waves === ONBOARD_NEW_ASSET_NO_BREACHES
          ? addEmailNoBreachesIdentityAlert
          : addEmailBreachesIdentityAlert
      }
      animate={false}
      showBack={false}
      className="MonitorAssetDone"
    >
      <Display size="xs" data-testid="newAssetDarkWebEducateHeader">
        <String id={domItems.header} />
      </Display>
      <div>
        {domItems.waves === ONBOARD_NEW_ASSET_NO_BREACHES && (
          <Row className="mt-32">
            <Col xs={11} className="pl-8">
              <Input
                icon="email-rounded-outline"
                readOnly
                value={verifiedEmail}
              />
            </Col>
          </Row>
        )}
        <Paragraph
          className={`text-secondary mb-2 mt-32`}
          size="sm"
          data-testid="newAssetDarkWebEducateContent1"
        >
          <String id={domItems.content1} />
        </Paragraph>
        <Paragraph
          className={`text-secondary mb-2 mt-32 MonitorAssetDone__content2`}
          size="sm"
          data-testid="newAssetDarkWebEducateContent1"
        >
          <String id={domItems.content2} />
        </Paragraph>
      </div>
    </DialogLayout>
  );
}
