export const SCREEN_SPLASH = "splash";

/// onboarding
export const SCREEN_ONBOARDING_QUICK_TOUR = "quick_tour";
export const SCREEN_ONBOARDING_QUICK_TOUR_TOOLTIP = "quick_tour_tooltip";
export const SCREEN_ONBOARDING_QUICK_TOUR_LANDING = "landing";
export const SCREEN_ONBOARDING_CREATE_ACCOUNT = "create_account";
export const SCREEN_ONBOARDING_CREATE_ACCOUNT_CREATED =
  "create_account_created";
export const SCREEN_ONBOARDING_SIGN_IN = "sign_in";

/// dws onboarding
export const SCREEN_DWS_INTRO = "dws_intro";
export const SCREEN_DWS_TOOLTIP = "dws_tooltip";
export const SCREEN_DWS_SCANNING = "dws_scanning";
export const SCREEN_DWS_SCAN_DONE = "dws_scan_done";

///home cards dashboard
export const SCREEN_MAIN_DASHBOARD = "dashboard";

export const SCREEN_BREACHES_FOUND_RESULTS = "breaches_found_results";
export const SCREEN_BREACHES_FOUND_LIST = "breaches_found_breach_list";
export const SCREEN_BREACHES_FOUND = "breaches_found";

/// identity

export const SCREEN_BREACH_LIST = "breach_list";
export const SCREEN_BREACH_LIST_DELETE_EMAIL = "breach_list_delete_email";
// export const SCREEN_IDENTITY_MENU_EMAILS = "menu_emails"; /// NOT IN BETA
export const SCREEN_ADD_EMAIL = "add_email";

export const SCREEN_DWS_SCAN_VERIFY_EMAIL = "dws_scan_verify_email";
export const SCREEN_DWS_SCAN_RESULTS = "dws_scan_results";
export const SCREEN_DWS_SCAN_EXIT_VERIFICATION = "dws_scan_exit_verification";

export const SCREEN_BREACH_DETAIL = "breach_detail";
export const SCREEN_BREACH_DETAIL_CHANGE_PWD_UNVERIFIED =
  "breach_detail_change_pwd_unverified";
export const SCREEN_BREACH_DETAIL_CHANGE_PWD_VERIFIED =
  "breach_detail_change_pwd_verified";
export const SCREEN_BREACH_DETAIL_CHANGE_PWD_IN_APP_BROWSER =
  "breach_detail_change_pwd_in_app_browser";
export const SCREEN_BREACH_DETAIL_CHANGE_PWD_CHANGE =
  "breach_detail_confirm_pwd_change";
export const SCREEN_BREACH_DETAIL_CONFIRMATION = "breach_detail_confirmation";
// export const SCREEN_BREACH_DETAIL_SNACKBAR_BREACH_RESOLVED = 'breach_detail_snackbar_breach_resolved'
export const SCREEN_BREACH_DETAIL_BREACH_DETAILS_RESOLVED =
  "breach_detail_breach_details_resolved";
export const SCREEN_BREACH_DETAIL_PLAINTEXT_PWD_LEARN_MORE =
  "breach_detail_plaintext_pwd_known_learn_more";
export const SCREEN_BREACH_DETAIL_OTP = "breach_detail_otp";

export const SCREEN_BREACH_DETAIL_CHANGE_PWD = "breach_detail_change_pwd";
export const SCREEN_BREACH_DETAIL_RESOLVED = "breach_detail_breach_resolved";

export const SCREEN_BREACH_DETAIL_REMINDER_CONFIRMATION =
  "breach_detail_reminder_confirmation";
export const SCREEN_BREACH_DETAIL_CHANGE_PWD_OPTION =
  "breach_detail_change_pwd_option";
export const SCREEN_BREACH_DETAIL_CONFIRM_PWD_CHANGE =
  "breach_detail_confirm_pwd_change";
export const SCREEN_BREACH_DETAIL_LEARN_MORE = "breach_detail_learn_more";
export const SCREEN_PWD_TIPS = "pwd_tips";

/// Services
export const SCREEN_SERVICES_IDENTITY_NO_BREACHES =
  "services_identity_no_breaches_found";
export const SCREEN_SERVICES_IDENTITY_URGENT_BREACHES =
  "services_identity_urgent_breaches_found";
export const SCREEN_SERVICES_IDENTITY = "services_identity";
export const SCREEN_SERVICES_SECURE_VPN = "services_secure_vpn";

/// Settings
export const SCREEN_SETTINGS_ABOUT_US = "settings_about_us";
export const SCREEN_SETTINGS_VPN_SETTINGS_OVERVIEW =
  "settings_secure_vpn_settings_overview";
export const SCREEN_SETTINGS_VPN_SETTINGS_CONNECT =
  "settings_secure_vpn_settings_when_to_connect";
export const SCREEN_SETTINGS_NOTIFICATIONS_VPN = "settings_notifications_vpn";
export const SCREEN_SETTINGS_NOTIFICATIONS_LIST = "settings_notifications_list";
export const SCREEN_SETTINGS_NOTIFICATIONS_ENABLE =
  "settings_notifications_enable_notifications";

export const SCREEN_SETTINGS_ACCOUNT_DEFAULT = "settings_account_default";
export const SCREEN_SETTINGS_ACCOUNT_ADD_PHONE = "settings_account_add_phone";
export const SCREEN_SETTINGS_ACCOUNT_ADD_PHONE_CONFIRMATION =
  "settings_account_add_phone_confirmation";
export const SCREEN_SETTINGS_ACCOUNT_OTP = "settings_account_otp";

export const SCREEN_SETTINGS_VPN_WHEN_TO_CONNECT =
  "settings_secure_vpn_when_to_connect";

export const SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_MENU =
  "settings_account_remove_phone_menu";
export const SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_CONFIRM =
  "settings_account_remove_phone_confirm";
export const SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_CONFIRMATION =
  "settings_account_remove_phone_confirmation";

export const SCREEN_SETTINGS_FEEDBACK_DEFAULT = "settings_feedback_default";
export const SCREEN_SETTINGS_FEEDBACK_POSITIVE = "settings_feedback_positive";
export const SCREEN_SETTINGS_FEEDBACK_NEUTRAL = "settings_feedback_neutral";
export const SCREEN_SETTINGS_FEEDBACK_NEGATIVE = "settings_feedback_negative";
export const SCREEN_SETTINGS_FEEDBACK_NEGATIVE_SUPPORT =
  "settings_feedback_negative_support";
export const SCREEN_SETTINGS_FEEDBACK_NEGATIVE_FEEDBACK_SENT_CONFIRMATION =
  "settings_feedback_negative_feedback_sent_confirmation";
export const SCREEN_SETTINGS_FEEDBACK_GENERIC_FEEDBACK_SENT_CONFIRMATION =
  "settings_feedback_generic_feedback_sent_confirmation";

export const SCREEN_SETTINGS_MANAGE_EMAILS = "manage_emails";
export const SCREEN_SETTINGS_DELETE_EMAIL = "delete_email";
export const SCREEN_SETTINGS_DELETE_EMAIL_CONFIRM = "delete_email_confirm";
export const SCREEN_SETTINGS_DELETE_EMAIL_SNACKBAR = "delete_email_snackbar";
export const SCREEN_SETTINGS_ADD_EMAIL_SNACKBAR = "add_email_snackbar";

/// devices
export const SCREEN_DEVICES_PROTECT_NEW_DEVICE = "devices_protect_new_device";
export const SCREEN_DEVICES_PROTECT_NEW_DEVICE_CONFIRMATION =
  "devices_protect_new_device_confirmation";

/// privacy
export const SCREEN_VPN_SETUP_LANDING_PAGE = "landing_page";
export const SCREEN_VPN_SETUP_DATA_DISCLOSURE = "setup_data_disclosure";
export const SCREEN_VPN_SETUP_CONFIGURING = "setup_configuring";
export const SCREEN_VPN_SETUP_COMPLETE = "setup_complete";
export const SCREEN_VPN_SETUP_ERROR_RETRY = "setup_error_retry";
export const SCREEN_VPN_SETUP_ERROR_FAILED = "setup_error_failed";
export const SCREEN_VPN_QUICK_TOUR = "quick_tour";
export const SCREEN_VPN_OVERVIEW = "overview";
export const SCREEN_VPN_BENEFITS = "benefits";
export const SCREEN_VPN_NETWORK_ATTACK_SAFETY_TIPS =
  "network_attack_safety_tips";
export const SCREEN_VPN_NETWORK_ATTACK_DISCONNECT_WIFI =
  "network_attack_disconnect_wifi_instructions";
export const SCREEN_VPN_NETWORK_ATTACK_WIFI_DISCONNECTED =
  "network_attack_wifi_disconnected_confirmation";

/// subscription
export const SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_COUNTER_STATE =
  "trial_counter_state";
export const SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_EXPIRED = "trial_expired";
export const SCREEN_DASHBOARD_SUBSCRIPTION_PAID_STATE = "paid_state";
export const SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_CHECKING =
  "syncing_trial_checking";
export const SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_NOT_FOUND =
  "syncing_trial_not_found";
export const SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_ERROR =
  "syncing_trial_error";
export const SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_SUCCESS =
  "syncing_trial_success";

export const SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION = "my_subscription";
export const SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION_SYNCING_TRIAL_CONNECTING =
  "my_subscription_syncing_trial_connecting";
export const SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION_TRIAL_STATUS_NOT_FOUND =
  "my_subscription_trial_status_not_found";
export const SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION_SYNCING_TRIAL_ERROR =
  "my_subscription_syncing_trial_error";
export const SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION_UPDATED_CONFIRMATION =
  "my_subscription_updated_confirmation";

// VPN fab screens

export const SCREEN_VPN_FAB_OVERVIEW = "vpn_vpn_overview";
export const SCREEN_VPN_FAB_FIRST_TIME_INFO = "vpn_setup_first_time_info";
