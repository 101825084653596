import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { APP_ATLAS } from "../../constants";
import { setUwpConnected } from "../../store/context";
import { isDevMode } from "../../utils";

export default function DevUwpStatus() {
  const [devMode] = useState(isDevMode());
  const skipUwp = localStorage.getItem("skipUwp") !== "false";
  const { socketOn, isUwpConnected } = useSelector((state) => state.context);
  const { app } = useSelector((state) => state.experience);
  const { ioBus, communicator, open, location } = window;
  const dispatch = useDispatch();

  // Handle websocket connect/disconnect events
  useEffect(() => {
    const { communicator, ioBus, setInterval, clearInterval } = window;

    if (communicator || !ioBus) {
      return;
    }

    let testingConnection;

    if (socketOn) {
      testingConnection = setInterval(async () => {
        try {
          const response = await ioBus.request("test_connection", {}, 3000);
          if (isUwpConnected !== response.data.success) {
            dispatch(
              setUwpConnected({
                uwp: response.data.success,
              })
            );
          }
        } catch (e) {
          //rejected or no request handlers
          if (isUwpConnected) {
            //to avoid dispatching unneaded actions
            dispatch(
              setUwpConnected({
                uwp: false,
              })
            );
          }
        }
      }, 5000);
    }

    return () => {
      if (testingConnection) {
        clearInterval(testingConnection);
      }
    };
  }, [socketOn, isUwpConnected, dispatch]);

  if (app !== APP_ATLAS || !devMode) {
    return null;
  }

  const socketOffHelp = (e) => {
    alert(`Install io-bus: "npm i -g io-bus"\nRun: "io-server"`);
  };

  if (communicator) {
    return (
      <div className="FloatingWarning">
        {socketOn ? (
          "Socket on :)"
        ) : (
          <button onClick={socketOffHelp} id="SocketOff">
            Socket OFF :(
          </button>
        )}
      </div>
    );
  }

  if (isUwpConnected && !skipUwp) {
    return null;
  }

  const onSkip = (e) => {
    if (ioBus) {
      ioBus.disconnect();
    }
    setTimeout(() => {
      dispatch(setUwpConnected({ uwp: true }));
    }, 1000);
    localStorage.setItem("skipUwp", true);
  };

  const openUwp = (e) => {
    open("mfe-pps:///", "_blank");
  };
  const onReconnect = (e) => {
    localStorage.setItem("skipUwp", false);
    location.reload();
  };

  return (
    <div className="FloatingWarning">
      {skipUwp ? (
        <button onClick={onReconnect} id="UWPSkipped">
          UWP Skipped (reconnect)
        </button>
      ) : (
        <>
          {socketOn ? (
            <button onClick={openUwp} id="UWPOff">
              UWP Off
            </button>
          ) : (
            <button onClick={socketOffHelp} id="SocketOFF">
              Socket OFF
            </button>
          )}
          <button onClick={onSkip} id="Skip">
            Skip{">>"}
          </button>
        </>
      )}
    </div>
  );
}
