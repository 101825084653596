import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Paragraph, Button, Heading } from "@mcafee/pegasus";
import errorSetup from "../../components/_assets/images/vpnSetup/vpnError.svg";

export default function VpnSetupErrorMessage({ error, onRetry, onDoItLater }) {
  const localePrefix = "vpn.";
  return (
    <div className="centerContent pb-32">
      <div className="slider__container">
        <img src={errorSetup} alt="ErrorSetup_Image" style={{ height: 264 }} />
      </div>

      <Heading className="mb-16">
        <String id={`${localePrefix}errorScreen.header`} />
      </Heading>

      <Paragraph size="md" className="mb-16">
        <String id={`${localePrefix}errorScreen.content`} />
      </Paragraph>

      <div className="flex direction-row justify-content-end mt-40 mb-32">
        {onRetry && (
          <Button onClick={onRetry} id="VpnErrorTryAgain" size="sm">
            <String id={`${localePrefix}tryAgain`} />
          </Button>
        )}

        {onDoItLater && (
          <Button
            variant="tertiary"
            shape="pill"
            width="normal"
            className="px-48"
            onClick={onDoItLater}
            id="VpnErrorDoItLater"
            size="sm"
          >
            <String id={`${localePrefix}doItLater`} />
          </Button>
        )}
      </div>
    </div>
  );
}
