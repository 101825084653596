import React from "react";

export const ViewPortCenter = ({ children }) => {
  return (
    <div
      className="centerContent"
      style={{
        height: "100vh",
      }}
    >
      {children}
    </div>
  );
};
