import React from "react";
import { Brand } from "@mcafee/pegasus";
import { useDispatch } from "react-redux";
import { publishEvent } from "../../store/thunks/experience";
import events from "../../experience/events";
import "./appHeader.scss";

export default function AppHeader() {
  const dispatch = useDispatch();

  const onClickLogo = (e) => {
    dispatch(publishEvent(events.appHeader.clickLogo));
  };

  return (
    <div className="appHeader border-disabled">
      <Brand height={15} className="appHeader__logo" onClick={onClickLogo} />
    </div>
  );
}
