import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import events from "../../experience/events";
import { CheckActions } from "../Shared/CheckAction";

// Custom components
import Navbar from "./NavBar";
import AppHeader from "../AppHeader/AppHeader";

import "./navbarLayout.scss";
import BackgroundProgress from "../Shared/BackgroundProgress";

const savedScroll = { Dashboard: 0 };

export default function NavbarLayout({
  id,
  children,
  className = "",
  classNameLayout = "",
}) {
  const content = useRef(null);
  const { pathname, query } = useSelector((state) => state.router.location);

  useEffect(() => {
    const { current: contentDiv } = content;
    if (contentDiv) {
      //restore saved scroll position
      const top = id ? savedScroll[id] || 0 : 0;

      if (contentDiv.scrollTo) {
        //Support different browsers
        contentDiv.scrollTo({ top, left: 0 });
      } else {
        contentDiv.scrollTop = top;
      }
    }
    return () => {
      if (id && contentDiv && savedScroll[id] !== undefined) {
        savedScroll[id] = contentDiv.scrollTop;
      }
    };
  }, [id, pathname, query]);

  return (
    <div className={`navbarLayout ${className}`} id={id}>
      <BackgroundProgress />
      <CheckActions events={events.navbar}>
        <div className="navbarLayout__navbar">
          <Navbar />
        </div>
      </CheckActions>
      <div className={`navbarLayout__content ${classNameLayout}`}>
        <CheckActions events={events.appHeader}>
          <div className="navbarLayout__header">
            <AppHeader />
          </div>
        </CheckActions>
        <div className="navbarLayout__body" ref={content}>
          {children}
        </div>
      </div>
    </div>
  );
}
