import React, { useEffect, useState } from "react";
import { String } from "../../l10n/loader";

// DOM
import { Heading, Paragraph, Button } from "@mcafee/pegasus";
//utils
import { createFormattedId } from "../../utils";
import {
  ANALYTICS_ID_PROTECTION,
  ANALYTICS_MENU,
  ANALYTICS_PROTECTION,
  HIT_CATEGORY,
  HIT_FEATURE,
  HIT_SCREEN,
  SCREEN_DETAILS,
  SCREEN_FLOW,
  SCREEN_TYPE,
} from "../../constants/analytics";
import { SCREEN_BREACH_DETAIL_CONFIRM_PWD_CHANGE } from "../../constants/analyticsScreens";
import { collectScreenLoadEvent } from "../../utils";

const ConfirmPasswordChange = ({ screenDetails, onFinish, onCancel }) => {
  const _S = (str) => `identity.${str}`;

  const [isEventSent, setIsEventSent] = useState(false);

  useEffect(() => {
    /// todo: move this event to middleware now that confirmPassword uses the popup component
    if (!isEventSent && screenDetails) {
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_SCREEN]: SCREEN_BREACH_DETAIL_CONFIRM_PWD_CHANGE,
        [SCREEN_TYPE]: ANALYTICS_MENU,
        [SCREEN_DETAILS]: screenDetails,
        [SCREEN_FLOW]: "identity",
      });

      setIsEventSent(true);
    }
  }, [isEventSent, screenDetails]);

  const handleSubmit = (e) => {
    onFinish(true);
  };

  return (
    <div className="pa-24">
      <Heading className="mb-8" size="xs">
        <String id={_S`confirmPasswordChange.title`} />
      </Heading>

      <Paragraph className="mt-8 mb-4" size="sm">
        <String id={_S`confirmPasswordChange.content`} />
      </Paragraph>
      <div className="mt-24 flex justify-content-end">
        <Button
          onClick={handleSubmit}
          id={`${createFormattedId(_S``)}Yes`}
          size="sm"
          data-testid="buttonYes"
        >
          <String id={_S`yes`} />
        </Button>
        <Button
          className="ml-24"
          variant="tertiary"
          onClick={onCancel}
          id={`${createFormattedId(_S``)}NotYet`}
          size="sm"
        >
          <String id={_S`notYet`} />
        </Button>
      </div>
    </div>
  );
};

export default ConfirmPasswordChange;
