import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";

//L10n
import { IntlProvider } from "react-intl";
import { loadMessages } from "../../l10n/loader";

//Redux actions
import { doneLoadingStrings, doneDetectingWSS } from "../../store/context";
import { vpnUpdateState } from "../../store/vpn";
import { networkUpdateStatus } from "../../store/network";
//Redux thunks
import { fetchContext, connectUWP } from "../../store/thunks/app";
import { autoSignIn } from "../../store/thunks/auth";
import { checkNetwork } from "../../store/thunks/network";
import { checkAvState } from "../../store/thunks/av";
import { ThemeProvider, Grid } from "@mcafee/pegasus";
import MainLoader from "./../MainLoader";

//CSS injection
import "./App.scss";
import * as tokens from "@mcafee/pegasus-tokens/dist/pegasusSurgeTheme";
import { DEFATUL_PPS_CONTEXT } from "../../constants/analytics";
import { analyticsInit, delay } from "../../utils";

//Utils
import { uwpInvoke, uwpSubscribe } from "../../uwp";
import { isDevMode } from "../../utils";
import Popups from "../PopUps/Popups";
import Toasters from "../Toasts/Toasters";
import DevUwpStatus from "./DevUwpStatus";
import { AppRouter } from "./AppRouter";
import {
  APP_ATLAS,
  MIN_API_LEVEL,
  OLD_VER_URL_MATCH,
  HEARTBEAT_INTERVAL,
  LTR_DIRECTION,
} from "../../constants/main";
import Overlays from "../Overlays/Overlays";
import { publishEvent } from "../../store/thunks/experience";
import events from "../../experience/events";
function App({
  //...state.context
  direction,
  locale,
  doneStrings,
  isUwpConnected,

  //store dispatches
  doneLoadingStrings, //context
  networkUpdateStatus, //network
  vpnUpdateState, //vpn
  doneDetectingWSS, //context

  //dispatch thunks
  fetchContext, //app
  connectUWP, //app
  autoSignIn, //auth
  checkNetwork, //network
}) {
  const [loading, setLoading] = useState(true);
  const [devMode] = useState(isDevMode());
  const [localeMessages, setLocaleMessages] = useState({});
  const [initAppCalled, setInitAppCalled] = useState(false);

  const { app } = useSelector((state) => state.experience);

  //Application one time initialization
  useEffect(() => {
    //Analytics engine should start before fetch context and autosignin
    analyticsInit(DEFATUL_PPS_CONTEXT);
    connectUWP();
  }, [connectUWP]);

  const dispatch = useDispatch();
  const heartBeat = async () => {
    await dispatch(publishEvent(events.app.heartbeat));
    delay(HEARTBEAT_INTERVAL).then(heartBeat);
  };
  const detectWss = useCallback(async () => {
    const wssTimeout = 60000;
    const { installed, display_name, status } = await uwpInvoke(
      "app_discovery.detect_wss",
      {},
      wssTimeout
    );
    doneDetectingWSS({ installed, display_name, status });
  }, [doneDetectingWSS]);

  const oneTimeInit = async () => {
    dispatch(checkAvState())
    const context = await fetchContext(); //Expected delay for fetching PD and remote config

    if (!window.location.pathname.match(OLD_VER_URL_MATCH)) {
      if (context.apiLevel < MIN_API_LEVEL) {
        const backwardPath =
          `/v${context.apiLevel}` +
          window.location.pathname.replace(OLD_VER_URL_MATCH, "");
        window.location.replace(backwardPath);
        return;
      }
    }

    checkNetwork({ cachedOnly: true }); //Call to retrieve last cached state from network
    await autoSignIn(); //Read localStorage accessToken and user IDs
    //Check and subscribe to network and Vpn state changes
    uwpSubscribe("vpn.state_update", (newVpnState) => {
      vpnUpdateState(newVpnState);
    });
    uwpSubscribe("network_scanner.status", (networkStatus) => {
      networkUpdateStatus(networkStatus);
    });
    detectWss();

    await heartBeat();

    setLoading(false);
  };

  //Perform UWP connection initialization and subscriptions
  useEffect(() => {
    if (isUwpConnected && !initAppCalled) {
      //One time initialization
      //Fetch initial application states
      oneTimeInit();
      setInitAppCalled(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUwpConnected, initAppCalled]);

  //Initialize and handle locale update
  useEffect(() => {
    if (locale) {
      (async function () {
        const messages = await loadMessages(locale);
        setLocaleMessages(messages);
        doneLoadingStrings();
      })();
    }
  }, [doneLoadingStrings, locale, direction]);

  if (loading || !doneStrings) {
    return (
      <IntlProvider locale="en">
        {devMode && <DevUwpStatus />}
        <ThemeProvider theme={{ ...tokens, rtl: false }}>
          <MainLoader dir={LTR_DIRECTION} />
        </ThemeProvider>
      </IntlProvider>
    );
  }

  return (
    <IntlProvider locale={locale} messages={localeMessages}>
      <div id="App" dir={direction}>
        <ThemeProvider
          // theme={{ ...tokens , rtl={direction !== LTR_DIRECTION ? true : false}   } }
          theme={{
            ...tokens,
            rtl: direction === LTR_DIRECTION ? false : true,
          }}
        >
          {devMode && <DevUwpStatus />}
          <Grid fluid className={`app-${app}`}>
            <AppRouter />
          </Grid>
          <Popups />
          <Toasters />
          <Overlays />
        </ThemeProvider>
      </div>
    </IntlProvider>
  );
}

//Connect state properties and action dispatchers to element properties
export default connect(
  (state) => ({
    direction: state.context.direction,
    locale: state.context.locale,
    doneStrings: state.context.doneStrings,
    isUwpConnected:
      state.experience.app !== APP_ATLAS || state.context.isUwpConnected,
  }), //mapStateToProps
  {
    autoSignIn,
    doneLoadingStrings,
    fetchContext,
    vpnUpdateState,
    connectUWP,
    networkUpdateStatus,
    checkNetwork,
    doneDetectingWSS,
  } //mapDispatchToProps (actions dispatchers as props)
)(App);
