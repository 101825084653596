//Inline error message showing upon API failure
import React from "react";
import { String } from "../../l10n/loader";
import { useShowDebugError } from "../../utils";

// DOM
import { Paragraph, Button, Heading } from "@mcafee/pegasus";
import errorSetup from "../../components/_assets/images/shared/errorSetup.svg";

export default function ErrorMessage({
  error,
  headerLabel,
  errorContent,
  onRetry,
  onDoItLater,
}) {
  const _S = (s) => `messages.${s}`;
  const showError = useShowDebugError();

  return (
    <div className="centerContent">
      <div className="slider__container">
        <img src={errorSetup} alt="ErrorSetup_Image" style={{ height: 200 }} />
      </div>
      <Heading className="mb-16">
        {headerLabel || <String id={_S`headerLabel`} />}
      </Heading>
      <Paragraph size="md">
        {errorContent || <String id={_S`errorContent`} />}
        {` `}
        <String id={_S`pleaseTryAgain`} />
      </Paragraph>
      <div className="mt-24">
        {onRetry && (
          <Button onClick={onRetry} size="sm">
            <String id={_S`tryAgain`} />
          </Button>
        )}
      </div>
      <div className="mt-24">
        {onDoItLater && (
          <Button
            variant="tertiary"
            shape="pill"
            width="normal"
            className="px-48"
            onClick={onDoItLater}
            size="sm"
          >
            <String id={_S`doItLater`} />
          </Button>
        )}
      </div>
      {showError && error.message && (
        <Paragraph
          size="md"
          className="text-secondary"
          style={{ wordBreak: "break-all" }}
        >
          <String id={_S`errorTextCode`} values={{ message: error.message }} />
        </Paragraph>
      )}
    </div>
  );
}
