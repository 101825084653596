import React from "react";
import { useSelector } from "react-redux";

//Rendering children only if event is mapped to an action

export function CheckActions({ events, children }) {
  const eventValues = Object.values(events);

  for (let i = 0, l = eventValues.length; i < l; i++) {
    const found = CheckAction({ event: eventValues[i], children: children });

    if (found) {
      return found;
    }
  }

  return null;
}

export default function CheckAction({ event, children }) {
  const eventActions = useSelector((state) => state.experience.event_actions);
  const action = eventActions[event];
  if (action === true) {
    return <> {children} </>;
  }

  if (
    Array.isArray(action) &&
    action.length > 0 &&
    !action[0].match(/^\$showIf\//g) //don't show conditional
  ) {
    return <> {children} </>;
  }
  return null;
}
