import React from "react";
import { connect, useSelector } from "react-redux";
import {
  ROUTE_SETTINGS_ABOUT,
  ROUTE_SETTINGS_VPN,
  ROUTE_SETTINGS_IDENTITY,
  ROUTE_SETTINGS_FEEDBACK,
  ROUTE_SETTINGS_ACCOUNT,
  ROUTE_SETTINGS_NOTIFICATIONS,
  ROUTE_SETTINGS_ANTIVIRUS,
} from "../app/routes";
import CheckFeature from "../Shared/CheckFeature";

import { FEATURE_IDENTITY, SETTINGS } from "../../constants/main";

// DOM imports

import {
  Sidebar,
  SidebarDivider,
  SidebarNavList,
  SidebarNavListIcon,
  SidebarNavListItem,
  SidebarTitle,
  SidebarSubTitle,
  Label,
  SidebarNavListButton,
  Subheading,
  Button,
} from "@mcafee/pegasus";
import { String } from "../../l10n/loader";
import { FEATURE_VPN, FEATURE_AV } from "../../constants/main";
import Logout from "../Logout/Logout";
import { publishEvent } from "../../store/thunks/experience";
import events from "../../experience/events";
import CheckAction from "../Shared/CheckAction";
import {
  ArrowBackRounded,
  FeedbackOutlined,
  FingerprintRounded,
  InfoOutlined,
  NotificationsActiveOutlined,
  SupervisorAccountRounded,
  VerifiedUserOutlined,
} from "@material-ui/icons";

const SettingsMenu = ({ pathname, publishEvent }) => {
  const { vpnSetup } = useSelector((state) => state.vpn);
  const _S = (str) => `settings.menu.${str}`;

  const menuGroups = [
    {
      title: "generalSettings",
      items: [
        {
          key: "MyAccountIcon",
          title: "account",
          icon: "supervisor_account",
          route: ROUTE_SETTINGS_ACCOUNT,
          event: events.settings.general.account,
          feature: null,
        },
        {
          key: "NotificationsIcon",
          title: "notifications",
          icon: "notifications_active",
          route: ROUTE_SETTINGS_NOTIFICATIONS,
          event: events.settings.general.notifications,
          feature: null,
        },
        {
          key: "AboutUsIcon",
          title: "about",
          icon: "info_outline",
          route: ROUTE_SETTINGS_ABOUT,
          event: events.settings.general.about,
          feature: null,
        },
      ],
    },
    {
      title: "serviceSettings",
      items: [
        {
          key: "Antivirus",
          title: "antivirus",
          icon: "VerifiedUserOutlined",
          event: events.settings.services.av,
          route: ROUTE_SETTINGS_ANTIVIRUS,
          feature: FEATURE_AV,
        },
        {
          key: "VPNIcon",
          title: "vpn",
          icon: "vpn-secure-outline",
          route: ROUTE_SETTINGS_VPN,
          event: events.settings.services.vpn,
          feature: FEATURE_VPN,
          disabled: !vpnSetup,
        },
        {
          key: "IdentityProtectionTab",
          title: "identity",
          icon: "fingerprint",
          event: events.settings.services.identity,
          route: ROUTE_SETTINGS_IDENTITY,
          feature: FEATURE_IDENTITY,
        },
        {
          key: "MonitoredEmails",
          title: "monitored",
          icon: "fingerprint",
          event: events.settings.services.monitoredEmails,
          route: ROUTE_SETTINGS_IDENTITY,
          feature: FEATURE_IDENTITY,
        },
      ],
    },
    {
      items: [
        {
          key: "SendFeedbackTab",
          title: "feedback",
          icon: "feedback",
          event: events.settings.feedback,
          route: ROUTE_SETTINGS_FEEDBACK,
        },
      ],
    },
  ];

  const renderIcon = (icon) => {
    switch (icon) {
      case "supervisor_account":
        return (
          <SupervisorAccountRounded className=" settingsMenu__icon mr-8" />
        );
      case "notifications_active":
        return (
          <NotificationsActiveOutlined className="settingsMenu__icon mr-8" />
        );
      case "info_outline":
        return <InfoOutlined className="settingsMenu__icon mr-8" />;
      case "fingerprint":
        return <FingerprintRounded className="settingsMenu__icon mr-8" />;
      case "feedback":
        return <FeedbackOutlined className="settingsMenu__icon mr-8" />;
      case "VerifiedUserOutlined":
        return <VerifiedUserOutlined className="settingsMenu__icon mr-8" />;
      default:
        return <SidebarNavListIcon icon={icon} className="mr-8"/>;
    }
  };

  return (
    <Sidebar className="sidebarContainer">
      <SidebarTitle>
        <Subheading size="lg">
          <CheckAction event={events.settings.back}>
            <Button
              title="Back"
              id="settingsButtonBack"
              variant="tertiary"
              onClick={() => {
                publishEvent(events.settings.back);
              }}
            >
              <ArrowBackRounded />
            </Button>
          </CheckAction>

          <String id="settings" />
        </Subheading>
      </SidebarTitle>
      <SidebarNavList>
        {menuGroups.map((group, index) => (
          <CheckFeature feature={group.items.map((i) => i.feature)} key={index}>
            {group.title && (
              <SidebarSubTitle>
                <String id={_S(group.title)} />
              </SidebarSubTitle>
            )}
            {group.items.map((item) => (
              <CheckFeature feature={item.feature} key={item.key}>
                <CheckAction event={item.event} key={item.key}>
                  <SidebarNavListItem
                    key={item.key}
                    data-testid={item.key}
                    active={item.route === pathname}
                    tabIndex="0"
                  >
                    <SidebarNavListButton
                      id={item.key}
                      active={item.route === pathname}
                      onClick={() => publishEvent(item.event)}
                      disabled={item.disabled}
                      onKeyDown={(e) => {
                        if ([32, 13].includes(e.keyCode)) {
                          publishEvent(item.event);
                        }
                      }}
                    >
                      {renderIcon(item.icon)}
                      <Label size="sm">
                        <String id={_S(item.title)} />
                      </Label>
                    </SidebarNavListButton>
                  </SidebarNavListItem>
                </CheckAction>
              </CheckFeature>
            ))}
            {group.title && <SidebarDivider />}
          </CheckFeature>
        ))}
        <Logout location={SETTINGS} />
      </SidebarNavList>
    </Sidebar>
  );
};

export default connect(
  (state) => ({
    ...state.router.location,
  }),
  { publishEvent }
)(SettingsMenu);
