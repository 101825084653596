import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Paragraph, Heading, Button } from "@mcafee/pegasus";
import Add_phone from "../../components/_assets/images/popUps/recievingTextMessages.svg";

import "./noPhoneFoundPopUp.scss";
import { useDispatch } from "react-redux";
import { showPopup } from "../../store/thunks/popups";
import { POPUP_ADD_PHONE_NUMBER } from "./constants";

export default function NoPhoneFoundPopUp({ onClose, onFinish }) {
  const _S = (str) => `popups.noPhoneFoundPopUp.${str}`;
  const dispatch = useDispatch();

  const addPhone = () => {
    dispatch(
      showPopup(POPUP_ADD_PHONE_NUMBER, {}, "", { overflow: "visible" })
    );
    onFinish();
  };

  return (
    <div className="noPhoneFoundPopUp">
      <img src={Add_phone} alt="" className="noPhoneFoundPopUp__image" />

      <Heading
        className="noPhoneFoundPopUp__title"
        size="xs"
        data-testid="noPhoneFoundPopUpHeader"
      >
        <String id={_S`header`} />
      </Heading>

      <div size="sm" data-testid="noPhoneFoundPopUpContent">
        <Paragraph className="noPhoneFoundPopUp__content">
          <String id={_S`content1`} />
        </Paragraph>
        <Paragraph className="noPhoneFoundPopUp__content">
          <String id={_S`content2`} />
        </Paragraph>
        <div className="mt-24 text-right">
          <Button
            className="ml-32"
            onClick={addPhone}
            id="AddPhoneNumberNow"
            data-testid="addPhoneNumberNow"
            size="sm"
          >
            <String id={_S`addPhoneNumber.button`} />
          </Button>
          <Button
            className="ml-32"
            variant="tertiary"
            onClick={onClose}
            id="AddPhoneNumberNowCancel"
            data-testid="addPhoneNumberNowCancelButton"
            size="sm"
          >
            <String id={_S`addPhoneNumber.cancel`} />
          </Button>
        </div>
      </div>
    </div>
  );
}
