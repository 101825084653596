import React from "react";
import { String } from "../../l10n/loader";

// Store
import { setDirtyPage } from "../../store/context";

import { useDispatch } from "react-redux";
// DOM
import { Button, Paragraph, Heading } from "@mcafee/pegasus";

const ConfirmBeforeLeavePagePopup = ({ onFinish, onCancel, action }) => {
  const S_ = (string) => `popups.confirmBeforeLeavePagePopup.${string}`;
  const dispatch = useDispatch();

  const onClickLeave = (e) => {
    dispatch(setDirtyPage(false));
    dispatch(action);
    onFinish();
  };

  const styles = {
    popUpStyles: {
      width: 550,
    },
  };

  return (
    <div
      style={styles.popUpStyles}
      className="pa-24 text-left flex direction-column "
    >
      <Heading size="xs" data-testid="confirmBeforeLeavePagePopupHeader">
        <String id={S_`header`} />
      </Heading>

      <Paragraph
        className="mt-8"
        size="sm"
        data-testid="confirmBeforeLeavePagePopupText"
      >
        <String id={S_`text`} />
      </Paragraph>
      <div className="mt-48 text-right">
        <Button
          className="ml-32"
          onClick={onCancel}
          id="PopUpStayButton"
          data-testid="confirmBeforeLeavePagePopupStay"
          size="sm"
        >
          <String id={S_`stay`} />
        </Button>
        <Button
          className="ml-32"
          variant="tertiary"
          onClick={onClickLeave}
          id="PopUpLeaveButton"
          data-testid="confirmBeforeLeavePagePopupLeave"
          size="sm"
        >
          <String id={S_`leave`} />
        </Button>
      </div>
    </div>
  );
};

export default ConfirmBeforeLeavePagePopup;
