import {
  fetchJson,
  fetchJsonCacheFirst,
  storeErrorDetails,
  stringifyJson,
  syncedInvoke,
  urlToSource,
} from "../../../src/utils";

import {
  dashboardCardsBegin,
  dashboardCardsFailure,
  dashboardCardsSuccess,
  updateMobileNumberBegin,
  updateMobileNumberSuccess,
  updateMobileNumberFailure,
  emailKeyCardBegin,
  emailKeyCardFailure,
  emailKeyCardSuccess,
  fetchMobileNumberBegin,
  fetchMobileNumberSuccess,
  fetchMobileNumberFailure,
  deleteMobileNumberBegin,
  deleteMobileNumberSuccess,
  deleteMobileNumberFailure,
} from "../dashboard";
import { refreshAccessToken } from "./auth";

import {
  API_SECURITY_MGMT,
  APP_ID,
  DEFAULT_AFF_ID,
  DEFAULT_LOCALE,
  HTTP_NO_CONTENT,
  VPN_STATE_CONNECTED,
  CACHE_NO_STORE,
  HTTP_POST,
  UA_ONBOARDING_SCAN_DONE,
  UA_ONBOARDING_BREACHES_FOUND,
  API_MESSAGING,
  FEATURE_IDENTITY,
  MTX_CARDS,
  HTTP_GET,
  HTTP_DELETE,
  HTTP_PUT,
  MTX_FETCH_MOBILE_NUMBER,
  HTTP_CREATED,
  HTTP_NOT_FOUND,
  FEATURE_VPN,
} from "../../constants/main";

import { getServiceUrl } from "./settings";
import { cardsMasterList } from "../../components/DashboardCards/config";
import { fetchDwsDashboard } from "./identity";

/**
 * Fetching home cards list ( cache first )
 * Update the redux stores with success or failure
 * @returns {{error?}}
 */
export const fetchDashboardCards = () => async (dispatch, getState) => {
  return syncedInvoke({ mutex: MTX_CARDS }, async () => {
    dispatch(dashboardCardsBegin());

    const { enabled: identityEnabled } = getState().experience.features[
      FEATURE_IDENTITY
    ];

    if (identityEnabled && getState().identity.breaches === null) {
      await dispatch(fetchDwsDashboard());
    }

    let cachedFound = false;
    try {
      /// to test:
      /// await delay and then throw the error
      /// remember to try multiple times

      const apiBaseUrl = await dispatch(getServiceUrl(API_MESSAGING));
      const state = getState();
      const {
        provision_id,
        aff_id,
        cspid,
        notifications,
        uwpVersion,
      } = state.context;

      const primary_email_monitoring =
        Array.isArray(state.identity.assets) && state.identity.assets.length > 0
          ? 1
          : 0;
      const secondary_email_monitoring =
        Array.isArray(state.identity.assets) && state.identity.assets.length > 1
          ? 1
          : 0;
      const vpn_status = state.vpn.state === VPN_STATE_CONNECTED ? 1 : 0;

      const {
        [UA_ONBOARDING_SCAN_DONE]: onBoardingScan,
        [UA_ONBOARDING_BREACHES_FOUND]: onboardingBreachesFound,
      } = state.auth.userActions;

      const onboard_dws_skipped = onBoardingScan !== true;

      const email_verification_breaches_found = onBoardingScan
        ? onboardingBreachesFound
          ? 1
          : 0
        : undefined;

      const email_verification_no_breaches_found = onBoardingScan
        ? onboardingBreachesFound
          ? 0
          : 1
        : undefined;

      const fetchingCacheFirst = await fetchJsonCacheFirst(
        `${apiBaseUrl}/api/v2/cards/from-current-context`,
        {
          method: HTTP_POST,
          headers: {
            Accept: "*/*",
            Authorization: () =>
              dispatch(refreshAccessToken()).then(
                (accessToken) => `Bearer ${accessToken}`
              ),
          },
          body: stringifyJson({
            user_global_reference_id: "",
            user_provision_id: provision_id,
            product_app_id: APP_ID,
            product_affiliate_id: aff_id || DEFAULT_AFF_ID,
            product_version: uwpVersion,
            product_idprotection_status: state.experience.features.ids.enabled
              ? 1
              : 0,
            product_vpn_status: state.experience.features[FEATURE_VPN].enabled ? 1 : 0,
            product_vpn_setup_status: state.vpn.vpnSetup ? 1 : 0,
            client_id: cspid,
            device_platform: state.device.info.osName,
            device_type: state.device.info.type,
            vpn_preferences: state.settings.vpn_profile,
            Event_Code: "", //"unsecure_wifi", "network_attack", ...etc
            push_notification_status: notifications ? 1 : 0,
            connected_network_type: "wifi",
            vpn_status,
            location_permission: 0,
            primary_email_monitoring,
            secondary_email_monitoring,
            email_verification_breaches_found,
            email_verification_no_breaches_found,
            onboard_dws_skipped,
          }),
        }
      );

      const handleResponse = (response) => {
        if (!response.ok) {
          throw response;
        }

        const { data, cached } = response;

        const apiCards = data.viewers
          ? data.viewers.map((c) => ({
              id: c.card_id,
              feature: c.feature,
              order: c.order,
            }))
          : [];

        const mergedCards = [
          ...apiCards,
          //Append master list cards ( excluding what is found in the apiCards )
          ...cardsMasterList.filter(
            (masterCard) =>
              !apiCards.find((apiCard) => apiCard.id === masterCard.id)
          ),
        ];

        //Sort by order
        mergedCards.sort((c1, c2) => {
          return c2.order > c1.order ? -1 : 1; //descending
        });

        //Store the results in the dashboard state
        dispatch(dashboardCardsSuccess({ cards: mergedCards, cached }));
      };

      const { value: cachedResponse } = await fetchingCacheFirst.next();

      if (cachedResponse) {
        cachedFound = true;
        handleResponse(cachedResponse);

        //Using .then() to skip waiting for for live response
        fetchingCacheFirst
          .next()
          .then(({ value: liveResponse }) => {
            handleResponse(liveResponse);
          })
          .catch((e) => {
            handleFailure(e);
          });
      } else {
        const liveResponse = await (await fetchingCacheFirst.next()).value;
        handleResponse(liveResponse);
      }
      return { ok: true };
    } catch (e) {
      //Network error, offline or malformed response
      return handleFailure(e);
    }

    function handleFailure(e) {
      const error = storeErrorDetails(e);
      dispatch(
        dashboardCardsFailure({
          ...error,
          cached: cachedFound,
        })
      );
      return { error };
    }
  });
};

/**
 * An action to request an email to enroll a new device
 * @param {string} email_address
 * @returns {{ok:boolean, error?}}
 */
export const emailKeyCard = (email_address) => async (dispatch) => {
  dispatch(emailKeyCardBegin());

  try {
    const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT));

    const fetchOptions = {
      method: HTTP_POST,
      cache: CACHE_NO_STORE,
      headers: {
        Accept: "*/*",
        Authorization: () => dispatch(refreshAccessToken()),
      },
      body: stringifyJson({
        aff_id: DEFAULT_AFF_ID,
        culture: DEFAULT_LOCALE,
        email_address,
        client: "PPS",
        caller_type: "SendLink",
      }),
    };

    const apiUrl = `${apiBaseUrl}/keycard/v1/email/${APP_ID}`;

    const response = await fetchJson(apiUrl, fetchOptions);

    const { status, ok } = response;

    if (ok && status === HTTP_CREATED) {
      dispatch(emailKeyCardSuccess());
      return { ok };
    }

    throw Object({
      status,
      source: urlToSource(apiUrl),
    });
  } catch (e) {
    const error = storeErrorDetails(e);
    dispatch(emailKeyCardFailure(error));
    return { ok: false, error };
  }
};

/**
 * Posts a user preferred phone number to get contacted on
 * Updates state.dashboard and returns ok or error if failed
 * @param {string} phone
 * @param {string} otpToken
 * @returns {ok:boolean, phone, error?}
 */
export const updateMobileNumber = (phone, otpToken) => async (dispatch) => {
  dispatch(updateMobileNumberBegin({ phone }));

  try {
    const accessToken = otpToken
      ? otpToken
      : await dispatch(refreshAccessToken());

    const options = {
      method: HTTP_PUT,
      headers: {
        Authorization: accessToken,
      },
      body: stringifyJson({
        settings: [
          {
            value: phone,
            name: "preferred_sms",
          },
        ],
      }),
    };
    const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT));
    const response = await fetchJson(
      `${apiBaseUrl}/setting/v2/account/${APP_ID}/comm_preference`,
      options
    );

    if (response.status === HTTP_NO_CONTENT) {
      dispatch(updateMobileNumberSuccess(phone));
      return { ok: true, phone };
    } else {
      throw response;
    }
  } catch (e) {
    const error = storeErrorDetails(e);
    dispatch(
      updateMobileNumberFailure({
        ...error,
        phone,
      })
    );
    return { ok: false, phone, error };
  }
};

/**
 * Delete preferred contact phone number
 * updates state.dashboard values
 * and returns ok or error upon failure
 * @returns {{ok:boolean,error?}}
 */
export const deleteMobileNumber = () => async (dispatch) => {
  dispatch(deleteMobileNumberBegin());

  try {
    const accessToken = await dispatch(refreshAccessToken());

    const options = {
      method: HTTP_DELETE,
      cache: CACHE_NO_STORE,
      headers: {
        Authorization: accessToken,
      },
    };
    const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT));
    const response = await fetchJson(
      `${apiBaseUrl}/setting/v1/account/${APP_ID}/comm_preference/preferred_sms`,
      options
    );

    if (response.status === HTTP_NO_CONTENT) {
      dispatch(deleteMobileNumberSuccess());
      return { ok: true };
    }
    throw response;
  } catch (e) {
    const error = storeErrorDetails(e);
    dispatch(deleteMobileNumberFailure(error));
    return { ok: false, error };
  }
};

/**
 * Fetch remote settings API to get the registered phone number to receive sms messages
 * @returns {{value,error}}
 */
export const fetchMobileNumber = () => async (dispatch) => {
  return syncedInvoke(
    { mutex: MTX_FETCH_MOBILE_NUMBER, reuseActiveCallResult: true },
    async () => {
      dispatch(fetchMobileNumberBegin());

      try {
        const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT));

        const fetchingCacheFirst = await fetchJsonCacheFirst(
          `${apiBaseUrl}/setting/v1/account/${APP_ID}/comm_preference`,
          {
            method: HTTP_GET,
            headers: {
              Authorization: () => dispatch(refreshAccessToken()),
            },
          }
        );

        const { value: cachedResponse } = await fetchingCacheFirst.next();

        const handleResponse = ({ data: { settings }, cached = false }) => {
          const preferred_sms = settings.find(
            (setting) => setting.name === "preferred_sms"
          );

          if (preferred_sms) {
            dispatch(fetchMobileNumberSuccess({ ...preferred_sms, cached }));
            return { value: preferred_sms.value };
          }
          dispatch(fetchMobileNumberSuccess({ value: null, cached }));
          return { value: null };
        };

        if (cachedResponse) {
          //Using .then() to skip waiting for for live response
          fetchingCacheFirst
            .next()
            .then(({ value: liveResponse }) => handleResponse(liveResponse))
            .catch((e) => {
              dispatch(
                fetchMobileNumberFailure({
                  ...storeErrorDetails(e),
                  cached: true,
                })
              );
            });

          return handleResponse(cachedResponse);
        } else {
          //Cached data not found, wait for live response
          const liveResponse = await (await fetchingCacheFirst.next()).value;
          return handleResponse(liveResponse);
        }
      } catch (e) {
        if (e.status === HTTP_NOT_FOUND) {
          dispatch(fetchMobileNumberSuccess({ value: null }));
          return { value: null };
        }
        dispatch(fetchMobileNumberFailure(storeErrorDetails(e)));
        return { error: e };
      }
    }
  );
};
