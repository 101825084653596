// React
import React from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import backgroundImage from "../_assets/images/dashboard/cards/emailVerificationNoBreaches.svg";

// utils
import { RECOMMENDED } from "../../constants/main";
import { ROUTE_IDENTITY, ROUTE_SCAN_EMAIL_RESULT } from "../app/routes";

export default function EmailVerificationNoBreachesFound({ id }) {
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.auth);
  const { assets } = useSelector((state) => state.identity);

  const onPrimaryAction = (e) => {
    dispatch(
      push({
        pathname:
          assets && assets.includes(email)
            ? ROUTE_IDENTITY
            : ROUTE_SCAN_EMAIL_RESULT,
      })
    );
  };

  const card = {
    id,
    backgroundImage,
    tagText: RECOMMENDED,
    stringValues: {
      Email: email,
    },
    primaryActionStringId: "yesLetsDoIt",
    primaryActionDataTestId: "emailVerificationNoBreachesFoundYesLetsDoIt",
    onPrimaryAction,
  };

  return <DashboardCard {...card} />;
}
