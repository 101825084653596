// React
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// Store
import { showPopup } from "../../store/thunks/popups";

// DOM
import "./settings.scss";
import "./identitySettingsPage.scss";

import { Grid, Row, Col, Subheading, Button } from "@mcafee/pegasus";

// utils
import { String } from "../../l10n/loader";
import { POPUP_DWS_LEARN_MORE } from "../PopUps/constants";

// Custom components
import NavbarLayout from "../NavBar/NavbarLayout";
import SettingsMenu from "./SettingsMenu";
// import CheckFeature from "../Shared/CheckFeature";
import {
  createQueryString,
  useCheckPrimaryEmail,
  useIsSignedIn,
} from "../../utils";
import Unauthorized from "../Shared/Unauthorized";

import IdentityMonitoredEmails from "./IdentityMonitoredEmails";

import { InfoOutlined } from "@material-ui/icons";
import { replace } from "connected-react-router";
import { ROUTE_SCAN_EMAIL } from "../app/routes";

export default function IdentitySettingsPage() {
  const _S = (str) => `settings.identity.${str}`;

  const dispatch = useDispatch();

  //Using a custom hook to render upon detecting isMonitored primary email
  const { isMonitored } = useCheckPrimaryEmail();

  /**
   * redirect to SCAN_EMAIL route
   * if primary email is not monitored
   * in order to scan it
   */
  useEffect(() => {
    if (isMonitored === false) {
      dispatch(
        replace({
          pathname: ROUTE_SCAN_EMAIL,
          search: createQueryString({ hide_skip: true }),
        })
      );
    }
  }, [isMonitored, dispatch]);

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  const handleAboutIdentity = () => {
    dispatch(showPopup(POPUP_DWS_LEARN_MORE));
  };

  return (
    <NavbarLayout
      id="Settings"
      className="pa-0"
      classNameLayout="pa-0 bg-white"
    >
      <Grid>
        <Row style={{ height: "100%" }}>
          <Col sm xs={12} className="flex">
            <SettingsMenu />
          </Col>
          {isMonitored && (
            <Col
              sm={8}
              xs={12}
              className="flex direction-column align-items-start bg-white pa-24 grow-1"
            >
              <Subheading size="lg" className="mb-24">
                <String id={_S`title`} />
              </Subheading>
              <IdentityMonitoredEmails />
              <Button
                variant="tertiary"
                size="sm"
                className="text-info"
                onClick={handleAboutIdentity}
                id="AboutIdentityProtection"
              >
                <InfoOutlined className="identitySettings__aboutIcon" />
                <String id={_S`aboutIdentity`} />
              </Button>
            </Col>
          )}
        </Row>
      </Grid>
    </NavbarLayout>
  );
}
