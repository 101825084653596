import {
  API_SECURITY_MGMT,
  CSPID_MATCH,
  EMAIL_MATCH,
  DEVICE_ID_MATCH,
  SESSION_ID_MATCH,
  ASSET_ID_MATCH,
  GUID_MATCH,
  PLAIN_PASSWORD_MATCH,
  HTTP_POST,
  JWT_MATCH,
  LOG_LEVEL_VERBOSE,
  LOG_LEVEL_INFO,
  LOG_LEVEL_ERROR,
  LOG_LEVEL_CRITICAL,
  LOG_LEVEL_WARNING,
} from "./constants/main";
import {
  analyticsSendEvent,
  analyticsUpdateContext,
  getContextValues,
} from "./utils";
import { DEFAULT_PPS_UI_CONTEXT } from "./constants/analytics";
import { delayedResult, fetchJson, stringifyJson } from "./utils";
import { store } from "./store/store";
import { getServiceUrl } from "./store/thunks/settings";

const defaultTimeout = 10000;

/**
 * Invoking a service or function in the UWP (or similar) container
 * Will try to use io-bus outside UWP webview in debugging mode
 * @param {string} action function or action Id
 * @param {*} params payload to the invoked function
 * @param {number} timeout number of milliseconds to wait for before timing out
 * @returns invoked function response
 */
export const uwpInvoke = async (
  action,
  params = {},
  timeout = defaultTimeout
) => {
  const { communicator, ioBus } = window;
  const { automation } = store.getState().context;

  uwpLog(`~uwpInvoke(${action},${stringifyJson(params)},timeout=${timeout})`);

  if (automation && automation[action]) {
    return await delayedResult(automation[action]);
  } else if (communicator) {
    return new Promise((onSuccess, onReject) => {
      communicator.invoke(
        action,
        stringifyJson(params),
        (results) => {
          uwpLog(
            `~uwpInvokeResponse(${action},${stringifyJson(params)})=${results}`
          );
          onSuccess(JSON.parse(results));
        },
        (err) => {
          uwpLog(
            `~uwpInvokeRejected(${action},${stringifyJson(
              params
            )})=${stringifyJson(err.message || err.error)}`
          );
          onReject(err);
        }
      );
    });
  } else if (ioBus && ioBus.is_connected()) {
    try {
      const response = await ioBus.request(
        "uwpInvoke",
        { id: action, params },
        timeout
      );
      uwpLog(
        `~uwpInvokeResponse(${action},${stringifyJson(params)})=${stringifyJson(
          response.data
        )}`
      );
      return response.data;
    } catch (e) {
      uwpLog(`~uwpInvoke(${action}) failed => ${e.message || e.error}`);
    }
  }

  uwpLog(`~uwpInvoke(${action}) failed (no UWP connection)`);

  return Promise.reject({
    source: "communicator_invoke_" + action.replace(/\./g, "_"),
    code: 0,
    message: "communicator.invoke is not available",
  });
};

/**
 * Publishing a message to the UWP (or similar) container
 * Will try to use io-bus outside UWP webview in debugging mode
 * @param {string} msg message ID
 * @param {*} params published payload
 */
export const uwpPublish = async (msg, params = {}) => {
  const { communicator, ioBus } = window;

  uwpLog(`~uwpPublish(${msg},${stringifyJson(params)})`);

  if (communicator) {
    communicator.publish(msg, stringifyJson(params));
  } else if (ioBus && ioBus.is_connected()) {
    ioBus.publish("uwpPublish", { msg, params });
  }
};
const uwpSubscriptions = {};

/**
 * Create a listener to a message published by UWP (or similar) container
 * @param {string} eventId message, event or topic Id
 * @param {function} callback callback receiving this message
 * @returns
 */
export const uwpSubscribe = (eventId, callback) => {
  const { communicator, ioBus } = window;

  if (communicator && communicator.subscribe) {
    return new Promise((onSuccess, onReject) => {
      let subscription_id;
      communicator.subscribe(
        eventId,
        (sub_id) => {
          if (sub_id) {
            subscription_id = sub_id;
            uwpLog(`~uwpSubscribe(${eventId}@${subscription_id})`);
            uwpSubscriptions[sub_id] = eventId;
            onSuccess(sub_id);
          } else {
            onReject({ message: `failed to subscribe to ${eventId}` });
          }
        },
        (msg) => {
          uwpLog(`~uwpSubscribeMsg(${eventId}@${subscription_id})=>${msg}`);
          callback(JSON.parse(msg));
        }
      );
    });
  } else if (ioBus && ioBus.is_connected()) {
    //subscribe remotely
    return new Promise((resolve, reject) => {
      ioBus
        .request("uwpSubscribe", { id: eventId }, defaultTimeout)
        .then((response) => {
          const subscription_id = response.data;
          window.ioBus.on(eventId, (msg) => {
            uwpLog(
              `~uwpSubscribeMsg(${eventId}@${subscription_id})=>${stringifyJson(
                msg.data
              )}`
            );
            callback(msg.data);
          });
          uwpSubscriptions[subscription_id] = eventId;
          uwpLog(`~uwpSubscribe(${eventId}@${subscription_id})`);
          resolve(subscription_id); //return id for unsubscribe
        }, reject);
    });
  }

  return Promise.reject({
    source: "communicator_subscribe_" + eventId,
    code: 0,
    message: "communicator.subscribe is not available",
  });
};

/**
 * Stop listening to UWP ( or similar ) container published message
 * @param {string} subscription_id Previously created subscription Id
 */
export const uwpUnsubscribe = (subscription_id) => {
  const { communicator, ioBus } = window;

  uwpLog(
    `~uwpUnsubscribe(${uwpSubscriptions[subscription_id]}@${subscription_id})`
  );

  if (subscription_id) {
    if (communicator && communicator.unsubscribe) {
      communicator.unsubscribe(subscription_id);
    } else if (ioBus && ioBus.is_connected()) {
      //unsubscribe remotely
      ioBus.publish("uwpUnsubscribe", { id: subscription_id });
    }
  }
};

export const uwpGetSecurityToken = async (clientId, body) => {
  const { ioBus, securityTokenManagerWrapper } = window;
  var cspSecurityToken = null;

  uwpLog("~uwpGetSecurityToken()");

  try {
    if (securityTokenManagerWrapper) {
      cspSecurityToken = securityTokenManagerWrapper.getSecurityToken(
        clientId || "",
        body
      );
    } else if (ioBus) {
      const results = await ioBus.request(
        "uwpGetSecurityToken",
        { clientId, body },
        defaultTimeout
      );
      cspSecurityToken = results.data.securityToken;
    }
  } catch (e) {
    uwpLog(e.message || e.error, LOG_LEVEL_ERROR);
  }
  uwpLog(`~uwpGetSecurityToken response=>${stringifyJson(cspSecurityToken)}`);
  return cspSecurityToken;
};

export const getClientId = async (dispatch, deviceGUID) => {
  try {
    const body = stringifyJson({});

    const cspSecurityToken = await uwpGetSecurityToken(deviceGUID, body);

    if (cspSecurityToken === null || cspSecurityToken.size === 0) {
      uwpLog("Failed to get cspSecurityToken");
      return null;
    }
    const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT));
    const { data: result } = await fetchJson(
      `${apiBaseUrl}/device-store/v1/deviceid`,
      {
        method: HTTP_POST,
        headers: {
          ak: cspSecurityToken.ak,
          cd: cspSecurityToken.cd,
          ac: cspSecurityToken.ac,
          st: cspSecurityToken.st,
          sv: cspSecurityToken.sv,
        },
        body,
      }
    );

    return result.client_id;
  } catch (e) {
    uwpLog(e.message || e.error);
    return null;
  }
};

export const uwpOpenWindowsSettingPage = (
  settingsId = "ms-settings:network-wifi"
) => {
  const { ioBus, communicator } = window;
  try {
    if (communicator && communicator.openWindowsSettingPage) {
      uwpLog(`~uwpOpenWindowsSettingPage(${settingsId})`);
      communicator.openWindowsSettingPage(settingsId);
    } else if (ioBus && ioBus.is_connected()) {
      //Try remote execution if exists
      uwpLog(`~uwpOpenWindowsSettingPage(${settingsId})->Remote`);
      ioBus.publish("uwpOpenWindowsSettingPage", { settingsId });
    } else {
      uwpLog(
        `~uwpOpenWindowsSettingPage(${settingsId}) failed (no UWP connection)`
      );
    }
  } catch (e) {
    uwpLog(e.message || e.error, LOG_LEVEL_ERROR);
  }
};

export const uwpLog = (msg, logLevel = LOG_LEVEL_VERBOSE) => {
  const { communicator, ioBus } = window;
  // if (!isDevMode()) {
  if (true) {
    msg = msg
      .replace(GUID_MATCH, `GGG`)
      .replace(JWT_MATCH, `JJJ`)
      .replace(CSPID_MATCH, `CCC`)
      .replace(DEVICE_ID_MATCH, `DDD`)
      .replace(SESSION_ID_MATCH, `SSS`)
      .replace(ASSET_ID_MATCH, `AAA`)
      .replace(PLAIN_PASSWORD_MATCH, `"password":"***"`)
      .replace(EMAIL_MATCH, `@@@`);
  }

  switch (logLevel) {
    case LOG_LEVEL_INFO:
      console.info(msg);
      break;
    case LOG_LEVEL_WARNING:
      console.warning(msg);
      break;
    case LOG_LEVEL_ERROR:
      console.error(msg);
      break;
    case LOG_LEVEL_CRITICAL:
      console.error(msg);
      break;
    default:
      logLevel = LOG_LEVEL_VERBOSE;
      console.log(msg);
  }
  if (communicator) {
    try {
      communicator.log(logLevel, msg);
    } catch (e) {
      return;
    }
  } else if (ioBus && ioBus.is_connected()) {
    ioBus.publish("uwLog", { msg, type: logLevel });
  }
};

export const uwpSendTelemetry = (eventName, valueList = {}) => {
  const { ioBus, communicator } = window;

  const jsonValueList = stringifyJson(valueList);
  const jsonDynamicContextValue = stringifyJson(getContextValues());

  if (communicator && communicator.sendTelemetry) {
    uwpLog(
      `uwpSendTelemetry(${eventName}, ${jsonValueList}, ${jsonDynamicContextValue})`
    );
    communicator.sendTelemetry(
      eventName,
      jsonValueList,
      jsonDynamicContextValue
    );
  } else if (ioBus && ioBus.is_connected()) {
    uwpLog(
      `uwpSendTelemetry(${eventName}, ${jsonValueList}, ${jsonDynamicContextValue})->remote`
    );
    ioBus.publish("uwpSendTelemetry", {
      eventName,
      jsonValueList,
      jsonDynamicContextValue,
    });
  } else {
    uwpLog(
      `uwpSendTelemetry(${eventName}, ${jsonValueList}, ${jsonDynamicContextValue})->web`
    );
    analyticsUpdateContext(DEFAULT_PPS_UI_CONTEXT);
    analyticsSendEvent(eventName, valueList);
  }
};

export const uwpUpdateSharedContext = (valueList) => {
  const { ioBus, communicator } = window;

  const jsonValueList = stringifyJson(valueList);

  if (communicator && communicator.updateTelemetrySharedContext) {
    uwpLog(`~uwpUpdateSharedContext(${jsonValueList})`);
    communicator.updateTelemetrySharedContext(jsonValueList);
  } else if (ioBus && ioBus.is_connected()) {
    uwpLog(`~uwpUpdateSharedContext(${jsonValueList})->remote`);
    ioBus.publish("uwpUpdateSharedContext", { valueList });
  } else {
    analyticsUpdateContext(valueList);
  }
};
