import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading } from "@mcafee/pegasus";

import "./passwordTipsPopup.scss";

const passwordTipsPopup = ({ onCancel }) => {
  const _S = (str) => `popups.passwordTips.${str}`;

  return (
    <div className="passwordTipsPopup">
      <Heading size="xs" className="passwordTipsPopup__title">
        <String id={_S`header`} />
      </Heading>

      <Paragraph
        size="sm"
        data-testid="PasswordTipsPopupContent"
        className="passwordTipsPopup__content"
      >
        <String id={_S`content`} />
      </Paragraph>

      {[1, 2, 3, 4].map((item) => (
        <Paragraph
          size="sm"
          key={`point${item}`}
          className="passwordTipsPopup__content"
        >
          <span className="passwordTipsPopup__bullet">•</span>
          <String id={_S(`point${item}`)} />
        </Paragraph>
      ))}

      <Button
        onClick={onCancel}
        id="PasswordTipsPopupGotIt"
        data-testid="passwordTipsPopupGotItButton"
        size="sm"
        className="passwordTipsPopup__button"
      >
        <String id="popups.gotIt" />
      </Button>
    </div>
  );
};

export default passwordTipsPopup;
