import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  lastOverlayResults: null,
};

const slice = createSlice({
  name: "@overlays",
  initialState,
  reducers: {
    openOverlay: (state, action) => {
      state.list = [...state.list, action.payload];
      state.lastOverlayResults = null;
    },
    closeOverlay: (state, action) => {
      const { overlay, results } = action.payload;
      state.list = state.list.filter((item) => item.overlay !== overlay);
      state.lastOverlayResults = results;
    },
  },
  extraReducers: {},
});

export const { openOverlay, closeOverlay } = slice.actions;

export default slice.reducer;
