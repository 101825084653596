//Data to be provided by remote config

import {
  CARD_ACCOUNT_BREACHED,
  CARD_UNSAFE_WIFI_ALERT,
  CARD_OPEN_WIFI_ALERT,
  CARD_ADD_VPN_RECOMMENDED,
  CARD_ONBOARD_DWS_SKIPPED,
  CARD_AV_TURN_ON_RTS,
  CARD_AV_FOUND_THREATS,
  CARD_AV_PROTECTING_YOU
} from "./constants";

//// Dashboard Cards master list
//// NGM API would define different orders, and could add more cards

export const cardsMasterList = [
  { id: CARD_UNSAFE_WIFI_ALERT, order: 0 }, //Client “show” rule exists
  { id: CARD_ACCOUNT_BREACHED, order: 0 }, //Client “show” rule exists
  { id: CARD_OPEN_WIFI_ALERT, order: 0 }, //Client “show” rule exists
  { id: CARD_ADD_VPN_RECOMMENDED, order: 0 }, //Client “show” rule exists
  { id: CARD_ONBOARD_DWS_SKIPPED, order: 100 }, //Client “show” rule exists
  { id: CARD_AV_TURN_ON_RTS, order: 0 },
  { id: CARD_AV_FOUND_THREATS, order: 0 },
  { id: CARD_AV_PROTECTING_YOU, order: 1 },
  /* 
  { id: CARD_MONITOR_NEW_EMAIL, order: 101 }, //Client “show” rule exists
  { id: CARD_ADD_NOTIFICATION_PERMISSION, order: 100 }, //Client “show” rule exists
  { id: CARD_MISSING_PHONE_NUMBER, order: 107 },
  { id: CARD_PROTECT_NEW_DEVICE, order: 106 },
  { id: CARD_ADD_VPN_RECOMMENDED, order: 102 }, //Client “show” rule exists
  { id: CARD_MONITOR_NEW_EMAIL, order: 104 },
  { id: CARD_VPN_LEARN_MORE, order: 105 },
  { id: CARD_EMAIL_VERIFICATION_NO_BREACHES_FOUND, order: 106 },
  { id: CARD_EMAIL_VERIFICATION_BREACHES_FOUND, order: 107 },
  { id: CARD_BREACH_SMS_NOTIFICATION, order: 103 },
  { id: CARD_PASSWORD_CHANGE, order: 100 },
  { id: CARD_BREACH_LEARN_MORE, order: 100 },
  { id: CARD_IDENTITY_MONITOR, order: 100 },
  { id: CARD_ABOUT_PPS_INFO, order: 100 },
  { id: CARD_IDENTITY_SECURE, order: 100 },
  { id: CARD_PPS_SOFTWARE_OUTDATED, order: 0 },*/
];
