import React, { useState } from "react";
import { Button } from "@mcafee/pegasus";

export default function Survey({ handleFinish }) {
  const [rating, setRating] = useState(0);
  const [startTime] = useState(Date.now());
  let comments;
  const submitRating = (e) => {
    handleFinish({
      rating,
      comments: comments.value,
      duration: Math.floor((Date.now() - startTime) / 1000),
    });
    //submit rating and why.value
  };
  return (
    <div className="PopupFrame Survey">
      <div className="PopupTitle" data-testid="surveyHeader">
        How are we doing?
      </div>
      <div className="PopupBody">
        <div className="PopupMessage" data-testid="surveyContent">
          Compared to before you had McAfee, how protected do you feel?
        </div>
        <div className="RatingButtons" data-testid="surveyRatingButtons">
          {[1, 2, 3, 4, 5].map((r) => {
            return (
              <Button
                key={r}
                variant={r === rating ? "primary" : "secondary"}
                onClick={(e) => {
                  comments.focus();
                  setRating(r);
                }}
                data-testid={`surveyRatingButton${r}`}
                size="sm"
              >
                {r}
              </Button>
            );
          })}
        </div>
        <div className="RatingDescription" data-testid="surveyDescription">
          <div>Much less protected</div>
          <div>Much more protected</div>
        </div>
        <div className="TextInputFrame" data-testid="surveyComments">
          <textarea
            ref={(r) => {
              comments = r;
            }}
            placeholder="Why did you select this rating?"
          />
        </div>
        <div className="ActionButtons">
          <Button
            onClick={submitRating}
            disabled={rating === 0}
            data-testid="surveySubmitButton"
            size="sm"
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
