import { createSlice } from "@reduxjs/toolkit";
import {
  DEFAULT_LEGAL_VERSION,
  DEFAULT_PRIVACY_VERSION,
  RTL_DIRECTION,
  LTR_DIRECTION,
} from "../constants/main";
import { signOutSuccess, signInSuccess } from "./auth";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  progress: false,
  error: null,
  eulaAcceptError: null,

  fetchingClaims: false,
  fetchingClaimsError: null,
  provision_id: null,
  aff_id: null,
  uwpVersion: null,
  apiLevel: 0,

  packageId: null,
  packageName: "McAfee Security",

  sc_carrier_id: null,
  sc_product_id: null,
  split_product_id: null,

  fetchingLegalInfo: false,
  fetchingLegalInfoError: null,
  fetchingPrivacyInfo: false,
  fetchingPrivacyInfoError: null,

  locale: navigator.language,
  direction: LTR_DIRECTION,
  cspid: null, // note: cspid also stands for deviceId
  deviceGUID: null,
  notifications: null,

  doneWelcome: null,
  doneStrings: null,
  eulaAccepted: null,
  oauthClientId: null,
  doneTrialPrompt: null,
  doneWssPrompt: null,

  doneDetectWss: null,
  wssCallStatus: null,
  wssInstalled: null,
  wssVariation: null,

  legalUrl: null,
  legalVersion: DEFAULT_LEGAL_VERSION,
  privacyUrl: null,
  privacyVersion: DEFAULT_PRIVACY_VERSION,

  isUwpConnected: false,
  devMode: false,
  socketOn: false,

  automation: null,
  userActions: {},
  dirtyPage: false,

  historyIndex: 0,
  historyKeys: [],
};

const readVars = (state, payload) => {
  const {
    locale = state.locale,
    doneWelcome = state.doneWelcome,
    uwpVersion = state.uwpVersion,
    notifications = state.notifications,
    aff_id = state.aff_id,
    eulaAccepted = state.eulaAccepted,
    deviceGUID = state.deviceGUID,
    cspid = state.cspid,
    oauthClientId = state.oauthClientId,
    apiLevel = state.apiLevel,
    doneTrialPrompt = state.doneTrialPrompt,
    doneWssPrompt = state.doneWssPrompt,
    devMode = state.devMode,
  } = payload;
  state.locale = locale;
  state.devMode = devMode;
  state.direction = assessLocaleDirection(locale);
  state.aff_id = aff_id;
  state.uwpVersion = uwpVersion;
  state.notifications = notifications;
  state.doneWelcome = doneWelcome;
  state.eulaAccepted = eulaAccepted;
  state.deviceGUID = deviceGUID;
  state.cspid = cspid;
  state.oauthClientId = oauthClientId;
  state.apiLevel = apiLevel;
  state.doneTrialPrompt = doneTrialPrompt;
  state.doneWssPrompt = doneWssPrompt;
};

export const assessLocaleDirection = (locale) => {
  const [language] = locale ? locale.split("-") : [];

  const availableLanguagesThatUseRTL = ["ar", "he"];

  return availableLanguagesThatUseRTL.includes(language)
    ? RTL_DIRECTION
    : LTR_DIRECTION;
};

// Reducer
const slice = createSlice({
  name: "@context",
  initialState,
  reducers: {
    doneWelcomeBegin: (state) => {
      state.doneWelcome = false;
    },
    doneWelcomeSuccess: (state) => {
      state.doneWelcome = true;
    },
    doneWelcomeFailed: (state) => {
      state.doneWelcome = false;
    },
    doneLoadingStrings: (state) => {
      state.doneStrings = true;
    },
    doneDetectingWSS: (state, action) => {
      state.doneDetectWss = true;
      state.wssCallStatus = action.payload.status;
      state.wssInstalled = action.payload.installed;
      state.wssVariation = action.payload.display_name;
    },
    doneShowingWssPrompt: (state) => {
      state.doneWssPrompt = true;
    },
    eulaAcceptedBegin: (state) => {
      state.eulaAccepted = false;
      state.eulaAcceptError = null;
    },
    eulaAcceptedSuccess: (state) => {
      state.eulaAccepted = true;
      state.eulaAcceptError = null;
    },
    eulaAcceptedFailure: (state, action) => {
      state.eulaAccepted = false;
      state.eulaAcceptError = action.payload;
    },
    fetchContextBegin: (state) => {
      state.progress = true;
      state.error = null;
    },
    fetchContextFailure: (state, action) => {
      state.progress = false;
      state.error = action.payload.error;
      readVars(state, action.payload);
    },
    fetchContextSuccess: (state, action) => {
      state.progress = false;
      readVars(state, action.payload);
    },
    legalInfoBegin: (state) => {
      state.fetchingLegalInfo = true;
      state.fetchingLegalInfoError = null;
    },
    legalInfoSuccess: (state, action) => {
      state.fetchingLegalInfo = false;
      state.fetchingLegalInfoError = null;
      state.legalUrl = action.payload.legalUrl;
      state.legalVersion = action.payload.eulaVersion;
    },
    legalInfoFailure: (state, action) => {
      state.fetchingLegalInfo = false;
      state.fetchingLegalInfoError = action.payload.error;
      state.legalUrl = action.payload.legalUrl;
      state.legalVersion = action.payload.eulaVersion;
    },
    privacyInfoBegin: (state) => {
      state.fetchingPrivacyInfo = true;
      state.fetchingPrivacyInfoError = null;
    },
    privacyInfoSuccess: (state, action) => {
      state.fetchingPrivacyInfo = false;
      state.fetchingPrivacyInfoError = null;
      state.privacyUrl = action.payload.privacyUrl;
      state.privacyVersion = action.payload.privacyVersion;
    },
    privacyInfoFailure: (state, action) => {
      state.fetchingPrivacyInfo = false;
      state.fetchingPrivacyInfoError = action.payload.error;
      state.privacyUrl = action.payload.privacyUrl;
      state.privacyVersion = action.payload.privacyVersion;
    },
    setUwpConnected: (state, action) => {
      const { uwp, socket } = action.payload;
      if (uwp !== undefined) {
        state.isUwpConnected = uwp;
      }
      if (socket !== undefined) {
        state.socketOn = socket;
      }
    },
    /**
     * Register fake API responses.
     * Sample fake response automation:
     * reduxStore.dispatch({type:"@context/startAutomation",payload:{"v1/auth/token":{status:402, body:{details:"Invalid token"}}}})
     * reduxStore.dispatch({type:"@context/startAutomation",payload:{"v1/auth/logout":{status:402, body:{details:"Invalid token"}}}})
     * reduxStore.dispatch({type:"@context/startAutomation",payload:{"v1/auth/logout":{status:500, body:{details:"Server Error"}}}})
     * @param {Object} state current state
     * @param {{type,payload} action action data
     */
    startAutomation: (state, action) => {
      state.automation = Object.assign(state.automation || {}, action.payload);
    },
    resetAutomation: (state, action) => {
      state.automation = null;
    },
    claimsBegin: (state) => {
      state.fetchingClaims = true;
      state.fetchingClaimsError = null;
    },
    claimsSuccess: (state, action) => {
      state.fetchingClaims = false;
      state.provision_id = action.payload.provision_id;
      state.aff_id = action.payload.aff_id;
    },
    claimsFailure: (state, action) => {
      state.fetchingClaims = false;
      state.fetchingClaimsError = action.payload;
    },
    saveRemoteConfigVars: (state, action) => {
      state.sc_carrier_id = action.payload.sc_carrier_id;
      state.sc_product_id = action.payload.sc_product_id;
      state.split_product_id = action.payload.split_product_id;
      state.packageId = action.payload.pkgId;
      state.packageName = action.payload.packageName;
    },
    setDirtyPage: (state, action) => {
      const dirty = typeof action.payload === "boolean" ? action.payload : true;
      state.dirtyPage = dirty;
    },
    setLocale: (state, action) => {
      state.locale = action.payload;
      state.direction = assessLocaleDirection(action.payload);
    },
  },
  extraReducers: {
    [signOutSuccess]: (state) => {
      state.eulaAccepted = false;
    },
    [signInSuccess]: (state) => {
      state.doneTrialPrompt = true;
    },
    [LOCATION_CHANGE]: (state, action) => {
      const {
        action: navAction,
        location: { key: pageKey },
      } = action.payload;
      if (navAction === "PUSH") {
        //New navigation, push the page key in the current index
        state.historyKeys.length = state.historyIndex; //truncate to current index
        state.historyKeys.push(pageKey);
        state.historyIndex = state.historyKeys.length;
      } else if (navAction === "REPLACE") {
        if (state.historyIndex > 0) {
          state.historyKeys.length = state.historyIndex + 1; //truncate to current index
          state.historyKeys[state.historyIndex - 1] = pageKey; //replace last page key with the new one
        }
      } else {
        //History navigation "POP"
        //Find the current index, if not found (-1), new index would be 0
        const newIndex = state.historyKeys.indexOf(action.payload.location.key);
        state.historyIndex = newIndex + 1;
      }
    },
  },
});

export const {
  openUrl,

  fetchContextBegin,
  fetchContextSuccess,
  fetchContextFailure,

  doneWelcomeBegin,
  doneWelcomeSuccess,
  doneWelcomeFailed,

  doneLoadingStrings,

  doneDetectingWSS,
  doneShowingWssPrompt,

  eulaAcceptedBegin,
  eulaAcceptedSuccess,
  eulaAcceptedFailure,

  legalInfoBegin,
  legalInfoSuccess,
  legalInfoFailure,

  privacyInfoBegin,
  privacyInfoSuccess,
  privacyInfoFailure,

  setUwpConnected,

  startAutomation,
  resetAutomation,

  claimsBegin,
  claimsSuccess,
  claimsFailure,

  saveRemoteConfigVars,

  setDirtyPage,
} = slice.actions;

export default slice.reducer;
