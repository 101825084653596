import {
  setupVpnBegin,
  setupVpnFailure,
  setupVpnSuccess,
  checkVpnBegin,
  checkVpnSuccess,
  checkVpnFailure,
  startVpnBegin,
  startVpnSuccess,
  startVpnFailure,
  stopVpnBegin,
  stopVpnFailure,
  stopVpnSuccess,
  activateVPNBegin,
  activateVPNSuccess,
  activateVPNFailure,
  vpnTokenBegin,
  vpnTokenSuccess,
  vpnTokenFailure,
} from "../vpn";
import { uwpInvoke } from "../../uwp";
import { refreshAccessToken } from "./auth";
import {
  fetchJson,
  fetchUrl,
  createQueryString,
  getProperties,
  stringifyJson,
  setProperties,
  storeErrorDetails,
  urlToSource,
  navToErrorPage,
} from "../../utils";

import {
  API_SECURITY_MGMT,
  LS_VPN_SETUP,
  LS_PRODUCT_ID,
  LS_CARRIER_ID,
  HTTP_GET,
  HTTP_PUT,
  SETTING_VPN_STARTUP,
  SETTING_VPN_CONNECT_AUTO,
  CACHE_NO_STORE,
  FALLBACK_PRODUCT_ID_PPS,
  HTTP_EXPECTATION_NOT_MET,
  VPN_STATUS_SUCCESS,
  MAX_ERROR_RETRY,
  FEATURE_VPN,
} from "../../constants/main";
import { getServiceUrl } from "./settings";
import { showToast } from "./toasts";
import { BANNER_NOTIFICATION } from "../../components/Toasts/constants";

const vpnTimeout = 60000;

//Called on App start to check the vpn status
export const checkVpn = () => async (dispatch, getState) => {
  const { vpn } = getState().experience.features;
  if (!vpn || !vpn.enabled) {
    return;
  }
  dispatch(checkVpnBegin());
  try {
    const device_id = getState().context.cspid;

    const vpnStatus = await uwpInvoke(
      "vpn.get_state",
      { device_id },
      vpnTimeout
    );

    const { [LS_VPN_SETUP]: vpnSetup = false } = await getProperties([
      LS_VPN_SETUP,
    ]);
    dispatch(checkVpnSuccess({ ...vpnStatus, vpnSetup }));
  } catch (e) {
    dispatch(checkVpnFailure(e.message));
  }
};

/**
 * VPN initialization doing the following:
 * 1. Obtaining vpnToken using sign-in accessToken
 * 2. Invoking UWP container "vpn.login" function
 * 3. Auto start the VPN service
 * @param {{trigger}} param0
 * @returns {ok,error}
 */
export const setupVpn =
  ({ trigger }) =>
  async (dispatch, getState) => {
    dispatch(setupVpnBegin({ trigger }));
    try {
      const accessToken = await dispatch(refreshAccessToken());
      const { token } = await dispatch(fetchVPNToken(accessToken));
      const { sc_carrier_id } = getState().context;
      const { features } = getState().experience;

      if (!features[FEATURE_VPN].enabled) {
        throw Object({
          source: `setupVpn_vpn_disabled`,
          message: "setupVpn failed (vpn feature disabled)",
          trigger,
        });
      }

      const { [LS_CARRIER_ID]: cachedCarrierId } = await getProperties([
        LS_CARRIER_ID,
      ]);
      const partner_id = sc_carrier_id || cachedCarrierId || "mcafee_pps";
      const loginResponse = await uwpInvoke(
        "vpn.login",
        {
          token,
          partner_id,
        },
        vpnTimeout
      );

      if (loginResponse.status === VPN_STATUS_SUCCESS) {
        setProperties({
          [LS_VPN_SETUP]: true,
          [SETTING_VPN_STARTUP]: SETTING_VPN_CONNECT_AUTO,
        });

        await dispatch(activateVPN(accessToken));

        dispatch(
          setupVpnSuccess({
            trigger,
          })
        );
        const { error } = await dispatch(startVpn());
        if (error) {
          throw error; //Failure to start VPN
        }
        return { ok: true };
      }

      //Failed to login to VPN server
      throw Object({
        source: `communicator_invoke_vpn_login`,
        code: loginResponse.status,
        message: "setupVpn failed",
        trigger,
      });
    } catch (e) {
      const error = {
        source: "setupVpn", //fallback source
        code: "catch", //fallback source
        ...storeErrorDetails(e), //source and code are most likely extracted from exception
        trigger,
      };
      dispatch(setupVpnFailure(error));
      return { error };
    }
  };

/**
 * Endpoint activates a vpn service for the signed-in user on
 * the current device identified with cspid
 * @param {string} accessToken sign-in accessToken
 * @throws {code, status, messages, source, traceid}
 */
export const activateVPN = (accessToken) => async (dispatch, getState) => {
  dispatch(activateVPNBegin());
  const { cspid } = getState().context;
  try {
    const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT));

    await fetchUrl(`${apiBaseUrl}/feature/v1/activate/vpn/${cspid}`, {
      method: HTTP_PUT,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: stringifyJson({
        activate: true,
      }),
    });

    dispatch(activateVPNSuccess());
  } catch (e) {
    const errorInfo = storeErrorDetails(e);
    dispatch(activateVPNFailure(storeErrorDetails));
    throw errorInfo;
  }
};

/**
 * Starting the VPN service
 * Would show a toast banner and an error page
 * if failed and retry value is passed
 * @param {number} retryCount number or retries
 * @returns {ok,result,error}
 */
export const startVpn = (retryCount) => async (dispatch) => {
  const retVal = {};

  dispatch(startVpnBegin());
  try {
    const result = await uwpInvoke("vpn.start", {}, vpnTimeout);
    if (result.status === VPN_STATUS_SUCCESS) {
      dispatch(startVpnSuccess(result));
      retVal.ok = true;
      retVal.result = result;
    } else {
      throw Object({
        source: "communicator_invoke_vpn_start",
        code: result.status,
      });
    }
  } catch (e) {
    const error = (retVal.error = storeErrorDetails(e));
    dispatch(startVpnFailure(error));
    if (retryCount !== undefined) {
      if (retryCount >= MAX_ERROR_RETRY) {
        navToErrorPage(error);
      } else {
        return dispatch(
          showToast(BANNER_NOTIFICATION, {
            stringId: "toasters.banner.somethingWrong",
            selfDestruct: false,
          })
        ).then((retry) => {
          if (retry) {
            return dispatch(startVpn(retryCount + 1));
          }
          return retVal;
        });
      }
    }
  }
  return retVal;
};

/**
 * Stopping the VPN service
 * Would show a toast banner and an error page
 * if failed and retry value is passed
 * @param {number} retryCount number or retries
 * @returns {ok,result,error}
 */
export const stopVpn = (retryCount) => async (dispatch) => {
  const retVal = {};
  dispatch(stopVpnBegin());
  try {
    const result = await uwpInvoke("vpn.stop", {}, vpnTimeout);
    if (result.status === VPN_STATUS_SUCCESS) {
      dispatch(stopVpnSuccess(result));
      retVal.ok = true;
      retVal.result = result;
    } else {
      throw Object({
        source: "communicator_invoke_vpn_stop",
        code: result.status,
      });
    }
  } catch (e) {
    const error = (retVal.error = storeErrorDetails(e));
    dispatch(stopVpnFailure(error));
    if (retryCount !== undefined) {
      if (retryCount >= MAX_ERROR_RETRY) {
        navToErrorPage(error);
      } else {
        return dispatch(
          showToast(BANNER_NOTIFICATION, {
            stringId: "toasters.banner.somethingWrong",
            selfDestruct: false,
          })
        ).then((retry) => {
          if (retry) {
            return dispatch(stopVpn(retryCount + 1));
          }
          return retVal;
        });
      }
    }
  }
  return retVal;
};

/**
 * Fetches a vpnToken using a sign-in token
 * @param {string} accessToken sign-in accessToken
 * @returns {ok,token,error}
 * @throws {code, status, message, source, traceId}
 */
export const fetchVPNToken = (accessToken) => async (dispatch, getState) => {
  dispatch(vpnTokenBegin());

  try {
    const { cspid, locale, sc_product_id: productid } = getState().context;

    const country_code = locale.split("-")[1];
    const { [LS_PRODUCT_ID]: cachedProductId } = await getProperties([
      LS_PRODUCT_ID,
    ]);

    const queryString = createQueryString({
      country_code: country_code ? country_code : "us",
      device_id: cspid,
      productid: productid || cachedProductId || FALLBACK_PRODUCT_ID_PPS,
    });

    const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT));

    const vpnTokenAPI = `${apiBaseUrl}/feature/safeconnect/v1/vpntoken?${queryString}`;
    const { data: results } = await fetchJson(vpnTokenAPI, {
      method: HTTP_GET,
      cache: CACHE_NO_STORE,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (results.token) {
      const { token } = results;

      dispatch(vpnTokenSuccess(token));

      return { ok: true, token };
    }
    // //No vpnToken found
    throw Object({
      source: urlToSource(vpnTokenAPI),
      code: HTTP_EXPECTATION_NOT_MET,
    });
  } catch (e) {
    const error = storeErrorDetails(e);
    dispatch(vpnTokenFailure(error));
    throw error;
  }
};
