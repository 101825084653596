// React
import React from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

// Store

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import BreachInfoImage from "../_assets/images/dashboard/cards/breachInfo.svg";

// utils
import { ROUTE_IDENTITY } from "../app/routes";
import {
  LEARNING,
  TELL_MORE_BREACH,
  UA_WHAT_APP_CAN_DO_FOR_YOU_SHOWN,
} from "../../constants/main";
import { POPUP_TELL_ME_MORE } from "../PopUps/constants";
import { showPopup } from "../../store/thunks/popups";
import { saveUserActions } from "../../store/thunks/auth";

export default function BreachLearnMore({ id }) {
  const dispatch = useDispatch();
  const tellMeMore = async (e) => {
    if (
      await dispatch(
        showPopup(POPUP_TELL_ME_MORE, {
          source: TELL_MORE_BREACH,
        })
      )
    ) {
      dispatch(push(ROUTE_IDENTITY));
      dispatch(
        saveUserActions({
          [UA_WHAT_APP_CAN_DO_FOR_YOU_SHOWN]: true,
        })
      );
    }
  };

  const card = {
    id: id,
    backgroundImage: BreachInfoImage,
    tagText: LEARNING,
    onPrimaryAction: tellMeMore,
  };

  return <DashboardCard {...card} />;
}
