// React
import React from "react";
import { push } from "connected-react-router";

// Store
import { useDispatch, useSelector } from "react-redux";

// DOM

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import backgroundImage from "../_assets/images/dashboard/cards/addEmail.svg";

// utils
import { RECOMMENDED, TRUE_S } from "../../constants/main";
import { ROUTE_DASHBOARD, ROUTE_SCAN_EMAIL_RESULT } from "../app/routes";
import { POPUP_ADD_EMAIL_IDENTITY } from "../PopUps/constants";
import { showPopup } from "../../store/thunks/popups";
import { createQueryString } from "../../utils";

//utils

export default function MonitorNewEmail({ id }) {
  const { email } = useSelector((state) => state.auth);
  const { fetchingBreachCount } = useSelector((state) => state.dws);
  const dispatch = useDispatch();
  const onPrimaryAction = async () => {
    const email = await dispatch(showPopup(POPUP_ADD_EMAIL_IDENTITY));
    if (email && email.length > 0) {
      dispatch(
        push({
          pathname: ROUTE_SCAN_EMAIL_RESULT,
          search: createQueryString({
            path: ROUTE_DASHBOARD,
            email,
          }),
        })
      );
    }
  };

  const card = {
    id,
    stringValues: { email },
    backgroundImage,
    tagText: RECOMMENDED,
    primaryActionStringId: "checkMyInfo",
    primaryActionLoading: fetchingBreachCount ? TRUE_S : undefined,
    onPrimaryAction,
    primaryActionDataTestId: "monitorNewEmailCheckMyInfo",
  };

  return <DashboardCard {...card} />;
}
