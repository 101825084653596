//Analytics
import {
  ANALYTICS_DETAILS,
  ANALYTICS_ID_PROTECTION,
  ANALYTICS_PROTECTION,
  ANALYTICS_SUCCESS,
  EVENTID_PPS_REMEDIATION_CANCEL,
  EVENTID_PPS_REMEDIATION_START,
  HIT_CATEGORY,
  HIT_FEATURE,
  HIT_SCREEN,
  SCREEN_DETAILS,
  SCREEN_FLOW,
  SCREEN_TYPE,
} from "../../constants/analytics";
import { uwpSendTelemetry } from "../../uwp";
// React
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";

import {
  BREACH_STATUS_RESOLVED,
  MAX_ERROR_RETRY,
  SUCCESS,
} from "../../constants/main";
import { String } from "../../l10n/loader";

// Store
import { remediateBreach } from "../../store/thunks/identity";

// DOM
import { Icon, Label, Link } from "@mcafee/pegasus";

// Custom components

import BreachActions from "./BreachActions";
import BreachedAssetList from "./BreachedAssetList";
import { analyzeBreachDetails, navToErrorPage, newGUID } from "../../utils";
import {
  SCREEN_BREACH_DETAIL_CHANGE_PWD_IN_APP_BROWSER,
  // SCREEN_BREACH_DETAIL_CHANGE_PWD_UNVERIFIED, ///TODO: review it
  // SCREEN_BREACH_DETAIL_CONFIRMATION, //TODO: review it
} from "../../constants/analyticsScreens";
import {
  getScreenDetailsFromBreachedInfo,
  collectScreenLoadEvent,
} from "../../utils";

import "./IdentityBreachDetails.scss";

import { useDispatch } from "react-redux";
import { showPopup } from "../../store/thunks/popups";
import {
  POPUP_CONFIRM_PASSWORD_CHANGE,
  POPUP_WHAT_CAN_YOU_DO,
} from "../PopUps/constants";
import { showToast } from "../../store/thunks/toasts";
import {
  BANNER_NOTIFICATION,
  TOAST_SNACKBAR_SUCCESS,
} from "../Toasts/constants";

import { OVERLAY_WELL_DONE } from "../../components/Overlays/constants";
import { showOverlay } from "../../store/thunks/overlays";
import { navigateBack } from "../../store/thunks/app";

//Identity->PersonalInfo->Breach details->Breach view--renders the breach view corresponding to the breach selected
const BreachView = ({
  //props
  id,
  updateContent,
  //...state.identity
  breachDetails,
  fetchingDashboard,
  remediating,
  //dispatches
  remediateBreach,
}) => {
  const [assetsStolen, setAssetsStolen] = useState([]); // To store the list of assets stolen
  const [hasPassword, setHasPassword] = useState(false);
  const [hasPlainTextPassword, setHasPlainTextPassword] = useState(false);
  const [hasPII, setHasPII] = useState(false);
  const [siteKnown, setSiteKnown] = useState(false);
  const [site, setSite] = useState();
  const [screenDetails, setScreenDetails] = useState(undefined);

  const query = useSelector((state) => state.router.location.query);
  const trigger = decodeURIComponent(query.src).replace(/[^A-Za-z0-9]/g, "");

  const _S = (str) => `identity.breachView.${str}`;

  const dispatch = useDispatch();

  const handleMoreInfoClick = () => {
    let whyChangePassword = screenDetails.includes("pii_known"); /// PII known
    let makeYourselfSafer = screenDetails.includes("pii_unknown"); /// PII unknown

    dispatch(
      showPopup(POPUP_WHAT_CAN_YOU_DO, {
        whyChangePassword,
        makeYourselfSafer,
        screenDetails,
      })
    );
  };

  useEffect(() => {
    let cancelRequested = false; //will be set to true by the clean up callback

    if (id) {
      getScreenDetailsFromBreachedInfo().then((details) => {
        if (!cancelRequested) {
          setScreenDetails(details);
        }
      });
    }
    return () => {
      //To prevent setting component state while it is unmounted
      cancelRequested = true;
    };
  }, [id]);

  // iterate through the keys of breachDetails and record the stolen assets
  useEffect(() => {
    if (breachDetails) {
      const {
        assetsStolen,
        siteKnown,
        hasPassword,
        hasPlainTextPassword,
        hasPII,
        site,
      } = analyzeBreachDetails(breachDetails);

      setAssetsStolen(assetsStolen);

      setSiteKnown(siteKnown);

      setHasPassword(hasPassword);

      setHasPlainTextPassword(hasPlainTextPassword);

      setHasPII(hasPII);

      updateContent(siteKnown, hasPlainTextPassword);

      setSite(site);
    }
  }, [breachDetails, updateContent]);

  const setPasswordChangedConfirmationTrue = () => {
    setTimeout(async () => {
      const results = await dispatch(
        showPopup(POPUP_CONFIRM_PASSWORD_CHANGE, {
          screenDetails,
          onCancel: setPasswordChangedConfirmationFalse,
        })
      );
      if (results) {
        handlePasswordChanged();
      }
    }, 5000);

    collectScreenLoadEvent({
      [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
      [HIT_CATEGORY]: ANALYTICS_PROTECTION,
      [HIT_SCREEN]: SCREEN_BREACH_DETAIL_CHANGE_PWD_IN_APP_BROWSER,
      [SCREEN_TYPE]: ANALYTICS_DETAILS,
      [SCREEN_DETAILS]: screenDetails,
      [SCREEN_FLOW]: "identity",
    });

    uwpSendTelemetry(EVENTID_PPS_REMEDIATION_START, {
      hit_feature: "rule_engine",
      hit_trigger: trigger,
      hit_label_0: ANALYTICS_SUCCESS,
      hit_label_2: ANALYTICS_SUCCESS,
      hit_label_3: "id protection",
      hit_label_4: "2",
      hit_guid: newGUID(),
    });
  };

  const setPasswordChangedConfirmationFalse = () => {
    uwpSendTelemetry(EVENTID_PPS_REMEDIATION_CANCEL, {
      hit_feature: "rule_engine",
      hit_trigger: trigger,
      hit_label_0: ANALYTICS_SUCCESS,
      hit_label_2: ANALYTICS_SUCCESS,
      hit_label_3: "id protection",
      hit_label_4: "2",
      hit_guid: newGUID(),
    });
  };

  const handleFixedClick = async (retryCount = 0) => {
    const { breach_ref_id, asset_public_id } = breachDetails;

    /// this logic is to define the type well done page
    let breachType = "pii_known";

    if (hasPassword && hasPlainTextPassword) {
      breachType = siteKnown ? "plaintext_known" : "plaintext_unknown";
    } else {
      breachType = siteKnown ? "pii_known" : "pii_unknown";
    }

    const response = await remediateBreach({
      breach_ref_id,
      asset_public_id,
      breachType,
    });

    if (response === SUCCESS) {
      await dispatch(
        showOverlay(OVERLAY_WELL_DONE, { breachType, screenDetails })
      );

      dispatch(navigateBack());

      dispatch(
        showToast(TOAST_SNACKBAR_SUCCESS, {
          stringId: "toasters.youAddressed",
          screenDetails,
        })
      );
    } else {
      if (retryCount < MAX_ERROR_RETRY) {
        dispatch(
          showToast(BANNER_NOTIFICATION, {
            stringId: "toasters.banner.somethingWrong",
            selfDestruct: false,
          })
        ).then((retry) => {
          if (retry) {
            handleFixedClick(retryCount + 1);
          }
        });
      } else {
        navToErrorPage(response);
      }
    }
  };

  const handlePasswordChanged = () => {
    handleFixedClick();
  };

  const resolved =
    breachDetails.status === BREACH_STATUS_RESOLVED ? true : false;

  if (!breachDetails) {
    return null;
  }

  //pass asset id to otp popup
  return (
    <div className="identityLayout">
      <div className="identityLayout__container__content">
        <div className="identityLayout__container__content__assetList">
          <BreachedAssetList
            id={id}
            assetsStolen={assetsStolen}
            emailAddress={breachDetails.email_address}
            password={breachDetails.password}
            resolved={resolved}
            hasPassword={hasPassword}
            hasPlainTextPassword={hasPlainTextPassword}
            site={site}
          />
        </div>
        <Link
          className="flex identityLayout__container__content__link"
          onClick={handleMoreInfoClick}
        >
          <Icon icon="info-outline" size="md" className="px-12" />
          <Label className="identityLayout__container__content__link__label">
            {hasPlainTextPassword || hasPassword ? (
              <String id={_S`whatCanIDo`} />
            ) : (
              <String id={_S`whyChange`} />
            )}
          </Label>
        </Link>
      </div>

      {!fetchingDashboard && !remediating && (
        <BreachActions
          onAlreadyFixedIt={handleFixedClick}
          onChangePasswordClick={setPasswordChangedConfirmationTrue}
          site={breachDetails.site}
          thirdParty={site}
          {...{
            hasPassword,
            hasPlainTextPassword,
            hasPII,
            siteKnown,
            resolved,
          }}
        />
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    breachDetails: state.identity.breachDetails,
    remediating: state.identity.remediating,
    fetchingDashboard: state.identity.fetchingDashboard,
  }),
  { remediateBreach }
)(BreachView);
