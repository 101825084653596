import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { ROUTE_IDENTITY } from "../app/routes";

// DOM

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import backgroundImage from "../_assets/images/dashboard/cards/placeholder.svg";
import { NEEDS_ATTENTION } from "../../constants/main";

// ToDo: No longer supported/Priorty. Replaced with 'EmailVerificationBreachFoundCard' or 'EmailVerificationNoBreachesFoundCard'. Is to be Removed?.
export default function IdentityMonitor({ id }) {
  const dispatch = useDispatch();

  const onPrimaryAction = () => {
    dispatch(push(ROUTE_IDENTITY));
  };

  const card = {
    id,
    backgroundImage,
    tagText: NEEDS_ATTENTION,
    onPrimaryAction,
  };

  return <DashboardCard {...card} />;
}
