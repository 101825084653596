import { closeOverlay, openOverlay } from "../overlays";

export const showOverlay = (
  overlay,
  props = {},
  className = {},
  style = {}
) => (dispatch) => {
  const { addEventListener, removeEventListener } = window;

  return new Promise((onSuccess) => {
    dispatch(openOverlay({ overlay, props, className, style }));
    const handleHide = (e) => {
      if (e.data.overlay === overlay) {
        onSuccess(e.data.results);
        removeEventListener(closeOverlay.type, handleHide);
      }
    };
    
    addEventListener(closeOverlay.type, handleHide);
  });
};
