import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { String } from "../../l10n/loader";
import { createQueryString } from "../../utils";
import {
  REGISTER,
  API_UNIFIED_AUTH,
  LOGIN,
  AUTH_POPUP,
  AUTH_IFRAME,
  TRIAL,
  GITHUB_PAGES_DOMAIN,
  GITHUB_PAGES_DEFAULT_BASENAME,
} from "../../constants/main";
import {
  UnifiedAuthIframeId,
  UnifiedAuthIframeInsert,
  UnifiedAuthIframeRemove,
} from "./UnifiedAuthIframe.js";

// Store
import { signInFailure, signInBegin } from "../../store/auth";
import { signInCancel } from "../../store/auth";
import { fetchAccessToken } from "../../store/thunks/auth";
import { acceptEulaAndPrivacyNotice } from "../../store/thunks/eula";
import { getServiceUrl } from "../../store/thunks/settings";

// DOM
import { Button, Link } from "@mcafee/pegasus";

//utils
import { createFormattedId } from "../../utils";

const UnifiedAuth = ({
  //attrs
  label = "signIn",
  signInType = LOGIN,
  buttonStyle = true,
  lightColor = true,
  onSuccess,
  authLaunch,
  //...state.auth
  signInCancelled,
  //...state.context
  locale,
  oauthClientId,
  //methods
  signInBegin,
  signInFailure,
  signInCancel,
  getServiceUrl,
  content,
}) => {
  const authentication = useSelector(
    (state) => state.experience.authentication
  );
  const [newUser, setNewUser] = useState(signInType === REGISTER);
  const [browserOpen, setBrowserOpen] = useState(false);
  const [browser] = useState({ window: null });
  const [oauthUrl, setOauthUrl] = useState("");

  const [triggerAutoLaunch, setTriggerAutoLaunch] = useState(authLaunch);

  const authWithPopup = authentication === AUTH_POPUP;
  const authWithIframe = authentication === AUTH_IFRAME;

  const launchBrowser = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }

      if (!localStorage || authWithIframe) {
        signInBegin({ newUser });
        setBrowserOpen(true);
        UnifiedAuthIframeInsert({ src: oauthUrl });
      } else {
        browser.window = window.open(oauthUrl, "_blank");
        if (window.communicator || browser.window) {
          // If running inside UWP or not blocked by Browser popup blocker, start the sign-in progress
          signInBegin({ newUser });
          setBrowserOpen(true);
        }
      }
    },
    [authWithIframe, browser, newUser, oauthUrl, signInBegin]
  );

  const closeBrowser = useCallback(() => {
    if (authWithIframe) {
      UnifiedAuthIframeRemove();
    } else if (authWithPopup && browser.window && !browser.window.closed) {
      browser.window.close();
    }

    setBrowserOpen(false);
  }, [browser, authWithIframe, authWithPopup]);

  useEffect(() => {
    if (triggerAutoLaunch && oauthUrl) {
      launchBrowser();
      setTriggerAutoLaunch(false);
    }
  }, [triggerAutoLaunch, oauthUrl, launchBrowser]);

  useEffect(() => {
    if (signInCancelled) {
      closeBrowser();
    }
  }, [signInCancelled, closeBrowser]);

  //Destroying this element while browserWindow is still open
  useEffect(() => {
    return () => {
      closeBrowser();
    };
  }, [closeBrowser]);

  const checkIfPopupWasClosed = useCallback(() => {
    if (
      (!authWithPopup && !document.getElementById(UnifiedAuthIframeId)) ||
      (browserOpen && browser.window && browser.window.closed)
    ) {
      signInCancel({ analytics: true, newUser });
      setBrowserOpen(false);
    }
  }, [browser.window, browserOpen, signInCancel, newUser, authWithPopup]);

  //Create browser window closed listener
  useEffect(() => {
    if (browserOpen) {
      const interval = setInterval(checkIfPopupWasClosed, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [browserOpen, checkIfPopupWasClosed]);

  const handlePostMessage = useCallback(
    ({ source, origin, data }) => {
      if (origin !== window.location.origin) {
        return; //Not from the same origin
      }
      const msg =
        typeof data === "string" && data.length > 2 ? JSON.parse(data) : data;

      switch (msg.id) {
        case "authSuccess": //called from oauth_redir.html
          const { authCode, act } = msg;
          const newUser = act !== "si";
          setNewUser(newUser);
          closeBrowser();
          onSuccess(authCode, newUser);
          break;
        case "authError":
          signInFailure({
            source: "unified_auth",
            code: msg.results || msg.error,
          });
          closeBrowser();
          break;
        case "authCancel":
          signInCancel({ analytics: true });
          closeBrowser();
          break;
        default:
          break;
      }
    },
    [closeBrowser, onSuccess, signInCancel, signInFailure]
  );

  //Create "message" event listener
  useEffect(() => {
    if (browserOpen) {
      window.addEventListener("message", handlePostMessage);

      return () => {
        window.removeEventListener("message", handlePostMessage);
      };
    }
  }, [browserOpen, handlePostMessage]);

  //Calculating oauthUrl from parameters and serviceUrl
  useEffect(() => {
    getServiceUrl(API_UNIFIED_AUTH).then((unified_auth) => {
      var query = createQueryString({
        redirect_uri: `${
          window.location.host.match(/^github/)
            ? `${GITHUB_PAGES_DOMAIN}${
                process.env.REACT_APP_GITHUB_PAGES_BASENAME ||
                GITHUB_PAGES_DEFAULT_BASENAME
              }`
            : window.location.origin
        }/oauth_redir.html`,
        client_id: oauthClientId,
        response_type: "code",
        state: "csrf_xsrf_metigation_value",
        nonce: Date.now(),
        scope: "openid email",
        culture: locale,
      });
      setOauthUrl(unified_auth + `/Home/${signInType}?${query}`);
    });
  }, [getServiceUrl, oauthClientId, signInType, locale]);

  const styles = {
    button: { width: "300px" },
  };

  //Authentication Button or link rendering

  if (buttonStyle) {
    return (
      <Button
        onClick={launchBrowser}
        id={createFormattedId(label)}
        // loading={signingIn && newUser ? TRUE_S : undefined}
        data-testid="unifiedAuthLaunch"
        size="sm"
        {...(lightColor
          ? {
              kind: "light",
              style: styles.button,
            }
          : {
              ...(content !== TRIAL
                ? {
                    className: "mt-32 px-72",
                  }
                : {
                    className: "",
                  }),
            })}
      >
        <String id={`messages.${label}`} />
      </Button>
    );
  } else {
    return (
      <Link
        className="mt-12"
        onClick={launchBrowser}
        id={createFormattedId(label)}
        data-testid="unifiedAuthLaunchLink"
      >
        <String id={`messages.${label}`} />
      </Link>
    );
  }
};

export default connect(
  (state) => ({
    ...state.auth,
    locale: state.context.locale,
    oauthClientId: state.context.oauthClientId,
  }),
  {
    signInCancel,
    signInBegin,
    signInFailure,
    fetchAccessToken,
    acceptEulaAndPrivacyNotice,
    getServiceUrl,
  }
)(UnifiedAuth);
