import React from "react";
import { useDispatch } from "react-redux";
import { String } from "../../l10n/loader";
import { Icon, Card, Paragraph, Tag, Subheading } from "@mcafee/pegasus";

import "./secureVpnCard.scss";
import { showPopup } from "../../store/thunks/popups";
import { ChevronRightRounded, LocationOffRounded } from "@material-ui/icons";

export default function SecureVpnCard({
  popupName,
  popupProps,
  iconKind,
  iconName,
  iconClass,
  titleId,
  paragraphId,
}) {
  const dispatch = useDispatch();

  const handleCardClick = () => {
    dispatch(showPopup(popupName, popupProps));
  };

  return (
    <Card
      className="secureVpnCard"
      clickable={!!popupName}
      onClick={popupName ? handleCardClick : null}
    >
      <Tag size="xs" icon className="secureVpnCard__tag" kind={iconKind}>
        {iconName ? (
          <Icon icon={iconName} className="text-info" />
        ) : (
          <LocationOffRounded className={iconClass} />
        )}
      </Tag>
      <div className="secureVpnCard__textContainer">
        <Subheading size="sm" className="mb-4">
          <String id={titleId} />
        </Subheading>
        <Paragraph size="sm" className="text-gray-light">
          <String id={paragraphId} />
        </Paragraph>
      </div>
      <ChevronRightRounded className="text-gray-light" />
    </Card>
  );
}
