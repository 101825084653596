import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading } from "@mcafee/pegasus";

// Store
import { signOutCancel } from "../../store/auth";
import { connect } from "react-redux";

const ConfirmSignOutPopUp = ({ onFinish, onCancel, signOutCancel }) => {
  const localePrefix = "popups.";
  const styles = {
    popUpStyles: {
      maxWidth: 550,
    },
    logoStyles: {
      display: "block",
    },
  };

  const handleSignOutConfirm = (e) => {
    onFinish(true);
  };

  const handleSignOutCancel = (e) => {
    signOutCancel({ analytics: true });
    onCancel();
  };

  return (
    <div style={styles.popUpStyles} className="pa-24">
      <Heading className="mb-8 text-left" size="xs" data-testid="signOutHeader">
        <String id={`${localePrefix}confirmSignOut.header`} />
      </Heading>

      <Paragraph className="text-left" size="md" data-testid="signOutContent">
        <String id={`${localePrefix}confirmSignOut.content`} />
      </Paragraph>
      <div className="mt-24 text-right">
        <Button
          onClick={handleSignOutConfirm}
          width="normal"
          id="signOut"
          data-testid="signOutButton"
          size="sm"
        >
          <String id={`${localePrefix}signOut`} />
        </Button>
        <Button
          className="ml-24"
          onClick={handleSignOutCancel}
          width="normal"
          id="cancelSignOut"
          variant="tertiary"
          data-testid="signOutCancelButton"
          size="sm"
        >
          <String id={`${localePrefix}cancelSignOut`} />
        </Button>
      </div>
    </div>
  );
};

export default connect((state) => ({}), {
  signOutCancel,
})(ConfirmSignOutPopUp);
