import React, { useEffect } from "react";
import { String } from "../../l10n/loader";
import { connect, useSelector } from "react-redux";
import { push, replace } from "connected-react-router";
import { ROUTE_SETTINGS_VPN, ROUTE_VPN_SETUP } from "../app/routes";

import { Heading, Icon, Subheading, Paragraph } from "@mcafee/pegasus";

import NavbarLayout from "../NavBar/NavbarLayout";

import VpnSwitch from "./VpnSwitch";
import {
  VPN_STATE_CONNECTING,
  VPN_STATE_CONNECTED,
  VPN_STATE_DISCONNECTING,
  VPN_STATE_DISCONNECTED,
  VPN_STATE_ERROR,
} from "../../constants/main";
import { useIsSignedIn } from "../../utils";
import Unauthorized from "../Shared/Unauthorized";

import "./secureVpn.scss";
import SecureVpnCard from "./SecureVpnCard";
import {
  POPUP_FIVE_REASONS,
  POPUP_VIRTUAL_LOCATION,
} from "../PopUps/constants";

const SecureVpn = ({
  state,
  push,
  vpnSetup,
  current_country,
  locale,
  replace,
}) => {
  const _S = (str) => `vpn.secure.${str}`;

  const query = useSelector((state) => state.router.location.query);

  useEffect(() => {
    if (!vpnSetup) {
      if (query.src) {
        replace({ pathname: ROUTE_VPN_SETUP, query });
      } else {
        replace(ROUTE_VPN_SETUP);
      }
    }
  }, [vpnSetup, query, replace]);

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  const stringContent = {
    [VPN_STATE_CONNECTED]: {
      vpnHeading: "vpn.status.boostingSecurityAndPrivacy",
      vpnParagraph: "vpn.nowYouCanBank",
      cardIconClass: "text-info",
      cardTagKind: "normal",
      cardTitleId: "vpn.location.yourLocationIsHidden",
      cardParagraphId: "vpn.location.weAreHiddingIt",
    },
    [VPN_STATE_DISCONNECTING]: {
      vpnHeading: "vpn.status.stoppingVPN",
      vpnParagraph: null,
      cardIconClass: "text-info",
      cardTagKind: "normal",
      cardTitleId: "vpn.location.yourLocationIsHidden",
      cardParagraphId: "vpn.location.weAreHiddingIt",
    },
    [VPN_STATE_DISCONNECTED]: {
      vpnHeading: "vpn.status.off",
      vpnParagraph: "vpn.swipe",
      cardIconClass: "text-gray-light",
      cardTagKind: "neutral",
      cardTitleId: "vpn.location.realLocationIsShowing",
      cardParagraphId: "vpn.location.turnOnVPNtoHide",
    },
    [VPN_STATE_CONNECTING]: {
      vpnHeading: "vpn.status.startingVPN",
      vpnParagraph: "vpn.getReady",
      cardIconClass: "text-gray-light",
      cardTagKind: "neutral",
      cardTitleId: "vpn.location.realLocationIsShowing",
      cardParagraphId: "vpn.location.turnOnVPNtoHide",
    },
    [VPN_STATE_ERROR]: {
      vpnHeading: "vpn.status.off",
      vpnParagraph: "vpn.swipe",
      cardIconClass: "text-gray-light",
      cardTagKind: "neutral",
      cardTitleId: "vpn.location.realLocationIsShowing",
      cardParagraphId: "vpn.location.turnOnVPNtoHide",
    },
  };

  const handleClickIconSettings = () => {
    push(ROUTE_SETTINGS_VPN);
  };

  const {
    vpnHeading,
    vpnParagraph,
    cardIconClass,
    cardTagKind,
    cardTitleId,
    cardParagraphId,
  } = stringContent[state] || stringContent[VPN_STATE_ERROR];

  return (
    <NavbarLayout id="secureVPN" classNameLayout="secureVpn__container">
      <Subheading size="lg" className="flex ma-4">
        <String id={_S`title`} />
        <Icon
          icon="settings-atlas-outline"
          className="secureVpn__titleIcon"
          role="button"
          tabIndex="0"
          data-testid="VPNSettingsButton"
          onClick={handleClickIconSettings}
        />
      </Subheading>
      <div className="secureVpn__switch">
        <VpnSwitch />
        <Heading className="mb-8" size="sm" data-testid="SecureVpnHeader">
          <String id={vpnHeading} />
        </Heading>
        {vpnParagraph && (
          <Paragraph size="md">
            <String id={vpnParagraph} />
          </Paragraph>
        )}
      </div>
      <div className="secureVpn__cardContainer">
        <SecureVpnCard
          popupName={
            state === VPN_STATE_CONNECTED ? POPUP_VIRTUAL_LOCATION : null
          }
          popupProps={{ current_country, locale }}
          iconClass={cardIconClass}
          iconKind={cardTagKind}
          titleId={cardTitleId}
          paragraphId={cardParagraphId}
        />
        <SecureVpnCard
          popupName={POPUP_FIVE_REASONS}
          iconKind="normal"
          iconName="vpn-secure-outline"
          titleId="vpn.protection.notSureWhy"
          paragraphId="vpn.protection.weGotFive"
        />
      </div>
    </NavbarLayout>
  );
};

export default connect(
  (state) => ({
    ...state.vpn,
    locale: state.context.locale,
  }),
  {
    push,
    replace,
  }
)(SecureVpn);
