import React from "react";
import { String } from "../../l10n/loader";
import { ROUTE_SETTINGS_ABOUT } from "../app/routes";
import { connect } from "react-redux";
import LicenseText from "./LicenseText";

// DOM
import { Icon, Button } from "@mcafee/pegasus";

// Custom components
import NavbarLayout from "../NavBar/NavbarLayout";
import { navigateBack } from "../../store/thunks/app";

const OpenSourceLicensesPage = ({ navigateBack }) => {
  const localePrefix = "messages.settings.openSourceLicenses.";
  const styles = {
    root: {
      whiteSpace: "pre-line",
      overflowY: "auto",
    },
    licenseText: {
      whiteSpace: "pre-wrap",
      maxWidth: "650px",
    },
    back: {
      alignSelf: "flex-start",
    },
  };

  const onClickBack = (e) => {
    //Setting the defaultRoute to avoid going back to home screen when history is empty
    navigateBack({ defaultRoute: ROUTE_SETTINGS_ABOUT });
  };

  return (
    <NavbarLayout
      id="Settings"
      className="pa-0"
      classNameLayout="pa-0 bg-white no-overflow"
    >
      <div
        style={{ height: "100vh", display: "flex", flexDirection: "column" }}
      >
        <div>
          <Button
            variant="tertiary"
            className="ma-16"
            onClick={onClickBack}
            id={`${localePrefix}goBack`}
            size="sm"
            style={styles.back}
          >
            <Icon icon="chevron-left-outline" />
            <String id="goBack" />
          </Button>
        </div>
        <div className="px-48" style={styles.root}>
          <pre className="py-16" style={styles.licenseText}>
            {LicenseText}
          </pre>
        </div>
      </div>
    </NavbarLayout>
  );
};

export default connect(
  (state) => ({
    historyIndex: state.context.historyIndex,
  }),
  { navigateBack }
)(OpenSourceLicensesPage);
