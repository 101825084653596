import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Paragraph, Heading, Label } from "@mcafee/pegasus";
import Phone_added from "../../components/_assets/images/popUps/phoneAdded.svg";

import "./assetAddedConfirmationPopup.scss";

export default function AssetAddedPopUp({ phoneNumber }) {
  const _S = (str) => `popups.assetAddedPopUp.${str}`;
  return (
    <div className="assetAddedPopup">
      <img src={Phone_added} alt="" className="assetAddedPopup__image" />

      <Heading
        className="assetAddedPopup__title"
        size="xs"
        data-testid="assetAddedPopUpHeader"
      >
        <String id={_S`header`} />
      </Heading>

      <div size="sm" data-testid="assetAddedPopUpContent">
        <Paragraph className="assetAddedPopup__content">
          <String
            id={_S`content1`}
            values={{
              asset: <Label>{phoneNumber}</Label>,
            }}
          />
        </Paragraph>
        <Paragraph className="assetAddedPopup__content">
          <String id={_S`content2`} />
        </Paragraph>
        <Paragraph className="assetAddedPopup__content__label">
          <String
            id={_S`content3`}
            values={{
              account: (
                <Label className="assetAddedPopup__label">
                  <String id={_S`myAccount`} />
                </Label>
              ),
            }}
          />
        </Paragraph>
      </div>
    </div>
  );
}
