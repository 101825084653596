/**
 * Http fetch utility functions
 * Author: ramy_eldesoky@mcafee.com
 */

import {
  APP_ID,
  CACHE_NO_STORE,
  CACHE_TTL,
  EMPTY_VAL,
  HTTP_BAD_REQUEST,
  HTTP_CONFLICT,
  HTTP_CREATED,
  HTTP_EXPECTATION_NOT_MET,
  HTTP_FETCH_FAILED,
  HTTP_FORBIDDEN,
  HTTP_GATEWAY_TIMEOUT,
  HTTP_GET,
  HTTP_METHOD_NOT_ALLOWED,
  HTTP_NOT_ACCEPTABLE,
  HTTP_NOT_FOUND,
  HTTP_NOT_IMPLEMENTED,
  HTTP_NOT_MODIFIED,
  HTTP_NO_CONTENT,
  HTTP_OK,
  HTTP_POST,
  HTTP_REQUEST_TIMEOUT,
  HTTP_SERVER_ERROR,
  HTTP_SERVICE_NOT_AVAILABLE,
  HTTP_TOO_MANY_REQUESTS,
  HTTP_UNAUTHORIZED,
  LOG_LEVEL_ERROR,
  LS_ACCESS_TOKEN,
  LS_CACHE_LIST,
  LS_CARRIER_ID,
  LS_EULA_ACCEPTED,
  LS_ID_TOKEN,
  LS_PRODUCT_ID,
  LS_PROVISION_ID,
  LS_SESSION,
  LS_USER_ACTIONS,
  LS_VPN_SETUP,
  MTX_CACHE,
} from "../constants";

import { store } from "../store/store";
import { syncedInvoke } from "./sync";

import {
  createFailureQueryString,
  delay,
  delayedResult,
  parseJson,
  stringifyJson,
} from "./main";

import { uwpLog } from "../uwp";
import { getProperties, setProperties } from "./props";

import { SHA256 } from "crypto-js";
import { push } from "connected-react-router";
import { ROUTE_FAILURE } from "../components/app/routes";

const httpStatusText = (code) => {
  return (
    {
      [HTTP_FETCH_FAILED]: "Failed to fetch",
      [HTTP_OK]: "Ok",
      [HTTP_CREATED]: "Created",
      [HTTP_NO_CONTENT]: "No content",
      [HTTP_NOT_MODIFIED]: "Not modified",
      [HTTP_BAD_REQUEST]: "Bad request",
      [HTTP_UNAUTHORIZED]: "Unauthorized",
      [HTTP_FORBIDDEN]: "Forbidden",
      [HTTP_NOT_FOUND]: "Not found",
      [HTTP_METHOD_NOT_ALLOWED]: "Method not allowed",
      [HTTP_NOT_ACCEPTABLE]: "Not acceptable",
      [HTTP_REQUEST_TIMEOUT]: "Request timeout",
      [HTTP_TOO_MANY_REQUESTS]: "Too many requests",
      [HTTP_CONFLICT]: "Conflict",
      [HTTP_EXPECTATION_NOT_MET]: "Expectation not met",
      [HTTP_TOO_MANY_REQUESTS]: "Too many requests",
      [HTTP_SERVER_ERROR]: "Internal server error",
      [HTTP_NOT_IMPLEMENTED]: "Not implemented",
      [HTTP_SERVICE_NOT_AVAILABLE]: "Service not available",
      [HTTP_GATEWAY_TIMEOUT]: "Gateway timeout",
    }[code] || "Unknown"
  );
};

/**
 * Creates a source ID from the API Url to use in error reporting
 * @param {string} url api Url
 * @param {string?} fetchMethod http request method
 * @returns {string}
 */
export const urlToSource = (url, fetchMethod = HTTP_GET) => {
  const matchApiPath = url.match(/\.mcafee\.com\/([^?]*)/);
  const path = matchApiPath ? matchApiPath[1] : url;
  const source = path.replace(`${APP_ID}`, `appid`).split("/").join("_");
  const method = fetchMethod === HTTP_GET ? "" : fetchMethod + "_";
  return method + source;
};

/**
 * Detecting and parse different error models
 * to extract the code, error message and traceId
 * Supporting the following models:
 *  {status,message}
 *  {code,message,traceId}
 *  {error:{code,message,traceId}}
 *  {status,title,detail,traceId,errors:[]}
 *  {response_header:{response_code,message}}
 *  {message, stack}
 *  plain text error message
 * @param {number} httpStatus HTTP response status code
 * @param {*} responseText HTTP response body
 * @returns {ok, status, code, message, traceId?} returns
 */
const parseErrorModel = (httpStatus, responseText) => {
  //Parse response text
  const errorInfo =
    typeof responseText === "string" && responseText.length > 2
      ? parseJson(responseText, { message: responseText })
      : responseText || {}; //Try to parse the response text

  //Try the model {code,message,traceId}
  try {
    const { code, message, traceId } = errorInfo;
    if (code !== undefined) {
      return { ok: false, code, message, traceId };
    }
  } catch (ex) {}

  //Try the model {status,message,traceId}
  try {
    const { status, message, traceId } = errorInfo;
    if (status !== undefined) {
      return { ok: false, status, code: status, message, traceId };
    }
  } catch (ex) {}

  //Try the model {error:{code,message,traceId}}
  try {
    const {
      error: { code, message, traceId },
    } = errorInfo;

    if (code !== undefined) {
      return { ok: false, code, message, traceId };
    }
  } catch (ex) {}

  //Try the model {status,title,detail,traceId,errors:[]}
  try {
    const { status: code, title, traceId, errors } = errorInfo;
    if (code !== undefined) {
      return {
        ok: false,
        code,
        message: title + (errors ? ":" + stringifyJson(errors) : ""),
        traceId,
      };
    }
  } catch (ex) {}

  //Try the model {response_header:{response_code,message}}
  try {
    const {
      response_header: { response_code: code, message, traceid: traceId },
    } = errorInfo;

    if (code !== undefined) {
      return { ok: false, code, message, traceId };
    }
  } catch (ex) {}

  //Assume the default model {message, stack}
  const fallback = {
    ok: false,
    status: httpStatus,
    code: httpStatus,
    message: errorInfo.message || responseText || httpStatusText(httpStatus),
  };

  return fallback;
};

/**
 * For responses in error state, extract status and data for debugging and logging
 * This call will finish reading the http stream and provides the data via .json() and .text()
 * @param {{ok, type, status, statusText, json, text}} resp Response interface
 */
const fetchErrorDetails = async (resp, fetchMethod) => {
  let { status, url, statusText, type } = resp;

  if (!statusText || !statusText.length) {
    statusText = httpStatusText(status);
  }

  const responseText = await resp.text(); //Read the text stream
  resp.text = () => {
    return Promise.resolve(responseText);
  };
  resp.json = () => {
    return Promise.resolve(parseJson(responseText));
  };

  const { code, message, traceId } = parseErrorModel(status, responseText);

  return {
    ok: false,
    source: urlToSource(url, fetchMethod), //set the default error source from the API path
    status,
    code,
    message,
    traceId,
    statusText,
    url,
    type,
    response: responseText,
  };
};

/**
 * Extract required error attributes for redux failure action
 * @param {*} e exception or error object
 * @returns {{ok,status,code,message,source,traceId?,cached?,trigger?}}
 */
export const storeErrorDetails = (e) => {
  const { status, code, message, traceId, source, cached, trigger } = e;
  const errorDetails = {
    ok: false,
    status: status === undefined ? (code === undefined ? 0 : code) : status,
    code: code === undefined ? status : code,
    message,
    source,
    traceId,
    cached,
    trigger,
  };
  try {
    console.error({errorDetails,e}, JSON.stringify({errorDetails,e}));
  } catch (e) {
    console.error(e);
  }
  return errorDetails;
};

/**
 * Called by fetchJson to read and parse the json stream
 * and store the object in "data" attribute
 * Log error for debugging and analytics
 * @param {{ok:boolean, json:function():Promise, text:function():Promise}} resp Response interface
 * @returns {{ok:boolean, status:number, data}}
 * @throws {{status:number, code:number, message:string, traceId:string}}
 */
const parseJsonResponse = async (resp) => {
  try {
    const { status } = resp;

    if (isValidApiResponse(status)) {
      //Make sure it is not a network nor server error response
      const data = resp.status !== HTTP_NO_CONTENT ? await resp.json() : {};
      return { ok: true, status: resp.status, data }; //Successful response
    }

    return Promise.reject(resp);
  } catch (e) {
    //Invalid response or resp.json() threw an exception
    return Promise.reject(e);
  }
};

/**
 * Read cached nodes keys and expiry table
 * @returns {Array<{key,expiresAt}>} list of cached keys and their expiry timestamp
 */
const fetchCacheTable = async () => {
  try {
    const { [LS_CACHE_LIST]: allCachedData = "[]" } = await getProperties([
      LS_CACHE_LIST,
    ]);
    return parseJson(allCachedData, []);
  } catch (e) {
    return [];
  }
};

/**
 * Creates a unique cache node key for a fetch request
 * @param {string} fetch fetch API url
 * @param {{method,body}} fetchOptions fetch options
 * @returns {string} a cache key for the fetched Url and options
 */
const calcCacheKey = (fetch, fetchOptions) => {
  let key = (fetchOptions.method || HTTP_GET) + ":" + fetch;
  if (fetchOptions.method === HTTP_POST && fetchOptions.body) {
    key = key + `:` + fetchOptions.body;
  }
  return SHA256(key).toString();
};

/**
 * Store or replace a cache entry
 * @param {string} cacheKey cache entry unique key
 * @param {Object} response api response
 */
const saveCachedResponse = async (cacheKey, response) => {
  return syncedInvoke({ mutex: MTX_CACHE }, async () => {
    const expiresAt = Date.now() + CACHE_TTL;
    const cacheItem = {
      key: cacheKey,
      expiresAt,
    };

    const cacheList = await fetchCacheTable();
    const updatedCacheList = cacheList.filter((n) => n.key !== cacheKey);
    updatedCacheList.push(cacheItem);
    await setProperties({
      [LS_CACHE_LIST]: updatedCacheList,
      [cacheKey]: response,
    });
  });
};

/**
 * Returns API cached response if found
 * @param {string} url fetch url
 * @param {Object} options fetch options
 */
const findCachedResponse = async (url, options) => {
  const cacheKey = calcCacheKey(url, options);

  const { [cacheKey]: cachedResponse } = await getProperties([cacheKey]);

  return cachedResponse ? parseJson(cachedResponse, null) : null;
};

/**
 * A generator function that yields cached then live API fetch results
 * If no cache is found, first yield is {data: null}
 * @param {string} url fetch url
 * @param {Object} options fetch option
 */
export async function* fetchJsonCacheFirst(url, options) {
  const cachedApiResponse = await findCachedResponse(url, options);

  if (cachedApiResponse) {
    //Yield cached response
    yield { ...cachedApiResponse, cached: true };
  } else {
    yield null;
  }

  //Yield live data
  yield fetchJson(url, options);
}

/**
 * Remove expired cached entries.
 * Remove all cached entries if signOut is true
 * @param {{signOut}} param0 optional parameters
 */
export async function cleanupCache({
  signOut = false,
  deleteAll = false,
} = {}) {
  const cleanedUpProps = signOut
    ? {
        [LS_ACCESS_TOKEN]: EMPTY_VAL,
        [LS_ID_TOKEN]: EMPTY_VAL,
        [LS_SESSION]: EMPTY_VAL,
        [LS_VPN_SETUP]: EMPTY_VAL,
        [LS_EULA_ACCEPTED]: EMPTY_VAL,
        [LS_USER_ACTIONS]: EMPTY_VAL,
        [LS_PROVISION_ID]: EMPTY_VAL,
        [LS_PRODUCT_ID]: EMPTY_VAL,
        [LS_CARRIER_ID]: EMPTY_VAL,
        [LS_CACHE_LIST]: EMPTY_VAL,
      }
    : {};

  const cacheList = await fetchCacheTable();
  const now = Date.now();

  const newProps = cacheList.reduce(
    (results, cacheItem) => {
      if (signOut || deleteAll || cacheItem.expiresAt < now) {
        results[cacheItem.key] = EMPTY_VAL; //delete expired cache, or all if signout
      } else {
        //keep only non expired items
        results[LS_CACHE_LIST].push(cacheItem);
      }
      return results;
    },
    { ...cleanedUpProps, [LS_CACHE_LIST]: [] }
  );

  if (signOut || deleteAll) {
    newProps[LS_CACHE_LIST] = EMPTY_VAL;
  }

  return setProperties(newProps);
}

/**
 * A wrapper for fetch API
 * Supports simulated responses for automation test purpose
 * Supports lazy fetching for http header authorization and accessToken values
 * Helps debugging and logging errors
 *
 * @param {string} url fetch Url
 * @param {{method,headers,body}} options fetch options
 * @returns {{ok:boolean, status, text:function():Promise, json:function():Promise}}
 * @throws {{status:number, code?:number, message?:string, source, stack?:string}}
 */
export async function fetchUrl(url, options = { method: HTTP_GET }) {
  const {
    context: { automation },
  } = store.getState();

  if (automation) {
    const fakeResponseKey = Object.keys(automation).find((key) =>
      url.match(RegExp(key, "i"))
    );

    if (fakeResponseKey) {
      const fakeResponse = automation[fakeResponseKey];
      const { status, body } = fakeResponse;

      if (status === HTTP_FETCH_FAILED) {
        return delay(1000).then(() => {
          return Promise.reject({
            status,
            code: HTTP_FETCH_FAILED,
            message: "Failed to fetch",
            source: urlToSource(url, options.method),
          });
        });
      }

      const ok = isApiSuccess(status);

      if (ok) {
        //status between 200-299
        const bodyText = JSON.stringify(body);
        return delayedResult({
          ...fakeResponse,
          ok,
          text: async () => bodyText,
          json: async () => body,
        });
      } else {
        //Failure 400+, 500+
        return delay(1000).then(() => {
          return Promise.reject({
            ...parseErrorModel(status, JSON.stringify(body)),
            source: urlToSource(url, options.method),
            ok,
          });
        });
      }
    }
  }

  //Default Content-Type
  const headers = { "Content-Type": "application/json", ...options.headers };

  const fetchOptions = { ...options, headers };

  try {
    //Lazy Authorization value fetch
    if (typeof options.headers.Authorization === "function") {
      headers.Authorization = await options.headers.Authorization();
    }

    const response = await fetch(url, fetchOptions);
    if (response.ok) {
      return response; //Successful response
    }
    //API or Error response, read the error stream
    const errorDetails = await fetchErrorDetails(response, options.method);
    throw errorDetails;
  } catch (e) {
    //network error, offline or api error
    const { method, headers, cache, body } = fetchOptions;

    const errorDetails =
      e.ok === undefined //offline or network error
        ? parseErrorModel(HTTP_FETCH_FAILED, e)
        : e;

    uwpLog(
      `fetch(${url},${stringifyJson({
        method,
        headers,
        cache,
        body,
      })}) failed response-> ${stringifyJson(errorDetails)}`,
      LOG_LEVEL_ERROR
    );

    throw Object({ source: urlToSource(url, options.method), ...errorDetails }); //Throw common error model
  }
}

/**
 * Fetching a url resource of Content-Type="application/json"
 * A wrapper to fetchUrl->fetch
 * if options.cache!=CACHE_NO_STORE, it stores the successful GET|POST response into cache
 * @param {string} api fetch API Url
 * @param {{method,headers,cache,body}} options
 * @return {{ok:boolean, status:number, data:Object,statusText?:string}}
 * @throws {{ok:boolean, status:number, code:number, message:string, source:string, stack?:string}} same fetchUrl() error response
 */
export async function fetchJson(
  api,
  options = { method: HTTP_GET, headers: {} }
) {
  return fetchUrl(api, options)
    .then((resp) => {
      return parseJsonResponse(resp);
    })
    .then((result) => {
      //Caching the successful response if allowed by the caller
      if (
        result.ok &&
        options.cache !== CACHE_NO_STORE &&
        [HTTP_GET, HTTP_POST].includes(options.method)
      ) {
        const cacheKey = calcCacheKey(api, options);
        saveCachedResponse(cacheKey, result);
      }
      return result;
    })
    .catch((e) => {
      //fetchUrl or parseJsonResponse failures
      //Adding source to the error result
      // const source = urlToSource(api);

      throw e; //for break points
    });
}

/**
 * Checks if httpStatus is returned from API code ( not web server nor web browser )
 * @param {string} httpStatus http response status
 * @returns {boolean}
 */
export const isValidApiResponse = (httpStatus) => {
  return isApiSuccess(httpStatus) || isApiError(httpStatus);
};

/**
 * Checks if httpStatus is a successful API response
 * @param {string} httpStatus http response status
 * @returns {boolean}
 */
export const isApiSuccess = (httpStatus) => {
  return httpStatus >= HTTP_OK && httpStatus < HTTP_BAD_REQUEST;
};

/**
 * Checks if the http status represents an error code returned from the API
 * ( not a server error nor a failure to fetch)
 * @param {number} httpStatus http status code
 * @returns {boolean}
 */
export const isApiError = (httpStatus) => {
  return (
    httpStatus &&
    httpStatus >= HTTP_BAD_REQUEST &&
    httpStatus < HTTP_SERVER_ERROR
  );
};

/**
 * Checks of the http status is returned from web server
 * reporting a failure to execute the API code
 * @param {string} httpStatus http response status
 * @returns {boolean}
 */
export const isServerError = (httpStatus) => {
  return httpStatus === HTTP_FETCH_FAILED || httpStatus >= HTTP_SERVER_ERROR;
};

/**
 * A shared utility to navigate to error page
 * For easier debugging
 * @param {{status,code,message,source}} error errorInfo
 */
export const navToErrorPage = (error) => {
  store.dispatch(
    push({
      pathname: ROUTE_FAILURE,
      search: createFailureQueryString(error),
    })
  );
};
