import { refreshAccessToken } from "./auth";
import {
  identityBreachCountBegin,
  identityBreachCountSuccess,
  identityBreachCountFailure,
} from "../dws";
import { API_IDENTITY, HTTP_POST } from "../../constants/main";
import {
  analyticsHeaders,
  fetchJson,
  storeErrorDetails,
  stringifyJson,
} from "../../utils";
import { getServiceUrl } from "./settings";

/**
 * Fetches number of breaches for a given email address.
 * Doesn't require OTP token, only accessToken
 * @param {{email:string,hit_trigger:string}} param0 options
 * @returns {ok,error?,total_breaches:number}
 */
export const fetchBreachCountInfo = ({
  email,
  hit_trigger = "monitor_new_email",
}) => async (dispatch, getState) => {
  dispatch(identityBreachCountBegin(email));

  try {
    const apiBaseUrl = await dispatch(getServiceUrl(API_IDENTITY));

    const response = await fetchJson(`${apiBaseUrl}/breach/v1/BreachCount`, {
      method: HTTP_POST,
      headers: {
        Authorization: () =>
          dispatch(refreshAccessToken()).then(
            (accessToken) => `Bearer ${accessToken}`
          ),
        ...analyticsHeaders(getState(), hit_trigger),
      },
      body: stringifyJson({
        value: email,
        type: "email",
      }),
      referrer: "/",
    });

    const {
      data: {
        breach_info: { total_breaches },
      },
    } = response;

    dispatch(identityBreachCountSuccess(total_breaches));
    return { ok: true, total_breaches };
  } catch (e) {
    const error = storeErrorDetails(e);
    dispatch(identityBreachCountFailure(error));
    return { ok: false, error };
  }
};
