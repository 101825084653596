//TODO: check if still used
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { String } from "../../l10n/loader";

// DOM
import { Button, Input, Label } from "@mcafee/pegasus";

// Store
import { updateMobileNumber } from "../../store/thunks/dashboard";
import { MOBILE_REGEX } from "../../constants/main";
import { InPlaceProgress } from "../styledComponents/progress";

const ProtectMobileDevicePopUp = ({
  updateMobileProgress,
  onFinish,
  onCancel,
  updateMobileNumber,
}) => {
  const localePrefix = "popups.";
  const [disableState, setDisableState] = useState(true);
  const [inputKind, setInputKind] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const phNumber = useRef();

  useEffect(() => {
    if (phNumber.current) {
      phNumber.current.focus();
    }
  }, []);

  /* For each input check if its Digits only and also verify its length
     Disable the 'Send Link' button incase the alphanumeric or not 10 digits
  */
  const handleChange = (e) => {
    const phone = e.target.value;
    const numberLength = phone.length;
    setPhoneNumber(phone);

    let mobileMaxLength = phone.includes("+")
      ? 15
      : phone.includes(" ")
      ? 14
      : 11;

    if (numberLength > mobileMaxLength) {
      setInputKind("danger");
      setErrorMsg(<String id={`${localePrefix}phoneError`} />);
      setDisableState(true);
    } else {
      if (MOBILE_REGEX.test(e.target.value)) {
        setInputKind("success");
        setDisableState(false);
      } else {
        setInputKind("");
        setDisableState(true);
      }
      setErrorMsg("");
    }
  };

  // Request for the link
  const handleSendLink = async (e) => {
    setErrorMsg(null);
    const { error } = await updateMobileNumber(phoneNumber); //TODO: missing otpToken
    if (!error) {
      onFinish(true);
    } else {
      setErrorMsg(<String id={`errorTryAgain`} />);
    }
  };

  const styles = {
    popUpStyles: {
      minWidth: 400,
    },
    logoStyles: {
      float: "center",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
    contentStyles: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    errorStyles: {
      textAlign: "left",
      display: "flex",
      justifyContent: "left",
      alignItems: "left",
      marginLeft: "12px",
    },
  };

  return (
    <div style={styles.popUpStyles} className="pa-32">
      {/* <img
        style={styles.logoStyles}
        src={AddDevice}
        alt=""
        className="mb-36 pb-36"
      />
      <Heading
        className="mt-36 pt-36 flex justify-content-center align-items-center"
        size="xs"
        data-testid="protectMobileDeviceHeader"
      >
        <String id={`${localePrefix}addMobileDevicePopUp.header`} />
      </Heading>
      <Paragraph
        style={styles.contentStyles}
        className="mt-8"
        size="sm"
        data-testid="protectMobileDeviceContent"
      >
        <String id={`${localePrefix}addMobileDevicePopUp.content`} />
      </Paragraph> */}
      {updateMobileProgress ? (
        <InPlaceProgress infinite noPercentage />
      ) : (
        <>
          <div
            className="wrap"
            style={styles.contentStyles}
            data-testid="protectMobileDeviceInput"
          >
            <Input
              className="mt-40"
              icon="laptop-mobile-outline"
              label={<String id={`${localePrefix}phoneNumber`} />}
              ref={phNumber}
              kind={inputKind}
              onChange={handleChange}
              id="ProtectMobileDevicePhoneNumber"
              value={phoneNumber}
            ></Input>
          </div>
          <div style={styles.errorStyles}>
            <Label size="sm" className="text-danger mt-4">
              {errorMsg}
            </Label>
          </div>
          <div className="mt-40 flex">
            <Button
              className="mx-auto"
              onClick={handleSendLink}
              disabled={disableState}
              id="ProtectMobileDeviceSendLink"
              data-testid="protectMobileDeviceSendLinkButton"
              size="sm"
            >
              <String id={`${localePrefix}ok`} />
            </Button>
            <Button
              className="mx-auto"
              variant="secondary"
              onClick={onCancel}
              data-testid="protectMobileCancelButton"
              size="sm"
            >
              <String id={`${localePrefix}cancel`} />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    ...state.dashboard,
  }),
  { updateMobileNumber }
)(ProtectMobileDevicePopUp);
