import React, { useEffect } from "react";
import { String } from "../../l10n/loader";
import { connect, useDispatch } from "react-redux";

import { Heading, Paragraph, Button, Label, Row, Col } from "@mcafee/pegasus";

import { DialogLayout } from "../../components/layouts/DialogLayout";

import Expired from "../../components/_assets/images/shared/expired.svg";

import Logout from "../../components/Logout/Logout";
import { showPopup } from "../../store/thunks/popups";
import { POPUP_SYNC_SUBSCRIPTION } from "../../components/PopUps/constants";
import {
  subscriptionInitiatedEvent,
  collectScreenLoadEvent,
  newGUID,
  isSubscriptionActive,
} from "../../utils";
import {
  ANALYTICS_DASHBOARD,
  ANALYTICS_HOME,
  ANALYTICS_IN_APP,
  ANALYTICS_MANUAL,
  ANALYTICS_PROTECTION,
  CAMPAIGN_SUBSCRIPTION_EXPIRY_MESSAGE_CONTENT,
  EVENTID_PPS_CAMPAIGN_VIEW,
  FEATURE_SUBSCRIPTION,
  HIT_CATEGORY,
  HIT_FEATURE,
  HIT_LABEL_1,
  HIT_LABEL_2,
  HIT_SCREEN,
  HIT_TRIGGER,
  SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_EXPIRED,
  SCREEN_DETAILS,
  SCREEN_TYPE,
} from "../../constants";
import { uwpSendTelemetry } from "../../uwp";

import { ROUTE_DASHBOARD } from "../../components/app/routes";
import { replace } from "connected-react-router";

const ExpiredSubscription = ({ subscriptionStatus, renewUrl }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    uwpSendTelemetry(EVENTID_PPS_CAMPAIGN_VIEW, {
      hit_trigger: ANALYTICS_IN_APP,
      [HIT_LABEL_1]: "subscription_expiry_message",
      [HIT_LABEL_2]: CAMPAIGN_SUBSCRIPTION_EXPIRY_MESSAGE_CONTENT,
      hit_guid: newGUID(),
    });

    collectScreenLoadEvent({
      [HIT_FEATURE]: FEATURE_SUBSCRIPTION,
      [HIT_CATEGORY]: ANALYTICS_PROTECTION,
      [HIT_TRIGGER]: ANALYTICS_MANUAL,
      [HIT_SCREEN]: SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_EXPIRED,
      [SCREEN_TYPE]: ANALYTICS_DASHBOARD,
      [SCREEN_DETAILS]: "trial_status_expired_state",
    });
  }, []);

  useEffect(() => {
    if (isSubscriptionActive(subscriptionStatus)) {
      dispatch(replace(ROUTE_DASHBOARD));
    }
  }, [dispatch, subscriptionStatus]);

  const _InitialStatusString = (str) => `popups.subscription.${str}`;

  const onSubscribeNowClick = () => {
    subscriptionInitiatedEvent(
      SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_EXPIRED,
      ANALYTICS_HOME
    );
    window.open(renewUrl, "_blank");
  };

  const onClickSync = async (e) => {
    await dispatch(showPopup(POPUP_SYNC_SUBSCRIPTION));
    // only navigate to dashboard if subscription/trial or extention is active
  };

  const Buttons = () => (
    <>
      <Button
        onClick={onSubscribeNowClick}
        id="SubscriptionRenewNow"
        data-testid="syncSubscriptionPopupRenewNowButton"
        size="sm"
        className="syncSubscriptionPopup__button"
      >
        <String id={_InitialStatusString`renewNow`} />
      </Button>

      <Logout type="button" />
    </>
  );

  return (
    <DialogLayout
      pageId="ExpiredSubscriptionPage"
      buttons={<Buttons />}
      image={Expired}
      animate={false}
      showBack={false}
    >
      <Heading size="sm">
        <String id={_InitialStatusString`header`} />
      </Heading>
      <Row className="mt-32">
        <Col xs={11}>
          <Paragraph size="sm" className="mb-12">
            <String id={_InitialStatusString`content`} />
          </Paragraph>
          <Paragraph size="sm">
            <String
              id={_InitialStatusString`content2`}
              values={{
                link: (
                  <Label
                    className="text-info syncSubscriptionPopup__link"
                    size="sm"
                    onClick={onClickSync}
                    id="SubscriptionSync"
                  >
                    <String id={_InitialStatusString`link`} />
                  </Label>
                ),
              }}
            />
          </Paragraph>
        </Col>
      </Row>
    </DialogLayout>
  );
};

export default connect(
  (state) => ({
    subscriptionStatus: state.subscription.subscriptionStatus,
    renewUrl: state.subscription.renewUrl,
  }),
  {}
)(ExpiredSubscription);
