import { createSlice } from "@reduxjs/toolkit";
import { signOutSuccess } from "./auth";

const initialState = {
  // To fetch and store the count of breaches for each email ID
  fetchingBreachCount: false,
  fetchBreachCountError: null,
  email: null,
  breachCount: null,
  onboardingScan: null,
  onboardingEmailEnrolled: null,
};

const slice = createSlice({
  name: "@dws",
  initialState,
  reducers: {
    scanEmailReset(state) {
      state.email = null;
    },
    identityBreachCountBegin(state, action) {
      state.fetchingBreachCount = true;
      state.fetchBreachCountError = null;
      state.breachCount = null;
      state.email = action.payload;
    },
    identityBreachCountFailure(state, action) {
      state.fetchingBreachCount = false;
      state.fetchBreachCountError = action.payload;
      state.breachCount = null;
    },
    identityBreachCountSuccess(state, action) {
      state.fetchingBreachCount = false;
      state.fetchBreachCountError = null;
      state.breachCount = action.payload || 0;
    },
    doneEmailEnrollment(state) {
      state.onboardingEmailEnrolled = true;
    },
    skipEmailEnrollment(state) {
      state.onboardingEmailEnrolled = false;
    },
    doneOnboardingScan(state) {
      state.onboardingScan = true;
    },
    skipOnboardingScan(state) {
      state.onboardingScan = false;
    },
  },
  extraReducers: {
    [signOutSuccess]: () => {
      return initialState;
    },
  },
});

export const {
  scanEmailReset,
  identityBreachCountBegin,
  identityBreachCountFailure,
  identityBreachCountSuccess,
  doneEmailEnrollment,
  skipEmailEnrollment,
  doneOnboardingScan,
  skipOnboardingScan,
} = slice.actions;

export default slice.reducer;
