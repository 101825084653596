import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading } from "@mcafee/pegasus";
import { connect } from "react-redux";
import { navigateBack } from "../../store/thunks/app";

const ConfirmVpnDisclosurePopUp = ({ onFinish, onCancel, navigateBack }) => {
  const localePrefix = "popups.confirmVpn";

  const handleSubmit = async (e) => {
    navigateBack({ steps: 2 });
    onFinish(true);
  };

  return (
    <div className="text-left popup__container popup__container--md">
      <Heading size="xs" data-testid="ConfirmVpnHeader">
        <String id={`${localePrefix}.header`} />
      </Heading>

      <Paragraph
        className="mt-8 flex text-left"
        size="sm"
        data-testid="confirmVpnContent"
      >
        <String id={`${localePrefix}.content`} />
      </Paragraph>

      <div className="mt-24 text-right">
        <Button
          className="ml-32"
          onClick={handleSubmit}
          id="DoNotUseVpnButton"
          data-testid="DoNotUseVpn"
          size="sm"
        >
          <String id={`${localePrefix}.doNotUseVpn`} />
        </Button>
        <Button
          className="ml-32"
          variant="tertiary"
          onClick={onCancel}
          id="ConfirmVpnGoBack"
          data-testid="confirmVpnGoBackButton"
          size="sm"
        >
          <String id={`${localePrefix}.goBack`} />
        </Button>
      </div>
    </div>
  );
};
export default connect(
  (state) => ({
    ...state.router.location,
  }),
  { navigateBack }
)(ConfirmVpnDisclosurePopUp);
