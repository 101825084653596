import React from "react";
import { Button, Icon, Subheading } from "@mcafee/pegasus";
import { useDispatch, useSelector } from "react-redux";
import { String } from "../../l10n/loader";
import { navigateBack } from "../../store/thunks/app";
import { createFormattedId } from "../../utils";
import "./DialogLayout.scss";
import BackgroundProgress from "../Shared/BackgroundProgress";


export const DialogLayout = ({
  pageId,
  image,
  buttons,
  animate = false,
  showBack = true,
  showInlineImage = true,
  children,
  animationMode = "slideInRight",
  title,
  titleIcon,
  onSettingsClick
}) => {
  const dispatch = useDispatch();

  const { query } = useSelector((state) => state.router.location);

  const onClickBack = (e) => {
    //passing {steps:1}, gives priority to popping history over "back" parameter
    //If history is empty (page reloaded, or it was the app starting route), "back" route value would be pushed
    dispatch(navigateBack({ steps: 1 }));
  };

  const Avatar = () => {
    return (
      image !== undefined && (
        <div className={`dialog-layout-avatar`}>
          <img src={image} alt="feature_avatar" />
        </div>
      )
    );
  };

  return (
    <div id={createFormattedId(pageId) + "Page"} className={"dialog-layout"}>
      <BackgroundProgress />
      {title !== undefined && (
          <div className="dialog-title">
            <Subheading className="dialog-title__text" size="md">Antivirus</Subheading>

            <Button
          tabIndex={0}
          kind="normal"
          variant="tertiary"
          onClick={onSettingsClick}
          className="text-gray-light"
          size="sm"
        >
          <Icon icon="settings-atlas-outline" />
        </Button>
          </div>
        )}
      <div
        className={
          "content-block" + (animate ? " animated " + animationMode : "")
        }
      >
        {" "}
        
        <div className="left-col">
          <Avatar />
        </div>
        <div className="right-col">
          {(showBack || (showBack === undefined && query.back)) && (
            <div className="dialog-layout-back">
              <div className="buttons-bar">
                <Button
                  variant="tertiary"
                  onClick={onClickBack}
                  id={createFormattedId(pageId) + "GoBack"}
                  size="sm"
                >
                  <Icon
                    icon="chevron-left-outline"
                    className="mr-8"
                    size="sm"
                  />
                  <String id="goBack" />
                </Button>
              </div>
            </div>
          )}

          <div className="dialog-layout-content">
            {showInlineImage && <Avatar />}
            {children}
          </div>
        </div>
      </div>
      {buttons !== undefined && (
        <div className="dialog-layout-buttons">
          <div className="buttons-bar">{buttons}</div>
        </div>
      )}
    </div>
  );
};
