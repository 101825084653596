import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading, Icon } from "@mcafee/pegasus";

import "./avExcludeItemPopup.scss";

const AvExcludeItemPopup = ({ onCancel, onAddFile }) => {
  const _S = (str) => `popups.avExcludeItemPopup.${str}`;

  return (
    <div className="avExcludeItemPopup">
      <Heading
        className="avExcludeItemPopup__title"
        size="xs"
        data-testid="avExcludeItemHeader"
      >
        <String id={_S`header`} />
      </Heading>

      <Paragraph
        className="avExcludeItemPopup__content"
        size="sm"
        data-testid="avExcludeItemPopupContent"
      >
        <String id={_S`content`} />
      </Paragraph>
      <Paragraph
        className="avExcludeItemPopup__content__info"
        size="sm"
        data-testid="avExcludeItemPopupContent"
      >
        <Icon
          icon="info-outline"
          size="md"
          className="text-gray-light avExcludeItemPopup__content__info__icon"
        />
        <String id={_S`info`} />
      </Paragraph>
      <div className="avExcludeItemPopup__buttons">
        <Button
          size="sm"
          onClick={() => {
            onAddFile();
            onCancel();
          }}
          id="addExcludedFile"
          data-testid="addExcludedFileButton"
          shape="pill"
          variant="primary"
        >
          <String id={_S`addButton`} />
        </Button>
        <Button
          className="ml-32"
          onClick={onCancel}
          id="avExcludeItemCancel"
          variant="tertiary"
          data-testid="avExcludeItemPopupCancelButton"
          size="sm"
        >
          <String id={_S`cancelButton`} />
        </Button>
      </div>
    </div>
  );
};

export default AvExcludeItemPopup;
