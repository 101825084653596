import React, { useEffect, useState } from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Heading, Paragraph } from "@mcafee/pegasus";
import { DialogLayout } from "../layouts/DialogLayout";

import idea from "../_assets/images/identity/wellDone/idea.svg";
import dogParkingIntruder from "../_assets/images/identity/wellDone/sayBye.svg";
import raining from "../_assets/images/identity/wellDone/raining.svg";
import successMan from "../_assets/images/identity/wellDone/successMcafee.svg";

// logic:
// PII or hashed password + known source: well done screen; DEFAULT CASE
// PII or hashed password + unknown source: better prepared screen;
// plaintext password + known source: say bye to hackers screen;
// plaintext password + unknown source: you're in the known screen;

const WellDoneOverlay = ({ breachType, onCancel }) => {
  const _S = (str) => `identity.${str}`;

  const [bgImage, setBgImage] = useState(null);
  const [stringId, setStringId] = useState(null);

  useEffect(() => {
    switch (breachType) {
      case "pii_known":
        setBgImage(successMan);
        setStringId("welldone");
        break;
      case "pii_unknown":
        setBgImage(raining);
        setStringId("betterPrepared");
        break;
      case "plaintext_known":
        setBgImage(dogParkingIntruder);
        setStringId("sayBye");
        break;
      case "plaintext_unknown":
        setBgImage(idea);
        setStringId("inTheKnown");
        break;
      default:
    }
  }, [breachType]);

  const onClickEnd = () => {
    onCancel();
  };

  const Buttons = ({ stringId }) => {
    return (
      <Button
        onClick={onClickEnd}
        data-testid="wellDoneButton"
        id="WellDoneThanks"
      >
        <String id={stringId} />
      </Button>
    );
  };

  return (
    <DialogLayout
      pageId="wellDone"
      buttons={stringId && <Buttons stringId={_S(`${stringId}.button`)} />}
      showBack={false}
      image={bgImage}
      style={{ position: "fixed", top: 0, width: "100%" }}
    >
      {stringId && (
        <>
          <Heading size="md" className="mb-12">
            <String id={_S(`${stringId}.header`)} />
          </Heading>
          <Paragraph size="sm" className="mb-16">
            <String id={_S(`${stringId}.content1`)} />
          </Paragraph>
          {stringId !== "inTheKnown" && (
            <Paragraph size="sm" className="mb-16">
              <String id={_S(`${stringId}.content2`)} />
            </Paragraph>
          )}
          {stringId === "betterPrepared" && (
            <Paragraph size="sm">
              <String id={_S`betterPrepared.content3`} />
            </Paragraph>
          )}
        </>
      )}
    </DialogLayout>
  );
};

export default WellDoneOverlay;
