import React from "react";
import { String } from "../../l10n/loader";
import { StyledModalContainer } from "../styledComponents";

// Store

// DOM
import { Paragraph, Heading, Button } from "@mcafee/pegasus";
import VpnNotifications from "../../components/_assets/images/dashboard/allowNotifications.svg";
import { uwpOpenWindowsSettingPage } from "../../uwp";
import {
  SETTINGS,
  SETTING_VPN_NOTIFY_DEVICE,
  SETTING_GENERAL_NOTIFY_EMAIL,
  SETTING_GENERAL_NOTIFY_DEVICE,
  SETTING_IDENTITY_NOTIFY_EMAIL,
  SETTING_IDENTITY_NOTIFY_DEVICE,
} from "../../constants/main";
import { useDispatch } from "react-redux";
import { saveSettings } from "../../store/thunks/settings";

export default function NotificationsModal({ onFinish, onCancel, source }) {
  const localePrefix = "vpn.";
  const dispatch = useDispatch();
  const onClickSavePreferences = () => {
    //if turn on notifications is selected from settings, turn all notifications on
    if (source === SETTINGS) {
      dispatch(
        saveSettings({
          [SETTING_VPN_NOTIFY_DEVICE]: true,
          [SETTING_GENERAL_NOTIFY_EMAIL]: true,
          [SETTING_GENERAL_NOTIFY_DEVICE]: true,
          [SETTING_IDENTITY_NOTIFY_EMAIL]: true,
          [SETTING_IDENTITY_NOTIFY_DEVICE]: true,
        })
      );
    } else {
      uwpOpenWindowsSettingPage("ms-settings:notifications");
    }
    onFinish();
  };

  const styles = {
    img: {
      transform: "scale3d(0.5, 0.5, 0.5)",
      height: 320,
    },
  };

  return (
    <StyledModalContainer
      className="pb-32 flex direction-column"
      data-testid="VpnNotificationsModal"
    >
      <div className="flex grow-1 no-wrap slider__container">
        <div className="slider__container">
          <img style={styles.img} src={VpnNotifications} alt="Splash_Image" />
        </div>
      </div>

      <div className="px-48">
        <Heading size="lg" className="mb-16">
          <String id={`${localePrefix}vpnNotifications.header`} />
        </Heading>

        <Paragraph size="md" className="mb-16">
          <String id={`${localePrefix}vpnNotifications.content_part1`} />
        </Paragraph>
        <Paragraph size="md" className="mb-32">
          <String id={`${localePrefix}vpnNotifications.content_part2`} />
        </Paragraph>
      </div>
      <div className="text-right">
        <Button
          className="mr-12 px-56"
          id="VpnNotifyMe"
          onClick={onClickSavePreferences}
          size="sm"
          data-testid="VpnNotifyMeButton"
        >
          <String id={`${localePrefix}notifyMe`} />
        </Button>
        <Button
          className="mr-40"
          variant="tertiary"
          id="VpnNoThanks"
          onClick={onCancel}
          size="sm"
        >
          <String id={`${localePrefix}noThanks`} />
        </Button>
      </div>
    </StyledModalContainer>
  );
}
