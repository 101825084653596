import {

    setProperties,
    getProperties
} from "../../utils";

import {
    LS_AV_LAST_SCAN_ID
} from "../../constants/main";

import {
    setScanId,
    setScanStart,
    setScanType,
    setStatus,
    setProgress,
    setRtsStatus,
    updateThreats,
    setIssuesResolved,
    setFilesScanned,
    setIssuesUnresolved,
} from "../av"

import { ROUTE_AV_SCAN_RESULT } from "../../components/app/routes";
import { v4 as uuidv4 } from "uuid";
import { uwpPublish, uwpSubscribe } from "../../uwp";
import { dashboardShowCard } from "../dashboard";
import { CARD_AV_FOUND_THREATS, CARD_AV_TURN_ON_RTS, CARD_AV_PROTECTING_YOU } from "../../components/DashboardCards/constants";
import { push } from "connected-react-router";


const onComplete = (msg, dispatch, getState) => {

    var scan_id = getState().av.scanId;
    console.log("onComplete: ", msg, " scan_id = ",scan_id);
   
    dispatch(setStatus("completed"));
    dispatch(setProgress(0));


    dispatch(setIssuesResolved(msg.fixed_count));
    dispatch(setFilesScanned(msg.scanned_count));
    dispatch(setIssuesUnresolved(msg.unresolved_count));

    var unresolved = msg.unresolved_count;

    console.log("onComplete unresolved =", unresolved);
    dispatch(dashboardShowCard({ card: CARD_AV_FOUND_THREATS, show: (unresolved > 0 ? true : false) }));
    dispatch(push(ROUTE_AV_SCAN_RESULT));
    dispatch(updateThreats({ threats: { fixedThreats: [], unresolvedThreats: [] } }))
   
    uwpPublish("nav.detections.query", scan_id === 0 ? {} : { scan_id: scan_id })

};

const onStatusChange = (msg, dispatch, getState) => {

    console.log("onStatusChange = ", msg);
    if (!msg || msg.scans === undefined) {
        dispatch(setStatus("idle"));
        return
    }
    if (msg.scans[0].scan_state === "active") {
        dispatch(setStatus("running"));
    } else if (msg.scans[0].scan_state === "paused") {
        dispatch(setStatus("paused"));
    } else {
        console.log("onStatusChange uknown state in  ", msg);
    }
};

const onRtsStatusChange = (msg, dispatch, getState) => {

    if (msg.state === "off") {
        dispatch(dashboardShowCard({ card: CARD_AV_TURN_ON_RTS, show: true }));
        dispatch(dashboardShowCard({ card: CARD_AV_PROTECTING_YOU, show: false }));
    } else {
        dispatch(dashboardShowCard({ card: CARD_AV_TURN_ON_RTS, show: false }));
        if (getState().av.threats.unresolvedThreats.length === 0) {
            dispatch(dashboardShowCard({ card: CARD_AV_PROTECTING_YOU, show: true }));
        }
    }
    dispatch(setRtsStatus(msg.state));
};

const onDetectionHistory = (msg, dispatch, getState) => {

    console.log("onDetectionHistory =", msg);

    const threat_types_detection = [
        { state: "cleaned", arr: 'fixedThreats' },
        { state: "unresolved", arr: 'unresolvedThreats' },
        { state: "blocked", arr: 'unresolvedThreats' },
        { state: "quarantined", arr: 'fixedThreats' }
    ]

    if (msg.state === "unresolved" || msg.state === "blocked") {
        dispatch(dashboardShowCard({ card: CARD_AV_FOUND_THREATS, show: true }));
        dispatch(dashboardShowCard({ card: CARD_AV_PROTECTING_YOU, show: false }));
    }

    const state = getState();
    threat_types_detection.forEach(element => {
        if (msg.state === element.state) {

            var indx = state.av.threats[element.arr].findIndex((it) => {
                return it.id === msg.index.toString()
            })
            if (indx === -1) {
                var arr = state.av.threats[element.arr];
                var someDate = new Date(msg.date);
                someDate = someDate.getTime();
                console.log("onDetectionHistory ",msg.date,"->",someDate," >= ",state.av.scanStart," scan_id = ",state.av.scanId)
                
                const payload = { threats: {} }
                payload.threats[element.arr] = arr.concat({
                    time: someDate / 1000,
                    id: msg.index.toString(),
                    infection_name: msg.malware,
                    path: msg.filename,
                    status: msg.state
                });
                dispatch(updateThreats(payload))
            }
        }

    });

};

const onProgress = (msg, dispatch, getState) => {
    console.log("onProgress: ", msg);
    dispatch(setStatus("running"));
    dispatch(setProgress(msg.progress_percent));
    dispatch(setIssuesResolved(msg.fixed_count));
    dispatch(setFilesScanned(msg.scanned_count));
    dispatch(setIssuesUnresolved(msg.unresolved_count));
}

const startScan = (type) => async (dispatch, getState) => {
    let scan_id = uuidv4();
    let time = Date.now();
    dispatch(setScanStart(time));
    setProperties({ [LS_AV_LAST_SCAN_ID]: scan_id})
    dispatch(setScanId(scan_id));
    dispatch(setScanType(type));
    console.log("RunScan ");
    uwpPublish("nav.ods.start", {
        scan_id: scan_id,
        scan_type: type,
    });
}

const checkAvState = () => async (dispatch, getState) => {

    uwpSubscribe("nav.detections.history", (msg) => onDetectionHistory(msg, dispatch, getState));
    uwpSubscribe("nav.ods.progress", (msg) => onProgress(msg, dispatch, getState));
    uwpSubscribe("nav.ods.complete", (msg) => onComplete(msg, dispatch, getState));
    uwpSubscribe("nav.ods.status", (msg) => onStatusChange(msg, dispatch, getState));
    uwpSubscribe("av.state", (msg) => onRtsStatusChange(msg, dispatch, getState));

    let prop = await getProperties([LS_AV_LAST_SCAN_ID])
    console.log("checkAvState props=", prop);
    var scan_id = 0;
    if (prop && prop[LS_AV_LAST_SCAN_ID]) {
        scan_id = prop[LS_AV_LAST_SCAN_ID]
        console.log("checkAvState scan_id = ", scan_id);
        dispatch(setScanId(scan_id));
    }

    dispatch(updateThreats({ threats: { fixedThreats: [], unresolvedThreats: [] } }))
    setTimeout(() => {
        console.log("publish nav.detections.query ...");
        dispatch(dashboardShowCard({ card: CARD_AV_FOUND_THREATS, show: false }));
        dispatch(dashboardShowCard({ card: CARD_AV_TURN_ON_RTS, show: false }));
        dispatch(dashboardShowCard({ card: CARD_AV_PROTECTING_YOU, show: true }));
        uwpPublish("nav.detections.query", scan_id === 0 ? {} : { scan_id: scan_id })
        uwpPublish("nav.ods.query", {});
    }, 100);
}

export {
    startScan,
    checkAvState
}