import {
  saveSettingsBegin,
  saveSettingsSuccess,
  saveSettingsFailure,
  loadSettingsBegin,
  loadSettingsSuccess,
  loadSettingsFailure,
  fetchDiscoveryApiBegin,
  fetchDiscoveryApiSuccess,
  fetchDiscoveryApiFailure,
} from "../settings";
import {
  detectEnvironment,
  stringifyJson,
  setProperties,
  getProperties,
  fetchJsonCacheFirst,
  syncedInvoke,
  storeErrorDetails,
} from "../../utils";
import {
  ENV_DEV,
  ENV_QA,
  API_SECURITY_MGMT,
  APP_ID,
  FALLBACK_APIS_QA,
  FALLBACK_APIS_STAGING,
  EVN_STAGING,
  FALLBACK_APIS_PROD,
  SETTING_SERVICE_URLS,
  HTTP_GET,
  MTX_DISCOVERY_API,
} from "../../constants/main";
import { refreshAccessToken } from "./auth";

/**
 * Redux thunk dispatched to save a group of settings
 * It would internal call setProperties that stores the values
 * in UWP container settings, Polaris messageBus, or localStorage
 * @param {*} properties name value pairs of props
 * @returns {Boolean}
 */
export const saveSettings = (properties) => async (dispatch) => {
  dispatch(saveSettingsBegin());

  try {
    const success = await setProperties(properties);

    if (success) {
      dispatch(saveSettingsSuccess(properties));
    } else {
      dispatch(
        saveSettingsFailure({
          source: "setProps",
          code: 0,
          message: "Failed to save settings",
        })
      );
    }
  } catch (e) {
    dispatch(saveSettingsFailure(storeErrorDetails(e)));
  }
};

//Called on App start to check the notifications status
export const loadSettings = (settingsToLoad = null) => async (dispatch) => {
  if (!settingsToLoad) {
    return {};
  }

  const mutex = stringifyJson(settingsToLoad);

  return syncedInvoke({ mutex, reuseActiveCallResult: true }, async () => {
    dispatch(loadSettingsBegin(settingsToLoad));
    try {
      const settings = await getProperties(settingsToLoad);
      dispatch(loadSettingsSuccess(settings));
      return settings;
    } catch (e) {
      dispatch(loadSettingsFailure(e.message));
    }
    return {};
  });
};

export const getBuiltInServiceUrls = (serviceId) => {
  const env = detectEnvironment();
  let serviceUrls = FALLBACK_APIS_PROD;

  switch (env) {
    case ENV_DEV:
      serviceUrls = FALLBACK_APIS_QA;
      break;
    case ENV_QA:
      serviceUrls = FALLBACK_APIS_QA;
      break;
    case EVN_STAGING:
      serviceUrls = FALLBACK_APIS_STAGING;
      break;
    default:
      //Production
      serviceUrls = FALLBACK_APIS_PROD;
      break;
  }

  return serviceId ? serviceUrls[serviceId] : serviceUrls;
};

export const getServiceUrl = (serviceId, fromFetchToken = false) => async (
  dispatch,
  getState
) => {
  try {
    let { ServiceURLs } = getState().settings;

    if (!ServiceURLs) {
      ServiceURLs = (await dispatch(loadSettings([SETTING_SERVICE_URLS])))
        .ServiceURLs;
    }

    const { accessToken } = getState().auth;

    if (!ServiceURLs && accessToken && !fromFetchToken) {
      //For web browser host, no UWP
      ServiceURLs = await dispatch(fetchDiscoveryApi());
    }

    if (typeof ServiceURLs === "string") {
      ServiceURLs = JSON.parse(ServiceURLs.toLowerCase()
      );
    }

    if (ServiceURLs) {
      const { [serviceId]: serviceUrl } = ServiceURLs;

      if (serviceUrl) {
        return serviceUrl;
      }
    }
  } catch (e) {
    dispatch(loadSettingsFailure(e.message));
  }
  //Couldn't find discovery API entry
  return getBuiltInServiceUrls(serviceId);
};

export const fetchDiscoveryApi = () => async (dispatch, getState) => {
  return syncedInvoke(
    { mutex: MTX_DISCOVERY_API, reuseActiveCallResult: true },
    async () => {
      let cachedFound = false;
      dispatch(fetchDiscoveryApiBegin());
      try {
        const apiBaseUrl = getBuiltInServiceUrls(API_SECURITY_MGMT);
        const fetchingCacheFirst = await fetchJsonCacheFirst(
          `${apiBaseUrl}/config/v1/servicediscovery/${APP_ID}`,
          {
            method: HTTP_GET,
            headers: {
              Authorization: () =>
                dispatch(refreshAccessToken()).then(
                  (accessToken) => `Bearer ${accessToken}`
                ),
            },
          }
        );

        const handleResponse = ({ data: results }) => {
          if (results && results.sd_info && results.sd_info.urls) {
            dispatch(fetchDiscoveryApiSuccess(results.sd_info.urls));
          } else if (!cachedFound) {
            dispatch(fetchDiscoveryApiFailure(stringifyJson(results)));
          }
        };
        const { value: cachedResponse } = await fetchingCacheFirst.next();
        if (cachedResponse) {
          cachedFound = true;
          handleResponse(cachedResponse);

          //Using .then() to skip waiting for for live response
          fetchingCacheFirst
            .next()
            .then(({ value: liveResponse }) => {
              handleResponse(liveResponse);
            })
            .catch((e) => {
              dispatch(
                fetchDiscoveryApiFailure(
                  storeErrorDetails({ ...e, cached: true })
                )
              );
            });
        } else {
          //wait for live response
          const liveResponse = await (await fetchingCacheFirst.next()).value;
          handleResponse(liveResponse);
        }
        return getState().settings.ServiceURLs;
      } catch (e) {
        dispatch(fetchDiscoveryApiFailure(storeErrorDetails(e)));
      }
    }
  );
};
