//TODO: check if still needed
import parser from "ua-parser-js";
import { refreshAccessToken } from "./auth";

import {
  fetchDeviceBegin,
  fetchDeviceSuccess,
  fetchDeviceFailure,
  addDeviceBegin,
  addDeviceFailure,
  addDeviceSuccess,
} from "../devices";

import {
  fetchDeviceListBegin,
  fetchDeviceListFailure,
  fetchDeviceListSuccess,
} from "../deviceList";

import {
  API_PPS,
  LS_DEVICE_INFO,
  HTTP_POST,
  HTTP_GET,
  CACHE_NO_STORE,
  EMPTY_JSON,
  HTTP_NO_CONTENT,
} from "../../constants/main";
import { getServiceUrl } from "./settings";
import {
  fetchJson,
  getProperties,
  parseJson,
  setProperties,
  stringifyJson,
  storeErrorDetails,
} from "../../utils";

export const fetchDevice = (cspid) => async (dispatch) => {
  dispatch(fetchDeviceBegin());
  try {
    const {
      [LS_DEVICE_INFO]: deviceInfoJson = EMPTY_JSON,
    } = await getProperties([LS_DEVICE_INFO]);

    let deviceInfo = parseJson(deviceInfoJson);

    if (!deviceInfo) {
      const UA = parser(navigator.userAgent);

      const platforms = [
        "not applicable",
        "windows",
        "linux",
        "mac OS",
        "mobileos",
      ];

      let platform = platforms.indexOf(UA.os.name.toLowerCase());
      if (platform === -1) {
        platform = 0;
      }

      const types = ["unknown", "phone", "tablet", "pc", "linux", "mac"];
      let type = types.indexOf(UA.device.name);
      if (type === -1) {
        type = 0;
      }

      deviceInfo = {
        id: cspid,
        type: String(type),
        platform: String(platform),
        osName: UA.os.name,
        browser: UA.browser.name,
        name: UA.device.name,
      };

      // await fetchUrl(
      //   `${API_SECURITY_MGMT}/device-store/v2/devices`,
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${accessToken}`,
      //     },
      //     body: JSON.stringify({
      //       device: {
      //         client_id: cspid,
      //         name: UA.browser.name,
      //         os: UA.os.name.toLowerCase(),
      //         os_version: UA.os.version,
      //         device_type: UA.device.name ? UA.device.name : "unknown",
      //       },
      //     }),
      //   }
      // );

      setProperties({ [LS_DEVICE_INFO]: deviceInfo });
    }
    dispatch(fetchDeviceSuccess(deviceInfo));
    return deviceInfo;
  } catch (e) {
    dispatch(fetchDeviceFailure(storeErrorDetails(e)));
  }
};

export const fetchDeviceList = () => async (dispatch) => {
  dispatch(fetchDeviceListBegin());

  try {
    const accessToken = await dispatch(refreshAccessToken());
    const apiBaseUrl = await dispatch(getServiceUrl(API_PPS));
    const response = await fetchJson(`${apiBaseUrl}/v1/user/devices`, {
      method: HTTP_GET,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const { data: devices } = response;

    if (devices.errors) {
      dispatch(
        fetchDeviceListFailure({
          ...response,
          ok: false,
          status: HTTP_NO_CONTENT,
        })
      );
      return false;
    }

    if (devices.devices) {
      dispatch(fetchDeviceListSuccess(devices.devices));
    }
  } catch (e) {
    // won't be hit due to current server implementation
    dispatch(fetchDeviceListFailure(storeErrorDetails(e)));
  }
};

export const addDevice = (deviceInfo) => async (dispatch) => {
  dispatch(addDeviceBegin());

  try {
    const accessToken = await dispatch(refreshAccessToken());
    const apiBaseUrl = await dispatch(getServiceUrl(API_PPS));
    const { ok } = await fetchJson(`${apiBaseUrl}/v1/user/device`, {
      method: HTTP_POST,
      cache: CACHE_NO_STORE,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: stringifyJson(deviceInfo),
    });

    if (ok) {
      setProperties({ [LS_DEVICE_INFO]: deviceInfo });
      dispatch(addDeviceSuccess(deviceInfo));
      dispatch(fetchDeviceList());
    } else {
      dispatch(addDeviceFailure({ message: "Failed to add device" }));
    }
  } catch (err) {
    dispatch(addDeviceFailure(err.message));
  }
};
