//<Identity><PersonalInfoAlerts><IdentityBreachLayout><AssetExplorer>
//dwsMonitor API (fetchDwsDashboard) failure is handled by the Identity component

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { push } from "connected-react-router";

// DOM
import {
  Row,
  Col,
  Label,
  Grid,
  Icon,
  Button,
  Sidebar,
  SidebarDivider,
  SidebarNavList,
  SidebarNavListItem,
  SidebarTitle,
  SidebarSubTitle,
  SidebarNavListButton,
} from "@mcafee/pegasus";

import { String } from "../../l10n/loader";
import { showPopup } from "../../store/thunks/popups";
import { POPUP_ADD_EMAIL_IDENTITY } from "../PopUps/constants";
import {
  ROUTE_SCAN_EMAIL_RESULT,
  ROUTE_IDENTITY,
  ROUTE_SETTINGS_IDENTITY,
} from "../app/routes";

import { InPlaceProgress } from "../styledComponents/progress";
import { ASSET_PUBLIC_ID, REMEDIATION_ACTED } from "../../constants/main";
import { createQueryString } from "../../utils";

export default function AssetExplorer() {
  const _S = (str) => `identity.assetExplorer.${str}`;
  const dispatch = useDispatch();
  const [unResolvedBreaches, setUnResolvedBreaches] = useState({});
  const { breaches, assets, fetchingDashboard, fetchDashboardError } =
    useSelector((state) => state.identity);
  const { pathname, query } = useSelector((state) => state.router.location);
  const filteredAssets = assets.filter((asset) => asset.type === "email");
  const onAddNewEmail = async () => {
    const email = await dispatch(showPopup(POPUP_ADD_EMAIL_IDENTITY));
    if (email) {
      dispatch(
        push({
          pathname: ROUTE_SCAN_EMAIL_RESULT,
          search: createQueryString({
            src: "monitor_new_email",
            path: ROUTE_IDENTITY,
            email,
          }),
        })
      );
    }
  };

  const onSettingsClick = (e) => {
    dispatch(push(ROUTE_SETTINGS_IDENTITY));
  };

  const onAssetClick = (asset_public_id) => {
    if (asset_public_id === query.asset_public_id) {
      return;
    }

    var search = createQueryString({
      back: query.back ? decodeURIComponent(query.back) : undefined,
      [ASSET_PUBLIC_ID]: asset_public_id || undefined,
    });

    dispatch(
      push({
        pathname: ROUTE_IDENTITY,
        search,
      })
    );
  };

  const getTotalUnResolvedBreachesCount = (asset_public_id) => {
    return unResolvedBreaches[asset_public_id] === undefined
      ? 0
      : unResolvedBreaches[asset_public_id];
  };

  useEffect(() => {
    if (breaches && !fetchingDashboard) {
      const unResolvedBreaches = breaches.reduce(
        (unResolvedBreaches, breach) => {
          if (breach.remediation_status !== REMEDIATION_ACTED) {
            if (unResolvedBreaches[breach.asset_public_id] === undefined) {
              unResolvedBreaches[breach.asset_public_id] = 1;
            } else {
              unResolvedBreaches[breach.asset_public_id] += 1;
            }
            unResolvedBreaches.total++;
          }
          return unResolvedBreaches;
        },
        { total: 0 }
      );
      setUnResolvedBreaches(unResolvedBreaches);
    }
  }, [breaches, fetchingDashboard]);

  if (!filteredAssets && !fetchDashboardError) {
    return <InPlaceProgress infinite noPercentage />;
  }

  return (
    <Sidebar className="sidebarContainer">
      <SidebarTitle
        data-testid="AssetExplorertitle"
        className="flex justify-content-between align-items-center"
      >
        <String id={_S`title`} />
        <Button
          tabIndex={0}
          kind="normal"
          variant="tertiary"
          onClick={onSettingsClick}
          className="text-gray-light"
          size="sm"
        >
          <Icon icon="settings-atlas-outline" />
        </Button>
      </SidebarTitle>

      <SidebarSubTitle data-testid="AssetExplorercontent" size="sm">
        <String id={_S`content`} />
      </SidebarSubTitle>
      <SidebarNavList>
        {filteredAssets && filteredAssets.length > 0 && (
          <SidebarNavListItem
            key="AllEmailAddress"
            id="AllEmailAddress"
            tabIndex="0"
          >
            <SidebarNavListButton
              className="text-left"
              active={ROUTE_IDENTITY === pathname && !query.asset_public_id}
              onClick={() => onAssetClick(undefined)}
            >
              <Grid>
                <Row className="mb-2">
                  <Col>
                    <Label
                      size="sm"
                      data-testid="AssetExplorerAllEmailTitle"
                      className={
                        ROUTE_IDENTITY === pathname && !query.asset_public_id
                          ? {}
                          : "text-dark"
                      }
                    >
                      <String id={_S`allEmailTitle`} />
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label size="xs">
                      <String
                        id={
                          unResolvedBreaches.total >= 2
                            ? _S`totalbreaches`
                            : _S`totalbreach`
                        }
                        values={{
                          count: unResolvedBreaches.total,
                        }}
                      />
                    </Label>
                  </Col>
                </Row>
              </Grid>
            </SidebarNavListButton>
          </SidebarNavListItem>
        )}

        {filteredAssets &&
          filteredAssets.length > 0 &&
          filteredAssets.map((asset, index) => (
            <SidebarNavListItem
              key={`${asset.asset_value}`}
              id={`${asset.asset_value}_${index}`}
              data-testid={`${asset.asset_value}_${index}`}
              tabIndex={index}
            >
              <SidebarNavListButton
                active={
                  pathname === ROUTE_IDENTITY &&
                  query.asset_public_id === asset.public_id
                }
                onClick={() => onAssetClick(asset.public_id)}
              >
                <Grid>
                  <Row className="mb-2">
                    <Col>
                      <Label
                        size="sm"
                        className={
                          pathname === ROUTE_IDENTITY &&
                          query.asset_public_id === asset.public_id
                            ? ""
                            : "text-dark"
                        }
                      >
                        {asset.asset_value}
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label size="xs">
                        <String
                          id={
                            getTotalUnResolvedBreachesCount(asset.public_id) >=
                            2
                              ? _S`breaches`
                              : _S`breach`
                          }
                          values={{
                            count: getTotalUnResolvedBreachesCount(
                              asset.public_id
                            ),
                          }}
                        />
                      </Label>
                    </Col>
                  </Row>
                </Grid>
              </SidebarNavListButton>
            </SidebarNavListItem>
          ))}
      </SidebarNavList>
      <SidebarDivider />
      <SidebarNavList>
        <SidebarNavListItem key={"monitoredEmails_click"}>
          <SidebarNavListButton
            id="MonitoredEmailsButton"
            className="text-left"
            onClick={onSettingsClick}
          >
            <Label
              size="sm"
              className="text-info"
              data-testid="AssetExplorerMonitoredEmails"
            >
              <String id={_S`monitoredEmails`} />
            </Label>
          </SidebarNavListButton>
        </SidebarNavListItem>
        <SidebarDivider />
        <SidebarNavListItem key={"addNewEmail_emailsAddKey"}>
          <SidebarNavListButton
            id="AddEmailButton"
            onClick={onAddNewEmail}
            className="flex justify-content-between text-left"
          >
            <Label
              size="sm"
              className="text-info"
              data-testid="AssetExplorerAddEmail"
            >
              <String id={_S`addEmail`} />
            </Label>
            <Icon
              icon="add-atlas-outline"
              className="text-info ma-0 pa-0"
              size="md"
            />
          </SidebarNavListButton>
        </SidebarNavListItem>
      </SidebarNavList>
    </Sidebar>
  );
}
