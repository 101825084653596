import React, { useEffect } from "react";
import { String } from "../../l10n/loader";
import { connect, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {
  VPN_PRIVACY,
  VPN_DISCLOSURE_BANDWIDTH,
  VPN_DISCLOSURE_DEVICE,
  VPN_DISCLOSURE_NETWORK,
  VPN_DISCLOSURE_DATE,
  VPN_DISCLOSURE_SESSION,
  VPN_DISCLOSURE_CONNECTION,
  VPN_DISCLOSURE_LOCATION,
  FEATURE_VPN,
} from "../../constants/main";
import { ROUTE_DASHBOARD, ROUTE_VPN_SETUP_RUN } from "../app/routes";

// Store
import { setupVpn } from "../../store/thunks/vpn";

// DOM
import {
  Paragraph,
  Button,
  Icon,
  Label,
  Subheading,
  Heading,
} from "@mcafee/pegasus";

// Custom components
import privacyRespect from "../_assets/images/vpnSetup/privacyRespect.svg";

//utils
import { createFormattedId, useIsSignedIn } from "../../utils";
import { POPUP_CONFIRM_VPN_DISCLOSURE } from "../PopUps/constants";
import { showPopup } from "../../store/thunks/popups";
import Unauthorized from "../Shared/Unauthorized";
import { fetchPrivacyInfo } from "../../store/thunks/app";
import { DialogLayout } from "../layouts/DialogLayout";

const items = [
  VPN_DISCLOSURE_BANDWIDTH,
  VPN_DISCLOSURE_DEVICE,
  VPN_DISCLOSURE_NETWORK,
  VPN_DISCLOSURE_DATE,
  VPN_DISCLOSURE_SESSION,
  VPN_DISCLOSURE_CONNECTION,
  VPN_DISCLOSURE_LOCATION,
];

const VpnSetupDisclosure = ({
  push,
  setupVpn,
  query,
  privacyUrl,
  fetchingPrivacyInfo,
  fetchingPrivacyInfoError,
  //dispatches
  fetchPrivacyInfo,
  vpnEnabled,
}) => {
  const _S = (str) => `vpn.${str}`;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!privacyUrl && !fetchingPrivacyInfo && !fetchingPrivacyInfoError) {
      fetchPrivacyInfo();
    }
  }, [
    fetchPrivacyInfo,
    fetchingPrivacyInfo,
    fetchingPrivacyInfoError,
    privacyUrl,
  ]);

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  const onClickLetsDoThis = () => {
    setupVpn({ trigger: query.back ? query.back.slice(1) : "dashboard_card" });
    push({ pathname: ROUTE_VPN_SETUP_RUN });
  };

  const onClickDontUseVpn = (e) => {
    dispatch(
      showPopup(POPUP_CONFIRM_VPN_DISCLOSURE, {
        source: query.back || ROUTE_DASHBOARD,
        trigger: query.back ? query.back.slice(1) : "dashboard_card",
      })
    );
  };

  const onClickDataDisclosure = (e) => {
    if (privacyUrl) {
      window.open(privacyUrl, "_blank");
    }
  };

  const Buttons = () => (
    <>
      <Button
        onClick={onClickLetsDoThis}
        id={`${createFormattedId(VPN_PRIVACY)}Accept`}
        size="sm"
        className="mr-24"
        data-testid="VpnPrivacyAccept"
        disabled={!vpnEnabled}
      >
        <String id={_S`privacyDisclosure.Accept`} />
      </Button>
      <Button
        variant="tertiary"
        onClick={onClickDontUseVpn}
        id={`${createFormattedId(VPN_PRIVACY)}DontUseVpn`}
        size="sm"
        data-testid="DontUseVpn"
      >
        <String id={_S`privacyDisclosure.DontUseVpn`} />
      </Button>
    </>
  );

  return (
    <DialogLayout
      pageId={VPN_PRIVACY}
      image={privacyRespect}
      buttons={<Buttons />}
      animate={true}
    >
      <div>
        <Heading size="sm">
          <String id={_S`privacyDisclosure.header`} />
        </Heading>
        <Paragraph size="sm" className="text-secondary my-24">
          <String id={_S`privacyDisclosure.content_part1`} />
        </Paragraph>
        <Label size="sm" className="text-secondary mb-24">
          <String id={_S`privacyDisclosure.content_part2`} />
        </Label>
        <Button
          variant="tertiary"
          className="ml-0 pl-0 mb-24 text-info"
          onClick={onClickDataDisclosure}
          id={`${createFormattedId(VPN_PRIVACY)}WhatData`}
          size="sm"
        >
          <Icon icon="info-outline" className="mr-12" size="md" />
          <String id={_S`privacyDisclosure.privacyPolicy`} />
        </Button>
      </div>
      <Subheading size="md" className="pb-12">
        <String id={_S`privacyDisclosure.header2`} />
      </Subheading>
      <ul>
        {items.map((item, index) => (
          <li className="ml-24" key={`bulletPoint${index}`}>
            <div className="py-8">
              <Label className="float-left" id="privacyLabel">
                <String id={`vpn.privacyDisclosure.${item}.label`} />
              </Label>
              <Paragraph size="sm" id="privacyText">
                <String id={`vpn.privacyDisclosure.${item}.text`} />
              </Paragraph>
            </div>
          </li>
        ))}
      </ul>
    </DialogLayout>
  );
};

export default connect(
  (state) => ({
    ...state.router.location,
    ...state.context,
    vpnEnabled: state.experience.features[FEATURE_VPN].enabled,
  }),
  { push, setupVpn, fetchPrivacyInfo }
)(VpnSetupDisclosure);
