import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: "idle",
    scanId: '0',
    scanType: '',
    scanStart: 0,
    progress: 0,
    issuesResolved: 0,
    filesScanned:0,
    issuesUnresolved:0,
    rtsStatus: 'on',
    threats: {fixedThreats:[],unresolvedThreats:[]}
  };

const slice = createSlice({
    name: "@av",
    initialState,
    reducers: {
        setIssuesResolved: (state,action) => {
            state.issuesResolved = action.payload;
        },
        setFilesScanned: (state,action) => {
            state.filesScanned = action.payload;
        },
        setIssuesUnresolved: (state,action) => {
            state.issuesUnresolved = action.payload;
        },
        setScanId: (state,action) => {
            state.scanId = action.payload;
        },
        setScanStart: (state,action) => {
            state.scanStart = action.payload;
        },
        setScanType: (state,action) => {
            state.scanType = action.payload;
        },
        setRtsStatus: (state,action) => {
            state.rtsStatus = action.payload;
        },
        setStatus: (state,action) => {
            state.status = action.payload;
        },
        setProgress: (state,action) => {
            state.progress = action.payload;
        },
      updateThreats: (state,action) => {
          for(const [key,value] of Object.entries(action.payload.threats)) {
            state.threats[key] = value;
          }
          
      }
    }
});

export const {
    setIssuesResolved,
    setFilesScanned,
    setIssuesUnresolved,
    updateThreats,
    setScanId,
    setScanStart,
    setScanType,
    setStatus,
    setProgress,
    setRtsStatus
  } = slice.actions;
  
  export default slice.reducer;
  