import {
  getFeature,
  getScreenType,
  getScreenCategory,
  getScreenDetails,
  getTrigger,
  getScreenDetailsFromBreachedInfo,
  collectScreenLoadEvent,
  getScreenFlow,
  newGUID,
} from "../../utils";
import {
  ANALYTICS_SUCCESS,
  ANALYTICS_FAILURE,
  ANALYTICS_MANUAL,
  ANALYTICS_NA,
  ANALYTICS_SUCCESS_CODE,
  ANALYTICS_AUTOSIGNIN,
  ANALYTICS_SCAN_MANUAL,
  ANALYTICS_VPN,
  ANALYTICS_MANUAL_REQUEST,
  ANALYTICS_APP_PUSH,
  ANALYTICS_ONBOARDING,
  ANALYTICS_EMAIL,
  ANALYTICS_ENGAGEMENT,
  HIT_TYPE_SCREEN,
  ANALYTICS_LIFE_CYCLE,
  ANALYTICS_FORM,
  ANALYTICS_ERROR,
  ANALYTICS_PROGRESS,
  ANALYTICS_SETTING,
  ANALYTICS_HOME,
  ANALYTICS_EDUCATION,
  SCREEN_DETAILS,
  HIT_FEATURE,
  HIT_TRIGGER,
  HIT_CATEGORY,
  HIT_SCREEN,
  SCREEN_TYPE,
  ANALYTICS_PROTECTION,
  ANALYTICS_ID_PROTECTION,
  ANALYTICS_ID_ONBOARDING,
  ANALYTICS_AUTHENTICATION,
  ANALYTICS_CANCEL,
  SCREEN_LOAD_RESULT,
  ANALYTICS_DETAILS,
  ANALYTICS_LAUNCHER_ICON,
  SCREEN_PREVIOUS_SCREEN,
  EVENTID_PPS_EMAIL_VALIDATION_CANCEL,
  EVENTID_PPS_EMAIL_VALIDATION_COMPLETE,
  EVENTID_PPS_EMAIL_VALIDATION_START,
  EVENTID_PPS_OTP_VALIDATED,
  EVENTID_PPS_OTP_GENERATED,
  EVENTID_PPS_ID_BREACH_SUMMARY,
  EVENTID_PPS_IDS_ENROLLMENT_COMPLETE,
  EVENTID_PPS_IDS_ENROLLMENT_SKIP,
  EVENTID_PPS_ADD_DEVICE_INITIATED,
  EVENTID_PPS_SETTING,
  EVENTID_PPS_WIFI_DISCONNECT_REMIND_ME_LATER,
  EVENTID_PPS_WIFI_DISCONNECT_SKIP,
  EVENTID_PPS_WIFI_DISCONNECT_START,
  EVENTID_PPS_WIFI_DISCONNECT_COMPLETE,
  EVENTID_PPS_WIFI_DISCONNECT_PROMPTED,
  EVENTID_PPS_VPN_SETUP_COMPLETE,
  EVENTID_PPS_EULA_ACCEPTED,
  EVENTID_PPS_ENROLLMENT_SKIP,
  EVENTID_PPS_SIGNUP,
  EVENTID_PPS_SIGNOUT,
  EVENTID_PPS_SIGNIN,
  EVENTID_PPS_ENROLLMENT_COMPLETE,
  ANALYTICS_MENU,
  EVENTID_PPS_EMAIL_REMOVE_START,
  EVENTID_PPS_EMAIL_ADDITION_START,
  EVENTID_PPS_EMAIL_ADDITION_COMPLETE,
  HIT_LABEL_2,
  HIT_HASH_1,
  EVENTID_PPS_EMAIL_ADDITION_CANCEL,
  EVENTID_PPS_EMAIL_REMOVE_COMPLETE,
  EVENTID_PPS_PHONE_VALIDATION_START,
  EVENTID_PPS_PHONE_VALIDATION_COMPLETE,
  RESULT,
  EVENTID_PPS_PHONE_VALIDATION_CANCEL,
  SCREEN_FLOW,
  EVENTID_PPS_EMAIL_REMOVE_CANCEL,
  EVENTID_PPS_VPN_PRIVACY_DISCLOSURE,
  ANALYTICS_DENY,
  EVENTID_PPS_VPN_SETUP_CANCEL,
  EVENTID_PPS_PHONE_ADDITION_COMPLETE,
  EVENTID_PPS_PHONE_REMOVE_COMPLETE,
  EVENTID_PPS_PHONE_REMOVE_START,
  EVENTID_PPS_PHONE_REMOVE_CANCEL,
  EVENTID_PPS_PHONE_ADDITION_CANCEL,
  EVENTID_PPS_USER_MOVE_OUT_START,
  HIT_LABEL_0,
  EVENTID_PPS_USER_MOVE_OUT_CANCEL,
  EVENTID_PPS_USER_MOVE_OUT_CONTINUE,
  EVENTID_PPS_USER_SUBSCRIPTION_SYNC,
  HIT_LABEL_1,
  EVENTID_CONTEXT_CHANGE,
  EVENTID_PPS_CAMPAIGN_VIEW,
  CAMPAIGN_SUBSCRIPTION_EXPIRY_MESSAGE,
  EVENTID_PPS_CAMPAIGN_REMOVED,
  EVENTID_PPS_CAMPAIGN_CLICKED,
  ANALYTICS_IN_APP,
  ANALYTICS_SETTINGS,
  ANALYTICS_ACCEPT,
  HIT_ENGAGEMENT,
  ANALYTICS_SNACKBAR,
  HIT_LABEL_3,
  PPS_PHONE_VALIDATION_COMPLETE_MESSAGE,
  PPS_PHONE_VALIDATION_COMPLETE_FAILURE_MESSAGE,
  PPS_EMAIL_ADDITION_MESSAGE,
  PPS_EMAIL_ADDITION_FAILURE_MESSAGE,
  CAMPAIGN_SUBSCRIPTION_EXPIRY_MESSAGE_CONTENT,
} from "../../constants/analytics";
import {
  SCREEN_ADD_EMAIL,
  SCREEN_BREACHES_FOUND_RESULTS,
  SCREEN_BREACH_DETAIL,
  SCREEN_BREACH_DETAIL_CHANGE_PWD_OPTION,
  SCREEN_BREACH_DETAIL_CHANGE_PWD_VERIFIED,
  SCREEN_BREACH_DETAIL_CONFIRMATION,
  SCREEN_BREACH_DETAIL_RESOLVED,
  SCREEN_DEVICES_PROTECT_NEW_DEVICE,
  SCREEN_DWS_SCANNING,
  SCREEN_DWS_SCAN_DONE,
  SCREEN_DWS_SCAN_EXIT_VERIFICATION,
  SCREEN_ONBOARDING_CREATE_ACCOUNT,
  SCREEN_ONBOARDING_QUICK_TOUR,
  SCREEN_ONBOARDING_SIGN_IN,
  SCREEN_PWD_TIPS,
  SCREEN_SETTINGS_ACCOUNT_ADD_PHONE,
  SCREEN_SETTINGS_ACCOUNT_ADD_PHONE_CONFIRMATION,
  SCREEN_SETTINGS_ACCOUNT_DEFAULT,
  SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION,
  SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_CONFIRM,
  SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_CONFIRMATION,
  SCREEN_SETTINGS_DELETE_EMAIL_CONFIRM,
  SCREEN_SETTINGS_DELETE_EMAIL_SNACKBAR,
  SCREEN_SETTINGS_NOTIFICATIONS_ENABLE,
  SCREEN_SETTINGS_NOTIFICATIONS_LIST,
  SCREEN_SETTINGS_NOTIFICATIONS_VPN,
  SCREEN_SETTINGS_VPN_SETTINGS_CONNECT,
  SCREEN_SPLASH,
  SCREEN_VPN_BENEFITS,
  SCREEN_VPN_SETUP_DATA_DISCLOSURE,
  SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_COUNTER_STATE,
  SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_EXPIRED,
  SCREEN_VPN_FAB_FIRST_TIME_INFO,
  SCREEN_SETTINGS_ADD_EMAIL_SNACKBAR,
} from "../../constants/analyticsScreens";
import { uwpSendTelemetry, uwpUpdateSharedContext } from "../../uwp";
import { v4 as uuidv4 } from "uuid";

import {
  POPUP_ADDRESSED_BREACHES,
  POPUP_CONFIRM_BEFORE_EXTERNAL_LINK,
  POPUP_CONFIRM_BEFORE_LEAVE_PAGE,
  POPUP_ENCRYPTED_PASSWORD,
  POPUP_PRE_EXPIRY,
  POPUP_SECURE_VPN,
  POPUP_WHAT_CAN_YOU_DO,
} from "../../../src/components/PopUps/constants";
import { showPopup } from "../../store/thunks/popups";

import {
  screenNameFromRouterPath,
  jwtPayload,
  currentScreenName,
  delay,
} from "../../../src/utils";

import moment from "moment";

// Redux types
import {
  signInSuccess,
  signInCancel,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  signOutCancel,
  signInBegin,
} from "../auth";
import {
  fetchContextSuccess,
  eulaAcceptedSuccess,
  fetchContextBegin,
  saveRemoteConfigVars,
} from "../context";
import {
  skipEmailEnrollment,
  doneEmailEnrollment,
  identityBreachCountBegin,
  identityBreachCountSuccess,
} from "../dws";
import {
  loadSettingsSuccess,
  saveSettingsFailure,
  saveSettingsSuccess,
} from "../settings";
import {
  setupVpnBegin,
  setupVpnFailure,
  setupVpnSuccess,
  vpnUpdateState,
} from "../vpn";
import {
  networkUpdateStatus,
  networkDisconnectStart,
  networkDisconnectSkip,
} from "../network";
import {
  dashboardDismissCard,
  emailKeyCardSuccess,
  emailKeyCardFailure,
  emailKeyCardBegin,
  deleteMobileNumberSuccess,
  deleteMobileNumberFailure,
  updateMobileNumberFailure,
  deleteMobileNumberBegin,
} from "../dashboard";
import {
  addAssetCancel,
  addAssetFailure,
  addAssetSuccess,
  fetchBreachDetailsSuccess,
  fetchDashboardSuccess,
  removeAssetFailure,
  removeAssetSuccess,
} from "../identity";
import {
  sendOtpFailure,
  sendOtpSuccess,
  verifyOtpFailure,
  verifyOtpSuccess,
} from "../otp";
import {
  BREACH_STATUS_RESOLVED,
  EMPTY_VAL,
  FEATURE_VPN,
  NETWORK_STATUS_ATTACKED,
  NETWORK_STATUS_HACKED,
  SS_PAGE_SEQUENCE,
  SYNC,
} from "../../constants/main";
import { openPopup, closePopup } from "../popups";
import {
  POPUP_ADD_EMAIL_DEVICE,
  POPUP_ADD_EMAIL_IDENTITY,
  POPUP_ADD_PHONE_NUMBER,
  POPUP_CONFIRM_VPN_DISCLOSURE,
  POPUP_FIVE_REASONS,
  POPUP_NEW_ASSET_CANCEL,
  POPUP_NOTIFICATIONS,
  POPUP_ONBOARD_KNOWMORE,
  POPUP_OTP,
  POPUP_PASSWORD_TIPS,
  POPUP_REMOVE_EMAIL,
  POPUP_REMOVE_PHONE_NUMBER,
  POPUP_SURVEY,
} from "../../components/PopUps/constants";
import {
  ROUTE_ACCOUNT,
  ROUTE_FAILURE,
  ROUTE_OPEN_SOURCE_LICENSES,
  ROUTE_ROOT,
  ROUTE_SCAN_EMAIL,
  ROUTE_SCAN_EMAIL_RESULT,
  ROUTE_SETTINGS_ABOUT,
  ROUTE_WELCOME,
  ROUTE_WELCOME1,
  ROUTE_WELCOME2,
  ROUTE_WELCOME3,
  ROUTE_EXPIRED_SUBSCRIPTION,
  ROUTE_SETTINGS_ACCOUNT,
  ROUTE_DASHBOARD
  
} from "../../components/app/routes";
import {
  CALL_HISTORY_METHOD,
  LOCATION_CHANGE,
  replace,
} from "connected-react-router";
import { TOAST_SNACKBAR_SUCCESS } from "../../components/Toasts/constants";
import { addToast } from "../toasts";
import { openOverlay } from "../overlays";
import { OVERLAY_WELL_DONE } from "../../components/Overlays/constants";
import { fetchPDSuccess, fetchPDBegin } from "../experience";

import {
  isSubscriptionExpired,
  getSubscriptionRemainingDays,
  getProductSubscriptionStatus,
  getSubscriptionType,
} from "../../utils/subscription";

const data_cupture = (store) => (next) => async (action) => {
  let prevPath, nextPath;
  const prevState = store.getState();

  if (CALL_HISTORY_METHOD === action.type && prevState.context.dirtyPage) {
    store.dispatch(showPopup(POPUP_CONFIRM_BEFORE_LEAVE_PAGE, { action }));
    return;
  }

  let result = next(action);
  const nextState = store.getState();
  prevPath = prevState.router.location.pathname;
  nextPath = nextState.router.location.pathname;
  const hit_guid = uuidv4().toUpperCase();

  // const { sentEmail: email } = nextState.otp;
  const { locale } = nextState.context;

  const { payload, type } = action;

  switch (type) {
    case saveRemoteConfigVars.type:
    case fetchPDSuccess.type:
      detectContextChange(prevState, nextState);
      break;
    case signOutSuccess:
      uwpUpdateSharedContext({
        product_vpn_setup_status: "0",
        user_global_reference_id: EMPTY_VAL,
        user_provision_id: EMPTY_VAL,
        client_id: EMPTY_VAL,
      });
      sessionStorage.removeItem(SS_PAGE_SEQUENCE);
      break;
    case signInBegin.type:
      if (payload.newUser) {
        collectScreenLoadEvent({
          [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
          [HIT_CATEGORY]: ANALYTICS_PROTECTION,
          [HIT_SCREEN]: SCREEN_ONBOARDING_CREATE_ACCOUNT,
          [SCREEN_TYPE]: ANALYTICS_FORM,
          [SCREEN_FLOW]: await getScreenFlow(
            screenNameFromRouterPath(nextPath),
            store
          ),
        });
      } else {
        collectScreenLoadEvent({
          [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
          [HIT_CATEGORY]: ANALYTICS_PROTECTION,
          [HIT_SCREEN]: SCREEN_ONBOARDING_SIGN_IN,
          [SCREEN_TYPE]: ANALYTICS_FORM,
          [SCREEN_PREVIOUS_SCREEN]: screenNameFromRouterPath(prevPath),
          [SCREEN_FLOW]: await getScreenFlow(
            screenNameFromRouterPath(nextPath),
            store
          ),
        });
      }

      break;
    case signInSuccess.type: {
      const { autoSignIn, accessToken } = payload;
      const accessTokenInfo = jwtPayload(accessToken);
      uwpUpdateSharedContext({
        user_global_reference_id: accessTokenInfo.grid,
        user_provision_id: accessTokenInfo.prv,
      });

      if (autoSignIn) {
        uwpSendTelemetry(EVENTID_PPS_SIGNIN, {
          hit_trigger: ANALYTICS_AUTOSIGNIN,
          hit_label_0: ANALYTICS_SUCCESS,
          hit_label_2: ANALYTICS_SUCCESS,
          hit_engagement_interactive: ANALYTICS_SUCCESS_CODE,
          hit_screen: screenNameFromRouterPath(nextPath),
          hit_guid,
        });
      } else {
        const { newUser, email } = nextState.auth;
        uwpSendTelemetry(
          newUser ? EVENTID_PPS_ENROLLMENT_COMPLETE : EVENTID_PPS_SIGNIN,
          {
            hit_trigger: ANALYTICS_MANUAL,
            hit_label_0: ANALYTICS_SUCCESS,
            hit_engagement_interactive: ANALYTICS_SUCCESS_CODE,
            hit_screen: screenNameFromRouterPath(nextPath),
            hit_guid,
          }
        );
        if (newUser) {
          uwpSendTelemetry(EVENTID_PPS_SIGNUP, {
            hit_event_id: EVENTID_PPS_SIGNUP,
            hit_feature: ANALYTICS_LIFE_CYCLE,
            hit_category_0: ANALYTICS_ONBOARDING,
            hit_trigger: screenNameFromRouterPath(prevPath),
            hit_label_0: ANALYTICS_SUCCESS,
            hit_hash_1: email,
            hit_guid,
          });
        }
      }
      break;
    }

    case signInFailure.type:
      const { newUser, email } = nextState.auth;
      if (newUser) {
        uwpSendTelemetry(EVENTID_PPS_SIGNUP, {
          hit_event_id: EVENTID_PPS_SIGNUP,
          hit_feature: ANALYTICS_LIFE_CYCLE,
          hit_category_0: ANALYTICS_ONBOARDING,
          hit_trigger: screenNameFromRouterPath(prevPath),
          hit_label_0: ANALYTICS_FAILURE,
          hit_label_1:
            payload && payload.message ? payload.message : "Failed to fetch",
          hit_hash_1: email,
          hit_guid,
        });
      } else {
        uwpSendTelemetry(EVENTID_PPS_SIGNIN, {
          hit_trigger: screenNameFromRouterPath(prevPath),
          hit_label_0: ANALYTICS_FAILURE,
          hit_label_1:
            payload && payload.message ? payload.message : "Failed to fetch",
          hit_engagement_interactive: ANALYTICS_SUCCESS_CODE,
          hit_screen: screenNameFromRouterPath(nextPath),
          hit_guid,
        });
      }
      break;

    case signInCancel.type:
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [SCREEN_LOAD_RESULT]: ANALYTICS_FAILURE, // Capture screen load is success or fail 1- success/ 0- failure
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_SCREEN]: SCREEN_ONBOARDING_SIGN_IN,
        [SCREEN_TYPE]: ANALYTICS_FORM,
        [SCREEN_PREVIOUS_SCREEN]: screenNameFromRouterPath(prevPath),
        [SCREEN_FLOW]: await getScreenFlow(
          screenNameFromRouterPath(nextPath),
          store
        ),
      });

      if (payload && payload.analytics) {
        uwpSendTelemetry(EVENTID_PPS_ENROLLMENT_SKIP, {
          hit_trigger: screenNameFromRouterPath(prevPath),
          hit_label_0: ANALYTICS_SUCCESS,
          hit_screen: screenNameFromRouterPath(prevPath),
          hit_guid,
        });

        if (payload.newUser) {
          uwpSendTelemetry(EVENTID_PPS_SIGNUP, {
            hit_feature: ANALYTICS_LIFE_CYCLE,
            hit_category_0: ANALYTICS_ONBOARDING,
            hit_trigger: screenNameFromRouterPath(prevPath),
            hit_label_0: ANALYTICS_FAILURE,
            hit_label_1: ANALYTICS_CANCEL,
            hit_hash_1: "",
            hit_guid,
          });
        } else {
          uwpSendTelemetry(EVENTID_PPS_SIGNIN, {
            hit_trigger: screenNameFromRouterPath(prevPath),
            hit_label_0: ANALYTICS_FAILURE,
            hit_label_1: ANALYTICS_CANCEL,
            hit_engagement_interactive: ANALYTICS_SUCCESS_CODE,
            hit_screen: screenNameFromRouterPath(nextPath),
            hit_guid,
          });
        }
      }
      break;

    case signOutSuccess.type:
      uwpSendTelemetry(EVENTID_PPS_SIGNOUT, {
        hit_trigger: ANALYTICS_MANUAL,
        hit_label_0: ANALYTICS_SUCCESS,
        hit_label_1: ANALYTICS_SUCCESS,
        hit_guid,
      });
      break;

    case signOutFailure.type:
      uwpSendTelemetry(EVENTID_PPS_SIGNOUT, {
        hit_trigger: ANALYTICS_MANUAL,
        hit_label_0: ANALYTICS_FAILURE,
        hit_label_1: payload.code,
        hit_guid,
      });
      break;

    case signOutCancel.type:
      if (payload && payload.analytics) {
        uwpSendTelemetry(EVENTID_PPS_SIGNOUT, {
          hit_trigger: ANALYTICS_MANUAL,
          hit_label_0: ANALYTICS_FAILURE,
          hit_label_1: ANALYTICS_CANCEL,
          hit_guid,
        });
      }

      break;

    case fetchContextBegin.type:
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_TRIGGER]: ANALYTICS_LAUNCHER_ICON,
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_SCREEN]: SCREEN_SPLASH,
        [SCREEN_TYPE]: ANALYTICS_PROGRESS,
        [SCREEN_FLOW]: await getScreenFlow(SCREEN_SPLASH, store),
      });
      break;
    case LOCATION_CHANGE:
      if (prevPath === nextPath) {
        break; //to fix duplicate screen load events
      }
      if (checkRedirect(store, prevPath, nextPath)) {
        break; //a direction occurred
      }

      const screenName = screenNameFromRouterPath(nextPath);
      //Not all screens views are posted here
      if (screenName) {
        collectScreenLoadEvent({
          [HIT_FEATURE]: getFeature(nextPath),
          [HIT_TRIGGER]: getTrigger(screenName, nextState, prevState),
          [HIT_CATEGORY]: getScreenCategory(screenName),
          [HIT_SCREEN]: screenName,
          [SCREEN_TYPE]: getScreenType(screenName, store),
          [SCREEN_DETAILS]: await getScreenDetails(screenName, store),
          [SCREEN_PREVIOUS_SCREEN]: screenNameFromRouterPath(prevPath),
          [SCREEN_FLOW]: await getScreenFlow(screenName, store),
        });
      }

      break;

    case fetchContextSuccess.type:
      uwpUpdateSharedContext({
        client_id: payload.cspid,
      });
      break;
    // eula
    case eulaAcceptedSuccess.type:
      uwpSendTelemetry(EVENTID_PPS_EULA_ACCEPTED, {
        hit_engagement_interactive: ANALYTICS_SUCCESS_CODE,
        hit_guid,
      });
      detectContextChange(prevState, nextState);
      break;
    // vpn
    case setupVpnBegin.type:
      uwpSendTelemetry(EVENTID_PPS_VPN_PRIVACY_DISCLOSURE, {
        hit_trigger: payload.trigger,
        hit_screen: screenNameFromRouterPath(nextPath),
        hit_label_0: ANALYTICS_ACCEPT,
        hit_label_1: ANALYTICS_ACCEPT,
        hit_label_2: ANALYTICS_MANUAL,
        hit_guid,
      });
      break;
    case setupVpnFailure.type:
      uwpSendTelemetry(EVENTID_PPS_VPN_SETUP_COMPLETE, {
        hit_trigger: payload.trigger,
        hit_screen: screenNameFromRouterPath(nextPath),
        hit_label_0: ANALYTICS_FAILURE,
        hit_label_1: "setup_complete",
        hit_label_2: ANALYTICS_MANUAL,
        hit_guid,
      });
      break;
    case setupVpnSuccess.type:
      uwpSendTelemetry(EVENTID_PPS_VPN_SETUP_COMPLETE, {
        hit_trigger: payload.trigger,
        hit_screen: screenNameFromRouterPath(nextPath),
        hit_label_0: ANALYTICS_SUCCESS,
        hit_label_1: ANALYTICS_NA,
        hit_label_2: ANALYTICS_MANUAL,
        hit_guid,
      });
      break;
    case vpnUpdateState.type:
      if (payload.vpnSetup !== undefined) {
        uwpUpdateSharedContext({
          product_vpn_setup_status: payload.vpnSetup ? "1" : "0",
        });
      }
      break;

    // network
    case networkUpdateStatus.type: {
      // New ssl-attack coming in will cause a prompt
      if (
        payload.status === NETWORK_STATUS_ATTACKED ||
        payload.status === NETWORK_STATUS_HACKED
      ) {
        uwpSendTelemetry(EVENTID_PPS_WIFI_DISCONNECT_PROMPTED, {
          hit_trigger: ANALYTICS_SCAN_MANUAL, // TBD
          hit_screen: screenNameFromRouterPath(nextPath),
          hit_engagement_interactive: ANALYTICS_SUCCESS_CODE,
          hit_label_1: ANALYTICS_NA, // TBD
          hit_guid,
        });
      }

      // If user previously had ssl-attack and fixed it by disconnecting
      if (
        (prevState.network.status === NETWORK_STATUS_ATTACKED &&
          payload.status !== NETWORK_STATUS_ATTACKED) ||
        (prevState.network.status === NETWORK_STATUS_HACKED &&
          payload.status !== NETWORK_STATUS_HACKED)
      ) {
        uwpSendTelemetry(EVENTID_PPS_WIFI_DISCONNECT_COMPLETE, {
          hit_trigger: ANALYTICS_SCAN_MANUAL, // TBD
          hit_screen: screenNameFromRouterPath(nextPath),
          hit_engagement_interactive: ANALYTICS_SUCCESS_CODE,
          hit_label_1: ANALYTICS_NA, // TBD
          hit_guid,
        });
      }

      break;
    }
    case networkDisconnectStart.type:
      uwpSendTelemetry(EVENTID_PPS_WIFI_DISCONNECT_START, {
        hit_trigger: ANALYTICS_SCAN_MANUAL, // TBD
        hit_screen: screenNameFromRouterPath(nextPath),
        hit_engagement_interactive: ANALYTICS_SUCCESS_CODE,
        hit_label_1: ANALYTICS_NA, // TBD
        hit_guid,
      });
      break;
    case networkDisconnectSkip.type:
      uwpSendTelemetry(EVENTID_PPS_WIFI_DISCONNECT_SKIP, {
        hit_trigger: ANALYTICS_SCAN_MANUAL, // TBD
        hit_screen: screenNameFromRouterPath(nextPath),
        hit_engagement_interactive: ANALYTICS_SUCCESS_CODE,
        hit_label_1: ANALYTICS_NA, // TBD
        hit_guid,
      });
      break;
    case dashboardDismissCard.type:
      if (
        payload === NETWORK_STATUS_ATTACKED ||
        payload === NETWORK_STATUS_HACKED
      ) {
        uwpSendTelemetry(EVENTID_PPS_WIFI_DISCONNECT_REMIND_ME_LATER, {
          hit_trigger: ANALYTICS_SCAN_MANUAL, // TBD
          hit_screen: screenNameFromRouterPath(nextPath),
          hit_engagement_interactive: ANALYTICS_SUCCESS_CODE,
          hit_label_1: ANALYTICS_NA, // TBD
          hit_label_2: moment(payload).format("yyyy-MM-DD"),
          hit_guid,
        });
      }
      break;

    // settings
    case loadSettingsSuccess.type:
      for (const [settingId, settingValue] of Object.entries(payload)) {
        // since the result field (hit_label_0) only allows letters and - and _
        // therefore we need to format the string to be more readable
        let sectionFlag = settingId.split("/")[0];

        switch (sectionFlag) {
          case "device":
            let profileStates = {
              auto: "connection_settings_weak_wifi",
              always_on: "connection_settings_always",
              manual: "connection_settings_manual",
            };

            collectScreenLoadEvent({
              [HIT_FEATURE]: getFeature(screenNameFromRouterPath(nextPath)),
              [HIT_CATEGORY]: ANALYTICS_SETTING,
              [HIT_SCREEN]: SCREEN_SETTINGS_VPN_SETTINGS_CONNECT,
              [SCREEN_TYPE]: ANALYTICS_MENU,
              [SCREEN_DETAILS]: profileStates[settingValue],
              [SCREEN_FLOW]: await getScreenFlow(
                screenNameFromRouterPath(nextPath),
                store
              ),
            });
            break;
          case "account":
            let typeOfNotification = settingId.split(".")[1];
            if (typeOfNotification === "vpn") {
              collectScreenLoadEvent({
                [HIT_FEATURE]: ANALYTICS_VPN,
                [HIT_CATEGORY]: ANALYTICS_SETTING,
                [HIT_SCREEN]: SCREEN_SETTINGS_NOTIFICATIONS_LIST,
                [SCREEN_TYPE]: getScreenType(
                  screenNameFromRouterPath(nextPath)
                ),
                [SCREEN_DETAILS]: settingValue === true ? "vpn_on" : "vpn_off",
                [SCREEN_FLOW]: await getScreenFlow(
                  screenNameFromRouterPath(nextPath),
                  store
                ),
              });
            }
            break;
          default:
        }
      }

      break;
    case saveSettingsSuccess.type: {
      let settingResult = "";

      if (Object.keys(payload).length > 1) {
        /// this if is to capture turning on all notifications from the NotificationsModal
        collectScreenLoadEvent({
          [HIT_FEATURE]: getFeature(screenNameFromRouterPath(nextPath)),
          [HIT_CATEGORY]: ANALYTICS_SETTING,
          [HIT_SCREEN]: SCREEN_SETTINGS_NOTIFICATIONS_ENABLE,
          [SCREEN_TYPE]: getScreenType(
            screenNameFromRouterPath(nextPath),
            store
          ),
          [SCREEN_FLOW]: await getScreenFlow(
            screenNameFromRouterPath(nextPath),
            store
          ),
        });
      } else {
        for (const [settingId, settingValue] of Object.entries(payload)) {
          // since the result field (hit_label_0) only allows letters and - and _
          // therefore we need to format the string to be more readable
          settingResult = `${settingId.replace(
            /[/.]/g,
            "-"
          )} updated to ${settingValue}`;
          let sectionFlag = settingId.split("/")[0];

          switch (sectionFlag) {
            case "device":
              let profileStates = {
                auto: "Automatic",
                always_on: "Always",
                manual: "Manual",
              };
              collectScreenLoadEvent({
                [HIT_FEATURE]: getFeature(screenNameFromRouterPath(nextPath)),
                [HIT_CATEGORY]: ANALYTICS_SETTING,
                [HIT_SCREEN]: SCREEN_SETTINGS_VPN_SETTINGS_CONNECT,
                [SCREEN_TYPE]: getScreenType(
                  screenNameFromRouterPath(nextPath),
                  store
                ),
                [SCREEN_DETAILS]: profileStates[settingValue],
                [SCREEN_FLOW]: await getScreenFlow(
                  screenNameFromRouterPath(nextPath),
                  store
                ),
              });
              break;
            case "account":
              let typeOfNotification = settingId.split(".")[1];
              if (typeOfNotification === "vpn") {
                collectScreenLoadEvent({
                  [HIT_FEATURE]: ANALYTICS_VPN,
                  [HIT_CATEGORY]: ANALYTICS_SETTING,
                  [HIT_SCREEN]: SCREEN_SETTINGS_NOTIFICATIONS_VPN,
                  [SCREEN_TYPE]: getScreenType(
                    screenNameFromRouterPath(nextPath)
                  ),
                  [SCREEN_DETAILS]: settingValue === true ? "On" : "Off",
                  [SCREEN_FLOW]: await getScreenFlow(
                    screenNameFromRouterPath(nextPath),
                    store
                  ),
                });
              }
              break;
            default:
          }
        }
      }

      uwpSendTelemetry(EVENTID_PPS_SETTING, {
        hit_trigger: getTrigger(screenNameFromRouterPath(prevPath)),
        hit_label_0: settingResult,
        hit_label_2: ANALYTICS_MANUAL_REQUEST,
        hit_screen: screenNameFromRouterPath(nextPath),
        hit_guid,
      });
      break;
    }

    case saveSettingsFailure.type: {
      let settingResult = "";

      if (Object.keys(payload).length > 1) {
        /// this if is to capture turning on all notifications from the NotificationsModal
        collectScreenLoadEvent({
          [HIT_FEATURE]: getFeature(screenNameFromRouterPath(nextPath)),
          [HIT_CATEGORY]: ANALYTICS_SETTING,
          [HIT_SCREEN]: SCREEN_SETTINGS_NOTIFICATIONS_ENABLE,
          [SCREEN_TYPE]: getScreenType(screenNameFromRouterPath(nextPath)),
          [SCREEN_LOAD_RESULT]: ANALYTICS_FAILURE,
          [SCREEN_FLOW]: await getScreenFlow(
            screenNameFromRouterPath(nextPath),
            store
          ),
        });
      } else {
        for (const [key, value] of Object.entries(payload)) {
          // since the result field (hit_label_0) only allows letters and - and _
          // therefore we need to format the string to be more readable
          settingResult = `${key.replace(
            /[/.]/g,
            "-"
          )} failed to update to ${value}`;
          let sectionFlag = key.split("/")[0];

          switch (sectionFlag) {
            case "device":
              let profileStates = {
                auto: "Automatic",
                always_on: "Always",
                manual: "Manual",
              };
              collectScreenLoadEvent({
                [HIT_FEATURE]: getFeature(screenNameFromRouterPath(nextPath)),
                [HIT_CATEGORY]: ANALYTICS_SETTING,
                [HIT_SCREEN]: SCREEN_SETTINGS_VPN_SETTINGS_CONNECT,
                [SCREEN_TYPE]: getScreenType(
                  screenNameFromRouterPath(nextPath)
                ),
                [SCREEN_LOAD_RESULT]: ANALYTICS_FAILURE,
                [SCREEN_DETAILS]: profileStates[value],
                [SCREEN_FLOW]: await getScreenFlow(
                  screenNameFromRouterPath(nextPath),
                  store
                ),
              });
              break;
            case "account":
              let typeOfNotification = key.split(".")[1];
              if (typeOfNotification === "vpn") {
                collectScreenLoadEvent({
                  [HIT_FEATURE]: ANALYTICS_VPN,
                  [HIT_CATEGORY]: ANALYTICS_SETTING,
                  [HIT_SCREEN]: SCREEN_SETTINGS_NOTIFICATIONS_VPN,
                  [SCREEN_TYPE]: getScreenType(
                    screenNameFromRouterPath(nextPath)
                  ),
                  [SCREEN_LOAD_RESULT]: ANALYTICS_FAILURE,
                  [SCREEN_DETAILS]: value === true ? "On" : "Off",
                  [SCREEN_FLOW]: await getScreenFlow(
                    screenNameFromRouterPath(nextPath),
                    store
                  ),
                });
              }
              break;
            default:
          }
        }
      }

      uwpSendTelemetry(EVENTID_PPS_SETTING, {
        hit_trigger: getTrigger(screenNameFromRouterPath(prevPath)),
        hit_label_0: settingResult,
        hit_label_2: ANALYTICS_MANUAL_REQUEST,
        hit_screen: screenNameFromRouterPath(nextPath),
        hit_guid,
      });
      break;
    }

    // dashboard
    case emailKeyCardBegin.type:
      collectScreenLoadEvent({
        [HIT_CATEGORY]: ANALYTICS_ENGAGEMENT,
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_SCREEN]: SCREEN_DEVICES_PROTECT_NEW_DEVICE,
        [SCREEN_TYPE]: ANALYTICS_PROGRESS,
        [SCREEN_DETAILS]: "loading",
        [SCREEN_FLOW]: await getScreenFlow(
          screenNameFromRouterPath(nextPath),
          store
        ),
      });
      break;
    case emailKeyCardSuccess.type:
      uwpSendTelemetry(EVENTID_PPS_ADD_DEVICE_INITIATED, {
        hit_trigger: ANALYTICS_APP_PUSH,
        // hit_label_0: payload,
        hit_label_0: ANALYTICS_SUCCESS,
        hit_label_1: payload,
        hit_label_2: ANALYTICS_EMAIL,
        hit_screen: screenNameFromRouterPath(nextPath),
        hit_guid,
      });
      collectScreenLoadEvent({
        [HIT_CATEGORY]: ANALYTICS_ENGAGEMENT,
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_SCREEN]: SCREEN_DEVICES_PROTECT_NEW_DEVICE,
        [SCREEN_TYPE]: ANALYTICS_PROGRESS,
        [SCREEN_DETAILS]: "confirmation",
        [SCREEN_FLOW]: await getScreenFlow(
          screenNameFromRouterPath(nextPath),
          store
        ),
      });
      break;
    case emailKeyCardFailure.type:
      uwpSendTelemetry(EVENTID_PPS_ADD_DEVICE_INITIATED, {
        hit_trigger: ANALYTICS_APP_PUSH,
        hit_label_0: ANALYTICS_FAILURE,
        hit_label_1: payload.message,
        hit_label_2: ANALYTICS_EMAIL,
        hit_screen: screenNameFromRouterPath(nextPath),
        hit_guid,
      });
      collectScreenLoadEvent({
        [HIT_CATEGORY]: ANALYTICS_ENGAGEMENT,
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_SCREEN]: SCREEN_DEVICES_PROTECT_NEW_DEVICE,
        [SCREEN_TYPE]: ANALYTICS_ERROR,
        [SCREEN_DETAILS]: ANALYTICS_ERROR,
        [SCREEN_FLOW]: await getScreenFlow(
          screenNameFromRouterPath(nextPath),
          store
        ),
      });
      break;

    // DWS
    case identityBreachCountBegin.type:
      collectScreenLoadEvent({
        [HIT_FEATURE]: getFeature(ROUTE_SCAN_EMAIL),
        [HIT_TRIGGER]: getTrigger(SCREEN_DWS_SCANNING),
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_SCREEN]: SCREEN_DWS_SCANNING,
        [SCREEN_TYPE]: getScreenType(SCREEN_DWS_SCANNING, store),
        [SCREEN_DETAILS]: await getScreenDetails(SCREEN_DWS_SCANNING, store),
        [SCREEN_PREVIOUS_SCREEN]: screenNameFromRouterPath(prevPath),
        [SCREEN_FLOW]: await getScreenFlow(
          screenNameFromRouterPath(nextPath),
          store
        ),
      });
      break;
    case identityBreachCountSuccess.type:
      collectScreenLoadEvent({
        [HIT_FEATURE]: getFeature(ROUTE_SCAN_EMAIL_RESULT),
        [HIT_TRIGGER]: getTrigger(SCREEN_DWS_SCAN_DONE),
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_SCREEN]: SCREEN_DWS_SCAN_DONE,
        [SCREEN_TYPE]: getScreenType(SCREEN_DWS_SCAN_DONE, store),
        [SCREEN_DETAILS]: await getScreenDetails(SCREEN_DWS_SCAN_DONE, store),
        [SCREEN_PREVIOUS_SCREEN]: screenNameFromRouterPath(prevPath),
        [SCREEN_FLOW]: await getScreenFlow(
          screenNameFromRouterPath(nextPath),
          store
        ),
      });
      break;
    case skipEmailEnrollment.type:
      uwpSendTelemetry(EVENTID_PPS_IDS_ENROLLMENT_SKIP, {
        hit_trigger: ANALYTICS_ONBOARDING, // what triggered this
        hit_label_0: ANALYTICS_SUCCESS,
        hit_screen: screenNameFromRouterPath(nextPath),
        hit_guid,
      });
      break;

    case doneEmailEnrollment.type:
      uwpSendTelemetry(EVENTID_PPS_IDS_ENROLLMENT_COMPLETE, {
        hit_trigger: ANALYTICS_ONBOARDING, // what triggered this
        hit_label_0: ANALYTICS_SUCCESS,
        hit_screen: screenNameFromRouterPath(nextPath),
        hit_guid,
      });
      break;
    case fetchDashboardSuccess.type:
      uwpSendTelemetry(EVENTID_PPS_ID_BREACH_SUMMARY, {
        hit_label_0: Array.isArray(payload.breach_history)
          ? String(payload.breach_history.length)
          : "0",
        hit_label_1: Array.isArray(payload.breach_history) //Unresolved count
          ? String(
              payload.breach_history.reduce(
                (total, breach) =>
                  breach.status !== BREACH_STATUS_RESOLVED ? total + 1 : total,
                0
              )
            )
          : "0",
        hit_type: HIT_TYPE_SCREEN,
        hit_trigger: ANALYTICS_IN_APP,
        hit_screen: "Identity-Breach_summary",
        hit_guid,
      });
      break;
    case sendOtpSuccess.type:
      uwpSendTelemetry(EVENTID_PPS_OTP_GENERATED, {
        hit_trigger: payload.trigger, // what triggered this
        hit_label_0: ANALYTICS_SUCCESS,
        hit_label_1: ANALYTICS_SUCCESS,
        hit_label_2: locale,
        hit_hash_1: payload.email,
        // hit_hash_2: payload.hashedPhone,
        hit_guid,
      });

      break;
    case sendOtpFailure.type:
      uwpSendTelemetry(EVENTID_PPS_OTP_GENERATED, {
        hit_trigger: payload.trigger, // what triggered this
        hit_label_0: ANALYTICS_FAILURE,
        hit_label_1: ANALYTICS_FAILURE,
        hit_label_2: locale,
        hit_hash_1: payload.email,
        // hit_hash_2: payload.hashedPhone, // not for v0.1?
        hit_guid,
      });

      break;
    case verifyOtpSuccess.type:
      uwpSendTelemetry(EVENTID_PPS_OTP_VALIDATED, {
        hit_trigger: payload.trigger, // what triggered this
        hit_screen: screenNameFromRouterPath(nextPath),
        hit_label_0: ANALYTICS_SUCCESS,
        hit_label_1: ANALYTICS_SUCCESS,
        hit_label_3: String(payload.otp_code),
        // hit_label_4: "v1",
        hit_hash_1: payload.email,
        // hit_hash_2: payload.hashedPhone, // not for v0.1?
        hit_guid,
      });
      break;
    case verifyOtpFailure.type:
      uwpSendTelemetry(EVENTID_PPS_OTP_VALIDATED, {
        hit_trigger: payload.trigger, // what triggered this
        hit_screen: screenNameFromRouterPath(nextPath),
        hit_label_0: ANALYTICS_FAILURE,
        hit_label_1: ANALYTICS_FAILURE,
        hit_label_3: String(payload.otp_code),
        // hit_label_4: "v1",
        hit_hash_1: payload.email,
        // hit_hash_2: payload.hashedPhone, // not for v0.1?
        hit_guid,
      });
      break;
    case fetchBreachDetailsSuccess.type:
      if (!payload.cached) {
        collectScreenLoadEvent({
          [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
          [HIT_CATEGORY]: ANALYTICS_PROTECTION,
          [HIT_SCREEN]: payload.screen_details
            ? SCREEN_BREACH_DETAIL_CHANGE_PWD_VERIFIED
            : nextState.identity.breachDetails &&
              nextState.identity.breachDetails.status === BREACH_STATUS_RESOLVED
            ? SCREEN_BREACH_DETAIL_RESOLVED
            : SCREEN_BREACH_DETAIL,
          [SCREEN_TYPE]: ANALYTICS_DETAILS,
          [SCREEN_DETAILS]: await getScreenDetailsFromBreachedInfo({
            otpVerified: !!payload.screen_details,
          }),
          [SCREEN_PREVIOUS_SCREEN]: screenNameFromRouterPath(prevPath),
          [SCREEN_FLOW]: "identity",
        });
      }

      break;
    case openPopup.type:
      startPopupAnalytics(payload, store);
      break;
    case closePopup.type:
      endPopupAnalytics(payload, store);
      break;
    case addToast.type:
      startToastAnalytics(payload);
      break;
    case openOverlay.type:
      startOverlayAnalytics(payload);
      break;
    case addAssetSuccess.type:
      uwpSendTelemetry(EVENTID_PPS_EMAIL_ADDITION_COMPLETE, {
        [HIT_TRIGGER]: "enrollment",
        [HIT_SCREEN]: screenNameFromRouterPath(nextPath),
        [SCREEN_LOAD_RESULT]: ANALYTICS_SUCCESS,
        [HIT_HASH_1]: payload,
        hit_session_id: hit_guid,
        [HIT_LABEL_1]: ANALYTICS_SUCCESS,
        [HIT_LABEL_2]: PPS_EMAIL_ADDITION_MESSAGE,
        hit_guid,
      });
      break;
    case addAssetFailure.type:
      uwpSendTelemetry(EVENTID_PPS_EMAIL_ADDITION_COMPLETE, {
        [HIT_TRIGGER]: "enrollment",
        [HIT_SCREEN]: screenNameFromRouterPath(nextPath),
        [SCREEN_LOAD_RESULT]: ANALYTICS_FAILURE,
        [HIT_LABEL_2]: PPS_EMAIL_ADDITION_FAILURE_MESSAGE,
        [HIT_LABEL_1]: payload.message,
        [HIT_HASH_1]: payload.email,
        hit_session_id: hit_guid,
        hit_guid,
      });
      break;
    case addAssetCancel.type:
      uwpSendTelemetry(EVENTID_PPS_EMAIL_ADDITION_CANCEL, {
        [HIT_TRIGGER]: "enrollment",
        [HIT_SCREEN]: SCREEN_BREACHES_FOUND_RESULTS,
        [SCREEN_LOAD_RESULT]: ANALYTICS_SUCCESS,
        [HIT_HASH_1]: payload.email,
        [HIT_LABEL_1]: ANALYTICS_MANUAL,
        [HIT_LABEL_2]: ANALYTICS_MANUAL,
        hit_session_id: hit_guid,
        hit_guid,
      });
      break;
    case removeAssetSuccess.type:
      uwpSendTelemetry(EVENTID_PPS_EMAIL_REMOVE_COMPLETE, {
        [HIT_TRIGGER]: "enrollment",
        [HIT_SCREEN]: "settings_identity_protection",
        [HIT_LABEL_0]: ANALYTICS_SUCCESS,
        [HIT_HASH_1]: payload.asset_value,
        hit_session_id: hit_guid,
        hit_guid,
      });
      break;
    case removeAssetFailure.type:
      uwpSendTelemetry(EVENTID_PPS_EMAIL_REMOVE_COMPLETE, {
        [HIT_TRIGGER]: "enrollment",
        [HIT_SCREEN]: "settings_identity_protection",
        [HIT_LABEL_0]: ANALYTICS_FAILURE,
        [HIT_LABEL_2]: payload.message,
        [HIT_HASH_1]: payload.asset_value,
        hit_session_id: hit_guid,
        hit_guid,
      });
      break;
    case updateMobileNumberFailure.type:
      uwpSendTelemetry(EVENTID_PPS_PHONE_ADDITION_COMPLETE, {
        [HIT_TRIGGER]: ANALYTICS_SETTINGS,
        [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_DEFAULT,
        [SCREEN_LOAD_RESULT]: ANALYTICS_FAILURE,
        [HIT_HASH_1]: payload.phone,
        hit_guid,
      });
      uwpSendTelemetry(EVENTID_PPS_PHONE_VALIDATION_COMPLETE, {
        [HIT_TRIGGER]: "monitor_new_phone",
        [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_DEFAULT,
        [SCREEN_LOAD_RESULT]: ANALYTICS_FAILURE,
        [RESULT]: payload.message,
        [HIT_HASH_1]: payload.phone,
        [HIT_LABEL_2]: PPS_PHONE_VALIDATION_COMPLETE_FAILURE_MESSAGE,
        hit_guid,
      });
      break;
    case deleteMobileNumberBegin.type:
      break;
    case deleteMobileNumberSuccess.type:
      uwpSendTelemetry(EVENTID_PPS_PHONE_REMOVE_COMPLETE, {
        [HIT_TRIGGER]: ANALYTICS_SETTINGS,
        [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_CONFIRMATION,
        [SCREEN_LOAD_RESULT]: ANALYTICS_SUCCESS,
        [RESULT]: payload,
        [HIT_HASH_1]: prevState.dashboard.mobileNumber,
        hit_guid,
      });
      collectScreenLoadEvent({
        [HIT_CATEGORY]: ANALYTICS_SETTING,
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_TRIGGER]: ANALYTICS_MANUAL,
        [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_CONFIRMATION,
        [SCREEN_TYPE]: ANALYTICS_PROGRESS,
        [SCREEN_DETAILS]: "sms_removed",
      });
      break;
    case deleteMobileNumberFailure.type:
      uwpSendTelemetry(EVENTID_PPS_PHONE_REMOVE_COMPLETE, {
        [HIT_TRIGGER]: ANALYTICS_SETTINGS,
        [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_CONFIRMATION,
        [SCREEN_LOAD_RESULT]: ANALYTICS_FAILURE,
        [RESULT]: payload,
        [HIT_HASH_1]: store.getState().dashboard.mobileNumber,
        hit_guid,
      });
      collectScreenLoadEvent({
        [HIT_CATEGORY]: ANALYTICS_SETTING,
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_TRIGGER]: ANALYTICS_MANUAL,
        [SCREEN_LOAD_RESULT]: ANALYTICS_FAILURE,
        [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_CONFIRMATION,
        [SCREEN_TYPE]: ANALYTICS_PROGRESS,
        [SCREEN_DETAILS]: "sms_removed",
        [SCREEN_FLOW]: await getScreenFlow(
          screenNameFromRouterPath(nextPath),
          store
        ),
      });
      break;
    case fetchPDBegin.type:
      const hitTrigger =
        prevState.router.location.pathname === ROUTE_SETTINGS_ACCOUNT
          ? ANALYTICS_SETTING
          : ANALYTICS_HOME;

      if (payload && payload.fetchingOrigin === SYNC) {
        uwpSendTelemetry(EVENTID_PPS_USER_SUBSCRIPTION_SYNC, {
          [HIT_TRIGGER]: hitTrigger,
          [HIT_SCREEN]: getScreenNameForSubscriptionSyncEvent(
            prevState.router.location.pathname
          ),
          [HIT_LABEL_3]: ANALYTICS_SUCCESS,
          [HIT_LABEL_1]: prevState.subscription.subscriptionLength,
          [HIT_LABEL_2]: getSubscriptionRemainingDays(prevState),
          hit_guid: newGUID(),
        });
      }
      break;

    default:
      break;
  }
  return result;
};

async function startPopupAnalytics({ popup, props }, store) {
  const hit_guid = uuidv4().toUpperCase();

  switch (popup) {
    case POPUP_SURVEY:
      const hit_action = "pps_survey_prompted";
      const hit_screen = currentScreenName();
      uwpSendTelemetry(hit_action, {
        hit_action,
        hit_feature: "life_cycle",
        hit_category_0: "engagement",
        hit_trigger: hit_screen,
        hit_screen,
        hit_guid,
      });
      break;
    case POPUP_OTP:
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
        [HIT_TRIGGER]: props.trigger,
        [HIT_CATEGORY]: ANALYTICS_AUTHENTICATION,
        [HIT_SCREEN]: props.screen,
        [SCREEN_TYPE]: ANALYTICS_FORM,
        [SCREEN_DETAILS]: props.details,
      });

      if (!props.newPhoneNumber) {
        uwpSendTelemetry(EVENTID_PPS_EMAIL_VALIDATION_START, {
          hit_label_0: "success",
          hit_hash_1: props.newEmail,
          hit_screen: "Identity-OTP_Verification_email",
          hit_trigger: props.emailTrigger || props.trigger || ANALYTICS_MANUAL,
          hit_guid,
        });
      } else {
        uwpSendTelemetry(EVENTID_PPS_PHONE_VALIDATION_START, {
          [HIT_TRIGGER]: "monitor_new_phone",
          [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_DEFAULT,
          [SCREEN_LOAD_RESULT]: ANALYTICS_SUCCESS,
          [HIT_HASH_1]: props.newPhoneNumber,
          [HIT_LABEL_1]: ANALYTICS_SUCCESS,
          [HIT_LABEL_2]: ANALYTICS_SUCCESS,
          hit_guid,
        });
      }

      break;
    case POPUP_ADD_EMAIL_DEVICE:
      collectScreenLoadEvent({
        [HIT_CATEGORY]: ANALYTICS_ENGAGEMENT,
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_TRIGGER]: props.trigger || ANALYTICS_MANUAL,
        [HIT_SCREEN]: SCREEN_DEVICES_PROTECT_NEW_DEVICE,
        [SCREEN_TYPE]: ANALYTICS_FORM,
        [SCREEN_DETAILS]: "new_device_default",
      });
      break;
    case POPUP_FIVE_REASONS:
      collectScreenLoadEvent({
        [HIT_CATEGORY]: ANALYTICS_ENGAGEMENT,
        [HIT_FEATURE]: ANALYTICS_VPN,
        [HIT_SCREEN]: SCREEN_VPN_BENEFITS,
        [SCREEN_TYPE]: ANALYTICS_EDUCATION,
      });
      break;
    case POPUP_ONBOARD_KNOWMORE:
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_CATEGORY]: ANALYTICS_ONBOARDING,
        [HIT_SCREEN]: SCREEN_ONBOARDING_QUICK_TOUR,
        [SCREEN_TYPE]: ANALYTICS_EDUCATION,
        [SCREEN_FLOW]: "onboarding",
      });
      break;
    case POPUP_ADD_EMAIL_IDENTITY:
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
        [HIT_TRIGGER]: props.trigger || ANALYTICS_MANUAL,
        [HIT_CATEGORY]: ANALYTICS_ENGAGEMENT,
        [HIT_SCREEN]: SCREEN_ADD_EMAIL,
        [SCREEN_TYPE]: ANALYTICS_FORM,
        [SCREEN_FLOW]: "identity",
      });
      break;
    case POPUP_NEW_ASSET_CANCEL:
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
        [HIT_TRIGGER]: props.trigger || ANALYTICS_ID_ONBOARDING,
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_SCREEN]: SCREEN_DWS_SCAN_EXIT_VERIFICATION,
        [SCREEN_TYPE]: ANALYTICS_PROGRESS,
        [SCREEN_FLOW]: "identity",
      });
      break;
    case POPUP_NOTIFICATIONS:
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_TRIGGER]: props.trigger || ANALYTICS_MANUAL,
        [HIT_CATEGORY]: ANALYTICS_SETTING,
        [HIT_SCREEN]: SCREEN_SETTINGS_NOTIFICATIONS_ENABLE,
        [SCREEN_TYPE]: ANALYTICS_MENU,
        [SCREEN_DETAILS]: "permission_denied_notifications",
      });
      break;
    case POPUP_ADD_PHONE_NUMBER:
      collectScreenLoadEvent({
        [HIT_CATEGORY]: ANALYTICS_SETTING,
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_ADD_PHONE,
        [SCREEN_TYPE]: ANALYTICS_FORM,
        [SCREEN_DETAILS]: "add_phone",
      });
      break;
    case POPUP_REMOVE_PHONE_NUMBER:
      collectScreenLoadEvent({
        [HIT_CATEGORY]: ANALYTICS_SETTING,
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_CONFIRM,
        [SCREEN_TYPE]: ANALYTICS_FORM,
        [SCREEN_DETAILS]: "remove_phone",
      });
      uwpSendTelemetry(EVENTID_PPS_PHONE_REMOVE_START, {
        [HIT_TRIGGER]: ANALYTICS_SETTINGS,
        [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_CONFIRMATION,
        [SCREEN_LOAD_RESULT]: ANALYTICS_SUCCESS,
        [HIT_HASH_1]: store.getState().dashboard.mobileNumber,
        hit_guid,
      });
      break;
    case POPUP_REMOVE_EMAIL:
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
        [HIT_CATEGORY]: ANALYTICS_SETTING,
        [HIT_SCREEN]: SCREEN_SETTINGS_DELETE_EMAIL_CONFIRM,
        [SCREEN_TYPE]: null,
        [SCREEN_FLOW]: "identity",
      });
      uwpSendTelemetry(EVENTID_PPS_EMAIL_REMOVE_START, {
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_CATEGORY]: ANALYTICS_ONBOARDING,
        [HIT_SCREEN]: "settings_identity_protection",
        [HIT_TRIGGER]: "enrollment",
        [HIT_HASH_1]: props.email,
        [HIT_LABEL_0]: ANALYTICS_SUCCESS,
        hit_guid,
      });
      break;
    case POPUP_PASSWORD_TIPS:
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
        [HIT_CATEGORY]: ANALYTICS_ENGAGEMENT,
        [HIT_SCREEN]: SCREEN_PWD_TIPS,
        [SCREEN_TYPE]: ANALYTICS_EDUCATION,
        [SCREEN_FLOW]: "identity",
      });
      break;
    case POPUP_WHAT_CAN_YOU_DO:
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_SCREEN]: SCREEN_BREACH_DETAIL_CHANGE_PWD_OPTION,
        [SCREEN_TYPE]: ANALYTICS_DETAILS,
        [SCREEN_FLOW]: "identity",
        [SCREEN_DETAILS]: props.screenDetails,
      });
      break;
    case POPUP_ENCRYPTED_PASSWORD:
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_SCREEN]: SCREEN_BREACH_DETAIL,
        [SCREEN_TYPE]: ANALYTICS_EDUCATION,
        [SCREEN_FLOW]: "identity",
        [SCREEN_DETAILS]: "hashed_pwd_known_encrypted_password",
      });
      break;
    case POPUP_CONFIRM_BEFORE_EXTERNAL_LINK:
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
        [HIT_CATEGORY]: ANALYTICS_ENGAGEMENT,
        [HIT_SCREEN]: SCREEN_BREACH_DETAIL,
        [SCREEN_TYPE]: ANALYTICS_DETAILS,
        [SCREEN_FLOW]: "identity",
        [SCREEN_DETAILS]: "before_leave", // this info needs to be fixed once defined properly by CXD
      });
      uwpSendTelemetry(EVENTID_PPS_USER_MOVE_OUT_START, {
        [HIT_SCREEN]: SCREEN_BREACH_DETAIL,
        [HIT_TRIGGER]: ANALYTICS_MANUAL,
        [HIT_LABEL_0]: ANALYTICS_SUCCESS,
        [HIT_LABEL_2]: props.thirdParty || "N/A",
        [HIT_ENGAGEMENT]: ANALYTICS_SUCCESS_CODE,
        hit_guid,
      });
      break;
    case POPUP_ADDRESSED_BREACHES:
      const screenDetails = await getScreenDetailsFromBreachedInfo({
        otpVerified: false,
        breachDetails: props.breach,
      });
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_SCREEN]: SCREEN_BREACH_DETAIL_RESOLVED,
        [SCREEN_TYPE]: ANALYTICS_DETAILS,
        [SCREEN_DETAILS]:
          screenDetails || `pii_${props.breach.source_type.toLowerCase()}`, /// TODO: Is a breach with just email, an pii breach?
        [SCREEN_FLOW]: "identity",
      });
      break;
    case POPUP_PRE_EXPIRY:
      uwpSendTelemetry(EVENTID_PPS_CAMPAIGN_VIEW, {
        [HIT_TRIGGER]: ANALYTICS_IN_APP,
        [HIT_LABEL_0]: ANALYTICS_SUCCESS,
        [HIT_LABEL_1]: CAMPAIGN_SUBSCRIPTION_EXPIRY_MESSAGE,
        [HIT_LABEL_2]: CAMPAIGN_SUBSCRIPTION_EXPIRY_MESSAGE_CONTENT,
        hit_guid,
      });
      break;
    case POPUP_SECURE_VPN:
      collectScreenLoadEvent({
        [HIT_FEATURE]: FEATURE_VPN,
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_TRIGGER]: ANALYTICS_MANUAL,
        [HIT_SCREEN]: SCREEN_VPN_FAB_FIRST_TIME_INFO,
        [SCREEN_TYPE]: ANALYTICS_EDUCATION,
        [SCREEN_DETAILS]: "vpn_fab_first_time_info",
        [SCREEN_FLOW]: "FAB",
      });
      break;
    default:
      break;
  }
}

function endPopupAnalytics({ popup, results, props }, store) {
  const hit_guid = uuidv4().toUpperCase();
  switch (popup) {
    case POPUP_SURVEY:
      const hit_screen = currentScreenName();
      const hit_feature = "life_cycle";
      const hit_category_0 = "engagement";
      if (results) {
        const { rating, comments, duration } = results;
        const hit_action = "pps_survey_complete";
        uwpSendTelemetry(hit_action, {
          hit_action,
          hit_feature,
          hit_category_0,
          hit_screen,
          hit_trigger: hit_screen,
          hit_label_6: duration,
          hit_label_7: rating,
          hit_label_8: comments,
          hit_guid,
        });
      } else {
        const hit_action = "pps_survey_skip";
        uwpSendTelemetry(hit_action, {
          hit_action,
          hit_screen,
          hit_trigger: hit_screen,
          hit_feature,
          hit_category_0,
          hit_guid,
        });
      }
      break;
    case POPUP_OTP:
      if (!props.newPhoneNumber) {
        uwpSendTelemetry(
          results
            ? EVENTID_PPS_EMAIL_VALIDATION_COMPLETE
            : EVENTID_PPS_EMAIL_VALIDATION_CANCEL,
          {
            hit_label_0: "success",
            hit_hash_1: props.newEmail,
            hit_screen: "Identity-OTP_Verification_email",
            hit_trigger: props.trigger,
            hit_guid,
          }
        );
      } else {
        uwpSendTelemetry(
          results
            ? EVENTID_PPS_PHONE_VALIDATION_COMPLETE
            : EVENTID_PPS_PHONE_VALIDATION_CANCEL,
          {
            [HIT_TRIGGER]: "monitor_new_phone",
            [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_DEFAULT,
            [SCREEN_LOAD_RESULT]: ANALYTICS_SUCCESS,
            [HIT_HASH_1]: props.newPhoneNumber,
            [HIT_LABEL_1]: results? PPS_PHONE_VALIDATION_COMPLETE_MESSAGE : ANALYTICS_MANUAL,
            [HIT_LABEL_2]: results? PPS_PHONE_VALIDATION_COMPLETE_MESSAGE : ANALYTICS_MANUAL,
            hit_guid,
          }
        );

        uwpSendTelemetry(
          results
            ? EVENTID_PPS_PHONE_ADDITION_COMPLETE
            : EVENTID_PPS_PHONE_ADDITION_CANCEL,
          {
            [HIT_TRIGGER]: ANALYTICS_SETTINGS,
            [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_DEFAULT,
            [SCREEN_LOAD_RESULT]: ANALYTICS_SUCCESS,
            [HIT_HASH_1]: props.newPhoneNumber,
            hit_guid,
          }
        );
      }
      break;
    case POPUP_ADD_EMAIL_IDENTITY:
      uwpSendTelemetry(
        results
          ? EVENTID_PPS_EMAIL_ADDITION_START
          : EVENTID_PPS_EMAIL_ADDITION_CANCEL,
        {
          [HIT_TRIGGER]: "enrollment",
          [HIT_SCREEN]: SCREEN_ADD_EMAIL,
          [SCREEN_LOAD_RESULT]: ANALYTICS_SUCCESS,
          [HIT_HASH_1]: results || "N/A", // TODO: remove N/A once updated event (without hit_hash_1 as mandatory) is released
          [HIT_LABEL_1]: results ? ANALYTICS_SUCCESS : ANALYTICS_MANUAL,
          [HIT_LABEL_2]: results ? ANALYTICS_SUCCESS : ANALYTICS_MANUAL,
          hit_session_id: hit_guid,
          hit_guid,
        }
      );
      break;
    case POPUP_REMOVE_EMAIL:
      if (!results) {
        uwpSendTelemetry(EVENTID_PPS_EMAIL_REMOVE_CANCEL, {
          [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
          [HIT_CATEGORY]: ANALYTICS_ONBOARDING,
          [HIT_TRIGGER]: "enrollment",
          [HIT_SCREEN]: "settings_identity_protection", // TODO: review this value once defined by cxd
          [HIT_LABEL_0]: ANALYTICS_SUCCESS,
          [HIT_HASH_1]: props.email,
          hit_session_id: hit_guid,
          hit_guid,
        });
      }
      break;
    case POPUP_REMOVE_PHONE_NUMBER:
      if (!results) {
        uwpSendTelemetry(EVENTID_PPS_PHONE_REMOVE_CANCEL, {
          [HIT_TRIGGER]: ANALYTICS_SETTINGS,
          [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_CONFIRMATION,
          [SCREEN_LOAD_RESULT]: ANALYTICS_SUCCESS,
          [HIT_HASH_1]: store.getState().dashboard.mobileNumber,
          hit_guid,
        });
      }

      break;
    case POPUP_CONFIRM_VPN_DISCLOSURE:
      if (results) {
        uwpSendTelemetry(EVENTID_PPS_VPN_PRIVACY_DISCLOSURE, {
          hit_trigger: props.trigger,
          hit_screen: SCREEN_VPN_SETUP_DATA_DISCLOSURE,
          hit_label_0: ANALYTICS_DENY,
          hit_label_1: ANALYTICS_DENY,
          hit_label_2: ANALYTICS_MANUAL,
          hit_guid,
        });

        uwpSendTelemetry(EVENTID_PPS_VPN_SETUP_CANCEL, {
          hit_trigger: props.trigger,
          hit_screen: SCREEN_VPN_SETUP_DATA_DISCLOSURE,
          hit_label_0: ANALYTICS_SUCCESS,
          hit_label_1: ANALYTICS_MANUAL,
          hit_label_2: ANALYTICS_MANUAL,
          hit_guid,
        });
      }
      break;
    case POPUP_CONFIRM_BEFORE_EXTERNAL_LINK:
      uwpSendTelemetry(
        results
          ? EVENTID_PPS_USER_MOVE_OUT_CONTINUE
          : EVENTID_PPS_USER_MOVE_OUT_CANCEL,
        {
          [HIT_SCREEN]: SCREEN_BREACH_DETAIL,
          [HIT_TRIGGER]: ANALYTICS_MANUAL,
          [HIT_LABEL_0]: ANALYTICS_SUCCESS,
          [HIT_LABEL_2]: props.thirdParty || "N/A",
          hit_guid,
        }
      );
      break;
    case POPUP_PRE_EXPIRY:
      uwpSendTelemetry(
        results ? EVENTID_PPS_CAMPAIGN_CLICKED : EVENTID_PPS_CAMPAIGN_REMOVED,
        {
          [HIT_TRIGGER]: ANALYTICS_IN_APP,
          [HIT_LABEL_0]: ANALYTICS_SUCCESS,
          [HIT_LABEL_1]: CAMPAIGN_SUBSCRIPTION_EXPIRY_MESSAGE,
          [HIT_LABEL_2]: CAMPAIGN_SUBSCRIPTION_EXPIRY_MESSAGE_CONTENT,
          hit_guid,
        }
      );
      break;
    default:
      break;
  }
}

export default data_cupture;

function startToastAnalytics({ toast, props }) {
  switch (toast) {
    case TOAST_SNACKBAR_SUCCESS:
      let feature, category, screen, screenType, screenDetails, screenFlow;
      feature = ANALYTICS_ID_PROTECTION;
      category = ANALYTICS_SETTING;
      screenFlow = "identity";

      switch (props.stringId) {
        case "toasters.addPhone":
          feature = ANALYTICS_LIFE_CYCLE;
          screen = SCREEN_SETTINGS_ACCOUNT_ADD_PHONE_CONFIRMATION;
          screenType = ANALYTICS_PROGRESS;
          break;
        case "toasters.youAddressed":
          screen = SCREEN_BREACH_DETAIL_RESOLVED;
          screenType = ANALYTICS_PROGRESS;
          screenDetails = props.screenDetails;
          break;
        case "toasters.removePhone":
          feature = ANALYTICS_LIFE_CYCLE;
          screen = SCREEN_SETTINGS_ACCOUNT_REMOVE_PHONE_CONFIRMATION;
          screenType = ANALYTICS_PROGRESS;
          screenDetails = "sms_removed";
          break;
        case "toasters.removeEmail":
          screen = SCREEN_SETTINGS_DELETE_EMAIL_SNACKBAR;
          screenType = ANALYTICS_SNACKBAR;
          break;
        case "toasters.addedEmail":
          screen = SCREEN_SETTINGS_ADD_EMAIL_SNACKBAR;
          screenType = ANALYTICS_SNACKBAR;
          break;
        default:
          break;
      }
      collectScreenLoadEvent({
        [HIT_FEATURE]: feature,
        [HIT_CATEGORY]: category,
        [HIT_SCREEN]: screen,
        [SCREEN_TYPE]: screenType,
        [SCREEN_DETAILS]: screenDetails,
        [SCREEN_FLOW]: screenFlow,
      });
      break;
    default:
      break;
  }
}

function startOverlayAnalytics({ overlay, props }) {
  switch (overlay) {
    case OVERLAY_WELL_DONE:
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_SCREEN]: SCREEN_BREACH_DETAIL_CONFIRMATION,
        [SCREEN_TYPE]: ANALYTICS_PROGRESS,
        [SCREEN_DETAILS]: props.screenDetails,
        [SCREEN_FLOW]: "identity",
      });
      break;
    default:
      break;
  }
}

export const checkRedirect = (store, prevPath, nextPath) => {
  const neutralPages = [
    ROUTE_ROOT,
    ROUTE_SETTINGS_ABOUT,
    ROUTE_OPEN_SOURCE_LICENSES,
  ];

  //Check neutral pages
  if (nextPath.match(ROUTE_FAILURE)) {
    return false;
  }
  if (neutralPages.includes(nextPath)) {
    return false;
  }
  const {
    auth: { accessToken },
    subscription: { subscriptionStatus },
  } = store.getState();
  const signedOffSupportedPages = [
    ROUTE_ACCOUNT,
    ROUTE_WELCOME,
    ROUTE_WELCOME1,
    ROUTE_WELCOME2,
    ROUTE_WELCOME3,
  
  ];
  if (accessToken) {
    if (
      isSubscriptionExpired(subscriptionStatus) &&
      nextPath !== ROUTE_EXPIRED_SUBSCRIPTION
    ) {
      delay(1).then(() => {
        store.dispatch(replace(ROUTE_EXPIRED_SUBSCRIPTION));
      });
    }
    if (signedOffSupportedPages.includes(nextPath)) {
      delay(1).then(() => {
        store.dispatch(replace(ROUTE_ROOT));
      });
      return true;
    }
  } else {
    if (!signedOffSupportedPages.includes(nextPath)) {
      delay(1).then(() => {
        store.dispatch(replace(ROUTE_ACCOUNT));
      });
      return true;
    }
  }
  return false;
};

/**
 * Called upon dispatching redux actions fetchPDSuccess, eulaAcceptedSuccess
 * @param {*} prevState previous redux state
 * @param {*} nextState next redux state
 */
export const detectContextChange = (prevState, nextState) => {
  const {
    subscription: {
      subscriptionStatus: previousStateSubscriptionStatus,
      expiryDate: previousStateExpiryDate,
    },
    experience: {
      features: {
        ids: { enabled: previousStateIdsEnabled },
        [FEATURE_VPN]: { enabled: previousStateVpnEnabled },
      },
    },
    context: { packageId: previousStatePackageId },
  } = prevState;

  const {
    subscription: {
      subscriptionStatus: nextStateSubscriptionStatus,
      expiryDate: nextStateExpiryDate,
    },
    experience: {
      features: {
        ids: { enabled: nextStateIdsEnabled },
        [FEATURE_VPN]: { enabled: nextStateVpnEnabled },
      },
    },
    context: { packageId: nextStatePackageId },
  } = nextState;

  if (previousStateSubscriptionStatus !== nextStateSubscriptionStatus) {
    sendContextChangeEvent(
      "product_subscription_status",
      getProductSubscriptionStatus(previousStateSubscriptionStatus),
      getProductSubscriptionStatus(nextStateSubscriptionStatus)
    );
    sendContextChangeEvent(
      "product_subscription_type",
      getSubscriptionType(previousStateSubscriptionStatus),
      getSubscriptionType(nextStateSubscriptionStatus)
    );
  }

  if (previousStateExpiryDate !== nextStateExpiryDate) {
    sendContextChangeEvent(
      "product_subscription_expiry_date",
      previousStateExpiryDate || "N/A",
      nextStateExpiryDate || "N/A"
    );
  }

  if (previousStateIdsEnabled !== nextStateIdsEnabled) {
    sendContextChangeEvent(
      "product_idprotection_status",
      previousStateIdsEnabled || "N/A",
      nextStateIdsEnabled || "N/A"
    );
  }

  if (previousStateVpnEnabled !== nextStateVpnEnabled) {
    sendContextChangeEvent(
      "product_vpn_status",
      previousStateVpnEnabled || "N/A",
      nextStateVpnEnabled || "N/A"
    );
  }

  if (previousStatePackageId !== nextStatePackageId) {
    sendContextChangeEvent(
      "product_sku_id",
      previousStatePackageId || "NA",
      nextStatePackageId || "N/A"
    );
  }
};

export function sendContextChangeEvent(
  contextName,
  oldContextValue,
  newContextValue
) {
  uwpSendTelemetry(EVENTID_CONTEXT_CHANGE, {
    [HIT_TRIGGER]: contextName,
    [HIT_LABEL_0]: newContextValue,
    [HIT_LABEL_1]: oldContextValue,
    hit_guid: newGUID(),
  });
}

export function getScreenNameForSubscriptionSyncEvent(routeName) {
  let screenName;
  switch (routeName) {
    case ROUTE_DASHBOARD:
      screenName = SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_COUNTER_STATE;
      break;
    case ROUTE_SETTINGS_ACCOUNT:
      screenName = SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION;
      break;
    case ROUTE_EXPIRED_SUBSCRIPTION:
      screenName = SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_EXPIRED;
      break;
    default:
      screenName = SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_COUNTER_STATE;
  }
  return screenName;
}
