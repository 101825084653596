//ROUTE_HACK_BLOCK
import React from "react";
import { String } from "../../l10n/loader";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { ROUTE_DISCONNECT_WIFI } from "../app/routes";
import { ONBOARD_HACK_BLOCK_EDUCATE } from "../../constants/main";
import image from "../_assets/images/hackBlock/hackBlock.svg";

import { networkDisconnectSkip } from "../../store/network";
import { navigateBack } from "../../store/thunks/app";

// DOM
import {
  Display,
  Paragraph,
  Button,
  Icon,
  Grid,
  Row,
  Col,
  Heading,
  Tag,
} from "@mcafee/pegasus";

// Custom components
import { useIsSignedIn } from "../../utils";
import Unauthorized from "../Shared/Unauthorized";
import { DialogLayout } from "../layouts/DialogLayout";
import { WifiOffRounded } from "@material-ui/icons";

const DisconnectWifi = ({ push, navigateBack, networkDisconnectSkip }) => {
  const _S = (str) => `hackBlock.darkWebEducate.${str}`;

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  const onProtectMeNowHandler = () => {
    // uwpOpenWindowsSettingPage("ms-settings:network-wifi");
    push(ROUTE_DISCONNECT_WIFI);
  };

  // ToDo: TBD Action to be taken upon Take Risk button click
  const onTakeRiskHandler = (e) => {
    networkDisconnectSkip();
    navigateBack();
  };

  const Buttons = () => (
    <>
      <Button
        className="mr-24 px-40"
        onClick={onProtectMeNowHandler}
        id="DarkWebEducateDisconnectNow"
        size="sm"
      >
        <String id={`hackBlock.disconnectNow`} />
      </Button>
      <Button
        variant="tertiary"
        onClick={onTakeRiskHandler}
        id="DarkWebEducateTakeRisk"
        size="sm"
      >
        <String id={`hackBlock.takeRisk`} />
      </Button>
    </>
  );

  return (
    <DialogLayout
      pageId={ONBOARD_HACK_BLOCK_EDUCATE}
      image={image}
      showBack={false}
      buttons={<Buttons />}
    >
      <Display size="sm">
        <String
          id={_S`header`}
          values={{
            // ToDo: replace the constant with the network name coming from the API
            WiFi: "Wi-Fi",
          }}
        />
      </Display>
      <Grid className="mt-48" style={{ width: "auto", height: "auto" }}>
        <Row>
          <Col xs={1}>
            <Tag icon variant="solid" kind="danger">
              <WifiOffRounded />
            </Tag>
          </Col>
          <Col xs={10} className="pl-16">
            <Heading size="xs">
              <String id={_S`whatCanQues`} />
            </Heading>

            <Paragraph size="sm">
              <String id={_S`whatCanAns`} />
            </Paragraph>
          </Col>
        </Row>
        <Row className="mt-32">
          <Col xs={1}>
            <Tag icon variant="solid" kind="danger">
              <Icon icon="vpn-secure-outline" size="md" />
            </Tag>
          </Col>
          <Col xs={11} className="pl-16">
            <Heading size="xs">
              <String id={_S`canVpnQues`} />
            </Heading>
            <Paragraph size="sm">
              <String id={_S`canVpnAns`} />
            </Paragraph>
          </Col>
        </Row>
      </Grid>
    </DialogLayout>
  );
};

export default connect(
  (state) => ({ historyIndex: state.context.historyIndex }),
  {
    push,
    navigateBack,
    networkDisconnectSkip,
  }
)(DisconnectWifi);
