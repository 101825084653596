import { APP_ID, DEFAULT_AFF_ID } from "./main";
import buildInfo from "../build-info.json";

export const ANALYTICS_SUCCESS = "success";
export const ANALYTICS_FAILURE = "failure";
export const ANALYTICS_CANCEL = "cancel";
export const ANALYTICS_ACCEPT = "accept";
export const ANALYTICS_DENY = "deny";
export const ANALYTICS_NA = "NA";

export const ANALYTICS_SUCCESS_CODE = "1";
export const ANALYTICS_FAILURE_CODE = "0";

export const ANALYTICS_INTERACTIVE = "1";
export const ANALYTICS_NON_INTERACTIVE = "0";

export const ANALYTICS_NEW = "new";

export const ANALYTICS_AUTOSIGNIN = "autosignin";
export const ANALYTICS_SCAN_MANUAL = "scan_manual";
export const ANALYTICS_VPN = "vpn";
export const ANALYTICS_MANUAL_REQUEST = "manual_request";
export const ANALYTICS_APP_PUSH = "app_push";
export const ANALYTICS_ONBOARDING = "onboarding";
export const ANALYTICS_EMAIL = "email";

/// analytics triggers

export const ANALYTICS_LAUNCHER_ICON = "launcher_icon";
export const ANALYTICS_MANUAL = "manual";
export const ANALYTICS_REGISTRATION = "registration";
export const ANALYTICS_ID_ONBOARDING = "id_onboarding";
export const ANALYTICS_DASHBOARD_CARD = "dashboard_card";
export const ANALYTICS_SERVICES = "services";
export const ANALYTICS_NOTIFICATION = "notification";
export const ANALYTICS_MONITOR_NEW_EMAIL = "monitor_new_email";
export const ANALYTICS_HOME = "home";
export const ANALYTICS_IN_APP = "in-app";
export const ANALYTICS_SETTINGS = "settings";

/// analytics categories

export const ANALYTICS_ENGAGEMENT = "engagement";
export const ANALYTICS_PROTECTION = "protection";
export const ANALYTICS_SETTING = "setting";
export const ANALYTICS_AUTHENTICATION = "authentication";

/// analytics features

export const ANALYTICS_LIFE_CYCLE = "life_cycle";
export const ANALYTICS_ID_PROTECTION = "id_protection";
export const FEATURE_MESSAGING = "messaging";

/// analytics screen type

export const ANALYTICS_FORM = "form";
export const ANALYTICS_ERROR = "error";
export const ANALYTICS_PROGRESS = "progress";
export const ANALYTICS_LIST = "list";
export const ANALYTICS_DETAILS = "details";
export const ANALYTICS_MENU = "menu";
export const ANALYTICS_SERVICES_CARD = "services_card";
export const ANALYTICS_EDUCATION = "education";
export const ANALYTICS_DASHBOARD = "dashboard";
export const ANALYTICS_BOTTOM_SHEET = "bottom sheet"
export const ANALYTICS_SNACKBAR = "snackbar"

//// screen load time

export const SCREEN_DEFAULT_LOAD_TIME = "10";

/// categories for hit_label_5

export const HIT_TYPE_SCREEN = "screen";
export const ANALYTICS_CARD = "card";

// Survey response language for hit_label_9
export const SURVEY_RESPONSE_LANGUAGE_EN = "en";

export const DEFATUL_PPS_CONTEXT = {
  product_app_id: APP_ID,
  product_affiliate_id: DEFAULT_AFF_ID, //TODO: use state.context.affid
  product_version: buildInfo.build,
  product_publisher_id: "pps_uwp_client",
};

// This is used for context sent from UI
export const DEFAULT_PPS_UI_CONTEXT = {
  product_component_version: buildInfo.build,
  product_component_name: "Unified UI",
};

export const EVENTID_SCREEN_LOAD = "pps_screen_load";
export const EVENTID_BREACH_LOOKUP = "pps_id_breach_lookup";
export const EVENTID_PPS_ENROLLMENT_SKIP = "pps_enrollment_skip";
export const EVENTID_PPS_ENROLLMENT_COMPLETE = "pps_enrollment_complete";
export const EVENTID_PPS_SIGNIN = "pps_signin";
export const EVENTID_PPS_SIGNOUT = "pps_signout";
export const EVENTID_PPS_SIGNUP = "pps_signup";
export const EVENTID_PPS_ADD_DEVICE_INITIATED = "pps_add_device_initiated";
export const EVENTID_PPS_REMEDIATION_START = "pps_remediation_start";
export const EVENTID_PPS_REMEDIATION_CANCEL = "pps_remediation_cancel";
export const EVENTID_PPS_REMEDIATION_COMPLETE = "pps_remediation_complete";
export const EVENTID_PPS_VPN_PRIVACY_DISCLOSURE = "pps_vpn_privacy_disclosure";
export const EVENTID_PPS_VPN_SETUP_START = "pps_vpn_setup_start";
export const EVENTID_PPS_VPN_SETUP_CANCEL = "pps_vpn_setup_cancel";
export const EVENTID_PPS_VPN_SETUP_COMPLETE = "pps_vpn_setup_complete";
export const EVENTID_PPS_WIFI_DISCONNECT_PROMPTED =
  "pps_wifi_disconnect_prompted";
export const EVENTID_PPS_WIFI_DISCONNECT_START = "pps_wifi_disconnect_start";
export const EVENTID_PPS_WIFI_DISCONNECT_COMPLETE =
  "pps_wifi_disconnect_complete";
export const EVENTID_PPS_WIFI_DISCONNECT_SKIP = "pps_wifi_disconnect_skip";
export const EVENTID_PPS_WIFI_DISCONNECT_REMIND_ME_LATER =
  "pps_wifi_disconnect_remind_me_later";
export const EVENTID_PPS_SETTING = "pps_setting";
export const EVENTID_PPS_IDS_ENROLLMENT_SKIP = "pps_ids_enrollment_skip";
export const EVENTID_PPS_IDS_ENROLLMENT_COMPLETE =
  "pps_ids_enrollment_complete";
export const EVENTID_PPS_EMAIL_VALIDATION_START = "pps_email_validation_start";
export const EVENTID_PPS_EMAIL_VALIDATION_COMPLETE =
  "pps_email_validation_complete";
export const EVENTID_PPS_EMAIL_VALIDATION_CANCEL =
  "pps_email_validation_cancel";
export const EVENTID_PPS_ID_BREACH_SUMMARY = "pps_id_breach_summary";
export const EVENTID_PPS_OTP_GENERATED = "pps_otp_generated";
export const EVENTID_PPS_OTP_VALIDATED = "pps_otp_validated";
export const EVENTID_PPS_EULA_ACCEPTED = "pps_eula_accepted";
export const EVENTID_PPS_EMAIL_ADDITION_START = "pps_email_addition_start";
export const EVENTID_PPS_EMAIL_ADDITION_CANCEL = "pps_email_addition_cancel";
export const EVENTID_PPS_EMAIL_ADDITION_COMPLETE =
  "pps_email_addition_complete";
export const EVENTID_PPS_EMAIL_REMOVE_START = "pps_email_remove_start";
export const EVENTID_PPS_EMAIL_REMOVE_CANCEL = "pps_email_remove_cancel";
export const EVENTID_PPS_EMAIL_REMOVE_COMPLETE = "pps_email_remove_complete";
export const EVENTID_PPS_PHONE_ADDITION_START = "pps_phone_addition_start";
export const EVENTID_PPS_PHONE_ADDITION_CANCEL = "pps_phone_addition_cancel";
export const EVENTID_PPS_PHONE_ADDITION_COMPLETE =
  "pps_phone_addition_complete";
export const EVENTID_PPS_PHONE_REMOVE_START = "pps_phone_remove_start";
export const EVENTID_PPS_PHONE_REMOVE_CANCEL = "pps_phone_remove_cancel";
export const EVENTID_PPS_PHONE_REMOVE_COMPLETE = "pps_phone_remove_complete";
export const EVENTID_PPS_PHONE_VALIDATION_START = "pps_phone_validation_start";
export const EVENTID_PPS_PHONE_VALIDATION_CANCEL =
  "pps_phone_validation_cancel";
export const EVENTID_PPS_PHONE_VALIDATION_COMPLETE =
  "pps_phone_validation_complete";
export const EVENTID_PPS_SURVEY_PROMPTED = "pps_survey_prompted";
export const EVENTID_PPS_SURVEY_COMPLETE = "pps_survey_complete";
export const EVENTID_PPS_SURVEY_SKIP = "pps_survey_skip";
export const EVENTID_PPS_SUPPORT_INITIATED = "pps_support_initiated";
export const EVENTID_PPS_USER_MOVE_OUT_START = "pps_user_move_out_start";
export const EVENTID_PPS_USER_MOVE_OUT_CANCEL = "pps_user_move_out_cancel";
export const EVENTID_PPS_USER_MOVE_OUT_CONTINUE = "pps_user_move_out_continue";

export const EVENTID_CONTEXT_CHANGE = "context_change";
export const EVENTID_PPS_CAMPAIGN_VIEW = "pps_campaign_view";
export const EVENTID_PPS_CAMPAIGN_CLICKED = "pps_campaign_clicked";
export const EVENTID_PPS_CAMPAIGN_REMOVED = "pps_campaign_removed";
export const EVENTID_PPS_USER_SUBSCRIPTION_SYNC = "pps_user_subscription_sync";
export const EVENTID_PPS_USER_SUBSCRIPTION_INITIATED =
  "pps_user_subscription_initiated";
export const EVENTID_PPS_PURCHASE_INITIATED = "pps_purchase_initiated";

export const HIT_CATEGORY = "hit_category_0";
export const HIT_FEATURE = "hit_feature";
export const HIT_TRIGGER = "hit_trigger";
export const HIT_SCREEN = "hit_screen";
export const HIT_SESSION_ID = "hit_session_id";
export const HIT_HASH_1 = "hit_hash_1";
export const HIT_RESULT = "hit_result";

export const HIT_LABEL_0 = "hit_label_0";
export const HIT_LABEL_1 = "hit_label_1";
export const HIT_LABEL_2 = "hit_label_2";
export const HIT_LABEL_3 = "hit_label_3";
export const HIT_LABEL_4 = "hit_label_4";
export const HIT_LABEL_5 = "hit_label_5";
export const HIT_LABEL_6 = "hit_label_6";
export const HIT_LABEL_7 = "hit_label_7";
export const HIT_LABEL_8 = "hit_label_8";
export const HIT_LABEL_9 = "hit_label_9";
export const HIT_LABEL_10 = "hit_label_10";
export const HIT_LABEL_11 = "hit_label_11";
export const HIT_LABEL_12 = "hit_label_12";
export const HIT_LABEL_13 = "hit_label_13";
export const HIT_LABEL_14 = "hit_label_14";
export const HIT_LABEL_15 = "hit_label_15";
export const HIT_LABEL_16 = "hit_label_16";
export const HIT_LABEL_17 = "hit_label_17";
export const HIT_LABEL_18 = "hit_label_18";
export const HIT_LABEL_19 = "hit_label_19";

export const HIT_ENGAGEMENT = "hit_engagement_interactive";

export const SCREEN_LOAD_RESULT = HIT_LABEL_0;
export const SCREEN_SEQUENCE_ID = HIT_LABEL_2;
export const SCREEN_TYPE = HIT_LABEL_3;
export const SCREEN_LOAD_TIME = HIT_LABEL_4;
export const SCREEN_CARD_OR_SCREEN = HIT_LABEL_5;
export const SCREEN_DETAILS = HIT_LABEL_6;
export const SCREEN_PREVIOUS_SCREEN = HIT_LABEL_7;
export const SCREEN_FLOW = HIT_LABEL_8;

export const RESULT = HIT_LABEL_1;
export const PPS_PHONE_VALIDATION_COMPLETE_MESSAGE =
  "You’re all set for text alerts";
export const PPS_PHONE_VALIDATION_COMPLETE_FAILURE_MESSAGE = "Try again";
export const CAMPAIGN_SUBSCRIPTION_EXPIRY_MESSAGE =
  "subscription_expiry_message";

export const PPS_EMAIL_ADDITION_MESSAGE = "Your email address was added";
export const PPS_EMAIL_ADDITION_FAILURE_MESSAGE = "Try again";
export const CAMPAIGN_SUBSCRIPTION_EXPIRY_MESSAGE_CONTENT =
  "Your subscription is expired";
