import React, { useEffect } from "react";
import { replace } from "connected-react-router";
import { connect, useSelector } from "react-redux";
import { publishEvent } from "../store/thunks/experience";
import events from "../experience/events";
import { ROUTE_ACCOUNT } from "./app/routes";
import { LAUNCHUA_SIGN_IN } from "../constants";

const Root = ({
  //...context
  progress: loadContextProgress,
  doneWelcome,
  //...auth
  accessToken,
  //dispatching
  publishEvent,
  replace,
}) => {
  const doneTrialPrompt = useSelector((state) => state.context.doneTrialPrompt);

  useEffect(() => {
    if (!loadContextProgress) {
      if (doneWelcome || accessToken) {
        //home would be different based on domain name
        if (accessToken) {
          publishEvent(events.root.home);
        } else {
          replace({
            pathname: ROUTE_ACCOUNT,
            launchUA: doneTrialPrompt ? LAUNCHUA_SIGN_IN : undefined,
          });
        }
      } else {
        publishEvent(events.root.welcome);
      }
    }
  }, [
    accessToken,
    doneTrialPrompt,
    doneWelcome,
    loadContextProgress,
    publishEvent,
    replace,
  ]);

  return <div />;
};

export default connect(
  (state) => ({ accessToken: state.auth.accessToken, ...state.context }),
  { replace, publishEvent }
)(Root);
