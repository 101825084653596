import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Paragraph, Heading, Button, Label, Subheading } from "@mcafee/pegasus";
import "./avScanTypesPopup.scss";
import timerRoundedIcon from "../_assets/images/shared/timerRounded.svg";

export default function AvScanTypesPopup({
  onRunFullScan,
  onRunQuickScan,
  onCancel,
}) {
  const _S = (str) => `popups.ScanTypesPopup.${str}`;

  return (
    <div className="scanTypesPopup">
      <div className="scanTypesPopup__title">
        <Heading
          className="scanTypesPopup__title__text"
          size="xs"
          data-testid="scanTypesPopupHeader"
        >
          <String id={_S`header`} />
        </Heading>
      </div>

      <Label size="sm">
        <String id={_S`subheader1`} />
      </Label>

      <div data-testid="scanTypesPopupContent">
        <Paragraph size="sm" className="scanTypes__content">
          <String id={_S`content`} />
        </Paragraph>
      </div>
      <div className="scanTypesPopup__info">
        <img src={timerRoundedIcon} alt="feature_avatar" />
        <Subheading size="sm" className="scanTypesPopup__info__text">
          <String id={_S`info`} />
        </Subheading>{" "}
      </div>
      <div className="scanTypesPopup__buttonsBar">
        <Button
          // className="ml-32"
          onClick={() => {
            onRunQuickScan();
            onCancel();
          }}
          size="sm"
          id="runQuickScan"
          data-testid="runQuickScanButton"
          className="scanTypesPopup__buttonsBar__button"
        >
          <String id={_S`runQuickScan`} />
        </Button>
      </div>
      <Label size="sm">
        <String id={_S`subheader2`} />
      </Label>

      <div data-testid="scanTypesPopupContent">
        <Paragraph size="sm" className="scanTypes__content2">
          <String id={_S`content2`} />
        </Paragraph>
      </div>
      <div className="scanTypesPopup__info">
        <img src={timerRoundedIcon} alt="feature_avatar" />
        <Subheading size="sm" className="scanTypesPopup__info__text">
          <String id={_S`info2`} />
        </Subheading>{" "}
      </div>
      <div className="scanTypesPopup__buttonsBar">
        <Button
          // className="ml-32"
          onClick={() => {
            onRunFullScan();
            onCancel();
          }}
          size="sm"
          id="runQuickScan"
          data-testid="runFullScanButton"
          className="scanTypesPopup__buttonsBar__button"
        >
          <String id={_S`runFullScan`} />
        </Button>
      </div>
    </div>
  );
}
