//ROUTE_SCAN_EMAIL page (navigated to from onboarding or service page)
import React, { useState } from "react";
import {
  ONBOARD_CHECK_EMAIL,
  ONBOARD,
  TRUE_S,
  UA_ONBOARDING_SCAN_DONE,
} from "../../constants/main";
import { connect, useDispatch } from "react-redux";
import { replace } from "connected-react-router";
import { ROUTE_SCAN_EMAIL_RESULT } from "../app/routes";

// DOM
import {
  Paragraph,
  Button,
  Icon,
  Link,
  Label,
  Checkbox,
  Display,
  Input,
} from "@mcafee/pegasus";

import { String } from "../../l10n/loader";
import { POPUP_DWS_LEARN_MORE } from "../PopUps/constants";
import { showPopup } from "../../store/thunks/popups";
import { saveUserActions } from "../../store/thunks/auth";
import { createQueryString, useIsSignedIn } from "../../utils";
import Unauthorized from "../Shared/Unauthorized";

import { DialogLayout } from "../layouts/DialogLayout";
import events from "../../experience/events";
import { publishEvent } from "../../store/thunks/experience";

import checkEmailAvatar from "../_assets/images/onboard/scanEmail/scanEmail.svg";
import "./scanEmail.scss";

const ScanEmail = ({ accountEmail, replace, fetchingBreachCount, query }) => {
  const [consentChecked, setConsentChecked] = useState(false);
  const hide_skip = query.hide_skip === "true";
  const dispatch = useDispatch();

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  const handleLearnMoreClick = () => {
    dispatch(showPopup(POPUP_DWS_LEARN_MORE));
  };

  const onClickSkip = (e) => {
    dispatch(saveUserActions({ [UA_ONBOARDING_SCAN_DONE]: false }));
    dispatch(publishEvent(events.root.home));
  };

  const handleSubmit = async () => {
    saveUserActions({ [UA_ONBOARDING_SCAN_DONE]: true });
    replace({
      pathname: ROUTE_SCAN_EMAIL_RESULT,
      search: createQueryString({ path: ONBOARD, email: accountEmail }),
    });
  };

  const onUpdateConsentState = (e) => {
    setConsentChecked(e.currentTarget.checked);
  };

  const Buttons = () => (
    <>
      <Button
        loading={fetchingBreachCount ? TRUE_S : undefined}
        id={`${ONBOARD_CHECK_EMAIL}SearchWeb`}
        onClick={handleSubmit}
        size="sm"
        data-testid="checkEmailSubmit"
        disabled={!consentChecked}
      >
        <String id="identity.search.prompt" />
      </Button>
      {!hide_skip && (
        <Button
          className="ma-16"
          id="CheckEmailSkip"
          tabIndex={0}
          variant="tertiary"
          onClick={onClickSkip}
          disabled={fetchingBreachCount}
          size="sm"
        >
          <String id="messages.skip" />
        </Button>
      )}
    </>
  );

  return (
    <DialogLayout
      pageId={ONBOARD_CHECK_EMAIL}
      image={checkEmailAvatar}
      buttons={<Buttons />}
      animate={true}
      showBack={query.back || hide_skip ? true : false}
    >
      <Display size="xs">
        <String id="onboard.dwsIntro.title" />
      </Display>

      {/* Show the email to be scanned if not right after signing-in or creating an account */}
      {hide_skip && (
        <div className="mt-24">
          <Input icon="email-rounded-outline" readOnly value={accountEmail} />
        </div>
      )}

      <Paragraph className={`text-secondary  mb-2 mt-40`} size="md">
        <String id="onboard.dwsIntro.content" />
      </Paragraph>

      <Label className="py-24">
        <Link
          onClick={handleLearnMoreClick}
          id={`${ONBOARD_CHECK_EMAIL}LearnMore`}
          className="flex align-items-center"
          data-testid="onboardCheckEmailLearnMore"
        >
          <Icon icon="info-outline mr-8"></Icon>
          <String id="identity.search.knowMore" />
        </Link>
      </Label>
      <Paragraph>
        <Checkbox
          data-testid="consentCheckbox"
          id="check16plusConsent"
          onChange={onUpdateConsentState}
        >
          <strong>
            <String id="identity.16plusConsent" />
          </strong>
        </Checkbox>
      </Paragraph>
    </DialogLayout>
  );
};

export default connect(
  (state) => ({
    accountEmail: state.auth.email,
    query: state.router.location.query,
    ...state.dws,
  }),
  {
    replace,
  }
)(ScanEmail);
