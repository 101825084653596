import React from "react";
import { String } from "../../l10n/loader";
import { useDispatch, useSelector } from "react-redux";
import buildInfo from "../../build-info.json";
import { push } from "connected-react-router";
import { ROUTE_OPEN_SOURCE_LICENSES } from "../app/routes";

// DOM
import {
  Row,
  Col,
  Label,
  Link,
  Paragraph,
  Grid,
  Brand,
  Subheading,
} from "@mcafee/pegasus";

// Custom components
import NavbarLayout from "../NavBar/NavbarLayout";
import SettingsMenu from "./SettingsMenu";
import {
  APP_ATLAS,
  APP_VERSION,
  MCAFEE_SUPPORT_URL,
} from "../../constants/main";
import "./settings.scss";
import { newGUID, useLegalAndPrivacyInfo } from "../../utils";
import { uwpSendTelemetry } from "../../uwp";
import {
  ANALYTICS_MANUAL,
  ANALYTICS_SUCCESS,
  EVENTID_PPS_SUPPORT_INITIATED,
  HIT_LABEL_0,
  HIT_LABEL_2,
  HIT_SCREEN,
  HIT_TRIGGER,
  SCREEN_SETTINGS_ABOUT_US,
} from "../../constants";
import { OpenInNew } from "@material-ui/icons";

export default function AboutSettingsPage() {
  const _S = (str) => `messages.settings.about.${str}`;
  const { legalUrl, privacyUrl } = useLegalAndPrivacyInfo();

  const dispatch = useDispatch();

  const app = useSelector((state) => state.experience.app);
  const { uwpVersion, aff_id } = useSelector((state) => state.context);

  const openUrl = (url) => {
    if (url === ROUTE_OPEN_SOURCE_LICENSES) {
      dispatch(push(ROUTE_OPEN_SOURCE_LICENSES));
    } else {
      window.open(url, "_blank");
      if (url === MCAFEE_SUPPORT_URL) {
        uwpSendTelemetry(EVENTID_PPS_SUPPORT_INITIATED, {
          [HIT_TRIGGER]: ANALYTICS_MANUAL,
          [HIT_SCREEN]: SCREEN_SETTINGS_ABOUT_US,
          [HIT_LABEL_0]: ANALYTICS_SUCCESS,
          [HIT_LABEL_2]: "about_us",
          hit_guid: newGUID(),
        });
      }
    }
  };

  const aboutUsLinks = [
    {
      text: "contactSupport",
      url: MCAFEE_SUPPORT_URL,
    },
    {
      text: "termsAndConditions",
      url: legalUrl,
    },
    {
      text: "privacyNotice",
      url: privacyUrl,
    },
    {
      text: "openSourceLicenses",
      url: ROUTE_OPEN_SOURCE_LICENSES,
    },
  ];
  return (
    <NavbarLayout
      id="Settings"
      className="pa-0"
      classNameLayout="pa-0 bg-white"
    >
      <Grid>
        <Row style={{ height: "100%" }}>
          <Col sm xs={12} className="flex">
            <SettingsMenu />
          </Col>
          <Col
            sm={8}
            xs={12}
            className="flex direction-column justify-content-start bg-white pa-24 grow-1"
          >
            <Subheading
              size="lg"
              className="mb-24"
              data-testid="AboutSettingsPageTitle"
            >
              <String id={_S`title`} />
            </Subheading>
            <div className="flex direction-column align-items-center mb-50 mt-50">
              <Brand className="mb-16" />
              {app === APP_ATLAS && (
                <>
                  <Label size="sm" className="text-tertiary mb-4">
                    <String
                      id={_S`version`}
                      values={{ appVersion: APP_VERSION }}
                    />
                  </Label>
                  {aff_id ? (
                    <Label size="sm" className="text-tertiary mb-4">
                      <String
                        id={_S`aff_id`}
                        values={{
                          aff_id: aff_id,
                        }}
                      />
                    </Label>
                  ) : null}
                  <Label size="sm" className="text-tertiary mb-4">
                    <String id={_S`app_build`} values={{ uwpVersion }} />
                  </Label>
                </>
              )}
              <Label size="sm" className="text-tertiary mb-4">
                <String
                  id={_S`web_build`}
                  values={{ build: buildInfo.build }}
                />
              </Label>

              <Paragraph size="xs" className="text-gray-light">
                <String
                  id={_S`copyright`}
                  values={{ year: new Date().getFullYear() }}
                />
              </Paragraph>
            </div>
            <div className="mt-24 justify-content-between">
              {aboutUsLinks.map((item, index) =>
                item.url ? (
                  <div
                    className="flex direction-row mb-32 align-items-center"
                    key={index}
                  >
                    <OpenInNew fontSize="small" className="text-info mr-16" />
                    <Link
                      onClick={() => openUrl(item.url)}
                      data-testid={`LinkText${index}`}
                    >
                      <String id={_S(item.text)} />
                    </Link>
                  </div>
                ) : null
              )}
            </div>
          </Col>
        </Row>
      </Grid>
    </NavbarLayout>
  );
}
