import React, { useEffect, useState } from "react";

// Supported toasts
import SnackbarSucess from "./SnackbarSuccess";

import BannerNotification from "./BannerNotification";

import { Toaster } from "@mcafee/pegasus";

import { connect, useDispatch } from "react-redux";

import { removeToast } from "../../store/toasts";

import "./toasters.scss";

const UnknownToast = () => {
  return <div className="pa-32">Unknown toast</div>;
};

const ToastComponent = (toast, props) => {
  const Toast =
    {
      SnackbarSucess,
      BannerNotification,
    }[toast] || UnknownToast;
  return <Toast {...props} />;
};

const formattedToastList = (toastIds = [], dispatch) => {
  return toastIds.map((info, index) => {
    const { toast, props } = info;
    if (props.selfDestruct) {
      setTimeout(
        () => dispatch(removeToast({ toast, id: props.id })),
        props.selfDestructTimer
      );
    }
    const handleFinish = (results = true) => {
      const { onFinish } = info.props;
      if (onFinish) {
        onFinish(results);
      }
      dispatch(removeToast({ toast, id: props.id, results }));
    };
    const handleClose = (e, results = undefined) => {
      dispatch(removeToast({ toast, id: props.id, results }));
      const { onCancel } = info.props || {};
      if (onCancel) {
        onCancel(results);
      }
    };
    return {
      id: props.id, // this id is relevant only to avoid a warning inside toaster
      content: ToastComponent(toast, {
        ...props,
        ...{
          handleFinish,
          onFinish: handleFinish,
          handleClose,
          onCancel: handleClose,
        },
      }),
    };
  });
};

const Toasters = ({ toastList }) => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const isBanner = toastList.some((ele) => ele.toast === "BannerNotification");
  /// used useEffect to prevent conflicts with popover component
  useEffect(() => {
    const formattedList = formattedToastList(toastList, dispatch);
    setList(formattedList);
  }, [toastList, dispatch]);
  return (
    <Toaster
      toasts={list}
      placement={`${isBanner ? "top" : "bottom-right"} `}
      className={`${isBanner ? "banner-container" : ""} ${
        toastList.length === 1 ? "toast-length-1" : ""
      }`}
    />
  );
};

export default connect(
  (state) => ({
    ...state.toasts,
  }),
  {}
)(Toasters);
