import React from "react";
import { FormattedMessage } from "react-intl";
import { isProduction } from "../utils";
import { Input } from "@mcafee/pegasus";
import { DEFAULT_LOCALE } from "../constants";

/**
 * Matching a given locale_id to a recognized folder name value for translated strings
 * It will return the exact match if found,
 * else find the first matching language
 * else returns DEFAULT_LOCALE (en-us)
 * @param {string} locale_id - fetched via context api
 * @returns {string} returns folder name if folder is available, else returns "en-us"
 */
export const mapLocaleToFolder = (locale_id) => {
  if (isProduction()) {
    // TODO: remove this condition after integrating real translated content
    return DEFAULT_LOCALE;
  }
  // List of the localization folders available
  // this array will have 28 languages eventually
  const availableFolders = [
    // "ar-AE", Pseudo-translation
    "de-DE",
    "es-ES",
    "es-MX",
    "fr-FR",
    "fr-CA",
    "it-IT",
    "ja-JP",
    "pt-BR",
    // "ru-RU", Pseudo-translation
  ];

  //Find exact match
  const localeIgnoreCase = new RegExp(`^${locale_id}$`, "i");
  const exactMatch = availableFolders.find((i) => i.match(localeIgnoreCase));

  if (exactMatch !== undefined) {
    return exactMatch;
  }

  //Extract the language part (ignore the country)
  const [language] = locale_id.split("-");

  // regex to match "language*"
  const languagePrefix = new RegExp(`^${language}.*`, "i");

  // Find the first "language*" match
  const firstLanguageMatch = availableFolders.find((i) =>
    i.match(languagePrefix)
  );

  return firstLanguageMatch || DEFAULT_LOCALE;
};

export const loadMessages = async (locale_id) => {
  const base_messages = require("./base/messages.json");
  const base_onboard = require("./base/onboard.json");
  const base_devices = require("./base/devices.json");
  const base_identity = require("./base/identity.json");
  const base_dashboard = require("./base/dashboard.json");
  const base_vpn = require("./base/vpn.json");
  const base_popups = require("./base/popups.json");
  const base_toasters = require("./base/toasters.json");
  const base_services = require("./base/services.json");
  const base_hackBlock = require("./base/hackBlock.json");
  const base_settings = require("./base/settings.json");
  const base_logout = require("./base/logout.json");
  const base_failure = require("./base/failure.json");
  const base_av = require("./base/av.json");


  const folder = mapLocaleToFolder(locale_id);
  const overrideBase = folder !== DEFAULT_LOCALE;
  const messages = overrideBase ? require(`./${folder}/messages.json`) : {};
  const onboard = overrideBase ? require(`./${folder}/onboard.json`) : {};
  const devices = overrideBase ? require(`./${folder}/devices.json`) : {};
  const identity = overrideBase ? require(`./${folder}/identity.json`) : {};
  const dashboard = overrideBase ? require(`./${folder}/dashboard.json`) : {};
  const vpn = overrideBase ? require(`./${folder}/vpn.json`) : {};
  const popups = overrideBase ? require(`./${folder}/popups.json`) : {};
  const toasters = overrideBase ? require(`./${folder}/toasters.json`) : {};
  const services = overrideBase ? require(`./${folder}/services.json`) : {};
  const hackBlock = overrideBase ? require(`./${folder}/hackBlock.json`) : {};
  const settings = overrideBase ? require(`./${folder}/settings.json`) : {};
  const logout = overrideBase ? require(`./${folder}/logout.json`) : {};
  const failure = overrideBase ? require(`./${folder}/failure.json`) : {};
  const av = overrideBase ? require(`./${folder}/av.json`) : {};

  //Read partner and cohort strings from the Webpack built bundle
  const partner = {}; // require(`/partner/${folder||"base"}/messages.json`);
  const cohort = {}; // require(`/cohort/${folder||"base"}/messages.json`);
  //We can also fetch JSON data from a URL to override and customize some strings

  //Fake delay for now, later on we fetch partner and cohort messages from remote Urls
  await new Promise((r) => setTimeout(r, 1));

  //merge strings into one object
  return {
    ...base_messages,
    ...messages, //Translated content, (make sure it is appended after the base file)

    ...base_onboard,
    ...onboard,

    ...base_devices,
    ...devices,

    ...base_identity,
    ...identity,

    ...base_dashboard,
    ...dashboard,

    ...base_vpn,
    ...vpn,

    ...base_popups,
    ...popups,

    ...base_toasters,
    ...toasters,

    ...base_services,
    ...services,

    ...base_hackBlock,
    ...hackBlock,

    ...base_settings,
    ...settings,

    ...base_logout,
    ...logout,

    ...base_failure,
    ...failure,

    ...base_av,
    ...av,

    //partner and cohort should be merged at the end to be able to override default strings
    ...partner,
    ...cohort,
  };
};

export const String = FormattedMessage;

export const LocInput = ({
  children,
  defaultMessage = " ",
  innerRef,
  ...rest
}) => (
  <FormattedMessage
    id={children || "not_defined"}
    defaultMessage={defaultMessage}
  >
    {(txt) => <Input placeholder={txt} ref={innerRef} {...rest}></Input>}
  </FormattedMessage>
);
