import React from "react";

import { Snackbar, Paragraph } from "@mcafee/pegasus";
import { String } from "../../l10n/loader";
import "./snackbarSuccess.scss";

export default function SnackbarSucess({ stringId, values = {} }) {
  values.br = <br />;

  return (
    <Snackbar kind="success" className="snackbarSuccess">
      <Paragraph data-testid="snackbarContent">
        <String id={stringId} values={values} />
      </Paragraph>
    </Snackbar>
  );
}
