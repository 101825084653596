import React, { useEffect } from "react";
import { String } from "../../l10n/loader";
import { connect } from "react-redux";
import { push } from "connected-react-router";

// Store
import { fetchPrivacyInfo } from "../../store/thunks/app";

// DOM
import { Paragraph, Heading, Button } from "@mcafee/pegasus";

const DataDisclosureModal = ({
  //passed attrs
  handleClose,
  //...state.context
  privacyUrl,
  fetchingPrivacyInfo,
  fetchingPrivacyInfoError,
  //dispatches
  fetchPrivacyInfo,
}) => {
  const localePrefix = "vpn.";

  const contentSessions = [
    {
      titleId: "wirelessData.header",
      descriptionId: "wirelessData.content",
    },
    {
      titleId: "bandwidth.header",
      descriptionId: "bandwidth.content",
    },
  ];

  const styles = {
    root: { maxWidth: "592px" },
    relative: { position: "relative" },
    icon: {
      position: "absolute",
      left: "0",
    },
    minwidth: { minWidth: "24px" },
  };

  useEffect(() => {
    if (!privacyUrl && !fetchingPrivacyInfo && !fetchingPrivacyInfoError) {
      fetchPrivacyInfo();
    }
  }, [
    fetchPrivacyInfo,
    fetchingPrivacyInfo,
    fetchingPrivacyInfoError,
    privacyUrl,
  ]);

  const onClickPrivacyNotice = (e) => {
    if (privacyUrl) {
      window.open(privacyUrl, "_blank");
    }
  };

  return (
    <div
      handleClose={handleClose}
      className="pl-48 pt-60 pr-56 pb-32"
      style={styles.root}
    >
      <Heading className="mb-16">
        <String id={`${localePrefix}dataDisclosure.header`} />
      </Heading>

      <Paragraph className="mb-24">
        <String id={`${localePrefix}dataDisclosure.subHeader`} />
      </Paragraph>
      {contentSessions.map((contentSession, index) => (
        <div
          className="flex justify-content-between mb-24"
          style={styles.relative}
          key={`dataDisclosureModal_${index}`}
        >
          <Paragraph
            size="sm"
            className="my-4 text-center"
            style={styles.minwidth}
          >
            •
          </Paragraph>
          <Paragraph>
            <String id={`${localePrefix}${contentSession.descriptionId}`} />
          </Paragraph>
        </div>
      ))}
      <div className="flex direction-row justify-content-end mt-40">
        <Button onClick={handleClose} id="DataDisclosureModalGotIt" size="sm">
          <String id={`${localePrefix}gotIt`} />
        </Button>
        <Button
          variant="tertiary"
          onClick={onClickPrivacyNotice}
          id="DataDisclosureModalPrivacyNotice"
          disabled={!privacyUrl}
          size="sm"
        >
          <String id={`${localePrefix}privacyNotice`} />
        </Button>
      </div>
    </div>
  );
};

export default connect((state) => ({ ...state.context }), {
  push,
  fetchPrivacyInfo,
})(DataDisclosureModal);
