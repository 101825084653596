import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading } from "@mcafee/pegasus";
import AddDeviceSuccess from "../../components/_assets/images/popUps/addDeviceSuccess.svg";

//utils
import { POPUP_ADD_EMAIL_DEVICE } from "../PopUps/constants";
import { useDispatch } from "react-redux";
import { showPopup } from "../../store/thunks/popups";

const SuccessPopUp = ({ onCancel }) => {
  const localePrefix = "popups.";
  const styles = {
    popUpStyles: {
      maxWidth: 450,
    },
    logoStyles: {
      float: "center",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
    errorStyles: {
      textAlign: "left",
      display: "flex",
      justifyContent: "left",
      alignItems: "left",
      marginLeft: "12px",
    },
  };

  const dispatch = useDispatch();

  const handleSendLinkToDifferentEmail = async (e) => {
    dispatch(showPopup(POPUP_ADD_EMAIL_DEVICE));
  };

  return (
    <div style={styles.popUpStyles} className="pa-32">
      <img style={styles.logoStyles} src={AddDeviceSuccess} alt="" />

      <Heading className="mt-24" size="xs" data-testid="successPopUpHeader">
        <String id={`${localePrefix}addSuccessPopUp.header`} />
      </Heading>

      <Paragraph className="mt-8" size="sm" data-testid="successPopUpContent">
        <String id={`${localePrefix}addSuccessPopUp.content`} />
      </Paragraph>

      <div className="mt-24 flex justify-content-end">
        <Button
          onClick={handleSendLinkToDifferentEmail}
          id="SendToDifferentEmail"
          data-testid="successPopUpTryDifferentEmailButton"
          size="sm"
          variant="tertiary"
        >
          <String id={`${localePrefix}sendToDifferentEmail`} />
        </Button>
        <Button
          onClick={onCancel}
          id="AddSuccessGotIt"
          data-testid="successPopUpGotItButton"
          size="sm"
        >
          <String id={`${localePrefix}gotIt`} />
        </Button>
      </div>
    </div>
  );
};

export default SuccessPopUp;
