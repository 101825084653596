import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading } from "@mcafee/pegasus";
import WomanTyping from "../../components/_assets/images/identity/whatCanYouDo.svg";

import "./whatCanYouDoPopup.scss";

const WhatCanYouDoPopup = ({
  onCancel,
  whyChangePassword,
  makeYourselfSafer,
}) => {
  const _S = (str) =>
    `popups.${
      whyChangePassword
        ? "whyChangePassword"
        : makeYourselfSafer
        ? "makeYourselfSafer"
        : "whatCanYouDo"
    }.${str}`;

  return (
    <div className="whatCanYouDoPopup">
      <img src={WomanTyping} alt="" className="whatCanYouDoPopup__image" />

      <Heading size="xs" className="whatCanYouDoPopup__title">
        <String id={_S`header`} />
      </Heading>

      <Paragraph
        size="sm"
        data-testid="WhatCanYouDoPopupContent"
        className="whatCanYouDoPopup__content"
      >
        <String id={_S`content`} />
      </Paragraph>

      {!makeYourselfSafer && (
        <Paragraph
          size="sm"
          data-testid="WhatCanYouDoPopupContent2"
          className="whatCanYouDoPopup__content"
        >
          <String id={_S`content2`} />
        </Paragraph>
      )}

      <Paragraph
        size="sm"
        data-testid="WhatCanYouDoPopupContent3"
        className="whatCanYouDoPopup__content"
      >
        <span className="whatCanYouDoPopup__contentBold">
          <String id={_S`keepInMind`} />
        </span>{" "}
        - <String id={_S`content3`} />
      </Paragraph>

      <Button
        onClick={onCancel}
        id="WhatCanYouDoPopupGotIt"
        data-testid="WhatCanYouDoPopupGotItButton"
        size="sm"
        className="whatCanYouDoPopup__button"
      >
        <String id="popups.gotIt" />
      </Button>
    </div>
  );
};

export default WhatCanYouDoPopup;
