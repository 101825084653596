//////// Dashboard cards
//Missing:
//"add_breaches_email_verified_card"
//  Setup identity monitoring for primary email)
//"add_no_breaches_email_verified_card"
//  Add secondary email for identity monitoring)
//"unsafe_wifi_alert"
//  Network attack detected: VPN is not setup. Ask user to disconnect from wifi then setup VPN configuration
//  Network attack detected: VPN is setup. Ask user to disconnect wifi
//"open_wifi_alert"
//  Connected to unsecure wifi: VPN is not setup. Ask user to setup VPN configuration and then turn on VPN
//  Connected to new unsecure wifi: VPN is setup & VPN preferences are set to manual. Ask user to turn on VPN
//"add_secure_wifi_card"
//  Connected to secure wifi: VPN is not setup. Recommend user to turn on VPN
//  Connected to secure wifi: VPN is setup & VPN preferences is either set to unsecure wifi only or turn on manually. Recommend user to turn on VPN
//  Setup VPN: If VPN configuration has not been setup or has been deleted/removed. Ask user to setup VPN configuration
//"add_vpn_location_permission_card"
//  Location Permission not given and vpn is setup
//"add_location_permission_card_vpn_setup"
//  Location Permission not given and vpn is NOT setup

//Identity cards
export const CARD_EMAIL_VALIDATION = "email_validation"; //(PPS2) Has your personal info being stolen
export const CARD_ONBOARD_DWS_SKIPPED = "onboard_dws_skipped"; //(PPS2) Has your personal info being stolen

export const CARD_ACCOUNT_BREACHED = "account_breached"; //Your personal info was taken -
export const CARD_MONITOR_NEW_EMAIL = "monitor_new_email"; //Want to search for other personal info? -
export const CARD_BREACH_SMS_NOTIFICATION = "breach_sms_notification"; //Instantly know when you're at risk -
export const CARD_IDENTITY_SECURE = "identity-secure"; //*Your personal info is safe
export const CARD_BREACH_LEARN_MORE = "breach_learn_more"; //What is a data breach -
//Vpn cards
export const CARD_OPEN_WIFI_ALERT = "open_wifi_alert"; //Careful, This Wi-Fi network has weak security -
export const CARD_ADD_VPN_RECOMMENDED = "add_vpn_recommended"; //This Wi-Fi is secure, but... //What's privacy protection -
export const CARD_ADD_SECURE_WIFI = "add_secure_wifi_card"; //**This Wi-Fi is secure, but... -
export const CARD_VPN_LEARN_MORE = "vpn_learn_more"; //Why do hackers love public Wi-Fi? -
//Networking cards
export const CARD_UNSAFE_WIFI_ALERT = "unsafe_wifi_alert"; //Disconnect now - this Wi-Fi is unsafe -
//General cards
export const CARD_ABOUT_PPS_INFO = "about_pps_info"; //??
export const CARD_PASSWORD_CHANGE = "password_change"; //Time to change your password
export const CARD_PROTECT_NEW_DEVICE = "protect_new_device"; //Protect all devices|Protect more devices -
export const CARD_ADD_NOTIFICATION_PERMISSION = "add_notification_permission"; //We have important things to tell you
export const CARD_MISSING_PHONE_NUMBER = "missing_phone_number"; //**Instantly know when you're at risk
//Other cards ( not verified )
export const CARD_NAME_THIS_DEVICE = "name_this_device"; //Lets get you set up
export const CARD_IDENTITY_MONITOR = "identity_monitor"; //Identity
export const CARD_AV_PROTECTING_YOU = "av_is_protecting_you"; // AV is protecting you
export const CARD_AV_FOUND_THREATS = "av_found_threats";
export const CARD_AV_TURN_ON_RTS = "av_turn_on_rts";

// NEW Cards
export const CARD_EMAIL_VERIFICATION_BREACHES_FOUND =
  "email_verification_breaches_found"; // NEW: 'Your info's been stolen but we can help' -
export const CARD_EMAIL_VERIFICATION_NO_BREACHES_FOUND =
  "email_verification_no_breaches_found"; // NEW: 'Can we keep an eye out for your info?'
// ToDo: No Figma Cards Found - needs CXD discussion
export const CARD_ADD_LOCATION_PERMISSION_CARD_VPN_SETUP =
  "add_location_permission_card_vpn_setup"; // NEW: 'One more step to go to set up VPN'
export const CARD_ADD_VPN_LOCATION_PERMISSION_CARD =
  "add_vpn_location_permission_card"; // NEW: 'You've connected to a new Wi-Fi'
// ToDo: No card ID found - needs discussion with Cards API team [FIGMA link: https://www.figma.com/file/0qx8pn4pZ3yWaojQVtFDXF/PPS-Dashboard-v0.2.4?node-id=509%3A10563]
export const CARD_PPS_SOFTWARE_OUTDATED = "pps_software_outdated"; // New: 'Update so we can do more for you'
