// React
import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// Store
import { networkDisconnectStart } from "../../store/network";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import DisconnectImage from "../_assets/images/dashboard/cards/disconnectNow.svg";

// utils
import { NEEDS_ATTENTION } from "../../constants/main";
import { ROUTE_HACK_BLOCK } from "../app/routes";

export default function UnsafeWifiAlert({ id }) {
  const dispatch = useDispatch();

  const onDisconnectNow = () => {
    dispatch(networkDisconnectStart());
    dispatch(push(ROUTE_HACK_BLOCK));
  };

  const card = {
    id: id,
    backgroundImage: DisconnectImage,
    tagText: NEEDS_ATTENTION,
    primaryActionStringId: "disconnectNow",
    primaryActionDataTestId: "unsafeWifiAlertDisconnectNow",
    onPrimaryAction: onDisconnectNow,
  };

  return <DashboardCard {...card} />;
}
