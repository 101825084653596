import React, { useState } from "react";
import { String } from "../../l10n/loader";
import { useDispatch, useSelector } from "react-redux";

// Store
import { publishEvent } from "../../store/thunks/experience";
import { signInCancel } from "../../store/auth";

// DOM
import {
  Animator,
  Heading,
  Paragraph,
  Button,
  Grid,
  Row,
  Brand,
  Col,
} from "@mcafee/pegasus";

// Custom components
import UnifiedAuth from "../signIn/UnifiedAuth";
import { UnifiedAuthIframeContainer } from "../signIn/UnifiedAuthIframe";
import oval_loader from "../../components/_assets/animations/oval_loader/oval_loader.json";

// utils
import events from "../../experience/events";
import CheckAction from "../Shared/CheckAction";
import "./CreateAccount.scss";
import { navToErrorPage, newGUID, useLegalAndPrivacyInfo } from "../../utils";
import {
  FEATURE_SUBSCRIPTION,
  HIT_LABEL_0,
  HIT_LABEL_1,
  HIT_TRIGGER,
  LAUNCHUA_CREATE_ACCOUNT,
  LAUNCHUA_SIGN_IN,
  TRIAL,
  MAX_ERROR_RETRY,
} from "../../constants";
import { uwpSendTelemetry } from "../../uwp";
import { EVENTID_CONTEXT_CHANGE } from "../../constants/analytics";
import { Done } from "@material-ui/icons";
import { acceptEulaAndPrivacyNotice } from "../../store/thunks/eula";
import { fetchAccessToken, fetchClaims } from "../../store/thunks/auth";
import ErrorMessage from "../Shared/ErrorMessage";
import { ViewPortCenter } from "../Widgets/ViewPortCenter";

export default function CreateAccount({ location: { launchUA } }) {
  const _S = (str) => `messages.${str}`;

  const [{ authCode, newUser }, setAuthCode] = useState({});
  const [retryCount, setRetryCount] = useState(0);
  const [errorInfo, setErrorInfo] = useState(null);

  const dispatch = useDispatch();
  //Selectors
  const doneTrialPrompt = useSelector((state) => state.context.doneTrialPrompt);
  const { accessToken, signingIn, signInCancelled, fetchingAccessToken } =
    useSelector((state) => state.auth);

  const fetchingClaims = useSelector((state) => state.context.fetchingClaims);

  const features = useSelector((state) => state.experience.features);

  useLegalAndPrivacyInfo();

  const processing =
    (signingIn || fetchingAccessToken || fetchingClaims || accessToken) &&
    !signInCancelled;

  const showSubscriptionInfo =
    features && features[FEATURE_SUBSCRIPTION].enabled;

  const doneSignIn = (newUser) => {
    dispatch(acceptEulaAndPrivacyNotice());
    if (newUser && !doneTrialPrompt) {
      onTrialStart();
    } else {
      dispatch(
        publishEvent(
          newUser ? events.account.accountCreated : events.account.signedIn
        )
      );
    }
  };
  const trySignInWithAuthCode = ({ authCode, newUser }, retryCount = 0) => {
    setErrorInfo(null);
    dispatch(
      fetchAccessToken({
        authCode,
        newUser,
      })
    ).then(({ ok, error }) => {
      //Accept eula after successful sign-in or registration
      if (ok) {
        doneSignIn(newUser);
      } else if (error) {
        if (retryCount < MAX_ERROR_RETRY) {
          setRetryCount(retryCount + 1);
          setErrorInfo(error);
        } else {
          navToErrorPage(error);
        }
      }
    });
  };

  const tryFetchClaims = async (newUser, retryCount = 0) => {
    setErrorInfo(null);
    try {
      await dispatch(fetchClaims());
      doneSignIn(newUser);
    } catch (e) {
      if (retryCount < MAX_ERROR_RETRY) {
        setRetryCount(retryCount + 1);
        setErrorInfo(e);
      } else {
        navToErrorPage(e);
      }
    }
  };

  const onAuthCodeReceived = (authCode, newUser) => {
    setAuthCode({ authCode, newUser });
    trySignInWithAuthCode({ authCode, newUser });
  };

  const onRetry = (e) => {
    if (errorInfo.source === "auth_v1_token_claims") {
      tryFetchClaims(newUser, retryCount);
    } else {
      trySignInWithAuthCode({ authCode, newUser }, retryCount);
    }
  };

  const onTrialStart = () => {
    uwpSendTelemetry(EVENTID_CONTEXT_CHANGE, {
      [HIT_TRIGGER]: "product_subscription_status",
      [HIT_LABEL_0]: "trial-active",
      [HIT_LABEL_1]: null,
      hit_guid: newGUID(),
    });
    dispatch(publishEvent(events.account.trialAccountCreated));
  };

  const onProgressCancel = (e) => {
    dispatch(signInCancel({ analytics: true }));
  };

  const SignInProgress = () => {
    return (
      <div
        className={`flex align-items-center justify-content-center direction-column loader`}
        style={{ width: "auto" }}
      >
        <Animator src={oval_loader} width="100" style={{ width: "120px" }} />
        <Paragraph size="lg" className="mt-32 text-white">
          <String id="signingYouIn" />
        </Paragraph>
        <Button
          className="mt-32"
          variant="secondary"
          onClick={onProgressCancel}
          id="DashboardCancel"
          size="sm"
        >
          <String id="cancel" />
        </Button>
      </div>
    );
  };

  const accountActions = () => {
    return (
      <div
        className={`flex direction-column align-content-center align-items-center justify-content-center ${
          processing ? "hide-content" : ""
        }`}
      >
        <Heading size="sm">
          <String id={_S`login.header`} />
        </Heading>
        <Paragraph size="sm">
          <String
            id={_S`description`}
            values={{
              signUp: (
                <CheckAction event={events.account.create}>
                  <UnifiedAuth
                    signInType="Register"
                    label="signUp"
                    buttonStyle={false}
                    onSuccess={onAuthCodeReceived}
                    authLaunch={launchUA === LAUNCHUA_CREATE_ACCOUNT}
                  />
                </CheckAction>
              ),
            }}
          />
        </Paragraph>

        <CheckAction event={events.account.signIn}>
          <UnifiedAuth
            signInType="Login"
            label="login"
            lightColor={false}
            onSuccess={onAuthCodeReceived}
            authLaunch={launchUA === LAUNCHUA_SIGN_IN}
          />
        </CheckAction>
      </div>
    );
  };

  const trialOfferIntro = () => {
    return (
      <div className={`trial-container ${processing ? "hide-content" : ""}`}>
        <div className="trial-container__content">
          <Heading>
            <String id={_S`startTrial.header`} />
          </Heading>
          <div className="trial-container__content__desc">
            {["1", "2", "3", "4"].map((id) => (
              <div
                key={`bulletPoint${id}`}
                className="trial-container__content__desc__info"
              >
                <Done className="text-success" />
                <String id={`messages.startTrial.whyTrial${id}`} />
              </div>
            ))}
          </div>
        </div>

        <div className="trial-container__actions">
          <UnifiedAuth
            signInType="Register"
            label="startTrial.getStarted"
            lightColor={false}
            onSuccess={onAuthCodeReceived}
            content={TRIAL}
          />
          <Paragraph className="trial-container__actions__signIn">
            <String
              id={_S`startTrial.signIn`}
              values={{
                signIn: (
                  <UnifiedAuth
                    signInType="Login"
                    label="signIn"
                    buttonStyle={false}
                    onSuccess={onAuthCodeReceived}
                    content={TRIAL}
                  />
                ),
              }}
            />
          </Paragraph>
          {showSubscriptionInfo && (
            <Paragraph size="sm" className="trial-container__actions__noCard">
              <String id={_S`startTrial.noCard`} />
            </Paragraph>
          )}
        </div>
      </div>
    );
  };

  const actionsArea = () => {
    return doneTrialPrompt ? accountActions() : trialOfferIntro();
  };

  if (errorInfo) {
    return (
      <ViewPortCenter>
        <ErrorMessage {...{ error: errorInfo, onRetry }} />
      </ViewPortCenter>
    );
  }

  return (
    <Grid className="CreateAccount">
      <Row className="welcomeWrapper" data-testid="OnboardLayout">
        <Col
          xs={12}
          md={6}
          className="flex direction-column pt-8 px-56 align-items-start z-index4"
        >
          <Brand kind="light" className="brand" />
          <div
            className={`defaultIntro ${processing ? "hide-in-portrait" : ""}`}
          >
            <Heading size="xxl" className="text-white">
              <String id={_S`myAccountTitle`} />
            </Heading>
          </div>
          <div className={`idpsIntro ${processing ? "hide-in-portrait" : ""}`}>
            <Heading size="lg" className="text-white">
              <String id={_S`idps.welcome`} />
            </Heading>
            <Paragraph size="sm" className={`text-white mt-24`}>
              <String id={_S`idps.intro`} />
            </Paragraph>
          </div>
          <div
            className={`welcome-image ${processing ? "hide-in-portrait" : ""}`}
          />
          <UnifiedAuthIframeContainer />
        </Col>
        <Col
          xs={12}
          md={6}
          className={`flex direction-column align-content-center justify-content-center  ${
            !processing ? "bg-secondary" : ""
          }`}
        >
          {actionsArea()}
          {processing && <SignInProgress />}
        </Col>
      </Row>
    </Grid>
  );
}
