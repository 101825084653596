import { createSlice } from "@reduxjs/toolkit";
import {
  APP_ATLAS,
  APP_IDENTITY_PORTAL,
  APP_POLARIS,
  FEATURE_SUBSCRIPTION,
  SUBDOMAIN_MATCH,
} from "../constants/main.js";
import { atlas, polaris, identityPortal } from "../experience";

var matchSubdomain = window.location.hostname.match(SUBDOMAIN_MATCH);

let debuggingApp = localStorage ? localStorage.__app : null;
let appExperience;
let app = matchSubdomain
  ? matchSubdomain[1]
  : debuggingApp || process.env.REACT_APP_DEFAULT_EXPERIENCE;

switch (app) {
  case APP_IDENTITY_PORTAL:
    appExperience = identityPortal;
    break;
  case APP_POLARIS:
    appExperience = polaris;
    break;
  case APP_ATLAS:
    appExperience = atlas;
    break;
  default:
    appExperience = atlas;
    break;
}

const initialState = {
  lastFetchProductDefinition: null,
  lastPreExpiryPopupTime: 0,
  fetchingPD: false,
  fetchPDError: null,

  fetchingExperience: false,
  fetchingExperienceError: null,
  //For tracking
  lastEvent: null,
  lastEventPayload: null,
  lastAction: null,
  lastActionPayload: null,
  //event_actions, initial_events, analytics
  app,
  ...appExperience,
};

const slice = createSlice({
  name: "@exp",
  initialState,
  reducers: {
    fetchPDBegin(state) {
      state.fetchingPD = true;
      state.fetchPDError = null;
    },
    fetchPDFailure(state, action) {
      state.fetchingPD = false;
      state.fetchPDError = action.payload;
    },
    fetchPDSuccess(state, action) {
      state.lastFetchProductDefinition = action.payload.last_check;
      state.fetchingPD = false;
      state.fetchPDError = null;
      const enabled_features = action.payload.features.reduce(
        (features, val) => {
          const key = val.name.toLowerCase();
          features[key] = features[key] || { enabled: true };
          features[key] = { ...features[key], ...val };
          delete features[key].name;
          return features;
        },
        {}
      );

      //If "FEATURE_SUBSCRIPTION" feature flag was not sent, set with default true except if pkgId = 641
      if ("" + action.payload.pkgId === "641") {
        enabled_features[FEATURE_SUBSCRIPTION] = { enabled: false };
      }

      state.features = Object.assign(state.features, enabled_features);
    },

    fetchExperienceBegin(state) {
      state.fetchingExperience = true;
      state.fetchingExperienceError = null;
    },
    fetchExperienceSuccess(state, action) {
      state.fetchingExperience = false;
      state.fetchingExperienceError = null;
      if (action.payload) {
        //Merge loaded experience with existing defaults
        state.features = {
          ...state.features,
          ...action.payload.features,
        };
        state.event_actions = {
          ...state.event_actions,
          ...action.payload.event_actions,
        };
        state.initial_events = {
          ...state.initial_events,
          ...action.payload.initial_events,
        };
      }
    },
    fetchExperienceFailure(state, action) {
      state.fetchingExperience = false;
      state.fetchingExperienceError = action.payload;
    },
    publishEventSuccess(state, action) {
      state.lastEvent = action.payload.id;
      state.lastEventPayload = action.payload.payload;
    },
    publishEventFailure(state, action) {
      state.lastEvent = action.payload.id;
      state.lastEventPayload = action.payload.payload;
    },
    doActionSuccess(state, action) {
      state.lastAction = action.payload.id;
      state.lastActionPayload = action.payload.payload;
    },
    doActionFailure(state, action) {
      state.lastAction = action.payload.id;
      state.lastActionPayload = action.payload.payload;
    },
    doActionPrexEpiryPopup(state, action) {
      state.lastPreExpiryPopupTime = action.payload.lastPreExpiryPopupTime;
    },
  },
});

export const {
  fetchPDBegin,
  fetchPDSuccess,
  fetchPDFailure,

  fetchExperienceBegin,
  fetchExperienceSuccess,
  fetchExperienceFailure,

  publishEventSuccess,
  publishEventFailure,
  doActionSuccess,
  doActionFailure,
  doActionPrexEpiryPopup,
} = slice.actions;

export default slice.reducer;
