import { createSlice } from "@reduxjs/toolkit";
import { stringifyJson } from "../utils";

const initialState = {
  updating: false,
  updatingError: null,
  loading: false,
  loadingError: null,
  fetchDiscoveryApiError: null,
  fetchingDiscoveryApi: null,
  //individual settings
  vpn_profile: "auto",
  notifications_account_email: null,
  notifications_account_device: null,
  notifications_identity_email: null,
  notifications_identity_device: null,
  notifications_vpn_device: null,
  ServiceURLs: null,
};

const onSettingsResponse = (state, results) => {
  for (let [key, value] of Object.entries(results)) {
    key = key.replace(/\./g, "_");
    let matchName = key.match(/^.+\/(.+)/);

    if (matchName) {
      state[matchName[1]] = value;
    } else {
      state[key] = value;
    }
  }
};

// Reducers
const slice = createSlice({
  name: "@settings",
  initialState,
  reducers: {
    saveSettingsBegin: (state) => {
      state.updating = true;
      state.updatingError = null;
    },
    saveSettingsSuccess: (state, action) => {
      state.updating = false;
      state.updatingError = null;
      onSettingsResponse(state, action.payload);
    },
    saveSettingsFailure: (state, action) => {
      state.updating = false;
      state.updatingError = action.payload;
    },
    loadSettingsBegin: (state) => {
      state.loading = true;
      state.loadingError = null;
    },
    loadSettingsSuccess: (state, action) => {
      state.loading = false;
      state.loadingError = null;
      onSettingsResponse(state, action.payload);
    },
    loadSettingsFailure: (state, action) => {
      state.loading = false;
      state.loadingError = action.payload;
    },
    fetchDiscoveryApiBegin: (state) => {
      state.fetchDiscoveryApiError = null;
      state.fetchingDiscoveryApi = true;
    },
    fetchDiscoveryApiFailure: (state, action) => {
      state.fetchingDiscoveryApi = false;
      state.fetchDiscoveryApiError = action.payload;
    },
    fetchDiscoveryApiSuccess: (state, action) => {
      state.fetchingDiscoveryApi = false;
      state.fetchDiscoveryApiError = null;
      //Sample: {"pps_ngm":"https://messaging.qa.ccs.mcafee.com","unified_policy":"https://unifiedpolicy.qa.ccs.mcafee.com","vault_unified_apis":"https://vault.qa.unifiedapis.mcafee.com","ids":"https://identity.qa.unifiedapis.mcafee.com","pps_authentication":"https://pps-api.qa.unifiedapis.mcafee.com","pps_dashboard":"https://pps-dashboard.qa.unifiedapis.mcafee.com","pps_cloud":"https://pps-api.qa.unifiedapis.mcafee.com","pps_ui":"https://pps-ui.dev.unifiedapis.mcafee.com/","security_mgmt":"https://securitymgmt.qa.unifiedapis.mcafee.com","unified_auth":"https://account.qa.mcafee.com","ids_unified_apis":"https://identity.qa.unifiedapis.mcafee.com","csp_token_service":"https://csptoken.qa.ccs.mcafee.com","tb_api":"https://staging.polargrizzly.com"}
      const serviceUrls = action.payload.reduce((results, item) => {
        results[item.service_name.toLowerCase()] = item.primary_url;
        return results;
      }, {});

      onSettingsResponse(state, { ServiceURLs: stringifyJson(serviceUrls) });
    },
  },
});

export const {
  saveSettingsBegin,
  saveSettingsSuccess,
  saveSettingsFailure,

  loadSettingsBegin,
  loadSettingsFailure,
  loadSettingsSuccess,

  fetchDiscoveryApiBegin,
  fetchDiscoveryApiFailure,
  fetchDiscoveryApiSuccess,
} = slice.actions;

export default slice.reducer;
