import React from "react";
import { connect } from "react-redux";
import { String } from "../../l10n/loader";
import events from "../../experience/events";

// DOM
import {
  Icon,
  Drawer,
  DrawerButton,
  DrawerButtonLabel,
  DrawerFooter,
  DrawerMain,
} from "@mcafee/pegasus";

// Custom components
import {
  ROUTE_DASHBOARD,
  ROUTE_IDENTITY,
  ROUTE_SERVICES,
  ROUTE_SETTINGS,
} from "../app/routes";
import { publishEvent } from "../../store/thunks/experience";
import CheckAction from "../Shared/CheckAction";
import CheckFeature from "../Shared/CheckFeature";
import { FEATURE_VPN, FEATURE_IDENTITY } from "../../constants/main";
import { navigateBack } from "../../store/thunks/app";
import { ArrowBackRounded, WidgetsOutlined } from "@material-ui/icons";
import { useIntl } from "react-intl";

const NavBar = ({
  pathname,
  publishEvent,
  historyIndex,
  navigateBack,
  query,
}) => {
  const localePrefix = "messages.navBar.";
  const homePaths = [ROUTE_DASHBOARD];
  const identityPaths = [ROUTE_IDENTITY];
  const servicePaths = [ROUTE_SERVICES];
  const settingsPaths = [ROUTE_SETTINGS];
  const highlightTab = (paths) => {
    const result = paths.filter((path) => pathname.indexOf(path) === 0);
    return result.length > 0;
  };

  const onClickBack = (e) => {
    navigateBack({ steps: 1 });
  };

  const translate = useIntl();

  const historyFound = historyIndex > 0 || query.back;

  return (
    <Drawer>
      <DrawerButton
        title={translate.formatMessage({ id: "back" })}
        id="navbarButtonBack"
        onClick={onClickBack}
        disabled={!historyFound}
      >
        <ArrowBackRounded />
        <DrawerButtonLabel className="mx-12">
          <String id="back" />
        </DrawerButtonLabel>
      </DrawerButton>
      <DrawerMain style={{ marginTop: 0 }}>
        <CheckAction event={events.navbar.home}>
          <DrawerButton
            title={translate.formatMessage({ id: `${localePrefix}dashboard` })}
            id="navbarButtonHome"
            onClick={(e) => {
              publishEvent(events.navbar.home);
            }}
            active={highlightTab(homePaths)}
          >
            <Icon icon="home-outline" />
            <DrawerButtonLabel className="mx-12">
              <String id={`${localePrefix}home`} />
            </DrawerButtonLabel>
          </DrawerButton>
        </CheckAction>
        <CheckAction event={events.navbar.identity}>
          <DrawerButton
            title={translate.formatMessage({ id: `${localePrefix}dashboard` })}
            id="navbarButtonHome"
            onClick={(e) => {
              publishEvent(events.navbar.identity);
            }}
            active={highlightTab(identityPaths)}
          >
            <Icon icon="home-outline" />
            <DrawerButtonLabel className="mx-12">
              <String id={`${localePrefix}home`} />
            </DrawerButtonLabel>
          </DrawerButton>
        </CheckAction>
        <CheckFeature feature={[FEATURE_VPN, FEATURE_IDENTITY]}>
          <CheckAction event={events.navbar.services}>
            <DrawerButton
              title={translate.formatMessage({ id: `${localePrefix}services` })}
              active={highlightTab(servicePaths)}
              onClick={(e) => {
                publishEvent(events.navbar.services);
              }}
              className="px-6"
              id="navbarButtonServices"
            >
              <WidgetsOutlined />
              <DrawerButtonLabel className="mx-12">
                <String id={`${localePrefix}services`} />
              </DrawerButtonLabel>
            </DrawerButton>
          </CheckAction>
        </CheckFeature>
      </DrawerMain>
      <DrawerFooter>
        <CheckAction event={events.navbar.settings}>
          <DrawerButton
            title={translate.formatMessage({ id: `${localePrefix}settings` })}
            active={highlightTab(settingsPaths)}
            onClick={(e) => {
              publishEvent(events.navbar.settings);
            }}
            id="navbarButtonSettings"
          >
            <Icon icon="settings-atlas-outline" />
            <DrawerButtonLabel className="mx-12">
              <String id={`${localePrefix}settings`} />
            </DrawerButtonLabel>
          </DrawerButton>
        </CheckAction>
      </DrawerFooter>
    </Drawer>
  );
};

export default connect(
  (state) => ({
    ...state.router.location,
    historyIndex: state.context.historyIndex,
    query: state.router.location.query,
  }),
  { publishEvent, navigateBack }
)(NavBar);
