import {
  API_PPS,
  API_SECURITY_MGMT,
  LS_EULA_ACCEPTED,
  HTTP_POST,
  CACHE_NO_STORE,
  HTTP_PUT,
  SETTING_EULA_STATUS,
} from "../../constants/main";
import {
  eulaAcceptedBegin,
  eulaAcceptedSuccess,
  eulaAcceptedFailure,
} from "../context";
import {
  fetchJson,
  fetchUrl,
  getProperties,
  parseJson,
  storeErrorDetails,
  setProperties,
  stringifyJson,
} from "../../utils";
import { uwpGetSecurityToken, uwpLog } from "../../uwp";
import { getServiceUrl, saveSettings } from "./settings";

async function getEulaToken(csp_client_id, deviceGUID, dispatch) {
  //Caller will catch the error
  const body = stringifyJson({ csp_client_id });
  const cspSecurityToken = await uwpGetSecurityToken(deviceGUID, body);

  if (cspSecurityToken === null || cspSecurityToken.size === 0) {
    uwpLog("Failed to get cspSecurityToken");
    return null;
  }
  const apiBaseUrl = await dispatch(getServiceUrl(API_PPS));
  const { data: result } = await fetchJson(`${apiBaseUrl}/v1/eulatoken`, {
    method: HTTP_POST,
    cache: CACHE_NO_STORE,
    headers: {
      ak: cspSecurityToken.ak,
      cd: cspSecurityToken.cd,
      ac: cspSecurityToken.ac,
      st: cspSecurityToken.st,
      sv: cspSecurityToken.sv,
    },
    body,
  });

  const eulaToken = result.access_token;

  return eulaToken;
}

async function trackEulaAndPrivacyNoticeAcceptance({
  token,
  cspid,
  locale,
  aff_id,
  legalVersion,
  privacyVersion,
  dispatch,
}) {
  const {
    oobe_timestamp,
    oem_data_file: cdf,
    system_publisher_id: hardware_id = "0",
  } = await getProperties([
    "oobe_timestamp",
    "oem_data_file",
    "system_publisher_id",
  ]);
  const fetchBody = {
    context_type: "ClientId",
    action_type: "Accepted",
    acceptance_type: "Implicit",
    view_type: "Inline",
    application: "PPSUWPClient",
    context_type_value: cspid,
    culture: locale,
    aff_id: aff_id,
    pkg_id: "0",
    functionality: "Registration",
    context_additional_info: {
      EulaDate: "0",
      EulaFlowType: "0",
      EulaVersionContext: "0",
      HardwareId: hardware_id,
      InternetStatus: "0",
      IpAddress: "0",
      IsEulaCorrected: "0",
      MachineName: "0",
      SoftwareId: "0",
    },
    tracking_details: [
      {
        tracking_type: "Eula",
        version: legalVersion,
      },
    ],
  };
  //Add additional params for JEULA tracking only if cdf file is present and has jeula version and oobe_timestamp
  if (cdf) {
    //decode cdf file
    const decCdf = window.atob(cdf);
    const jeulaVersion = parseJson(decCdf).oem_provided.jeulaversion || null;
    if (oobe_timestamp && jeulaVersion) {
      fetchBody.context_additional_info.OobeTimestamp = oobe_timestamp;
      fetchBody.context_additional_info.JeulaVersion = jeulaVersion;
    }
  }

  const fetchOptions = {
    method: HTTP_PUT,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: stringifyJson(fetchBody),
  };
  const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT));
  await fetchUrl(`${apiBaseUrl}/eula/v1/track`, fetchOptions);
  fetchBody.tracking_details = [
    {
      tracking_type: "PrivacyPolicy",
      version: privacyVersion,
    },
  ];

  fetchOptions.body = stringifyJson(fetchBody);
  await fetchUrl(`${apiBaseUrl}/eula/v1/track`, fetchOptions);

  // this flag is never used, remove it in future release
  dispatch(saveSettings({ [SETTING_EULA_STATUS]: true }));
}

export const acceptEulaAndPrivacyNotice = () => async (dispatch, getState) => {
  try {
    const {
      auth: { accessToken },
      context: {
        cspid,
        locale,
        aff_id,
        legalVersion,
        privacyVersion,
        deviceGUID,
        eulaAccepted,
      },
    } = getState();
    if (eulaAccepted) {
      return;
    }
    dispatch(eulaAcceptedBegin());
    const token =
      accessToken || (await getEulaToken(cspid, deviceGUID, dispatch));
    trackEulaAndPrivacyNoticeAcceptance({
      token,
      cspid,
      locale,
      aff_id,
      legalVersion,
      privacyVersion,
      dispatch,
    });
    setProperties({ [LS_EULA_ACCEPTED]: true });
    dispatch(eulaAcceptedSuccess());
  } catch (e) {
    dispatch(eulaAcceptedFailure(storeErrorDetails(e)));
  }
};
