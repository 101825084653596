import React from "react";
import { Route, Switch, Redirect } from "react-router"; // react-router v4/v5
import { ConnectedRouter } from "connected-react-router";
import { history } from "../../store/history";
import {
  ROUTE_ACCOUNT,
  ROUTE_BREACH_DETAILS,
  ROUTE_DASHBOARD,
  ROUTE_DEVICES,
  ROUTE_DISCONNECT_WIFI,
  ROUTE_DISCONNECT_WIFI_DONE,
  ROUTE_HACK_BLOCK,
  ROUTE_IDENTITY,
  ROUTE_AV,
  ROUTE_IDENTITY_BREACH,
  ROUTE_SCAN_EMAIL_RESULT,
  ROUTE_OPEN_SOURCE_LICENSES,
  ROUTE_ROOT,
  ROUTE_SCAN_EMAIL,
  ROUTE_SECURE_VPN,
  ROUTE_SERVICES,
  ROUTE_SETTINGS_ABOUT,
  ROUTE_SETTINGS_NOTIFICATIONS,
  ROUTE_SETTINGS_VPN,
  ROUTE_SETTINGS_ANTIVIRUS,
  ROUTE_VPN_SETUP,
  ROUTE_VPN_SETUP_DISCLOSURE,
  ROUTE_VPN_SETUP_RUN,
  ROUTE_WELCOME,
  ROUTE_ACCOUNT_CREATION_COMPLETE,
  ROUTE_SETTINGS_ACCOUNT,
  ROUTE_SETTINGS_IDENTITY,
  ROUTE_SETTINGS_FEEDBACK,
  ROUTE_WELCOME_MATCH,
  ROUTE_WELCOME1,
  ROUTE_TRIAL_ACCOUNT_CREATION_COMPLETE,
  ROUTE_EXPIRED_SUBSCRIPTION,
  ROUTE_FAILURE,
  ROUTE_MONITOR_ASSET_DONE,
  ROUTE_AV_SCAN_RESULT,
  ROUTE_AV_SCAN_DETAILS,
  ROUTE_SETTINGS_AV_SCAN_RESULT,
  ROUTE_SETTINGS_AV_SCAN_DETAILS,
} from "./routes";

import {
  Root,
  Dashboard,
  OnboardWelcome,
  ScanEmail,
  CreateAccount,
  // OnboardComplete,
  Services,
  MyDevices,
  AboutSettingsPage,
  VPNSettingsPage,
  AntivirusSettingsPage,
  NotificationsSettingsPage,
  AccountSettingsPage,
  Identity,
  Antivirus,
  BreachDetails,
  VpnSetup,
  VpnSetupRun,
  VpnSetupDisclosure,
  SecureVpn,
  DisconnectWifiConfirm,
  DisconnectWifiEducate,
  OpenSourceLicensesPage,
  FeedbackSettingsPage,
  TrialAccountCreationComplete,
  ExpiredSubscription,
  MonitorAssetDone
} from "./pages";
import ScanEmailResult from "../IdentityBreachList/ScanEmailResult";
import DisconnectWifi from "../HackBlock/DisconnectWifi";
import AccountCreationComplete from "../Onboard/AccountCreationComplete";
import IdentitySettingsPage from "../Settings/IdentitySettingsPage";
import Failure from "../../pages/Failure/Failure";
import ScanResult from "../Antivirus/ScanResult";
import ScanDetails from "../Antivirus/ScanDetails";



export function AppRouter() {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path={ROUTE_ROOT} component={Root} />
        <Route
          exact
          path={ROUTE_WELCOME}
          render={() => <Redirect to={ROUTE_WELCOME1} />}
        />
        <Route exact path={ROUTE_WELCOME_MATCH} component={OnboardWelcome} />
        <Route exact path={ROUTE_SCAN_EMAIL} component={ScanEmail} />
        <Route exact path={ROUTE_ACCOUNT} component={CreateAccount} />
        <Route exact path={ROUTE_DASHBOARD} component={Dashboard} />
        <Route exact path={ROUTE_IDENTITY} component={Identity} />
        <Route exact path={ROUTE_AV} component={Antivirus} />        
        
        <Route exact path={ROUTE_BREACH_DETAILS} component={BreachDetails} />
        <Route
          exact
          path={ROUTE_IDENTITY_BREACH}
          render={() => <Redirect to={ROUTE_IDENTITY} />}
        />
        <Route
          exact
          path={ROUTE_SCAN_EMAIL_RESULT}
          component={ScanEmailResult}
        />
        <Route exact path={ROUTE_HACK_BLOCK} component={DisconnectWifi} />
        <Route
          exact
          path={ROUTE_DISCONNECT_WIFI}
          component={DisconnectWifiEducate}
        />
        <Route
          exact
          path={ROUTE_DISCONNECT_WIFI_DONE}
          component={DisconnectWifiConfirm}
        />
        <Route exact path={ROUTE_SERVICES} component={Services} />
        <Route exact path={ROUTE_DEVICES} component={MyDevices} />
        <Route
          exact
          path={ROUTE_OPEN_SOURCE_LICENSES}
          component={OpenSourceLicensesPage}
        />
        <Route
          exact
          path={ROUTE_SETTINGS_ABOUT}
          component={AboutSettingsPage}
        />
        <Route exact path={ROUTE_SETTINGS_VPN} component={VPNSettingsPage} />
        <Route exact path={ROUTE_SETTINGS_ANTIVIRUS} component={AntivirusSettingsPage} />
        <Route
          exact
          path={ROUTE_SETTINGS_NOTIFICATIONS}
          component={NotificationsSettingsPage}
        />
        <Route
          exact
          path={ROUTE_SETTINGS_IDENTITY}
          component={IdentitySettingsPage}
        />
        <Route
          exact
          path={ROUTE_SETTINGS_FEEDBACK}
          component={FeedbackSettingsPage}
        />
        <Route
          exact
          path={ROUTE_SETTINGS_ACCOUNT}
          component={AccountSettingsPage}
        />
        <Route exact path={ROUTE_VPN_SETUP} component={VpnSetup} />
        <Route exact path={ROUTE_VPN_SETUP_RUN} component={VpnSetupRun} />
        <Route
          exact
          path={ROUTE_VPN_SETUP_DISCLOSURE}
          component={VpnSetupDisclosure}
        />
        <Route exact path={ROUTE_SECURE_VPN} component={SecureVpn} />
        <Route exact path={ROUTE_FAILURE} component={Failure} />
        <Route
          exact
          path={ROUTE_ACCOUNT_CREATION_COMPLETE}
          component={AccountCreationComplete}
        />
        <Route
          exact
          path={ROUTE_EXPIRED_SUBSCRIPTION}
          component={ExpiredSubscription}
        />
        <Route
          exact
          path={ROUTE_TRIAL_ACCOUNT_CREATION_COMPLETE}
          component={TrialAccountCreationComplete}
        />
        <Route
          exact
          path={ROUTE_MONITOR_ASSET_DONE}
          component={MonitorAssetDone}
        />
        <Route
          exact
          path={ROUTE_AV_SCAN_RESULT}
          component={ScanResult}
        />
        <Route
          exact
          path={ROUTE_SETTINGS_AV_SCAN_DETAILS}
          component={ScanDetails}
        />
         <Route
          exact
          path={ROUTE_SETTINGS_AV_SCAN_RESULT}
          component={ScanResult}
        />
        <Route
          exact
          path={ROUTE_AV_SCAN_DETAILS}
          component={ScanDetails}
        />
        <Route  component={Dashboard} onEnter={() => console.log('Entered Invalid path')} />
      </Switch>
    </ConnectedRouter>
  );
}
