import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Paragraph, Button, Subheading, Label } from "@mcafee/pegasus";

import "./avScanFileDetailsPopup.scss";

export default function AvScanFileDetailsPopup({ 
  fileName, detectionTime, threatName, fileLocation,status,id, onDelete, onRestore, onContact,threat_resolution}) {
  const _S = (str) => `popups.scanFileDetailsPopup.${str}`;

  return (
    <div className="scanFileDetailsPopup">
      <Subheading
        className="scanFileDetailsPopup__title"
        size="lg"
        data-testid="scanFileDetailsPopupHeader"
      >
        {fileName}
      </Subheading>
      <Label className="scanFileDetailsPopup__label" size="sm"> <String id={_S(`foundOn`)} /> {detectionTime}</Label>
      <Subheading
        className="scanFileDetailsPopup__title"
        size="lsm"
        data-testid="scanFileDetailsPopupHeader"
      >
         <String id={_S(`threat`)} />
      </Subheading>
      <Paragraph size="sm" className="scanFileDetailsPopup__content">
        {threatName}
      </Paragraph>

      <Subheading
        className="scanFileDetailsPopup__title"
        size="lsm"
        data-testid="scanFileDetailsPopupHeader"
      >
         <String id={_S(`fileLocation`)} />
      </Subheading>
      <Paragraph size="sm" className="scanFileDetailsPopup__content">
        {fileLocation}
      </Paragraph>
      <Subheading
        className="scanFileDetailsPopup__title"
        size="lsm"
        data-testid="scanFileDetailsPopupHeader"
      >
         <String id={_S(`status`)} />
      </Subheading>
      <Paragraph size="sm" className="scanFileDetailsPopup__content">
        {status}
      </Paragraph>
      
      <div className="scanFileDetailsPopup__buttons">
        { threat_resolution === "quarantined" ? (
          <>
        <Button
          className="ml-32"
          onClick={() => onDelete(id)}
          size="sm"
          id="gotItScanEducate"
          data-testid="scanEducationButton"
        >
          <String id={_S(`delete`)} />
        </Button>
        <Button
          className="ml-32"
          onClick={() => onRestore(id)}
          size="sm"
          id="gotItScanEducate"
          data-testid="scanEducationButton"
          variant="secondary"
        >
          <String id={_S(`restore`)} />
        </Button>
         </>
        ):
        (
          <Button
            className="ml-32"
            onClick={() => onContact(id)}
            size="sm"
            id="gotItScanEducate"
            data-testid="scanEducationButton"
          >
             <String id={_S(`contactUs`)} />
          </Button>
          )
        }
      </div>
    </div>
  );
}
