// React
import React, { useEffect } from "react";
import { connect } from "react-redux";

// Store
import { fetchDeviceList } from "../../store/thunks/device";

// Custom components
import FeaturePageBg from "../Shared/FeaturePageBg";
import DevicesListDisplay from "./DevicesListDisplay";

// ToDo: have the devices displayed. Have plenty more to implement
const MyDevices = ({ accessToken, deviceList }) => {
  useEffect(() => {
    if (!deviceList.fetching) {
      fetchDeviceList(accessToken);
    }
  }, [accessToken, deviceList]);

  return (
    <FeaturePageBg title="myDevices_page_title">
      <DevicesListDisplay />
    </FeaturePageBg>
  );
};

export default connect(
  (state) => ({
    accessToken: state.auth.accessToken,
    deviceList: state.deviceList,
  }),
  { fetchDeviceList }
)(MyDevices);
