import { v4 as uuidv4 } from "uuid";
import {
  sendOtpBegin,
  sendOtpFailure,
  sendOtpSuccess,
  verifyOtpBegin,
  verifyOtpFailure,
  verifyOtpSuccess,
  resetOtp,
} from "../otp";
import {
  fetchJson,
  parseJson,
  storeErrorDetails,
  stringifyJson,
  urlToSource,
} from "../../utils";
import { refreshAccessToken } from "./auth";
import {
  API_SECURITY_MGMT,
  APP_ID,
  HTTP_POST,
  CACHE_NO_STORE,
  ERROR_OTP_LOCK_PERIOD_ACTIVE,
  HTTP_BAD_REQUEST,
  HTTP_EXPECTATION_NOT_MET,
} from "../../constants/main";
import { getServiceUrl } from "./settings";

/**
 * Send or resend OTP request for an email or phone number.
 * Successful return {transaction_key,resend_after_seconds}.
 * Error return {ok:false, error:{code,status,error,source}}
 * @param {{email?:string, phone_number?:string, flow?:string, scope?:string, trigger:string}} options
 * @returns {{error?, transaction_key, resend_after_seconds}}
 */
export const sendOtp =
  ({
    email,
    phone_number,
    flow = "asset addition",
    scope = "verified",
    trigger,
  }) =>
  async (dispatch, getState) => {
    let {
      context: { locale: culture, aff_id },
      otp: {
        transaction_key,
        sentEmail,
        sentPhoneNumber,
        resend_remaining,
        expiry_time,
        verify_attempts_remaining,
        resend_time,
      },
    } = getState();

    try {
      const now = Date.now();

      dispatch(sendOtpBegin({ email, phone_number }));

      const isResend =
        transaction_key !== null && //valid transaction key
        sentEmail === email && //same email
        sentPhoneNumber === phone_number && //same phoneNumber
        resend_remaining > 0 && //can still resend
        expiry_time > now && //resend not yet expired
        verify_attempts_remaining > 0; //can verify that same code

      const apiPath = `/OTP/v1/${isResend ? "resendotp" : "SendOtp"}`;

      const inCoolDown = resend_time !== null && now < resend_time;

      if (inCoolDown) {
        //In cool down period, prevent unneeded server trip
        //Return expected server response
        throw Object({
          source: urlToSource(apiPath, HTTP_POST),
          status: HTTP_BAD_REQUEST,
          code: ERROR_OTP_LOCK_PERIOD_ACTIVE,
          message: "Otp can be generated after the lock period is over", //TODO: use localized message
        });
      }

      const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT));

      if (!isResend) {
        //Brand new code, not resending the same code
        transaction_key = uuidv4();
      }

      const { data: sendOtpResults } = await fetchJson(
        `${apiBaseUrl}${apiPath}`,
        {
          method: HTTP_POST,
          cache: CACHE_NO_STORE,
          headers: {
            Authorization: () => dispatch(refreshAccessToken()),
          },
          body: stringifyJson({
            app_id: APP_ID,
            aff_id,
            email,
            phone_number,
            culture,
            transaction_key,
            scope,
            flow,
          }),
        }
      );

      //get transaction key returned from api and store it
      if (sendOtpResults.transaction_key) {
        dispatch(
          sendOtpSuccess({ ...sendOtpResults, trigger, email, isResend })
        );
        return sendOtpResults;
      }

      throw Object({
        status: HTTP_EXPECTATION_NOT_MET,
        code: HTTP_EXPECTATION_NOT_MET,
        message: "Missing transaction key",
        trigger,
      });
    } catch (e) {
      const error = { ...storeErrorDetails(e), email, phone_number, trigger };
      dispatch(sendOtpFailure(error));
      return { error };
    }
  };

export const verifyOtp =
  ({ otpInput, scope = "verified", flow = "asset addition", trigger }) =>
  async (dispatch, getState) => {
    dispatch(verifyOtpBegin());

    const {
      transaction_key,
      sentEmail: email,
      sentPhoneNumber: phone_number,
    } = getState().otp;
    const otp_code = Number(otpInput);

    try {
      const refreshedToken = await dispatch(refreshAccessToken());
      const { locale: culture, aff_id } = getState().context;
      const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT));

      const { data: results } = await fetchJson(
        `${apiBaseUrl}/OTP/v1/VerifyOtp`,
        {
          method: HTTP_POST,
          cache: CACHE_NO_STORE,
          headers: {
            Authorization: `Bearer ${refreshedToken}`,
          },
          body: stringifyJson({
            otp_code,
            app_id: APP_ID,
            aff_id,
            culture,
            email,
            phone_number,
            transaction_key,
            scope,
            flow,
          }),
        }
      );
      const { access_token, expires_in: timeToLive, message } = results;
      if (!access_token) {
        dispatch(
          verifyOtpFailure({
            message: message,
            otp_code,
            trigger,
          })
        );
      } else {
        setTimeout(() => {
          const { token } = getState().otp;
          if (access_token === token) {
            dispatch(resetOtp());
          }
        }, timeToLive * 1000);
        dispatch(
          verifyOtpSuccess({
            token: access_token,
            email,
            otp_code,
            trigger,
          })
        );
      }
    } catch (e) {
      const { message } = e;
      const error = parseJson(message);
      const { response } = error;
      dispatch(
        verifyOtpFailure({
          message: parseJson(response).message,
          email,
          otp_code,
          trigger,
        })
      );
    }
  };
