// React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Store
import { fetchDwsDashboard } from "../../store/thunks/identity";

// Custom components
import PersonalInfoAlerts from "../IdentityBreachList/PersonalInfoAlerts";

// utils

import { navToErrorPage, useIsSignedIn } from "../../utils";
import Unauthorized from "../Shared/Unauthorized";
import NavbarLayout from "../NavBar/NavbarLayout";
import { InPlaceProgress } from "../styledComponents/progress";
import ErrorMessage from "../Shared/ErrorMessage";
import { MAX_ERROR_RETRY } from "../../constants";

export default function Identity() {
  const [retryCount, setRetryCount] = useState(0);
  const { breaches, fetchingDashboard, fetchDashboardError } = useSelector(
    (state) => state.identity
  );
  const asset_public_id = useSelector(
    (state) => state.router.location.query.asset_public_id
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDwsDashboard({ hit_trigger: "active_breach_list" }));
  }, [dispatch]);

  useEffect(() => {
    if (fetchDashboardError && retryCount >= MAX_ERROR_RETRY) {
      navToErrorPage(fetchDashboardError);
    }
  }, [fetchDashboardError, retryCount]);

  const onRetry = () => {
    dispatch(fetchDwsDashboard({ hit_trigger: "active_breach_list" })).then(
      () => {
        setRetryCount(retryCount + 1);
      }
    );
  };

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  if (breaches !== null) {
    return <PersonalInfoAlerts {...{ breaches, asset_public_id }} />;
  }
  return (
    <NavbarLayout>
      {fetchingDashboard && (
        <div className="centerContent">
          <InPlaceProgress infinite noPercentage />
        </div>
      )}
      {fetchDashboardError && (
        <ErrorMessage
          {...{
            error: fetchDashboardError,
            onRetry,
          }}
        />
      )}
    </NavbarLayout>
  );
}
