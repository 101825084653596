import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading, Label } from "@mcafee/pegasus";

import { useSelector } from "react-redux";

import parsePhoneNumber from "libphonenumber-js";
import { DeleteForeverOutlined } from "@material-ui/icons";

export default function RemovePhoneNumberPopUp({ onFinish, onCancel }) {
  const localePrefix = "popups.";

  const { mobileNumber } = useSelector((state) => state.dashboard);

  const handleRemove = async (e) => {
    onFinish({ phone: mobileNumber });
  };

  const styles = {
    popUpStyles: {
      maxWidth: 550,
    },
    contentStyles: {
      textAlign: "left",
      display: "flex",
      justifyContent: "center",
    },
    errorStyles: {
      textAlign: "center",
    },
  };

  const phoneNumber = mobileNumber
    ? parsePhoneNumber(`+${mobileNumber}`)
    : null;

  return (
    <div style={styles.popUpStyles} className="pa-24 text-left">
      <Heading size="xs" data-testid="removePhoneNumberHeader">
        <String id={`${localePrefix}removePhoneNumber.header`} />
      </Heading>
      <div className="mt-12 mb-12">
        {phoneNumber && (
          <Label size="md" className="text-info">
            {phoneNumber.format("NATIONAL")}
          </Label>
        )}
      </div>

      <Paragraph
        style={styles.contentStyles}
        className="mt-8"
        size="sm"
        data-testid="removePhoneNumberContent"
      >
        <String id={`${localePrefix}removePhoneNumber.content`} />
      </Paragraph>

      <div className="mt-24 text-right">
        <Button
          className="ml-32"
          onClick={handleRemove}
          id="RemovePhoneNumber"
          data-testid="removePhoneNumber"
          size="sm"
          kind="danger"
        >
          <DeleteForeverOutlined />
          <String id={`${localePrefix}removePhoneNumber`} />
        </Button>
        <Button
          className="ml-32"
          variant="tertiary"
          onClick={onCancel}
          id="RemovePhoneNumberCancel"
          data-testid="removePhoneNumberCancelButton"
          size="sm"
        >
          <String id={`${localePrefix}removePhoneNumber.cancel`} />
        </Button>
      </div>
    </div>
  );
}
