// React
import React from "react";
import { connect } from "react-redux";

// DOM
import {
  Icon,
  Button,
  List,
  Heading,
  Label,
  Col,
  Row,
  Grid,
  Link,
  ListItem,
  Paragraph,
  Popover,
  PopoverContent,
  PopoverHandler,
  PopoverItem,
} from "@mcafee/pegasus";

// Store
import { addDevice } from "../../store/thunks/device";

// Custom components
import { Loader } from "../_generic/loader/Loader.js";
import { uwpLog } from "../../uwp";
import { ArrowForward } from "@material-ui/icons";

const DevicesListDisplay = ({
  accessToken,
  addNewDevice,
  deviceList,
  fetching,
}) => {
  const styles = {
    root: {
      background: "#ffffff",
      boxShadow:
        "0px 6px 12px rgba(55, 124, 251, 0.16), 0px -1px 4px rgba(55, 124, 251, 0.12)",
      borderRadius: 12,
    },
    headerWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  };

  const displayDetailedDevice = (deviceInfo) => {
    uwpLog("Detailed Device display triggered: ", deviceInfo);
  };

  //ToDo: Make the UI identical to Identity DarkWebScan functionality. Leaving it here as this is not in v0.1 scope
  return (
    <>
      <Grid fluid>
        <Heading size="lg" className="ml-10 pb-10">
          My devices
        </Heading>

        <Row style={{ height: "100%" }}>
          <Col sm={4} xs={12}>
            <div style={styles.root}>
              <div style={styles.headerWrapper} className="flex px-32 py-24">
                <Heading size="xs">My Devices</Heading>

                <Button variant="tertiary" icon>
                  <Icon
                    className="btn__icon"
                    icon="add-outline"
                    id="DevicesListAdd"
                  />
                </Button>
              </div>
              {deviceList.list.length <= 0 ? (
                <div style={{ textAlign: "center", paddingBottom: "24px" }}>
                  <Loader />
                </div>
              ) : (
                <List noBottom>
                  {deviceList.list.map((device) => (
                    <ListItem id={device.id} key={device.id}>
                      <div className="mx-16 flex justify-center direction-column">
                        <Paragraph className="my-auto" size="sm">
                          {device.name}
                        </Paragraph>
                        {device.os ? (
                          <Label size="xs" className="text-muted">
                            {device.os}
                          </Label>
                        ) : null}
                      </div>
                      <Popover className="ml-auto popover--right">
                        <PopoverHandler>
                          <Button
                            className="ml-auto text-tertiary"
                            variant="tertiary"
                            size="xs"
                            onClick={() => displayDetailedDevice(device)}
                          >
                            <ArrowForward
                              fontSize="large"
                              className="btn__icon"
                            />
                          </Button>
                        </PopoverHandler>
                        <PopoverContent className="monitored-emails-row-popover">
                          <PopoverItem onClick={displayDetailedDevice}>
                            <Link>ToDo</Link>
                            <ArrowForward
                              fontSize="large"
                              className="btn__icon"
                            />
                          </PopoverItem>
                        </PopoverContent>
                      </Popover>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          </Col>
          <div style={{ margin: 10 }}></div>
          <Col sm={7} xs={12}>
            <Label size="md">DETAILED LIST HERE</Label>
          </Col>
        </Row>
      </Grid>
    </>
  );
};

export default connect(
  (state) => ({
    accessToken: state.auth.accessToken,
    deviceList: state.deviceList,
  }),
  { addDevice }
)(DevicesListDisplay);
