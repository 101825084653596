// React
import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import backgroundImage from "../_assets/images/dashboard/cards/onboardDwsSkipped.svg";

// utils
import { RECOMMENDED } from "../../constants/main";
import { ROUTE_DASHBOARD, ROUTE_SCAN_EMAIL } from "../app/routes";

export default function OnboardDwsSkipped({ id }) {
  const dispatch = useDispatch();

  const onPrimaryAction = async () => {
    dispatch(
      push({
        pathname: ROUTE_SCAN_EMAIL,
        search: `back=${ROUTE_DASHBOARD}&hide_skip=true`,
      })
    );
  };

  const card = {
    id,
    backgroundImage,
    tagText: RECOMMENDED,
    primaryActionStringId: "confirmNow",
    onPrimaryAction,
    primaryActionDataTestId: "onboardDwsSkippedConfirmNow",
  };

  return <DashboardCard {...card} />;
}
