import { createSlice } from "@reduxjs/toolkit";
import { SORT_ALPHABETICAL, SORT_RECENT, SORT_RISK } from "../constants/main";
import { signOutSuccess } from "./auth";

const initialState = {
  fetchDashboardDone: false,
  fetchingDashboard: false,
  fetchDashboardError: null,
  assets: null,
  breaches: null,

  fetchingBreachDetails: false,
  fetchBreachDetailsError: null,
  breachDetails: null,

  addingAsset: false,
  addingAssetError: null,
  lastAddedAsset: null,

  remediating: false,
  remediatingError: null,
  lastRemediated: null,
  dispatchRemediatedToast: false,

  fetchingProductDefinitions: false,
  fetchingProductDefinitionsError: null,
  productDefinitions: null,

  currentSortMethod: "recent",

  // for showing the cloud and deciding whether to keep or remove the email based on OTP
  newAssetIncluded: false,
};

// Reducers
const slice = createSlice({
  name: "@identity",
  initialState,
  reducers: {
    stageAddNewEmail(state, action) {
      if (state.assets) {
        state.assets = [...state.assets, action.payload];
        state.newAssetIncluded = true;
      }
      // state.newAsset = action.payload.asset_value;
    },
    // cancelAddNewEmail(state) {
    //   if (state.assets && state.newAssetIncluded) {
    //     state.assets = state.assets.slice(0, -1);
    //     state.newAssetIncluded = false;
    //   }
    //   // state.newAsset = null;
    // },
    remediateBreachBegin(state) {
      state.remediating = true;
      state.remediatingError = null;
      state.lastRemediated = null;
    },
    remediateBreachFailure(state, action) {
      state.remediating = false;
      state.remediatingError = action.payload;
      state.lastRemediated = null;
    },
    remediateBreachSuccess(state, action) {
      state.remediating = false;
      state.remediatingError = null;
      state.lastRemediated = action.payload;
    },

    remediateBreachReset(state) {
      state.remediating = false;
      state.remediatingError = null;
      state.lastRemediated = null;
    },

    fetchDashboardBegin(state) {
      state.fetchDashboardDone = false;
      state.fetchingDashboard = true;
      state.fetchDashboardError = null;
    },
    fetchDashboardFailure(state, action) {
      state.fetchingDashboard = false;
      const { cached } = action.payload;
      if (!cached) {
        state.fetchDashboardError = action.payload;
        //   state.assets = null;
        //   state.breaches = null;
      }
      state.fetchDashboardDone = true;
    },
    fetchDashboardSuccess(state, action) {
      state.fetchDashboardError = null;
      const { cached = false, assets = {}, breach_history } = action.payload;
      state.assets = assets || [];
      state.breaches = breach_history || [];
      if (!cached) {
        state.fetchingDashboard = false;
        state.fetchDashboardDone = true;
      }
    },
    sortIdentityBreaches(state, action) {
      if (state.breaches === null) {
        return;
      }

      if (
        ![SORT_ALPHABETICAL, SORT_RECENT, SORT_RISK].includes(action.payload)
      ) {
        //invalid sort type
        return;
      }

      const sortMethods = {
        [SORT_ALPHABETICAL]: (a, b) => {
          return a.title.localeCompare(b.title);
        },
        [SORT_RECENT]: (a, b) => {
          const a_date =
            a.public_date ||
            a.breach_date ||
            a.breach_publish_date ||
            a.acquisition_date;
          const b_date =
            b.public_date ||
            b.breach_date ||
            b.breach_publish_date ||
            b.acquisition_date;
          const aTime = new Date(a_date).getTime();
          const bTime = new Date(b_date).getTime();
          return Number(bTime) - Number(aTime);
        },
        [SORT_RISK]: (a, b) => {
          return (b.severity || 0) - (a.severity || 0);
        },
      };

      state.currentSortMethod = action.payload;
      state.breaches = state.breaches.sort(sortMethods[action.payload]);
    },
    fetchBreachDetailsBegin(state, action) {
      const breach_ref_id = action.payload;
      state.fetchingBreachDetails = true;
      state.fetchBreachDetailsError = null;
      if (
        state.breachDetails &&
        breach_ref_id !== state.breachDetails.breach_ref_id
      ) {
        state.breachDetails = null;
      }
    },
    fetchBreachDetailsFailure(state, action) {
      state.fetchingBreachDetails = false;
      const { cached } = action.payload;
      if (!cached) {
        state.fetchBreachDetailsError = action.payload;
      }
    },
    fetchBreachDetailsSuccess(state, action) {
      const { breachDetails, cached } = action.payload;
      if (!cached) {
        state.fetchingBreachDetails = false;
      }
      state.fetchBreachDetailsError = null;
      state.breachDetails = breachDetails;
    },
    // Add asset
    addAssetBegin(state) {
      state.addingAsset = true;
      state.addingAssetError = null;
      state.lastAddedAsset = null;
    },
    addAssetFailure(state, action) {
      state.addingAsset = false;
      state.addingAssetError = action.payload;
      state.lastAddedAsset = null;
    },
    addAssetSuccess(state, action) {
      state.addingAsset = false;
      state.addingAssetError = null;
      state.lastAddedAsset = action.payload;
    },
    addAssetCancel: () => {},
    // Remove asset
    removeAssetBegin(state) {
      state.removingAsset = true;
      state.removingAssetError = null;
      state.lastRemovedAsset = null;
    },
    removeAssetFailure(state, action) {
      state.removingAsset = false;
      state.removingAssetError = action.payload;
      state.lastRemovedAsset = null;
    },
    removeAssetSuccess(state, action) {
      state.removingAsset = false;
      state.removingAssetError = null;
      state.lastRemovedAsset = action.payload.email;
    },
    // product definitions
  },
  extraReducers: {
    [signOutSuccess]: (state) => {
      return initialState;
    },
  },
});

export const {
  addAssetBegin,
  addAssetFailure,
  addAssetSuccess,
  addAssetCancel,

  fetchDashboardBegin,
  fetchDashboardFailure,
  fetchDashboardSuccess,

  sortIdentityBreaches,

  fetchBreachDetailsBegin,
  fetchBreachDetailsFailure,
  fetchBreachDetailsSuccess,

  remediateBreachReset,
  remediateBreachBegin,
  remediateBreachSuccess,
  remediateBreachFailure,

  // Delete asset action
  removeAssetBegin,
  removeAssetFailure,
  removeAssetSuccess,

  stageAddNewEmail,
  cancelAddNewEmail,
} = slice.actions;

export default slice.reducer;
