import {
  AMOUNT_OF_DAYS_TO_SHOW_REMINDER,
  RANGE_OF_DANGER_DAYS_BIGGEST,
  RANGE_OF_DANGER_DAYS_SMALLEST,
} from "../constants";

/**
 * Inspect subscription status and return if it's expired
 * @param {string} subscriptionStatus
 * @returns {boolean}
 */
export const isSubscriptionExpired = (subscriptionStatus) => {
  if (typeof subscriptionStatus === "string") {
    return subscriptionStatus.toLowerCase().includes("expired");
  }
  //returning undefined, indicates unknown status
};

/**
 * Inspect subscription status and return if it's paid active
 * @param {string} subscriptionStatus
 * @returns {boolean}
 */
export const isSubscriptionPaidActive = (subscriptionStatus = "") => {
  return subscriptionStatus && subscriptionStatus === "paid-active";
};

/**
 * Inspect subscription status and return if it's a trial active
 * @param {string} subscriptionStatus
 * @returns {boolean}
 */
export const isTrialActive = (subscriptionStatus = "") => {
  return subscriptionStatus && subscriptionStatus === "trial-active";
};

/**
 * Inspect subscription status and return if it's active, no matter if it is a subscription/trial or extend period
 * @param {string} subscriptionStatus
 * @returns {boolean}
 */
export const isSubscriptionActive = (subscriptionStatus = "") => {
  return subscriptionStatus && subscriptionStatus.includes("active");
};

/**
 * Inspect subscription status and return if it's a subscription.
 * types considered to be a subscription:
 * paid-active, paid-expired, extend-expiry-active, extend-expiry-expired;
 * @param {string} subscriptionStatus
 * @returns {boolean}
 */
export const isSubscription = (subscriptionStatus = "") => {
  return (
    subscriptionStatus &&
    (subscriptionStatus.includes("paid") ||
      subscriptionStatus.includes("extend"))
  );
};

/**
 * Inspect subscriptionStatus and return a simplified value
 * @param {string} subscriptionStatus
 * @returns {string} free, trial or subscription
 */
export const getSubscriptionStatus = (subscriptionStatus) => {
  let tempSubscriptionStatus;

  switch (subscriptionStatus) {
    case "free":
      tempSubscriptionStatus = "free";
      break;
    case "trial-active":
    case "trial-expired":
      tempSubscriptionStatus = "trial";
      break;
    default:
      // default cases: paid-active, paid-expired, extend-expiry-active, extend-expiry-expired;
      tempSubscriptionStatus = "subscription";
  }

  return tempSubscriptionStatus;
};

/**
 * Inspect if it's the last day of a valid subscription/trial
 * @param {number} daysToExpiry
 * @returns {boolean} boolean
 */
export const isSubscriptionOnItsLastDay = (daysToExpiry) => {
  return daysToExpiry === RANGE_OF_DANGER_DAYS_SMALLEST;
};

/**
 * Inspect the amount of days for a subscription/trial to end and return if it's 5 days or less;
 * @param {number} daysToExpiry
 * @returns {boolean}
 */
export const isSubscriptionOnItsLast5Days = (daysToExpiry) => {
  return daysToExpiry <= RANGE_OF_DANGER_DAYS_BIGGEST;
};

/**
 * Inspect the amount of days for a subscription/trial to end and return if it's between 2 to 5 days;
 * @param {number} daysToExpiry
 * @returns {boolean}
 */
export const isSubscriptionWithinDangerRange = (daysToExpiry) => {
  return (
    daysToExpiry > RANGE_OF_DANGER_DAYS_SMALLEST &&
    daysToExpiry <= RANGE_OF_DANGER_DAYS_BIGGEST
  );
};

/**
 * Inspect if the expiryDate and the length of the subscription are different, meaning that the countdown for expiration has started
 * @param {number} daysToExpiry
 * @param {number} subscriptionLength
 * @returns {boolean}
 */
export const hasCountdownForExpiryStarted = (
  daysToExpiry,
  subscriptionLength
) => {
  return daysToExpiry !== subscriptionLength;
};

export const calculateCountdown = (daysToExpiry) => {
  return (daysToExpiry * 100) / AMOUNT_OF_DAYS_TO_SHOW_REMINDER;
};

export const inPreExpiryState = (days_to_expiry, popup_last_time_shown) => {
  // less then 7 to expiry, and shown more then 48 h ago
  return (
    days_to_expiry <= 7 &&
    days_to_expiry >= 0 &&
    Date.now() - popup_last_time_shown > 48 * 3600 * 1000
  );
};

/**
 * Inspect the amount of remaining days of a subscription and returns the amount of days left OR 0 if it is expired already.
 * @param {object} store
 * @returns {number}
 */
export const getSubscriptionRemainingDays = (store) => {
  const { daysToExpiry } = store.subscription;
  return daysToExpiry > 0 ? daysToExpiry : 0;
};

/**
 * Inspect the sub_status returned by API and return subscriptionType
 * @param {string} subscriptionStatus
 * @returns {string}
 */
export const getSubscriptionType = (subscriptionStatus) => {
  const subscriptionType = subscriptionStatus
    ? subscriptionStatus.toLowerCase().split("-")[0]
    : "N/A";
  return subscriptionType;
};

/**
 * Inspect the sub_status returned by API and return formatted value
 * @param {string} sub_status
 * @returns {string}
 */
export const getProductSubscriptionStatus = (sub_status) => {
  const subscriptionStatus =
    getSubscriptionType(sub_status) === "free"
      ? "active"
      : sub_status
      ? sub_status.toLowerCase().split("-").pop()
      : "N/A";
  return subscriptionStatus;
};
