import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Paragraph, Heading, Button } from "@mcafee/pegasus";
import scanEducationImage from "../_assets/images/shared/womanWithLaptop.svg";
import "./avScanEducationPopup.scss";

export default function AvScanEducationPopup({ onCancel }) {
  const _S = (str) => `popups.scanEducatePopup.${str}`;

  return (
    <div className="scanEducationPopup">
      <img
        src={scanEducationImage}
        alt=""
        className="scanEducationPopup__image"
      />

      <Heading
        className="scanEducationPopup__title"
        size="xs"
        data-testid="scanEducationPopUpHeader"
      >
        <String id={_S`header`} />
      </Heading>

      <div data-testid="scanEducationPopUpContent">
        <Paragraph size="sm" className="scanEducationPopup__content">
          <String id={_S`content1`}/><br/> <br/>
          <String id={_S`content2`}/><br/> <br/>
          <String id={_S`content3`}/>
        </Paragraph>
      </div>
      <div className="scanEducationPopup__buttons">
        <Button
          className="ml-32"
          onClick={onCancel}
          size="sm"
          id="gotItScanEducate"
          data-testid="scanEducationButton"
        >
          <String id={_S`gotIt`} />
        </Button>
      </div>
    </div>
  );
}
