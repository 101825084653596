// React
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

// Store
import { showPopup } from "../../store/thunks/popups";

// DOM
import FingerprintIcon from "../_assets/images/settings/fingerprintIcon.svg";
import MyAccountIcon from "../_assets/images/settings/myAccountIcon.svg";
import VPNIcon from "../_assets/images/settings/vpnIcon.svg";
import "./settings.scss";
import {
  AccordionGroup,
  Accordion,
  AccordionContent,
  AccordionHandler,
  Grid,
  Row,
  Col,
  Subheading,
} from "@mcafee/pegasus";

// utils
import { loadSettings } from "../../store/thunks/settings";
import {
  FEATURE_IDENTITY,
  FEATURE_VPN,
  SETTING_VPN_NOTIFY_DEVICE,
  SETTING_GENERAL_NOTIFY_EMAIL,
  SETTING_GENERAL_NOTIFY_DEVICE,
  SETTING_IDENTITY_NOTIFY_EMAIL,
  SETTING_IDENTITY_NOTIFY_DEVICE,
  SETTINGS,
} from "../../constants/main";
import { String } from "../../l10n/loader";
import { POPUP_NOTIFICATIONS } from "../PopUps/constants";

// Custom components
import NavbarLayout from "../NavBar/NavbarLayout";
import SettingsMenu from "./SettingsMenu";
import NotificationSettingsCard from "./NotificationSettingsCard";
import CheckFeature from "../Shared/CheckFeature";
import { useIsSignedIn } from "../../utils";
import Unauthorized from "../Shared/Unauthorized";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

const NotificationsSettings = ({
  loadSettings,
  notifications_account_email,
  notifications_account_device,
  notifications_identity_email,
  notifications_identity_device,
  notifications_vpn_device,
}) => {
  const localePrefix = "messages.notifications.";
  const notificationCards = [
    {
      feature: null,
      text: "myAccount",
      image: MyAccountIcon,
      emailNotificationId: SETTING_GENERAL_NOTIFY_EMAIL,
      deviceNotificationId: SETTING_GENERAL_NOTIFY_DEVICE,
      emailNotificationState: notifications_account_email,
      deviceNotificationState: notifications_account_device,
    },
    {
      feature: FEATURE_VPN,
      text: "myVPN",
      image: VPNIcon,
      deviceNotificationId: SETTING_VPN_NOTIFY_DEVICE,
      deviceNotificationState: notifications_vpn_device,
    },
    {
      feature: FEATURE_IDENTITY,
      text: "myIdentity",
      image: FingerprintIcon,
      emailNotificationId: SETTING_IDENTITY_NOTIFY_EMAIL,
      deviceNotificationId: SETTING_IDENTITY_NOTIFY_DEVICE,
      emailNotificationState: notifications_identity_email,
      deviceNotificationState: notifications_identity_device,
    },
  ];

  const [activeID, setActiveID] = useState("0");
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchSettings() {
      const notificationSettings = await loadSettings([
        SETTING_GENERAL_NOTIFY_EMAIL,
        SETTING_GENERAL_NOTIFY_DEVICE,
        SETTING_IDENTITY_NOTIFY_EMAIL,
        SETTING_IDENTITY_NOTIFY_DEVICE,
        SETTING_VPN_NOTIFY_DEVICE,
      ]);
      const show = Object.keys(notificationSettings).some(
        (key) => notificationSettings[key] === true
      );
      if (!show) {
        dispatch(
          showPopup(POPUP_NOTIFICATIONS, {
            source: SETTINGS,
          })
        );
      }
    }
    fetchSettings();
  }, [dispatch, loadSettings]);

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }
  const handleClick = (id) => {
    setActiveID(activeID === id ? "" : id);
  };

  return (
    <NavbarLayout
      id="Settings"
      className="pa-0"
      classNameLayout="pa-0 bg-white"
    >
      <Grid>
        <Row style={{ height: "100%" }}>
          <Col sm xs={12} className="flex">
            <SettingsMenu />
          </Col>
          <Col
            sm={8}
            xs={12}
            className="flex direction-column justify-content-start bg-white pa-24 grow-1"
          >
            <Subheading size="lg" className="mb-24">
              <String id={`${localePrefix}title`} />
            </Subheading>

            <AccordionGroup activeID={activeID}>
              {notificationCards.map((card, index) => (
                <Accordion
                  id={index + 1}
                  className="mb-12 pa-24"
                  style={{ border: "1px solid #E6E9F0", borderRadius: "8px" }}
                  key={index}
                >
                  <CheckFeature feature={card.feature}>
                    <AccordionHandler
                      onClick={handleClick}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <NotificationSettingsCard
                        key={`notificationCard${index}`}
                        type={"handler"}
                        activeID={activeID}
                        {...card}
                      />
                      {activeID === index + 1 ? (
                        <KeyboardArrowUp className="text-info mr-16" />
                      ) : (
                        <KeyboardArrowDown className="text-info mr-16" />
                      )}
                    </AccordionHandler>

                    <AccordionContent>
                      <NotificationSettingsCard
                        key={`notificationCard${index}`}
                        type={"content"}
                        {...card}
                      />
                    </AccordionContent>
                  </CheckFeature>
                </Accordion>
              ))}
            </AccordionGroup>
          </Col>
        </Row>
      </Grid>
    </NavbarLayout>
  );
};

export default connect(
  (state) => ({
    notifications_account_email: state.settings.notifications_account_email,
    notifications_account_device: state.settings.notifications_account_device,
    notifications_identity_email: state.settings.notifications_identity_email,
    notifications_identity_device: state.settings.notifications_identity_device,
    notifications_vpn_device: state.settings.notifications_vpn_device,
  }),
  { loadSettings }
)(NotificationsSettings);
