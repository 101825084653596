// React
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Store
import { fetchBreachDetails } from "../../store/thunks/identity";

// Custom components

import BreachView from "./BreachView";

import { InPlaceProgress } from "../styledComponents/progress";
import { navToErrorPage, useIsSignedIn } from "../../utils";
import Unauthorized from "../Shared/Unauthorized";
import ErrorMessage from "../Shared/ErrorMessage";
import { String } from "../../l10n/loader";
import { MAX_ERROR_RETRY } from "../../constants";

//Identity->PersonalInfo->Breach details--fetches the breach details
const BreachDetails = ({
  //passed props
  breachId,
  updateContent,
  //...state.identity
  breachDetails,
  fetchBreachDetailsError,
  //dispatches
  fetchBreachDetails,
}) => {
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (breachId) {
      fetchBreachDetails({ breach_ref_id: breachId });
    }
  }, [breachId, fetchBreachDetails]);

  useEffect(() => {
    if (fetchBreachDetailsError && retryCount >= MAX_ERROR_RETRY) {
      navToErrorPage(fetchBreachDetailsError);
    }
  }, [fetchBreachDetailsError, retryCount]);

  const onRetry = (e) => {
    fetchBreachDetails({ breach_ref_id: breachId }).then(() => {
      setRetryCount(retryCount + 1);
    });
  };

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  if (!breachDetails && !fetchBreachDetailsError) {
    return <InPlaceProgress infinite noPercentage />;
  }

  if (fetchBreachDetailsError) {
    return (
      <div>
        <ErrorMessage
          {...{
            headerLabel: <String id="messages.error.tryAgain" />,
            error: fetchBreachDetailsError,
            onRetry,
          }}
        />
      </div>
    );
  }

  return <BreachView id={breachId} updateContent={updateContent} />;
};

export default connect(
  (state) => ({
    breachDetails: state.identity.breachDetails,
    fetchBreachDetailsError: state.identity.fetchBreachDetailsError,
  }),
  { fetchBreachDetails }
)(BreachDetails);
