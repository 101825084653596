import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetching: false,
  adding: false,
  errorFetching: null,
  errorAdding: null,
  info: {
    id: null,
    name: null,
    type: null,
    platform: null,
    osName: null,
    browser: null,
  },
  devices: null,
};

const slice = createSlice({
  name: "@devices",
  initialState,
  reducers: {
    fetchDeviceBegin: (state) => {
      state.fetching = true;
      state.errorFetching = null;
    },
    fetchDeviceSuccess: (state, action) => {
      state.info = action.payload;
      state.fetching = false;
      state.errorFetching = null;
    },
    fetchDeviceFailure: (state, action) => {
      state.fetching = false;
      state.errorFetching = action.payload;
    },
    addDeviceBegin: (state) => {
      state.adding = true;
      state.errorAdding = null;
    },
    addDeviceSuccess: (state, action) => {
      state.adding = false;
      state.errorAdding = action.payload;
    },
    addDeviceFailure: (state, action) => {
      state.info = action.payload;
      state.adding = false;
      state.errorAdding = null;
    },
  },
});

export const {
  fetchDeviceBegin,
  fetchDeviceSuccess,
  fetchDeviceFailure,
  addDeviceBegin,
  addDeviceSuccess,
  addDeviceFailure,
} = slice.actions;

export default slice.reducer;
