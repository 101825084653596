// React
import React, { useState, useEffect } from "react";
import { String } from "../../l10n/loader";

// DOM
import {
  Avatar,
  Paragraph,
  Grid,
  Row,
  Col,
  Emphasis,
  List,
  ListItem,
  Subheading,
} from "@mcafee/pegasus";

// Custom components
import { siteAvatarStyles } from "../../utils";
import { REMEDIATION_ACTED } from "../../constants/main";
import { ROUTE_MONITOR_ASSET_DONE } from "../app/routes";

export default function BreachRow({
  title,
  site,
  remediation_status,
  password_available,
  email,
  readonly = false,
  onClick,
  pageId,
}) {
  const [isUrgent, setIsUrgent] = useState(false);
  const _S = (str) => `identity.${str}`;

  useEffect(() => {
    if (remediation_status !== REMEDIATION_ACTED && password_available) {
      setIsUrgent(true);
    }
  }, [remediation_status, password_available]);
  const breachList = () => {
    return (
      <div className="flex grow-1 justify-content-between">
        <div className="flex justify-content-start">
          <Avatar
            kind="image"
            shape="square"
            style={siteAvatarStyles({ site })}
          ></Avatar>
          <div className="mx-12">
            <Subheading className="my-auto">{title}</Subheading>
            <Grid fluid>
              <Row>
                {isUrgent && pageId !== ROUTE_MONITOR_ASSET_DONE && (
                  <>
                    <Col>
                      <Paragraph
                        size="sm"
                        className="text-danger"
                        data-testid="BreachRowUrgentText"
                      >
                        <String id={_S`urgent`} />
                      </Paragraph>
                    </Col>
                    <Col className="px-8">
                      <Paragraph size="sm" data-testid="BreachRowSeparator">
                        <String id={_S`separator`} />
                      </Paragraph>
                    </Col>
                  </>
                )}
                <Col>
                  <Paragraph size="sm" data-testid="BreachRowEmail">
                    {email}
                  </Paragraph>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </div>
    );
  };
  if (pageId === ROUTE_MONITOR_ASSET_DONE) {
    return (
      <List>
        <ListItem>{breachList()}</ListItem>
      </List>
    );
  } else
    return (
      <Emphasis onClick={readonly ? null : onClick} className="my-8">
        {breachList()}
      </Emphasis>
    );
}
