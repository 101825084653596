//TODO: unused ( Only referenced by MyDevices component)
import React from "react";
import { String } from "../../l10n/loader";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { Button, Icon, Heading, Grid, Row, Col } from "@mcafee/pegasus";
import { createFormattedId } from "../../utils";
import { publishEvent } from "../../store/thunks/experience";
import events from "../../experience/events";

const FeaturePageBg = ({
  back: backEvent,
  replace,
  title,
  back_title,
  children,
  query,
  showButton = true,
  // greyBg = true,
  publishEvent,
}) => {
  const onClickBack = (e) => {
    if (backEvent) {
      publishEvent(backEvent);
    } else {
      publishEvent(events.root.home);
    }
  };

  return (
    <div className={`featurePageBg`.trim()}>
      <Grid fluid>
        <Row>
          <Col sm={12} xs={12}>
            <div className="flex align-items-center justify-content-stretch">
              {showButton && (
                <Button
                  variant="tertiary"
                  onClick={onClickBack}
                  id={`${createFormattedId(title)}GoBack`}
                  size="sm"
                >
                  <Icon icon="chevron-left-outline" />
                  <String id="goBack" />
                </Button>
              )}
              {back_title ? (
                <span className="grow-1 text-center">
                  <String id={back_title} />
                </span>
              ) : null}
            </div>

            <Heading className="pb-24 px-12">
              <String id={title} />
            </Heading>
          </Col>
        </Row>
        {children}
      </Grid>
    </div>
  );
};

export default connect((state) => ({ query: state.router.location.query }), {
  replace,
  publishEvent,
})(FeaturePageBg);
