import { createSlice } from "@reduxjs/toolkit";
import {
  UA_ONBOARDING_BREACHES_FOUND,
  UA_ONBOARDING_EMAIL_ENROLLED,
  UA_ONBOARDING_SCAN_DONE,
  UA_WHAT_APP_CAN_DO_FOR_YOU_SHOWN,
} from "../constants/main";

const initialState = {
  signingIn: false,
  signInCancelled: false,
  signingOut: false,
  error: null,
  fetchingAccessToken: false,
  accessToken: null,
  idToken: null,
  email: null,
  session: null,
  newUser: false,
  userActions: {
    [UA_ONBOARDING_BREACHES_FOUND]: null,
    [UA_ONBOARDING_EMAIL_ENROLLED]: null,
    [UA_ONBOARDING_SCAN_DONE]: null,
    [UA_WHAT_APP_CAN_DO_FOR_YOU_SHOWN]: null,
  },
};

const saveTokenValues = (state, action) => {
  const { accessToken, idToken, session, email } = action.payload;
  state.accessToken = accessToken;
  state.idToken = idToken;
  if (email) {
    state.email = email;
  }
  if (session) {
    state.session = session;
  }
};

const slice = createSlice({
  name: "@auth",
  initialState,
  reducers: {
    signInBegin: (state, action) => {
      state.error = null;
      state.signingIn = true;
      state.signInCancelled = false;
    },
    signInSuccess: (state, action) => {
      state.signingIn = false;
      state.fetchingAccessToken = false;
      if (!state.signInCancelled) {
        state.newUser = action.payload.newUser;
        saveTokenValues(state, action);
      }
    },
    signInFailure: (state, action) => {
      state.signingIn = false;
      state.fetchingAccessToken = false;
      state.error = action.payload;
      state.signInCancelled = false;
    },
    signInCancel: (state) => {
      state.signingIn = false;
      state.signInCancelled = true;
    },
    signOutBegin: (state) => {
      state.signingOut = true;
    },
    signOutSuccess: (state) => {
      state.signingOut = false;
      state.email = null;
      state.accessToken = null;
      state.idToken = null;
      state.session = null;
      state.error = null;
      state.userActions = { ...initialState.userActions };
    },
    signOutFailure: (state, action) => {
      state.signingOut = false;
      state.error = action.payload;
    },
    signOutError: (state, action) => {
      state.signingOut = false;
      state.error = action.payload;
      state.email = null;
      state.accessToken = null;
      state.idToken = null;
      state.session = null;
    },
    signOutCancel: (state) => {
      state.signingOut = false;
    },
    autoSignInBegin: (state) => {
      state.signingIn = true;
      state.error = null;
    },
    accessTokenBegin: (state, action) => {
      state.fetchingAccessToken = true;
      state.newUser = action.payload.newUser;
      state.error = null;
    },
    refreshAccessTokenBegin: (state) => {
      state.fetchingAccessToken = true;
      state.error = null;
    },
    refreshAccessTokenSuccess: (state, action) => {
      state.fetchingAccessToken = false;
      saveTokenValues(state, action);
    },
    refreshAccessTokenFailure: (state, action) => {
      state.fetchingAccessToken = false;
      state.error = action.payload;
    },
    refreshAccessTokenError: (state, action) => {
      state.fetchingAccessToken = false;
      state.error = action.payload;
    },
    updateUserActions: (state, action) => {
      state.userActions = { ...state.userActions, ...action.payload };
      Object.keys(state.userActions).forEach((k) => {
        if (state.userActions[k] === null) {
          state.userActions[k] = false;
        }
      });
    },
    resetError: (state) => {
      state.error = null;
    },
  },
});

export const {
  autoSignInBegin,
  signInBegin,
  signInSuccess,
  signInFailure,
  signInCancel,
  signOutBegin,
  signOutSuccess,
  signOutFailure,
  signOutError,
  signOutCancel,
  accessTokenBegin,
  refreshAccessTokenBegin,
  refreshAccessTokenSuccess,
  refreshAccessTokenFailure,
  refreshAccessTokenError,
  updateUserActions,
  resetError,
} = slice.actions;

export default slice.reducer;
