//ROUTE_FAILURE component
//Supported query string params: source, code, content, back_steps, error
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { String } from "../../l10n/loader";
import { MCAFEE_SUPPORT_URL } from "../../constants/main";
import errorSetup from "../../components/_assets/images/shared/errorSetup.svg";

// DOM
import { Paragraph, Button, Heading, Link } from "@mcafee/pegasus";
import { decodeQueryParams, sendErrorScreenEvent } from "../../utils";
import { navigateBack } from "../../store/thunks/app";

export default function Failure() {
  //check if content is passed in the query string, it has higher priority
  //Some error cases requires going back more than one step to avoid being trapped in the same error
  //For those error pages, page caller could set back_steps query string value to more than one
  const {
    content = "default",
    source = "pps",
    code = "0",
    back_steps = "1",
    message,
    traceId,
  } = useSelector((state) => decodeQueryParams(state.router.location.query));

  const _S = (str) => `failure.${content}.${str}`;

  const onSupportClick = () => {
    window.open(MCAFEE_SUPPORT_URL, "_blank");
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (source) {
      sendErrorScreenEvent({ source, code: `${source}(${code})` });
    }
  }, [source, code]);

  const goBack = () => {
    const steps = parseInt(back_steps);
    dispatch(navigateBack({ steps }));
  };

  return (
    <div className="centerContent" style={{ height: "100vh" }}>
      <div className="slider__container">
        <img src={errorSetup} alt="ErrorSetup_Image" style={{ height: 300 }} />
      </div>

      <Heading className="mb-16" data-testid="failureHeader">
        <String id={_S`header`} />
      </Heading>

      <Paragraph size="md" className="mb-16" data-testid="failureContent1">
        <String
          id={_S`content_part1`}
          values={{
            link: (
              <Link onClick={onSupportClick} id="LogoutFailureSupport">
                <String id={_S`support`} />
              </Link>
            ),
          }}
        />
      </Paragraph>
      <Paragraph
        size="md"
        className="text-center"
        data-testid="failureContent2"
      >
        <String
          id={_S`content_part2`}
          values={{
            code: (
              <strong>
                <br />
                {`${source}_${code}`}
              </strong>
            ),
          }}
        />
        {traceId && <div>Trace ID: {traceId}</div>}
      </Paragraph>
      <div className="mt-24">
        <Button
          onClick={goBack}
          id="LogoutFailureGoBack"
          data-testid="failureGoBack"
          size="sm"
        >
          <String id={_S`goBack`} />
        </Button>
      </div>
      {message && (
        <Paragraph size="md" className="mt-16">
          <String id="failure.errorText" values={{ message }} />
        </Paragraph>
      )}
    </div>
  );
}
