import React from "react";
import { String } from "../../l10n/loader";

import { Heading, Label, Paragraph } from "@mcafee/pegasus";

import "./virtualLocationPopup.scss";

export default function VirtualLocationPopup({ current_country, locale }) {
  const _S = (str) => `vpn.location.${str}`;

  var countries = require("i18n-iso-countries");
  countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));

  const getCountryName = () => {
    var language = locale ? locale.split("-")[0] : "en";
    var countries = require("i18n-iso-countries");
    countries.registerLocale(
      require(`i18n-iso-countries/langs/${language}.json`)
    );

    return countries.getName(current_country, language, { select: "official" });
  };

  return (
    <div className="virtualLocationPopup">
      <Heading size="xs" className="virtualLocationPopup__title">
        <String id={_S`yourLocationIsHidden`} />
      </Heading>
      <Paragraph size="sm" className="virtualLocationPopup__subtitle">
        <String id={_S`others`} />
      </Paragraph>
      <div className="virtualLocationPopup__locationContainer">
        <Label size="sm" className="text-gray-light">
          <String id={_S`yourVirtualLocation`} />
        </Label>
        <Label size="md" className="virtualLocationPopup__countryName">
          {getCountryName()}
        </Label>
        {/* TODO: uncomment this part once location functionality is implemented
        <Label size="sm" className="virtualLocationPopup__question">
          <String id={_S`havingTroubleTitle`} />
        </Label>
        <Paragraph size="sm" className="text-tertiary">
          <String id={_S`havingTroubleContent`} />
        </Paragraph> */}
      </div>
      <Label size="sm" className="virtualLocationPopup__question">
        <String id={_S`virtualLocationTitle`} />
      </Label>
      <Paragraph size="sm" className="text-tertiary">
        <String id={_S`virtualLocationContent`} />
      </Paragraph>
    </div>
  );
}
