import React from "react";

// DOM
import { Subheading } from "@mcafee/pegasus";
import "./Services.scss";

// Custom components
import NavbarLayout from "../NavBar/NavbarLayout";
import {
  FEATURE_IDENTITY,
  FEATURE_VPN,
  FEATURE_AV,
} from "../../constants/main";
import IdentityServiceCard from "./IdentityServiceCard";
import AntivirusServiceCard from "./AntivirusServiceCard";
import VpnServiceCard from "./VpnServiceCard";
import { String } from "../../l10n/loader";
import { useIsSignedIn } from "../../utils";
import Unauthorized from "../Shared/Unauthorized";
import CheckAction from "../Shared/CheckAction";
import events from "../../experience/events";

export default function Services() {
  const _S = (str) => `services.${str}`;

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  const renderServiceCard = (feature) => {
    switch (feature) {
      case FEATURE_IDENTITY:
        return <IdentityServiceCard />;
      case FEATURE_VPN:
        return <VpnServiceCard />;
       case FEATURE_AV:
           return <AntivirusServiceCard />;
      default:
        return null;
    }
  };

  const serviceList = [
    { feature: FEATURE_IDENTITY, event: events.services.identity },
    { feature: FEATURE_VPN, event: events.services.vpn },
     { feature: FEATURE_AV, event: events.services.av },
  ];

  return (
    <NavbarLayout id="Services">
      <Subheading size="lg" className="ma-4">
        <String id={_S`title`} />
      </Subheading>
      <div className="mx-auto" style={{ maxWidth: 624 }}>
      
        {serviceList.map(({ feature, event }) => (
          <CheckAction event={event} key={feature}>
            {renderServiceCard(feature)}
          </CheckAction>
        ))}
      </div>
    </NavbarLayout>
  );
}
