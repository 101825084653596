import React from "react";

import WaveLayout from "../Shared/WaveLayout";
// import { Brand } from "@mcafee/pegasus";
import { InPlaceProgress } from "../styledComponents/progress";

const MainLoader = () => {
  const styles = {
    layout: {
      background: "#212934",
      height: "100vh",
    },
    logo: {
      transform: "scale3d(1.6, 1.6, 1.6)",
    },
  };

  return (
    <WaveLayout style={styles.layout}>
      <div className="ma-auto text-center">
        <div className="bg-primary-inv pa-16" style={styles.logo}>
          {/* <Brand kind="light" variant="portrait" /> */}
          <InPlaceProgress infinite noPercentage />
        </div>
      </div>
    </WaveLayout>
  );
};

export default MainLoader;
