import styled from "styled-components";

const StyledCardContent = styled.div`
  flex-basis: 400px;
`;
const StyledCardBody = styled.div`
  min-height: 212px !important;
  max-width: 640px !important;
  margin: 24px auto 0 auto;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 80px;
  }
  &.header-card {
    margin-top: 60px;
    min-height: 35px !important;
    padding: 10px 20px !important;
    color: #5a6175;
    font-weight: 600;
  }
`;

export { StyledCardContent, StyledCardBody };
