import { EVENTID_PPS_REMEDIATION_COMPLETE } from "../../constants/analytics";
import { uwpSendTelemetry } from "../../uwp";

import {
  analyticsHeaders,
  fetchJson,
  fetchUrl,
  fetchJsonCacheFirst,
  stringifyJson,
  newGUID,
  syncedInvoke,
  storeErrorDetails,
  urlToSource,
  delay,
} from "../../../src/utils";

// Helpers
import {
  API_VAULT,
  API_IDS_HISTORY,
  API_IDS_DASHBOARD,
  APP_ID,
  HTTP_DELETE,
  CACHE_NO_STORE,
  HTTP_POST,
  HTTP_GET,
  REMEDIATION_ACTED,
  REMEDIATE_PASSWORD,
  MTX_IDS,
  MTX_BREACH,
  HTTP_OK,
  HTTP_EXPECTATION_NOT_MET,
  DELAY_FETCH_DWS_DASHBOARD,
} from "../../constants/main";
// Actions
import {
  fetchBreachDetailsBegin,
  fetchBreachDetailsFailure,
  fetchBreachDetailsSuccess,
  remediateBreachBegin,
  remediateBreachFailure,
  remediateBreachSuccess,
  removeAssetBegin,
  removeAssetFailure,
  removeAssetSuccess,
  fetchDashboardSuccess,
  fetchDashboardFailure,
  fetchDashboardBegin,
} from "../identity";

import { refreshAccessToken } from "./auth";

// Reducers
import { addAssetBegin, addAssetSuccess, addAssetFailure } from "../identity";
import { getServiceUrl } from "./settings";

/**
 * Synced fetching cached then live Identity dashboard API
 * Update state.identity values
 * return API result or {error}
 * @param {{hit_trigger:string}} options options
 * @returns {error?}
 */
export const fetchDwsDashboard =
  ({ disabledCache = false, hit_trigger = "dashboard" } = {}) =>
  async (dispatch, getState) => {
    return syncedInvoke(
      { mutex: MTX_IDS, reuseActiveCallResult: true },
      async () => {
        dispatch(fetchDashboardBegin());
        let cachedFound = false;
        try {
          const apiBaseUrl = await dispatch(getServiceUrl(API_IDS_DASHBOARD));

          const fetchingCacheFirst = await fetchJsonCacheFirst(
            `${apiBaseUrl}/v1/dashboard/dwsmonitor?appid=${APP_ID}`,
            {
              method: HTTP_GET,
              headers: {
                Authorization: () =>
                  dispatch(refreshAccessToken()).then(
                    (accessToken) => `Bearer ${accessToken}`
                  ),
                ...analyticsHeaders(getState(), hit_trigger),
              },
            }
          );

          const handleResponse = (response) => {
            const { data: results, cached = false } = response;

            if (results) {
              dispatch(
                fetchDashboardSuccess({
                  cached,
                  assets: results.assets ? results.assets.assets : [],
                  breach_history: results.breach_history,
                })
              );
            } else if (!cachedFound) {
              dispatch(fetchDashboardFailure(storeErrorDetails(response)));
            }
          };

          const { value: cachedResponse } = await fetchingCacheFirst.next();

          if (!disabledCache && cachedResponse) {
            const { data: cachedResults } = cachedResponse;
            cachedFound = true;
            handleResponse(cachedResponse);

            //Using .then() to skip waiting for for live response
            fetchingCacheFirst
              .next()
              .then(({ value: liveResponse }) => handleResponse(liveResponse))
              .catch((e) => {
                dispatch(
                  fetchDashboardFailure({
                    ...storeErrorDetails(e),
                    cached: true,
                  })
                );
              });

            return cachedResults;
          } else {
            //Cached data not found, wait for live response
            const liveResponse = await (await fetchingCacheFirst.next()).value;

            handleResponse(liveResponse);

            return liveResponse.data;
          }
        } catch (e) {
          const error = storeErrorDetails(e);
          dispatch(
            fetchDashboardFailure({
              ...error,
              cached: cachedFound,
            })
          );
          return { error };
        }
      }
    );
  };

/**
 * Synced fetching cached then live BreachDetails API
 * Update state.identity values
 * returned value not used, callers watches state.identity changes and errors
 * @param {{breach_ref_id, hit_trigger, screen_details}} param0
 * @returns {undefined}
 */
export const fetchBreachDetails =
  ({ breach_ref_id, hit_trigger = "active_breach_list", screen_details }) =>
  async (dispatch, getState) => {
    return syncedInvoke(
      { mutex: MTX_BREACH, reuseActiveCallResult: true },
      async () => {
        let cachedFound = false;

        dispatch(fetchBreachDetailsBegin(breach_ref_id));

        try {
          const apiPath = `/breach/v1/BreachDetails`;

          //Make sure breach history is loaded
          let { breaches } = getState().identity;
          if (!breaches) {
            const dwsDashboardResults = await dispatch(
              fetchDwsDashboard({ hit_trigger: "active_breach_list" })
            );
            if (dwsDashboardResults) {
              breaches = dwsDashboardResults.breach_history;
            }
          }

          const breachSummary = Array.isArray(breaches)
            ? breaches.find((breach) => breach.breach_id === breach_ref_id)
            : null;

          if (!breachSummary) {
            throw Object({
              status: HTTP_EXPECTATION_NOT_MET,
              message: "Breach summary not found",
              source: urlToSource(apiPath, HTTP_POST),
            });
          }

          const { email, asset_public_id, status } = breachSummary;

          const otp = getState().otp;

          let otpToken;
          let transaction_id, cache;

          if (otp.token && email === otp.verifiedEmail) {
            otpToken = otp.token;
            transaction_id = otp.transaction_key;
            cache = CACHE_NO_STORE;
          }

          const apiBaseUrl = await dispatch(getServiceUrl(API_IDS_HISTORY));

          const fetchingCacheFirst = await fetchJsonCacheFirst(
            `${apiBaseUrl}${apiPath}`,
            {
              method: HTTP_POST,
              cache,
              headers: {
                Authorization: otpToken
                  ? `Bearer ${otpToken}`
                  : () =>
                      dispatch(refreshAccessToken()).then(
                        (accessToken) => `Bearer ${accessToken}`
                      ),
                ...analyticsHeaders(getState(), hit_trigger),
              },
              body: stringifyJson({
                breach_ref_id,
                asset_public_id,
                transaction_id,
              }),
            }
          );

          const handleResponse = ({ data: results, cached = false }) => {
            if (results && results.breach_info && results.breach_info.length) {
              const breachDetails = results.breach_info[0];
              breachDetails.email_address = email;
              breachDetails.asset_public_id = asset_public_id;
              breachDetails.status = status;
              dispatch(
                fetchBreachDetailsSuccess({
                  breachDetails,
                  cached,
                  screen_details,
                })
              );
            }
          };

          const { value: cachedResponse } = await fetchingCacheFirst.next();
          if (cachedResponse) {
            cachedFound = true;

            handleResponse(cachedResponse);

            //Using .then() to skip waiting for for live response
            fetchingCacheFirst
              .next()
              .then(({ value: liveResponse }) => {
                handleResponse(liveResponse);
              })
              .catch((e) => {
                dispatch(
                  fetchBreachDetailsFailure({
                    ...storeErrorDetails(e),
                    cached: true,
                  })
                );
              });
          } else {
            const liveResponse = await (await fetchingCacheFirst.next()).value;
            if (liveResponse) {
              handleResponse(liveResponse);
            }
          }
        } catch (e) {
          dispatch(
            fetchBreachDetailsFailure({
              ...storeErrorDetails(e),
              cached: cachedFound,
            })
          );
        }
      }
    );
  };

/**
 * Removing monitored asset
 * @param {{email, asset_value}} param0
 * @returns {ok:boolean, error?}
 */
export const removeAsset =
  ({ email, asset_value }) =>
  async (dispatch, getState) => {
    dispatch(removeAssetBegin(email));
    try {
      const apiBaseUrl = await dispatch(getServiceUrl(API_VAULT));

      const response = await fetchUrl(
        `${apiBaseUrl}/v1/vaults/idm/assets/${email}`,
        {
          method: HTTP_DELETE,
          headers: {
            Authorization: () =>
              dispatch(refreshAccessToken()).then(
                (accessToken) => `Bearer ${accessToken}`
              ),
            ...analyticsHeaders(getState()),
          },
        }
      );

      if (response.ok) {
        await delay(DELAY_FETCH_DWS_DASHBOARD); //Give some time for aws functions to remove the asset's data
        dispatch(removeAssetSuccess({ email, asset_value }));
        return { ok: true };
      }
    } catch (e) {
      dispatch(
        removeAssetFailure({ ...storeErrorDetails(e), email, asset_value })
      );
    }

    return { ok: false, error: getState().identity.removingAssetError };
  };

/**
 *
 * @param {*} param0
 * @returns {error?,ok?}
 */
export const addNewAsset =
  ({ otpToken = null, email }) =>
  async (dispatch) => {
    dispatch(addAssetBegin());
    try {
      const accessToken = await dispatch(refreshAccessToken());

      const apiBaseUrl = await dispatch(getServiceUrl(API_VAULT));
      const response = await fetchJson(`${apiBaseUrl}/v1/vaults/idm/assets`, {
        method: HTTP_POST,
        cache: CACHE_NO_STORE,
        headers: {
          Authorization: `Bearer ${otpToken || accessToken}`,
          //...analyticsHeaders(getState(), hit_trigger),
        },
        body: stringifyJson({
          value: email,
          meta: {
            type: "email",
            dwsMonitor: otpToken !== null ? "enabled" : "disabled",
            ownerValidated: otpToken !== null,
          },
        }),
      });

      await delay(DELAY_FETCH_DWS_DASHBOARD); //Give some time for aws functions to import the asset's data
      dispatch(addAssetSuccess(email));
      return response;
    } catch (e) {
      const error = { ...storeErrorDetails(e), email };
      dispatch(addAssetFailure(error));
      return { error };
    }
  };

export const remediateBreach =
  ({
    breach_ref_id,
    asset_public_id,
    breachType,
    remediation_status = REMEDIATION_ACTED,
    remediation_attribute = REMEDIATE_PASSWORD,
  }) =>
  async (dispatch, getState) => {
    dispatch(remediateBreachBegin());

    let response = "",
      successFailReason = "";

    try {
      const apiBaseUrl = await dispatch(getServiceUrl(API_IDS_HISTORY));

      const { data: result } = await fetchJson(
        `${apiBaseUrl}/remediation/v2/remediate`,
        {
          method: HTTP_POST,
          cache: CACHE_NO_STORE,
          headers: {
            Authorization: () =>
              dispatch(refreshAccessToken()).then(
                (accessToken) => `Bearer ${accessToken}`
              ),
            ...analyticsHeaders(getState()),
          },
          body: stringifyJson({
            breach_ref_id,
            asset_public_id,
            remediation_attribute,
            remediation_status,
          }),
        }
      );

      if (result.response_header.response_code === HTTP_OK) {
        dispatch(remediateBreachSuccess(breach_ref_id));
        response = "success";
        successFailReason = "success";
      } else {
        throw Object(result.response_header);
      }

      dispatch(fetchDwsDashboard({ hit_trigger: "remediated_breach_list" }));
      return response;
      // await dispatch(fetchBreachDetails({ breach_ref_id }));
    } catch (e) {
      const errInfo = storeErrorDetails(e);
      dispatch(remediateBreachFailure(errInfo));
      response = errInfo;
      successFailReason = errInfo.message;
    } finally {
      uwpSendTelemetry(EVENTID_PPS_REMEDIATION_COMPLETE, {
        hit_trigger: "inapp",
        hit_label_0: response,
        hit_label_2: successFailReason,
        hit_label_3: "id protection",
        hit_label_4: "1",
        hit_guid: newGUID(),
      });
    }
    return response;
  };
