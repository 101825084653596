/**
 * Utility functions
 * Please avoid dispatching Redux actions from any of these methods
 * Only calculate and return values.
 * Urls fetching helpers are ok
 * localStorage or settings writing is ok
 */
import { uwpLog } from "../uwp";
import {
  ENV_DEV,
  ENV_PROD,
  EMPTY_VAL,
  TYPE_STRING,
  TYPE_UNDEFINED,
  EMPTY_JSON,
  LOG_LEVEL_ERROR,
  EVN_STAGING,
} from "../constants/main";

export const emailFromIdToken = (idToken) => {
  const { email } = jwtPayload(idToken);
  return email;
};

export const formatStringRemoveSpaces = (value) => {
  return typeof value === TYPE_STRING ? value.split(" ").join("_") : "";
};

export const isProduction = () => {
  return [ENV_PROD, EVN_STAGING].includes(detectEnvironment());
};

export const isDevMode = () => {
  return detectEnvironment() === ENV_DEV;
};

export const detectEnvironment = () => {
  if (
    window.location.host.match(/^localhost/) ||
    window.location.host.match(/^github/)
  ) {
    return ENV_DEV;
  }

  var matchEnv = window.location.host.match(
    /\.(dev|qa|alpha|staging|stg|prod)\./
  );
  if (matchEnv) {
    return matchEnv[1];
  }
  return ENV_PROD;
};

export const validateToken = (accessToken) => {
  const { exp } = jwtPayload(accessToken);
  // const fiveMinutes = 60 * (isDevMode() ? 5 : 5); //change isDevMode value from 5 to 59 to force refresh token every minute
  const fiveMinutes = 60 * 5;
  //Check if expiry time is less than 5 minutes away
  return (exp - fiveMinutes) * 1000 > Date.now();
};

export const siteAvatarStyles = ({ width = 48, site = "unknown" }) => {
  const url = `/site_icons/unknown.svg`;

  return {
    height: width,
    width,
    minWidth: width,
    maxWidth: width,
    backgroundImage: `url(${url})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    objectFit: "cover",
  };
};

export const jwtPayload = (jwt) => {
  const parts = typeof jwt === TYPE_STRING ? jwt.split(".") : [];
  const payload = parts.length > 1 ? atob(parts[1]) : EMPTY_JSON;
  return parseJson(payload);
};

export const parseJson = (json, fallback = {}) => {
  try {
    if (typeof json === TYPE_STRING) {
      return JSON.parse(json);
    } else {
      uwpLog(`Invalid JSON: ${json}`, LOG_LEVEL_ERROR);
    }
  } catch (e) {
    uwpLog(e.message, LOG_LEVEL_ERROR);
  }
  return fallback;
};

export const stringifyJson = (obj, fallback = EMPTY_VAL) => {
  if (typeof obj === TYPE_STRING) {
    return obj;
  }

  if (typeof obj === TYPE_UNDEFINED) {
    return fallback;
  }

  try {
    return JSON.stringify(obj);
  } catch (e) {
    // circular references found
    uwpLog(e.message, LOG_LEVEL_ERROR);
    return fallback;
  }
};

export const createQueryString = (obj) => {
  return Object.keys(obj)
    .filter((k) => typeof obj[k] !== "undefined")
    .map((k) => `${k}=${encodeURIComponent(obj[k])}`)
    .join("&");
};

export const createFailureQueryString = (errorInfo) => {
  const { code, source, message, content, back_steps, traceId } = errorInfo;
  return createQueryString({
    code,
    source,
    content,
    back_steps,
    traceId,
    message: isProduction() ? undefined : message,
  });
};

export const decodeQueryParams = (query) => {
  return Object.keys(query).reduce((result, key) => {
    result[key] = decodeURIComponent(query[key]);
    return result;
  }, {});
};

export const createFormattedId = (str) => {
  const words = str.split(/[_.]/);
  const camelCasedWords = words.map((w) => {
    if (w) return w[0].toUpperCase() + w.slice(1);
    else return "";
  });
  return camelCasedWords.join("");
};

export const delay = (ms) => {
  return new Promise((r) => setTimeout(r, ms));
};

export const delayedResult = async (result, ms = 1000) => {
  await delay(ms);
  return result;
};

export const dispatchDomEvent = (eventName, eventData) => {
  var e = document.createEvent("UIEvent");
  e.initEvent(eventName, true, true);
  e.data = eventData;
  window.dispatchEvent(e);
};

export const createSalt = () => {
  return Math.floor(Math.random() * 100000).toString();
};

export const objectNodeValue = (obj, nodePath) => {
  if (Array.isArray(nodePath)) {
    return nodePath.map((n) => objectNodeValue(obj, n));
  }

  if (typeof nodePath !== TYPE_STRING) {
    return;
  }

  return (function readNode(leaf, path) {
    const k = path.shift();
    if (!path.length) {
      return leaf[k];
    }
    return readNode(leaf[k], path);
  })(obj, nodePath.split("."));
};

/**
 * Wait for an expression to become true
 * @param {function} callback expression to validate
 * @returns {Promise}
 */
export const waitFor = async (callback) => {
  while (!callback()) {
    await delay(500);
  }
};

export const num2string = (num, padding = 2) => {
  const str = `${num}`;
  const zeros = padding - str.length;
  const prefix = zeros > 0 ? new Array(zeros).fill("0").join("") : "";
  return prefix + str;
};

/**
 * Scroll the designated element to the top if found
 * @param {string} rootSelector scroll-able element css selector
 */
export const scrollTop = (rootSelector = ".navbarLayoutContent") => {
  const layout = document.querySelector(rootSelector);
  if (layout) {
    layout.scrollTop = 0;
  }
};

export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export const isUndefined = (val) => {
  return typeof val === 'undefined';
}

export const isNullOrUndefined = (val) => {
  return isUndefined(val) || val === null;
}