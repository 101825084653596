import React, { useEffect } from "react";
import { String } from "../../l10n/loader";

// DOM
import {
  Card,
  Link,
  Paragraph,
  Button,
  ProgressGauge,
  Label,
} from "@mcafee/pegasus";

import "./subscriptionBar.scss";

import { connect, useDispatch } from "react-redux";
import { showPopup } from "../../store/thunks/popups";
import { POPUP_SYNC_SUBSCRIPTION } from "../PopUps/constants";
import {
  collectScreenLoadEvent,
  calculateCountdown,
  hasCountdownForExpiryStarted,
  isSubscriptionPaidActive,
  isSubscriptionOnItsLast5Days,
  isSubscriptionOnItsLastDay,
  isSubscriptionWithinDangerRange,
  subscriptionInitiatedEvent,
} from "../../utils";
import {
  ANALYTICS_DASHBOARD,
  ANALYTICS_HOME,
  ANALYTICS_MANUAL,
  ANALYTICS_PROTECTION,
  FEATURE_SUBSCRIPTION,
  HIT_CATEGORY,
  HIT_FEATURE,
  HIT_SCREEN,
  HIT_TRIGGER,
  SCREEN_DASHBOARD_SUBSCRIPTION_PAID_STATE,
  SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_COUNTER_STATE,
  SCREEN_DETAILS,
  SCREEN_TYPE,
} from "../../constants";

import { ThumbUpAltRounded, SyncRounded } from "@material-ui/icons";

const SubscriptionBar = ({
  subscriptionStatus,
  daysToExpiry,
  subscriptionLength,
  renewUrl,
  onDismiss,
}) => {
  const dispatch = useDispatch();
  const _S = (str) => `dashboard.subscriptionBar.${str}`;

  useEffect(() => {
    if (isSubscriptionPaidActive(subscriptionStatus)) {
      setTimeout(() => {
        onDismiss();
      }, 5000);
    }
  }, [onDismiss, subscriptionStatus]);

  useEffect(() => {
    if (isSubscriptionPaidActive(subscriptionStatus)) {
      collectScreenLoadEvent({
        [HIT_FEATURE]: FEATURE_SUBSCRIPTION,
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_TRIGGER]: ANALYTICS_MANUAL,
        [HIT_SCREEN]: SCREEN_DASHBOARD_SUBSCRIPTION_PAID_STATE,
        [SCREEN_TYPE]: ANALYTICS_DASHBOARD,
        [SCREEN_DETAILS]: "subscription_paid",
      });
    } else {
      collectScreenLoadEvent({
        [HIT_FEATURE]: FEATURE_SUBSCRIPTION,
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_TRIGGER]: ANALYTICS_MANUAL,
        [HIT_SCREEN]: SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_COUNTER_STATE,
        [SCREEN_TYPE]: ANALYTICS_DASHBOARD,
        [SCREEN_DETAILS]: "trial_status_counter_state",
      });
    }
  }, [subscriptionStatus]);

  const onSubscribeNow = () => {
    subscriptionInitiatedEvent(
      SCREEN_DASHBOARD_SUBSCRIPTION_TRIAL_COUNTER_STATE,
      ANALYTICS_HOME
    );
    window.open(renewUrl, "_blank");
  };

  const onSyncSubscription = () => {
    dispatch(showPopup(POPUP_SYNC_SUBSCRIPTION));
  };

  const _getClassName = () => {
    let tempClassName = null;

    if (!isSubscriptionPaidActive(subscriptionStatus)) {
      if (isSubscriptionOnItsLastDay(daysToExpiry)) {
        tempClassName = "lastDay";
      } else if (isSubscriptionWithinDangerRange(daysToExpiry)) {
        tempClassName = "danger";
      } else if (
        hasCountdownForExpiryStarted(daysToExpiry, subscriptionLength)
      ) {
        tempClassName = "countdown";
      }
    }

    return `subscriptionBar__counter${
      tempClassName ? `--${tempClassName}` : ""
    }`.trim();
  };

  return (
    <Card className="subscriptionBar">
      <div className="subscriptionBar__container">
        <div className={_getClassName()}>
          {isSubscriptionPaidActive(subscriptionStatus) ? (
            <ThumbUpAltRounded className="text-white" />
          ) : (
            <>
              {hasCountdownForExpiryStarted(daysToExpiry, subscriptionLength) &&
                !isSubscriptionOnItsLastDay(daysToExpiry) && (
                  <div className="subscriptionBar__progressGaugeContainer">
                    <ProgressGauge
                      value={calculateCountdown(daysToExpiry)}
                      kind={
                        isSubscriptionOnItsLast5Days(daysToExpiry)
                          ? "danger"
                          : "normal"
                      }
                      noPercentage
                    />
                  </div>
                )}

              <Label size="sm">{daysToExpiry}</Label>
            </>
          )}
        </div>
        <Paragraph size="sm">
          {/* TODO: update this string for other languages */}
          <String
            id={_S(
              isSubscriptionPaidActive(subscriptionStatus) ? "success" : "days"
            )}
            values={{ s: daysToExpiry !== 1 ? "s" : "" }}
          />
        </Paragraph>
      </div>

      {isSubscriptionPaidActive(subscriptionStatus) ? (
        <Paragraph size="sm">
          <Link onClick={onDismiss} id="TrialSubscriptionDismiss">
            <String id={_S`dismiss`}></String>
          </Link>
        </Paragraph>
      ) : (
        <div className="subscriptionBar__container">
          <Paragraph size="sm" className="subscriptionBar__subscribeNow">
            <Link
              onClick={onSubscribeNow}
              className={
                !isSubscriptionOnItsLast5Days(daysToExpiry)
                  ? "text-info"
                  : "text-danger"
              }
              id="TrialSubscribeNow"
            >
              <String id={_S`subscribeNow`}></String>
            </Link>
          </Paragraph>
          <Button
            onClick={onSyncSubscription}
            title="Sync subscription"
            variant="tertiary"
            size="sm"
            id="TrialSubscriptionSync"
            icon
          >
            <SyncRounded className="subscriptionBar__syncButtonIcon" />
          </Button>
        </div>
      )}
    </Card>
  );
};
export default connect(
  (state) => ({
    ...state.subscription,
    query: state.router.location.query,
  }), //mapStateToProps
  {}
)(SubscriptionBar);
