import {
  CARD_ACCOUNT_BREACHED,
  CARD_OPEN_WIFI_ALERT,
  CARD_ADD_VPN_RECOMMENDED,
  CARD_VPN_LEARN_MORE,
  CARD_UNSAFE_WIFI_ALERT,
  CARD_ADD_NOTIFICATION_PERMISSION,
  CARD_PASSWORD_CHANGE,
  CARD_PROTECT_NEW_DEVICE,
  CARD_NAME_THIS_DEVICE,
  CARD_IDENTITY_MONITOR,
  CARD_MONITOR_NEW_EMAIL,
  CARD_ONBOARD_DWS_SKIPPED,
  CARD_MISSING_PHONE_NUMBER,
  CARD_BREACH_SMS_NOTIFICATION,
  CARD_BREACH_LEARN_MORE,
  CARD_EMAIL_VERIFICATION_BREACHES_FOUND,
  CARD_EMAIL_VERIFICATION_NO_BREACHES_FOUND,
  CARD_PPS_SOFTWARE_OUTDATED,
  CARD_AV_PROTECTING_YOU,
  CARD_AV_TURN_ON_RTS,
  CARD_AV_FOUND_THREATS
  // SETTING_VPN_CONNECT_MANUAL,
} from "./constants";

import AccountBreached from "./AccountBreached";
import MonitorNewEmail from "./MonitorNewEmail";
import AddMobilePhone from "./AddMobilePhone";
import BreachLearnMore from "./BreachLearnMore";
import PasswordChange from "./PasswordChange";
import OnboardDwsSkipped from "./OnboardDwsSkipped";
import IdentityMonitor from "./IdentityMonitor";
import ProtectNewDevice from "./ProtectNewDevice";
import OpenWifiAlert from "./OpenWifiAlert";
import AddVpnRecommended from "./AddVpnRecommended";
import VpnLearnMore from "./VpnLearnMore";
import UnsafeWifiAlert from "./UnsafeWifiAlert";
import AddNotificationPermission from "./AddNotificationPermission";
import AddDevice from "../addDevice/AddDevice";
import EmailVerificationBreachesFound from "./EmailVerificationBreachesFound";
import EmailVerificationNoBreachesFound from "./EmailVerificationNoBreachesFound";
import PpsSoftwareOutdated from "./PpsSoftwareOutdated";
import AvIsProtectingYou from "./AvIsProtectingYou";
import AvFoundThreats from "./AvFoundThreats";
import AvTurnOnRTS from "./AvTurnOnRTS";

import {
  ANALYTICS_ENGAGEMENT,
  ANALYTICS_ID_PROTECTION,
  ANALYTICS_LIFE_CYCLE,
  ANALYTICS_MANUAL,
  ANALYTICS_PROTECTION,
  ANALYTICS_VPN,
  FEATURE_MESSAGING,
} from "../../constants/analytics";
import { store } from "../../store/store";

export const cardsMap = {
  [CARD_ACCOUNT_BREACHED]: AccountBreached, //Your personal info was taken
  [CARD_UNSAFE_WIFI_ALERT]: UnsafeWifiAlert, //Disconnect now - this Wi-Fi is unsafe
  [CARD_OPEN_WIFI_ALERT]: OpenWifiAlert, //Careful, This {WiFi} network has weak security
  [CARD_ADD_VPN_RECOMMENDED]: AddVpnRecommended, //This {WiFi} is secure, but...
  [CARD_VPN_LEARN_MORE]: VpnLearnMore, //Why do hackers love public Wi-Fi?
  [CARD_ADD_NOTIFICATION_PERMISSION]: AddNotificationPermission, //We have important things to tell you
  [CARD_PASSWORD_CHANGE]: PasswordChange, //Time to change your password ** not used
  [CARD_IDENTITY_MONITOR]: IdentityMonitor, //Identity
  [CARD_ONBOARD_DWS_SKIPPED]: OnboardDwsSkipped, //Has your personal info being stolen?
  [CARD_PROTECT_NEW_DEVICE]: ProtectNewDevice, //Protect all your devices
  [CARD_MONITOR_NEW_EMAIL]: MonitorNewEmail, //Want to search for other personal info?
  [CARD_MISSING_PHONE_NUMBER]: AddMobilePhone, //Prefer text or emails
  [CARD_BREACH_SMS_NOTIFICATION]: AddMobilePhone, //Instantly know when you're at risk
  [CARD_BREACH_LEARN_MORE]: BreachLearnMore, //Rediscover the ways we help protect you
  [CARD_EMAIL_VERIFICATION_BREACHES_FOUND]: EmailVerificationBreachesFound, //Your info's been stolen but we can help
  [CARD_EMAIL_VERIFICATION_NO_BREACHES_FOUND]: EmailVerificationNoBreachesFound, //Can we keep an eye out for your info?

  // [CARD_ADD_SECURE_WIFI]: AddVpnRecommended,  //Bank, shop, and browse safely and privately
  [CARD_PPS_SOFTWARE_OUTDATED]: PpsSoftwareOutdated, //Update so we can do more for you - In Figma only ** not used
  [CARD_NAME_THIS_DEVICE]: AddDevice, //Lets get you set up ** not used
  // [CARD_ONBOARDING_MESSAGE]: OnboardingMessage, //What is a data breach ** no longer supported
  [CARD_AV_PROTECTING_YOU] : AvIsProtectingYou,
  [CARD_AV_FOUND_THREATS] : AvFoundThreats,
  [CARD_AV_TURN_ON_RTS] : AvTurnOnRTS,
};

export const cardAnalytics = (id) => {
  let hit_screen, hit_feature, hit_category_0, hit_label_8;
  let hit_trigger = ANALYTICS_MANUAL;

  switch (id) {
    //Lifecycle
    case CARD_PROTECT_NEW_DEVICE: //(PPS1) Protect all your devices
      hit_screen = "protect_new_device";
      hit_feature = ANALYTICS_LIFE_CYCLE;
      hit_category_0 = ANALYTICS_PROTECTION;
      hit_label_8 = "dashboard";
      break;
    case CARD_ADD_NOTIFICATION_PERMISSION: //(NOT1) We have important things to tell you
      hit_screen = "recommended_notification_permission";
      hit_feature = FEATURE_MESSAGING;
      hit_category_0 = ANALYTICS_ENGAGEMENT;
      hit_label_8 = "engagement";
      // hit_trigger = "permission_denied_notifications";
      break;
    case CARD_PPS_SOFTWARE_OUTDATED: //(PPS7) Update so we can do more for you - In Figma only ** not used
      break;
    case CARD_PASSWORD_CHANGE: //Time to change your password
      break;
    //Identity
    case CARD_MISSING_PHONE_NUMBER: //(ID3) Prefer text or emails
    case CARD_BREACH_SMS_NOTIFICATION: //Instantly know when you're at risk
      hit_screen = "add_sms_alert";
      hit_feature = ANALYTICS_LIFE_CYCLE;
      hit_category_0 = ANALYTICS_ENGAGEMENT;
      hit_label_8 = "dashboard";
      break;

    case CARD_ACCOUNT_BREACHED: //(ID7,10**) Your personal info was taken
      hit_screen = "breach_found";
      hit_feature = ANALYTICS_ID_PROTECTION;
      hit_category_0 = ANALYTICS_PROTECTION;
      hit_label_8 = "identity";
      // hit_trigger = "pps_id_breach_lookup";
      break;

    case CARD_MONITOR_NEW_EMAIL: //(ID8) Want to search for other personal info?
      hit_screen = "recommended_id_monitoring_secondary_email";
      hit_feature = ANALYTICS_ID_PROTECTION;
      hit_category_0 = ANALYTICS_ENGAGEMENT;
      hit_label_8 = "identity";
      // hit_trigger = "pps_ids_enrollment_complete";
      break;
    case CARD_EMAIL_VERIFICATION_BREACHES_FOUND: //(ID9) Your info's been stolen but we can help
      hit_screen = "unverified_primary_email";
      hit_feature = ANALYTICS_ID_PROTECTION;
      hit_category_0 = "authentication";
      hit_label_8 = "identity";
      // hit_trigger = "email_addition_cancel";
      break;
    case CARD_ONBOARD_DWS_SKIPPED: //(ID7|PPS2) Has your personal info being stolen?
      hit_screen = "recommended_id_monitoring_primary_email";
      hit_feature = ANALYTICS_ID_PROTECTION;
      hit_category_0 = ANALYTICS_ENGAGEMENT;
      hit_label_8 = "identity";
      // hit_trigger = "pps_ids_enrollment_skip";
      break;
    case CARD_EMAIL_VERIFICATION_NO_BREACHES_FOUND: //(ID11) Can we keep an eye out for your info?
      hit_screen = "unverified_primary_email";
      hit_feature = ANALYTICS_ID_PROTECTION;
      hit_category_0 = ANALYTICS_PROTECTION;
      // hit_trigger = "email_addition_cancel";
      break;
    case CARD_BREACH_LEARN_MORE: //(***) Rediscover the ways we help protect you
      hit_screen = "breach_education";
      hit_feature = ANALYTICS_ID_PROTECTION;
      hit_category_0 = ANALYTICS_ENGAGEMENT;
      break;
    case CARD_IDENTITY_MONITOR: //(***) Identity
      break;
    //VPN
    case CARD_UNSAFE_WIFI_ALERT: //(VPN1a,VPN1b) Disconnect now - this Wi-Fi is unsafe
      {
        const {
          vpn: { vpnSetup },
        } = store.getState();
        hit_screen = vpnSetup
          ? "critical_network_attack_vpn_setup"
          : "critical_network_attack_vpn_notsetup";
        hit_feature = ANALYTICS_VPN;
        hit_category_0 = ANALYTICS_PROTECTION;
        hit_label_8 = "vpn_setup";
        // hit_trigger = "pps_network_threat_detected"; //| "pps_vpn_setup_cancel"
      }
      break;
    case CARD_OPEN_WIFI_ALERT: //(VPN2a-manual:VPN2c) Careful, This {WiFi} network has weak security
      {
        const {
          vpn: { vpnSetup },
        } = store.getState();
        hit_screen = vpnSetup
          ? "critical_unsecure_wifi_vpn_manual"
          : "critical_unsecure_wifi_vpn_notsetup";
        hit_feature = ANALYTICS_VPN;
        hit_category_0 = ANALYTICS_PROTECTION;
        hit_label_8 = "vpn_setup";
        // hit_trigger = "network_connection_unsecure"; //| "pps_vpn_setup_cancel"
      }
      break;
    case CARD_ADD_VPN_RECOMMENDED: //(VPN2e-manual:VPN2F) This {WiFi} is secure, but...
      {
        const {
          vpn: { vpnSetup },
        } = store.getState();
        hit_screen = vpnSetup
          ? "recommended_secure_wifi_vpn_manual_always_on"
          : "recommended_secure_wifi_vpn_notsetup";
        hit_feature = ANALYTICS_VPN;
        hit_category_0 = ANALYTICS_ENGAGEMENT;
        hit_label_8 = "engagement";
        // hit_trigger = "network_connection_secure"; // | "pps_vpn_setup_cancel"
      }
      break;
    case CARD_VPN_LEARN_MORE: //(VPN3) Why do hackers love public Wi-Fi?
      hit_screen = "value_secure_wifi_vpn_notsetup_education";
      hit_feature = ANALYTICS_VPN;
      hit_category_0 = ANALYTICS_ENGAGEMENT;
      hit_label_8 = "engagement";
      // hit_trigger = "network_connection_secure";
      break;

    default:
      break;
  }
  return { hit_screen, hit_feature, hit_category_0, hit_trigger, hit_label_8 };
};
