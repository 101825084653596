

import NavbarLayout from "../NavBar/NavbarLayout";
import { Button, Heading, Link, Paragraph } from "@mcafee/pegasus";


import noThreatsFoundImg from "../_assets/images/shared/laptopSuccess.svg";

import fixedThreatsImg from "../_assets/images/shared/successMan.svg";
import threatsFoundImg from "../_assets/images/shared/laptopError.svg";


import { push } from "connected-react-router";
import { connect } from "react-redux";
import "./scanResult.scss";
import { DialogLayout } from "../layouts/DialogLayout";
import { String } from "../../l10n/loader";
import { AV_SCAN_RESULT, MCAFEE_SUPPORT_URL } from "../../constants";
import { ROUTE_AV_SCAN_DETAILS,ROUTE_AV,ROUTE_DASHBOARD } from "../app/routes";

import { useDispatch } from "react-redux";

const ScanResult = ({ issuesResolved,issuesUnresolved,threats }) => {

  const dispatch = useDispatch();
  const issuesFound = issuesResolved+issuesUnresolved;
  const unresolvedThreats = issuesUnresolved;
  const issuesFixed = issuesResolved;
  // show AV page if scan complete no issues found 
  // show scan_details when RTS issues found (without scan issuesFound === 0)
  if (issuesFound === 0) {
    if(threats.fixedThreats.length === 0 && threats.unresolvedThreats.length === 0) {
      dispatch(push(ROUTE_AV))
    } else {
      dispatch(push(ROUTE_AV_SCAN_DETAILS))
    }
  }

  const _S = (str) => `av.scanResult.${str}`;
  const variation =
    issuesFound === issuesFixed
      ? "threatsFixed"
      : unresolvedThreats > 0 && issuesFixed === 0
        ? "unresolvedThreats"
        : unresolvedThreats > 0 && issuesFixed > 0
          ? "threatsFound"
          : "noThreatsFound";


  const onDetails =  async (e) => {
    await dispatch(push(ROUTE_AV_SCAN_DETAILS))
  }

  const onOk =  async (e) => {
    await dispatch(push(ROUTE_DASHBOARD))
  }

  const onScanAgain =  async (e) => {
    await dispatch(push(ROUTE_AV))
  }

  const onContactUs =  async (e) => {
    window.open(MCAFEE_SUPPORT_URL, "_blank");
  }

  const Buttons = () => (
    <div className="scanResult__buttons">
      {(variation === "unresolvedThreats" || variation === "threatsFound") && (
        <>
          <Button
             onClick={onScanAgain}
            variant="tertiary"
            className="scanResult__content__button"
            size="sm"
          >
            <String id={_S`scanAgain.button`} />
          </Button>
          <Button
              onClick={onContactUs}
            size="sm"
            className="scanResult__content__button"
          >
            <String id={_S`contactUs.button`} />
          </Button>
        </>
      )}
      {variation === "threatsFixed" && (
        <>
          <Button
            onClick={onDetails}
            variant="tertiary"
            size="sm"
            className="scanResult__content__button"
          >
            <String id={_S`seeDetails.button`} />
          </Button>
          <Button
            onClick={onOk}
            className="scanResult__content__button"
            size="sm"
          >
            <String id={_S`thanks.button`} />
          </Button>
        </>
      )}
      {variation === "noThreatsFound" && (
        <>
          <Button
            onClick={onOk}
            className="scanResult__content__button"
            size="sm"
          >
            <String id={_S`thanks.button`} />
          </Button>
        </>
      )}
    </div>
  );

  const img =
    variation === "noThreatsFound"
      ? noThreatsFoundImg
      : variation === "unresolvedThreats"
        ? threatsFoundImg
        : variation === "threatsFound"
          ? threatsFoundImg
          : fixedThreatsImg;
  const threats_count = (variation === "noThreatsFound") ? 0
    : variation === "unresolvedThreats"
      ? unresolvedThreats
      : issuesFixed

  const scanResult = () => {
    return (
      <DialogLayout
        image={img}
        buttons={<Buttons />}
        pageId={AV_SCAN_RESULT}
        animate={false}
        showBack={false}
      >
        <div className="scanResult__content">
          <Heading size="xs" className="scanResult__content">
          {threats_count > 1 && <String id={_S(`${variation}.header2`)} values={{count:threats_count}} />}
            {threats_count <= 1 && <String id={_S(`${variation}.header1`)} />}
          </Heading>

          <Paragraph size="sm" className="scanResult__content">
            {threats_count > 1 && <String id={_S(`${variation}.content2`)} values={{count:threats_count}} />}
            {threats_count <= 1 && <String id={_S(`${variation}.content1`)} />}
            {variation === "unresolvedThreats" && (
              <Link size="sm" className="scanResult__content__link" onClick={onDetails}>
                <String id={_S(`${variation}.seeDetails`)} />
              </Link>
            )}
          </Paragraph>

          {variation === "threatsFixed" && (
            <Paragraph size="md" className="scanResult__content">
              <String id={_S(`${variation}.content_2`)} />
            </Paragraph>
          )}
          {variation === "threatsFound" && (
            <Link size="sm" className="scanResult__content__link" onClick={onDetails}>
              <String id={_S(`${variation}.seeDetails`)} />
            </Link>
          )}
        </div>
      </DialogLayout>
    );
  };
  return <NavbarLayout>{scanResult()}</NavbarLayout>;
};

export default connect(
  (state) => ({
    ...state.av,
  }),
  {
    push,
  }
)(ScanResult);
