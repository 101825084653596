import React from "react";
import { useSelector } from "react-redux";

//Rendering children only if feature is supported

export default function CheckFeature({ feature, children }) {
  const features = useSelector((state) => state.experience.features);

  const isEnabled = (feature) => {
    if (!feature) {
      return true; //No features specified, show always
    }
    if (Array.isArray(feature)) {
      for (let i = 0; i < feature.length; i++) {
        if (isEnabled(feature[i])) {
          return true;
        }
      }
      return false;
    }

    if (features[feature] && features[feature].enabled) {
      return true;
    }
    return false; //No feature support
  };

  if (isEnabled(feature)) {
    return <>{children}</>;
  }
  return null;
}
