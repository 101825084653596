/* eslint-disable no-unused-vars */
// React
import React, { useEffect, useState } from "react";

// DOM
import {
  Grid,
  Row,
  Col,
  Avatar,
  Heading,
  Paragraph,
  Link,
  Subheading,
  Button,
  Icon,
} from "@mcafee/pegasus";

import NavbarLayout from "../NavBar/NavbarLayout";

import { String } from "../../l10n/loader";
import AssetExplorer from "../Identity/AssetExplorer";
import { useDispatch, useSelector } from "react-redux";
import { showPopup } from "../../store/thunks/popups";
import { POPUP_PASSWORD_TIPS } from "../PopUps/constants";
import CheckAction from "../Shared/CheckAction";
import events from "../../experience/events";
import { publishEvent } from "../../store/thunks/experience";

export const IdentityBreachLayout = ({
  children,
  contentGroup,
  avatarImage,
}) => {
  const dispatch = useDispatch();
  const { breach_id } = useSelector((state) => state.router.location.query);
  const getUpdatedTime = () => {
    return new Date().toLocaleString("en-US", {
      hour: "numeric",
      hour12: true,
    });
  };

  const handleGetPasswordTips = () => {
    dispatch(showPopup(POPUP_PASSWORD_TIPS));
  };

  const stringIdPrefix = "identity.breachView.";

  return (
    <NavbarLayout
      id={
        contentGroup === stringIdPrefix ||
        contentGroup === "identity.breachView.noBreach."
          ? "Identity"
          : "BreachDetails"
      }
      classNameLayout="pa-0"
    >
      <Grid fluid>
        <Row className="identityLayout__basePanel">
          <Col md={4} sm={4} xs={12} className="identityLayout__assetExplorer">
            <AssetExplorer />
          </Col>
          {contentGroup && (
            <Col md={8} sm={8} xs={12} className="identityLayout__container">
              <CheckAction event={events.ids.back}>
                {breach_id && (
                  <div className="identityLayout__detailsBack">
                    <Button
                      title="Back"
                      id="BreachDetailsBackButton"
                      variant="tertiary"
                      onClick={() => {
                        dispatch(publishEvent(events.ids.back));
                      }}
                    >
                      <Icon
                        icon="chevron-left-outline"
                        className="mr-8"
                        size="sm"
                      />
                      <String id="goBack" />
                    </Button>
                  </div>
                )}
              </CheckAction>
              <div className="identityLayout__container__header">
                <Avatar
                  shape="round"
                  kind="image"
                  style={{
                    backgroundImage: `url(${avatarImage})`,
                  }}
                ></Avatar>
                <Subheading size="lg">
                  <String id={`${contentGroup}header`} />
                </Subheading>
                <Paragraph
                  size="sm"
                  className="identityLayout__container__content__caption"
                >
                  <String id={`${contentGroup}caption`} />
                  <Link
                    className="identityLayout__container__content__caption__link"
                    onClick={handleGetPasswordTips}
                  >
                    {contentGroup !== stringIdPrefix &&
                    contentGroup !== "identity.breachView.noBreach." ? (
                      <String id={`${contentGroup}getPwdTips`} />
                    ) : null}
                  </Link>
                </Paragraph>
                {contentGroup === stringIdPrefix ||
                contentGroup !== "identity.breachView.noBreach." ? (
                  <Paragraph size="sm">
                    <String
                      id={`${stringIdPrefix}updatedAt`}
                      values={{
                        time: `${getUpdatedTime()}`,
                      }}
                    />
                  </Paragraph>
                ) : null}
              </div>
              {children}
            </Col>
          )}
        </Row>
      </Grid>
    </NavbarLayout>
  );
};
