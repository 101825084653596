import { HTTP_NOT_ACCEPTABLE, LOG_LEVEL_VERBOSE } from "../constants";
import { dispatchDomEvent, stringifyJson } from "./main";
import { uwpLog } from "../uwp";

/**
 * Synchronization methods
 */

//named mutex list
const mutexLocks = {};

/**
 * Wait for a named lock to be released (without creating a new lock)
 * @param {string} name mutex name
 * @returns {object} first un-locker shared value
 */
export async function waitMutex(name) {
  if (mutexLocks[name] === undefined) {
    mutexLocks[name] = { locks: 0, unlocks: 0 };
    return;
  }
  const mutex = mutexLocks[name];
  if (mutex.locks <= mutex.unlocks) {
    delete mutexLocks[name].returnValue; //mutex is open, don't return a value unless another locker is working
    return;
  }

  const position = mutex.locks - mutex.unlocks; //reserve waiter's position in the queue

  return new Promise((resolve) => {
    const { addEventListener, removeEventListener } = window;
    const eventName = `unlock_${name}`;

    addEventListener(eventName, function handleUnlock() {
      if (position > mutex.locks - mutex.unlocks) {
        // Position is reached, this locked code can be released
        uwpLog(`$$ReleaseLock#${name}@position:${position}`);
        resolve(mutex.returnValue);
        removeEventListener(eventName, handleUnlock);
      }
    });
  });
}

/**
 * Create and wait for a named lock
 * Returns immediately if this is the only lock
 * If previously locked, wait for others to unlock it
 * @param {string} name mutex name
 * @returns {Promise} resolved to shared results
 */
export async function lockMutex(name) {
  if (mutexLocks[name] === undefined) {
    mutexLocks[name] = { locks: 0, unlocks: 0 };
  }
  const mutex = mutexLocks[name];
  const { locks, unlocks, returnValue } = mutex;
  const position = locks - unlocks + 1;

  uwpLog(
    `$$Lock>#${name}(${stringifyJson({
      locks,
      unlocks,
      retVal: stringifyJson(returnValue).substr(0, 10),
      position,
    })})`
  );

  if (mutex.locks++ === mutex.unlocks) {
    uwpLog(`$$ReleaseLock#${name}@position:1`, LOG_LEVEL_VERBOSE);
    return; //already open, don't wait
  }

  await waitMutex(name); //Wait for others locking it
  return mutex.returnValue;
}

/**
 * Unlock a named mutex and optionally share a return value for other waiters
 * @param {string} name mutex nam
 * @param {Object} returnValue a value to be shared with waiters for the same lock
 */
export function unlockMutex(name, returnValue) {
  const mutex = mutexLocks[name];
  if (mutex.locks === mutex.unlocks) {
    uwpLog(`Unlocking open mutex(${name})`, LOG_LEVEL_VERBOSE);
    return returnValue;
  }
  mutex.unlocks++; //reduce one lock layer
  mutex.returnValue = returnValue; //return a value to other waiters for possible re-use
  const { locks, unlocks } = mutex;
  uwpLog(
    `$$Unlock<#${name}(${JSON.stringify({
      locks,
      unlocks,
      retVal: stringifyJson(returnValue).substr(0, 10),
    })})`
  );
  dispatchDomEvent(`unlock_${name}`);
  return returnValue;
}

/**
 * Invoke async function synchronously and optionally use the result of
 * active execution if not undefined.
 * functions throwing an exception could be caught by the syncedInvoke caller
 * A named mutex is used to queue calls sharing the same mutex name
 * @param {{mutex,reuseActiveCallResult}} param0 mutex name and optional flag to re-use running calls result
 * @param {function} asyncFunc a function to call synchronously
 * @returns
 */
export async function syncedInvoke(
  { mutex, reuseActiveCallResult = false },
  asyncFunc
) {
  if (mutex === undefined) {
    throw Object({
      code: HTTP_NOT_ACCEPTABLE,
      message: "Mutex name is undefined",
      source: `syncedInvoke`,
    });
  }

  const activeCallResult = await lockMutex(mutex);

  if (reuseActiveCallResult && activeCallResult !== undefined) {
    return unlockMutex(mutex, activeCallResult);
  }

  let result;

  try {
    result = await asyncFunc();
  } catch (e) {
    unlockMutex(mutex); //release the lock to pop the waiting queue
    throw e; //syncedInvoke callers can catch this exception
  }
  return unlockMutex(mutex, result); //release the lock and pass the result back so callers in the queue may re-use
}
