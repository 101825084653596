import {
  networkCheckBegin,
  networkCheckSuccess,
  networkCheckFailure,
} from "../network";
import { uwpInvoke } from "../../uwp";
import { MTX_CHECK_NETWORK } from "../../constants";
import { syncedInvoke } from "../../utils";

export const checkNetwork = (params = {}) => async (dispatch) => {
  return syncedInvoke({ mutex: MTX_CHECK_NETWORK }, async () => {
    dispatch(networkCheckBegin());

    try {
      const state = await uwpInvoke("network_scanner.get_state", params);
      dispatch(networkCheckSuccess(state));
    } catch (e) {
      dispatch(networkCheckFailure(e.message));
    }
  });
};
