// React
import React from "react";

import DashboardCard from "../DashboardCard/DashboardCard";
import backgroundImage from "../_assets/images/dashboard/cards/wifiSecure.svg";

import { RECOMMENDED } from "../../constants/main";
import { ROUTE_SECURE_VPN, ROUTE_VPN_SETUP } from "../app/routes";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

export default function AddVpnRecommended({ id }) {
  const dispatch = useDispatch();
  const { vpnSetup } = useSelector((state) => state.vpn);

  const onExtraPrivacyClick = () => {
    dispatch(
      push({
        pathname: vpnSetup ? ROUTE_SECURE_VPN : ROUTE_VPN_SETUP,
        showPopup: true,
      })
    );
  };

  const card = {
    id,
    stringValues: {
      WiFi: "Wi-Fi",
    },
    backgroundImage,
    tagText: RECOMMENDED,
    primaryActionStringId: "extraPrivacy",
    primaryActionDataTestId: "secureWifiExtraPrivacy",
    onPrimaryAction: onExtraPrivacyClick,
  };

  return <DashboardCard {...card} />;
}
