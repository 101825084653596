import React from "react";

import { Banner, Button, Icon, Label, Paragraph } from "@mcafee/pegasus";

import { String } from "../../l10n/loader";

import "./bannerNotifications.scss";

export default function BannerNotification({
  stringId,
  label,
  onCancel,
  onFinish,
  confirmButtonStringId,
}) {
  const _S = (str) => `toasters.banner.${str}`;
  return (
    <Banner className="banner">
      <div className="banner-content">
        <div className="banner-left flex ma-12 align-items-center">
          <Icon
            icon="info-outline"
            className="banner-icon text-warning mr-16"
          />
          <div className="inline-labels">
            {label ? (
              <Label
                className="banner-label position-relative display-inline mr-8"
                data-testid="BannerNotificationLabel"
              >
                <String id={label} />
              </Label>
            ) : null}
            <Paragraph
              className="banner-body display-inline"
              data-testid="BannerNotificationContent"
            >
              <String id={stringId} />
            </Paragraph>
          </div>
        </div>
        <div
          className="banner-right flex justify-content-end"
          style={{
            minWidth: "200px",
          }}
        >
          <Button
            variant="tertiary"
            className="mr-8"
            onClick={onCancel}
            data-testid="BannerNotificationCancel"
          >
            <String id={_S`dismiss`} />
          </Button>
          <Button
            variant="tertiary"
            onClick={() => onFinish(true)}
            data-testid="BannerNotificationSubmit"
          >
            <String id={confirmButtonStringId || _S`tryAgain`} />
          </Button>
        </div>
      </div>
    </Banner>
  );
}
