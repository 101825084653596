import React from "react";
// import wave from "../_assets/animations/wave/wave.json";
// import redWave from "../_assets/animations/red-wave/red_wave.json";
// import Animator from "../Animator";

const WaveLayout = ({ children, style }) => {
  const styles = {
    root: {
      height: "100vh",
    },
    waveAnimation: {
      width: "100%",
      position: "absolute",
      bottom: -3,
      left: 0,
      right: 0,
      zIndex: "-1",
    },
  };

  return (
    <div
      className="flex direction-column align-items-center"
      style={style ? style : styles.root}
    >
      {children}
      
    </div>
  );
};

export default WaveLayout;
