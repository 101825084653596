import { uwpLog } from "../../uwp";
import { dispatchDomEvent, stringifyJson } from "../../utils";
import {
  TYPE_BIG_INTEGER,
  TYPE_BOOLEAN,
  TYPE_NUMBER,
  TYPE_STRING,
  TYPE_SYMBOL,
  TYPE_UNDEFINED,
} from "../../constants/main";

/**
 * Check if object type is premitive or an Array
 * @param {object} o
 */
const isPrimitive = (o) => {
  switch (typeof o) {
    case TYPE_UNDEFINED:
    case TYPE_STRING:
    case TYPE_NUMBER:
    case TYPE_BOOLEAN:
    case TYPE_BIG_INTEGER:
    case TYPE_SYMBOL:
      return true;
    default:
      if (o === null) {
        return true;
      }
      return false;
  }
};

/**
 * Detect state updates
 * Returns log string and list of update vars
 *
 * @param {object} newObj new state
 * @param {object} oldObj old state
 * @returns {log: string, vars: Array }
 */
const objectUpdates = (newObj, oldObj) => {
  const log = {},
    vars = [];

  /**
   * Add an update item to the "log" object
   * Add a changed item path to "vars" list
   * @param {Array} nodePath path to the node in app state, example: ["idenity","breaches","2"]
   * @param {string} changes description of changes, example: "null->[1,2,3]"
   */
  const logUpdate = (nodePath, changes) => {
    let logNode = log,
      pathIndex = 0;
    vars.push(nodePath.join("."));
    for (pathIndex = 0; pathIndex < nodePath.length - 1; pathIndex++) {
      const key = nodePath[pathIndex];
      logNode = logNode[key] = logNode[key] !== undefined ? logNode[key] : {};
    }

    if (changes !== undefined) {
      logNode[nodePath[pathIndex]] = changes;
    }
  };

  /**
   * Returned strigified object with optional ellipses
   * @param {*} o object to stringify
   * @param {*} maxLength length to add ellipses after
   */
  const stringify = (o, maxLength = 0) => {
    const strVal = JSON.stringify(o);
    return maxLength ? strVal.substr(0, maxLength) : strVal;
  };

  /**
   * Detect values differences and append findings to vars and log objects
   * @param {object} newObj updated object
   * @param {object} oldObj old object
   * @param {Array} nodePath node path components
   */
  (function findDiffs(newObj, oldObj, nodePath) {
    /**
     * Returns node compareable value
     * Optionally trim the length if string is returned
     * @param {object} node
     * @param {number} maxLength optional trim length
     */
    const nodeValue = (node, maxLength = 0) => {
      return isPrimitive(node) ? node : stringify(node, maxLength);
    };

    /**
     * Match two end nodes and log updates if not matching
     * @param {Object} newNode
     * @param {Object} oldNode
     * @param {Array} nodePath
     */
    const checkLogUpdate = (newNode, oldNode, nodePath) => {
      const [newNodeVal, oldNodeVal] = [nodeValue(newNode), nodeValue(oldNode)];
      if (newNodeVal !== oldNodeVal) {
        //None matching premitive nodes
        logUpdate(
          nodePath, //pass the whole path
          `${nodeValue(oldNode, 100)}->${nodeValue(newNode, 100)}` //pass the changes message
        );
      }
    };

    const isObject = (node) => {
      return typeof node === "object" && node !== null;
    };

    //If newObj is Array, match elements up to max length of both arrays
    //Find none matching premitive nodes
    if (isObject(newObj) && isObject(oldObj)) {
      //combine keys from two objects
      const keys = [...Object.keys(newObj), ...Object.keys(oldObj)].reduce(
        (uniqueKeys, item) => {
          //remove duplicates
          if (!uniqueKeys.includes(item)) {
            uniqueKeys.push(item);
          }
          return uniqueKeys;
        },
        []
      );
      keys.forEach((nodeKey) => {
        const [newNode, oldNode] = [newObj[nodeKey], oldObj[nodeKey]];

        if (isPrimitive(newNode) || isPrimitive(oldNode)) {
          //End node found, compare now, can't digg more
          checkLogUpdate(newNode, oldNode, [...nodePath, nodeKey]);
        } else {
          //Digg more
          findDiffs(newNode, oldNode, [...nodePath, nodeKey]);
        }
      });
    } else {
      checkLogUpdate(newObj, oldObj, nodePath);
    }
  })(newObj, oldObj, []); //Start digging

  return { log: stringifyJson(log), vars };
};

/**
 * Redux middleware invoked on every store action
 * @param {object} store redux store reference
 */
const logger = (store) => (next) => (action) => {
  const prevState = store.getState();
  const result = next(action);
  const nextState = store.getState();
  try {
    const { log, vars } = objectUpdates(nextState, prevState);
    uwpLog(`{Dispatching:${stringifyJson(action)},\n__updates:${log}}`);
    if (vars.length) {
      dispatchDomEvent("@state/updates", vars); //For events engine to dispatch events linked to state changes
    }
  } catch (e) {
    uwpLog(`{Dispatching:${stringifyJson(action)}}`); //Failed to detect updates
  }
  dispatchDomEvent(action.type, action.payload); //For action listeners outside redux scope
  return result;
};

export default logger;
