import styled from "styled-components";
import { ProgressBar, ProgressGauge } from "@mcafee/pegasus";

export const TopProgressBar = styled(ProgressBar)`
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
  width: 100vw;
  z-index: 1000;
`;

export const InPlaceProgress = styled(ProgressGauge)`
  margin: auto;
  width: 150px;
  height: 150px;
  padding: 20px;
`;
