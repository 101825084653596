import {
  cleanupCache,
  createQueryString,
  emailFromIdToken,
  fetchJson,
  fetchUrl,
  getProperties,
  parseJson,
  syncedInvoke,
  setProperties,
  stringifyJson,
  storeErrorDetails,
  isApiError,
  isServerError,
  newGUID,
  urlToSource,
} from "../../../src/utils";

import {
  signInFailure,
  signInSuccess,
  signOutBegin,
  signOutFailure,
  signOutSuccess,
  autoSignInBegin,
  accessTokenBegin,
  signOutError,
  refreshAccessTokenBegin,
  refreshAccessTokenSuccess,
  refreshAccessTokenFailure,
  updateUserActions,
  refreshAccessTokenError,
} from "../auth";

import { validateToken } from "../../utils";
import { uwpUpdateSharedContext } from "../../uwp";
import { checkVpn, setupVpn } from "./vpn";
import { fetchProductFeatures } from "./experience";
import { getServiceUrl } from "./settings";
import {
  API_PPS,
  API_SECURITY_MGMT,
  LS_ACCESS_TOKEN,
  LS_SESSION,
  LS_ID_TOKEN,
  LS_AFF_ID,
  LS_PROVISION_ID,
  HTTP_UNAUTHORIZED,
  CACHE_NO_STORE,
  HTTP_GET,
  HTTP_POST,
  HTTP_NO_CONTENT,
  REMIND_ONE_HOUR,
  REMIND_NEXT_WEEK,
  WEEK_MS,
  REMIND_TOMORROW,
  DAY_MS,
  HOUR_MS,
  UA_SCHEDULE_CARDS,
  MTX_REFRESH_TOKEN,
  MTX_ACCESS_TOKEN,
  MTX_SIGN_OUT,
  LS_USER_ACTIONS,
  FORCE,
  LS_DONE_TRIAL_PROMPT,
} from "../../constants/main";
import { claimsBegin, claimsSuccess, claimsFailure } from "../context";
import { uwpPublish } from "../../uwp";
import { checkNetwork } from "./network";

const uwpSyncPushChannel = () => {
  // uwpPublish("app.sync_push_channel"); //Disabled upon Bhavnesh Sharma's request
};

/**
 * Called upon app startup to auto sign-in
 * @returns undefined
 */
export const autoSignIn = () => async (dispatch) => {
  dispatch(autoSignInBegin());
  try {
    const {
      [LS_ACCESS_TOKEN]: accessToken,
      [LS_SESSION]: session,
      [LS_ID_TOKEN]: idToken,
      [LS_PROVISION_ID]: provision_id,
      [LS_AFF_ID]: aff_id,
      [LS_USER_ACTIONS]: userActions,
    } = await getProperties([
      LS_ACCESS_TOKEN,
      LS_SESSION,
      LS_ID_TOKEN,
      LS_PROVISION_ID,
      LS_AFF_ID,
      LS_USER_ACTIONS,
    ]);

    if (accessToken && idToken && session) {
      dispatch(claimsSuccess({ provision_id, aff_id }));
      if (userActions) {
        dispatch(updateUserActions(parseJson(userActions)));
      }
      await dispatch(
        doneSignIn({
          accessToken,
          session,
          idToken,
          autoSignIn: true,
        })
      );
      uwpSyncPushChannel();
    } else {
      dispatch(signInFailure(null)); //No error to show, failure is normal here
    }
  } catch (e) {
    dispatch(signInFailure(storeErrorDetails(e)));
  }
};

/**
 * Returns guaranteed valid accessToken, or throws "Not signed in" or API errors
 * It also updates token claims if successfully refreshed
 * It re-login to VPN if provision ID has changed
 * @returns {string} fresh accessToken or current token if still valid
 * @throws {{status:number, message:string, source:string}} statndard error details
 */
export const refreshAccessToken =
  (forceRefresh = false) =>
  async (dispatch, getState) => {
    return syncedInvoke(
      { mutex: MTX_REFRESH_TOKEN, reuseActiveCallResult: true },
      async () => {
        const {
          auth: { accessToken, session, idToken },
          context: { provision_id: oldProvisionId },
          vpn: { vpnSetup },
        } = getState();

        const apiPath = "/v1/auth/refresh";

        if (!accessToken) {
          throw Object({
            code: HTTP_UNAUTHORIZED,
            message: "Not signed in",
          });
        }

        try {
          if (!forceRefresh && validateToken(accessToken)) {
            return accessToken; //still valid keep using it
          }

          dispatch(refreshAccessTokenBegin());

          const queryString = createQueryString({
            include_session: true,
          });
          const apiBaseUrl = await dispatch(
            getServiceUrl(API_PPS, true /*fromFetchAccessToken */)
          );
          const response = await fetchJson(
            `${apiBaseUrl}${apiPath}?${queryString}`,
            {
              method: HTTP_POST,
              cache: CACHE_NO_STORE,
              headers: {
                accessToken,
                traceid: newGUID(),
              },
              body: stringifyJson({
                session,
              }),
            }
          );

          const {
            status,
            data: { access_token: refreshedToken, session: newSession },
          } = response;

          if (refreshedToken) {
            setProperties({ [LS_ACCESS_TOKEN]: refreshedToken });

            if (newSession) {
              setProperties({ [LS_SESSION]: newSession });
            }

            uwpSyncPushChannel();

            const email = emailFromIdToken(idToken);

            await dispatch(
              refreshAccessTokenSuccess({
                accessToken: refreshedToken,
                idToken,
                session: newSession,
                email,
              })
            );

            //Check if provision_id has been updated, dispatch a vpn re-login
            dispatch(fetchClaims(refreshedToken)).then(
              ({ provision_id: newProvisionId }) => {
                if (oldProvisionId !== newProvisionId) {
                  if (vpnSetup) {
                    const trigger = "user_resignin-token_refresh";
                    dispatch(
                      setupVpn({ accessToken: refreshedToken, trigger })
                    );
                  }
                }
              }
            );

            return refreshedToken; //for the caller to use the new token immediately
          }

          throw Object({
            status,
            message: "Expired or revoked token",
            source: urlToSource(apiPath, HTTP_POST),
          });
        } catch (e) {
          const errorInfo = storeErrorDetails(e);
          const { status } = errorInfo;
          if (isApiError(status)) {
            dispatch(refreshAccessTokenError(errorInfo));
            dispatch(signOut(FORCE)); //TODO: force navigation to singIn page
            throw errorInfo; //For callers to stop executing
          } else {
            //Offline, or server didn't reject the token
            //Keep that token and don't sign out
            dispatch(refreshAccessTokenFailure(errorInfo));
            throw errorInfo;
            // return EMPTY_VAL; //For syncedInvoke waiters to stop trying
          }
        }
      }
    );
  };

/**
 * Called after successful (or forced) signOut to cleanup the user data
 */
const doneSignOut = () => {
  uwpPublish("app.log_out");
  cleanupCache({ signOut: true });
};

/**
 * Calling logout API and dispatch the signOut action if successful
 * The two possible actions for error are signOutError() and signOutFailure()
 * The signOutError is used when the API can't invalidate the token,
 * as this is unrecoverable state, we force the client signout
 * @param {boolean} force forcing the client action due to bad accessToken
 * @returns {{ok:boolean,accessToken,session,error?}} signOut API response
 */
export const signOut =
  (force = false) =>
  async (dispatch, getState) => {
    return syncedInvoke(
      { mutex: MTX_SIGN_OUT, reuseActiveCallResult: true },
      async () => {
        dispatch(signOutBegin());

        try {
          const { accessToken, session } = getState().auth;

          if (!accessToken) {
            //Not signed in
            dispatch(signOutFailure({ code: 0, message: "Not signed in" }));
            return { ok: true };
          }

          const queryString = createQueryString({
            include_session: true,
          });
          const apiPath = `/v1/auth/logout`;
          const apiBaseUrl = await dispatch(getServiceUrl(API_PPS, true));

          const logoutResponse = await fetchUrl(
            `${apiBaseUrl}${apiPath}?${queryString}`,
            {
              method: HTTP_POST,
              cache: CACHE_NO_STORE,
              headers: {
                accessToken,
              },
              body: stringifyJson({
                session,
              }),
            }
          );

          const { status } = logoutResponse;

          if (status === HTTP_NO_CONTENT) {
            //This is the successful API response
            dispatch(signOutSuccess());
            doneSignOut();
            return { ...logoutResponse, ok: true };
          }

          throw Object({
            status,
            message: "Unexpected logout response",
            source: urlToSource(apiPath),
          });
        } catch (e) {
          //Catch errors or unsuccessful response
          const error = storeErrorDetails(e);
          const { status } = error;
          if (isServerError(status)) {
            dispatch(signOutFailure(error));
            //Unless forced, prompt user to retry
            if (force) {
              doneSignOut();
            }
            return { error, ok: force };
          }
          //API responded with an error, force sign out
          dispatch(signOutError(error));
          doneSignOut();
          return { error, ok: true };
        }
      }
    );
  };
/**
 * Performing post-sign actions
 * @param {{accessToken, idToken, session}} tokenInfo
 * @returns undefined
 */
export const doneSignIn = (tokenInfo) => async (dispatch) => {
  const { idToken } = tokenInfo;
  const email = emailFromIdToken(idToken);

  setProperties({ [LS_DONE_TRIAL_PROMPT]: true });
  dispatch(signInSuccess({ ...tokenInfo, email }));

  await dispatch(fetchProductFeatures());
  dispatch(checkVpn());
  dispatch(checkNetwork());
};

/**
 * Dispatched after obtaining oauth auth_code to finish signing in
 * and obtain the tokens
 * @param {{authCode, newUser:boolean}} options
 * @returns {{ok:boolean,accessToken?:string, idToken?:string, session?:string, error?}}
 */
export const fetchAccessToken =
  ({ authCode, newUser = false }) =>
  async (dispatch, getState) => {
    return syncedInvoke(
      { mutex: MTX_ACCESS_TOKEN, reuseActiveCallResult: true },
      async () => {
        dispatch(accessTokenBegin({ newUser }));

        try {
          const {
            locale,
            cspid: csp_client_id,
            oauthClientId: app_id,
          } = getState().context;

          const {
            oem_data_file: cdf,
            system_user_id: system_id,
            system_publisher_id: published_id,
          } = await getProperties([
            "oem_data_file",
            "system_publisher_id",
            "system_user_id",
          ]);

          const params = {
            locale: locale,
            authentication_code: authCode,
            app_id: app_id,
            csp_client_id: csp_client_id,
            include_session: true,
            cdf: cdf,
            system_id: system_id,
            publisher_id: published_id,
          };

          const apiPath = "/v1/auth/token";

          const apiBaseUrl = await dispatch(
            getServiceUrl(API_PPS, true /*fromFetchAccessToken */)
          );

          const { data: results } = await fetchJson(`${apiBaseUrl}${apiPath}`, {
            method: HTTP_POST,
            headers: {
              traceid: newGUID(),
            },
            cache: CACHE_NO_STORE,
            body: stringifyJson(params),
          });

          if (results.access_token) {
            const {
              access_token: accessToken,
              id_token: idToken,
              session,
            } = results;

            await dispatch(fetchClaims(accessToken));

            setProperties({
              [LS_ACCESS_TOKEN]: accessToken,
              [LS_ID_TOKEN]: idToken,
              [LS_SESSION]: session,
            });

            uwpSyncPushChannel();

            await dispatch(
              doneSignIn({ accessToken, idToken, session, newUser })
            );
            return { ok: true, ...results };
          }

          //No accessToken found
          throw Object({
            status: HTTP_NO_CONTENT,
            message: "No accessToken returned",
            source: urlToSource(apiPath, HTTP_POST),
          });
        } catch (e) {
          //Catching errors from v1/auth/token or auth/v1/token/claims APIs
          const error = storeErrorDetails(e);
          dispatch(signInFailure(error));
          return { ok: false, error };
        }
      }
    );
  };

/**
 * Fetch and store provision_id and aff_id
 * @param {string} accessToken accessToken
 * @returns {{ provision_id:string, aff_id:string }}
 */
export const fetchClaims = (accessToken) => async (dispatch) => {
  dispatch(claimsBegin());

  try {
    const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT, true));

    const claimsAPI = `${apiBaseUrl}/auth/v1/token/claims`;
    const response = await fetchJson(claimsAPI, {
      method: HTTP_GET,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const {
      data: { provisionId: provision_id, affId: aff_id },
    } = response;

    const results = { provision_id, aff_id };

    if (provision_id && aff_id) {
      //Store claims for auto sign-in purpose
      setProperties({
        [LS_PROVISION_ID]: provision_id,
        [LS_AFF_ID]: aff_id,
      });
      // update analytics context
      uwpUpdateSharedContext({
        product_affiliate_id: aff_id,
      });
      dispatch(claimsSuccess(results));
    } else {
      throw Object({ ...response, status: HTTP_NO_CONTENT });
    }

    return results;
  } catch (e) {
    const error = storeErrorDetails(e);
    dispatch(claimsFailure(error));
    throw error; //for callers to stop execution
  }
};

/**
 * Store an action ID and completion flag into
 * a settings collection node
 * @param {Array<{[actionId]:boolean}>} actions
 * @returns {undefined}
 */
export const saveUserActions = (actions) => (dispatch, getState) => {
  dispatch(updateUserActions(actions));
  //Persist user actions across sessions
  const { userActions } = getState().auth;
  setProperties({ [LS_USER_ACTIONS]: userActions });
};

/**
 * Based on the option selected from the drop down,
 * Calculate the appropriate expire time and upon storing/updating the localStorage,
 * reload the dashboard with updated cards calculates the delay
 * based on the option selected and stores them in the Storage
 * If the card information is already stored then simply update its value else create a new entry
 *
 * @param {*} cardId
 * @param {*} schedule
 * @returns
 */
export const rescheduleCard =
  (cardId, schedule = REMIND_ONE_HOUR) =>
  async (dispatch, getState) => {
    const now = Date.now(); // returns date in milliseconds since 1970-01-01 UTC

    const calcRespawnDate = (schedule) => {
      switch (schedule) {
        case REMIND_NEXT_WEEK:
          return now + WEEK_MS;
        case REMIND_TOMORROW:
          return now + DAY_MS;
        case REMIND_ONE_HOUR:
        default:
          return now + HOUR_MS;
      }
    };

    const sc = { [cardId]: calcRespawnDate(schedule) };

    const { [UA_SCHEDULE_CARDS]: scheduledCards } = getState().auth.userActions;

    dispatch(
      saveUserActions({ [UA_SCHEDULE_CARDS]: { ...sc, ...scheduledCards } })
    );
  };
