import React from "react";
import { Card, Paragraph, Tag, Button, Subheading } from "@mcafee/pegasus";
import { String } from "../../l10n/loader";
import {
  ArrowBackRounded,
  ArrowForwardRounded,
  SettingsOutlined,
} from "@material-ui/icons";
import { LTR_DIRECTION } from "../../constants";
import { useSelector } from "react-redux";

export default function ServiceCard({
  feature,
  icon,
  contentPrefix,
  heading,
  content,
  tagKind,
  tagContent,
  onClick,
  onSettingsClick,
  disabled = true,
  children = null,
}) {
  const localePrefix = "services.";
  const { direction } = useSelector((state) => state.context);

  return (
    <Card className="my-16 mx-auto pa-24" data-testid={`${feature}ServiceCard`}>
      <div className="flex align-content-start justify-content-start grow-1">
        <img
          src={icon}
          alt=""
          className="mr-16"
          style={{ alignSelf: "flex-start", width: 40, height: 40 }}
        />
        <div
          className={`flex direction-column grow-1 text-left ${
            !onSettingsClick && `mt-8`
          }`}
        >
          <div className="flex justify-content-between align-items-center">
            <Subheading className="mb-4" size="md">
              {heading}
              <String id={`${localePrefix}${contentPrefix}.header`} />
            </Subheading>
            {onSettingsClick && (
              <Button
                tabIndex={0}
                kind="normal"
                variant="tertiary"
                icon
                onClick={onSettingsClick}
                id={`${feature}ServiceSettings`}
                disabled={disabled}
                className="text-gray-light"
                size="sm"
                data-testid="SettingsServiceCardButton"
              >
                <SettingsOutlined />
              </Button>
            )}
          </div>
          <Paragraph className="text-secondary mb-16" size="sm">
            <String id={`${localePrefix}${contentPrefix}.content`} />
            {content}
          </Paragraph>
          {!disabled && (
            <div className="flex justify-content-between align-items-center" data-testid="ServiceCardTag">
              {children ? (
                children
              ) : (
                <Tag kind={tagKind} size="sm" data-testid="tagServiceCard">
                  {tagContent}
                </Tag>
              )}
              <Button
                tabIndex={0}
                kind="normal"
                variant="tertiary"
                icon
                onClick={onClick}
                id={`${feature}Service`}
                disabled={disabled}
                size="sm"
                data-testid="ServiceCardButton"
              >
                {direction === LTR_DIRECTION ? (
                  <ArrowForwardRounded className="btn__icon text-gray-light" data-testid="forwardRoundedButton" />
                ) : (
                  <ArrowBackRounded className="btn__icon text-gray-light" data-testid="backRoundedButton" />
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}
