// React libraries
import React, { useEffect, useState } from "react";
import { String } from "../../l10n/loader";

// DOM
import { List, Paragraph, Subheading, Tab, Tabs, Tag } from "@mcafee/pegasus";
import breachesFoundImage from "../_assets/images/identityBreachList/breachesHeader.svg";
import noBreachesImage from "../_assets/images/identityBreachList/greenFingerprint.svg";

// Custom components
import BreachRow from "../IdentityBreachList/BreachRow";
import {
  ADDRESSED_BREACH_TAB_ID,
  REMEDIATION_ACTED,
  NEW_BREACH_TAB_ID,
} from "../../constants/main";
import { BreachDetails } from "../app/pages";
import { IdentityBreachLayout } from "./IdentityBreachLayout";
import { showPopup } from "../../store/thunks/popups";
import { POPUP_ADDRESSED_BREACHES } from "../PopUps/constants";
import { useDispatch, useSelector } from "react-redux";

import "./personalInfoAlerts.scss";
// import { analyzeBreachDetails, collectScreenLoadEvent } from "../../utils";
// import { collectScreenLoadEvent } from "../../utils";

import { ROUTE_IDENTITY } from "../app/routes";
import { push } from "connected-react-router";
import { createQueryString } from "../../utils";
import { InfoOutlined } from "@material-ui/icons";
import { SCREEN_MAIN_DASHBOARD } from "../../constants";

//Identity->PersonalInfo--renders the breach list/breach details in identity layout
export default function PersonalInfoAlerts({
  breaches,
  asset_public_id,
  topProgress,
}) {
  const _S = (str) => `identity.breachView.${str}`;
  const [activeId, setActiveId] = useState(NEW_BREACH_TAB_ID);
  const [unresolvedBreaches, setUnresolvedBreaches] = useState([]);
  const [resolvedBreaches, setResolvedBreaches] = useState([]);
  const [filteredBreaches, setFilteredBreaches] = useState(breaches);
  const [breachDetails, setBreachDetails] = useState(null);
  const [contentGroup, setContentGroup] = useState();
  const [avatarImage, setAvatarImage] = useState();

  const { pathname, query } = useSelector((state) => state.router.location);
  const dispatch = useDispatch();

  const _onClick = (id) => {
    setActiveId(id);
  };

  useEffect(() => {
    if (asset_public_id && breaches) {
      setFilteredBreaches(
        breaches.filter((breach) => breach.asset_public_id === asset_public_id)
      );
    } else {
      setFilteredBreaches(breaches);
    }
  }, [breaches, asset_public_id]);

  const unresolvedBreachesFilter = (breach) =>
    breach.remediation_status !== REMEDIATION_ACTED;

  const resolvedBreachesFilter = (breach) =>
    breach.remediation_status === REMEDIATION_ACTED;

  const showResolvedBreachDetails = (breach) => {
    dispatch(showPopup(POPUP_ADDRESSED_BREACHES, { breach: breach }));
  };

  const pushBreachDetails = (breach_id) => {
    const backQuery = {
      asset_public_id,
      breach_id: undefined,
    };
    const search = createQueryString({
      back: ROUTE_IDENTITY + "?" + createQueryString(backQuery),
      src: query.back ? decodeURIComponent(query.back): SCREEN_MAIN_DASHBOARD,
      asset_public_id,
      breach_id,
    });
    dispatch(
      push({
        pathname: ROUTE_IDENTITY,
        search,
      })
    );
  };

  const updateAssetContent = (unresolvedBreaches) => {
    if (!unresolvedBreaches.length > 0) {
      setAvatarImage(noBreachesImage);
      setContentGroup("identity.breachView.noBreach.");
    } else {
      setAvatarImage(breachesFoundImage);
      setContentGroup("identity.breachView.");
    }
  };

  useEffect(() => {
    if (ROUTE_IDENTITY === pathname) {
      if (!query.breach_id) {
        // setContentGroup("identity.breachView.");
        updateAssetContent(unresolvedBreaches);
        setBreachDetails(null);
      } else {
        setBreachDetails(query.breach_id);
      }
    }
  }, [pathname, query, unresolvedBreaches]);

  useEffect(() => {
    if (filteredBreaches) {
      setResolvedBreaches(filteredBreaches.filter(resolvedBreachesFilter));
      setUnresolvedBreaches(filteredBreaches.filter(unresolvedBreachesFilter));
    }
  }, [filteredBreaches]);
  useEffect(() => {
    updateAssetContent(unresolvedBreaches);
  }, [unresolvedBreaches]);
  const showBreachDetails = async (breach) => {
    setBreachDetails(breach.breach_id);
    pushBreachDetails(breach.breach_id);
  };

  const renderNoBreaches = (id) => {
    return (
      <div className="PersonalInfo__noBreach">
        <InfoOutlined className="PersonalInfo__noBreach__noBreachIcon" />
        <Paragraph>
          <String id={id} />
        </Paragraph>
      </div>
    );
  };

  const renderTabbedArea = () => {
    return (
      <>
        <Tabs activeId={activeId}>
          <Tab id={NEW_BREACH_TAB_ID} onClick={_onClick} className="pa-24">
            <String id={_S`newBreachesTab`} />
            <Tag variant="solid" kind="danger" className="px-16 mx-24 py-2">
              <Subheading className="text-white">
                {unresolvedBreaches.length}
              </Subheading>
            </Tag>
          </Tab>
          <Tab
            id={ADDRESSED_BREACH_TAB_ID}
            onClick={_onClick}
            className="pa-24"
          >
            <String id={_S`addressedBreachesTab`} />
            <Tag kind="neutral" className="px-16 mx-24 py-2">
              <Subheading className="text-body">
                {resolvedBreaches.length}
              </Subheading>
            </Tag>
          </Tab>
        </Tabs>
        {activeId === NEW_BREACH_TAB_ID && (
          <div>
            {Array.isArray(filteredBreaches) && (
              <List
                noBottom
                className="flex justify-content-evenly align-content-stretch direction-column"
              >
                {unresolvedBreaches.length > 0
                  ? unresolvedBreaches.map((breach) => (
                      <BreachRow
                        {...breach}
                        key={breach.breach_id}
                        onClick={() => showBreachDetails(breach)}
                      />
                    ))
                  : renderNoBreaches(_S`noBreaches`)}
              </List>
            )}
          </div>
        )}
        {activeId === ADDRESSED_BREACH_TAB_ID && (
          <>
            {Array.isArray(filteredBreaches) && (
              <List
                noBottom
                className="flex justify-content-evenly align-content-stretch direction-column"
              >
                {resolvedBreaches.length > 0
                  ? resolvedBreaches.map((breach) => (
                      <BreachRow
                        {...breach}
                        key={breach.breach_id}
                        onClick={() => showResolvedBreachDetails(breach)}
                      />
                    ))
                  : renderNoBreaches(_S`noAddressedBreaches`)}
              </List>
            )}
          </>
        )}
      </>
    );
  };

  const updateContent = (isKnown, hasPlainTextPassword) => {
    if (isKnown && hasPlainTextPassword) {
      setContentGroup(_S`knownSourcePlainText.`);
    } else if (isKnown) {
      setContentGroup(_S`knownSource.`);
    } else {
      setContentGroup(_S`unknownSource.`);
    }
  };

  return (
    <IdentityBreachLayout
      topProgress={topProgress}
      contentGroup={contentGroup}
      avatarImage={avatarImage}
    >
      {breachDetails ? (
        <BreachDetails breachId={breachDetails} updateContent={updateContent} />
      ) : (
        renderTabbedArea()
      )}
    </IdentityBreachLayout>
  );
}
