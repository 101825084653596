import React, { useEffect, useState, useCallback } from "react";
import { String } from "../../l10n/loader";
import { useDispatch, useSelector } from "react-redux";

// DOM
import { Loader, Button, Paragraph, Label, Link } from "@mcafee/pegasus";

import {
  ANALYTICS_DASHBOARD,
  ANALYTICS_HOME,
  ANALYTICS_LIFE_CYCLE,
  ANALYTICS_MANUAL,
  ANALYTICS_PROTECTION,
  ANALYTICS_SETTING,
  ANALYTICS_SUCCESS,
  EVENTID_PPS_SUPPORT_INITIATED,
  FEATURE_SUBSCRIPTION,
  HIT_CATEGORY,
  HIT_FEATURE,
  HIT_LABEL_0,
  HIT_LABEL_2,
  HIT_SCREEN,
  HIT_TRIGGER,
  MAX_ERROR_RETRY,
  MCAFEE_SUPPORT_URL,
  SCREEN_DETAILS,
  SCREEN_TYPE,
  SYNC,
} from "../../constants";
import "./syncSubscriptionPopup.scss";
import { fetchProductFeatures } from "../../store/thunks/experience";
import {
  collectScreenLoadEvent,
  isSubscriptionExpired,
  navToErrorPage,
  newGUID,
  subscriptionInitiatedEvent,
} from "../../utils";
import {
  ROUTE_DASHBOARD,
  ROUTE_EXPIRED_SUBSCRIPTION,
  ROUTE_SETTINGS_ACCOUNT,
} from "../app/routes";
import { uwpSendTelemetry } from "../../uwp";

import {
  SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_CHECKING,
  SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_NOT_FOUND,
  SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_ERROR,
  SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_SUCCESS,
  SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION_SYNCING_TRIAL_CONNECTING,
  SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION_TRIAL_STATUS_NOT_FOUND,
  SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION_SYNCING_TRIAL_ERROR,
  SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION_UPDATED_CONFIRMATION,
} from "../../constants/analyticsScreens";
import { ErrorOutlineRounded, InfoOutlined } from "@material-ui/icons";

export default function SyncSubscriptionPopup({ onCancel }) {
  const _S = (str) => `popups.${apiState}.${str}`;

  const pathname = useSelector((state) => state.router.location.pathname);
  const { subscriptionStatus, renewUrl } = useSelector(
    (state) => state.subscription
  );

  const [initDone, setInitDone] = useState(false);
  const [apiState, setApiState] = useState("loading");
  const [oldSubscriptionStatus] = useState(subscriptionStatus);
  const [newStatus, setNewStatus] = useState();
  const [syncError, setSyncError] = useState();
  const [retryCount, setRetryCount] = useState(0);

  const dispatch = useDispatch();

  const sendApiStateScreenLoadEvent = useCallback(
    (apiStatus, eventToTrigger = "default") => {
      if (
        [
          ROUTE_SETTINGS_ACCOUNT,
          ROUTE_EXPIRED_SUBSCRIPTION,
          ROUTE_DASHBOARD,
        ].includes(pathname)
      ) {
        const screenLoadEventData = {
          [ROUTE_DASHBOARD]: {
            feature: FEATURE_SUBSCRIPTION,
            category: ANALYTICS_PROTECTION,
            screenType: ANALYTICS_DASHBOARD,
            screenName: {
              loading: SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_CHECKING,
              error: SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_ERROR,
              notFound: SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_NOT_FOUND,
              success: SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_SUCCESS,
            },
            screenDetails: {
              loading: "trial_sync_checking",
              error: "trial_sync_error",
              notFound: "trial_sync_not_found",
              success: "trial_sync_success",
            },
          },
          [ROUTE_EXPIRED_SUBSCRIPTION]: {
            feature: FEATURE_SUBSCRIPTION,
            category: ANALYTICS_PROTECTION,
            screenType: ANALYTICS_DASHBOARD,
            screenName: {
              loading: SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_CHECKING,
              error: SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_ERROR,
              notFound: SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_NOT_FOUND,
              success: SCREEN_DASHBOARD_SUBSCRIPTION_SYNCING_TRIAL_SUCCESS,
            },
            screenDetails: {
              loading: "trial_sync_checking",
              error: "trial_sync_error",
              notFound: "trial_sync_not_found",
              success: "trial_sync_success",
            },
          },
          [ROUTE_SETTINGS_ACCOUNT]: {
            feature: ANALYTICS_LIFE_CYCLE,
            category: ANALYTICS_SETTING,
            screenType: ANALYTICS_SETTING,
            screenName: {
              loading:
                SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION_SYNCING_TRIAL_CONNECTING,
              error:
                SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION_SYNCING_TRIAL_ERROR,
              notFound:
                SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION_TRIAL_STATUS_NOT_FOUND,
              success:
                SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION_UPDATED_CONFIRMATION,
            },
            screenDetails: {
              loading: "syncing_trial",
              error: "syncing_trial_error",
              notFound: "trial_not_found",
              success: "updated_confirmation_sent",
            },
          },
        };
        if (eventToTrigger === "purchase") {
          // todo: update the trigger once the event definition is updated with new values
          subscriptionInitiatedEvent(
            screenLoadEventData[pathname].screenName[apiStatus],
            ANALYTICS_HOME
          );
        } else if (eventToTrigger === "support") {
          uwpSendTelemetry(EVENTID_PPS_SUPPORT_INITIATED, {
            [HIT_TRIGGER]: ANALYTICS_MANUAL,
            [HIT_SCREEN]: screenLoadEventData[pathname].screenName[apiStatus],
            [HIT_LABEL_0]: ANALYTICS_SUCCESS,
            [HIT_LABEL_2]: "subscription",
            hit_guid: newGUID(),
          });
        } else {
          collectScreenLoadEvent({
            [HIT_FEATURE]: screenLoadEventData[pathname].feature,
            [HIT_CATEGORY]: screenLoadEventData[pathname].category,
            [HIT_TRIGGER]: ANALYTICS_MANUAL,
            [HIT_SCREEN]: screenLoadEventData[pathname].screenName[apiStatus],
            [SCREEN_TYPE]: screenLoadEventData[pathname].screenType,
            [SCREEN_DETAILS]:
              screenLoadEventData[pathname].screenDetails[apiStatus],
          });
        }
      }
    },
    [pathname]
  );

  const tryFetchProductDefinition = useCallback(
    async (retryCount = 0) => {
      setSyncError(null);

      sendApiStateScreenLoadEvent("loading");
      const { ok, data, error } = await dispatch(
        fetchProductFeatures({
          fetchingOrigin: SYNC,
        })
      );

      if (ok && data) {
        const {
          subscription_info: { sub_status: subscriptionStatus },
        } = data;
        setNewStatus(subscriptionStatus.toLowerCase());
      } else if (error) {
        if (retryCount < MAX_ERROR_RETRY) {
          setSyncError(error);
          setRetryCount(retryCount + 1);
        } else {
          //close the popup and push Error page
          onCancel();
          navToErrorPage(error);
        }
      }
    },
    [dispatch, onCancel, sendApiStateScreenLoadEvent]
  );

  useEffect(() => {
    if (!initDone) {
      tryFetchProductDefinition();
      setInitDone(true);
    }
  }, [initDone, tryFetchProductDefinition]);

  useEffect(() => {
    if (syncError) {
      // error has bigger priority in case the cached call works but the live data doesn't
      setApiState("error");
      sendApiStateScreenLoadEvent("error");
    } else if (newStatus !== undefined) {
      if (isSubscriptionExpired(newStatus)) {
        //Expired state
        if (isSubscriptionExpired(oldSubscriptionStatus)) {
          //Already expired, no change
          setApiState("expired");
          sendApiStateScreenLoadEvent("notFound");
        } else {
          //Switched from Active to Expired
          onCancel();
        }
      } else {
        //Active status
        if (oldSubscriptionStatus !== newStatus) {
          // Switched from a different state
          setApiState("success");
          sendApiStateScreenLoadEvent("success");
          setTimeout(onCancel, 5000);
        } else {
          //No change in active state
          setApiState("notFound");
          sendApiStateScreenLoadEvent("notFound");
        }
      }
    }
  }, [
    syncError,
    onCancel,
    sendApiStateScreenLoadEvent,
    newStatus,
    oldSubscriptionStatus,
  ]);

  const onSupportClick = () => {
    sendApiStateScreenLoadEvent(apiState, "support");
    window.open(MCAFEE_SUPPORT_URL, "_blank");
  };

  const onTryAgain = (e) => {
    setApiState("loading");
    sendApiStateScreenLoadEvent("loading");
    tryFetchProductDefinition(retryCount);
  };

  const onSubscribeNowClick = () => {
    sendApiStateScreenLoadEvent(apiState, "purchase");
    window.open(renewUrl, "_blank");
  };

  const renderSupportButton = (stringID) => ({
    link: (
      <Label
        className="text-info syncSubscriptionPopup__link"
        size="sm"
        onClick={onSupportClick}
        id="SubscriptionSupport"
      >
        <String id={stringID} />
      </Label>
    ),
  });

  const renderLoadingState = () => (
    <div className="syncSubscriptionPopup">
      <div className="syncSubscriptionPopup__apiStatusContainer">
        <Loader size="xl" />
        <Label size="sm" className="syncSubscriptionPopup__apiStatusLabel">
          <String id={_S`header`} />
        </Label>
      </div>
      <Button
        variant="secondary"
        className="syncSubscriptionPopup__apiStatusButton"
        id="SubscriptionCancel"
        onClick={onCancel}
      >
        <String id={_S`button`} />
      </Button>
    </div>
  );

  const renderApiResultState = () => (
    <div className="syncSubscriptionPopup">
      <div className="syncSubscriptionPopup__apiStatusContainer">
        {apiState === "error" ? (
          <ErrorOutlineRounded
            className={`syncSubscriptionPopup__apiStatusIcon--${apiState}`}
          />
        ) : (
          <InfoOutlined
            className={`syncSubscriptionPopup__apiStatusIcon--${apiState}`}
          />
        )}
        <Label size="sm" className="syncSubscriptionPopup__apiStatusLabel">
          <String id={_S`header`} />
        </Label>
        <Paragraph size="sm">
          <String
            id={_S`content`}
            values={{
              tryAgain: (
                <Link onClick={onTryAgain}>
                  <String id="popups.tryAgain" />
                </Link>
              ),
            }}
          />
        </Paragraph>
        {apiState === "notFound" && (
          <Paragraph size="sm" className="mt-12">
            <String
              id={_S`content2`}
              values={renderSupportButton(_S("support"))}
            />
          </Paragraph>
        )}
        {apiState === "expired" && (
          <Label
            className="text-info syncSubscriptionPopup__link mt-12"
            size="sm"
            onClick={onSubscribeNowClick}
            id="SubscriptionGetItNow"
          >
            <String id={_S`link`} />
          </Label>
        )}
      </div>
      {apiState !== "success" && (
        <Button
          variant="secondary"
          className="syncSubscriptionPopup__apiStatusButton"
          onClick={onCancel}
          id={
            apiState === "notFound" ? "SubscriptionGotIt" : "SubscriptionGoBack"
          }
        >
          <String id={_S`button`} />
        </Button>
      )}
    </div>
  );

  return apiState === "loading" ? renderLoadingState() : renderApiResultState();
}
