const actions={
  checkSignedIn: "$checkSignedIn",
  go: {
    regex: /^\$(go|replace)\/(.+)/,
    welcome1: "$go/welcome1",
    welcome2: "$go/welcome2",
    welcome3: "$go/welcome3",
    account: "$go/account",
    dws_welcome: "$go/dws_welcome",
    dashboard: "$go/dashboard",
    services: "$go/services",
    settings: "$go/settings",
    dws_email: "$go/dws_email",
    dws_dashboard: "$go/dws_dashboard",
    dws_threat_details: "$go/dws_threat_details",
  },
  open: {
    legal: "$open/legal",
    privacy: "$open/privacy",
    survey: "$open/survey",
  },
  popup: {
    survey: "$popup/survey",
  },
  call: {
    account: "$call/account",
    dwsResetEmail: "$call/dwsResetEmail",
    idsMonitorEmail: "$call/idsMonitorEmail", //Unreferenced
    isValidSubscription: "$call/isValidSubscription",
    identityEnrollOrDashboard: "$call/identityEnrollOrDashboard",
    updateRemoteConfig: "$call/updateRemoteConfig",
    checkShowPreExpiryPopup: "$call/checkShowPreExpiryPopup",
  },
  show: {
    wellDone: "$show/wellDone",
  },
  doneWelcome: "$doneWelcome",
  trackAcceptEula: "$trackAcceptEula",
  back: "$back",
  setCardVisibility: "setCardVisibility",
};

export default actions;