// React
import React from "react";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import Placeholder from "../_assets/images/dashboard/cards/placeholder.svg";

// ToDo: No longer supported/Priorty. Is to be Removed?
export default function PasswordChange({ id }) {
  const card = {
    id: id,
    backgroundImage: Placeholder,
    onPrimaryAction: () => {},
    showDismiss: true,
  };

  return <DashboardCard {...card} />;
}
