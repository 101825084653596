import React from "react";
import { useDispatch, useSelector } from "react-redux";

import WellDoneOverlay from "../IdentityBreachDetails/WellDoneOverlay";

import { closeOverlay } from "../../store/overlays";

import "./overlays.scss";

const UnknownOverlay = () => {
  return <div className="pa-32">Unknown overlay</div>;
};

const OverlayComponent = ({ overlay, props }) => {
  const Overlay =
    {
      WellDoneOverlay,
    }[overlay] || UnknownOverlay;

  return <Overlay {...props} />;
};

export default function Overlays() {
  const overlayList = useSelector((state) => {
    return state.overlays.list;
  });
  const dispatch = useDispatch();

  return (
    <>
      {overlayList.map((info, index) => {
        const { overlay, props } = info;
        const handleFinish = (results = true) => {
          const {
            handleFinish,
            onFinish,
            onFinishHandler,
            onSubmit,
            onSuccess,
          } = info.props;
          const submitHandler =
            handleFinish ||
            onFinish ||
            onSubmit ||
            onSuccess ||
            onFinishHandler;
          if (submitHandler) {
            submitHandler(results);
          }
          dispatch(closeOverlay({ overlay, results, props }));
        };
        const handleClose = (e, results = undefined) => {
          dispatch(closeOverlay({ overlay, results, props }));

          const { onCancel, onClose, onCancelHandler, onCloseHandler } =
            info.props || {};
          const cancelHandler =
            onCancel || onClose || onCancelHandler || onCloseHandler;
          if (cancelHandler) {
            cancelHandler(results);
          }
        };
        return (
          <div className="overlays" key={index}>
            <OverlayComponent
              overlay={overlay}
              props={{
                ...props,
                ...{
                  handleFinish,
                  onFinish: handleFinish,
                  onFinishHandler: handleFinish,
                  onSubmit: handleFinish,
                  onSuccess: handleFinish,

                  handleClose,
                  onCancel: handleClose,
                  onClose: handleClose,
                  onCancelHandler: handleClose,
                  onCloseHandler: handleClose,
                },
              }}
            />
          </div>
        );
      })}
    </>
  );
}
