import { createSlice } from "@reduxjs/toolkit";
import {
  CARD_ACCOUNT_BREACHED,
  CARD_OPEN_WIFI_ALERT,
  CARD_UNSAFE_WIFI_ALERT,
  CARD_BREACH_LEARN_MORE,
  CARD_BREACH_SMS_NOTIFICATION,
  CARD_EMAIL_VERIFICATION_NO_BREACHES_FOUND,
  CARD_EMAIL_VERIFICATION_BREACHES_FOUND,
  CARD_ONBOARD_DWS_SKIPPED,
  CARD_ADD_VPN_RECOMMENDED,
  CARD_AV_TURN_ON_RTS,
  CARD_AV_FOUND_THREATS,
  CARD_AV_PROTECTING_YOU
} from "../components/DashboardCards/constants";
import { signOutSuccess, signInSuccess } from "./auth";

const initialState = {
  fetching: false,
  cards: null,
  error: null,
  cardsView: {
    //Cards with client rules initialized as hidden
    [CARD_ADD_VPN_RECOMMENDED]: false,
    [CARD_UNSAFE_WIFI_ALERT]: false,
    [CARD_OPEN_WIFI_ALERT]: false,
    [CARD_ACCOUNT_BREACHED]: false,
    [CARD_EMAIL_VERIFICATION_NO_BREACHES_FOUND]: false,
    [CARD_EMAIL_VERIFICATION_BREACHES_FOUND]: false,
    [CARD_ONBOARD_DWS_SKIPPED]: false,
    [CARD_AV_TURN_ON_RTS] : false,
    [CARD_AV_FOUND_THREATS] : false,
    [CARD_AV_PROTECTING_YOU] : false,
    //Dismissible cards
    [CARD_BREACH_LEARN_MORE]: true,
    [CARD_BREACH_SMS_NOTIFICATION]: true,
  },

  scheduledCards: {},

  updateMobileProgress: false,
  updateMobileError: null,
  fetchMobileProgress: false,
  fetchMobileError: null,
  mobileNumber: null,
  deleteMobileProgress: false,
  deleteMobileError: null,
  emailKeyCardProgress: false,
  emailKeyCardError: null,
  protectEmailState: null,
};

// Reducers
const slice = createSlice({
  name: "@dashboard",
  initialState,
  reducers: {
    dashboardCardsBegin(state, action) {
      state.fetching = true;
      state.error = null;
    },
    dashboardCardsSuccess(state, action) {
      const { cards, cached = false } = action.payload;
      state.error = null;
      state.cards = cards;
      if (!cached) {
        state.fetching = false;
      }
    },
    dashboardCardsFailure(state, action) {
      state.fetching = false;
      const { cached } = action.payload;
      if (!cached) {
        state.error = action.payload;
        state.cards = null;
      }
    },
    //
    updateMobileNumberBegin(state) {
      state.updateMobileProgress = true;
      state.updateMobileError = null;
    },
    updateMobileNumberSuccess(state, action) {
      state.updateMobileProgress = false;
      state.mobileNumber = action.payload;
    },
    updateMobileNumberFailure(state, action) {
      state.updateMobileProgress = false;
      state.updateMobileError = action.payload;
    },
    deleteMobileNumberBegin(state) {
      state.deleteMobileProgress = true;
      state.deleteMobileError = null;
    },
    deleteMobileNumberSuccess(state) {
      state.deleteMobileProgress = false;
      state.mobileNumber = null;
    },
    deleteMobileNumberFailure(state, action) {
      state.deleteMobileProgress = false;
      state.deleteMobileError = action.payload;
    },
    fetchMobileNumberBegin(state) {
      state.fetchMobileProgress = true;
      state.fetchMobileError = null;
      state.mobileNumber = undefined;
    },
    fetchMobileNumberSuccess(state, action) {
      const { cached, value } = action.payload;
      if (!cached) {
        state.fetchMobileProgress = false;
      }
      state.mobileNumber = value;
    },
    fetchMobileNumberFailure(state, action) {
      state.fetchMobileProgress = false;
      if (!action.payload.cached) {
        state.fetchMobileError = action.payload;
      }
    },
    //
    dashboardDismissCard(state, action) {
      state.cards = state.cards.filter((card) => card.id !== action.payload);
    },
    // Protect Email Device
    emailKeyCardBegin(state) {
      state.emailKeyCardProgress = true;
      state.emailKeyCardError = null;
    },
    emailKeyCardSuccess(state) {
      state.emailKeyCardProgress = false;
    },
    emailKeyCardFailure(state, action) {
      state.emailKeyCardProgress = false;
      state.emailKeyCardError = action.payload;
    },
    dashboardShowCard(state, action) {
      const { card, show } = action.payload;
      state.cardsView[card] = show;
    },
  },
  extraReducers: {
    [signInSuccess]: (state) => {
      state.error = null; //In order for Home screen to fetch the cards again
    },
    [signOutSuccess]: (state) => {
      state.cards = null;
      state.cardsView = initialState.cardsView;
      state.mobileNumber = null;
    },
  },
});

export const {
  dashboardCardsBegin,
  dashboardCardsSuccess,
  dashboardCardsFailure,

  dashboardDismissCard,

  dashboardShowCard,
  scheduleCards,

  updateMobileNumberBegin,
  updateMobileNumberSuccess,
  updateMobileNumberFailure,

  fetchMobileNumberBegin,
  fetchMobileNumberSuccess,
  fetchMobileNumberFailure,

  deleteMobileNumberBegin,
  deleteMobileNumberSuccess,
  deleteMobileNumberFailure,

  emailKeyCardBegin,
  emailKeyCardSuccess,
  emailKeyCardFailure,
} = slice.actions;

export default slice.reducer;
