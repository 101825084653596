import { v4 as uuidv4 } from "uuid";
import {
  ca_engine_start,
  fillURL,
  shutDownProcessing,
  Event,
  updateContext,
} from "@mcafee/analytics-sdk-web";

import {
  ROUTE_ACCOUNT,
  ROUTE_ACCOUNT_CREATION_COMPLETE,
  ROUTE_DASHBOARD,
  ROUTE_DEVICES,
  ROUTE_DISCONNECT_WIFI,
  ROUTE_DISCONNECT_WIFI_DONE,
  ROUTE_HACK_BLOCK,
  ROUTE_IDENTITY,
  ROUTE_SCAN_EMAIL,
  ROUTE_SCAN_EMAIL_RESULT,
  ROUTE_SECURE_VPN,
  ROUTE_SETTINGS_ABOUT,
  ROUTE_SETTINGS_ACCOUNT,
  ROUTE_SETTINGS_NOTIFICATIONS,
  ROUTE_SETTINGS_VPN,
  ROUTE_VPN_SETUP,
  ROUTE_VPN_SETUP_DISCLOSURE,
  ROUTE_VPN_SETUP_RUN,
  ROUTE_WELCOME,
  ROUTE_WELCOME1,
  ROUTE_WELCOME2,
  ROUTE_ROOT,
  ROUTE_PRIVACY,
  ROUTE_WELCOME3,
  ROUTE_SERVICES,
  ROUTE_BREACH_DETAILS,
  ROUTE_OPEN_SOURCE_LICENSES,
  ROUTE_SETTINGS_IDENTITY,
  ROUTE_MONITOR_ASSET_DONE,
} from "../components/app/routes";

import {
  SCREEN_BREACHES_FOUND_RESULTS,
  SCREEN_BREACH_LIST,
  SCREEN_DWS_INTRO,
  SCREEN_MAIN_DASHBOARD,
  SCREEN_ONBOARDING_CREATE_ACCOUNT_CREATED,
  SCREEN_ONBOARDING_QUICK_TOUR,
  SCREEN_SETTINGS_ABOUT_US,
  SCREEN_SETTINGS_ACCOUNT_DEFAULT,
  SCREEN_SETTINGS_NOTIFICATIONS_LIST,
  SCREEN_SETTINGS_VPN_WHEN_TO_CONNECT,
  SCREEN_VPN_NETWORK_ATTACK_DISCONNECT_WIFI,
  SCREEN_VPN_NETWORK_ATTACK_SAFETY_TIPS,
  SCREEN_VPN_NETWORK_ATTACK_WIFI_DISCONNECTED,
  SCREEN_VPN_OVERVIEW,
  SCREEN_VPN_SETUP_CONFIGURING,
  SCREEN_VPN_SETUP_DATA_DISCLOSURE,
  SCREEN_VPN_SETUP_LANDING_PAGE,
  ANALYTICS_DETAILS,
  ANALYTICS_MENU,
  ANALYTICS_SERVICES_CARD,
  ANALYTICS_SETTING,
  ANALYTICS_PROTECTION,
  ANALYTICS_EDUCATION,
  ANALYTICS_PROGRESS,
  ANALYTICS_ERROR,
  ANALYTICS_ENGAGEMENT,
  ANALYTICS_LIFE_CYCLE,
  ANALYTICS_ID_PROTECTION,
  ANALYTICS_MANUAL,
  ANALYTICS_LIST,
  ANALYTICS_FORM,
  EVENTID_SCREEN_LOAD,
  HIT_TYPE_SCREEN,
  ANALYTICS_SUCCESS,
  SCREEN_DEFAULT_LOAD_TIME,
  SCREEN_CARD_OR_SCREEN,
  SCREEN_LOAD_TIME,
  SCREEN_SEQUENCE_ID,
  SCREEN_LOAD_RESULT,
  HIT_TRIGGER,
  ANALYTICS_ONBOARDING,
  SCREEN_SERVICES_SECURE_VPN,
  SCREEN_SETTINGS_NOTIFICATIONS_VPN,
  SCREEN_SETTINGS_VPN_SETTINGS_OVERVIEW,
  SCREEN_SERVICES_IDENTITY,
  SCREEN_VPN_SETUP_COMPLETE,
  SCREEN_VPN_SETUP_ERROR_RETRY,
  SCREEN_VPN_SETUP_ERROR_FAILED,
  SCREEN_ONBOARDING_QUICK_TOUR_LANDING,
  SCREEN_DWS_SCANNING,
  SCREEN_DWS_SCAN_DONE,
  SCREEN_BREACH_LIST_DELETE_EMAIL,
  SCREEN_DWS_TOOLTIP,
  SCREEN_DWS_SCAN_VERIFY_EMAIL,
  SCREEN_BREACHES_FOUND,
  SCREEN_DWS_SCAN_RESULTS,
  SCREEN_DWS_SCAN_EXIT_VERIFICATION,
  SCREEN_BREACH_DETAIL,
  SCREEN_BREACH_DETAIL_CHANGE_PWD_UNVERIFIED,
  SCREEN_BREACH_DETAIL_CHANGE_PWD_VERIFIED,
  SCREEN_BREACH_DETAIL_CHANGE_PWD_IN_APP_BROWSER,
  SCREEN_BREACH_DETAIL_CONFIRM_PWD_CHANGE,
  SCREEN_BREACH_DETAIL_CONFIRMATION,
  SCREEN_BREACH_DETAIL_BREACH_DETAILS_RESOLVED,
  SCREEN_BREACH_DETAIL_CHANGE_PWD,
  SCREEN_BREACH_DETAIL_REMINDER_CONFIRMATION,
  SCREEN_BREACH_DETAIL_CHANGE_PWD_OPTION,
  SCREEN_ADD_EMAIL,
  SCREEN_BREACH_DETAIL_OTP,
  SCREEN_ONBOARDING_QUICK_TOUR_TOOLTIP,
  SCREEN_ONBOARDING_CREATE_ACCOUNT,
  SCREEN_ONBOARDING_SIGN_IN,
  SCREEN_SPLASH,
  VPN_STATE_CONNECTED,
  VPN_STATE_DISCONNECTED,
  LOG_LEVEL_ERROR,
  APP_ID,
  SS_PAGE_SEQUENCE,
  ANALYTICS_SERVICES,
  HIT_SCREEN,
  SCREEN_TYPE,
  HIT_FEATURE,
  HIT_CATEGORY,
  SCREEN_DETAILS,
  ANALYTICS_AUTHENTICATION,
  SCREEN_SETTINGS_MANAGE_EMAILS,
  HIT_LABEL_0,
  EVENTID_PPS_USER_SUBSCRIPTION_INITIATED,
  EVENTID_PPS_PURCHASE_INITIATED,
  HIT_LABEL_2,
  ANALYTICS_MONITOR_NEW_EMAIL,
  FEATURE_VPN,
} from "../constants";

import { store } from "../store/store";
import { waitFor, stringifyJson } from "./main";
import { uwpLog, uwpSendTelemetry } from "../uwp";
import { analyzeBreachDetails } from "./dws";

import { DEFAULT_PPS_UI_CONTEXT } from "../constants/analytics";
import {
  getProductSubscriptionStatus,
  getSubscriptionType,
} from "./subscription";

/**
 * API header values added for analytics purpose
 * @param {Object} state redux store state copy
 * @param {string} hit_trigger API trigger value
 */
export const analyticsHeaders = (state, hit_trigger) => {
  const client_id = state.context.cspid;
  const product_app_id = APP_ID;
  return {
    client_context: stringifyJson({ client_id, product_app_id, hit_trigger }),
    traceid: newGUID(),
  };
};

/**
 * Returns analytics screen name from current route
 */
export const currentScreenName = () => {
  const { pathname } = store.getState().router.location;
  return screenNameFromRouterPath(pathname);
};

/**
 * Format a screen name from current route path
 * @param {string} pathname router pathname
 */
export const screenNameFromRouterPath = (pathname) => {
  //TODO: keep reviewing this list for newly added screens
  const routeToScreenName = {
    [ROUTE_ACCOUNT_CREATION_COMPLETE]: SCREEN_ONBOARDING_CREATE_ACCOUNT_CREATED,
    [ROUTE_WELCOME]: SCREEN_ONBOARDING_QUICK_TOUR,
    [ROUTE_WELCOME1]: SCREEN_ONBOARDING_QUICK_TOUR,
    [ROUTE_WELCOME2]: SCREEN_ONBOARDING_QUICK_TOUR,
    [ROUTE_WELCOME2]: SCREEN_ONBOARDING_QUICK_TOUR,
    [ROUTE_SCAN_EMAIL]: SCREEN_DWS_INTRO,
    [ROUTE_SCAN_EMAIL_RESULT]: SCREEN_BREACHES_FOUND_RESULTS,
    [ROUTE_MONITOR_ASSET_DONE]: SCREEN_DWS_SCAN_RESULTS,
    [ROUTE_ACCOUNT]: SCREEN_ONBOARDING_QUICK_TOUR,
    [ROUTE_DASHBOARD]: SCREEN_MAIN_DASHBOARD,
    [ROUTE_IDENTITY]: SCREEN_BREACH_LIST,
    [ROUTE_SETTINGS_ABOUT]: SCREEN_SETTINGS_ABOUT_US,
    [ROUTE_SETTINGS_NOTIFICATIONS]: SCREEN_SETTINGS_NOTIFICATIONS_LIST,
    [ROUTE_SETTINGS_ACCOUNT]: SCREEN_SETTINGS_ACCOUNT_DEFAULT,
    [ROUTE_SETTINGS_IDENTITY]: SCREEN_SETTINGS_MANAGE_EMAILS,
    [ROUTE_VPN_SETUP]: SCREEN_VPN_SETUP_LANDING_PAGE,
    [ROUTE_HACK_BLOCK]: SCREEN_VPN_NETWORK_ATTACK_SAFETY_TIPS,
    [ROUTE_DISCONNECT_WIFI]: SCREEN_VPN_NETWORK_ATTACK_DISCONNECT_WIFI,
    [ROUTE_DISCONNECT_WIFI_DONE]: SCREEN_VPN_NETWORK_ATTACK_WIFI_DISCONNECTED,
    [ROUTE_VPN_SETUP_DISCLOSURE]: SCREEN_VPN_SETUP_DATA_DISCLOSURE,
    [ROUTE_VPN_SETUP_RUN]: SCREEN_VPN_SETUP_CONFIGURING,
    [ROUTE_SECURE_VPN]: SCREEN_VPN_OVERVIEW,
    [ROUTE_SETTINGS_VPN]: SCREEN_SETTINGS_VPN_WHEN_TO_CONNECT,
    // [ROUTE_DEVICES]: "devices-device_list",
  };

  const screenFromPath = routeToScreenName[pathname];

  if (screenFromPath) {
    return screenFromPath;
  }

  return undefined;
};

export const newPageSequence = () => {
  let pageSequence = sessionStorage.getItem(SS_PAGE_SEQUENCE);

  try {
    pageSequence = parseInt(pageSequence);
    if (isNaN(pageSequence)) {
      pageSequence = 0;
    }
  } catch (e) {
    pageSequence = 0;
  }

  sessionStorage.setItem(SS_PAGE_SEQUENCE, ++pageSequence);

  return pageSequence;
};

export const newGUID = () => {
  return uuidv4().toUpperCase();
};

export function getFeature(routeName) {
  let feature;
  switch (routeName) {
    case ROUTE_ROOT:
      feature = ANALYTICS_ONBOARDING;
      break;
    case ROUTE_SERVICES:
      feature = ANALYTICS_SERVICES;
      break;
    case ROUTE_BREACH_DETAILS:
    case ROUTE_DEVICES:
    case ROUTE_PRIVACY:
      feature = "identity";
      break;
    case ROUTE_OPEN_SOURCE_LICENSES:
      feature = "settings";
      break;
    case ROUTE_SETTINGS_NOTIFICATIONS:
    case ROUTE_WELCOME:
    case ROUTE_WELCOME1:
    case ROUTE_WELCOME2:
    case ROUTE_WELCOME3:
    case ROUTE_ACCOUNT:
    case ROUTE_ACCOUNT_CREATION_COMPLETE:
    case ROUTE_SETTINGS_ABOUT:
    case ROUTE_SETTINGS_ACCOUNT:
      feature = ANALYTICS_LIFE_CYCLE;
      break;
    case ROUTE_SETTINGS_VPN:
    case ROUTE_HACK_BLOCK:
    case ROUTE_DISCONNECT_WIFI:
    case ROUTE_DISCONNECT_WIFI_DONE:
    case ROUTE_VPN_SETUP_DISCLOSURE:
    case ROUTE_VPN_SETUP_RUN:
    case ROUTE_SECURE_VPN:
    case ROUTE_VPN_SETUP:
      feature = "vpn";
      break;
    case ROUTE_IDENTITY:
    case ROUTE_SCAN_EMAIL:
    case ROUTE_SCAN_EMAIL_RESULT:
    case ROUTE_MONITOR_ASSET_DONE:
    case ROUTE_SETTINGS_IDENTITY:
      feature = ANALYTICS_ID_PROTECTION;
      break;

    case ROUTE_DASHBOARD:
    default:
      feature = "dashboard";
  }
  return feature;
}

export function getScreenType(screenName, store) {
  let type;
  switch (screenName) {
    case SCREEN_SETTINGS_ABOUT_US:
    case SCREEN_VPN_OVERVIEW:
    case SCREEN_BREACH_DETAIL:
    case SCREEN_BREACH_DETAIL_CHANGE_PWD_UNVERIFIED:
    case SCREEN_BREACH_DETAIL_CHANGE_PWD_VERIFIED:
    case SCREEN_BREACH_DETAIL_CHANGE_PWD_IN_APP_BROWSER:
    case SCREEN_BREACH_DETAIL_BREACH_DETAILS_RESOLVED:
    case SCREEN_BREACH_DETAIL_CHANGE_PWD:
    case SCREEN_BREACH_DETAIL_CHANGE_PWD_OPTION:
      type = ANALYTICS_DETAILS;
      break;
    case SCREEN_SETTINGS_VPN_SETTINGS_OVERVIEW:
    case SCREEN_SETTINGS_NOTIFICATIONS_VPN:
    case SCREEN_SETTINGS_NOTIFICATIONS_LIST:
      type = ANALYTICS_MENU;
      break;
    case SCREEN_SERVICES_SECURE_VPN:
    case SCREEN_SERVICES_IDENTITY:
      type = ANALYTICS_SERVICES_CARD;
      break;
    case SCREEN_ONBOARDING_QUICK_TOUR:
    case SCREEN_VPN_NETWORK_ATTACK_SAFETY_TIPS:
    case SCREEN_VPN_SETUP_LANDING_PAGE:
    case SCREEN_VPN_SETUP_DATA_DISCLOSURE:
    case SCREEN_VPN_NETWORK_ATTACK_DISCONNECT_WIFI:
    case SCREEN_VPN_NETWORK_ATTACK_WIFI_DISCONNECTED:
    case SCREEN_DWS_INTRO:
      // ROUTE_ACCOUNT is the main login screen;
      let isLoginScreen =
        store && store.getState().router.location.pathname === ROUTE_ACCOUNT;
      type = isLoginScreen ? ANALYTICS_PROGRESS : ANALYTICS_EDUCATION;
      break;
    case SCREEN_VPN_SETUP_CONFIGURING:
    case SCREEN_ONBOARDING_QUICK_TOUR_LANDING:
    case SCREEN_ONBOARDING_CREATE_ACCOUNT_CREATED:
    case SCREEN_DWS_SCAN_DONE:
    case SCREEN_DWS_SCAN_EXIT_VERIFICATION:
    case SCREEN_BREACH_DETAIL_CONFIRMATION:
    case SCREEN_BREACH_DETAIL_REMINDER_CONFIRMATION:
    case SCREEN_DWS_SCANNING: // THIS SCREEN CAN HAVE VALUE ERROR
      type = ANALYTICS_PROGRESS;
      break;
    case SCREEN_VPN_SETUP_ERROR_FAILED:
    case SCREEN_VPN_SETUP_ERROR_RETRY:
      type = ANALYTICS_ERROR;
      break;
    case SCREEN_BREACHES_FOUND_RESULTS:
    case SCREEN_BREACH_LIST:
    case SCREEN_BREACH_LIST_DELETE_EMAIL:
    case SCREEN_BREACHES_FOUND:
    case SCREEN_DWS_SCAN_RESULTS:
    case SCREEN_SETTINGS_ACCOUNT_DEFAULT:
      type = ANALYTICS_LIST;
      break;
    case SCREEN_ADD_EMAIL:
    case SCREEN_BREACH_DETAIL_OTP:
    case SCREEN_DWS_SCAN_VERIFY_EMAIL: // THIS SCREEN CAN HAVE VALUE ERROR
      type = ANALYTICS_FORM;
      break;
    default:
      type = "";
  }
  return type;
}

export function getScreenCategory(screenName) {
  let category;
  switch (screenName) {
    case SCREEN_BREACH_LIST_DELETE_EMAIL:
    case SCREEN_SETTINGS_ABOUT_US:
    case SCREEN_SETTINGS_NOTIFICATIONS_VPN:
    case SCREEN_SETTINGS_VPN_SETTINGS_OVERVIEW:
    case SCREEN_SETTINGS_ACCOUNT_DEFAULT:
    case SCREEN_SETTINGS_MANAGE_EMAILS:
      category = ANALYTICS_SETTING;
      break;
    case SCREEN_ONBOARDING_QUICK_TOUR:
    case SCREEN_ONBOARDING_QUICK_TOUR_LANDING:
    case SCREEN_ONBOARDING_CREATE_ACCOUNT_CREATED:
    case SCREEN_SERVICES_IDENTITY:
    case SCREEN_SERVICES_SECURE_VPN:
    case SCREEN_VPN_SETUP_LANDING_PAGE:
    case SCREEN_VPN_SETUP_DATA_DISCLOSURE:
    case SCREEN_VPN_SETUP_COMPLETE:
    case SCREEN_VPN_SETUP_CONFIGURING:
    case SCREEN_VPN_SETUP_ERROR_RETRY:
    case SCREEN_VPN_SETUP_ERROR_FAILED:
    case SCREEN_VPN_OVERVIEW:
    case SCREEN_VPN_NETWORK_ATTACK_DISCONNECT_WIFI:
    case SCREEN_VPN_NETWORK_ATTACK_WIFI_DISCONNECTED:
    case SCREEN_DWS_INTRO:
    case SCREEN_DWS_SCAN_DONE:
    case SCREEN_BREACHES_FOUND_RESULTS:
    case SCREEN_BREACH_LIST:
    case SCREEN_DWS_SCANNING:
    case SCREEN_BREACHES_FOUND:
    case SCREEN_DWS_SCAN_RESULTS:
    case SCREEN_DWS_SCAN_EXIT_VERIFICATION:
    case SCREEN_BREACH_DETAIL:
    case SCREEN_BREACH_DETAIL_CHANGE_PWD_UNVERIFIED:
    case SCREEN_BREACH_DETAIL_CHANGE_PWD_VERIFIED:
    case SCREEN_BREACH_DETAIL_CHANGE_PWD_IN_APP_BROWSER:
    case SCREEN_BREACH_DETAIL_CONFIRM_PWD_CHANGE:
    case SCREEN_BREACH_DETAIL_CONFIRMATION:
    case SCREEN_BREACH_DETAIL_BREACH_DETAILS_RESOLVED:
    case SCREEN_BREACH_DETAIL_CHANGE_PWD:
    case SCREEN_BREACH_DETAIL_CHANGE_PWD_OPTION:
      category = ANALYTICS_PROTECTION;
      break;
    case SCREEN_DWS_TOOLTIP:
      category = ANALYTICS_EDUCATION;
      break;
    case SCREEN_VPN_NETWORK_ATTACK_SAFETY_TIPS:
      category = ANALYTICS_ENGAGEMENT;
      break;
    case SCREEN_DWS_SCAN_VERIFY_EMAIL:
      category = "authentication";
      break;
    // case SCREEN_BREACH_DETAIL_REMINDER_CONFIRMATION: // screen not implemented yet
    //   category = ANALYTICS_PROTECTION || ANALYTICS_ENGAGEMENT;
    //   break;
    default:
      category = "";
  }
  return category;
}

export async function getScreenDetails(screenName, store) {
  let screenDetails;
  switch (screenName) {
    case SCREEN_BREACH_LIST:
      await waitFor(() => store.getState().identity.breaches !== null);
      screenDetails =
        store.getState().identity.breaches &&
        store.getState().identity.breaches.length > 0
          ? "urgent_breaches_found"
          : "no_breaches_found";
      break;
    case SCREEN_BREACHES_FOUND_RESULTS:
      await waitFor(() => store.getState().dws.breachCount !== null);
      screenDetails = store.getState().dws.breachCount;
      break;
    case SCREEN_BREACH_DETAIL:
      screenDetails = "";
      break;
    case SCREEN_ONBOARDING_QUICK_TOUR:
      const matchWelcome = store
        .getState()
        .router.location.pathname.match(RegExp(ROUTE_WELCOME + "/([0-9])"));

      if (matchWelcome) {
        screenDetails = `value_${matchWelcome[1] || "1"}`;
      } else {
        screenDetails = "landing";
      }

      break;
    case SCREEN_DWS_INTRO:
    case SCREEN_DWS_SCANNING:
    case SCREEN_DWS_SCAN_DONE:
      const { path } = store ? store.getState().router.location.query : {};

      screenDetails = path ? "identity" : "first_value";
      break;
    case SCREEN_VPN_OVERVIEW:
      await waitFor(() =>
        [VPN_STATE_CONNECTED, VPN_STATE_DISCONNECTED].includes(
          store.getState().vpn.state
        )
      );
      screenDetails = store.getState().vpn.state.toLowerCase();
      break;
    case SCREEN_DWS_SCAN_RESULTS:
      await waitFor(() => store.getState().identity.breaches !== null);

      const {
        identity: { breaches },
        otp: { verifiedEmail },
      } = store.getState();
      const breachesFound = breaches.find(
        (breach) => breach.email === verifiedEmail
      );

      screenDetails = breachesFound
        ? "unverified_breaches_found"
        : "unverified_no_breaches_found";
      break;
    case SCREEN_VPN_SETUP_LANDING_PAGE:
      screenDetails = "setup_landing_page_details";
      break;
    default:
      screenDetails = null;
  }
  return screenDetails;
}

export function getTrigger(screenName, state, prevState) {
  let trigger = ANALYTICS_MANUAL;

  if (state && state.router.location.query.src) {
    trigger = state.router.location.query.src;
  } else {
    switch (screenName) {
      case SCREEN_SETTINGS_VPN_WHEN_TO_CONNECT:
        trigger = "vpn_preferences";
        break;
      case SCREEN_SETTINGS_NOTIFICATIONS_LIST:
        trigger = "notification_preferences";
        break;
      case SCREEN_ONBOARDING_SIGN_IN:
      case SCREEN_ONBOARDING_CREATE_ACCOUNT:
        trigger = ANALYTICS_ONBOARDING;
        break;
      case SCREEN_DWS_SCAN_RESULTS:
        trigger =
          state.router.location.query.path === "monitor_new_email"
            ? ANALYTICS_MONITOR_NEW_EMAIL
            : ANALYTICS_MANUAL;
        break;
      case SCREEN_VPN_SETUP_LANDING_PAGE:
        trigger = state.router.location.query.back
          ? "services"
          : "dashboard_card";
        break;
      default:
    }
  }

  return trigger;
}

export function getScreenFlow(screenName, store) {
  let screenFlow;
  const { path } = store ? store.getState().router.location.query : {};

  switch (screenName) {
    case SCREEN_SPLASH:
    case SCREEN_ONBOARDING_QUICK_TOUR:
    case SCREEN_ONBOARDING_QUICK_TOUR_TOOLTIP:
    case SCREEN_ONBOARDING_QUICK_TOUR_LANDING:
    case SCREEN_ONBOARDING_CREATE_ACCOUNT:
    case SCREEN_ONBOARDING_CREATE_ACCOUNT_CREATED:
    case SCREEN_ONBOARDING_SIGN_IN:
      screenFlow = "onboarding";
      break;
    /// need to double check these
    case SCREEN_DWS_INTRO:
    case SCREEN_DWS_TOOLTIP:
    case SCREEN_DWS_SCANNING:
    case SCREEN_DWS_SCAN_DONE:
    case SCREEN_BREACHES_FOUND_RESULTS:
    case SCREEN_BREACH_LIST:
      screenFlow = path ? "onboarding" : "identity";
      break;
    case SCREEN_DWS_SCAN_RESULTS:
    case SCREEN_SETTINGS_MANAGE_EMAILS:
      screenFlow = "identity";
      break;
    default:
      screenFlow = null;
  }

  return screenFlow;
}
/**
 * Return screenDetails based on the breached information and if it's verified
 * @param {*} otpVerified (a verified breach is a breach that you've used otp to check the information)
 * @param {*} breachDetails
 * @returns {string}
 */
export async function getScreenDetailsFromBreachedInfo({
  otpVerified = false,
  breachDetails,
} = {}) {
  let breachSummary;
  if (!breachDetails) {
    await waitFor(() => store.getState().identity.breachDetails !== null);

    breachSummary = analyzeBreachDetails(
      store.getState().identity.breachDetails
    );
  } else {
    breachSummary = analyzeBreachDetails(breachDetails);
  }

  let screenDetails;

  if (breachSummary.hasPlainTextPassword || breachSummary.hasPassword) {
    if (
      (breachSummary.hasPlainTextPassword || breachSummary.hasPassword) &&
      breachSummary.siteKnown
    ) {
      screenDetails = breachSummary.hasPlainTextPassword
        ? `plaintext_pwd_known${otpVerified ? `_verified` : ""}`.trim()
        : "hashed_pwd_known";
    } else {
      if (
        (breachSummary.hasPlainTextPassword || breachSummary.hasPassword) &&
        !breachSummary.siteKnown
      ) {
        screenDetails = breachSummary.hasPlainTextPassword
          ? `plaintext_pwd_unknown${otpVerified ? `_verified` : ""}`.trim()
          : "hashed_pwd_unknown_urgent";
      } else if (!breachSummary.hasPassword) {
        screenDetails = "hashed_pwd_unknown";
      }
    }
  } else {
    let knownOrNotKnown = breachSummary.siteKnown ? "known" : "unknown";

    if (breachSummary.assetsStolen.includes("credit_card_number")) {
      screenDetails = `pii_${knownOrNotKnown}_credit_card`;
    } else if (breachSummary.hasSSN) {
      screenDetails = `pii_${knownOrNotKnown}_ssn`;
    } else {
      screenDetails = `pii_${knownOrNotKnown}`;
    }
  }

  return screenDetails;
}

export function collectScreenLoadEvent(telemetryData) {
  uwpSendTelemetry(EVENTID_SCREEN_LOAD, {
    hit_guid: newGUID(),
    [HIT_TRIGGER]: ANALYTICS_MANUAL,
    [SCREEN_LOAD_RESULT]: ANALYTICS_SUCCESS,
    [SCREEN_SEQUENCE_ID]: newPageSequence(),
    [SCREEN_LOAD_TIME]: SCREEN_DEFAULT_LOAD_TIME,
    [SCREEN_CARD_OR_SCREEN]: HIT_TYPE_SCREEN,
    ...telemetryData,
  });
}

export function analyticsInit(InitialContext = {}) {
  try {
    // Only use web version of the sdk if uwp is not connected
    if (!window.communicator || !window.communicator.sendTelemetry) {
      fillURL(process.env.REACT_APP_ANALYTICS_FILL_URL);
      ca_engine_start(JSON.stringify(InitialContext));
    }
  } catch (e) {
    uwpLog(
      `Analytics initialization failure ---> ${e.message | e.error}`,
      LOG_LEVEL_ERROR
    );
  }
}

export function analyticsDispose() {
  try {
    shutDownProcessing();
  } catch (e) {
    uwpLog(
      `Analytics dispose failure ---> ${e.message || e.error}`,
      LOG_LEVEL_ERROR
    );
  }
}

export function analyticsUpdateContext(ContextKeyValue) {
  try {
    updateContext(JSON.stringify(ContextKeyValue));
  } catch (e) {
    uwpLog(
      `updateContext failure ---> ${e.message || e.error}`,
      LOG_LEVEL_ERROR
    );
  }
}

export function analyticsSendEvent(eventId, data) {
  try {
    const evnt = new Event(eventId);
    Object.keys(data).map((key) => evnt.Add(key, data[key]));
    evnt.Finish();
  } catch (e) {
    uwpLog(
      `analyticsSendEvents failure ---> ${e.message || e.error}`,
      LOG_LEVEL_ERROR
    );
  }
}

export function sendErrorScreenEvent({
  source: errorSource,
  code: errorCode = "000-000",
}) {
  switch (errorSource) {
    case SCREEN_ONBOARDING_SIGN_IN:
    case SCREEN_ONBOARDING_CREATE_ACCOUNT:
    case "sign_out":
    case "v1_auth_token":
    case "v1_auth_logout":
      uwpSendTelemetry(EVENTID_SCREEN_LOAD, {
        [HIT_SCREEN]: SCREEN_ONBOARDING_SIGN_IN,
        [SCREEN_TYPE]: ANALYTICS_ERROR,
        [HIT_FEATURE]: ANALYTICS_LIFE_CYCLE,
        [HIT_CATEGORY]: ANALYTICS_AUTHENTICATION,
        [HIT_TRIGGER]: ANALYTICS_ONBOARDING,
        [SCREEN_CARD_OR_SCREEN]: HIT_TYPE_SCREEN,
        [SCREEN_LOAD_TIME]: SCREEN_DEFAULT_LOAD_TIME,
        [SCREEN_DETAILS]: errorCode,
        [SCREEN_SEQUENCE_ID]: newPageSequence(),
        hit_guid: newGUID(),
      });
      break;
    default:
    //TODO: support other error screens
  }
}

export function subscriptionInitiatedEvent(screenName, hitTrigger) {
  uwpSendTelemetry(EVENTID_PPS_USER_SUBSCRIPTION_INITIATED, {
    [HIT_TRIGGER]: hitTrigger,
    [HIT_LABEL_0]: ANALYTICS_SUCCESS,
    hit_guid: newGUID(),
  });

  uwpSendTelemetry(EVENTID_PPS_PURCHASE_INITIATED, {
    [HIT_TRIGGER]: ANALYTICS_MANUAL,
    [HIT_SCREEN]: screenName,
    [HIT_LABEL_0]: ANALYTICS_SUCCESS,
    [HIT_LABEL_2]: "home.mcafee.com", //TODO should be url name like home.mcafee.com or google_app_store or apple_app_store
    hit_guid: newGUID(),
  });
}

export function getContextValues() {
  const {
    subscription: { subscriptionStatus, expiryDate },
    experience: {
      features: {
        ids: { enabled: idsEnabled },
        [FEATURE_VPN]: { enabled: vpnEnabled },
      },
    },
    context: { packageId },
  } = store.getState();

  return {
    ...DEFAULT_PPS_UI_CONTEXT,
    product_subscription_status: subscriptionStatus
      ? getProductSubscriptionStatus(subscriptionStatus)
      : "N/A",
    product_subscription_type: subscriptionStatus
      ? getSubscriptionType(subscriptionStatus)
      : "N/A",
    product_subscription_expiry_date: expiryDate || "N/A",
    product_idprotection_status: idsEnabled || "N/A",
    product_vpn_status: vpnEnabled || "N/A",
    product_sku_id: packageId || "N/A",
  };
}
