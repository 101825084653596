import styled from "styled-components";

const StyledWelcomeBody = styled.div`
  width: 100vw;
  height: 100vh;
`;

const StyledWelcomeGrid = styled.div`
  width: 80%;
`;

export { StyledWelcomeBody, StyledWelcomeGrid };
