// React
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { push, replace } from "connected-react-router";
// DOM
import "./settings.scss";
import "./antivirusSettingsPage.scss";

import {
  Grid,
  Row,
  Col,
  Subheading,
  Button,
  Label,
  Paragraph,
  Switch,
  Accordion,
  AccordionHandler,
  AccordionContent,
  List,
  ListItem,
  SidebarDivider,
  Icon,
  Link,
} from "@mcafee/pegasus";

// utils
import { String as LocaleString } from "../../l10n/loader";

// Custom components
import NavbarLayout from "../NavBar/NavbarLayout";
import SettingsMenu from "./SettingsMenu";

import {
  FolderOpenOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";

import { showPopup } from "../../store/thunks/popups";
import {
  POPUP_AV_EXCLUDE_FILE,
  POPUP_AV_REMOVE_EXCLUDED_FILE,
} from "../PopUps/constants";
import { ROUTE_AV_SCAN_DETAILS } from "../app/routes";
import { uwpPublish, uwpSubscribe, uwpUnsubscribe } from "../../uwp";
import { showToast } from "../../store/thunks/toasts";
import { BANNER_NOTIFICATION } from "../Toasts/constants";
import { dashboardShowCard } from "../../store/dashboard";
import { CARD_AV_TURN_ON_RTS } from "../DashboardCards/constants";

function AntivirusSettingsPage({ rtsStatus }) {
  const _S = (str) => `settings.antivirus.${str}`;
  const dispatch = useDispatch();
  const [activeID, setActiveID] = useState("1");
  const [newFiles, setNewFiles] = useState([]);
  const [threatsList, setThreatsList] = useState([]);
  //const [rtsState, setRtsState] = useState("off");
  const onViewItem = (threat) => {
    dispatch(
      showPopup(POPUP_AV_REMOVE_EXCLUDED_FILE, {
        onRemoveFile: onRemove,
        name: threat.data.name,
        location: threat.data.filepath,
        threat: threat.id,
      })
    );
  };
  const onRemove = (id) => {
    let files = threatsList;

    var exclusion_paths = [];
    // go throught existing files
    // for (var i = 0; i < files.length; i++) {
    //   exclusion_paths.push(files[i].data.filepath);
    // }
    console.log("threatsList", threatsList);
    const file = threatsList.find((element) => element.id === id);
    console.log("file", file);

    exclusion_paths.push(file.data.filepath);
    files.splice(id - 1, 1);
    setThreatsList(files);
    var out_msg = { filepath: exclusion_paths };
    uwpPublish("nav.exclusions.remove", out_msg);
    uwpPublish("nav.exclusions.query", "{}");
  };
  const onShowQuarantined = () => {
    dispatch(replace(ROUTE_AV_SCAN_DETAILS));
  };
  const toggleRts = () => {
    const rts = rtsStatus === "on" ? "off" : "on";

    uwpPublish("av.state.set", { state: rts });
  };

  function rtsPathToFileInfo(threatObj) {
    if (!threatObj) {
      console.log("filepath undefined");
      return;
    }
    const filepath = threatObj.filepath;

    var location = filepath.substr(0, filepath.lastIndexOf("\\"));
    var filename = filepath.substr(filepath.lastIndexOf("\\") + 1);
    return {
      filepath: filepath,
      name: filename,
      file_name: filename,
      location: location,
    };
  }

  const handleClick = (id) => {
    console.log(id);
    console.log(activeID);
    setActiveID(activeID === id ? "" : id);
  };

  const onOpenFileExplorer = () => {
    uwpPublish("Core.AddFileOpenDialog.Query", {});
  };

  const onAddItem = () => {
    dispatch(
      showPopup(POPUP_AV_EXCLUDE_FILE, { onAddFile: onOpenFileExplorer })
    );
  };
  const onAddFileResponse = useCallback((msg) => {
    console.log("onAddFileResponse ", msg);
    setNewFiles(msg);
  }, []);
  const onExclusions = useCallback(
    (msg) => {
      console.log("nav.exclusions.items", msg);
      let files = [];

      for (var i = 0; i < msg.exclusions_list.length; i++) {
        files.push({
          id: i + 1,
          data: rtsPathToFileInfo(msg.exclusions_list[i]),
        });
      }
      console.log("files", files);
      setThreatsList(files);
    },
    [setThreatsList]
  );
  const onGetAvState = useCallback(
    (msg) => {
      dispatch(
        dashboardShowCard({
          card: CARD_AV_TURN_ON_RTS,
          show: msg.state === "off" ? true : false,
        })
      );
    },
    [dispatch]
  );
  const onError = useCallback(
    (msg) => {
      console.log("response for exclusions set", msg);
      if (msg.errors.length > 0) {
        dispatch(
          showToast(BANNER_NOTIFICATION, {
            stringId: "toasters.banner.somethingWrong",
            selfDestruct: false,
          })
        );
      }
    },
    [dispatch]
  );
  useEffect(() => {
    if (rtsStatus === "off") {
      dispatch(
        showToast(BANNER_NOTIFICATION, {
          stringId: "toasters.rtsOff",
        })
      );
    }
  }, [dispatch, rtsStatus]);
  useEffect(() => {
    uwpPublish("av.state.get", {});

    // uwpSubscribe("av.state", onGetAvState);
  }, [onGetAvState]);

  useEffect(() => {
    console.log("running nav.exclusions.query");
    // const on_excluded_items =
    uwpSubscribe("nav.exclusions.items", onExclusions);

    uwpPublish("nav.exclusions.query", "{}");
    uwpSubscribe("nav.exclusions.error", onError);
  }, [onError, onExclusions]);

  useEffect(() => {
    const on_add_file_id = uwpSubscribe(
      "Core.AddFileOpenDialog.Response",
      onAddFileResponse
    );

    return function cleanup() {
      uwpUnsubscribe(on_add_file_id);
    };
  }, [onAddFileResponse]);

  useEffect(() => {
    console.log("newFiles changed ", newFiles);
    if (newFiles.length === 0) {
      return;
    }
    var exclusion_paths = [];
    var exclusion_payload = [];
    console.log("threatList", threatsList);
    // go throught existing files
    for (var i = 0; i < threatsList.length; i++) {
      exclusion_paths.push(threatsList[i].data.filepath);
    }
    // add new files
    for (i = 0; i < newFiles.length; i++) {
      var new_path_name = newFiles[i].filepath;
      exclusion_paths.push(new_path_name);
    }
    exclusion_payload = exclusion_paths.map((exclusion_path) => {
      var out_msg = {
        enabled: true,
        is_hash: true,
        type: ["ods", "oes"],
        filepath: exclusion_path,
      };
      return out_msg;
    });

    uwpPublish("nav.exclusions.set", exclusion_payload);

    uwpPublish("nav.exclusions.query", "{}");

    setNewFiles([]);
  }, [newFiles, onExclusions, onError, threatsList]);

  useEffect(() => {
    console.log("threatsList changed ", threatsList);
  }, [threatsList]);
  return (
    <NavbarLayout
      id="Settings"
      className="pa-0"
      classNameLayout="pa-0 bg-white"
    >
      <Grid>
        <Row style={{ height: "100%" }}>
          <Col sm xs={12} className="flex">
            <SettingsMenu />
          </Col>

          <Col sm={8} xs={12} className="flex direction-column bg-white pa-24 ">
            <Subheading size="md" className="mb-24">
              <LocaleString id={_S`title`} />
            </Subheading>
            <Label>
              <LocaleString id={_S`rts.header`} />
            </Label>
            <Paragraph size="sm" className="antivirusSettings__paragraph">
              <LocaleString id={_S`rts.content`} />
            </Paragraph>
            <SidebarDivider className="antivirusSettings__divider" />

            <div className="antivirusSettings__rtsContent">
              <Paragraph
                size="sm"
                className="antivirusSettings__rtsContent__switch"
              >
                <LocaleString id={_S`rts`} />
              </Paragraph>
              <Switch
                onClick={toggleRts}
                kind="success"
                checked={rtsStatus === "on"}
              />
            </div>

            <Label>
              <LocaleString id={_S`excluded.header`} />
            </Label>
            <Paragraph size="sm" className="antivirusSettings__paragraph">
              <LocaleString id={_S`excluded.content`} />
            </Paragraph>

            <Accordion
              id="0"
              activeID={activeID}
              className="antivirusSettings__excludedList"
            >
              <AccordionHandler
                onClick={handleClick}
                variant="tertiary"
                className="antivirusSettings__excludedList__count"
              >
                <Label
                  size="sm"
                  className="antivirusSettings__excludedList__count__header"
                >
                  <LocaleString
                    id={_S`excluded.count`}
                    values={{ count: threatsList.length }}
                  />
                  {activeID === "0" ? (
                    <KeyboardArrowUp className="" />
                  ) : (
                    <KeyboardArrowDown className="" />
                  )}
                </Label>
              </AccordionHandler>
              <AccordionContent
                className={`antivirusSettings__excludedList__content ${
                  activeID === "0" ? `antivirusSettings__excludedList__content__showContent` : ``
                }`}
              >
                <List>
                  <ListItem
                    className="text-info antivirusSettings__excludedList__addItem"
                    id="AddItemButton"
                  >
                    <div
                      className="text-info antivirusSettings__excludedList__addItem__container"
                      onClick={onAddItem}
                    >
                      <Icon
                        icon="add-atlas-outline"
                        className="antivirusSettings__excludedList__addItem__icon"
                      />
                      <Label size="sm" className="text-info">
                        <LocaleString id={_S`excluded.add`} />
                      </Label>
                    </div>
                  </ListItem>
                  <div>
                    <Label size="sm">
                      {threatsList.length > 0 &&
                        threatsList.map((threat) => {
                          return (
                            <ListItem className="antivirusSettings__excludedList__content__item">
                              <FolderOpenOutlined className="antivirusSettings__excludedList__content__item__icon" />
                              <div className="antivirusSettings__excludedList__content__item__name">
                                {threat.data.name}
                              </div>
                              <Button
                                variant="primary"
                                onClick={() => {
                                  onViewItem(threat);
                                }}
                                id="viewFile"
                                size="sm"
                              >
                                <LocaleString id={_S`view`} />
                              </Button>
                            </ListItem>
                          );
                        })}
                    </Label>
                  </div>
                </List>
              </AccordionContent>
            </Accordion>

            <Label className="antivirusSettings__quarantined">
              <LocaleString id={_S`Quarantined`} />
            </Label>
            <Paragraph size="sm">
              <LocaleString
                id={_S`Quarantined.content`}
                values={{
                  link: (
                    <Link id="viewQuarantined" onClick={onShowQuarantined}>
                      <LocaleString id={_S`Quarantined`} />
                    </Link>
                  ),
                }}
              />
            </Paragraph>
          </Col>
        </Row>
      </Grid>
    </NavbarLayout>
  );
}

export default connect(
  (state) => ({
    ...state.av,
  }),
  {
    push,
  }
)(AntivirusSettingsPage);
