import { combineReducers } from "redux";

import auth from "./auth";
import deviceList from "./deviceList";
import device from "./devices";
import context from "./context";
import dws from "./dws";
import dashboard from "./dashboard";
import identity from "./identity";
import otp from "./otp";
import vpn from "./vpn";
import av from "./av";
import network from "./network";
import settings from "./settings";
import experience from "./experience";
import popups from "./popups";
import toasts from "./toasts";
import overlays from "./overlays";
import subscription from "./subscription";

import { connectRouter } from "connected-react-router";

export default function createRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    auth,
    otp,
    identity,
    device,
    deviceList,
    context,
    dws,
    dashboard,
    vpn,
    network,
    settings,
    experience,
    popups,
    toasts,
    overlays,
    subscription,
    av
  });
}
