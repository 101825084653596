import { createSlice } from "@reduxjs/toolkit";
import { signOutSuccess } from "./auth";

const initialState = {
  fetching: false,
  errorFetching: null,
  list: null,
};

const slice = createSlice({
  name: "@deviceList",
  initialState,
  reducers: {
    fetchDeviceListBegin: (state) => {
      state.fetching = true;
      state.errorFetching = null;
    },
    fetchDeviceListSuccess: (state, action) => {
      state.fetching = false;
      state.errorFetching = null;
      state.list = action.payload;
    },
    fetchDeviceListFailure: (state, action) => {
      state.fetching = false;
      state.errorFetching = action.payload;
    },
  },
  extraReducers: {
    [signOutSuccess]: () => {
      return initialState;
    },
  },
});

export const {
  fetchDeviceListBegin,
  fetchDeviceListSuccess,
  fetchDeviceListFailure,
} = slice.actions;

export default slice.reducer;
