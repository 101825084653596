import React, { useEffect, useState } from "react";
import { String } from "../../l10n/loader";

// DOM
import {
  Button,
  Paragraph,
  Heading,
  Label,
  Phoneput,
  Link,
} from "@mcafee/pegasus";

import { useDispatch, useSelector } from "react-redux";

import { updateMobileNumber } from "../../store/thunks/dashboard";
import { showPopup } from "../../store/thunks/popups";
import {
  POPUP_ASSET_ADDED,
  POPUP_FAILURE,
  POPUP_OTP,
} from "../PopUps/constants";
import {
  SCREEN_SETTINGS_ACCOUNT_ADD_PHONE,
  SCREEN_SETTINGS_ACCOUNT_OTP,
} from "../../constants/analyticsScreens";
// Store
import { fetchPrivacyInfo } from "../../store/thunks/app";

import { isValidNumberForRegion, parsePhoneNumber } from "libphonenumber-js";

import {
  ANALYTICS_SETTINGS,
  ANALYTICS_SUCCESS,
  EVENTID_PPS_PHONE_ADDITION_START,
  HIT_HASH_1,
  HIT_SCREEN,
  HIT_TRIGGER,
  MAX_ERROR_RETRY,
  SCREEN_LOAD_RESULT,
} from "../../constants";
import { navToErrorPage, newGUID } from "../../utils";
import { uwpSendTelemetry } from "../../uwp";

export default function AddPhoneNumberPopUp({
  onCancel, //Provided by Popups manager component, call to close with undefined result
  onFinish, //Provided by Popups manager component, call to return a value or true by default
}) {
  const _S = (str) => `popups.addPhoneNumber.${str}`;
  const [errorMsg, setErrorMsg] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const dispatch = useDispatch();
  const [inputKind, setInputKind] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const { privacyUrl, fetchingPrivacyInfo, fetchingPrivacyInfoError } =
    useSelector((state) => state.context);

  const { updateMobileProgress } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (!privacyUrl && !fetchingPrivacyInfo && !fetchingPrivacyInfoError) {
      dispatch(fetchPrivacyInfo());
    }
  }, [dispatch, fetchingPrivacyInfo, fetchingPrivacyInfoError, privacyUrl]);

  useEffect(() => {
    setErrorMsg(false);
    setInputKind("");
  }, [phoneNumber, countryCode]);

  const onChange = (e) => {
    const phone = e.value;
    const formattedPhone = e.inputValue;
    const country = e.countryCode;
    setPhoneNumber(phone);
    setFormattedPhoneNumber(formattedPhone);
    setCountryCode(country);
  };

  const tryAddPhoneNumber = async (phoneNumber, otpToken, retryCount = 0) => {
    const { ok: mobileUpdated, error } = await dispatch(
      updateMobileNumber(phoneNumber, otpToken)
    );
    if (mobileUpdated) {
      const mobile = phoneNumber ? parsePhoneNumber(`+${phoneNumber}`) : "";
      dispatch(
        showPopup(POPUP_ASSET_ADDED, {
          phoneNumber: mobile.format("NATIONAL"),
        })
      );
      onFinish(); //Positive return
    } else {
      //handle error
      if (retryCount < MAX_ERROR_RETRY) {
        if (
          await dispatch(
            showPopup(POPUP_FAILURE, {
              message: "addFailurePopUp",
            })
          )
        ) {
          tryAddPhoneNumber(phoneNumber, otpToken, retryCount + 1);
        }
      } else {
        //Close popup and show error page
        onCancel();
        navToErrorPage(error);
      }
    }
  };

  const handleSubmit = async (e) => {
    const isValid = isValidNumberForRegion(formattedPhoneNumber, countryCode);
    if (isValid) {
      uwpSendTelemetry(EVENTID_PPS_PHONE_ADDITION_START, {
        [HIT_TRIGGER]: ANALYTICS_SETTINGS,
        [HIT_SCREEN]: SCREEN_SETTINGS_ACCOUNT_ADD_PHONE,
        [SCREEN_LOAD_RESULT]: ANALYTICS_SUCCESS,
        [HIT_HASH_1]: formattedPhoneNumber,
        hit_guid: newGUID(),
      });
      const otpResult = await dispatch(
        showPopup(POPUP_OTP, {
          newPhoneNumber: phoneNumber,
          formattedPhoneNumber: formattedPhoneNumber,
          trigger: "monitor_new_phone",
          flow: "add_asset_phone",
          scope: "phone_verified",
          screen: SCREEN_SETTINGS_ACCOUNT_OTP,
          details: "otp_default",
        })
      );
      // const otpResult = { otpToken: "dummyToken" };//For error testing
      if (!otpResult) {
        return;
      } else {
        const { otpToken } = otpResult;
        tryAddPhoneNumber(phoneNumber, otpToken);
      }
    } else {
      setInputKind("danger");
      setErrorMsg(true);
    }
  };

  const onClickOfTerms = (e) => {
    if (privacyUrl) {
      window.open(privacyUrl, "_blank");
    }
  };

  const styles = {
    popUpStyles: {
      maxWidth: 550,
    },
  };

  return (
    <div style={styles.popUpStyles} className="pa-24 text-left">
      <Heading size="xs" data-testid="addPhoneNumberHeader">
        <String id={_S`header`} />
      </Heading>

      <Paragraph
        className="mt-8 flex text-left justify-content-center"
        size="md"
        data-testid="addPhoneNumberContent"
      >
        <String id={_S`content`} />
      </Paragraph>
      <Paragraph
        className="mt-8 text-left"
        size="md"
        data-testid="addPhoneNumberContent2"
      >
        <String id={_S`content2`} />
        <Link onClick={onClickOfTerms}>
          <String id={_S`link`} />
        </Link>
      </Paragraph>

      <div className="flex align-items-center justify-content-center mt-24">
        <Phoneput onChange={onChange} kind={inputKind} />
      </div>
      {errorMsg && (
        <div className="text-center">
          <Label size="sm" className="text-danger mt-4">
            <String id={`popups.addPhoneNumberError`} />
          </Label>
        </div>
      )}

      <div className="mt-24 text-right">
        <Button
          className="ml-32"
          onClick={handleSubmit}
          id="AddPhoneNumberNext"
          data-testid="addPhoneNumberNext"
          size="sm"
          disabled={updateMobileProgress}
        >
          <String id={_S`next`} />
        </Button>
        <Button
          className="ml-32"
          variant="tertiary"
          onClick={onCancel}
          id="AddEmailFormCancel"
          data-testid="addEmailPopUpCancelButton"
          size="sm"
        >
          <String id={_S`cancel`} />
        </Button>
      </div>
    </div>
  );
}
