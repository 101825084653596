import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";

import NavbarLayout from "../NavBar/NavbarLayout";
import ServiceStatusBar from "../ServicesStatusBar/ServiceStatusBar";
import ErrorMessage from "../Shared/ErrorMessage";
import { ViewPortCenter } from "../Widgets/ViewPortCenter";

import { fetchDashboardCards } from "../../store/thunks/dashboard";

import {
  AMOUNT_OF_DAYS_TO_SHOW_REMINDER,
  FEATURE_SUBSCRIPTION,
  LS_DONE_WSS_PROMPT,
  MAX_ERROR_RETRY,
  SETTING_VPN_CONNECT_MANUAL,
  SETTING_VPN_NOTIFY_DEVICE,
  SETTING_VPN_STARTUP,
  SUCCESS,
  UA_SCHEDULE_CARDS,
} from "../../constants/main";

// Dashboard card image assets

import "./Dashboard.scss";
import { cardsMap } from "../DashboardCards/cardsMap.js";
import { InPlaceProgress } from "../styledComponents/progress.js";
import {
  isTrialActive,
  navToErrorPage,
  setProperties,
  useIsSignedIn,
} from "../../utils";
import Unauthorized from "../Shared/Unauthorized";
import { showPopup } from "../../store/thunks/popups";
import { ROUTE_DASHBOARD } from "../app/routes";
import { replace } from "connected-react-router";
import SubscriptionBar from "./SubscriptionBar";
import { POPUP_WSS } from "../PopUps/constants";
import { doneShowingWssPrompt } from "../../store/context";

const Dashboard = ({
  //...state.context
  devMode,
  //...state.experience
  features,
  //...state.dashboard
  cards: dashboardCards,
  error: errorFetchingCards,
  fetchDashboardCards,
  cardsView,
  //...state.subscription
  subscriptionStatus,
  daysToExpiry,
  query,
  replace,
}) => {
  const [retryCount, setRetryCount] = useState(0);
  const showSubscriptionActions =
    features && features[FEATURE_SUBSCRIPTION].enabled;

  const onRetry = (e) => {
    fetchDashboardCards().then(() => {
      setRetryCount(retryCount + 1);
    });
  };

  const dispatch = useDispatch();

  const { [UA_SCHEDULE_CARDS]: scheduledCards = {} } = useSelector(
    (state) => state.auth.userActions
  );

  const [showSubscriptionBar, setShowSubscriptionBar] = useState(
    showSubscriptionActions &&
      isTrialActive(subscriptionStatus) &&
      daysToExpiry <= AMOUNT_OF_DAYS_TO_SHOW_REMINDER
  );
  const { doneWssPrompt, wssInstalled, wssVariation, wssCallStatus } =
    useSelector((state) => state.context);

  useEffect(() => {
    if (wssCallStatus === SUCCESS) {
      if (doneWssPrompt) {
        if (!wssInstalled) {
          //reset doneWssPrompt in case the wss was uninstalled
          setProperties({ [LS_DONE_WSS_PROMPT]: false });
        }
      } else {
        if (wssInstalled) {
          setProperties({
            [SETTING_VPN_STARTUP]: SETTING_VPN_CONNECT_MANUAL,
            [SETTING_VPN_NOTIFY_DEVICE]: false,
          });
          setProperties({ [LS_DONE_WSS_PROMPT]: true });
          dispatch(doneShowingWssPrompt());
          dispatch(showPopup(POPUP_WSS, { wssVariation }));
        }
      }
    }
  }, [dispatch, doneWssPrompt, wssCallStatus, wssInstalled, wssVariation]);

  useEffect(() => {
    fetchDashboardCards();
  }, [fetchDashboardCards]);

  useEffect(() => {
    if (errorFetchingCards && retryCount >= MAX_ERROR_RETRY) {
      navToErrorPage(errorFetchingCards);
    }
  }, [errorFetchingCards, retryCount, dispatch]);

  useEffect(() => {
    if (query && query.popup) {
      replace(ROUTE_DASHBOARD);
      dispatch(showPopup(query.popup));
    }
  }, [query, dispatch, replace]);

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  const renderCard = (props) => {
    const CardComponent = cardsMap[props.id];
    if (CardComponent) {
      return <CardComponent {...props} key={props.id} />;
    }
    return devMode && <div>Unknown Card [{props.id}]</div>;
  };

  const now = Date.now(); // returns date in milliseconds since 1970-01-01 UTC

  const isCardVisible = (card) => {
    if (features[card.feature] && !features[card.feature].enabled) {
      return false; //check if feature is enabled
    }

    if (cardsView[card.id] === false) {
      return false;
    }

    //Check if rescheduled
    /* Fetch the respective values of the desired card from the localStorage and
     simply check if the expireTime is After or Before or the same as current time (date and time both)
     Returns True if current time is before or same as expireTime
     False otherwise */
    if (scheduledCards[card.id]) {
      const expireTime = scheduledCards[card.id];
      return now - expireTime > 0;
    }

    if (!cardsMap[card.id]) {
      return false;
    }

    return true;
  };

  const visibleCards = !!dashboardCards
    ? dashboardCards.filter((card) => isCardVisible(card))
    : [];

  const onDismiss = () => {
    setShowSubscriptionBar(false);
  };

  return (
    <NavbarLayout id="Dashboard">
      {showSubscriptionBar && <SubscriptionBar onDismiss={onDismiss} />}
      <div className="dashboardCards" data-testid="dashboard-cards">
        <ServiceStatusBar />
        {errorFetchingCards ? (
          <ViewPortCenter>
            <ErrorMessage
              {...{
                error: errorFetchingCards,
                onRetry,
              }}
            />
          </ViewPortCenter>
        ) : !!dashboardCards ? (
          visibleCards.map(renderCard)
        ) : (
          <InPlaceProgress infinite noPercentage />
        )}
      </div>
    </NavbarLayout>
  );
};

//Connect state properties and action dispatchers to element properties
export default connect(
  (state) => ({
    ...state.dashboard,
    subscriptionStatus: state.subscription.subscriptionStatus,
    daysToExpiry: state.subscription.daysToExpiry,
    features: state.experience.features,
    devMode: state.context.devMode,
    query: state.router.location.query,
  }), //mapStateToProps
  { fetchDashboardCards, replace }
)(Dashboard);
