import React, { useEffect, useState } from "react";
import ServiceCard from "./ServiceCard";
import { FEATURE_AV } from "../../constants/main";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { ROUTE_AV, ROUTE_SERVICES } from "../app/routes";

import antivirusOnIcon from "../../components/_assets/images/services/antivirusOn.svg";
import antivirusOffIcon from "../../components/_assets/images/services/antivirusOff.svg";


import { createQueryString } from "../../utils";

const AntivirusServiceCard = ({
  features,
  av,
  push,
}) => {
  
  const contentPrefix = "AntivirusCard";

  const feature = FEATURE_AV;
  const disabled = false;

  const [tagContent, setTagContent] = useState("...");
  const [tagKind, setTagKind] = useState("success");
  const [av_icon,setAvIcon] = useState(antivirusOnIcon)
  

  const onClick = (e) => {
    let urlQuery = {
      back: ROUTE_SERVICES,
      hide_skip: true,
    };

    push({
      pathname: ROUTE_AV,
      search: createQueryString(urlQuery),
    });
  };

  useEffect(() => {
    setTagContent(av.rtsStatus === "on" ? "On" : "Off");
    setTagKind(av.rtsStatus === "on" ? "success" : "danger");
    setAvIcon(av.rtsStatus === "on" ? antivirusOnIcon : antivirusOffIcon);
  }, [av]);
  

  if (features.av === undefined || !features.av.enabled) {
     return <div />;
  }

   const icon = av_icon
  return (
    <ServiceCard
      {...{
        feature,
        icon,
        contentPrefix,
        tagKind,
        tagContent,
        onClick,

        disabled,
      }}
    />
  );
};

export default connect(
  (state) => ({
    ...state.identity,
    av: state.av,
    features: state.experience.features,
  }),
  {
    push,
  }
)(AntivirusServiceCard);
