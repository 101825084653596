import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading } from "@mcafee/pegasus";

import "./avRestoreFailedPopup.scss"

const AvRestoreFailedPopup = ({ onCancel, onFinish }) => {
const _S = (str) => `popups.restoreFailedPopup.${str}`;

  return (
    <div className="restoreFailedPopup">
      <Heading
        className="restoreFailedPopup__title"
        size="xs"
        data-testid="restoreFailedPopupHeader"
      >
        <String id={_S`header`} />
      </Heading>

      <Paragraph
        className="restoreFailedPopup__content"
        size="sm"
        data-testid="restoreFailedPopupContent"
      >
        <String id={_S`content`} />
      </Paragraph>
      <div className="restoreFailedPopup__buttons">
        <Button
          size="sm"
          onClick={(e) => {
            onFinish(true);
          }}
          id="restoreFailedPopupTryAgain"
          data-testid="restoreFailedPopupTryButton"
          shape="pill"
          variant="primary"
        >
          <String id={_S`tryAgain`} />
        </Button>
        <Button
          className="ml-32"
          onClick={(e) => {
            onFinish(false);
          }}
          id="restoreFailedPopupLater"
          variant="tertiary"
          data-testid="restoreFailedPopupLaterButton"
          size="sm"
        >
          <String id={_S`later`} />
        </Button>
      </div>
    </div>
  );
};

export default AvRestoreFailedPopup;
