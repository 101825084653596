import React, { useState, useEffect } from "react";
import page1Avatar from "../_assets/images/onboard/dwsLearnMore/step1.svg";
import page2Avatar from "../_assets/images/onboard/dwsLearnMore/step2.svg";
import page3Avatar from "../_assets/images/onboard/dwsLearnMore/step3.svg";
import { String } from "../../l10n/loader";
import {
  ANALYTICS_EDUCATION,
  ANALYTICS_ID_PROTECTION,
  ANALYTICS_PROTECTION,
  ANALYTICS_ID_ONBOARDING,
  HIT_FEATURE,
  SCREEN_DETAILS,
  SCREEN_TYPE,
  HIT_SCREEN,
  HIT_CATEGORY,
  HIT_TRIGGER,
  SCREEN_FLOW,
} from "../../constants/analytics";
import {
  Paragraph,
  Button,
  Pagination,
  PageItem,
  Heading,
} from "@mcafee/pegasus";
import { SCREEN_DWS_TOOLTIP } from "../../constants/analyticsScreens";
import { collectScreenLoadEvent } from "../../utils";
import "./dwsLearnMore.scss";

const DWSLearnMore = ({ onClose }) => {
  const [activePageId, setActivePageId] = useState("1");

  useEffect(() => {
    if (activePageId) {
      collectScreenLoadEvent({
        [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
        [HIT_TRIGGER]: ANALYTICS_ID_ONBOARDING,
        [HIT_CATEGORY]: ANALYTICS_PROTECTION,
        [HIT_SCREEN]: SCREEN_DWS_TOOLTIP,
        [SCREEN_TYPE]: ANALYTICS_EDUCATION,
        [SCREEN_DETAILS]: `first_value_tooltip_${activePageId}`,
        [SCREEN_FLOW]: "onboarding",
      });
    }
  }, [activePageId]);

  const pagesData = {
    1: {
      image: page1Avatar,
      secondParagraph: true,
    },
    2: {
      image: page2Avatar,
      secondParagraph: true,
    },
    3: {
      image: page3Avatar,
      secondParagraph: false,
    },
  };

  const _S = (str) => `onboard.learnMore.page${activePageId}.${str}`;

  const handlePageClick = (id) => {
    setActivePageId(id);
  };

  const handleNextClick = () => {
    if (activePageId === "3") return onClose();

    const newPage = (Number(activePageId) + 1).toString();
    setActivePageId(newPage);
  };

  const { image, secondParagraph } = pagesData[activePageId]
    ? pagesData[activePageId]
    : pagesData["1"];

  return (
    <div className="dwsLearnMorePopup">
      <div className="dwsLearnMorePopup__container">
        <img src={image} alt="Avatar_Image" />
      </div>
      <Heading className="dwsLearnMorePopup__title" size="xs">
        <String id={_S`title`} />
      </Heading>
      <Paragraph size="sm" className="dwsLearnMorePopup__paragraph">
        <String id={_S`desc1`} />
      </Paragraph>
      {secondParagraph && (
        <Paragraph size="sm" className="dwsLearnMorePopup__paragraph">
          <String id={_S`desc2`} />
        </Paragraph>
      )}
      <div className="dwsLearnMorePopup__buttonsContainer">
        <Pagination activeId={activePageId}>
          {["1", "2", "3"].map((id) => (
            <PageItem id={id} key={id} onClick={handlePageClick} />
          ))}
        </Pagination>
        <Button
          onClick={handleNextClick}
          id={`OnboardLearnMorePage${activePageId}NextBtn`}
          size="sm"
        >
          <String id={_S`nextBtn`} />
        </Button>
      </div>
    </div>
  );
};

export default DWSLearnMore;
