// React
import React from "react";

// utils
import { String } from "../../l10n/loader";

// DOM
import { Heading, Button, Paragraph } from "@mcafee/pegasus";
import { StyledOnboardPopUpBody } from "../styledComponents";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { ROUTE_ACCOUNT } from "../app/routes";
import { LAUNCHUA_CREATE_ACCOUNT } from "../../constants";

export default function OnboardKnowMorePopUp({ onCancel, onFinish }) {
  const localePrefix = "popups.";

  const dispatch = useDispatch();

  const onCreateAccount = (newUser) => {
    dispatch(
      push({ pathname: ROUTE_ACCOUNT, launchUA: LAUNCHUA_CREATE_ACCOUNT })
    );
    onFinish(true);
  };

  return (
    <StyledOnboardPopUpBody>
      <Heading size="xs" className="mb-8">
        <String id={`${localePrefix}onboardKnowMore.header`} />
      </Heading>

      {[1, 2, 3, 4, 5].map((id) => (
        <Paragraph size="sm" className="flex mb-8" key={`bulletPoint${id}`}>
          <span className="px-8 mr-4">•</span>
          <String id={`${localePrefix}onboardKnowMore.point${id}`} />
        </Paragraph>
      ))}
      <div className="flex align-content-end justify-content-end mt-24">
        <Button
          id="Register"
          size="sm"
          className="pgs-button mr-24"
          onClick={onCreateAccount}
        >
          <String id={`messages.register`} />
        </Button>
        <Button
          variant="tertiary"
          className="mr-12"
          id="onboardKnowMoreClose"
          onClick={onCancel}
          size="sm"
        >
          <String id={`${localePrefix}cancel`} />
        </Button>
      </div>
    </StyledOnboardPopUpBody>
  );
}
