//TODO: not used
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { ROUTE_DASHBOARD, ROUTE_SCAN_EMAIL } from "../app/routes";

// Custom components
import MainLoader from "./../MainLoader";
import { FEATURE_IDENTITY } from "../../constants/main";

const OnboardComplete = ({ features, newUser, push }) => {
  useEffect(() => {
    setTimeout(() => {
      push({
        pathname:
          newUser && features[FEATURE_IDENTITY].enabled
            ? ROUTE_SCAN_EMAIL
            : ROUTE_DASHBOARD,
        search: "src=id_onboarding",
      });
    }, 2000);
  }, [push, newUser, features]);

  return <MainLoader message="lets_get_started" />;
};

export default connect(
  (state) => ({
    newUser: state.auth.newUser,
    features: state.experience.features,
  }),
  {
    push,
  }
)(OnboardComplete);
