import React from "react";
import { ONBOARD_TRIAL } from "../../constants/main";
// DOM
import { Paragraph, Button, Display } from "@mcafee/pegasus";

import { String } from "../../l10n/loader";
import trialActiveAvatar from "../../components/_assets/images/shared/trialActiveSuccess.svg";
import { DialogLayout } from "../../components/layouts/DialogLayout";
import { connect } from "react-redux";
import "./TrialAccountCreationComplete.scss";
import { publishEvent } from "../../store/thunks/experience";
import events from "../../experience/events";

const TrialAccountCreationComplete = ({ publishEvent }) => {
  const _S = (str) => `onboard.trial.${str}`;

  const handleSubmit = () => {
    publishEvent(events.account.trialAccountCreationComplete.next);
  };

  const Buttons = () => (
    <>
      <Button id={_S`Next`} onClick={handleSubmit} size="sm">
        <String id={_S`next`} />
      </Button>
    </>
  );

  return (
    <DialogLayout
      pageId={ONBOARD_TRIAL}
      image={trialActiveAvatar}
      buttons={<Buttons />}
      showBack={false}
      className="trial-account-container"
    >
      <Display size="xs">
        <String id={_S`title`} />
      </Display>
      <Paragraph
        className="text-secondary trial-account-container__content"
        size="md"
      >
        <String id={_S`description`} />
      </Paragraph>
      <Paragraph
        className="text-secondary trial-account-container__content"
        size="md"
      >
        <String id={_S`description2`} />
      </Paragraph>
    </DialogLayout>
  );
};

export default connect(
  (state) => ({
    accountEmail: state.auth.email,
  }),
  {
    publishEvent,
  }
)(TrialAccountCreationComplete);
