// React
import React from "react";

// DOM
import AvIsProtectingYouImage from "../_assets/images/dashboard/cards/antivirusIsProtectingYou.svg";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";

// utils
import { RECOMMENDED } from "../../constants/main";
import { ROUTE_AV } from "../app/routes";

import { useDispatch } from "react-redux";

import { push } from "connected-react-router";

export default function AvIsProtectingYou({ id }) {
  // Close the current PopUp and show the other popup based on the Storing status.
  const dispatch = useDispatch();

  const onPrimaryAction = async (e) => {
    await dispatch(push(ROUTE_AV))
  };

  const card = {
    id: id,
    backgroundImage: AvIsProtectingYouImage,
    tagText: RECOMMENDED,
    primaryActionStringId: "seeAntivirus",
    primaryActionDataTestId: "seeAntivirusButton",
    onPrimaryAction,
  };

  // has the Popup handler intact with the card UI
  return <DashboardCard {...card} />;
}
