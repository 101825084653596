import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading, Subheading, Icon } from "@mcafee/pegasus";

import "./avRemoveExcludedItemPopup.scss";
import { DeleteForeverOutlined } from "@material-ui/icons";

const AvRemoveExcludedItemPopup = ({
  onCancel,
  onRemoveFile,
  name,
  location,
  threat,
}) => {
  const _S = (str) => `popups.avRemoveExcludedItemPopup.${str}`;

  return (
    <div className="avRemoveExcludedItemPopup">
      <Heading
        className="avRemoveExcludedItemPopup__title"
        size="xs"
        data-testid="avRemoveExcludedItemPopupHeader"
      >
        <String id={_S`header`} />
      </Heading>
      <Subheading
        className="avRemoveExcludedItemPopup__title"
        size="md"
        data-testid="avRemoveExcludedItemPopupHeader"
      >
        <String id={_S`file`} values={{ name: name }} />
      </Subheading>
      <Subheading
        className="avRemoveExcludedItemPopup__title"
        size="md"
        data-testid="avRemoveExcludedItemPopupHeader"
      >
        <String id={_S`location`} values={{ location: location }} />
      </Subheading>
      <Paragraph
        className="avRemoveExcludedItemPopup__content"
        size="sm"
        data-testid="avRemoveExcludedItemPopupContent"
      >
        <String id={_S`content`} />
      </Paragraph>
      <Paragraph
        className="avRemoveExcludedItemPopup__content__info"
        size="sm"
        data-testid="avRemoveExcludedItemPopupContent"
      >
        <Icon
          icon="info-outline"
          size="md"
          className="text-gray-light avRemoveExcludedItemPopup__content__info__icon"
        />
        <String id={_S`info`} />
      </Paragraph>
      <div className="avRemoveExcludedItemPopup__buttons">
        <Button
          size="sm"
          onClick={() => {
            onRemoveFile(threat);
            onCancel();
          }}
          id="removeFile"
          data-testid="removeExcludedFileButton"
          shape="pill"
          variant="primary"
          kind="danger"
        >
          <DeleteForeverOutlined className="buttonIcon" />
          <String id={_S`removeButton`} />
        </Button>
        <Button
          className="ml-32"
          onClick={onCancel}
          id="removeExcludedCancel"
          variant="tertiary"
          data-testid="avRemoveExcludedCancelButton"
          size="sm"
        >
          <String id={_S`closeButton`} />
        </Button>
      </div>
    </div>
  );
};

export default AvRemoveExcludedItemPopup;
