import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading } from "@mcafee/pegasus";

import "./encryptedPasswordPopup.scss";

const EncryptedPasswordPopup = ({ onCancel, site }) => {
  const _S = (str) =>
    `popups.encryptedPassword.${site ? "knownSource" : "unknownSource"}.${str}`;

  return (
    <div className="encryptedPasswordPopup">
      <Heading size="xs" className="encryptedPasswordPopup__title">
        <String id={_S`header`} />
      </Heading>

      <Paragraph
        size="sm"
        data-testid="EncryptedPasswordPopupContent"
        className="encryptedPasswordPopup__content"
      >
        <String id={_S`content`} />
      </Paragraph>

      <Button
        onClick={onCancel}
        id="EncryptedPasswordPopupGotIt"
        data-testid="EncryptedPasswordPopupGotItButton"
        size="sm"
        className="encryptedPasswordPopup__button"
      >
        <String id="popups.close" />
      </Button>
    </div>
  );
};

export default EncryptedPasswordPopup;
