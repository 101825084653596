// React
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import backgroundImage from "../_assets/images/dashboard/cards/openWifiAlert.svg";

// utils
import { NEEDS_ATTENTION } from "../../constants/main";
import { ROUTE_SECURE_VPN, ROUTE_VPN_SETUP } from "../app/routes";

export default function OpenWifiAlert({ id }) {
  const dispatch = useDispatch();
  const { vpnSetup } = useSelector((state) => state.vpn);

  const onProtectMeNow = () => {
    dispatch(
      push({
        pathname: vpnSetup ? ROUTE_SECURE_VPN : ROUTE_VPN_SETUP,
      })
    );
  };

  const card = {
    id,
    stringValues: {
      WiFi: "Wi-Fi",
    },
    backgroundImage,
    tagText: NEEDS_ATTENTION,
    primaryActionStringId: "protectMeNow",
    primaryActionDataTestId: "openWifiAlertProtectMeNow",
    onPrimaryAction: onProtectMeNow,
  };

  return <DashboardCard {...card} />;
}
