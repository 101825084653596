import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./components/app/App";
import { Provider } from "react-redux";
import { store } from "./store/store";
import * as serviceWorker from "./registerServiceWorker";
import { OLD_VER_URL_MATCH } from "./constants";
import { showToast } from "./store/thunks/toasts";
import { BANNER_NOTIFICATION } from "./components/Toasts/constants";

require("./firebase");
require("./experience/eventsEngine");
require("./debug");

const appNode = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  appNode
);

if (!window.location.pathname.match(OLD_VER_URL_MATCH)) {
  serviceWorker.register();

  navigator.serviceWorker.addEventListener("message", async (event) => {
    if (event.data === "installDone") {
      const keys = await caches.keys();
      // the intention is to show the banner once there's an update (a second SW)
      if (keys.length >= 2) {
        store.dispatch(
          showToast(BANNER_NOTIFICATION, {
            stringId: "messages.updateAppPrompt",
            selfDestruct: false,
            confirmButtonStringId: "update",
            onFinish: () => window.location.reload(),
          })
        );
      }
    }
  });
}
