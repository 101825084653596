import React, { useState } from "react";
import { String } from "../../l10n/loader";
import { BREACH_ASSETS } from "../../constants/main";

// DOM
import { Icon, Label, Paragraph } from "@mcafee/pegasus";
import { useDispatch, useSelector } from "react-redux";
import { showPopup } from "../../store/thunks/popups";
import { POPUP_ENCRYPTED_PASSWORD, POPUP_OTP } from "../PopUps/constants";
import { fetchBreachDetails } from "../../store/thunks/identity";
import { analyzeBreachDetails } from "../../utils";
import { SCREEN_BREACH_DETAIL_OTP } from "../../constants/analyticsScreens";
import { ANALYTICS_MANUAL } from "../../constants/analytics";
import "./breachAssetList.scss";
import {
  AccountBalanceOutlined,
  CallOutlined,
  CreditCardOutlined,
} from "@material-ui/icons";

export default function BreachedAssetList({
  id,
  assetsStolen,
  emailAddress,
  password,
  resolved,
  hasPlainTextPassword,
  site,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const s_ = (string) => `identity.breachAssetsList.${string}`;

  const dispatch = useDispatch();
  const otp = useSelector((state) => state.otp);
  const breachDetails = useSelector((state) => state.identity.breachDetails);

  const otpValidated = () => {
    return otp.token && otp.verifiedEmail === breachDetails.email_address;
  };

  const showOtpPopup = async () => {
    let breachSummary = analyzeBreachDetails(breachDetails);

    const otpResults = await dispatch(
      showPopup(POPUP_OTP, {
        newEmail: breachDetails.email_address,
        screen: SCREEN_BREACH_DETAIL_OTP,
        trigger: ANALYTICS_MANUAL,
        emailTrigger: "plain_password",
        details: breachSummary.hasPlainTextPassword
          ? "plaintext_pwd_known_otp"
          : "plaintext_pwd_unknown",
      })
    );
    if (otpResults) {
      //dispatch breachDetails thunk using the otp token
      dispatch(
        fetchBreachDetails({
          breach_ref_id: id,
          hit_trigger: "active_breach_list",
          screen_details: "breach_detail_change_pwd_verified",
        })
      );
    }
    return otpResults;
  };

  const onTogglePassword = (e) => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      if (!otpValidated()) {
        showOtpPopup().then((validated) => {
          if (validated) {
            setShowPassword(true);
          }
        });
      } else {
        setShowPassword(true);
      }
    }
  };

  const onViewHashedPassword = () => {
    dispatch(
      showPopup(POPUP_ENCRYPTED_PASSWORD, {
        site: breachDetails.site !== "unknown",
      })
    );
  };

  const styles = {
    olStyles: {
      marginLeft: "28px",
    },
    liStyles: {
      paddingLeft: "1em",
    },
    showPasswordIcon: {
      cursor: "pointer",
    },
  };

  return (
    <>
      <div className="pa-16 text-tertiary">
        {site ? (
          <div className="flex mb-16">
            <Icon className="mx-8 my-auto" icon={BREACH_ASSETS.site.icon} />
            <Label size="md">{site}</Label>
          </div>
        ) : null}
        {assetsStolen.map((asset) => {
          switch (asset) {
            case BREACH_ASSETS.emailAddress.label:
              return (
                <div className="flex mb-16" key={asset}>
                  <Icon
                    className="mx-8 my-auto"
                    icon={BREACH_ASSETS.emailAddress.icon}
                  />
                  <Label size="md">{emailAddress}</Label>
                </div>
              );
            case BREACH_ASSETS.fullName.label:
              return (
                <div className="flex mb-16" key={asset}>
                  <Icon
                    className="mx-8 my-auto"
                    icon={BREACH_ASSETS.fullName.icon}
                  />
                  <Label size="md">
                    <String id={s_`fullName`} />
                  </Label>
                </div>
              );
            case BREACH_ASSETS.firstName.label:
              return (
                <div className="flex mb-16" key={asset}>
                  <Icon
                    className="mx-8 my-auto"
                    icon={BREACH_ASSETS.fullName.icon}
                  />
                  <Label size="md">
                    <String id={s_`firstName`} />
                  </Label>
                </div>
              );
            case BREACH_ASSETS.lastName.label:
              return (
                <div className="flex mb-16" key={asset}>
                  <Icon
                    className="mx-8 my-auto"
                    icon={BREACH_ASSETS.fullName.icon}
                  />
                  <Label size="md">
                    <String id={s_`lastName`} />
                  </Label>
                </div>
              );
            case BREACH_ASSETS.dob.label:
              return (
                <div className="flex mb-16" key={asset}>
                  <Icon
                    className="mx-8 my-auto"
                    icon={BREACH_ASSETS.dob.icon}
                  />
                  <Label size="md">
                    <String id={s_`dob`} />
                  </Label>
                </div>
              );
            case BREACH_ASSETS.address.label:
              return (
                <div className="flex mb-16" key={asset}>
                  <Icon
                    className="mx-8 my-auto"
                    icon={BREACH_ASSETS.address.icon}
                  />
                  <Label size="md">
                    <String id={s_`address`} />
                  </Label>
                </div>
              );
            case BREACH_ASSETS.phoneNumber.label:
              return (
                <div className="flex mb-16" key={asset}>
                  <CallOutlined className="mx-8 my-auto" />
                  <Label size="md">
                    <String id={s_`phoneNumber`} />
                  </Label>
                </div>
              );
            case BREACH_ASSETS.creditCardNumber.label:
              return (
                <div className="flex mb-16" key={asset}>
                  <CreditCardOutlined className="mx-8 my-auto" />
                  <Label size="md">
                    <String id={s_`creditCardNumber`} />
                  </Label>
                </div>
              );
            case BREACH_ASSETS.ssnNumber.label:
              return (
                <div className="flex mb-16" key={asset}>
                  <AccountBalanceOutlined className="mx-8 my-auto" />
                  <Label size="md">
                    <String id={s_`ssnNumber`} />
                  </Label>
                </div>
              );
            case BREACH_ASSETS.plaintextPassword.label:
              return (
                <div className="flex" key={asset}>
                  <div className="flex grow-1">
                    <Icon
                      className="mx-8"
                      icon={BREACH_ASSETS.plaintextPassword.icon}
                    />
                    <div>
                      <Paragraph size="md">
                        {showPassword && otpValidated()
                          ? password
                          : "************"}
                      </Paragraph>
                    </div>
                  </div>
                  {(resolved || hasPlainTextPassword) && (
                    <Icon
                      style={styles.showPasswordIcon}
                      className="btn__icon mx-16 justify-content-end"
                      icon={
                        showPassword && otpValidated()
                          ? "visibility-on-outline"
                          : "visibility-off-outline"
                      }
                      onClick={onTogglePassword}
                      data-testid="btnTogglePassword"
                    />
                  )}
                </div>
              );
            case BREACH_ASSETS.hashedPassword.label:
              return (
                <div className="flex" key={asset}>
                  <div className="flex grow-1">
                    <Icon
                      className="mx-8"
                      icon={BREACH_ASSETS.hashedPassword.icon}
                    />

                    <Paragraph
                      size="md"
                      className="BreachAsset-content__hashedPwd"
                    >
                      {"************"}
                    </Paragraph>
                    <Icon
                      icon="info-outline"
                      size="md"
                      className="px-12 text-gray-light iconPointer"
                      onClick={onViewHashedPassword}
                      data-testid="btnViewHashedPassword"
                    />
                  </div>
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </>
  );
}

// breach Severity values
// 2: Email only severity. This record is part of an email-only list.
// 5: Informational severity. This severity value is given to breach records where we have a non-crackable password hash, or no password at all.
// 20:High severity. This severity value is given to breach records where we have an email address and a plaintext password.
// 25:Critical severity. This severity value is given to breach records recovered from an infected machine (botnet data).
//    These records will always have a plaintext password and most will have an email address.
