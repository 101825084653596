import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading } from "@mcafee/pegasus";

const NewAssetCancelPopUp = ({ onFinish }) => {
  const localePrefix = "popups.";
  const styles = {
    popUpStyles: {
      maxWidth: 550,
    },
    logoStyles: {
      display: "block",
    },
  };

  return (
    <div style={styles.popUpStyles} className="pa-24">
      <Heading
        className="mb-8 text-left"
        size="xs"
        data-testid="newAssetCancelHeader"
      >
        <String id={`${localePrefix}newAssetCancel.header`} />
      </Heading>

      <Paragraph
        className="mb-24 text-left"
        size="md"
        data-testid="newAssetCancelContent"
      >
        <String id={`${localePrefix}newAssetCancel.content`} />
      </Paragraph>
      <div className="mt-24 flex justify-content-end  text-right">
        <Button
          size="sm"
          onClick={(e) => {
            onFinish(true);
          }}
          id="NewAssetVerify"
          data-testid="newAssetVerifyButton"
          shape="pill"
          variant="primary"
        >
          <String id={`${localePrefix}continueVerify`} />
        </Button>
        <Button
          className="ml-32"
          onClick={(e) => {
            onFinish(false);
          }}
          id="NewAssetDontAdd"
          variant="tertiary"
          data-testid="newAssetDontAddButton"
          size="sm"
        >
          <String id={`${localePrefix}dontAdd`} />
        </Button>
      </div>
    </div>
  );
};

export default NewAssetCancelPopUp;
