import React from "react";
import { connect } from "react-redux";

import Animator from "../../components/Animator";
import { startVpn, stopVpn } from "../../store/thunks/vpn";

import loading from "../../components/_assets/animations/vpn_switch/loading.json";
import loadingToOff from "../../components/_assets/animations/vpn_switch/loading_to_off.json";
import offToLoading from "../../components/_assets/animations/vpn_switch/off_to_loading.json";
import loadingToOn from "../../components/_assets/animations/vpn_switch/loading_to_on.json";

import {
  VPN_STATE_CONNECTING,
  VPN_STATE_DISCONNECTING,
  VPN_STATE_CONNECTED,
  VPN_STATE_DISCONNECTED,
  VPN_STATE_ERROR,
} from "../../constants/main";

const VpnSwitch = ({ className, vpnState, startVpn, stopVpn }) => {
  const toggleVPN = (e) => {
    switch (vpnState) {
      case VPN_STATE_DISCONNECTED:
        startVpn(0);
        break;
      case VPN_STATE_CONNECTED:
        stopVpn(0);
        break;
      default:
        return;
    }
  };

  const getAnimations = () => {
    const listOfAnimations = {
      [VPN_STATE_CONNECTED]: loadingToOn,
      [VPN_STATE_DISCONNECTING]: loading,
      [VPN_STATE_DISCONNECTED]: loadingToOff,
      [VPN_STATE_CONNECTING]: offToLoading,
      [VPN_STATE_ERROR]: loadingToOff,
    };

    return vpnState && listOfAnimations[vpnState]
      ? listOfAnimations[vpnState]
      : listOfAnimations[VPN_STATE_ERROR];
  };

  return (
    <div
      className={className}
      style={{ cursor: "pointer", marginBottom: "38px" }}
    >
      <Animator
        src={getAnimations()}
        extraParams={{ loop: false }}
        onClick={toggleVPN}
        extraStyles={{ height: "80px" }}
        id="VPNSwitch"
        data-testid="VPNSwitch"
      />
    </div>
  );
};

export default connect(
  (state) => ({
    vpnState: state.vpn.state,
    status: state.vpn.status,
  }),
  {
    startVpn,
    stopVpn,
  }
)(VpnSwitch);
