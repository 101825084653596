//All application routes
export const ROUTE_ROOT = "/";
export const ROUTE_WELCOME = "/welcome";
export const ROUTE_WELCOME_MATCH = "/welcome/:step";
export const ROUTE_WELCOME1 = "/welcome/1";
export const ROUTE_WELCOME2 = "/welcome/2";
export const ROUTE_WELCOME3 = "/welcome/3";
export const ROUTE_ACCOUNT = "/account";
export const ROUTE_SCAN_EMAIL = "/scan_email";
export const ROUTE_SCAN_EMAIL_RESULT = "/scan_email_result";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_IDENTITY = "/identity";
export const ROUTE_AV = "/av";
export const ROUTE_RTS = "/rts";
export const ROUTE_QUARANTINED = "/quarantined";
export const ROUTE_BREACH_DETAILS = "/identity/breach/:id";
export const ROUTE_IDENTITY_BREACH = "/identityBreach"; //For outside link via mfe-pps://identityBreach, redirects to /identity
export const ROUTE_SERVICES = "/services";
export const ROUTE_SETTINGS = "/settings";
export const ROUTE_SETTINGS_ABOUT = "/settings/about";
export const ROUTE_SETTINGS_VPN = "/settings/vpn";
export const ROUTE_SETTINGS_NOTIFICATIONS = "/settings/notifications";
export const ROUTE_SETTINGS_ACCOUNT = "/settings/account";
export const ROUTE_SETTINGS_IDENTITY = "/settings/identity";
export const ROUTE_SETTINGS_FEEDBACK = "/settings/feedback";
export const ROUTE_SETTINGS_ANTIVIRUS = "/settings/antivirus";
export const ROUTE_DEVICES = "/devices";
export const ROUTE_PRIVACY = "/privacy";
// export const ROUTE_POST_SIGN_IN = "/post_sign_in";
export const ROUTE_ONBOARD = "/onboard";
export const ROUTE_ONBOARD_WELCOME = "/onboard_welcome";
export const ROUTE_VPN_SETUP = "/vpn_setup";
export const ROUTE_HACK_BLOCK = "/hack_block";
export const ROUTE_DISCONNECT_WIFI = "/disconnect_wifi";
export const ROUTE_DISCONNECT_WIFI_DONE = "/disconnect_wifi_done";
export const ROUTE_VPN_SETUP_DISCLOSURE = "/vpn_setup_disclosure";
export const ROUTE_VPN_SETUP_RUN = "/vpn_setup_run";
export const ROUTE_SECURE_VPN = "/secure_vpn";
export const ROUTE_OPEN_SOURCE_LICENSES = "/settings/licenses";

export const ROUTE_FAILURE = "/failure";

export const ROUTE_ACCOUNT_CREATION_COMPLETE = "/account_creation_complete";

export const ROUTE_TRIAL_ACCOUNT_CREATION_COMPLETE =
  "/trial_account_creation_complete";
export const ROUTE_EXPIRED_SUBSCRIPTION = "/expired_subscription";
export const ROUTE_MONITOR_ASSET_DONE = "/monitor_asset_done";
export const ROUTE_AV_SCAN_RESULT = "/scan_result";
export const ROUTE_AV_SCAN_DETAILS = "/scan_detail";
export const ROUTE_SETTINGS_AV_SCAN_RESULT = "/settings/scan_result";
export const ROUTE_SETTINGS_AV_SCAN_DETAILS = "/settings/scan_detail";

