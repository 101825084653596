export const APP_ID = "f989723c-3e01-4328-8424-740383c26c11";
export const SKU = "0-8907-129864"; //No reference
export const PRODUCT_DEF_CODE = "C835DB9B-CD9D-424E-8F88-FE5C5320FF90"; //No reference
export const DEFAULT_PACKAGE_ID = "0";
export const DEFAULT_FLEX_PACKAGE_ID = "";
export const MIN_API_LEVEL = 1;
export const REC_API_LEVEL = 1;
export const HOST_ID_POLARIS = "polaris-pps";
export const HOST_ID_WEB = "pps";

export const APP_VERSION = "0.2.26";
export const DEFAULT_AFF_ID = 0;
export const DEFAULT_LOCALE = "en-us";

//Mutex names
export const MTX_CACHE = "cache";
export const MTX_PROPS = "properties";
export const MTX_ACCESS_TOKEN = "accessToken";
export const MTX_REFRESH_TOKEN = "refreshToken";
export const MTX_IDS = "identity";
export const MTX_BREACH = "breach";
export const MTX_CARDS = "cards";
export const MTX_DISCOVERY_API = "discoveryApi";
export const MTX_SIGN_OUT = "signOut";
export const MTX_CHECK_NETWORK = "checkNetwork";
export const MTX_FETCH_MOBILE_NUMBER = "fetchMobileNumber";

export const SETTING_SERVICE_URLS = "ServiceURLs";
export const SETTING_VPN_STARTUP = "device/vpn.profile";
export const SETTING_VPN_NOTIFY_DEVICE = "account/notifications.vpn.device";
export const SETTING_GENERAL_NOTIFY_EMAIL =
  "account/notifications.account.email";
export const SETTING_GENERAL_NOTIFY_DEVICE =
  "account/notifications.account.device";
export const SETTING_IDENTITY_NOTIFY_EMAIL =
  "account/notifications.identity.email";
export const SETTING_IDENTITY_NOTIFY_DEVICE =
  "account/notifications.identity.device";
export const SETTING_EULA_STATUS = "device/eula_status";

export const SETTING_VPN_CONNECT_AUTO = "auto";
export const SETTING_VPN_CONNECT_ON = "always_on";
export const SETTING_VPN_CONNECT_MANUAL = "manual";
export const SETTING_VPN_RECOMMENDATIONS = "recommendations";
export const SETTING_VPN_SECURITY_ALERTS = "securityAlerts";
export const SETTING_VPN_CONNECTIONS = "VPNConnections";

export const SETTINGS = "settings";

//Discovery API Keys
export const API_PPS = "pps_authentication";
export const API_IDS_DASHBOARD = "pps_dashboard";
export const API_IDS_HISTORY = "idshistory_unified_apis";
export const API_IDENTITY = "ids_unified_apis";
export const API_VAULT = "vault_unified_apis";
export const API_MESSAGING = "pps_messaging";
export const API_SECURITY_MGMT = "security_mgmt";
export const API_UNIFIED_AUTH = "unified_auth";

export const FALLBACK_APIS_PROD = {
  [API_UNIFIED_AUTH]: "https://logon.mcafee.com",
  [API_PPS]: "https://pps-api.unifiedapis.mcafee.com",
  [API_VAULT]: "https://vault.unifiedapis.mcafee.com",
  [API_IDS_DASHBOARD]: "https://pps-dashboard.unifiedapis.mcafee.com",
  [API_SECURITY_MGMT]: "https://securitymgmt.unifiedapis.mcafee.com",
  [API_MESSAGING]: "https://messaging.ccs.mcafee.com",
  [API_IDENTITY]: "https://identity.unifiedapis.mcafee.com",
  [API_IDS_HISTORY]: "https://idshistory.unifiedapis.mcafee.com",
  //Unused in web-ui
  // unified_policy: "https://unifiedpolicy.ccs.mcafee.com",
  // csp_token_service: "https://csptoken.ccs.mcafee.com",
  // tunnelbear_api: "https://api.polargrizzly.com",
  // pps_ui: "https://pps-ui.unifiedapis.mcafee.com",
};
export const FALLBACK_APIS_STAGING = {
  [API_UNIFIED_AUTH]: "https://accountstaging.mcafee.com",
  [API_PPS]: "https://pps-api.stg.unifiedapis.mcafee.com",
  [API_VAULT]: "https://vault.unifiedapis.stg.mcafee.com",
  [API_IDS_DASHBOARD]: "https://pps-dashboard.stg.unifiedapis.mcafee.com",
  [API_SECURITY_MGMT]: "https://securitymgmt.staging.unifiedapis.mcafee.com",
  [API_MESSAGING]: "https://messaging-stg.ccs.mcafee.com",
  [API_IDENTITY]: "https://identity.stg.unifiedapis.mcafee.com",
  [API_IDS_HISTORY]: "https://idshistory.stg.unifiedapis.mcafee.com",
  //Unused in web-ui
  // unified_policy: "https://unifiedpolicy.stg.ccs.mcafee.com",
  // csp_token_service: "https://csptoken.stg.ccs.mcafee.com",
  // tunnelbear_api: "https://api.stg.polargrizzly.com",
  // pps_ui: "https://pps-ui.stg.unifiedapis.mcafee.com",
};

export const FALLBACK_APIS_QA = {
  [API_UNIFIED_AUTH]: "https://account.qa.mcafee.com",
  [API_PPS]: "https://pps-api.qa.unifiedapis.mcafee.com",
  [API_VAULT]: "https://vault.qa.unifiedapis.mcafee.com",
  [API_IDS_DASHBOARD]: "https://pps-dashboard.qa.unifiedapis.mcafee.com",
  [API_SECURITY_MGMT]: "https://securitymgmt.qa.unifiedapis.mcafee.com",
  [API_MESSAGING]: "https://messaging-qa1.ccs.mcafee.com",
  [API_IDENTITY]: "https://identity.qa.unifiedapis.mcafee.com",
  [API_IDS_HISTORY]: "https://idshistory.qa.unifiedapis.mcafee.com",
  //Unused in web-ui
  // unified_policy: "https://unifiedpolicy.qa.ccs.mcafee.com",
  // csp_token_service: "https://csptoken.qa.ccs.mcafee.com",
  // tunnelbear_api: "https://api.qa.polargrizzly.com",
  // pps_ui: "https://pps-ui.qa.unifiedapis.mcafee.com",
};
export const FALLBACK_APIS_DEV = FALLBACK_APIS_QA;

export const API_EULA_PRIVACY =
  "https://www.mcafee.com/en-us/consumer-support/policy/legal.html?culture=en-us#privacytop";
export const API_EULA_LEGAL =
  "https://www.mcafee.com/en-us/consumer-support/policy/legal.html?culture=en-us#eula";
export const DEFAULT_LEGAL_VERSION = "EULA_v8";
export const DEFAULT_PRIVACY_VERSION = "Default";

//App supported features
export const FEATURE_IDENTITY = "ids";
export const FEATURE_VPN = "vpn";
export const FEATURE_SUBSCRIPTION = "subscription";
export const FEATURE_AV = "av";

export const NETWORK_STATUS_SECURED = "secured";
export const NETWORK_STATUS_UNSECURED = "unsecured";
export const NETWORK_STATUS_ATTACKED = "ssl-attack";
export const NETWORK_STATUS_HACKED = "hack";

//LocalStorage keys
export const LS_CACHED_APIS = "device/uiApiCache"; //No longer used starting from 0.2.13
export const LS_CACHE_LIST = "device/uiCacheList";
export const LS_DONE_WELCOME = "device/uiDoneWelcome";
export const LS_EULA_ACCEPTED = "device/uiEulaAccepted";
export const LS_ACCESS_TOKEN = "device/accessToken";
export const LS_ID_TOKEN = "device/idToken";
export const LS_SESSION = "device/session";
export const LS_VPN_SETUP = "device/vpnSetup";
export const LS_LOCALE = "device/locale";
export const LS_DEVICE_GUID = "device/deviceGUID";
export const LS_CLIENT_ID = "device/cspid";
export const LS_DEVICE_INFO = "device/deviceInfo"; //Not really used
export const LS_SPLIT_PRODUCT_ID = "device/split_product_id";
export const LS_CARRIER_ID = "device/sc_carrier_id";
export const LS_PRODUCT_ID = "device/sc_product_id";
export const LS_DONE_TRIAL_PROMPT = "device/done_trial_prompt";
export const LS_DONE_WSS_PROMPT = "device/done_wss_prompt";

export const LS_EMAIL = "account/email";
export const LS_AFF_ID = "account/aff_id";
export const LS_PROVISION_ID = "account/provisionId";
export const LS_USER_ACTIONS = "account/uiUserActions";
export const LS_RESPAWN = "account/uiRespawnScheduler";
export const LS_PRODUCT_DEFINITION = "product_definition";
export const LS_REMOTE_CONFIG = "remote_config";
export const LS_LAST_SUB_SYNC_SERVER_TIMESTAMP =
  "last_sub_sync_server_timestamp";
export const LS_PRE_EXPIRY_POPUP_INFO = "pre_expiry_popup_info";
export const LS_AV_LAST_SCAN_ID = "av_last_scan_id";
//Session storage keys
export const SS_PAGE_SEQUENCE = "pg_sqn";
// export const SS_USER_ID = "usr_sn";

//Persistent user actions
export const UA_ONBOARDING_SCAN_DONE = "OnboardingScanDone";
export const UA_ONBOARDING_BREACHES_FOUND = "OnboardingBreachesFound";
export const UA_ONBOARDING_EMAIL_ENROLLED = "OnboardingEmailEnrolled";
export const UA_WHAT_APP_CAN_DO_FOR_YOU_SHOWN = "WhatAppCanDoForYouShown";
export const UA_SCHEDULE_CARDS = "ScheduleCards";
export const UA_NOTIFICATIONS_DONE = "NotificationsDone";

// Reminder keys and values
export const REMIND_ONE_HOUR = "hour";
export const REMIND_TOMORROW = "tomorrow";
export const REMIND_NEXT_WEEK = "nextWeek";
export const HOUR_MS = 3600000;
export const DAY_MS = HOUR_MS * 24;
export const WEEK_MS = DAY_MS * 7;

export const CACHE_TTL = 7 * DAY_MS; //One week cache live time
export const CACHE_NO_STORE = "no-store";

export const ONBOARD_WELCOME = "Welcome";
export const ONBOARD_WELCOME_2 = "Welcome_2";
export const ONBOARD_WELCOME_3 = "Welcome_3";
export const ONBOARD_CHECK_EMAIL = "CheckEmail";
export const ONBOARD_CREATE_ACCOUNT = "CreateAccount";
export const ONBOARD_DWS_SAFE = "DWSSafe";
export const ONBOARD_DWS_UNSAFE = "DWSUnsafe";
export const ONBOARD_COMPLETE = "COMPLETE";
export const ONBOARD_HACK_BLOCK_EDUCATE = "HackBlock_Educate";
export const ONBOARD_DISCONNECT_WIFI_EDUCATE = "Disconnect_Wifi_Educate";
export const ONBOARD_DISCONNECT_WIFI_CONFIRM = "Disconnect_Wifi_Confirm";
export const ONBOARD_NEW_ASSET_SAFE = "NewAsset_Safe";
export const ONBOARD_NEW_ASSET_UNSAFE = "NewAsset_UnSafe";
export const ONBOARD_TRIAL = "trial_account_creation_complete";
export const ONBOARD_NEW_ASSET_NO_BREACHES = "NewAsset_no_breaches";
export const ONBOARD_NEW_ASSET_BREACHES_FOUND = "NewAsset_breaches_found";

// Screen sizes
export const MD_VIEWPORT_SIZE = 1024;
export const SM_VIEWPORT_SIZE = 768;

// used for identifying the source of the icons in the dashboard Card headers.
export const ICON_MATERIAL = "material";
export const ICON_PEGASUS = "pegasus";

// used for identifying the type of the Alert text displayed in the dashboard Card headers.
export const RECOMMENDED = "recommended";
export const NEEDS_ATTENTION = "needsAttention";
export const LEARNING = "learning";

export const ENV_QA = "qa";
export const ENV_DEV = "dev";
export const ENV_PROD = "prod";
export const EVN_STAGING = "stg";

export const ACT_SI = "si";

export const TELL_MORE_VPN = "vpnSetup";
export const TELL_MORE_BREACH = "breachInfoCard";

export const REGISTER = "Register";
export const LOGIN = "Login";
export const TRIAL = "Trial";
export const LOGOUT = "logout";

export const AV = "av";
export const AV_SCAN_RESULT = "scanResult";

export const VPN_SETUP = "Setup";
export const VPN_SETUP_RUN = "SetupRun";
export const VPN_PRIVACY = "Privacy";
export const VPN_STATUS_SUCCESS = "success";
export const VPN_STATUS_SETTING_UP = "settingUp";
export const VPN_STATUS_CONNECTING = "connecting";
export const VPN_STATUS_DISCONNECTING = "disconnecting";
export const VPN_STATUS_CHECKING = "checking";
export const VPN_STATUS_FAILURE = "failure";

export const VPN_STATE_DISCONNECTED = "Disconnected";
export const VPN_STATE_DISCONNECTING = "Disconnecting";
export const VPN_STATE_CONNECTING = "Connecting";
export const VPN_STATE_CONNECTED = "Connected";
export const VPN_STATE_ERROR = "Error";
export const VPN_DISCLOSURE_BANDWIDTH = "Bandwidth";
export const VPN_DISCLOSURE_DEVICE = "Device";
export const VPN_DISCLOSURE_NETWORK = "Network";
export const VPN_DISCLOSURE_DATE = "Date";
export const VPN_DISCLOSURE_SESSION = "Session";
export const VPN_DISCLOSURE_CONNECTION = "Connection";
export const VPN_DISCLOSURE_LOCATION = "Location";

// used for displaying Breaches
export const NEW_BREACH_TAB_ID = "NewBreaches";
export const ADDRESSED_BREACH_TAB_ID = "AddressedBreaches";
export const BREACH_STATUS_URGENT = "Urgent";
export const BREACH_STATUS_RESOLVED = "Resolved";
export const ASSET_TYPE_EMAIL = "email";
export const DWS_MONITOR_ENABLED = "Enabled";
export const ASSET_PUBLIC_ID = "asset_public_id";

export const REMEDIATION_NOT_ACTED = 0;
export const REMEDIATION_SHOWN = 1;
export const REMEDIATION_ACTED = 2;

export const REMEDIATE_PASSWORD = "password";

export const FIREBASE_API_KEY = "AIzaSyCCC813M4Ce0IrdhMgtT5BjWSX2nHH2B8A";
export const FIREBASE_AUTH_DOMAIN = "pps-internal.firebaseapp.com";
export const FIREBASE_DATABASE_URL = "https://pps-internal.firebaseio.com";
export const FIREBASE_PROJECT_ID = "pps-internal";
export const FIREBASE_STORAGE_BUCKET = "pps-internal.appspot.com";
export const FIREBASE_MESSAGING_SENDER_ID = "1035744155215";
export const FIREBASE_APP_ID = "1:1035744155215:web:aad0d101a161672977bc3a";

export const OLD_VER_URL_MATCH = /^\/v[0-9]+/;
export const URL_REGEX =
  /^(http|https):\/\/((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const DOMAIN_REGEX =
  /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_REGEX =
  /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9_\-.])+\.([A-Za-z]{2,4}))$/;
export const EMAIL_MATCH =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
export const GUID_MATCH =
  /(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/g;
export const JWT_MATCH =
  /\b[A-Za-z0-9-_=]{20,}\.[A-Za-z0-9-_=]{20,}\.[A-Za-z0-9-_.+/=]{20,}\b/g;
export const CSPID_MATCH = /\b[a-z0-9]{30,}-[a-z0-9]{10,}\b/g;
export const DEVICE_ID_MATCH = /\b[a-z0-9]{32}\b/g;
export const SESSION_ID_MATCH = /\bCfDJ8[A-Za-z0-9-_=]{50,}\b/g;
export const ASSET_ID_MATCH = /\b[A-Z0-9]{30,}\b/g;
export const PLAIN_PASSWORD_MATCH = /"password":".+"/g;
export const MOBILE_REGEX = /\+?\d{1,2}\s?\d{3}\s?\d{3}\s?\d{4}$/;
export const SUBDOMAIN_MATCH = /^([^.]+).*\.mcafee\.com/i;

export const UNKNOWN = "unknown";

export const HTTP_GET = "GET";
export const HTTP_POST = "POST";
export const HTTP_PUT = "PUT";
export const HTTP_PATCH = "PATCH";
export const HTTP_DELETE = "DELETE";

export const HTTP_FETCH_FAILED = 0;
export const HTTP_OK = 200;
export const HTTP_CREATED = 201;
export const HTTP_NO_CONTENT = 204;

export const HTTP_NOT_MODIFIED = 304;

export const HTTP_BAD_REQUEST = 400;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;
export const HTTP_NOT_FOUND = 404;
export const HTTP_METHOD_NOT_ALLOWED = 405;
export const HTTP_NOT_ACCEPTABLE = 406;
export const HTTP_REQUEST_TIMEOUT = 408;
export const HTTP_CONFLICT = 409;
export const HTTP_EXPECTATION_NOT_MET = 417;
export const HTTP_TOO_MANY_REQUESTS = 429;

export const HTTP_SERVER_ERROR = 500;
export const HTTP_NOT_IMPLEMENTED = 501;
export const HTTP_SERVICE_NOT_AVAILABLE = 503;
export const HTTP_GATEWAY_TIMEOUT = 504;

export const ERROR_OTP_INVALID_REQUEST = 40069;
export const ERROR_OTP_LOCK_PERIOD_ACTIVE = 40070;
export const ERROR_OTP_INVALID_TRANSACTION_KEY = 40071;
export const ERROR_OTP_ALREADY_USED = 40072;
export const ERROR_OTP_OVER_USED = 40073;
export const OTP_FLOW_TYPE_PHONE = "add_asset_phone";

export const WINDOW_SIZE_SMALL = 0;
export const WINDOW_SIZE_MEDIUM = 1;
export const WINDOW_SIZE_LARGE = 2;
export const WINDOW_ASPECT_PORTRAIT = 0;
export const WINDOW_ASPECT_LANDSCAPE = 1;

export const SORT_ALPHABETICAL = "alphabetical";
export const SORT_RECENT = "recent";
export const SORT_RISK = "risk";

export const EMPTY_VAL = "";
export const EMPTY_JSON = "{}";
export const TYPE_STRING = "string";
export const TYPE_OBJECT = "object";
export const TYPE_BOOLEAN = "boolean";
export const TYPE_NUMBER = "number";
export const TYPE_SYMBOL = "symbol";
export const TYPE_BIG_INTEGER = "bigint";
export const TYPE_UNDEFINED = "undefined";

export const SUCCESS = "success";
export const ERROR = "error";

export const LOG_LEVEL_VERBOSE = "Verbose";
export const LOG_LEVEL_INFO = "Informational";
export const LOG_LEVEL_WARNING = "Warning";
export const LOG_LEVEL_ERROR = "Error";
export const LOG_LEVEL_CRITICAL = "Critical";

export const FORCE = true;

export const TRUE_S = "true";
export const FALSE_S = "false";

//For feddback screen
export const FEEDBACK_POSITIVE = "positive";
export const FEEDBACK_NEUTRAL = "neutral";
export const FEEDBACK_NEGATIVE = "negative";

export const MCAFEE_SUPPORT_URL =
  "https://home.mcafee.com/root/campaign.aspx?cid=245219";
// Used for storing the list of beached assets
export const BREACH_ASSETS = {
  emailAddress: {
    label: "email_address",
    icon: "email-rounded-outline",
  },
  fullName: { label: "full_name", icon: "user-outline" },
  firstName: { label: "first_name", icon: "user-outline" },
  lastName: { label: "last_name", icon: "user-outline" },
  address: { label: "address", icon: "home-outline" },
  dob: { label: "date_of_birth", icon: "date-range" },
  phoneNumber: { label: "phone_number", icon: "call" },
  creditCardNumber: { label: "credit_card_number" },
  ssnNumber: { label: "social_security_number" },
  plaintextPassword: { label: "plaintext_password", icon: "lock-outline" },
  hashedPassword: { label: "hashed_password", icon: "lock-outline" },
  site: { label: "source", icon: "home-outline" },
};

export const DEFAULT_CLIENT_ID =
  "3b8a47ebb4da4f4db11c1b894def5b0c-d1e74b7f3a16"; //Temporary used for DEV mode on web clients

export const FULL_NAME = "Full Name";
export const FIRST_NAME = "First name";
export const LAST_NAME = "Last name";
export const DOB = "Date of birth";
export const ADDRESS = "Address";
export const CREDIT_CARD = "Credit card";
export const SSN = "SSN";
export const PHONE_NUMBER = "Phone Number";

export const APP_ATLAS = "pps-ui";
export const APP_POLARIS = "polaris";
export const APP_IDENTITY_PORTAL = "identityportal";

export const AUTH_POPUP = "popup";
export const AUTH_IFRAME = "iframe";

export const SYNC = "sync";
export const SECONDS_IN_A_DAY = 86400;
export const RANGE_OF_DANGER_DAYS_BIGGEST = 5;
export const RANGE_OF_DANGER_DAYS_SMALLEST = 1;
export const AMOUNT_OF_DAYS_TO_SHOW_REMINDER = 30;
export const HEARTBEAT_INTERVAL = 60 * 1000 * 15; //TODO: 60 sec
export const CHECK_PRODUCT_DEF_INTERVAL = 60 * 60 * 1000; // 1H in msec

export const FALLBACK_PRODUCT_ID_PPS = "pps";

export const EMAIL_MAX_LENGTH = 49;

export const LTR_DIRECTION = "ltr";
export const RTL_DIRECTION = "rtl";

export const GITHUB_PAGES_DOMAIN =
  "https://github-lvs.corpzone.internalzone.com";
export const GITHUB_PAGES_DEFAULT_BASENAME = "/pages/mcafee/pps-ui";

export const LAUNCHUA_SIGN_IN = "signIn";
export const LAUNCHUA_CREATE_ACCOUNT = "createAccount";

export const DELAY_FETCH_DWS_DASHBOARD = 4000;
export const MAX_ERROR_RETRY = 3;

export const ONBOARD = "onboard";
export const LOCAL_IO_BUS_URL = "http://localhost:9666";
