import React from "react";
import { useSelector } from "react-redux";
import { TopProgressBar } from "../styledComponents/progress";

export default function BackgroundProgress() {
  const signingOut = useSelector((state) => state.auth.signingOut);
  const {
    fetching: fetchingCards,
    cards,
    fetchMobileProgress,
    updateMobileProgress,
  } = useSelector((state) => state.dashboard);
  const {
    addingAsset,
    removingAsset,
    remediating,
    fetchingDashboard,
    fetchingBreachDetails,
    breaches,
    breachDetails,
  } = useSelector((state) => state.identity);

  const identityProgress =
    (fetchingDashboard && !!breaches) ||
    (fetchingBreachDetails && !!breachDetails) ||
    addingAsset ||
    removingAsset ||
    remediating;

  const cardsProgress = fetchingCards && cards !== null;

  const accountSettingsProgress = fetchMobileProgress || updateMobileProgress;

  if (
    signingOut ||
    cardsProgress ||
    identityProgress ||
    accountSettingsProgress
  ) {
    return <TopProgressBar infinite />;
  }

  return null;
}
