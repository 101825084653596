// React
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Label, Paragraph, Button } from "@mcafee/pegasus";

import { POPUP_SYNC_SUBSCRIPTION } from "../PopUps/constants";

// utils
import { String } from "../../l10n/loader";

import { showPopup } from "../../store/thunks/popups";
import {
  getSubscriptionStatus,
  subscriptionInitiatedEvent,
  isSubscription,
} from "../../utils";
import {
  ANALYTICS_SETTING,
  FEATURE_SUBSCRIPTION,
  SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION,
} from "../../constants";
import { SyncRounded } from "@material-ui/icons";
import "./settings.scss";

export default function SubscriptionInformation() {
  const _S = (str) => `settings.account.subscription.${str}`;
  const dispatch = useDispatch();

  const { email } = useSelector((state) => state.auth);

  const { subscriptionStatus, expiryDate, renewUrl } = useSelector(
    (state) => state.subscription
  );

  const { locale, packageName } = useSelector((state) => state.context);

  const features = useSelector((state) => state.experience.features);

  const onSyncSubscription = () => {
    dispatch(showPopup(POPUP_SYNC_SUBSCRIPTION));
  };

  const onRenewNow = () => {
    subscriptionInitiatedEvent(
      SCREEN_SETTINGS_ACCOUNT_MY_SUBSCRIPTION,
      ANALYTICS_SETTING
    );
    window.open(renewUrl, "_blank");
  };

  const getFormattedDate = () => {
    const baseDate = new Date(expiryDate);
    if (locale.includes("en")) {
      return `${baseDate.toLocaleString(locale, {
        month: "long",
      })} ${baseDate.getDate()}, ${baseDate.getFullYear()}`;
    }
    return baseDate.toLocaleString(locale, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const currentSubscriptionStatus = getSubscriptionStatus(subscriptionStatus);

  const showSubscriptionActions =
    features && features[FEATURE_SUBSCRIPTION].enabled;

  return (
    <>
      <Label size="sm" className="mb-4 text-body">
        <String id={_S`title`} />
      </Label>
      <Paragraph size="sm" className="mb-24">
        <String
          id={
            showSubscriptionActions
              ? _S(`${currentSubscriptionStatus}.content`)
              : _S(`hideSubscription.content`)
          }
          values={{ subscriptionName: packageName }}
        />
      </Paragraph>
      <div
        className={`mb-24 ${showSubscriptionActions ? "ml-12" : "bg-disabled"}`}
      >
        <Label
          size="sm"
          className={`mb-24 flex ${
            !showSubscriptionActions ? "ml-12 mt-24" : ""
          }`}
        >
          <String
            id={_S`email`}
            values={{
              email: (
                <Paragraph className="ml-4" size="sm">
                  {email}
                </Paragraph>
              ),
            }}
          />
        </Label>
        <Label
          size="sm"
          className={`mb-24 flex ${!showSubscriptionActions ? "ml-12" : ""}`}
        >
          <String
            id={_S`status`}
            values={{
              status: (
                <Paragraph
                  className={`${
                    !showSubscriptionActions
                      ? "text-decorative subscriptionInformation__status"
                      : isSubscription(subscriptionStatus) && "text-success"
                  } ml-4
                  `}
                  size="sm"
                >
                  <String id={_S(currentSubscriptionStatus)} />
                </Paragraph>
              ),
            }}
          />
        </Label>
        <Label
          size="sm"
          className={`flex ${
            !showSubscriptionActions ? "mb-24 ml-12" : "mb-4"
          }`}
        >
          <String
            id={_S`expiryDate`}
            values={{
              expiryDate: (
                <Paragraph className="ml-4" size="sm">
                  {getFormattedDate()}
                </Paragraph>
              ),
            }}
          />
        </Label>
      </div>
      {showSubscriptionActions && (
        <div className="mb-12">
          <Button
            size="sm"
            className="mb-12 mr-12"
            onClick={onSyncSubscription}
            id="SubscriptionSync"
          >
            <String id={_S`syncSubscription`} />
            <SyncRounded className="ml-8" />
          </Button>
          <Button
            size="sm"
            className="mb-12"
            variant="secondary"
            onClick={onRenewNow}
            id="SubscriptionRenewNow"
          >
            <String id={_S`renewNow`} />
          </Button>
        </div>
      )}
    </>
  );
}
