import {
  BREACH_ASSETS,
  DOMAIN_REGEX,
  UNKNOWN,
  URL_REGEX,
} from "../constants/main";

/**
 * Inspect breachDetails and return summarized information
 * @param {*} breachDetails
 * @returns {{assetsStolen:string[], hasPII:boolean, hasPassword:boolean, hasPlainTextPassword:boolean, hasSSN:boolean, siteKnown:boolean}}
 */
export const analyzeBreachDetails = (breachDetails) => {
  const summary = {};
  const assetsStolen = [];
  assetsStolen.push(BREACH_ASSETS.emailAddress.label);

  if (
    breachDetails.full_name_available ||
    (breachDetails.first_name_available && breachDetails.last_name_available)
  ) {
    assetsStolen.push(BREACH_ASSETS.fullName.label);
  } else if (breachDetails.first_name_available) {
    assetsStolen.push(BREACH_ASSETS.firstName.label);
  } else if (breachDetails.last_name_available) {
    assetsStolen.push(BREACH_ASSETS.lastName.label);
  }

  if (breachDetails.address_1_available || breachDetails.address_2_available)
    assetsStolen.push(BREACH_ASSETS.address.label);

  if (breachDetails.phone_number_available)
    assetsStolen.push(BREACH_ASSETS.phoneNumber.label);

  if (breachDetails.ssn_available)
    assetsStolen.push(BREACH_ASSETS.ssnNumber.label);

  // ToDo: Discuss whether creditCardNumber is recorded under bank_acct_number_available key.
  if (breachDetails.bank_acct_number_available)
    assetsStolen.push(BREACH_ASSETS.creditCardNumber.label);

  if (breachDetails.password_available || breachDetails.password) {
    if (breachDetails.password_type.toLowerCase() === "plaintext") {
      assetsStolen.push(BREACH_ASSETS.plaintextPassword.label);
    } else {
      assetsStolen.push(BREACH_ASSETS.hashedPassword.label);
    }
  }

  summary.assetsStolen = assetsStolen;

  const site = breachDetails.site;
  summary.site =
    site &&
    (site.match(DOMAIN_REGEX) || site.match(URL_REGEX)) &&
    site !== UNKNOWN
      ? breachDetails.title
      : null;
  summary.siteKnown =
    site &&
    (site.match(DOMAIN_REGEX) || site.match(URL_REGEX)) &&
    site !== UNKNOWN
      ? true
      : false;

  summary.hasPassword = assetsStolen.some((a) =>
    [
      BREACH_ASSETS.plaintextPassword.label,
      BREACH_ASSETS.hashedPassword.label,
    ].includes(a)
  );

  summary.hasPlainTextPassword = assetsStolen.some((a) =>
    [BREACH_ASSETS.plaintextPassword.label].includes(a)
  );

  summary.hasPII = assetsStolen.some((a) =>
    [
      BREACH_ASSETS.fullName.label,
      BREACH_ASSETS.address.label,
      BREACH_ASSETS.dob.label,
      BREACH_ASSETS.phoneNumber.label,
      BREACH_ASSETS.ssnNumber.label,
      BREACH_ASSETS.emailAddress.label,
    ].includes(a)
  );

  summary.hasSSN = assetsStolen.some((a) =>
    [BREACH_ASSETS.ssnNumber].includes(a)
  );

  return summary;
};
