// TODO: clean up this file

export const POPUP_ADD_EMAIL_DEVICE = "ProtectEmailDevicePopUp";
// export const POPUP_ADD_MOBILE_DEVICE = "ProtectMobileDevicePopUp";
export const POPUP_SUCCESS = "SuccessPopUp";
export const POPUP_FAILURE = "FailurePopUp";
// export const POPUP_EDIT_DEVICE = "EditDevicePopUp";
export const POPUP_SECURE_VPN = "SecureVpnPopUp";
export const POPUP_NEW_ASSET_CANCEL = "NewAssetCancelPopUp";
export const POPUP_OTP = "OtpPopup";
export const POPUP_CONFIRM_SIGNOUT = "ConfirmSignOutPopUp";
export const POPUP_DWS_LEARN_MORE = "DWSLearnMore";
export const POPUP_SURVEY = "Survey";
export const POPUP_TELL_ME_MORE = "TellMeMoreModal";
export const POPUP_FIVE_REASONS = "FiveReasonsModal";
export const POPUP_ONBOARD_KNOWMORE = "OnboardKnowMorePopUp";
export const POPUP_NOTIFICATIONS = "NotificationsModal";
// export const POPUP_DATA_DISCLOSURE = "DataDisclosureModal";
export const POPUP_ADD_EMAIL_IDENTITY = "AddEmailPopUp";
export const POPUP_REMOVE_EMAIL = "RemoveEmailPopup";
export const POPUP_ADD_PHONE_NUMBER = "AddPhoneNumberPopUp";
export const POPUP_REMOVE_PHONE_NUMBER = "RemovePhoneNumberPopUp";
export const POPUP_CONFIRM_BEFORE_EXTERNAL_LINK =
  "ConfirmBeforeExternalLinkPopup";
export const POPUP_CONFIRM_VPN_DISCLOSURE = "ConfirmVpnDisclosurePopup";
export const POPUP_WHAT_CAN_YOU_DO = "WhatCanYouDoPopup";
export const POPUP_PASSWORD_TIPS = "PasswordTipsPopup";
export const POPUP_ADDRESSED_BREACHES = "AddressedBreachPopup";
export const POPUP_ENCRYPTED_PASSWORD = "EncryptedPasswordPopup";
export const POPUP_CONFIRM_BEFORE_LEAVE_PAGE = "ConfirmBeforeLeavePagePopup";
export const POPUP_VIRTUAL_LOCATION = "VirtualLocationPopup";
export const POPUP_CONFIRM_PASSWORD_CHANGE = "ConfirmPasswordChangePopup";
export const POPUP_ASSET_ADDED = "AssetAddedPopUp";
export const POPUP_SYNC_SUBSCRIPTION = "SyncSubscriptionPopup";
export const POPUP_PRE_EXPIRY = "PreExpiryPopup";
export const POPUP_NO_PHONE_FOUND = "NoPhoneFoundPopUp";
export const POPUP_WSS = "WssPopup";
export const POPUP_AV_SCAN_TYPES = "AvScanTypesPopup";
export const POPUP_AV_SCAN_EDUCATION = "AvScanEducationPopup";
export const POPUP_AV_CANCEL_SCAN = "AvCancelScanPopup";
export const POPUP_AV_RESTORE_FAILED = "AvRestoreFailedPopup";
export const POPUP_AV_SCAN_FILE_DETAILS = "AvScanFileDetailsPopup";
export const POPUP_AV_DELETE_FILE = "AvDeleteFilePopup"
export const POPUP_AV_EXCLUDE_FILE = "AvExcludeItemPopup"
export const POPUP_AV_REMOVE_EXCLUDED_FILE = "AvRemoveExcludedItemPopup"
export const POPUP_AV_RESTORE_FILE= "AvRestoreItemPopup"