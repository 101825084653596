// React
import React from "react";
import { useDispatch } from "react-redux";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import NotificationPermissionImage from "../_assets/images/dashboard/cards/notificationPermission.svg";

// utils
import { POPUP_NOTIFICATIONS } from "../PopUps/constants";

// Store
import { showPopup } from "../../store/thunks/popups";

// utils
import { RECOMMENDED, UA_NOTIFICATIONS_DONE } from "../../constants/main";
import { updateUserActions } from "../../store/auth";

export default function AddNotificationPermission({ id }) {
  const dispatch = useDispatch();

  const onPrimaryAction = async (e) => {
    if (await dispatch(showPopup(POPUP_NOTIFICATIONS))) {
      dispatch(updateUserActions({ [UA_NOTIFICATIONS_DONE]: true }));
    }
  };

  const card = {
    id: id,
    backgroundImage: NotificationPermissionImage,
    tagText: RECOMMENDED,
    primaryActionStringId: "turnOnNotifications",
    primaryActionDataTestId: "notificationTurnOnNotifications",
    onPrimaryAction,
  };

  return <DashboardCard {...card} />;
}
