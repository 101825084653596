import { createSlice } from "@reduxjs/toolkit";
import { signOutSuccess } from "./auth";

const initialState = {
  token: null,
  verifiedEmail: null,
  verifyProgress: false,
  verifyError: null,
  sentPhoneNumber: null,
  verifiedPhoneNumber: null,
  sentEmail: null,
  sendProgress: false,
  sendError: null,
  transaction_key: null,
  expiry_in_mins: 0,
  regen_after_seconds: 0,
  verify_attempts_remaining: 0,
  resend_remaining: 0,
  resend_after_seconds: 0,
  resend_time: null,
  expiry_time: null,
  isNewRequest: null,
};

const slice = createSlice({
  name: "@otp",
  initialState,
  reducers: {
    sendOtpBegin: (state, action) => {
      state.sentEmail = action.payload.email;
      state.sentPhoneNumber = action.payload.phone_number;
      state.sendProgress = true;
      state.sendError = null;
      state.verifyError = null;
    },
    sendOtpFailure: (state, action) => {
      state.sendError = action.payload;
      state.sendProgress = false;
      state.transaction_key = null;
    },
    sendOtpSuccess: (state, action) => {
      const now = Date.now();
      const {
        transaction_key,
        expiry_in_mins,
        regen_after_seconds,
        verify_attempts_remaining,
        resend_remaining,
        resend_after_seconds,
        isResend,
      } = action.payload;
      state.sendProgress = false;
      state.sendError = null;
      state.transaction_key = transaction_key;
      state.expiry_in_mins = expiry_in_mins;
      state.expiry_time = now + expiry_in_mins * 60 * 1000;
      state.regen_after_seconds = regen_after_seconds;
      state.verify_attempts_remaining = verify_attempts_remaining;
      state.resend_remaining = resend_remaining;
      state.resend_after_seconds = resend_after_seconds;
      state.isNewRequest = !isResend;
      if (resend_after_seconds > 0) {
        state.resend_time = now + resend_after_seconds * 1000;
        state.transaction_key = null; //new code is required
      } else {
        state.resend_time = null;
      }
    },

    verifyOtpBegin: (state) => {
      state.verifyProgress = true;
      state.verifyError = null;
      state.sendError = null;
    },
    verifyOtpFailure: (state, action) => {
      state.verifyProgress = false;
      state.verifyError = action.payload;
      state.verify_attempts_remaining =
        state.verify_attempts_remaining > 0
          ? state.verify_attempts_remaining - 1
          : 0;
    },
    verifyOtpSuccess: (state, action) => {
      state.token = action.payload.token;
      state.verifiedEmail = state.sentEmail;
      state.verifiedPhoneNumber = state.sentPhoneNumber;
      state.sentPhoneNumber = null;
      state.verifyError = null;
      state.verifyProgress = false;
    },

    resetOtp: () => {
      return initialState;
    },
  },
  extraReducers: {
    [signOutSuccess]: () => {
      return initialState;
    },
  },
});

export const {
  sendOtpBegin,
  sendOtpFailure,
  sendOtpSuccess,

  verifyOtpBegin,
  verifyOtpFailure,
  verifyOtpSuccess,

  resetOtp,
} = slice.actions;
export default slice.reducer;
