import React, { useCallback, useEffect, useState } from "react";
import ServiceCard from "./ServiceCard";
import { String } from "../../l10n/loader";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
  ROUTE_SECURE_VPN,
  ROUTE_VPN_SETUP,
  ROUTE_SETTINGS_VPN,
} from "../app/routes";
import { Switch, Label } from "@mcafee/pegasus";
import {
  VPN_STATE_CONNECTED,
  FEATURE_VPN,
  VPN_STATE_DISCONNECTED,
  VPN_STATE_CONNECTING,
  VPN_STATE_DISCONNECTING,
  VPN_STATE_ERROR,
  VPN_STATUS_FAILURE,
} from "../../constants/main";

import { startVpn, stopVpn } from "../../store/thunks/vpn";

import vpnOnIcon from "../../components/_assets/images/services/vpnOn.svg";
import vpnOffIcon from "../../components/_assets/images/services/vpnOff.svg";
import vpnErrorIcon from "../../components/_assets/images/services/vpnError.svg";

import {
  getScreenCategory,
  getScreenType,
  collectScreenLoadEvent,
} from "../../utils";
import {
  ANALYTICS_CARD,
  ANALYTICS_FAILURE,
  ANALYTICS_MANUAL,
  ANALYTICS_NEW,
  ANALYTICS_SUCCESS,
  ANALYTICS_VPN,
} from "../../constants/analytics";
import { SCREEN_SERVICES_SECURE_VPN } from "../../constants/analyticsScreens";
import { waitFor } from "../../utils";
import CheckFeature from "../Shared/CheckFeature";

const VpnServiceCard = ({
  vpnSetup,
  state: vpnState,
  status: vpnStatus,
  push,
  startVpn,
  stopVpn,
}) => {
  const localePrefix = "services.";
  const contentPrefix = "vpnCard";

  const feature = FEATURE_VPN;
  const disabled = false;

  const tagContent = <String id={`${localePrefix}new`} />;
  const tagKind = "decorative";

  const vpnContent = {
    [VPN_STATE_CONNECTED]: {
      imgIcon: vpnOnIcon,
      styles: { color: "#0CA77D" },
      kind: "success",
      vpnStateContent: "secureVpnOn",
      progress: false,
    },
    [VPN_STATE_DISCONNECTING]: {
      imgIcon: vpnOnIcon,
      vpnStateContent: "turningOff",
      progress: true,
    },
    [VPN_STATE_DISCONNECTED]: {
      imgIcon: vpnOffIcon,
      vpnStateContent: "secureVpnOff",
      progress: false,
    },
    [VPN_STATE_CONNECTING]: {
      imgIcon: vpnOffIcon,
      vpnStateContent: "turningOn",
      progress: true,
    },
    [VPN_STATE_ERROR]: {
      imgIcon: vpnErrorIcon,
      vpnStateContent: "secureVpnOff",
      progress: false,
    },
  };

  const [hitLabel0, setHitLabel0] = useState(undefined);
  const [hitLabel6, setHitLabel6] = useState(undefined);
  const [hitTrigger, setHitTrigger] = useState(undefined);
  const [isEventSent, setIsEventSent] = useState(false);

  const onClick = (e) => {
    push({
      pathname: vpnSetup ? ROUTE_SECURE_VPN : ROUTE_VPN_SETUP,
      search: "back=/services",
    });
  };

  const onSettingsClick = (e) => {
    push({
      pathname: ROUTE_SETTINGS_VPN,
      search: "back=/services",
    });
  };

  const toggleVPN = (e) => {
    e.preventDefault();
    switch (vpnState) {
      case VPN_STATE_DISCONNECTED:
        startVpn(0);
        break;
      case VPN_STATE_CONNECTED:
        stopVpn(0);
        break;
      default:
        return;
    }
  };

  const prepareAnalytics = useCallback(async () => {
    await waitFor(() => vpnStatus && vpnState);
    setHitLabel0(
      vpnSetup && vpnStatus === VPN_STATUS_FAILURE
        ? ANALYTICS_FAILURE
        : ANALYTICS_SUCCESS
    );
    setHitLabel6(
      vpnSetup
        ? vpnState === VPN_STATE_CONNECTED
          ? "secure_vpn_on"
          : "secure_vpn_off"
        : null
    );
    setHitTrigger(!vpnSetup ? ANALYTICS_NEW : ANALYTICS_MANUAL);
  }, [vpnSetup, vpnState, vpnStatus]);

  useEffect(() => {
    if (!isEventSent) {
      if (!hitTrigger && !hitLabel6 && !hitLabel0) {
        prepareAnalytics();
      } else if (hitTrigger && hitLabel0 && hitLabel6 !== undefined) {
        collectScreenLoadEvent({
          hit_category_0: getScreenCategory(SCREEN_SERVICES_SECURE_VPN),
          hit_feature: ANALYTICS_VPN,
          hit_screen: SCREEN_SERVICES_SECURE_VPN,
          hit_label_0: hitLabel0,
          hit_label_3: getScreenType(SCREEN_SERVICES_SECURE_VPN),
          hit_label_5: ANALYTICS_CARD,
          hit_label_6: hitLabel6,
          hit_label_8: "services",
          hit_trigger: hitTrigger,
        });
        setIsEventSent(true);
      }
    }
  }, [hitLabel0, hitLabel6, hitTrigger, isEventSent, prepareAnalytics]);

  return (
    <CheckFeature feature={FEATURE_VPN}>
      <ServiceCard
        {...{
          feature,
          icon:
            vpnSetup && vpnContent[vpnState]
              ? vpnStatus === VPN_STATUS_FAILURE
                ? vpnErrorIcon // error icon if start/stop fails
                : vpnContent[vpnState].imgIcon // other cases
              : vpnOffIcon, // icon when vpn wasn't set
          tagKind,
          tagContent,
          onClick,
          onSettingsClick:
            vpnSetup && vpnContent[vpnState] ? onSettingsClick : null,
          disabled,
          contentPrefix,
        }}
      >
        {vpnSetup && vpnContent[vpnState] && (
          <div
            className="flex align-items-center"
            style={vpnContent[vpnState].styles}
          >
            <Switch
              kind={vpnContent[vpnState].kind}
              onClick={toggleVPN}
              checked={
                vpnState === VPN_STATE_CONNECTED ||
                vpnState === VPN_STATE_CONNECTING
              }
              disabled={vpnContent[vpnState].progress === true}
              id="switchVpn"
              data-testid="switchVpn"
            />
            <Label id="switchVpnLabel" size="sm">
              <String
                id={`${localePrefix}${vpnContent[vpnState].vpnStateContent}`}
              />
            </Label>
          </div>
        )}
      </ServiceCard>
    </CheckFeature>
  );
};

export default connect(
  (state) => ({
    ...state.vpn,
  }),
  {
    push,
    startVpn,
    stopVpn,
  }
)(VpnServiceCard);
