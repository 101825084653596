// React
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Store
import { showPopup } from "../../store/thunks/popups";

// DOM
import "./settings.scss";
import "./identitySettingsPage.scss";

import {
  Icon,
  Label,
  Paragraph,
  List,
  ListItem,
  Popover,
  PopoverContent,
  PopoverHandler,
  PopoverItem,
} from "@mcafee/pegasus";

// utils
import { String } from "../../l10n/loader";
import {
  POPUP_ADD_EMAIL_IDENTITY,
  POPUP_REMOVE_EMAIL,
} from "../PopUps/constants";

import { removeAsset, fetchDwsDashboard } from "../../store/thunks/identity";
import {
  ROUTE_SCAN_EMAIL_RESULT,
  ROUTE_SETTINGS_IDENTITY,
} from "../app/routes";
import { push } from "connected-react-router";
import {
  collectScreenLoadEvent,
  createQueryString,
  navToErrorPage,
} from "../../utils";
import {
  ANALYTICS_BOTTOM_SHEET,
  ANALYTICS_ID_PROTECTION,
  ANALYTICS_SETTING,
  HIT_CATEGORY,
  HIT_FEATURE,
  HIT_SCREEN,
  MAX_ERROR_RETRY,
  SCREEN_FLOW,
  SCREEN_SETTINGS_DELETE_EMAIL,
  SCREEN_TYPE,
} from "../../constants";
import { DeleteForeverOutlined, MoreVertRounded } from "@material-ui/icons";
import { showToast } from "../../store/thunks/toasts";
import {
  BANNER_NOTIFICATION,
  TOAST_SNACKBAR_SUCCESS,
} from "../Toasts/constants";
import ErrorMessage from "../Shared/ErrorMessage";
import { InPlaceProgress } from "../styledComponents/progress";

export default function IdentityMonitoredEmails() {
  const _S = (str) => `settings.identity.${str}`;
  const [initDone, setInitDone] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const { assets, fetchingDashboard, fetchDashboardError } = useSelector(
    (state) => state.identity
  );
  const primaryEmail = useSelector((state) => state.auth.email);

  const dispatch = useDispatch();

  const tryFetchEmailList = useCallback(
    async (retryCount = 0) => {
      const { error } = await dispatch(fetchDwsDashboard());
      if (error) {
        setRetryCount(retryCount + 1);
        if (retryCount + 1 >= MAX_ERROR_RETRY) {
          navToErrorPage(error);
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!initDone) {
      tryFetchEmailList();
    }
    setInitDone(true);
  }, [initDone, tryFetchEmailList]);

  const onClickRetry = (e) => {
    tryFetchEmailList(retryCount);
  };

  const onAddNewEmail = async () => {
    const email = await dispatch(showPopup(POPUP_ADD_EMAIL_IDENTITY));
    if (email && email.length > 0) {
      dispatch(
        push({
          pathname: ROUTE_SCAN_EMAIL_RESULT,
          search: createQueryString({
            path: ROUTE_SETTINGS_IDENTITY,
            email,
          }),
        })
      );
    }
  };

  const tryFetchDwsDashboard = async (retryCount = 0) => {
    const response = await dispatch(
      fetchDwsDashboard({
        disabledCache: true,
        hit_trigger: "active_breach_list",
      })
    );

    if (response.error) {
      if (retryCount >= MAX_ERROR_RETRY) {
        navToErrorPage(response.error);
        return;
      }

      const retry = await dispatch(
        showToast(BANNER_NOTIFICATION, {
          stringId: "toasters.banner.somethingWrong",
          selfDestruct: false,
        })
      );

      if (retry) {
        tryFetchDwsDashboard(retryCount + 1);
      }
    }
  };

  const tryRemoveAsset = async (email, public_id, retryCount = 0) => {
    const response = await dispatch(
      removeAsset({ email: public_id, asset_value: email })
    );

    if (response.error) {
      if (retryCount >= MAX_ERROR_RETRY) {
        navToErrorPage(response.error);
        return;
      }

      const retry = await dispatch(
        showToast(BANNER_NOTIFICATION, {
          stringId: "toasters.banner.somethingWrong",
          selfDestruct: false,
        })
      );

      if (retry) {
        tryRemoveAsset(email, public_id, retryCount + 1);
      }

      return;
    }

    dispatch(
      showToast(TOAST_SNACKBAR_SUCCESS, {
        stringId: "toasters.removeEmail",
      })
    );

    tryFetchDwsDashboard();
  };

  const onDeleteEmailClick = async (email, public_id) => {
    if (
      await dispatch(
        showPopup(POPUP_REMOVE_EMAIL, {
          email,
        })
      )
    ) {
      tryRemoveAsset(email, public_id);
    }
  };

  const collectAnalytics = () => {
    collectScreenLoadEvent({
      [HIT_FEATURE]: ANALYTICS_ID_PROTECTION,
      [HIT_CATEGORY]: ANALYTICS_SETTING,
      [HIT_SCREEN]: SCREEN_SETTINGS_DELETE_EMAIL,
      [SCREEN_TYPE]: ANALYTICS_BOTTOM_SHEET,
      [SCREEN_FLOW]: "identity",
    });
  };

  if (fetchingDashboard && !assets) {
    return <InPlaceProgress infinite noPercentage />;
  }

  if (fetchDashboardError) {
    return (
      <ErrorMessage
        {...{ error: fetchDashboardError, onRetry: onClickRetry }}
      />
    );
  }

  return (
    <>
      <Label size="sm" className="identitySettings__subtitle">
        <String id={_S`monitoredEmails`} />
      </Label>
      <Paragraph size="sm" className="identitySettings__paragraph">
        <String id={_S`monitoredEmailsText`} />
      </Paragraph>
      <List noBottom className="identitySettings__assetList">
        {assets !== null &&
          assets.length > 0 &&
          assets.map(({ asset_value, public_id }, index) => (
            <ListItem
              className="identitySettings__assetItem"
              id={asset_value}
              key={asset_value}
            >
              <Icon
                icon="email-atlas-outline"
                className="text-gray-light identitySettings__icon"
              ></Icon>
              <Label
                size="sm"
                className="identitySettings__assetItem__email text-dark"
              >
                {asset_value}
              </Label>
              {/* TODO: remove this condition once mobile allow to remove the primary email */}
              {asset_value !== primaryEmail && (
                <Popover
                  onOpen={collectAnalytics}
                  position="bottom-right"
                  className="identitySettings__removeMenu"
                >
                  <PopoverHandler id={`RemoveEmailMenu${index}`}>
                    <MoreVertRounded className="identitySettings__assetItem__more" />
                  </PopoverHandler>
                  <PopoverContent className="identitySettings__removeMenu__container">
                    <PopoverItem
                      className="identitySettings__removeMenu__item"
                      onClick={() => onDeleteEmailClick(asset_value, public_id)}
                    >
                      <Label size="sm">
                        <String id={_S`removeEmail`} />
                      </Label>
                      <DeleteForeverOutlined className="identitySettings__removeMenu__icon" />
                    </PopoverItem>
                  </PopoverContent>
                </Popover>
              )}
            </ListItem>
          ))}
        <ListItem
          className="identitySettings__assetItem text-info"
          id="AddEmailButton"
          onClick={onAddNewEmail}
        >
          <Icon icon="add-atlas-outline" className="identitySettings__icon" />
          <Label
            size="sm"
            className="text-info identitySettings__assetItem__email"
          >
            <String id={_S`addEmail`} />
          </Label>
        </ListItem>
      </List>
    </>
  );
}
