/**
 * thunks to update state.context store
 */
import {
  fetchContextFailure,
  fetchContextSuccess,
  doneWelcomeBegin,
  doneWelcomeFailed,
  doneWelcomeSuccess,
  legalInfoBegin,
  legalInfoSuccess,
  legalInfoFailure,
  privacyInfoBegin,
  privacyInfoSuccess,
  privacyInfoFailure,
  setUwpConnected,
} from "../context";
import {
  isDevMode,
  getProperties,
  setProperties,
  stringifyJson,
  storeErrorDetails,
} from "../../utils";
import {
  uwpInvoke,
  uwpSubscribe,
  uwpUnsubscribe,
  uwpGetSecurityToken,
  getClientId,
  uwpOpenWindowsSettingPage,
  uwpLog,
  uwpSendTelemetry,
  uwpUpdateSharedContext,
  uwpPublish,
} from "../../uwp";
import {
  DEFAULT_LOCALE,
  DEFAULT_AFF_ID,
  API_SECURITY_MGMT,
  LS_DONE_WELCOME,
  LS_EULA_ACCEPTED,
  LS_AFF_ID,
  LS_DEVICE_GUID,
  LS_CLIENT_ID,
  DEFAULT_CLIENT_ID,
  LS_LOCALE,
  DEFAULT_PACKAGE_ID,
  DEFAULT_FLEX_PACKAGE_ID,
  HTTP_POST,
  MIN_API_LEVEL,
  API_EULA_PRIVACY,
  API_EULA_LEGAL,
  LS_DONE_TRIAL_PROMPT,
  LOCAL_IO_BUS_URL,
  LS_DONE_WSS_PROMPT,
} from "../../constants/main";
import { v4 as uuidv4 } from "uuid";
import { getServiceUrl } from "./settings";
import { cleanupCache, fetchJson } from "../../utils/http";
import { go, replace } from "connected-react-router";
import events from "../../experience/events";
import { publishEvent } from "./experience";

import { mapLocaleToFolder } from "../../l10n/loader";

export const invokeDoneWelcome = () => (dispatch) => {
  dispatch(doneWelcomeBegin());
  try {
    setProperties({ [LS_DONE_WELCOME]: true });
    dispatch(doneWelcomeSuccess());
  } catch (e) {
    dispatch(doneWelcomeFailed(e.message));
  }
};

/**
 * Collects root state data from the following sources:
 * - Saved props ( whether in UWP settings manager or localStorage )
 * - UWP invoke "system_info.get_state"
 * - Default hardcoded values
 * @returns state.context ( includes "error" prop if failure occurred )
 */
export const fetchContext = () => async (dispatch, getState) => {
  let oauthClientId = getState().experience.client,
    devMode = isDevMode();

  let {
    [LS_DONE_WELCOME]: doneWelcome = false,
    [LS_EULA_ACCEPTED]: eulaAccepted = false,
    [LS_DEVICE_GUID]: deviceGUID,
    [LS_CLIENT_ID]: cspid,
    [LS_AFF_ID]: aff_id = DEFAULT_AFF_ID,
    [LS_LOCALE]: locale = DEFAULT_LOCALE,
    [LS_DONE_TRIAL_PROMPT]: doneTrialPrompt = false,
    [LS_DONE_WSS_PROMPT]: doneWssPrompt = false,
  } = await getProperties([
    LS_DONE_WELCOME,
    LS_EULA_ACCEPTED,
    LS_DEVICE_GUID,
    LS_CLIENT_ID,
    LS_AFF_ID,
    LS_LOCALE,
    LS_DONE_TRIAL_PROMPT,
    LS_DONE_WSS_PROMPT
  ]);

  try {
    cleanupCache();

    const systemState = await uwpInvoke("system_info.get_state");
    // system_info.get_state sample response:
    // {
    //   "culture": "en-US",
    //   "uwp_version": "6666.1.1.0",
    //   "uwp_first_version": "2.0.4.0",
    //   "lockdown_mode": "false",
    //   "os_version": "10.0.19041.329",
    //   "machine_guid": "3bd95d65ebb7f96eb2bfe135fb952d4c",
    //   "manufacturer": "VMware, Inc.",
    //   "manufacturer_model": "VMware7,1",
    //   "device_name": "WYWIN10X64A",
    //   "os_architecture": "X64",
    //   "os_type": null,
    //   "timezone_bias_hours": "-500",
    //   "machine_id_type": "PublisherSpecificDeviceID",
    //   "affid": null,
    //   "factory_guid": null,
    //   "status": "success",
    //   "notification_setting": "Enabled"
    //   "api_version": 1
    // }

    let {
      machine_guid,
      affid: uwpAffid,
      uwp_version: uwpVersion = null,
      notification_setting,
      culture: uwpLocale,
      api_version: apiLevel = MIN_API_LEVEL,
    } = systemState;

    const notifications = notification_setting === "Enabled";

    const propsToSave = {};

    if (typeof uwpLocale === "string") {
      locale = propsToSave[LS_LOCALE] = uwpLocale.toLowerCase();
    }

    if (uwpAffid !== undefined && uwpAffid !== null) {
      aff_id = propsToSave[LS_AFF_ID] = uwpAffid;
    }

    if (deviceGUID !== undefined) {
      deviceGUID = propsToSave[LS_DEVICE_GUID] = machine_guid;
    }

    if (!deviceGUID) {
      deviceGUID = propsToSave[LS_DEVICE_GUID] = uuidv4();
    }

    if (!cspid) {
      const client_id = await getClientId(dispatch, deviceGUID);
      if (client_id) {
        cspid = propsToSave[LS_CLIENT_ID] = client_id;
      } else {
        cspid = DEFAULT_CLIENT_ID; //using hardcoded value for dev mode only
      }
    }

    if (Object.keys(propsToSave).length) {
      setProperties(propsToSave);
    }

    let mappedLocale = mapLocaleToFolder(locale).toLowerCase();

    dispatch(
      fetchContextSuccess({
        locale: mappedLocale,
        aff_id,
        deviceGUID,
        cspid,
        uwpVersion,
        notifications,

        oauthClientId,
        eulaAccepted,
        doneWelcome,
        devMode,
        apiLevel,
        doneTrialPrompt,
        doneWssPrompt
      })
    );
  } catch (e) {
    const error = storeErrorDetails(e);
    dispatch(
      fetchContextFailure({
        error,
        eulaAccepted,
        doneWelcome,
        cspid: DEFAULT_CLIENT_ID,
        aff_id,
        locale,
        oauthClientId,
        devMode,
        apiLevel: MIN_API_LEVEL,
        deviceGUID,
        doneTrialPrompt,
        doneWssPrompt
      })
    );
    return { ...getState().context, error, ok: false };
  }
  return getState().context;
};

/**
 * Fetches legal information
 * Returns hardcoded values (with additional error attribute) if failed
 * @param {*} param0
 * @returns {legalUrl, eulaVersion, ok?, error?}
 */
export const fetchLegalInfo =
  ({
    pkg_id = DEFAULT_PACKAGE_ID,
    flexpkg_id = DEFAULT_FLEX_PACKAGE_ID,
  } = {}) =>
  async (dispatch, getState) => {
    dispatch(legalInfoBegin());

    try {
      const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT));
      const { locale: culture, aff_id } = getState().context;

      const legalContentApi = `${apiBaseUrl}/eula/v1`;

      const fetchOptions = {
        method: HTTP_POST,
        headers: {},
      };

      const fetchBody = {
        aff_id,
        culture,
        pkg_id,
        flexpkg_id,
        functionality: "Registration",
        legal_types: [
          {
            legal_type: "EULA",
            req_type: "NOCONTENT",
          },
        ],
      };

      fetchOptions.body = stringifyJson(fetchBody);

      const { data: eulaResults } = await fetchJson(
        legalContentApi,
        fetchOptions
      );

      const returnVal = {
        legalUrl: eulaResults[0].legal_response.legal_url[0].value,
        eulaVersion: eulaResults[0].legal_response.legal_version,
      };

      dispatch(legalInfoSuccess(returnVal));

      return { ok: true, ...returnVal };
    } catch (e) {
      const error = storeErrorDetails(e);
      const defaultReturnVal = {
        legalUrl: API_EULA_LEGAL,
        eulaVersion: "EULA_v8",
      };

      dispatch(
        legalInfoFailure({
          error,
          ...defaultReturnVal,
        })
      );

      return {
        ok: false,
        error,
        ...defaultReturnVal,
      };
    }
  };

/**
 * Fetches privacy legal information
 * Returns hardcoded values (with additional error attribute) if failed
 * @param {{pks_id,flexpkg_id}} options
 * @returns {{privacyUrl,privacyVersion,ok?,error?}}
 */
export const fetchPrivacyInfo =
  ({ pkg_id = "0", flexpkg_id = "" } = {}) =>
  async (dispatch, getState) => {
    dispatch(privacyInfoBegin());
    try {
      const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT));
      const { locale: culture, aff_id } = getState().context;
      const legalContentApi = `${apiBaseUrl}/eula/v1`;
      const fetchOptions = {
        method: HTTP_POST,
        headers: {},
      };
      const fetchBody = {
        aff_id,
        culture,
        pkg_id,
        flexpkg_id,
        functionality: "Registration",
        legal_types: [
          {
            legal_type: "PRIVACYPOLICY",
            req_type: "NOCONTENT",
          },
        ],
      };

      fetchOptions.body = stringifyJson(fetchBody);

      const { data: privacyResults } = await fetchJson(
        legalContentApi,
        fetchOptions
      );

      const returnVal = {
        privacyUrl: privacyResults[0].legal_response.legal_url[0].value,
        privacyVersion: privacyResults[0].legal_response.legal_version,
      };
      dispatch(privacyInfoSuccess(returnVal));
      return returnVal;
    } catch (e) {
      const error = storeErrorDetails(e);

      const defaultReturnVal = {
        privacyUrl: API_EULA_PRIVACY,
        privacyVersion: "Default",
      };

      dispatch(
        privacyInfoFailure({
          error,
          ...defaultReturnVal,
        })
      );
      return { ok: false, error, ...defaultReturnVal };
    }
  };

export const connectUWP = () => async (dispatch) => {
  const isDev = isDevMode();
  const { communicator, MsgBus } = window;

  dispatch(
    setUwpConnected({ uwp: communicator ? true : false, socket: false })
  );

  if (isDev) {
    const skipUwp = localStorage.getItem("skipUwp") !== "false";
    const client_id = "client_" + Math.floor(Math.random() * 100000).toString();

    if (communicator) {
      //Connect as a proxy

      const ioBus = (window.ioBus = MsgBus(
        client_id,
        (connected) => {
          dispatch(setUwpConnected({ uwp: true, socket: connected }));
          if (!connected) {
            return;
          }
          ioBus.addRequestHandler("test_connection", () => {
            return { success: true };
          });
          ioBus.addRequestHandler("uwpInvoke", (params, from) => {
            let requestTimeout = 10000;
            if (params.id === "vpn.login") {
              requestTimeout = 60000;
            }
            uwpLog(
              `~uwpInvoke(${stringifyJson(
                params
              )})@remote received from ${from}`
            );
            return uwpInvoke(params.id, params.params, requestTimeout);
          });
          ioBus.on("uwpLog", (params) => {
            const { type, msg } = params.data;
            uwpLog(msg, type);
          });
          ioBus.on("uwpPublish", (payload) => {
            const { msg, params } = payload.data;
            uwpPublish(msg, params);
          });
          ioBus.addRequestHandler("uwpSubscribe", async (params, from) => {
            uwpLog(`~uwpSubscribe(${params.id})@remote received from ${from}`);
            const ret = await uwpSubscribe(params.id, (msg) => {
              uwpLog(
                `~uwpSubscribeMsg(${params.id},${stringifyJson(
                  msg
                )})->remote sent to ${from}`
              );
              ioBus.publish(params.id, msg, from); //publish to subscriber
            });
            return ret; //return id for unsubscribe
          });
          ioBus.addRequestHandler("uwpGetSecurityToken", async (params) => {
            const { clientId, body } = params;
            uwpLog(`uwpGetSecurityToken(clientId)@remote received`);
            const securityToken = await uwpGetSecurityToken(clientId, body);
            return { securityToken };
          });
          ioBus.on("uwpOpenWindowsSettingPage", (params) => {
            const { settingsId } = params.data;
            uwpLog(
              `uwpOpenWindowsSettingPage@remote received ${stringifyJson(
                params.data
              )}`
            );
            uwpOpenWindowsSettingPage(settingsId);
          });
          ioBus.on("uwpSendTelemetry", (payload) => {
            const { eventName, jsonValueList, dynamicContextValue } =
              payload.data;
            uwpLog(
              `uwpSendTelemetry@remote received ${stringifyJson(payload.data)}`
            );
            uwpSendTelemetry(
              eventName,
              JSON.parse(jsonValueList),
              dynamicContextValue
            );
          });

          ioBus.on("uwpUpdateSharedContext", (payload) => {
            const { valueList } = payload.data;
            uwpLog(
              `uwpUpdateSharedContext@remote received ${stringifyJson(
                payload.data
              )}`
            );
            uwpUpdateSharedContext(valueList);
          });
          ioBus.on("uwpUnsubscribe", async (payload) => {
            uwpLog(
              `uwpUnsubscribe@remote received ${stringifyJson(payload.data)}`
            );
            uwpUnsubscribe(payload.data.id);
          });
        },
        LOCAL_IO_BUS_URL
      ));
    } else if (skipUwp) {
      dispatch(setUwpConnected({ uwp: true, socket: false }));
    } else if (MsgBus) {
      //connect as a client
      window.ioBus = MsgBus(
        client_id,
        (connected) => {
          if (connected) {
            dispatch(setUwpConnected({ uwp: false, socket: true }));
          } else {
            dispatch(setUwpConnected({ uwp: false, socket: false }));
          }
        },
        LOCAL_IO_BUS_URL // configure uwp app to connect to localhost
        //process.env.REACT_APP_IO_BUS_URL
      );
    }
  }
};
/**
 * Navigate back in history
 * Set the depth to give it higher priority than the "back" value in the query string
 * If "back" is not set, nor history has enough depth for the required step, it would publish an event
 * requesting to go to the home screen
 * @param {number} depth backward steps, when set it gets higher priority if history has enough depth
 * @returns
 */
export const navigateBack =
  ({ steps = 0, defaultRoute } = {}) =>
  (dispatch, getState) => {
    const {
      router: {
        location: { query },
      },
      context: { historyIndex },
    } = getState();

    if (steps && historyIndex >= steps) {
      dispatch(go(-steps)); //Highest priority if depth is set
    } else if (query.back) {
      dispatch(replace(decodeURIComponent(query.back)));
    } else if (steps === 0 && historyIndex > 0) {
      dispatch(go(-1)); //steps not set, history can go back one step
    } else if (defaultRoute !== undefined) {
      dispatch(replace(defaultRoute));
    } else {
      dispatch(publishEvent(events.root.home)); //last resort, going back to home screen
    }
  };
