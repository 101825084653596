import { createSlice } from "@reduxjs/toolkit";
import { SECONDS_IN_A_DAY } from "../constants";
import { signOutSuccess } from "./auth";
import { fetchPDBegin, fetchPDFailure, fetchPDSuccess } from "./experience";

// possible api values for subscription:
// paid-active;
// paid-expired;
// trial-active;
// trial-expired;
// extend-expiry-active;
// extend-expiry-expired;
// free

const initialState = {
  progress: false,
  error: null,
  subscriptionStatus: null, // this value comes from the API, check comment above to see possibilities;
  subscriptionLength: null, // this value is the original duration of the subscription
  doneFetching: false,
  expiryDate: null,
  daysToExpiry: null,
  renewUrl: null,
};

const slice = createSlice({
  name: "@subscription",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPDBegin]: (state) => {
      state.progress = true;
      state.doneFetching = false;
      state.error = null;
    },
    [fetchPDSuccess]: (state, action) => {
      const {
        sub_status,
        exp_date,
        sub_length,
        time_to_expiry_in_seconds,
        renew_url,
        cached = false,
      } = action.payload;

      const _secondsToDays = (seconds) => Math.round(seconds / (3600 * 24));

      state.progress = false;
      state.expiryDate = exp_date;
      state.subscriptionLength = _secondsToDays(sub_length);

      const numberOfDaysToEnd = _secondsToDays(time_to_expiry_in_seconds);

      state.daysToExpiry =
        numberOfDaysToEnd === 0 &&
        time_to_expiry_in_seconds > 0 &&
        time_to_expiry_in_seconds <= SECONDS_IN_A_DAY /// day constant
          ? 1 // for the edge case where the amount of seconds left are smaller than a day
          : numberOfDaysToEnd;
      state.renewUrl = renew_url;

      state.subscriptionStatus = sub_status.toLowerCase();

      if (!cached) {
        state.doneFetching = true;
      }
    },
    [fetchPDFailure]: (state, action) => {
      state.progress = false;
      state.error = action.payload;
    },
    [signOutSuccess]: (state) => {
      return initialState;
    },
  },
});

export default slice.reducer;
