import React, { useState, useEffect, useRef } from "react";
import { Loader } from "../_generic/loader/Loader";
import "./AddDevice.scss";
import { useDispatch, useSelector } from "react-redux";
import { addDevice } from "../../store/thunks/device";
import DashboardCard from "../DashboardCard/DashboardCard";

import { Paragraph, Button, Input } from "@mcafee/pegasus";

//TODO: unused
export default function AddDevice({ asset }) {
  const [deviceEnrolled, setEnrolled] = useState(true);
  const dispatch = useDispatch();

  const {
    adding,
    list: deviceList,
    info: deviceInfo,
    fetching: fetchingDeviceList,
  } = useSelector((state) => state.devices);

  const deviceNameRef = useRef();

  const handleSubmit = () => {
    const name = deviceNameRef.current.value;
    dispatch(
      addDevice({
        ...deviceInfo,
        name,
      })
    );
  };

  useEffect(() => {
    if (deviceList) {
      setEnrolled(deviceList.find((d) => d.id === deviceInfo.id));
    }
  }, [deviceList, deviceInfo.id]);

  if (deviceEnrolled || (deviceList && deviceList.fetching)) {
    return null;
  }

  const [header, paragraph, tag] = [
    "Lets get you set up",
    "It looks like you current device isn't authorized, let's set it up.",
    "RECOMMENDED",
  ];

  return (
    <DashboardCard {...{ header, paragraph, tag, asset }}>
      <Paragraph size="sm">
        Device: {deviceInfo.osName}, Browser: {deviceInfo.browser}
      </Paragraph>
      {adding || fetchingDeviceList ? (
        <Loader />
      ) : (
        <>
          <div className="mb-16">
            <Input
              icon="laptop-mobile-outline"
              ref={deviceNameRef}
              label="Device Name"
              id="AddDeviceInput"
            />
          </div>
          <Button
            className="mr-8"
            size="sm"
            onClick={handleSubmit}
            id="AddDevice"
          >
            Add Device
          </Button>
        </>
      )}
    </DashboardCard>
  );
}
