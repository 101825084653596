import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { String } from "../../l10n/loader";

// DOM
import {
  Button,
  Input,
  Paragraph,
  Heading,
  Label,
  Link,
} from "@mcafee/pegasus";
import AddDevice from "../../components/_assets/images/popUps/devices.svg";

// Store
import { emailKeyCard } from "../../store/thunks/dashboard";
import {
  EMAIL_MAX_LENGTH,
  EMAIL_REGEX,
  MAX_ERROR_RETRY,
} from "../../constants/main";
import { POPUP_FAILURE } from "./constants";
import { showPopup } from "../../store/thunks/popups";
import { InPlaceProgress } from "../styledComponents/progress";
import { POPUP_SUCCESS } from "../PopUps/constants";
import { navToErrorPage, useLegalAndPrivacyInfo } from "../../utils";

const ProtectEmailDevicePopUp = ({
  emailKeyCardProgress,
  emailKeyCard,
  onFinish,
  onCancel,
  primaryEmail,
}) => {
  const _S = (str) => `popups.${str}`;
  const [disableState, setDisableState] = useState(true);
  const [inputKind, setInputKind] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState(primaryEmail);
  const { privacyUrl } = useLegalAndPrivacyInfo();
  const emailInput = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (emailInput.current) {
      emailInput.current.focus();
    }
    setDisableState(false);
    setErrorMsg("");
    setInputKind("success");
  }, []);

  /* For each input check if its Digits only and also verify its length
     Disable the 'Send Link' button incase the alphanumeric or not 10 digits
  */
  const handleChange = (e) => {
    const emailAddr = e.target.value;
    setEmail(emailAddr);

    if (!(EMAIL_REGEX.test(emailAddr) || emailAddr === "")) {
      setInputKind("danger");
      setErrorMsg(<String id={_S`emailError`} />);
      setDisableState(true);
    } else if (emailAddr.indexOf("@") !== -1) {
      setInputKind("success");
      setDisableState(false);
      setErrorMsg("");
    } else {
      setInputKind("");
      setDisableState(true);
      setErrorMsg("");
    }
  };

  const tryEmailKeyCard = async (retryCount) => {
    const { ok, error } = await emailKeyCard(email);
    if (ok) {
      onFinish(ok);
      await dispatch(showPopup(POPUP_SUCCESS, { trigger: "dashboard_card" }));
    } else {
      if (retryCount < MAX_ERROR_RETRY) {
        //Show error retry popup
        if (
          await dispatch(
            showPopup(POPUP_FAILURE, {
              message: "addFailurePopUp",
            })
          )
        ) {
          //Retry requested
          tryEmailKeyCard(retryCount + 1);
        }
      } else {
        //Close popup and show error page
        onCancel();
        navToErrorPage(error);
      }
    }
  };

  // Request for the link
  const handleSendLink = async (e) => {
    tryEmailKeyCard(0);
  };

  const onPrivacyNoticeClick = () => {
    window.open(privacyUrl, "_blank");
  };

  const styles = {
    popUpStyles: {
      maxWidth: 450,
    },
    logoStyles: {
      float: "center",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      height: 160,
    },
    contentStyles: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    errorStyles: {
      textAlign: "center",
    },
  };

  return (
    <div style={styles.popUpStyles} className="pa-32">
      <img style={styles.logoStyles} src={AddDevice} alt="" />

      <Heading
        className="mt-24 "
        size="xs"
        data-testid="protectEmailDeviceHeader"
      >
        <String id={_S`addEmailDevicePopUp.header`} />
      </Heading>

      <Paragraph
        className="mt-8"
        size="sm"
        data-testid="protectEmailDeviceContent"
      >
        <String id={_S`addEmailDevicePopUp.content`} />
        <Link onClick={onPrivacyNoticeClick}>
          <String id={_S`addEmailDevicePopUp.privacyNotice`} />
        </Link>
      </Paragraph>
      {emailKeyCardProgress ? (
        <InPlaceProgress infinite noPercentage />
      ) : (
        <>
          <div
            className="wrap"
            style={styles.contentStyles}
            data-testid="protectEmailDeviceInput"
          >
            <Input
              className="mt-24"
              icon="email-outline"
              label={<String id={_S`emailAddress`} />}
              ref={emailInput}
              kind={inputKind}
              onChange={handleChange}
              id="AddEmailDeviceInput"
              value={email}
              maxLength={EMAIL_MAX_LENGTH}
            ></Input>
          </div>
          <div style={styles.errorStyles}>
            {errorMsg && (
              <Label size="sm" className="text-danger mt-4">
                {errorMsg}
              </Label>
            )}
          </div>
          <div className="mt-24 flex justify-content-end">
            <Button
              onClick={handleSendLink}
              disabled={disableState}
              id="AddEmailDevicePopupSendLink"
              data-testid="protectEmailDeviceSendLinkButton"
              size="sm"
            >
              <String id={_S`emailLink`} />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    ...state.dashboard,
    ...state.router.location,
    primaryEmail: state.auth.email,
  }),
  { emailKeyCard }
)(ProtectEmailDevicePopUp);
