import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Paragraph, Button, Heading, Link } from "@mcafee/pegasus";
import errorSetup from "../../components/_assets/images/vpnSetup/vpnError.svg";

export default function VpnSetupFailureMessage({
  localePrefix,
  error,
  onGotIt,
}) {
  /* ToDo: HAve to handle the Support link click behavior */
  const onSupportClick = () => {
    console.log("Support link clicked");
  };
  return (
    <div className="centerContent">
      <div className="slider__container">
        <img src={errorSetup} alt="ErrorSetup_Image" style={{ height: 264 }} />
      </div>

      <Heading className="mb-16">
        <String id={`${localePrefix}setupFailure.header`} />
      </Heading>

      <Paragraph size="md" className="mb-16">
        <String id={`${localePrefix}setupFailure.content_part1`} />
      </Paragraph>
      <Paragraph size="md">
        <String
          id={`${localePrefix}setupFailure.content_part2`}
          values={{
            support: (
              <Link onClick={onSupportClick} id="VpnSetupFailureSupport">
                <String id={`${localePrefix}support`} />
              </Link>
            ),
          }}
        />
      </Paragraph>
      {error && (
        <Paragraph size="md" className="text-secondary" data-testid='VpnSetupError'>
          <strong>{`${error.source}_${error.code}`}</strong>
        </Paragraph>
      )}
      <div className="mt-40  mb-32">
        {onGotIt && (
          <Button onClick={onGotIt} id="VpnSetupFailureGotIt" size="sm" data-testid='VpnSetupOnGotIt'>
            <String id={`${localePrefix}gotIt`} />
          </Button>
        )}
      </div>
    </div>
  );
}
