import {
  createQueryString,
  dispatchDomEvent,
  fetchJson,
  fetchJsonCacheFirst,
  isDevMode,
  isSubscriptionExpired,
  storeErrorDetails,
  setProperties,
  isNullOrUndefined,
  urlToSource,
  isUndefined,
} from "../../utils";
import {
  fetchExperienceBegin,
  fetchExperienceFailure,
  fetchExperienceSuccess,
  publishEventFailure,
  doActionFailure,
  fetchPDBegin,
  fetchPDFailure,
  fetchPDSuccess,
  doActionPrexEpiryPopup,
} from "../experience";

import actions from "../../experience/actions";
import { goBack, push, replace } from "connected-react-router";
import {
  ROUTE_ACCOUNT,
  ROUTE_DASHBOARD,
  ROUTE_SCAN_EMAIL,
  ROUTE_SETTINGS_ABOUT,
  ROUTE_SETTINGS_NOTIFICATIONS,
  ROUTE_SETTINGS_VPN,
  ROUTE_WELCOME1,
  ROUTE_WELCOME2,
  ROUTE_WELCOME3,
} from "../../components/app/routes";
import { invokeDoneWelcome } from "./app";
import { acceptEulaAndPrivacyNotice } from "./eula";
import { openPrivacy, openLegal, openSurvey } from "./urlActions";
import { signOut, refreshAccessToken } from "./auth";
import { openUrl, saveRemoteConfigVars } from "../context";
import { uwpUpdateSharedContext } from "../../uwp";
import { POPUP_OTP, POPUP_PRE_EXPIRY } from "../../components/PopUps/constants";
import { scanEmailReset } from "../dws";
import { addNewAsset, fetchDwsDashboard } from "./identity";
import {
  API_SECURITY_MGMT,
  APP_ID,
  FEATURE_IDENTITY,
  FEATURE_VPN,
  DEFAULT_LOCALE,
  LS_CARRIER_ID,
  LS_PRODUCT_ID,
  LS_SPLIT_PRODUCT_ID,
  LS_PRODUCT_DEFINITION,
  LS_REMOTE_CONFIG,
  LS_LAST_SUB_SYNC_SERVER_TIMESTAMP,
  HTTP_GET,
  DEFAULT_AFF_ID,
  CACHE_NO_STORE,
  CHECK_PRODUCT_DEF_INTERVAL,
  SYNC,
  FEATURE_AV,
} from "../../constants/main";
import { getServiceUrl } from "./settings";
import { dashboardShowCard } from "../dashboard";
import { showPopup } from "./popups";
import { SCREEN_DWS_SCAN_VERIFY_EMAIL } from "../../constants/analyticsScreens";
import events from "../../experience/events";

import { isTrialActive, inPreExpiryState } from "../../utils";
import { uwpPublish, uwpSubscribe } from "../../uwp";

function onSubscribe(event, payload, subscribeEvent, timeout, onTimeout) {
  setTimeout(() => {
    console.log(" onSubscribe publish ", event);

    uwpPublish(event, payload);
  }, 100);

  return new Promise((resolve) => {
    setTimeout(() => {
      console.log(" onSubscribe timeout ");
      resolve(onTimeout);
    }, 100 + timeout);

    uwpSubscribe(subscribeEvent, (response) => {
      console.log(" onSubscribe response ", response);
      resolve(response);
    });
  });
}

/**
 * Fetch product details, like subscription and which features are enabled, and experience settings
 * Updates state.experience data and returns subscription info
 * @param {string|null} [fetchingOrigin] parameter created for analytics purpose, to identify what is triggering this call - possible values are: "sync", null;
 * null is used once the user signs in, while sync is used when fetching the latest subscription status
 * @returns void
 */
export const fetchProductFeatures =
  ({ fetchingOrigin = null } = {}) =>
  async (dispatch, getState) => {
    const {
      context: { aff_id = DEFAULT_AFF_ID, uwpVersion },
    } = getState();

    const now = new Date();

    const setError = (error) => {
      dispatch(fetchPDFailure({ ...error, fetchingOrigin }));
      dispatch(fetchExperienceFailure(error));
    };

    dispatch(fetchPDBegin({ fetchingOrigin }));
    dispatch(fetchExperienceBegin());

    try {
      const apiBaseUrl = await dispatch(getServiceUrl(API_SECURITY_MGMT));

      const queryString = createQueryString({
        aff_id,
        locale: DEFAULT_LOCALE,
        device_type: "PC",
        device_os: "Windows",
        policy_id: "",
        policy_version: "",
        branding_id: "",
        product_def_code: "",
        product_version: uwpVersion || "1.0",
      });

      const apiPath = `${apiBaseUrl}/features/v1/product-features/${APP_ID}?${queryString}`;
      const fetchingCacheFirst = await fetchJsonCacheFirst(apiPath, {
        method: HTTP_GET,
        headers: {
          Authorization: () => dispatch(refreshAccessToken()),
        },
      });

      const handleExpiry = ({ sub_status }) => {
        const isExpired = isSubscriptionExpired(
          getState().subscription.subscriptionStatus
        );

        if (isExpired !== true && isSubscriptionExpired(sub_status)) {
          dispatch(publishEvent(events.subscription.expired));
        }
      };

      const handleResponse = async (resp) => {
        //eslint-disable-next-line no-mixed-operators
        const value = (resp && resp.value) || null;
        //eslint-disable-next-line no-mixed-operators
        const status = (value && value.status) || "";
        if (!value) {
          setError({
            status,
            message: "Missing value in fetchProductFeatures response.",
            source: urlToSource(apiPath),
          });
          return;
        }
        const data = value.data;
        const cached = !!value.cached;
        if (!data) {
          setError({
            status,
            message: "Missing data in fetchProductFeatures response.",
            source: urlToSource(apiPath),
          });
          return;
        }

        const product_settings = data.product_settings || {};
        const { sc_carrier_id, sc_product_id, split_product_id } =
          product_settings;
        const {
          sub_status,
          sub_length,
          renew_url,
          time_to_expiry_in_seconds,
          exp_date,
        } = data.subscription_info || {};
        const { pkgId, packageName } = data.package_info || {};
        const event_action_map_url =
          product_settings.event_action_map_url || "";
        const featuresFromResp = data.features || [];

        const keyMap = {
          secure_vpn: FEATURE_VPN,
        };
        var features = featuresFromResp.map((f) => {
          const keyVal = f.name.toLowerCase();
          const key = keyMap[keyVal] || keyVal;
          f.name = key;
          return f;
        });

        if (
          [
            sub_status,
            pkgId,
            time_to_expiry_in_seconds,
            exp_date,
            sub_length,
          ].some(isNullOrUndefined)
        ) {
          setError({
            status,
            message: "Invalid response",
            source: urlToSource(apiPath),
          });
          return;
        }

        //features -> features_sku for uwp
        const features_sku = features.map((f) => {
          f.enable = isUndefined(f.enable) ? true : f.enable;
          return f;
        });
        const UWPProperties = {
          //outer LS_PRODUCT_DEFINITION is setting name, inner is expected on setting object
          [LS_PRODUCT_DEFINITION]: {
            [LS_PRODUCT_DEFINITION]: {
              renew_url,
              sub_status,
              sub_length,
              time_to_expiry_in_seconds,
              exp_date,
              features_sku,
            },
          },
          [LS_REMOTE_CONFIG]: {}, //TODO
          [LS_LAST_SUB_SYNC_SERVER_TIMESTAMP]: now.toISOString(),
          [LS_CARRIER_ID]: sc_carrier_id,
          [LS_PRODUCT_ID]: sc_product_id,
          [LS_SPLIT_PRODUCT_ID]: split_product_id,
        };

        if (!cached) {
          setProperties(UWPProperties);
        }

        //check if subscription expired
        handleExpiry({ sub_status });

        //set context store
        dispatch(
          saveRemoteConfigVars({
            pkgId,
            packageName,
            sc_carrier_id,
            sc_product_id,
            split_product_id,
          })
        );

        var av_feature = await onSubscribe(
          "nativeprotection.getinstallstatus",
          { protection_type: "antivirus" },
          "nativeprotection.installstatuschange",
          1000,
          { status: "timeout" }
        );
        // const av_status = features.some(
        //   (e) => e.name === FEATURE_AV && e.enable === true
        // );
        //show av feature regardless of backend response if av is installed
        if (av_feature) {
          features.push({
            name: FEATURE_AV,
            enabled: av_feature.status === "installed",
          });
        }
        // configure native features
        dispatch(
          fetchPDSuccess({
            features,
            pkgId,
            last_check: now.getTime(), //to store into state.experience.lastFetchProductDefinition
            fetchingOrigin, //For analytics middleware to capture this value
            //below needed for @subscription slice reducers
            sub_status,
            exp_date,
            sub_length,
            time_to_expiry_in_seconds,
            renew_url,
            cached,
          })
        );

        const matchExpJson = event_action_map_url.match(/\/([^/]+)\.json$/);

        //if event_action_map_url is set on response and not 'defaultExperience'
        //then fetch experience json
        if (matchExpJson && matchExpJson[1] !== "defaultExperience") {
          const { data: experience } = await fetchJson(
            `/config/${matchExpJson[1]}.json`,
            {
              method: HTTP_GET,
              cache: CACHE_NO_STORE,
              headers: {
                Authorization: () => dispatch(refreshAccessToken()),
              },
            }
          );

          //set experience store
          dispatch(fetchExperienceSuccess(experience));

          const analyticsSharedContext = product_settings;

          //temp:Convert boolean strings to lower case values
          Object.keys(analyticsSharedContext).forEach((key) => {
            if (
              typeof analyticsSharedContext[key] === "string" &&
              analyticsSharedContext[key].match(/^(True|False)$/)
            ) {
              analyticsSharedContext[key] =
                analyticsSharedContext[key].toLowerCase();
            }
          });

          delete analyticsSharedContext.event_action_map_url;
          delete analyticsSharedContext.sc_carrier_id;
          delete analyticsSharedContext.sc_product_id;
          uwpUpdateSharedContext(analyticsSharedContext);
        } else {
          dispatch(fetchExperienceSuccess());
        }
      };

      const cachedResp = await fetchingCacheFirst.next();
      if (fetchingOrigin !== SYNC) {
        //cached
        await handleResponse(cachedResp);
      }
      const liveResp = await fetchingCacheFirst.next();
      await handleResponse(liveResp);
      return liveResp && liveResp.value;
    } catch (e) {
      //Offline, network error or malformed response
      const error = storeErrorDetails(e);
      setError(error);
      return { ok: false, error };
    }
  };

export const publishEvent = (id, payload) => async (dispatch, getState) => {
  dispatchDomEvent(id, payload);
  try {
    const eventActions = getState().experience.event_actions[id];
    if (Array.isArray(eventActions)) {
      for (let i = 0; i < eventActions.length; i++) {
        const result = await dispatch(doAction(eventActions[i], payload));
        if (!result) {
          //Don't move to next action
          const e = { error: `Failed action ${id}` };
          throw e;
        }
      }
      // dispatch(publishEventSuccess({ id, payload }));
      return true; //handled action
    }
    dispatch(publishEventFailure({ id, payload }));
  } catch (e) {
    dispatch(publishEventFailure(e));
  }
  return false;
};

export const doAction = (id, payload) => async (dispatch, getState) => {
  try {
    const matchIf = id.match(/^\$(if|showIf)\/(.+)/);
    if (matchIf) {
      switch (matchIf[2]) {
        case "dev":
          return isDevMode();
        default:
          return false;
      }
    }

    //Find the page navigation actions
    const matchNav = id.match(actions.go.regex);
    const features = getState().experience.features;

    if (matchNav) {
      const pageInfo = mapPageId2Route(matchNav[2]);
      if (pageInfo) {
        const { feature } = pageInfo;
        const nav = matchNav[1] === "go" ? push : replace;

        if (feature) {
          if (!features[feature] || !features[feature].enabled) {
            //feature not supported, navigate to fallback page
            dispatch(nav(pageInfo.fallbackRoute));
            // dispatch(doActionSuccess({ id, payload }));
            return true;
          }
        }
        dispatch(nav({ pathname: pageInfo.route, search: pageInfo.query }));
        // dispatch(doActionSuccess({ id, payload }));
        return true;
      }
      dispatch(doActionFailure({ id, payload }));
      return false;
    }

    const matchUrl = id.match(/^(http|https):\/\/.+/);
    if (matchUrl) {
      dispatch(openUrl(id));
      window.open(id, "_blank");
      // dispatch(doActionSuccess({ id, payload }));
      return true;
    }

    const matchPopup = id.match(/^\$popup\/(.+)/);
    if (matchPopup) {
      const popup = matchPopup[1];
      await dispatch(showPopup(popup));
    }

    //match other actions
    switch (id) {
      case actions.setCardVisibility:
        dispatch(dashboardShowCard(payload));
        break;
      case actions.back:
        const {
          router: {
            location: {
              query: { back },
            },
          },
          context: { historyIndex },
        } = getState();
        if (back) {
          dispatch(replace(decodeURIComponent(back)));
        } else if (historyIndex > 0) {
          dispatch(goBack());
        } else {
          dispatch(publishEvent(events.root.home));
        }
        break;
      case actions.checkSignedIn:
        if (!getState().auth.accessToken) {
          dispatch(push(ROUTE_ACCOUNT));
          return false;
        }
        return true;
      case actions.open.privacy:
        dispatch(openPrivacy());
        break;
      case actions.open.legal:
        dispatch(openLegal());
        break;
      case actions.open.survey:
        dispatch(openSurvey());
        break;
      case actions.doneWelcome:
        dispatch(invokeDoneWelcome());
        break;
      case actions.trackAcceptEula:
        dispatch(acceptEulaAndPrivacyNotice());
        break;
      case actions.call.account:
        if (getState().auth.accessToken) {
          dispatch(signOut());
        } else {
          dispatch(push(ROUTE_ACCOUNT));
        }
        break;
      case actions.call.dwsResetEmail:
        dispatch(scanEmailReset());
        break;
      case actions.call.idsMonitorEmail: //TODO: unreferenced
        const otpResult = await dispatch(
          showPopup(POPUP_OTP, {
            newEmail: payload,
            screen: SCREEN_DWS_SCAN_VERIFY_EMAIL,
            trigger: "monitor_new_email",
            details: "verify_email_default",
          })
        );
        if (!otpResult) {
          return false;
        }
        const { otpToken, transaction_id } = otpResult;
        if (otpToken) {
          dispatch(addNewAsset({ otpToken, email: payload, transaction_id }));
          return true;
        }
        return false;

      case actions.call.isValidSubscription:
        var { subscriptionStatus } = getState().subscription;

        return !isSubscriptionExpired(subscriptionStatus);

      case actions.call.identityEnrollOrDashboard:
        var primaryEmailMonitored = false;
        var assets = getState().identity.assets;
        if (!assets) {
          await dispatch(fetchDwsDashboard());
          assets = getState().identity.assets;
        }
        primaryEmailMonitored =
          Array.isArray(assets) &&
          assets.some(
            (asset) => asset.asset_value === getState().auth.email //check if primary email is already monitored
          );
        primaryEmailMonitored
          ? dispatch(replace(ROUTE_DASHBOARD))
          : dispatch(replace(ROUTE_SCAN_EMAIL));

        break;
      case actions.call.updateRemoteConfig:
        if (!getState().auth.session) {
          // logged out
          return true;
        }
        // get last check epoch time in ms:
        const last_time = getState().experience.lastFetchProductDefinition;
        let diff = CHECK_PRODUCT_DEF_INTERVAL;
        if (last_time) {
          diff = Date.now() - last_time;
        }

        if (diff < CHECK_PRODUCT_DEF_INTERVAL) {
          return true;
        }

        await dispatch(fetchProductFeatures());

        break;

      case actions.call.checkShowPreExpiryPopup:
        const {
          experience: { lastPreExpiryPopupTime },
          subscription: {
            subscriptionStatus: sub_status,
            renewUrl,
            daysToExpiry,
          },
        } = getState();

        if (
          isTrialActive(sub_status) &&
          inPreExpiryState(daysToExpiry, lastPreExpiryPopupTime)
        ) {
          dispatch(showPreExpiryPopup(renewUrl, daysToExpiry));
        }
        break;
      default:
        dispatch({ type: id }); //generic action, addEventListener can handle it
        break;
    }

    // dispatch(doActionSuccess({ id, payload }));
    return true;
  } catch (e) {
    return false;
  }
};

const showPreExpiryPopup = (renewUrl, daysToExpiry) => async (dispatch) => {
  dispatch(
    showPopup(
      POPUP_PRE_EXPIRY,
      { renewUrl: renewUrl, daysToExpiry: daysToExpiry },
      "",
      { overflow: "visible" }
    )
  );
  dispatch(doActionPrexEpiryPopup({ lastPreExpiryPopupTime: Date.now() }));
};

export const mapPageId2Route = (pageId) => {
  const map = {
    welcome1: { route: ROUTE_WELCOME1 },
    welcome2: { route: ROUTE_WELCOME2 },
    welcome3: { route: ROUTE_WELCOME3 },
    onBoardingScan: {
      route: ROUTE_SCAN_EMAIL,
      feature: FEATURE_IDENTITY,
      fallbackRoute: ROUTE_DASHBOARD, //fallback page. used if feature is not supported
    },
    settings: { route: ROUTE_SETTINGS_ABOUT },
    vpn_settings: {
      route: ROUTE_SETTINGS_VPN,
      feature: FEATURE_VPN,
      fallbackRoute: ROUTE_SETTINGS_ABOUT,
    },
    notifications_settings: { route: ROUTE_SETTINGS_NOTIFICATIONS },
  };

  if (pageId) {
    const ret = map[pageId] || {
      route: `/${pageId}`,
    };

    return ret;
  }
  //If no parameters passed, return the map
  return map;
};
