//ROUTE_IDENTITY_NEW_EMAIL page
//TODO: move it to /src/pages/ScanEmailResult
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { replace } from "connected-react-router";
import {
  ROUTE_MONITOR_ASSET_DONE,
  ROUTE_DASHBOARD,
  ROUTE_IDENTITY,
} from "../app/routes";
import {
  MAX_ERROR_RETRY,
  ONBOARD_NEW_ASSET_SAFE,
  ONBOARD_NEW_ASSET_UNSAFE,
  ONBOARD,
  UA_ONBOARDING_BREACHES_FOUND,
  UA_ONBOARDING_EMAIL_ENROLLED,
} from "../../constants/main";

import scanAnimation from "../_assets/animations/identity_scanner/identity-scan-loading.json";
import addEmailBreachesIdentityAlert from "../_assets/images/onboard/scanEmail/breachesFound.svg";
import addEmailNoBreachesIdentityAlert from "../_assets/images/onboard/scanEmail/noBreachesFound.svg";

// DOM
import {
  Display,
  Paragraph,
  Button,
  Row,
  Col,
  Input,
  Animator,
  Heading,
} from "@mcafee/pegasus";

// Store imports
import { addNewAsset, fetchDwsDashboard } from "../../store/thunks/identity";

import { POPUP_NEW_ASSET_CANCEL, POPUP_OTP } from "../PopUps/constants";
import { fetchBreachCountInfo } from "../../store/thunks/dws";
import { doneEmailEnrollment, skipEmailEnrollment } from "../../store/dws";
import { ViewPortCenter } from "../Widgets/ViewPortCenter";
import { saveUserActions } from "../../store/thunks/auth";
import { showPopup } from "../../store/thunks/popups";
import Unauthorized from "../Shared/Unauthorized";
import {
  createQueryString,
  currentScreenName,
  navToErrorPage,
  newGUID,
  useIsSignedIn,
} from "../../utils";
import { SCREEN_DWS_SCAN_VERIFY_EMAIL } from "../../constants/analyticsScreens";

import { addAssetCancel } from "../../store/identity";
import { DialogLayout } from "../layouts/DialogLayout";
import { navigateBack } from "../../store/thunks/app";
import ErrorMessage from "../Shared/ErrorMessage";
import { showToast } from "../../store/thunks/toasts";
import {
  BANNER_NOTIFICATION,
  TOAST_SNACKBAR_SUCCESS,
} from "../Toasts/constants";
import { uwpSendTelemetry } from "../../uwp";
import { ANALYTICS_IN_APP, EVENTID_BREACH_LOOKUP } from "../../constants";
import { String } from "../../l10n/loader";

export default function ScanEmailResult() {
  const _S = (str) => `identity.newAsset.${str}`;

  const [retryCount, setRetryCount] = useState(0);
  const [addingProgress, setAddingProgress] = useState(false);

  const { fetchingBreachCount, fetchBreachCountError, breachCount } =
    useSelector((state) => state.dws);

  const query = useSelector((state) => state.router.location.query);
  const accountEmail = useSelector((state) => state.auth.email);

  const [email] = useState(decodeURIComponent(query.email) || accountEmail);
  const [initDone, setInitDone] = useState(false);

  const dispatch = useDispatch();

  const path = decodeURIComponent(query.path);
  const returnPage = path
    ? path === ONBOARD
      ? ROUTE_DASHBOARD
      : path
    : ROUTE_IDENTITY;

  //failure would render inline error message
  const tryFetchingBreachCount = useCallback(
    async (retryCount) => {
      await dispatch(
        fetchBreachCountInfo({
          email,
          hit_trigger: path === ONBOARD ? "id_onboarding" : "monitor_new_email",
        })
      );
      setRetryCount(retryCount + 1);
    },
    [dispatch, email, path]
  );

  const onClickRetry = (e) => {
    tryFetchingBreachCount(retryCount);
  };

  //Page init. fetch email breach count
  useEffect(() => {
    if (dispatch && email && path && !initDone) {
      tryFetchingBreachCount(0);
      setInitDone(true);
    }
  }, [initDone, dispatch, email, path, tryFetchingBreachCount]);

  //Handle results
  useEffect(() => {
    if (
      initDone &&
      !fetchingBreachCount &&
      !fetchBreachCountError &&
      breachCount !== null
    ) {
      if (path === ONBOARD) {
        dispatch(
          saveUserActions({
            [UA_ONBOARDING_BREACHES_FOUND]: breachCount > 0,
          })
        );
      }
      return;
    }
  }, [
    initDone,
    path,
    fetchBreachCountError,
    breachCount,
    dispatch,
    fetchingBreachCount,
  ]);

  //Handle error
  useEffect(() => {
    if (fetchBreachCountError && retryCount > MAX_ERROR_RETRY) {
      navToErrorPage({
        ...fetchBreachCountError,
        back_steps: 2,
      });
    }
  }, [fetchBreachCountError, retryCount]);

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  //if verified
  let domItems = {
    waves: ONBOARD_NEW_ASSET_SAFE,
    header: _S`safeHeader`,
    content1: _S`safeContent_1`,
    content2: _S`safeContent_2`,
    cancelBtn: _S`noThanks`,
    verifyBtn: _S`yesPlease`,
  };

  if (breachCount !== null && parseInt(breachCount) !== 0) {
    domItems.waves = ONBOARD_NEW_ASSET_UNSAFE;
    domItems.header = _S`alertHeader`;
    domItems.content1 = _S`alertContent_1`;
    domItems.content2 = _S`alertContent_2`;
    domItems.cancelBtn = _S`cancel`;
    domItems.verifyBtn = _S`verify`;
  }

  const onDontAdd = () => {
    if (path === ONBOARD) {
      dispatch(skipEmailEnrollment());
      dispatch(saveUserActions({ [UA_ONBOARDING_EMAIL_ENROLLED]: false }));
      dispatch(replace(returnPage));
    } else {
      dispatch(addAssetCancel({ email }));
      dispatch(navigateBack({ defaultRoute: returnPage }));
    }
  };

  const onCancel = async () => {
    const response = await dispatch(
      showPopup(POPUP_NEW_ASSET_CANCEL, {
        trigger: path === ONBOARD ? "id_onboarding" : "monitor_new_email",
      })
    );
    if (response === true) {
      onVerification();
    } else if (response === false) {
      onDontAdd();
    }
  };

  //Failure would show a modal snackbar
  const tryFetchDwsDashboard = async (retryCount) => {
    const response = await dispatch(
      fetchDwsDashboard({
        disabledCache: true,
        hit_trigger: path === ONBOARD ? "id_onboarding" : "monitor_new_email",
      })
    );

    if (response.error) {
      if (retryCount >= MAX_ERROR_RETRY) {
        //Asset is added, but fetchDwsDashboard keeps failing
        return; //we have to skip to the next page showing "no" breaches
      }

      const retry = await dispatch(
        showToast(BANNER_NOTIFICATION, {
          stringId: "toasters.banner.somethingWrong",
          selfDestruct: false,
        })
      );

      if (retry) {
        await tryFetchDwsDashboard(retryCount + 1);
      }

      //Asset is added, fetchDwsDashboard failed, user wouldn't retry..
      //We skip to the second page anyways
      return;
    }

    //post analytics event for each newly detected breaches
    if (
      response &&
      response.breach_history &&
      response.breach_history.length > 0
    ) {
      let newBreaches = response.breach_history.filter(
        (breach) => breach.email === email
      );

      newBreaches.forEach((breach) => {
        uwpSendTelemetry(EVENTID_BREACH_LOOKUP, {
          hit_trigger: ANALYTICS_IN_APP,
          hit_label_0: breach.breach_id,
          hit_label_1: breach.severity,
          hit_screen: currentScreenName(),
          hit_label_2: breach.site,
          hit_label_3: breach.asset_public_id,
          hit_guid: newGUID(),
        });
      });
    }
  };

  const tryAddNewAsset = async (email, otpToken, retryCount = 0) => {
    const response = await dispatch(
      addNewAsset({
        otpToken,
        email,
      })
    );

    if (response.error) {
      if (retryCount >= MAX_ERROR_RETRY) {
        navToErrorPage(response.error);
        return false;
      }

      const retry = await dispatch(
        showToast(BANNER_NOTIFICATION, {
          stringId: "toasters.banner.somethingWrong",
          selfDestruct: false,
        })
      );

      if (retry) {
        return await tryAddNewAsset(email, otpToken, retryCount + 1);
      }

      setAddingProgress(false); //Failed to add the asset, User has to initiate the process again
      return false;
    }

    if (breachCount > 0) {
      await tryFetchDwsDashboard(0);
    }

    return true;
  };

  const onVerification = async (e) => {
    const otpResult = await dispatch(
      showPopup(POPUP_OTP, {
        newEmail: email,
        screen: SCREEN_DWS_SCAN_VERIFY_EMAIL,
        trigger: "monitor_new_email",
        details: "verify_email_default",
      })
    );

    if (!otpResult) {
      return;
    }

    const { otpToken } = otpResult;

    if (otpToken) {
      setAddingProgress(true);

      if (await tryAddNewAsset(email, otpToken)) {
        if (path === ONBOARD) {
          dispatch(doneEmailEnrollment());
          dispatch(saveUserActions({ [UA_ONBOARDING_EMAIL_ENROLLED]: true }));
        }
        dispatch(
          showToast(TOAST_SNACKBAR_SUCCESS, {
            stringId: "toasters.addedEmail",
          })
        );
        dispatch(
          replace({
            pathname: ROUTE_MONITOR_ASSET_DONE,
            search: createQueryString({ email, path }),
          })
        );
      }
    }
  };

  const Buttons = () => (
    <>
      <Button
        onClick={onVerification}
        id="Verify"
        data-testid="newAssetDarkWebEducateVerifyButton"
        size="sm"
        disabled={addingProgress}
      >
        <String id={domItems.verifyBtn} />
      </Button>
      <Button
        variant="tertiary"
        onClick={onCancel}
        id="Cancel"
        data-testid="newAssetDarkWebEducateCancelButton"
        size="sm"
        disabled={addingProgress}
      >
        <String id={domItems.cancelBtn} />
      </Button>
    </>
  );

  //Show processing animation
  if (fetchingBreachCount) {
    return (
      <ViewPortCenter>
        <Animator
          style={{ width: 150, height: 150 }}
          src={scanAnimation}
        ></Animator>
        <Heading size="xs" className="mt-12">
          <String id={`identity.newAsset.content`} />
        </Heading>
      </ViewPortCenter>
    );
  }

  if (fetchBreachCountError) {
    return (
      <ViewPortCenter>
        <ErrorMessage
          {...{
            error: fetchBreachCountError,
            onRetry: onClickRetry,
          }}
        />
      </ViewPortCenter>
    );
  }

  return (
    <DialogLayout
      pageId={domItems.waves}
      buttons={<Buttons />}
      image={
        domItems.waves === ONBOARD_NEW_ASSET_SAFE
          ? addEmailNoBreachesIdentityAlert
          : addEmailBreachesIdentityAlert
      }
      animate={true}
      showBack={false}
    >
      <Display size="xs" data-testid="newAssetDarkWebEducateHeader">
        <String id={domItems.header} />
      </Display>
      <div>
        <Row className="mt-32">
          <Col xs={11} className="pl-8">
            <Input icon="email-rounded-outline" readOnly value={email} />
          </Col>
        </Row>
        <Paragraph
          className={`text-secondary mb-2 mt-32`}
          size="sm"
          data-testid="newAssetDarkWebEducateContent1"
        >
          <String id={domItems.content1} />
        </Paragraph>
      </div>
    </DialogLayout>
  );
}
