import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  lastPopupResults: null,
};

const slice = createSlice({
  name: "@popups",
  initialState,
  reducers: {
    openPopup: (state, action) => {
      state.list = [...state.list, action.payload];
      state.lastPopupResults = null;
    },
    closePopup: (state, action) => {
      const { popup, results } = action.payload;
      state.list = state.list.filter((item) => item.popup !== popup);
      state.lastPopupResults = results;
    },
  },
  extraReducers: {},
});

export const { openPopup, closePopup } = slice.actions;

export default slice.reducer;
