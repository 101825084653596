import createRootReducer from "./index";
import { routerMiddleware } from "connected-react-router";
import analyticsDataCapture from "./middleware/analyticsDataCapture";
import logger from "./middleware/logger";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

//thunkMiddleware allows store.dispatch() to accept a reference to a function instead of an action object
import thunkMiddleware from "redux-thunk";

import { history } from "./history";

const config = () => {
  return configureStore({
    reducer: createRootReducer(history),
    middleware: [
      ...getDefaultMiddleware(),
      logger,
      analyticsDataCapture,
      thunkMiddleware,
      routerMiddleware(history),
    ],
  });
};

export default config;