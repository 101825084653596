/* eslint-disable jsx-a11y/anchor-is-valid */
//ROUTE_DISCONNECT_WIFI_DONE
import React from "react";
import { String } from "../../l10n/loader";
import { connect } from "react-redux";
import { ONBOARD_DISCONNECT_WIFI_CONFIRM } from "../../constants/main";

// DOM
import { Display, Paragraph, Button } from "@mcafee/pegasus";

// Custom components
import image from "../_assets/images/hackBlock/girlRelaxing.svg";
import { DialogLayout } from "../layouts/DialogLayout";
import { navigateBack } from "../../store/thunks/app";

const DisconnectWifiConfirm = ({ navigateBack }) => {
  const _T = (str) => `hackBlock.${str}`;
  const _S = (str) => `hackBlock.disconnectWifiConfirm.${str}`;

  // ToDo: Need to set the 'showProtectAllCard' to true based on conditions(TBD)
  const onThanksHandler = () => {
    navigateBack({ steps: 3 });
  };

  return (
    <DialogLayout
      pageId={ONBOARD_DISCONNECT_WIFI_CONFIRM}
      image={image}
      showBack={false}
      buttons={
        <>
          <Button
            className="px-40"
            onClick={onThanksHandler}
            id="HackblockDisconnectWifiConfirmThanks"
            size="sm"
          >
            <String id={_T`thanks`} />
          </Button>
        </>
      }
    >
      <Display size="sm">
        <String id={_S`header`} />
      </Display>
      <Paragraph size="lg" className="mt-48">
        <String
          id={_S`content_1`}
          values={{
            // ToDo: replace the constant with the network name coming from the API
            WiFi: "Wi-Fi",
          }}
        />
      </Paragraph>
    </DialogLayout>
  );
};

export default connect(() => ({}), { navigateBack })(DisconnectWifiConfirm);
