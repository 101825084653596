import React, { useState, useEffect } from "react";
import { String } from "../../l10n/loader";
import { createFormattedId } from "../../utils";
import {
  ANALYTICS_VPN,
  ANALYTICS_ENGAGEMENT,
  ANALYTICS_EDUCATION,
  ANALYTICS_ID_PROTECTION,
  ANALYTICS_DASHBOARD_CARD,
  ANALYTICS_MANUAL,
} from "../../constants/analytics";

// DOM
import {
  Pagination,
  PageItem,
  Paragraph,
  Heading,
  Button,
} from "@mcafee/pegasus";
import "./vpn.scss";
import DashboardSlideOne from "../../components/_assets/images/onboard/dwsLearnMore/step1.svg";
import DashboardSlideTwo from "../../components/_assets/images/onboard/dwsLearnMore/step2.svg";
import DashboardSlideThree from "../../components/_assets/images/onboard/dwsLearnMore/step3.svg";
import VpnSlideOne from "../../components/_assets/images/vpnSetup/tellMeMore/safe.svg";
import VpnSlideTwo from "../../components/_assets/images/vpnSetup/tellMeMore/hideLocation.svg";
import VpnSlideThree from "../../components/_assets/images/vpnSetup/tellMeMore/whenYouNeedUs.svg";
import {
  TELL_MORE_VPN,
  TELL_MORE_BREACH,
  LTR_DIRECTION,
} from "../../constants/main";
import {
  SCREEN_DWS_TOOLTIP,
  SCREEN_VPN_QUICK_TOUR,
} from "../../constants/analyticsScreens";

import { connect } from "react-redux";
import { collectScreenLoadEvent } from "../../utils";
import "./TellMeMoreModal.scss";

const TellMeMoreModal = ({
  handleClose,
  onFinish,
  source,
  showSecondaryButton = true,
  query,
  direction,
}) => {
  let localePrefix = "dashboard.";
  let telemetryFeature = ANALYTICS_VPN;
  let telemetryHitScreen = SCREEN_VPN_QUICK_TOUR;
  let telemetryHitTrigger = ANALYTICS_MANUAL;
  let telemetryCategory = ANALYTICS_ENGAGEMENT;
  let telemetryScreenFlow = null;
  let images = [];
  const dashboard_images = [
    DashboardSlideOne,
    DashboardSlideTwo,
    DashboardSlideThree,
  ];
  const vpn_images = [VpnSlideOne, VpnSlideTwo, VpnSlideThree];
  const [step, setStep] = useState(0);

  switch (source) {
    case TELL_MORE_VPN:
      localePrefix = "vpn.";
      telemetryHitTrigger = query.src || ANALYTICS_MANUAL;
      images = vpn_images;
      break;
    case TELL_MORE_BREACH:
    default:
      localePrefix = "dashboard.";
      telemetryFeature = ANALYTICS_ID_PROTECTION;
      telemetryHitScreen = SCREEN_DWS_TOOLTIP;
      telemetryHitTrigger = ANALYTICS_DASHBOARD_CARD;
      telemetryCategory = ANALYTICS_EDUCATION;
      telemetryScreenFlow = "identity";
      images = dashboard_images;
      break;
  }

  useEffect(() => {
    if (Number.isInteger(step)) {
      collectScreenLoadEvent({
        hit_feature: telemetryFeature,
        hit_trigger: telemetryHitTrigger,
        hit_category_0: telemetryCategory,
        hit_screen: telemetryHitScreen,
        hit_label_3: ANALYTICS_EDUCATION,
        hit_label_6:
          source === TELL_MORE_VPN
            ? `value_${step + 1}`
            : `dws_tooltip_${step + 1}`,
        hit_label_8: telemetryScreenFlow,
      });
    }
  }, [
    step,
    telemetryCategory,
    telemetryFeature,
    telemetryHitScreen,
    telemetryHitTrigger,
    source,
    telemetryScreenFlow,
  ]);

  const onClickProtectMeNow = (e) => {
    onFinish(true);
  };

  const handlePaginationClick = (newStep) => {
    // due pegasus bug, it's necessary to substract the step
    setStep(newStep - 1);
  };

  const getButtons = (step) => {
    switch (source) {
      case TELL_MORE_VPN:
        if (step < 2) {
          return (
            <>
              <Button
                id={createFormattedId(`${TELL_MORE_VPN}Step${step}`)}
                onClick={() => setStep(step + 1)}
                size="sm"
                className="mr-12"
              >
                <String id={`${localePrefix}step${step}`} />
              </Button>
              {showSecondaryButton && (
                <Button
                  variant="tertiary"
                  onClick={onClickProtectMeNow}
                  id={createFormattedId(`${TELL_MORE_VPN}Step${step}Close`)}
                  size="sm"
                  className="mr-12"
                >
                  <String id={`${localePrefix}protectMeNow`} />
                </Button>
              )}
            </>
          );
        } else {
          return (
            <Button
              variant="primary"
              className="mr-12"
              id={createFormattedId(`${TELL_MORE_VPN}Step${step}`)}
              onClick={onClickProtectMeNow}
              size="sm"
            >
              <String id={`${localePrefix}protectMeNow`} />
            </Button>
          );
        }
      case TELL_MORE_BREACH:
      default:
        return (
          <Button
            onClick={(e) => {
              if (step < 2) {
                setStep(step + 1);
              } else {
                onFinish();
              }
            }}
            id={createFormattedId(`${TELL_MORE_BREACH}Step${step}`)}
            size="sm"
          >
            <String id={`${localePrefix}step${step}`} />
          </Button>
        );
    }
  };

  const sliderDirection =
    direction === LTR_DIRECTION ? "marginLeft" : "marginStart";

  return (
    <div className="direction-column align-items-center tellMeMore">
      <div className="tellMeMore__sliderRow">
        <div className="flex no-wrap tellMeMore__sliderContainer">
          {images.map((image, index) => {
            return (
              <div
                className="tellMeMore__slide"
                key={`telMeMore_${index}`}
                style={
                  index === 0
                    ? {
                        [sliderDirection]: `${"-" + 100 * step + "%"}`,
                      }
                    : {}
                }
              >
                <img src={image} alt="Splash_Image" style={{ height: 164 }} />
              </div>
            );
          })}
        </div>

        <div className="tellMeMore__textContainer">
          <Heading size="xs" className="mb-8">
            <String id={`${localePrefix}tellMeMore.step${step}.header`} />
          </Heading>

          <Paragraph size="sm" className="mb-16">
            <String
              id={`${localePrefix}tellMeMore.step${step}.content_part1`}
            />
          </Paragraph>
          {step !== 2 && (
            <Paragraph size="sm">
              <String
                id={`${localePrefix}tellMeMore.step${step}.content_part2`}
              />
            </Paragraph>
          )}
        </div>
      </div>

      <div className="tellMeMore__paginationContainer">
        <Pagination className="tellMeMore__pagination" activeId={step + 1}>
          {images.map((_, index) => {
            return (
              <PageItem
                id={index + 1}
                key={`page${index}`}
                onClick={handlePaginationClick}
              />
            );
          })}
        </Pagination>
        {getButtons(step)}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({ ...state.router.location, direction: state.context.direction }),
  {}
)(TellMeMoreModal);
