import React from "react";
import { String } from "../../l10n/loader";

// DOM
import { Button, Paragraph, Heading, Label, Tag, Icon } from "@mcafee/pegasus";

const ConfirmBeforeExternalLinkPopup = ({ siteUrl, onFinish, onCancel }) => {
  const S_ = (string) => `popups.confirmBeforeExternalLinkPopUp.${string}`;

  const handleSubmit = (e) => {
    onFinish(true);
  };

  const styles = {
    popUpStyles: {
      maxWidth: 550,
    },
  };

  return (
    <div
      style={styles.popUpStyles}
      className="pa-24 text-left flex direction-column "
    >
      <Tag icon variant="solid" kind="warning" className="mx-auto my-24">
        <Icon icon="external-link-outline" size="lg" />
      </Tag>

      <Heading size="xs" data-testid="confirmBeforeExternalLinkPopUpHeader">
        <String id={S_`header`} />
      </Heading>

      <Paragraph
        className="mt-12"
        size="sm"
        data-testid="confirmBeforeExternalLinkPopUpContent1"
      >
        <String id={S_`content_1`} />
      </Paragraph>

      <Paragraph
        className="mt-12"
        size="sm"
        data-testid="confirmBeforeExternalLinkPopUpContent2"
      >
        <String id={S_`content_2`} />
      </Paragraph>

      <Paragraph
        className="mt-12"
        size="sm"
        data-testid="confirmBeforeExternalLinkPopUpContent3"
      >
        <String id={S_`content_3`} />
      </Paragraph>

      <Paragraph
        className="mt-12"
        size="sm"
        data-testid="confirmBeforeExternalLinkPopUpSite"
      >
        <Label size="sm">{siteUrl}</Label>
      </Paragraph>

      <div className="mt-24 text-right">
        <Button
          className="ml-32"
          onClick={handleSubmit}
          id="GoToExternalSite"
          data-testid="confirmBeforeExternalLinkPopUpGoToExternalSite"
          size="sm"
        >
          <String id={`popups.goToSite`} />
          <Icon icon="external-link-outline" className="ml-8" />
        </Button>
        <Button
          className="ml-32"
          variant="tertiary"
          onClick={onCancel}
          id="GoBack"
          data-testid="confirmBeforeExternalLinkPopUpGoBack"
          size="sm"
        >
          <String id={`popups.goBack`} />
        </Button>
      </div>
    </div>
  );
};

export default ConfirmBeforeExternalLinkPopup;
