import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
// DOM
import {
  Row,
  Col,
  Label,
  Grid,
  Icon,
  Button,
  Sidebar,
  SidebarNavList,
  SidebarNavListItem,
  SidebarTitle,
  SidebarSubTitle,
  SidebarNavListButton,
  Avatar,
  Paragraph,
  Subheading,
  List,
  ListItem,
  Tag,
} from "@mcafee/pegasus";
import cautionImage from "../_assets/images/shared/blueCautionImage.svg";
import shieldImage from "../_assets/images/shared/blueShieldImage.svg";

import { String } from "../../l10n/loader";
import { push } from "connected-react-router";
import { ROUTE_SETTINGS_ANTIVIRUS,ROUTE_DASHBOARD } from "../app/routes";
import NavbarLayout from "../NavBar/NavbarLayout";
import { FolderOpenOutlined } from "@material-ui/icons";
import "./scanDetails.scss";

import { uwpPublish, uwpUnsubscribe, uwpSubscribe } from "../../uwp";

import {
  POPUP_AV_SCAN_FILE_DETAILS,
  POPUP_AV_DELETE_FILE,
  POPUP_AV_RESTORE_FILE,
  POPUP_AV_RESTORE_FAILED
} from "../PopUps/constants";
import { showPopup, closeAllPopups } from "../../store/thunks/popups";
import { MCAFEE_SUPPORT_URL } from "../../constants";




function shortLocation(loc, max_len) {

  var res_loc = loc;
  if (loc.length < max_len) {
    res_loc = loc;
  }
  else {
    var first_slash = loc.indexOf('\\');
    var last_slash = loc.lastIndexOf('\\');

    last_slash = loc.length - max_len + first_slash;
    res_loc = (loc.substring(0, first_slash + 1) + "..." + loc.substring(last_slash, loc.length));
  }

  return res_loc;
}


function ScanDetails({threats}) {

  const [unresolvedThreatsList, setUnresolvedThreatsList] = useState([]);
  const [quarantinedItemsList, setQuarantinedItemsList] = useState([]);
  const [threatsFixedList, setThreatsFixedList] = useState([]);

  const [contentGroup, setContentGroup] = useState("quarantined");
  const [avatarImage, setAvatarImage] = useState(cautionImage);
  const [threatsList, setThreatsList] = useState([]);
  const dispatch = useDispatch();
  const [threat_types,set_threat_types] = useState({
    cleaned: {
      getArray: threatsFixedList,
      setArray: setThreatsFixedList,

    },
    unresolved: {
      getArray: unresolvedThreatsList,
      setArray: setUnresolvedThreatsList,

    }
    ,
    quarantined: {
      getArray: quarantinedItemsList,
      setArray: setQuarantinedItemsList,

    }
  })

  useEffect(() => {
    set_threat_types({
      cleaned: {
        getArray: threatsFixedList,
        setArray: setThreatsFixedList,

      },
      unresolved: {
        getArray: unresolvedThreatsList,
        setArray: setUnresolvedThreatsList,

      }
      ,
      quarantined: {
        getArray: quarantinedItemsList,
        setArray: setQuarantinedItemsList,

      }
    })
  }, [threats,threatsFixedList,unresolvedThreatsList,quarantinedItemsList])

  const filenameFromPath = (str) => {
    return str.substring(str.lastIndexOf('\\') + 1)
  }

  const epochToTime = (str) => {
    let d = new Date(Number(str)*1000)
    return d.toLocaleString()
  }


  const onShowDeleteThreat = (index) => {
    dispatch(closeAllPopups())
    dispatch(showPopup(POPUP_AV_DELETE_FILE,
      {
        index: index,
        onDelete: onDeleteThreat,
        onCancel: (index) => console.log("cancel delete ", index)
      }));
  }

  const onShowRestoreThreat = (index) => {
    dispatch(closeAllPopups())
    dispatch(showPopup(POPUP_AV_RESTORE_FILE,
      {
        index: index,
        onCancel: (index) => console.log("cancel delete ", index),
        onRestore: onRestoreThreat,
      }));
  }

  const onDeleteThreat = (index) => {
    console.log("onDeleteThreat index = ", index);
    uwpPublish("nav.quarantine.delete", { ids: [index] })

    setTimeout(() => {
      console.log("publish nav.quarantine.index ...");
      uwpPublish("nav.quarantine.query", {})
    }, 100);
    dispatch(closeAllPopups())
  }



  const onRestoreThreat = useCallback(async (index) => {
    console.log("onRestoreThreat index = ", index);
    uwpPublish("nav.quarantine.restore", { ids: [index] })

    setTimeout(() => {
      console.log("publish nav.quarantine.index ...");
      uwpPublish("nav.quarantine.query", {})
    }, 100);
    dispatch(closeAllPopups())
  }, [dispatch]);

  const onContactUs = () => {
    window.open(MCAFEE_SUPPORT_URL, "_blank");
  }

  const onShowFileDetails = (threat) => {
    dispatch(showPopup(POPUP_AV_SCAN_FILE_DETAILS,
      {
        fileName: filenameFromPath(threat.path),
        detectionTime: epochToTime(threat.time),
        threatName: threat.infection_name,
        fileLocation: threat.path,
        id: threat.id,
        status: threat.status.toLowerCase(),
        onDelete: onShowDeleteThreat,
        onRestore: onShowRestoreThreat,
        onContact: onContactUs,
        threat_resolution: contentGroup
      }));
  };

  

  const onThreatsClick = useCallback(async (threatType) => {

    
    console.log("onThreatsClick ", threatType)
    setContentGroup(threatType);

    setThreatsList(threat_types[threatType].getArray)
    setAvatarImage(threatType === "unresolved" ? cautionImage : shieldImage);

}, [setContentGroup, setThreatsList, setAvatarImage, threat_types]);



useEffect(() => {

  console.log("threats changed =", threats);
  // dispatch(dashboardShowCard({card:CARD_AV_FOUND_THREATS,show: (unresolvedThreatsList.length > 0 ? true : false)}));

  setThreatsFixedList(threats.fixedThreats)
  setUnresolvedThreatsList(threats.unresolvedThreats)
}, [threats])

useEffect(() => {
  console.log("threaths changed group=", contentGroup);
  onThreatsClick(contentGroup);
}, [onThreatsClick, contentGroup, quarantinedItemsList, threatsFixedList]);

useEffect(() => {

  

  const onQuarantineIndex = (msg) => {
    setQuarantinedItemsList(msg.detection_list);
   

  }

  const onQuarantineRestore = (msg) => {
    console.log("onQuarantineRestore = ", msg);
    if(msg.response_list === undefined) {
      console.log("onQuarantineRestore response_list is missing ", msg);
      return;
    }
    msg.response_list.every(element => {

      if (element.status !== 'restored' && element.status !== 'deleted') {
        dispatch(showPopup(POPUP_AV_RESTORE_FAILED,
          {
            index: element.id,
            onCancel: (index) => console.log("cancel delete ", index),
            onFinish: onRestoreThreat,
          }));
          console.log("onQuarantineRestore failed to restore ", element);
          return false;
      }
      return true;
    });
    
  }

  

  console.log("subscribe nav.quarantine.index ...");

  const on_quarantine_id = uwpSubscribe("nav.quarantine.index", onQuarantineIndex);
  const on_resore_id = uwpSubscribe("nav.quarantine.response", onQuarantineRestore);
  



  return function cleanup() {
    uwpUnsubscribe(on_quarantine_id);
  
    uwpUnsubscribe(on_resore_id);
  };
}, [dispatch, onRestoreThreat, threatsFixedList, unresolvedThreatsList]);

useEffect(() => {
  setTimeout(() => {
    console.log("publish nav.quarantine.index ...");
    uwpPublish("nav.quarantine.query", {})
  //  uwpPublish("nav.detections.query", {})
  }, 1000);

}, []);

const _S = (str) => `av.scanDetails.${str}`;

const onSettingsClick = (e) => {
  dispatch(push(ROUTE_SETTINGS_ANTIVIRUS));
};




const onOk = (e) => {
  dispatch(push(ROUTE_DASHBOARD))
}

const buttons = () => {
  if (contentGroup === "unresolved") {
    return (
      <div className="scanDetails__container__content__buttonContainer">
        <Button
          variant="primary"
          onClick={onContactUs}
          id="viewFile"
          size="sm"
        >
          <String id={_S`contactUs`} />
        </Button>
      </div>
    );
  } else if (contentGroup === "cleaned") {
    return (
      <div className="scanDetails__container__content__buttonContainer">
        <Button
          className="scanDetails__container__content__buttonContainer__button"
          variant="primary"
          onClick={onOk}
          id="viewFile"
          size="sm"
        >
          <String id={_S`ok`} />
        </Button>
        
      </div>
    );
  } else return null;
};

  const threats_list = Object.keys(threat_types).map((key, indx) => {
    if (threat_types[key].getArray.length > 0)
    {
      return (
        <SidebarNavListItem
                key="unresolved-key"
                id="unresolved-id"
                tabIndex="0"
              >
                
          
        <SidebarNavListButton
          className="text-left"
          active={contentGroup === key}
          onClick={() => onThreatsClick(key)}
        >
          <Grid>
            <Row className="mb-2">
              <Col>
                <Label
                  size="sm"
                >
                  <String
                    id={_S(key)}
                    values={{
                      count: threat_types[key].getArray.length,
                    }}
                  />
                </Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label size="xs">

                </Label>
              </Col>
            </Row>
          </Grid>
        </SidebarNavListButton>
        </SidebarNavListItem>
      )} else {
        return (<></>)
      }
    })

return (
  <NavbarLayout id={contentGroup} classNameLayout="pa-0">
    <Grid fluid>
      <Row className="scanDetails__basePanel">
        <Col md={4} sm={4} xs={12} className="scanDetails__assetExplorer">
          <Sidebar className="sidebarContainer">
            <SidebarTitle className="flex justify-content-between align-items-center">
              <String id={_S`title`} />
              <Button
                tabIndex={0}
                kind="normal"
                variant="tertiary"
                onClick={onSettingsClick}
                className="text-gray-light"
                size="sm"
              >
                <Icon icon="settings-atlas-outline" />
              </Button>
            </SidebarTitle>

            <SidebarSubTitle size="sm">
              <String id={_S`content`} />
            </SidebarSubTitle>
            <SidebarNavList>
              {threats_list}
             
            </SidebarNavList>
          </Sidebar>
        </Col>
        {contentGroup && (
          <Col md={8} sm={8} xs={12} className="scanDetails__container">
            <div className="scanDetails__container__header">
              <div className="scanDetails__container__image">
                {contentGroup === "unresolved" && (
                  <Tag
                    className="scanDetails__container__tag"
                    kind="danger"
                    variant="solid"
                    icon
                  >
                    {unresolvedThreatsList.length}
                  </Tag>
                )}
                {contentGroup === "cleaned" && (
                  <Tag
                    className="scanDetails__container__tag"
                    kind="danger"
                    variant="solid"
                    icon
                  >
                    {threatsFixedList.length}
                  </Tag>
                )}
                <Avatar
                  shape="round"
                  kind="image"
                  style={{
                    backgroundImage: `url(${avatarImage})`,
                  }}
                  className="scanDetails__container__header__avatar"
                ></Avatar>
              </div>
              <Subheading size="lg">
                <String id={_S(`${contentGroup}Header`)} />
              </Subheading>
              <Paragraph
                size="sm"
                className="scanDetails__container__content__caption"
              >
                <String id={_S(`${contentGroup}Caption`)} />
              </Paragraph>
            </div>
            <Label
              size="sm"
              className="scanDetails__container__content__label"
            >
              <String
                id={_S(`${contentGroup}SubHeading`)}
                values={{
                  count: threat_types[contentGroup].getArray.length,
                }}
              />
            </Label>
            <List className="scanDetails__container__content__list">
              <Label size="sm">
                {threatsList.map((threat) => (
                  <ListItem>
                    <FolderOpenOutlined />{" "}
                    <div className="scanDetails__container__content__listItem">
                      {shortLocation(threat.path, 60)}
                    </div>
                    <Button
                      className="scanDetails__container__content__listItem_btn"
                      variant="secondary"
                      onClick={() => onShowFileDetails(threat)}
                      id="viewFile"
                      size="sm"
                    >
                      <String id={_S`view`} />
                    </Button>
                  </ListItem>
                ))}
              </Label>
            </List>
            {buttons()}
          </Col>
        )}
      </Row>
    </Grid>
  </NavbarLayout>
);
}

export default connect(
  (state) => ({
    ...state.av,
  }),
  {
    push,
  }
)(ScanDetails);