//Route ROUTE_VPN_SETUP /vpn_setup
import React, { useCallback, useEffect, useState } from "react";
import { String } from "../../l10n/loader";
import { connect, useDispatch } from "react-redux";
import { push, replace } from "connected-react-router";
import { ROUTE_SECURE_VPN, ROUTE_VPN_SETUP_DISCLOSURE } from "../app/routes";
import { TELL_MORE_VPN, VPN_SETUP } from "../../constants/main";

// DOM
import { Paragraph, Button, Display } from "@mcafee/pegasus";

// Custom components

//utils
import { createFormattedId, useIsSignedIn } from "../../utils";
import { POPUP_TELL_ME_MORE } from "../PopUps/constants";
import { showPopup } from "../../store/thunks/popups";
import Unauthorized from "../Shared/Unauthorized";
import image from "../_assets/images/vpnSetup/womanDisconnected.svg";
import { DialogLayout } from "../layouts/DialogLayout";
import {
  ANALYTICS_MANUAL,
  ANALYTICS_NA,
  ANALYTICS_SUCCESS,
  EVENTID_PPS_VPN_SETUP_START,
  SCREEN_VPN_SETUP_LANDING_PAGE,
} from "../../constants";
import { uwpSendTelemetry } from "../../uwp";
import { v4 as uuidv4 } from "uuid";

const VpnSetup = ({
  vpnSetupComplete,
  push,
  query,
  replace,
  showPopup: autoShowPopup,
}) => {
  const _S = (str) => `vpn.wifiSetup.${str}`;

  const [autoShowDone, setAutoShowDone] = useState(false);

  const dispatch = useDispatch();
  const [isEventSent, setIsEventSent] = useState(false);

  const onClickProtectMeNow = (e) => {
    push({
      pathname: ROUTE_VPN_SETUP_DISCLOSURE,
      search: query.back ? `back=${query.back}` : undefined,
    });
  };

  const showTellMeMore = useCallback(async () => {
    if (
      await dispatch(showPopup(POPUP_TELL_ME_MORE, { source: TELL_MORE_VPN }))
    ) {
      push(ROUTE_VPN_SETUP_DISCLOSURE);
    }
  }, [dispatch, push]);

  const onClickTellMeMore = async (e) => {
    showTellMeMore();
  };

  useEffect(() => {
    if (vpnSetupComplete) {
      replace(ROUTE_SECURE_VPN);
    }
  }, [replace, vpnSetupComplete]);

  useEffect(() => {
    if (!autoShowDone && autoShowPopup) {
      setAutoShowDone(true);
      showTellMeMore();
    }
  }, [autoShowDone, autoShowPopup, showTellMeMore]);

  useEffect(() => {
    if (!isEventSent) {
      const hit_guid = uuidv4().toUpperCase();

      uwpSendTelemetry(EVENTID_PPS_VPN_SETUP_START, {
        hit_trigger: query.back ? query.back.slice(1) : "dashboard_card",
        hit_screen: SCREEN_VPN_SETUP_LANDING_PAGE,
        hit_label_0: ANALYTICS_SUCCESS,
        hit_label_1: ANALYTICS_NA,
        hit_label_2: ANALYTICS_MANUAL,
        hit_guid,
      });

      setIsEventSent(true);
    }
  }, [isEventSent, query.back]);

  if (!useIsSignedIn()) {
    return <Unauthorized />;
  }

  const Buttons = () => (
    <>
      <Button
        onClick={onClickProtectMeNow}
        id={`${createFormattedId(TELL_MORE_VPN)}ProtectMyData`}
        size="sm"
        className="px-40"
      >
        <String id={_S`protectMyData`} />
      </Button>
      <Button
        variant="tertiary"
        onClick={onClickTellMeMore}
        id={`${createFormattedId(TELL_MORE_VPN)}HowWeHelp`}
        size="sm"
      >
        <String id={`vpn.howWeHelp`} />
      </Button>
    </>
  );

  return (
    <DialogLayout
      image={image}
      buttons={<Buttons />}
      pageId={VPN_SETUP}
      animate={autoShowPopup ? false : true}
      showBack
    >
      <Display size="sm">
        <String id={_S`header`} />
      </Display>
      <Paragraph size="lg" className="text-secondary mt-40">
        <String
          id={_S`content_part1`}
          values={{
            WiFi: "Wi-Fi",
          }}
        />
      </Paragraph>
      <Paragraph size="lg" className="text-secondary mt-40">
        <String id={_S`content_part2`} />
      </Paragraph>
    </DialogLayout>
  );
};

export default connect(
  (state) => ({
    vpnSetupComplete: state.vpn.vpnSetup,
    ...state.router.location,
  }),
  { push, replace }
)(VpnSetup);
