// React
import React from "react";
import { push } from "connected-react-router";
import { ROUTE_IDENTITY } from "../app/routes";

// Store
import { useDispatch } from "react-redux";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import backgroundImage from "../_assets/images/dashboard/cards/accountBreached.svg";
import { NEEDS_ATTENTION } from "../../constants/main";

export default function AccountBreached({ id }) {
  const dispatch = useDispatch();

  const onClickTellMeMore = (e) => {
    dispatch(push(ROUTE_IDENTITY));
  };

  const card = {
    id,
    backgroundImage,
    tagText: NEEDS_ATTENTION,
    onPrimaryAction: onClickTellMeMore,
  };

  return <DashboardCard {...card} />;
}
