// React
import React from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

// Custom components
import DashboardCard from "../DashboardCard/DashboardCard";
import backgroundImage from "../_assets/images/dashboard/cards/emailVerificationBreaches.svg";

// utils
import { NEEDS_ATTENTION } from "../../constants/main";
import { ROUTE_IDENTITY, ROUTE_SCAN_EMAIL_RESULT } from "../app/routes";

export default function EmailVerificationBreachesFound({ id }) {
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.auth);
  const { assets } = useSelector((state) => state.identity);

  const onPrimaryAction = (e) => {
    // Take the user to Identity if primary email already added else, scan and prompt to add
    dispatch(
      push({
        pathname:
          assets && assets.includes(email)
            ? ROUTE_IDENTITY
            : ROUTE_SCAN_EMAIL_RESULT,
      })
    );
  };

  const card = {
    id,
    backgroundImage,
    tagText: NEEDS_ATTENTION,
    stringValues: {
      Email: email,
    },
    primaryActionStringId: "letsDoIt",
    primaryActionDataTestId: "emailVerificationBreachesFoundLetsDoIt",
    onPrimaryAction,
    dismissDataTestId: "emailVerificationBreachesFoundDismiss",
  };

  return <DashboardCard {...card} />;
}
